import React from "react";
import ToggleButton from "./ToggleButton";

export default async (domNode, rehydrateChildren, extra) => {
  const props = {
    alignment: domNode.getAttribute("data-alignment"),
    activeItem: domNode.getAttribute("data-activeitem"),
    items: JSON.parse(domNode.getAttribute("data-items")),
    isAjax: JSON.parse(domNode.getAttribute("data-is-ajax")),
    analytics: extra.analytics
  };

  const childrenNode = domNode.querySelector("[data-rehydratable-children]");
  const children = await rehydrateChildren(childrenNode);

  return <ToggleButton {...props}>{children}</ToggleButton>;
};
