import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Button from "@emcm-ui/component-button";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

import EmptyCart from "./components/EmptyCart/EmptyCart";
import List from "./components/List/List";

class Cart extends Component {
  static displayName = "Cart";

  static propTypes = {
    /**
     * Title for the cart component
     */
    title: PropTypes.string.isRequired,
    /**
     * Description for the cart component
     */
    description: PropTypes.string.isRequired,
    /**
     * Maximum license options possible on the dropdown
     */
    maxLicense: PropTypes.number.isRequired,
    /**
     * Maximum purchasable online license possible
     */
    maxOnlineLicense: PropTypes.number.isRequired,
    /**
     * Text for Sub total
     */
    subTotalText: PropTypes.string.isRequired,
    /**
     * Tax details for the Sub total text
     */
    taxInfo: PropTypes.string.isRequired,
    /**
     * Invoice details for the Sub total text
     */
    footerText: PropTypes.string.isRequired,
    /**
     * Option for showing button
     */
    showButton: PropTypes.bool,
    /**
     * Text for the button
     */
    buttonText: PropTypes.string,
    /**
     * Option for showing button on full width
     */
    buttonBlock: PropTypes.bool,
    /**
     * Object of products details
     */
    productDetails: PropTypes.object,
    /**
     * Validity for product license
     */
    productValidity: PropTypes.string.isRequired,
    /**
     * License text for the product item
     */
    licenseText: PropTypes.string.isRequired,
    /**
     * Support team information node
     */
    support: PropTypes.node.isRequired,
    /**
     * Function to capture cart details
     */
    captureCartSelection: PropTypes.func,
    /**
     * Currency placeholder for empty basket
     */
    currency: PropTypes.string,
    /**
     * Info for the sub total
     */
    subTotalInfo: PropTypes.string,
    /**
     * Addons product details object
     */
    addonsDetails: PropTypes.object,
    /**
     * Exchanges product details object
     */
    exchangesDetails: PropTypes.object,
    /**
     * Empty cart info text
     */
    emptyCartText: PropTypes.string,
    /**
     * Call back function to remove product
     */
    captureRemoveProduct: PropTypes.func,
    /**
     * To hide/show remove link in each product
     */
    showRemoveLink: PropTypes.bool,
    /**
     * Tooltip info for the non base products
     */
    tooltipInfo: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.getClassName = getClassNameFactory(Cart.displayName);

    const {
      productDetails: { products } = {},
      addonsDetails: { products: addons = [] } = {},
      exchangesDetails: { products: exchanges = [] } = {}
    } = this.props;

    this.state = {
      products,
      addons,
      exchanges,
      totalPrice: 0
    };

    this.firstProductIndex = 0;
  }

  getTotalPrice = (productId, productLicense) => {
    const updatedState = {};
    const { maxOnlineLicense } = this.props;
    const { products: baseProducts, addons, exchanges } = this.state;

    const updateProducts = (products, updateAllLicences) => {
      return products.map(item => {
        return updateAllLicences || item.id === productId
          ? {
              ...item,
              licenseCount: Math.min(productLicense, maxOnlineLicense)
            }
          : item;
      });
    };

    updatedState.products = updateProducts(baseProducts, false);

    if (addons && addons.length > 0) {
      updatedState.addons = updateProducts(addons, true);
    }

    if (exchanges && exchanges.length > 0) {
      updatedState.exchanges = updateProducts(exchanges, true);
    }

    this.setState({
      ...updatedState
    });
  };

  calculateTotalPrice = () => {
    let totalPrice = 0;
    const { products: baseProducts, addons, exchanges } = this.state;
    const { maxOnlineLicense } = this.props;

    [baseProducts, addons, exchanges].forEach(products => {
      if (products && products.length > 0) {
        const currency = products[this.firstProductIndex].currency;

        products.forEach(
          ({ currency: productCurrency, licenseCount, price }) => {
            if (productCurrency === currency) {
              const selectedLicenseCount = Math.min(
                licenseCount,
                maxOnlineLicense
              );

              totalPrice = totalPrice + selectedLicenseCount * price;
            }
          }
        );
      }
    });

    return totalPrice;
  };

  captureCartSelection = () => {
    const totalPrice = this.calculateTotalPrice();

    const { products, addons, exchanges } = this.state;

    const currency = products[this.firstProductIndex].currency;

    const CartSelection = {
      "Cart Details": { products, addons, exchanges },
      "Total Price": `${totalPrice}${currency}`
    };

    if (this.props.captureCartSelection) {
      this.props.captureCartSelection(CartSelection);
    }
  };

  removeProduct = (productsType, productId) => {
    const updatedState = {};

    const updateProducts = products => {
      return products.filter(product => product.id !== productId);
    };

    updatedState[productsType] = updateProducts(this.state[productsType]);

    if (productsType === "products") {
      updatedState.addons = [];
      updatedState.exchanges = [];
    }

    this.setState({
      ...updatedState
    });

    if (this.props.captureRemoveProduct) {
      this.props.captureRemoveProduct(productId);
    }
  };

  render() {
    const {
      title,
      description,
      maxLicense,
      maxOnlineLicense,
      subTotalText,
      taxInfo,
      footerText,
      showButton,
      buttonText,
      buttonBlock,
      productValidity,
      licenseText,
      currency,
      subTotalInfo,
      productDetails,
      addonsDetails,
      exchangesDetails,
      emptyCartText,
      showRemoveLink,
      tooltipInfo,
      support
    } = this.props;

    const totalPrice = this.calculateTotalPrice();

    const { products, addons, exchanges } = this.state;

    const isEmpty = !(products && products.length > 0);

    const allProducts = [
      {
        productDetails,
        products,
        productsType: "products"
      },
      ...(addons.length > 0 && [
        {
          productDetails: addonsDetails,
          products: addons,
          productsType: "addons"
        }
      ]),
      ...(exchanges.length > 0 && [
        {
          productDetails: exchangesDetails,
          products: exchanges,
          productsType: "exchanges"
        }
      ])
    ];

    const productCurrency = isEmpty
      ? currency
      : products[this.firstProductIndex].currency;

    return (
      <section
        className={this.getClassName()}
        data-rehydratable={getRehydratableName(Cart.displayName)}
        data-cart-title={title}
        data-cart-description={description}
        data-max-license={maxLicense}
        data-max-online-license={maxOnlineLicense}
        data-total-text={subTotalText}
        data-tax-info={taxInfo}
        data-sub-total-info={subTotalInfo}
        data-footer-text={footerText}
        data-show-button={showButton}
        data-button-text={buttonText}
        data-button-block={buttonBlock}
        data-product-details={JSON.stringify(productDetails)}
        data-addons-details={JSON.stringify(addonsDetails)}
        data-exchanges-details={JSON.stringify(exchangesDetails)}
        data-empty-cart-text={emptyCartText}
        data-product-validity-text={productValidity}
        data-license-text={licenseText}
        data-currency={currency}
        data-show-remove-link={showRemoveLink}
        data-tooltip-info={tooltipInfo}
      >
        <header className={this.getClassName({ descendantName: "header" })}>
          <SVGIcon name={"cart-new"} size="m" fill="#4d4d4d" />
          <div
            className={this.getClassName({
              descendantName: "description",
              className: typestack("p1")
            })}
            aria-label={title}
          >
            <h3
              className={this.getClassName({
                descendantName: "title",
                className: typestack("h5")
              })}
            >
              {title}
            </h3>
            <p>{description}</p>
          </div>
        </header>
        {isEmpty && <EmptyCart emptyCartText={emptyCartText} />}
        {!isEmpty &&
          allProducts.map(product => (
            <List
              key={product.productsType}
              productDetails={product.productDetails}
              products={product.products}
              productsType={product.productsType}
              maxLicense={maxLicense}
              maxOnlineLicense={maxOnlineLicense}
              productValidity={productValidity}
              licenseText={licenseText}
              support={support}
              showRemoveLink={showRemoveLink}
              tooltipInfo={tooltipInfo}
              removeProduct={this.removeProduct}
              getTotalPrice={this.getTotalPrice}
            />
          ))}
        <div
          className={this.getClassName({
            descendantName: "subtotal",
            className: typestack("h3")
          })}
        >
          <h3>{subTotalText}</h3>
          <h3>
            {productCurrency}
            {totalPrice}
          </h3>
        </div>
        <div
          className={this.getClassName({
            descendantName: "subtotalinfo",
            className: typestack("p1")
          })}
        >
          <p>{subTotalInfo}</p>
          <p>{taxInfo}</p>
        </div>
        <footer>
          <p
            className={this.getClassName({
              descendantName: "footer",
              className: typestack("p1")
            })}
          >
            {footerText}
          </p>
          {showButton && (
            <Button
              color="primary"
              href="#"
              kind="link"
              block={buttonBlock}
              disabled={isEmpty}
              aria-labelledby={`${totalPrice} ${buttonText}`}
              onClick={this.captureCartSelection}
            >
              {buttonText}
            </Button>
          )}
        </footer>
      </section>
    );
  }
}

Cart.defaultProps = {
  showButton: true,
  buttonText: "Continue"
};

export default Cart;
