import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Typestack from "@emcm-ui/component-typestack";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const NoResults = () => {
  const getClassName = getClassNameFactory("SearchResults");

  return (
    <div className={getClassName({ descendantName: "noResults" })}>
      <Typestack align="left" rank="p" type="p1Bold">
        Suggestions
      </Typestack>
      <ul className={getClassName({ descendantName: "noResultsItems" })}>
        <li
          className={getClassName({
            descendantName: "noResultsItem",
            className: typestack("p1")
          })}
        >
          Check your spelling
        </li>
        <li
          className={getClassName({
            descendantName: "noResultsItem",
            className: typestack("p1")
          })}
        >
          Try fewer words
        </li>
        <li
          className={getClassName({
            descendantName: "noResultsItem",
            className: typestack("p1")
          })}
        >
          Try more general keywords
        </li>
      </ul>
    </div>
  );
};

NoResults.displayName = "SearchResults.NoResults";

export default NoResults;
