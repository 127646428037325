import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import Items from "./components/Items";
import GlobalNavDropdown from "./components/Dropdown/Dropdown";

class GlobalNav extends Component {
  static displayName = "GlobalNav";

  static propTypes = {
    /**
     * List of sites and url in key, value pairs
     */
    options: PropTypes.array,

    /**
     * Selected site index - starts from 0 to n
     */
    selected: PropTypes.number,

    /**
     * Title - on mobile view
     */
    title: PropTypes.string
  };

  getClassName = getClassNameFactory(GlobalNav.displayName);

  render() {
    const { options, selected, title } = this.props;

    return (
      <React.Fragment>
        <nav
          className={this.getClassName()}
          data-rehydratable={getRehydratableName(GlobalNav.displayName)}
          data-options={JSON.stringify(options)}
          data-selected={JSON.stringify(selected)}
          data-title={JSON.stringify(title)}
        >
          <Items options={options} selected={selected} />
          <GlobalNavDropdown
            options={options}
            selected={selected}
            title={title}
          />
        </nav>
      </React.Fragment>
    );
  }
}

GlobalNav.Items = Items;

export default GlobalNav;
