import React from "react";
import EventCard from "./EventCard";

// eslint-disable-next-line require-await
export default async domNode => {
  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data ? JSON.parse(data) : null;
  };

  const props = {
    borderVariant: domNode.getAttribute("data-border-variant"),
    calendarSubject: domNode.getAttribute("data-calendar-subject"),
    calendarStartDate: domNode.getAttribute("data-calendar-start-date"),
    calendarEndDate: domNode.getAttribute("data-calendar-end-date"),
    calendarLocation: domNode.getAttribute("data-calendar-location"),

    id: domNode.getAttribute("data-id"), // Google Map
    apiKey: domNode.getAttribute("data-api-key"), // Google Map
    latitude: Number(domNode.getAttribute("data-latitude")),
    longitude: Number(domNode.getAttribute("data-longitude")),
    zoom: Number(domNode.getAttribute("data-zoom")),
    height: Number(domNode.getAttribute("data-height")),
    width: Number(domNode.getAttribute("data-width")),

    block: readData("block"), // Calendar
    items: readData("items"), // Calendar
    placeholder: domNode.getAttribute("data-placeholder"), // Calendar
    placeholderIcon: domNode.getAttribute("data-placeholder-icon"), // Calendar

    eventSize: domNode.getAttribute("data-event-size"), // EventCardBase
    day: domNode.getAttribute("data-day"),
    date: domNode.getAttribute("data-date"),
    time: domNode.getAttribute("data-time"),
    orgName: domNode.getAttribute("data-org-name"),
    orgAddress: domNode.getAttribute("data-org-address"),
    orgCity: domNode.getAttribute("data-org-city"),
    clockIcon: domNode.getAttribute("data-clock-icon"),
    locationIcon: domNode.getAttribute("data-location-icon"),
    isGoogleMapBlocked: readData("is-google-map-blocked"),
    isEventOnline: readData("is-event-online"),
    eventOnlineDetails: readData("event-online-details")
  };

  return <EventCard {...props} />;
};
