import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const Duration = ({ children, duration, icon }) => {
  const getClassName = getClassNameFactory(Duration.displayName);

  return (
    <time
      className={getClassName({ className: typestack("p3") })}
      dateTime={duration}
    >
      <span className={getClassName({ descendantName: "icon" })}>{icon}</span>

      <span className={getClassName({ descendantName: "text" })}>
        {children}
      </span>
    </time>
  );
};

Duration.displayName = "Duration";

Duration.propTypes = {
  /**
   * The text to display inside this component.
   */
  children: PropTypes.string.isRequired,

  /**
   * The duration.
   *
   * Must be formatted according to the W3 specification: https://www.w3.org/TR/2014/REC-html5-20141028/infrastructure.html#valid-duration-string
   */
  duration: PropTypes.string.isRequired,
  /**
   * Icon.
   */
  icon: PropTypes.node
};

export default Duration;
