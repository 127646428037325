import React from "react";
import SearchInput from "./SearchInput";

// eslint-disable-next-line require-await
const rehydrator = async domNode => {
  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data ? JSON.parse(data) : null;
  };

  const name = readData("name");

  const value = readData("value");

  const autosuggestion = readData("autosuggestion");

  const size = readData("size");

  const searchMinLength = readData("search-min-length");

  const resetSearch = readData("reset-search");

  const searchSubmitAriaLabel = readData("search-submit-label");

  const searchClearAriaLabel = readData("clear-search-label");

  const props = {
    autoFocus: readData("auto-focus"),
    colorTheme: readData("color-theme"),
    name,
    placeholder: readData("placeholder"),
    typeahead: readData("typeahead"),
    typeaheadUrl: readData("typeahead-url"),
    queryParam: readData("query-param"),
    value,
    resetSearch,
    elasticKey: readData("elastic-key"),
    ...(searchSubmitAriaLabel && {
      searchSubmitAriaLabel
    }),
    ...(searchClearAriaLabel && {
      searchClearAriaLabel
    }),
    ...(searchMinLength && {
      searchMinLength
    }),
    ...(autosuggestion && {
      autosuggestion
    }),
    ...(size && {
      size
    })
  };

  return <SearchInput {...props} />;
};

export default rehydrator;
