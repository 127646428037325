import React from "react";
import AccessibilityLink from "./AccessibilityLink";

// eslint-disable-next-line require-await
const rehydrator = async domNode => {
  const props = {
    targetElement: domNode.getAttribute("data-target-element"),
    href: domNode.getAttribute("data-href"),
    label: domNode.getAttribute("data-label"),
    position: domNode.getAttribute("data-position"),
    backgroundType: domNode.getAttribute("data-background-type")
  };

  return <AccessibilityLink {...props} />;
};

export default rehydrator;
