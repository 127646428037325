import React from "react";
import { storiesOf } from "@storybook/react";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import SocialShare from "./index";
import "./SocialShare.css";
import SocialShareREADME from "../README.md";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import { getSocialIcon } from "./icons";

const stories = storiesOf("Components/SocialShare", module);

stories.addDecorator(withReadme(SocialShareREADME));

stories
  .add("Default", () => (
    <div style={{ display: "flex" }}>
      <SocialShare
        pageUrl="https://www.lseg.com"
        type="facebook"
        ariaLabel="view facebook profile"
      >
        <SVGIcon name="facebook" size="s" getExternalIconPath={getSocialIcon} />
      </SocialShare>

      <SocialShare
        pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
        pageUrl="https://www.lseg.com"
        type="twitter"
        ariaLabel="view twitter profile"
      >
        <SVGIcon name="twitter" size="s" getExternalIconPath={getSocialIcon} />
      </SocialShare>

      <SocialShare
        pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
        pageUrl="https://www.lseg.com"
        type="linkedIn"
        ariaLabel="view linkedIn profile"
      >
        <SVGIcon name="linkedin" size="s" getExternalIconPath={getSocialIcon} />
      </SocialShare>

      <SocialShare
        pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
        pageUrl="https://www.lseg.com"
        type="weibo"
        ariaLabel="view weibo profile"
      >
        <SVGIcon name="weibo" size="s" getExternalIconPath={getSocialIcon} />
      </SocialShare>

      <SocialShare
        pageUrl="https://www.lseg.com"
        type="whatsApp"
        ariaLabel="view whatsApp profile"
      >
        <SVGIcon name="whatsapp" size="s" getExternalIconPath={getSocialIcon} />
      </SocialShare>

      <SocialShare
        pageUrl="https://www.lseg.com"
        type="line"
        ariaLabel="view line profile"
      >
        <SVGIcon name="line" size="s" getExternalIconPath={getSocialIcon} />
      </SocialShare>

      <SocialShare
        pageUrl="https://www.lseg.com"
        type="mixi"
        ariaLabel="view mixi profile"
      >
        <SVGIcon name="mixi" size="s" getExternalIconPath={getSocialIcon} />
      </SocialShare>

      <SocialShare
        pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
        pageUrl="https://www.lseg.com"
        type="email"
        ariaLabel="view email"
      >
        <SVGIcon name="email" size="s" getExternalIconPath={getSocialIcon} />
      </SocialShare>

      <SocialShare type="print" arialLabel="Print this article">
        <SVGIcon name="print" size="s" getExternalIconPath={getSocialIcon} />
      </SocialShare>
    </div>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <SocialShare
        pageUrl="https://www.lseg.com"
        type="facebook"
        arial-label="Share this article in facebook"
      >
        <SVGIcon name="facebook" size="s" getExternalIconPath={getSocialIcon} />
      </SocialShare>
    </Language>
  ));
