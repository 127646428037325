import classNames from "classnames";
import React, { Component } from "react";
import PropTypes from "prop-types";
import AjaxContent from "@emcm-ui/component-ajax-content";
import getClassNameFactory from "@emcm-ui/utility-class-names";

import { Consumer as AjaxPanelConsumer } from "../ajaxPanelProvider";

class AjaxPanel extends Component {
  static propTypes = {
    /**
     * The contents of this panel, overides AJAX behaviour.
     */
    children: PropTypes.node,

    /**
     * The content location of this panel, to AJAX in content. If no children
     * passed, this content will be loaded in and rehydrated.
     */
    contentLocation: PropTypes.string,

    /**
     * The id of this panel. Should be unique on the current page.
     */
    id: PropTypes.string.isRequired,

    /**
     * Add an onChange callback. Called on loading new AJAX content
     */
    onChange: PropTypes.func,

    /**
     * Rehydration options.
     *
     * If unspecified, will use the default (empty) AjaxContent options.
     */
    reactFromMarkupOptions: PropTypes.object,

    /**
     * A list of rehydrators that are available. Used to rehydrate markup from
     * the server.
     *
     * If unspecified, will use the default (empty) AjaxContent rehydrators.
     */
    reactFromMarkupRehydrators: PropTypes.object,

    /**
     * The title of this panel, which will be shown in its associated tab.
     */
    tabTitle: PropTypes.string.isRequired
  };

  static displayName = "Ajax.Panel";

  /**
   * Get Panel props from DOM node, used by Tabs component rehydration.
   */
  static getPanelProps = async (domNode, rehydrateChildren, extra) => {
    const childrenNode = domNode.querySelector("[data-rehydratable-children]");

    // Note, other props are inferred from the TabList children

    return {
      children: childrenNode
        ? await rehydrateChildren(childrenNode, extra)
        : null,
      id: domNode.getAttribute("id"),
      tabTitle: domNode.getAttribute("data-tab-title"),
      contentLocation: domNode.getAttribute("data-content-location")
    };
  };

  render() {
    const {
      children,
      contentLocation,
      id,
      onChange,
      tabTitle,
      reactFromMarkupOptions,
      reactFromMarkupRehydrators
    } = this.props;
    const getClassName = getClassNameFactory("AjaxPanel");

    return (
      <AjaxPanelConsumer>
        {panel => (
          <article
            aria-labelledby={`panel-${id}`}
            className={getClassName({
              states: classNames({
                hidden: panel !== id
              })
            })}
            data-tab-title={tabTitle}
            hidden={panel !== id}
            id={id}
            role="tabpanel"
            tabIndex="-1"
            data-content-location={contentLocation}
          >
            {children && <div data-rehydratable-children>{children}</div>}
            {!children && (
              <AjaxContent
                failedContent="Failed to retrieve content"
                loadingLabel="Loading"
                location={panel === id ? contentLocation : null}
                onChange={onChange}
                reactFromMarkupOptions={reactFromMarkupOptions}
                reactFromMarkupRehydrators={reactFromMarkupRehydrators}
              />
            )}
          </article>
        )}
      </AjaxPanelConsumer>
    );
  }
}

export default AjaxPanel;
