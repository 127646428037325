import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import CardBase from "../../bases/CardBase";
import Eyebrow from "@emcm-ui/component-eyebrow";
import classNames from "classnames";

const QuoteCard = ({
  attribution,
  eyebrow,
  href,
  text,
  customer,
  borderVariant
}) => {
  const getClassName = getClassNameFactory(QuoteCard.displayName);

  return (
    <div
      className={getClassName({
        modifiers: classNames({
          customer,
          [borderVariant]: borderVariant
        })
      })}
    >
      <CardBase borderVariant={borderVariant} href={href} size="large">
        <div
          className={getClassName({
            descendantName: "content"
          })}
        >
          {eyebrow ? (
            <div
              className={getClassName({
                descendantName: "eyebrow"
              })}
            >
              <Eyebrow text={eyebrow} />
            </div>
          ) : null}

          <blockquote
            className={getClassName({
              descendantName: "quote",
              modifiers: classNames({
                customer
              })
            })}
          >
            {text}
          </blockquote>
          <div className={getClassName({ descendantName: "attribution" })}>
            {attribution}
          </div>
        </div>
      </CardBase>
    </div>
  );
};

QuoteCard.displayName = "QuoteCard";

QuoteCard.sizes = CardBase.sizes;

QuoteCard.borderVariant = [null, "borderless"];

/* eslint-disable max-len */
QuoteCard.propTypes = {
  /**
   * Attribution component for attributing the quotation
   */
  attribution: PropTypes.node.isRequired,
  /**
   * Card border variations
   */
  borderVariant: PropTypes.oneOf(QuoteCard.borderVariant),
  /**
   * Eyebrow text
   */
  eyebrow: PropTypes.string,
  /**
   * Card URL
   */
  href: PropTypes.string,
  /**
   * The quotation
   */
  text: PropTypes.string.isRequired,
  /**
   * Optional customer variant
   */
  customer: PropTypes.bool
};
/* eslint-enable max-len */

QuoteCard.defaultProps = {
  borderVariant: null,
  size: "large",
  customer: false
};

export default QuoteCard;
