/* eslint-disable no-magic-numbers */
import React from "react";
import { storiesOf } from "@storybook/react";
import {
  withKnobs,
  number,
  boolean,
  select,
  text
} from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import EventCard from "./index";
import "./EventCard.css";

import CardREADME from "../../../../README.md";

const stories = storiesOf("Components/Card/Cards/EventCard", module);

const getQueryParamValByName = param => {
  const params = new URLSearchParams(window.top.document.location.search);

  if (params.get(param) === null) {
    return "";
  }

  return params.get(param);
};

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(CardREADME));

const icecreamFlavoursWithIcons = [
  {
    value: "google",
    label: "Google Calendar",
    icon: "./icons/logos/google.svg"
  },
  {
    value: "apple",
    label: "Apple Calendar",
    icon: "./icons/logos/apple.svg"
  },
  {
    value: "outlook",
    label: "Outlook Calendar",
    icon: "./icons/logos/outlook.svg"
  },
  {
    value: "ical",
    label: "Download Ical",
    icon: "./icons/ui/grey/calendar-07.svg"
  }
];

const arabicCalendar = [
  {
    value: "google",
    label: "تقويم جوجل",
    icon: "./icons/logos/google.svg"
  },
  {
    value: "apple",
    label: "تقويم التفاح",
    icon: "./icons/logos/apple.svg"
  },
  {
    value: "outlook",
    label: "تقويم التوقعات",
    icon: "./icons/logos/outlook.svg"
  },
  {
    value: "ical",
    label: "تحميل Ical",
    icon: "./icons/ui/grey/calendar-07.svg"
  }
];

const eventSizeOptions = ["small", "medium", "large"];
const clockIcon = "./icons/ui/grey/time-07.svg";
const locationIcon = "./icons/ui/grey/location.svg";

stories
  .add("Default", () => (
    <div style={{ maxWidth: 360 }}>
      <EventCard
        borderVariant={select("borderVariant", EventCard.borderVariant, null)} // Google Map
        id="myMap" // Google Map
        apiKey={text("apiKey", getQueryParamValByName("apiKey"))} // Google Map
        latitude={number("latitude", 51.507032)} // Google Map
        longitude={number("longitude", -0.003396)} // Google Map
        zoom={number("zoom", 12)} // Google Map
        block={boolean("Block", true)} // Calendar
        items={icecreamFlavoursWithIcons} // Calendar
        placeholder={text("Placeholder", "Add to Calendar")} // Calendar
        placeholderIcon="./icons/ui/grey/calendar-07.svg" // Calendar
        eventSize={select("eventSize", eventSizeOptions, "small")} // EventCard
        day={text("day", "Thursday")} // EventCard
        date={text("date", "21 March 2019")} // EventCard
        time={text("time", "13:30 to 16:00 CDT")} // EventCard
        orgName={text("orgName", "Thomson Reuters")} // EventCard
        orgAddress={text("orgAddress", "30 South Colonnnade, Canary Wharf")} // EventCard
        orgCity={text("orgCity", "London")} // EventCard
        clockIcon={clockIcon} // EventCard
        locationIcon={locationIcon} // EventCard
        calendarSubject="Sample Configurable Subject" // EventCard
        calendarStartDate="20170324T193000Z" // EventCard
        calendarEndDate="20170324T203000Z" // EventCard
        calendarLocation="Zurich, Switzerland" // EventCard
        isGoogleMapBlocked={boolean("isGoogleMapBlocked", false)} // EventCard
        isEventOnline={boolean("Is Event Online", false)}
        eventOnlineDetails={{
          label: "Online Event",
          icon: "./icons/ui/grey/computer.svg",
          banner: "./test-images/eventCard/banner.png"
        }}
      />
    </div>
  ))
  .add("Arabic", () => (
    <div style={{ maxWidth: 360 }}>
      <Language code="ar">
        <EventCard
          borderVariant={select("borderVariant", EventCard.borderVariant, null)}
          id="myMap"
          apiKey={text("apiKey", getQueryParamValByName("apiKey"))} // Google Map
          latitude={number("latitude", 51.507032)}
          longitude={number("longitude", -0.003396)}
          zoom={number("zoom", 12)}
          block={boolean("Block", true)}
          items={arabicCalendar}
          placeholder={text("Placeholder", "إضافة إلى التقويم")}
          placeholderIcon="./icons/ui/grey/calendar-07.svg"
          eventSize={select("eventSize", eventSizeOptions, "small")}
          day={text("day", "الخميس")}
          date={text("date", "21 مارس 2019")}
          time={text("time", "13:30 حتى 16:00 CDT")}
          orgName={text("orgName", "طومسون رويترز ")}
          orgAddress={text("orgAddress", "٣٠ - الرواق الجنوبي ، كناري وارف")}
          orgCity={text("orgCity", "لندن")}
          clockIcon={clockIcon}
          locationIcon={locationIcon}
          calendarSubject="Sample Configurable Subject" // EventCard
          calendarStartDate="20170324T193000Z" // EventCard
          calendarEndDate="20170324T203000Z" // EventCard
          calendarLocation="Zurich, Switzerland" // EventCard
          isEventOnline={boolean("Is Event Online", false)}
          eventOnlineDetails={{
            label: "حدث عبر الإنترنت",
            icon: "./icons/ui/grey/computer.svg",
            banner: "./test-images/eventCard/banner.png"
          }}
        />
      </Language>
    </div>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <div style={{ maxWidth: 360 }}>
        <EventCard
          borderVariant={select("borderVariant", EventCard.borderVariant, null)} // Google Map
          id="myMap" // Google Map
          apiKey={text("apiKey", getQueryParamValByName("apiKey"))} // Google Map
          latitude={number("latitude", 51.507032)} // Google Map
          longitude={number("longitude", -0.003396)} // Google Map
          zoom={number("zoom", 12)} // Google Map
          block={boolean("Block", true)} // Calendar
          items={icecreamFlavoursWithIcons} // Calendar
          placeholder={text("Placeholder", "Add to Calendar")} // Calendar
          placeholderIcon="./icons/ui/grey/calendar-07.svg" // Calendar
          eventSize={select("eventSize", eventSizeOptions, "small")} // EventCard
          day={text("day", "Thursday")} // EventCard
          date={text("date", "21 March 2019")} // EventCard
          time={text("time", "13:30 to 16:00 CDT")} // EventCard
          orgName={text("orgName", "Thomson Reuters")} // EventCard
          orgAddress={text("orgAddress", "30 South Colonnnade, Canary Wharf")} // EventCard
          orgCity={text("orgCity", "London")} // EventCard
          clockIcon={clockIcon} // EventCard
          locationIcon={locationIcon} // EventCard
          calendarSubject="Sample Configurable Subject" // EventCard
          calendarStartDate="20170324T193000Z" // EventCard
          calendarEndDate="20170324T203000Z" // EventCard
          calendarLocation="Zurich, Switzerland" // EventCard
          isEventOnline={boolean("Is Event Online", false)}
          eventOnlineDetails={{
            label: "Online Event",
            icon: "./icons/ui/grey/computer.svg",
            banner: "./test-images/eventCard/banner.png"
          }}
        />
      </div>
    </StaticRenderer>
  ));
