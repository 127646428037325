import React from "react";

import Attribution from "@emcm-ui/component-attribution";
import {
  TextCard,
  ImageCard,
  PairedCard,
  PairedHorizontalCard,
  TwitterCard,
  QuoteCard,
  ArticleCardFooter,
  LinkCardFooter,
  VideoCardFooter
} from "@emcm-ui/component-card";
import Eyebrow from "@emcm-ui/component-eyebrow";
import Grid from "@emcm-ui/component-grid";
import Header from "@emcm-ui/component-header";
import Heading from "@emcm-ui/component-heading";
import Link from "@emcm-ui/component-link";
import Icon from "@emcm-ui/component-icon";
import ListOfLinks from "@emcm-ui/component-list-of-links";
import MarkupContainer from "@emcm-ui/component-markup-container";
import Poster from "@emcm-ui/component-poster";
import ProfileGallery from "@emcm-ui/component-profile-gallery";
import RichText from "@emcm-ui/component-rich-text";
import Section from "@emcm-ui/component-section";
import SemanticMain from "@emcm-ui/component-semantic-main";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";

import SampleSchemaOrganization from "../../fragments/schema-organization";
import SampleFooter from "../../fragments/footer";
import SampleHeader from "../../fragments/header";

import articleHeadshot from "../../sampleAssets/Article-headshot.jpg";
import articleLadyJustice from "../../sampleAssets/Article-lady-justice.jpg";
import articleLadyJusticeMobile from "../../sampleAssets/Article-lady-justice.mob.jpg";

import imgLarge1 from "../../sampleAssets/large_1.1x.jpg";
import imgLarge2 from "../../sampleAssets/large_2.1x.jpg";
import imgLarge3 from "../../sampleAssets/large_3.1x.jpg";
import imgLarge4 from "../../sampleAssets/large_4.1x.jpg";
import imgLarge5 from "../../sampleAssets/large_5.1x.jpg";

const Icons = {
  arrowRightBlue: "./icons/ui/blue/arrow-right.svg",
  arrowRightWhite: "./icons/ui/white/arrow-right.svg",
  time: "./icons/ui/grey/time-07.svg",
  timeWhite: "./icons/ui/white/time.svg",
  plusBlue: "./icons/ui/blue/plus.svg",

  playoutline: "./icons/ui/grey/playoutline-07.svg",

  home: "./icons/nucleo/energy-environment/small/home.svg",
  atom: "./icons/nucleo/healthcare-medical/small/atom.svg",
  carFront: "./icons/nucleo/transportation/small/car-front.svg",
  flaskConical: "./icons/nucleo/healthcare-medical/small/flask-conical.svg"
};

const Industries = () => {
  return (
    <div>
      <SampleSchemaOrganization />
      <SampleHeader />

      <SemanticMain>
        <Section background="grayDark" fullWidth>
          <Poster
            height="s"
            imageOverlay="scrimBottomDark"
            imageSrc={articleLadyJustice}
            imageSrcSet={`${articleLadyJusticeMobile} 768w, ${articleLadyJustice} 2048w`}
          >
            <Grid variant="7/12,5/12" gutterlessAtNarrow>
              <Grid.Item>
                <VerticalSpacing>
                  <Heading type="xl" rank="1">
                    <strong>Legal</strong>
                  </Heading>
                </VerticalSpacing>
                <p>
                  Providing unrivaled services and solutions that integrate
                  content, expertise and&nbsp;technologies
                </p>
              </Grid.Item>
            </Grid>
          </Poster>
        </Section>
        <Section background="grayLightest">
          <VerticalSpacing size="xl">
            <Header
              heading={
                <Heading rank="2" type="l">
                  Whatever your needs, we have the <b>answers</b>
                </Heading>
              }
              callToAction={
                <Link
                  href="https://example.com"
                  type="icon"
                  icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  iconPosition={"right"}
                >
                  All legal products
                </Link>
              }
            />
          </VerticalSpacing>
          <Grid variant="1/4,3/4">
            <Grid.Item>
              <VerticalSpacing>
                <Eyebrow text="Legal Solutions by Category" />
              </VerticalSpacing>
              <ListOfLinks>
                <ListOfLinks.BlockLink
                  size="small"
                  href="https://www.example.com"
                >
                  Law firm practice & management
                </ListOfLinks.BlockLink>
                <ListOfLinks.BlockLink
                  size="small"
                  href="https://www.example.com"
                >
                  Legal education
                </ListOfLinks.BlockLink>
                <ListOfLinks.BlockLink
                  size="small"
                  href="https://www.example.com"
                >
                  Corporate & government
                </ListOfLinks.BlockLink>
                <ListOfLinks.BlockLink
                  size="small"
                  href="https://www.example.com"
                >
                  Law firm business development
                </ListOfLinks.BlockLink>
                <ListOfLinks.BlockLink
                  size="small"
                  href="https://www.example.com"
                >
                  Legal solutions by country
                </ListOfLinks.BlockLink>
              </ListOfLinks>
            </Grid.Item>

            <Grid.Item>
              <VerticalSpacing>
                <Eyebrow text="Featured Legal Products" />
              </VerticalSpacing>
              <Grid variant="even-3">
                <Grid.Item>
                  <TextCard
                    description="Maximize your legal research with depth, accuracy, and speed"
                    eyebrow="Research"
                    footer={
                      <LinkCardFooter
                        title="Product detail"
                        type="icon"
                        icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                      />
                    }
                    heading="Westlaw"
                    href="https://www.example.com"
                    size="medium"
                    borderless
                  />
                </Grid.Item>
                <Grid.Item>
                  <TextCard
                    description="Meet a fresh challenge quickly with a wealth of practical resources at your fingertips"
                    eyebrow="Know-how"
                    footer={
                      <LinkCardFooter
                        title="Product detail"
                        type="icon"
                        icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                      />
                    }
                    heading="Practical Law"
                    href="https://www.example.com"
                    size="medium"
                    borderVariant="borderless"
                  />
                </Grid.Item>
                <Grid.Item>
                  <TextCard
                    description="Powerful financial and practice management features and built-in application development capabilities"
                    eyebrow="Management"
                    footer={
                      <LinkCardFooter
                        title="Product detail"
                        type="icon"
                        icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                      />
                    }
                    heading="Elite 3E"
                    href="https://www.example.com"
                    size="medium"
                    borderVariant="borderless"
                  />
                </Grid.Item>
                <Grid.Item>
                  <TextCard
                    description="Capture, track, process, and report on cases from filing through disposition"
                    eyebrow="Management"
                    footer={
                      <LinkCardFooter
                        title="Product detail"
                        type="icon"
                        icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                      />
                    }
                    heading="C-Track Court Case Management Law"
                    href="https://www.example.com"
                    size="medium"
                    borderVariant="borderless"
                  />
                </Grid.Item>
                <Grid.Item>
                  <TextCard
                    description="Next-generation online investigative platform designed specifically to meet unique needs of investigative customers"
                    eyebrow="Investigation"
                    footer={
                      <LinkCardFooter
                        title="Product detail"
                        type="icon"
                        icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                      />
                    }
                    heading="CLEAR"
                    href="https://www.example.com"
                    size="medium"
                    borderVariant="borderless"
                  />
                </Grid.Item>
              </Grid>
            </Grid.Item>
          </Grid>
        </Section>

        <Section>
          <VerticalSpacing size="xl">
            <Header
              heading={
                <Heading rank="2" type="l">
                  Our <strong>legal</strong> products in action
                </Heading>
              }
              callToAction={
                <Link
                  href="https://example.com"
                  type="icon"
                  icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  iconPosition={"right"}
                >
                  All legal case studies
                </Link>
              }
            />
          </VerticalSpacing>
          <Grid variant="2/3,1/3">
            <Grid.Item>
              <ImageCard
                href="https://www.example.com"
                size="large"
                eyebrow="Case Study"
                heading="How Practical Law helped in-house lawyers at Beam Inc."
                footer={
                  <ArticleCardFooter
                    dateTime="2016-02-28 13:57"
                    readingTime="6 min"
                    icon={<Icon path={Icons.timeWhite} size="xs" />}
                  />
                }
                imgSrc={imgLarge1}
                imgAnchor="C"
                imgGradient
                largeHeading
              />
            </Grid.Item>
            <Grid.Item>
              <QuoteCard
                attribution={
                  <Attribution
                    imgSrc={articleHeadshot}
                    name="Deborah K."
                    caption="City attorney"
                  />
                }
                eyebrow="Testimonials"
                text="When you've got a research tool like Westlaw, there is no reason not to be complete and thorough in your research."
                href="https://www.example.com"
              />
            </Grid.Item>
            <Grid.Item>
              <PairedHorizontalCard
                eyebrow="Article"
                footer={
                  <ArticleCardFooter
                    attributionName="Sophie P."
                    dateTime="2017-03-07 13:57"
                    readingTime="1 min"
                    icon={<Icon path={Icons.time} size="xs" />}
                  />
                }
                heading="Expert tips for getting the most out of Westlaw"
                href="https://www.example.com"
                imgAnchor="C"
                imgSrc={imgLarge4}
              />
            </Grid.Item>
            <Grid.Item>
              <ImageCard
                href="https://www.example.com"
                size="small"
                eyebrow="Case study"
                heading="How CLEAR® is helping Miami investigate crime"
                imgSrc={imgLarge5}
                imgAnchor="C"
                imgGradient
              />
            </Grid.Item>
          </Grid>
        </Section>

        <Section background="grayLightest">
          <VerticalSpacing size="xl">
            <Header
              heading={
                <Heading rank="2" type="l">
                  The expertise you need to find{" "}
                  <strong>trusted answers</strong>
                </Heading>
              }
              callToAction={
                <Link
                  href="https://example.com"
                  type="icon"
                  icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  iconPosition={"right"}
                >
                  Answers On
                </Link>
              }
            />
          </VerticalSpacing>
          <Grid variant="even-3">
            <Grid.Item>
              <PairedCard
                borderVariant="borderless"
                eyebrow="Enterprise Risk Management"
                footer={
                  <VideoCardFooter
                    runningTime="1 min"
                    icon={<Icon path={Icons.playoutline} size="xs" />}
                  />
                }
                heading="One global organization’s risk exposure to slavery"
                href="https://www.example.com"
                imgAnchor="C"
                imgSrc={imgLarge2}
              />
            </Grid.Item>
            <Grid.Item>
              <PairedCard
                borderVariant="borderless"
                eyebrow="Enterprise Risk Management"
                footer={
                  <ArticleCardFooter
                    attributionName="Sophie P."
                    dateTime="2017-02-05 13:00"
                    readingTime="5 min"
                    icon={<Icon path={Icons.time} size="xs" />}
                  />
                }
                heading="Mercury rising. The heavy social and environmental costs of illegal mining"
                href="https://www.example.com"
                imgAnchor="C"
                imgSrc={imgLarge3}
              />
            </Grid.Item>
            <Grid.Item>
              <MarkupContainer>
                <TwitterCard
                  borderVariant="borderless"
                  timelineDataSource={{
                    sourceType: "profile",
                    screenName: "westlaw"
                  }}
                  eyebrow="Featured tweets from @westlaw"
                />
              </MarkupContainer>
            </Grid.Item>
          </Grid>
        </Section>
        <Section background="grayLighter">
          <ProfileGallery
            introduction={
              <span>
                Our <strong>legal experts</strong> have practiced at the world’s
                leading law firms and in-house departments. Now their job is to
                make you better at yours.
              </span>
            }
            linkHref="https://www.example.com"
            linkTitle="Meet the team"
            linkIcon={<Icon path={Icons.arrowRightBlue} size="s" />}
            verticalAtDesktop
          >
            <ProfileGallery.Profile
              name="James G."
              title="Managing director of corporate law"
              location="New York"
              imgSrc={articleHeadshot}
            />
            <ProfileGallery.Profile
              name="Amanda P."
              title="Executive editor, tax"
              location="Pheonix"
              imgSrc={articleHeadshot}
            />
            <ProfileGallery.Profile
              name="David R."
              title="Alliance partnership specialist"
              location="Mumbai"
              imgSrc={articleHeadshot}
            />
            <ProfileGallery.Profile
              name="Gina L."
              title="Director, talent management"
              location="New York"
              imgSrc={articleHeadshot}
            />
          </ProfileGallery>
        </Section>
        <Section>
          <VerticalSpacing size="xl">
            <Heading rank="2" type="m">
              We’re hiring in <strong>New York</strong>
            </Heading>
          </VerticalSpacing>
          <Grid variant="1/3,2/3">
            <Grid.Item>
              <VerticalSpacing>
                <Heading rank="3" type="s">
                  Bring your passion
                </Heading>
              </VerticalSpacing>
              <VerticalSpacing>
                <RichText>
                  Our <strong>legal experts</strong> have practiced at the
                  world’s leading law firms and in-house departments. Now their
                  job is to make you better at yours.
                </RichText>
              </VerticalSpacing>
              <VerticalSpacing>
                <Link
                  href="https://example.com"
                  type="icon"
                  icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  iconPosition={"right"}
                >
                  Our company culture
                </Link>
              </VerticalSpacing>
            </Grid.Item>

            <Grid.Item>
              <VerticalSpacing>
                <Header
                  heading={
                    <Heading rank="3" type="s">
                      Join our team
                    </Heading>
                  }
                  callToAction={
                    <Link
                      href="https://example.com"
                      type="icon"
                      icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                      iconPosition={"right"}
                    >
                      All job openings
                    </Link>
                  }
                />
              </VerticalSpacing>
              <ListOfLinks columns={2}>
                <ListOfLinks.Column>
                  <ListOfLinks.BlockLink
                    size="small"
                    href="https://www.example.com"
                    description="New York  •  3 openings"
                  >
                    Legal product developer
                  </ListOfLinks.BlockLink>
                  <ListOfLinks.BlockLink
                    size="small"
                    href="https://www.example.com"
                    description="New York  •  1 opening"
                  >
                    Sales specialist, legal information solutions (graduate)
                  </ListOfLinks.BlockLink>
                  <ListOfLinks.BlockLink
                    size="small"
                    href="https://www.example.com"
                    description="New York  •  2 openings"
                  >
                    Legal document automation consultant
                  </ListOfLinks.BlockLink>
                </ListOfLinks.Column>
                <ListOfLinks.Column>
                  <ListOfLinks.BlockLink
                    size="small"
                    href="https://www.example.com"
                    description="New York  •  3 openings"
                  >
                    Industry practice group market lead, legal
                  </ListOfLinks.BlockLink>
                  <ListOfLinks.BlockLink
                    size="small"
                    href="https://www.example.com"
                    description="New York  •  1 opening"
                  >
                    Product manager (legal subscriptions portfolio manager -
                    primary law)
                  </ListOfLinks.BlockLink>
                  <ListOfLinks.BlockLink
                    size="small"
                    href="https://www.example.com"
                    description="New York  •  2 openings"
                  >
                    Legal product developer - Westlaw
                  </ListOfLinks.BlockLink>
                </ListOfLinks.Column>
              </ListOfLinks>
            </Grid.Item>
          </Grid>
        </Section>
      </SemanticMain>

      <SampleFooter />
    </div>
  );
};

export default Industries;
