import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const Eyebrow = ({ text }) => {
  const getClassName = getClassNameFactory(Eyebrow.displayName);

  return (
    <p className={getClassName({ className: typestack("subline1") })}>{text}</p>
  );
};

Eyebrow.displayName = "Eyebrow";

/* eslint-disable max-len */
Eyebrow.propTypes = {
  /**
   * The text to display in the eyebrow.
   */
  text: PropTypes.string.isRequired
};
/* eslint-enable max-len */

export default Eyebrow;
