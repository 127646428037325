import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import Datamap from "./index";
import "./Datamap.css";
import DatamapREADME from "../README.md";

const stories = storiesOf("Components/Datamap", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(DatamapREADME));

stories
  .add("Default", () => <Datamap />)
  .add("Loading datamaps with pre-filled data", () => {
    const data = {
      RUS: { fillKey: "mt100" },
      PRK: { fillKey: "btw51N100" },
      PRC: { fillKey: "btw21N50" },
      IND: { fillKey: "mt100" },
      GBR: { fillKey: "btw21N50" },
      FRA: { fillKey: "btw51N100" },
      PAK: { fillKey: "btw0N20" },
      USA: { fillKey: "mt100" }
    };
    const fills = {
      btw0N20: "#ccd2ff",
      btw21N50: "#6678ff",
      btw51N100: "#001eff",
      mt100: "#001299",
      fillHighlight: "#ff5900",
      defaultFill: "#cfcfcf"
    };
    const legendProps = {
      defaultFillName: "",
      legendLabels: {
        btw0N20: "0-20",
        btw21N50: "21-50",
        btw51N100: "51-100",
        mt100: "100+"
      },
      legendTitle: "legend Title",
      legendSubTitle: "legend SubTitle"
    };

    return (
      <Datamap
        data={data}
        fills={fills}
        fillKey="fillHighlight"
        legendProps={legendProps}
      />
    );
  })
  .add("Rehydrated", () => (
    <StaticRenderer>
      <Datamap />
    </StaticRenderer>
  ));
