import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import BaseItem from "./BaseItem";

export default async (domNode, rehydrateChildren) => {
  const getClassName = getClassNameFactory("GlobalHeaderBaseItem");
  const getHeaderClassName = getClassNameFactory("GlobalHeader");

  const childrenNode = domNode.querySelector(
    `.${getClassName({ descendantName: "contentInner" })}`
  );

  const parentNode = document.querySelector(`.${getHeaderClassName()}`);

  const reactifiedChildren = childrenNode
    ? await rehydrateChildren(childrenNode)
    : null;

  const iconNode = domNode.querySelector(
    `.${getClassName({ descendantName: "linkIcon" })}`
  );

  const reactifiedIcon = iconNode ? await rehydrateChildren(iconNode) : null;

  const narrow = domNode.getAttribute("data-narrow");

  const rehydratedProps = {
    icon: reactifiedIcon,
    href: domNode.getAttribute("data-href"),
    label: domNode.getAttribute("data-label"),
    longLabel: domNode.getAttribute("data-long-label"),
    gradientBackground: Boolean(
      parentNode && parentNode.getAttribute("data-gradient-background")
    ),
    narrow: narrow === "true"
  };

  return <BaseItem {...rehydratedProps}>{reactifiedChildren}</BaseItem>;
};
