import React from "react";
import PropTypes from "prop-types";
import { storiesOf } from "@storybook/react";
import { withKnobs, object } from "@storybook/addon-knobs";
import { action } from "@storybook/addon-actions";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import Filters from "./index";
import "./Filters.css";
import SearchResultsMixtureREADME from ".../../../README.md";

const stories = storiesOf(
  "Components/SearchMixedCard/Components/Filters",
  module
);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(SearchResultsMixtureREADME));

const allFilters = [
  {
    link: "",
    name: "all",
    title: "All"
  },
  {
    link: "?sort=relevance&x1=w_themes_id&q1=theme:funds-and-etfs",
    name: "theme:funds-and-etfs",
    title: "Funds and ETFs"
  },
  {
    link: "?sort=relevance&x1=w_themes_id&q1=theme:innovation-and-trends",
    name: "theme:innovation-and-trends",
    title: "Innovation and Trends"
  }
];

class Demo extends React.Component {
  static propTypes = {
    /**
     * filters object
     */
    filters: PropTypes.object,
    /**
     * onSelect function
     */
    onSelect: PropTypes.func
  };
  constructor(props) {
    super(props);

    this.state = {
      activeFilter: ""
    };
  }

  render() {
    const { filters, onSelect } = this.props;
    const { activeFilter } = this.state;
    const search = {
      hasSearchTerm: false
    };
    const filterSetting = {
      filters,
      activeFilter,
      onFilterSelect: response => {
        this.setState({
          activeFilter: response.activeFilter
        });
        onSelect(response);
      }
    };

    return <Filters filterSetting={filterSetting} search={search} />;
  }
}

stories
  .add("Default", () => (
    <Demo
      filters={object("Filters", allFilters)}
      onSelect={action("Filter clicked")}
    />
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Demo
        filters={object("Filters", allFilters)}
        onSelect={action("Filter clicked")}
      />
    </Language>
  ));
