export default function nextTriggerOnMount({
  rect,
  mode,
  position,
  direction,
  width
}) {
  if (mode !== "chain") {
    return false;
  }
  switch (direction) {
    case "toRight":
      return position.from > 0;
    case "toLeft":
    default: {
      const isElementInsideViewPort = rect.width + position.from <= width;

      return isElementInsideViewPort;
    }
  }
}
