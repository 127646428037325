import React from "react";
import AppNav from "./AppNav";

export default async (domNode, rehydrateChildren) => {
  const childrenNode = domNode.querySelector("[data-rehydratable-children]");
  const reactifiedChildren = await rehydrateChildren(childrenNode);
  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data ? JSON.parse(data) : null;
  };

  const props = {
    isRehydrating: true,
    items: readData("items")
  };

  return <AppNav {...props}>{reactifiedChildren}</AppNav>;
};
