import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

const ControlButton = ({
  backgroundType,
  handleClick,
  direction,
  ariaLabel
}) => {
  const getClassName = getClassNameFactory(ControlButton.displayName);
  const transform = direction === "next" ? "rotate(-90deg)" : "rotate(90deg)";

  return (
    <button
      id={`control-button-${direction}`}
      className={getClassName({
        modifiers: classNames(backgroundType)
      })}
      onClick={handleClick}
      aria-label={ariaLabel}
    >
      <SVGIcon
        name={"caret"}
        style={{ transform, width: "28px", height: "28px" }}
      />
    </button>
  );
};

ControlButton.propTypes = {
  /**
   * Function to handle the onClick
   */
  handleClick: PropTypes.func.isRequired,
  /**
   * Defines background color
   */
  backgroundType: PropTypes.string,
  /**
   * To define direction of nav button "next" or "prev"
   */
  direction: PropTypes.string,
  /**
   * To define the aria label for the button
   */
  ariaLabel: PropTypes.string
};

ControlButton.displayName = "ControlButton";

export default ControlButton;
