import React from "react";
import PropTypes from "prop-types";

import getClassNameFactory from "@emcm-ui/utility-class-names";
import Typestack from "@emcm-ui/component-typestack";
import Badge from "@emcm-ui/component-badge";
import Button from "@emcm-ui/component-button";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

const AddonsAndExchanges = ({
  title,
  buttonGroupAriaLabel,
  showBadge,
  badgeLabel,
  titleRank,
  priceRank,
  description,
  price,
  priceDescription,
  primaryButtonProps,
  secondaryButtonProps
}) => {
  const getClassName = getClassNameFactory(AddonsAndExchanges.displayName);

  const Heading = titleRank;

  const renderButton = ({ kind, label, action, href, showIcon }) =>
    label && (
      <Button
        block={false}
        color={"secondary"}
        kind={kind}
        type="pill"
        ariaLabel={`${label} ${title}`}
        {...showIcon && {
          icon: <SVGIcon name="plus" size="s" viewBox="0 0 20 20" />
        }}
        {...kind === "button" && { onClick: action }}
        {...kind === "link" && { href }}
      >
        {label}
      </Button>
    );

  return (
    <div className={getClassName()}>
      <div className={getClassName({ descendantName: "group" })}>
        <Heading
          className={getClassName({
            descendantName: "title",
            className: typestack("h5")
          })}
        >
          {title}
        </Heading>
        {showBadge && (
          <Badge
            text={badgeLabel}
            type="badge"
            color="blue"
            background="dark"
          />
        )}
      </div>
      <p
        className={getClassName({
          descendantName: "description",
          className: typestack("p1")
        })}
      >
        {description}
      </p>
      <div className={getClassName({ descendantName: "price" })}>
        <Typestack align="left" rank={priceRank} type={"h4"}>
          {price}
        </Typestack>
        <Typestack align="left" rank="p" type="p3">
          {priceDescription}
        </Typestack>
      </div>
      <div
        role="group"
        aria-label={buttonGroupAriaLabel}
        className={getClassName({ descendantName: "buttons" })}
      >
        {renderButton(primaryButtonProps)}
        {renderButton(secondaryButtonProps)}
      </div>
    </div>
  );
};

AddonsAndExchanges.displayName = "AddonsAndExchanges";

AddonsAndExchanges.defaultProps = {
  titleRank: "h3",
  priceRank: "h5"
};

AddonsAndExchanges.propTypes = {
  /**
   * Title for the addon or exchange card
   */
  title: PropTypes.string,
  /**
   * Aria-label for the addon or exchange button group card.
   */
  buttonGroupAriaLabel: PropTypes.string,
  /**
   * To show or hide badge
   */
  showBadge: PropTypes.bool,
  /**
   * Badge label
   */
  badgeLabel: PropTypes.string,
  /**
   * Heading rank for the title
   */
  titleRank: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5"]),
  /**
   * Description for the addon or exchange
   */
  description: PropTypes.string,
  /**
   * Price of the addon or exchange
   */
  price: PropTypes.string,
  /**
   * Heading rank for the price header
   */
  priceRank: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5"]),
  /**
   * Description for the price
   */
  priceDescription: PropTypes.string,
  /**
   * Primary button props which includes kind ["button" or "link"], label, if its button action, if its link href
   */
  primaryButtonProps: PropTypes.shape({
    kind: PropTypes.oneOf(["button", "link"]),
    href: PropTypes.string,
    action: PropTypes.func,
    label: PropTypes.string,
    showIcon: PropTypes.bool
  }),
  /**
   * Secondary button props which includes kind ["button" or "link"], label, if its button action, if its link href
   */
  secondaryButtonProps: PropTypes.shape({
    kind: PropTypes.oneOf(["button", "link"]),
    href: PropTypes.string,
    action: PropTypes.func,
    label: PropTypes.string,
    showIcon: PropTypes.bool
  })
};

export default AddonsAndExchanges;
