import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text, boolean, number } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import ProfileGallery from "./index";
import Section from "@emcm-ui/component-section";
import "./ProfileGallery.css";
import ProfileGalleryREADME from "../README.md";
import Icon from "@emcm-ui/component-icon";

const stories = storiesOf("Components/ProfileGallery", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(ProfileGalleryREADME));

const defaultNumberOfProfiles = 4;
const extendedNumberOfProfiles = 7;

stories
  .add("Default", () => (
    <Section>
      <ProfileGallery
        introduction={
          <span>
            Our <strong>legal experts</strong> have practiced at the world’s
            leading law firms and in-house departments. Now their job is to make
            you better at yours.
          </span>
        }
        linkHref="https://www.example.com"
        linkTitle="Meet the team"
        linkIcon={<Icon path="./icons/ui/blue/arrow-right.svg" size="s" />}
        verticalAtDesktop={boolean("verticalAtDesktop", true)}
      >
        {Array(number("numberOfProfiles", defaultNumberOfProfiles))
          .fill()
          .map((result, i) => (
            <ProfileGallery.Profile
              href={
                boolean("Use link", false) ? "https://www.example.com" : null
              }
              name={text("name", "Casey S.")}
              title={text("title", "Managing director of corporate law")}
              location={text("location", "New York")}
              imgSrc={text("imgSrc", "headshot.jpg")}
              key={i}
            />
          ))}
      </ProfileGallery>
    </Section>
  ))
  .add("Without Introduction", () => (
    <Section>
      <ProfileGallery verticalAtDesktop={boolean("verticalAtDesktop", true)}>
        {Array(number("numberOfProfiles", defaultNumberOfProfiles))
          .fill()
          .map((result, i) => (
            <ProfileGallery.Profile
              href={
                boolean("Use link", false) ? "https://www.example.com" : null
              }
              name={text("name", "Casey S.")}
              title={text("title", "Managing director of corporate law")}
              location={text("location", "New York")}
              imgSrc={text("imgSrc", "headshot.jpg")}
              key={i}
            />
          ))}
      </ProfileGallery>
    </Section>
  ))
  .add("Without Introduction, force new row", () => (
    <Section>
      <ProfileGallery verticalAtDesktop={boolean("verticalAtDesktop", true)}>
        {Array(number("numberOfProfiles", extendedNumberOfProfiles))
          .fill()
          .map((result, i) => (
            <ProfileGallery.Profile
              href={
                boolean("Use link", false) ? "https://www.example.com" : null
              }
              name={text("name", "Casey S.")}
              title={text("title", "Managing director of corporate law")}
              location={text("location", "New York")}
              imgSrc={text("imgSrc", "headshot.jpg")}
              key={i}
            />
          ))}
      </ProfileGallery>
    </Section>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Section>
        <ProfileGallery
          introduction={
            <span>
              لدينا <strong> الخبراء القانونيين </strong> تمارس في شركات القانون
              الرائدة في العالم والإدارات الداخلية. الآن وظيفتهم هي لجعل لكم
              أفضل في يدكم.
            </span>
          }
          linkHref="https://www.example.com"
          linkTitle="قابل الفريق"
          linkIcon={<Icon path="./icons/ui/blue/arrow-right.svg" size="s" />}
          verticalAtDesktop={boolean("verticalAtDesktop", true)}
        >
          {Array(number("numberOfProfiles", defaultNumberOfProfiles))
            .fill()
            .map((result, i) => (
              <ProfileGallery.Profile
                name={text("name", "Casey S.")}
                title={text(
                  "title",
                  "الذي ينبغي أن تستهدفه كافة الشعوب والأمم حتى"
                )}
                location={text("location", "نيويورك")}
                imgSrc={text("imgSrc", "headshot.jpg")}
                verticalAtDesktop={boolean("verticalAtDesktop", false)}
                key={i}
              />
            ))}
        </ProfileGallery>
      </Section>
    </Language>
  ));
