import React from "react";
import SharePriceTracker from "./SharePriceTracker";

// eslint-disable-next-line require-await
export default async domNode => {
  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data ? JSON.parse(data) : null;
  };

  const props = {
    apiUrl: domNode.getAttribute("data-api-url"),
    format: domNode.getAttribute("data-date-format"),
    overlay: readData("overlay"),
    backgroundType: domNode.getAttribute("data-background-type"),
    title: domNode.getAttribute("data-title"),
    borderless: readData("borderless"),
    ticker: readData("ticker")
  };

  return <SharePriceTracker {...props} />;
};
