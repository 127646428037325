import PropTypes from "prop-types";
import React, { Component } from "react";

import getClassNameFactory from "@emcm-ui/utility-class-names";
import SharePriceTracker from "@emcm-ui/component-share-price-tracker";

import ControlButton from "../ControlButton/ControlButton";
import { Consumer as StockTickerContextConsumer } from "../../StockTickerContext";

class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 1,
      length: props.shareDetails.length,
      touchStartPosition: null,
      touchEndPosition: null,
      transitionEnabled: true
    };
    this.TOUCH_DIFF = 5;
    this.PERCENTAGE = 75;
    this.firstTouchPosition = 0;
    this.carouselRef = React.createRef();
  }

  static displayName = "Carousel";

  static propTypes = {
    /**
     *To get list of share prices data by api
     */
    shareDetails: PropTypes.array,
    /**
     * To set the background color
     */
    backgroundType: PropTypes.string,
    /**
     * For date format
     */
    dateFormat: PropTypes.string,
    /**
     * To set the aria label for if no price change
     */
    staleAriaLabel: PropTypes.string,
    /**
     * To set the aria label for if increase in share price change
     */
    increasedAriaLabel: PropTypes.string,
    /**
     * To set the aria label for if decrease in price change
     */
    decreasedAriaLabel: PropTypes.string,
    /**
     * To set the aria label for previous stock navigation button
     */
    previousAriaLabel: PropTypes.string,
    /**
     * To set the aria label for next stock navigation button
     */
    nextAriaLabel: PropTypes.string,
    /**
     * To know rtl or ltl based on the local language
     */
    langDirection: PropTypes.string,
    /**
     * Hide the time in the stock
     */
    hideTime: PropTypes.bool
  };

  componentDidUpdate(prevProps) {
    const { currentIndex, length, transitionEnabled } = this.state;
    const isFirstIndex = currentIndex === 1;
    const isLastIndex = currentIndex === length;

    if (!transitionEnabled && (isLastIndex || isFirstIndex)) {
      /**
       * To make the animation smooth need to keep transitionEnabled as false till the render is completed
       * To make that happen setting the state once after queue is completed.
       */
      setTimeout(
        () =>
          this.setState({
            transitionEnabled: true
          }),
        0
      );
    }

    const { shareDetails } = this.props;
    const isStockListUpdated =
      shareDetails.length !== prevProps.shareDetails.length;

    if (isStockListUpdated) {
      this.setState({
        length: shareDetails.length
      });
    }
  }

  handleNext = () => {
    this.setState(prevState => ({ currentIndex: prevState.currentIndex + 1 }));
  };

  handlePrev = () => {
    this.setState(prevState => ({ currentIndex: prevState.currentIndex - 1 }));
  };

  handleTouchStart = e => {
    const { clientX } = e.touches[this.firstTouchPosition];

    this.setState({
      touchStartPosition: clientX
    });
  };

  handleTouchMove = e => {
    const { clientX } = e.touches[this.firstTouchPosition];

    this.setState({
      touchEndPosition: clientX
    });
  };

  handleTouchEnd = () => {
    const { touchStartPosition, touchEndPosition } = this.state;

    if (touchStartPosition === null || touchEndPosition === null) {
      return;
    }
    const diff = touchStartPosition - touchEndPosition;

    if (diff > this.TOUCH_DIFF) {
      this.handleNext();
    }
    if (diff < this.TOUCH_DIFF) {
      this.handlePrev();
    }
    this.resetTouchPositions();
  };

  resetTouchPositions = () => {
    this.setState({
      touchStartPosition: null,
      touchEndPosition: null
    });
  };

  handleTransitionEnd = () => {
    const { currentIndex, length } = this.state;
    const isFirstIndex = currentIndex === 0;
    const isLastIndex = currentIndex === length + 1;

    if (isFirstIndex) {
      this.setState({
        currentIndex: length,
        transitionEnabled: false
      });
    } else if (isLastIndex) {
      this.setState({
        currentIndex: 1,
        transitionEnabled: false
      });
    }
  };

  renderExtraChild = index => {
    const {
      backgroundType,
      shareDetails,
      dateFormat,
      staleAriaLabel,
      decreasedAriaLabel,
      increasedAriaLabel,
      hideTime
    } = this.props;

    return (
      <div className={this.getClassName({ descendantName: "list" })}>
        <SharePriceTracker
          overlay
          format={dateFormat}
          indexes={shareDetails[index]}
          ticker={true}
          backgroundType={backgroundType}
          useLocalTimeZone={true}
          href={`${shareDetails[index].indexDetails.pagePath}`}
          staleAriaLabel={staleAriaLabel}
          decreasedAriaLabel={decreasedAriaLabel}
          increasedAriaLabel={increasedAriaLabel}
          tabIndex={"-1"}
          showTimeZone={!hideTime}
        />
      </div>
    );
  };

  getClassName = getClassNameFactory(Carousel.displayName);

  render() {
    const {
      backgroundType,
      shareDetails,
      dateFormat,
      staleAriaLabel,
      decreasedAriaLabel,
      increasedAriaLabel,
      langDirection,
      previousAriaLabel,
      nextAriaLabel,
      hideTime
    } = this.props;
    const { currentIndex, transitionEnabled, length } = this.state;
    const calcTranslate = currentIndex * this.PERCENTAGE;
    const translate = langDirection === "rtl" ? calcTranslate : -calcTranslate;

    return (
      <div className={this.getClassName()}>
        <div
          className={this.getClassName({
            descendantName: "wrapper",
            modifiers: backgroundType
          })}
        >
          <ControlButton
            direction="prev"
            handleClick={this.handlePrev}
            backgroundType={backgroundType}
            ariaLabel={previousAriaLabel}
          />
          <div
            className={this.getClassName({
              descendantName: "body",
              modifiers: backgroundType
            })}
            onTouchStart={this.handleTouchStart}
            onTouchMove={this.handleTouchMove}
            onTouchEnd={this.handleTouchEnd}
          >
            <div
              className={this.getClassName({ descendantName: "content" })}
              style={{
                transform: `translateX(${translate}%)`,
                transition: transitionEnabled ? undefined : "none" // eslint-disable-line no-undefined
              }}
              onTransitionEnd={this.handleTransitionEnd}
              ref={this.carouselRef}
              role="marquee"
              aria-live="off"
            >
              {this.renderExtraChild(length - 1)}
              {shareDetails.map((shareDetail, index) => (
                <div
                  key={index}
                  className={this.getClassName({ descendantName: "list" })}
                >
                  <SharePriceTracker
                    overlay
                    format={dateFormat}
                    indexes={shareDetail}
                    ticker={true}
                    backgroundType={backgroundType}
                    useLocalTimeZone={true}
                    href={`${shareDetail.indexDetails.pagePath}`}
                    staleAriaLabel={staleAriaLabel}
                    decreasedAriaLabel={decreasedAriaLabel}
                    increasedAriaLabel={increasedAriaLabel}
                    tabIndex={index + 1 === currentIndex ? "0" : "-1"}
                    showTimeZone={!hideTime}
                  />
                </div>
              ))}
              {this.renderExtraChild(0)}
            </div>
            <div
              className={this.getClassName({
                descendantName: "mask",
                modifiers: backgroundType
              })}
            />
          </div>
          <ControlButton
            direction="next"
            handleClick={this.handleNext}
            backgroundType={backgroundType}
            ariaLabel={nextAriaLabel}
          />
        </div>
      </div>
    );
  }
}

const CarouselWithContext = ({ ...props }) => (
  <StockTickerContextConsumer>
    {contextValue => <Carousel {...contextValue} {...props} />}
  </StockTickerContextConsumer>
);

export default CarouselWithContext;
