import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import TextCardBase from "../../bases/TextCardBase";
import Image, { anchorPoints } from "@emcm-ui/component-image";
import classNames from "classnames";

const PairedHorizontalCard = ({
  eyebrow,
  footer,
  imgAnchor,
  imgSrc,
  imgSrcSet,
  heading,
  metaData,
  description,
  headingRank,
  href,
  borderVariant
}) => {
  const getClassName = getClassNameFactory(PairedHorizontalCard.displayName);

  return (
    <div
      className={getClassName({
        modifiers: classNames({
          [borderVariant]: borderVariant
        })
      })}
    >
      <TextCardBase
        anchor="top"
        borderVariant={borderVariant}
        eyebrow={eyebrow}
        footer={footer}
        heading={heading}
        metaData={metaData}
        description={description}
        headingRank={headingRank}
        href={href}
        size="small"
        renderContent={content => (
          <div className={getClassName({ descendantName: "content" })}>
            <div className={getClassName({ descendantName: "image" })}>
              <div className={getClassName({ descendantName: "imageInner" })}>
                <Image
                  alt=""
                  anchor={imgAnchor}
                  fit="cover"
                  sizes={Image.sizes.oneThird}
                  src={imgSrc}
                  srcSet={imgSrcSet}
                />
              </div>
            </div>

            <div className={getClassName({ descendantName: "contentInner" })}>
              {content}
            </div>
          </div>
        )}
      />
    </div>
  );
};

PairedHorizontalCard.displayName = "PairedHorizontalCard";

PairedHorizontalCard.headingRanks = TextCardBase.headingRanks;

PairedHorizontalCard.borderVariant = [null, "borderless"];

/* eslint-disable max-len */
PairedHorizontalCard.propTypes = {
  /**
   * Card border variations
   */
  borderVariant: PropTypes.oneOf(PairedHorizontalCard.borderVariant),
  /**
   * Eyebrow text
   */
  eyebrow: PropTypes.string.isRequired,
  /**
   * Footer. Should be a valid card footer, like ArticleCardFooter.
   */
  footer: PropTypes.node.isRequired,
  /**
   * Heading text
   */
  heading: PropTypes.string.isRequired,
  /**
   * Description text.
   */
  description: PropTypes.string,
  /**
   * Heading rank. 1 will render `<h1 />`, and so on. Defaults to 3.
   */
  headingRank: PropTypes.oneOf(PairedHorizontalCard.headingRanks),
  /**
   * Card URL
   */
  href: PropTypes.string.isRequired,
  /**
   * Anchor point for the image.
   */
  imgAnchor: PropTypes.oneOf(anchorPoints),
  /**
   * Image `src`. Used if srcSet is not defined, or as a fallback.
   */
  imgSrc: PropTypes.string.isRequired,
  /**
   * A comma-separated list indicating a set of possible image sources for the image. The image will be fit to cover. This is used by the user agent to determine the correct image for different screen sizes. Same format as [srcSet](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#Example_4_Using_the_srcset_and_sizes_attributes), i.e. `my-image-200.png 200w, my-image-200.png 200w`.
   */
  imgSrcSet: PropTypes.string,
  /**
   * Metadata content object array with label and value
   */
  metaData: PropTypes.array
};
/* eslint-enable max-len */

PairedHorizontalCard.defaultProps = {
  headingRank: "h4",
  imgGradient: false,
  imgAnchor: "C"
};

export default PairedHorizontalCard;
