import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import CardBase from "./index";
import "./CardBase.css";

import Block from "@emcm-ui/component-block";

import CardBaseREADME from "../../../../README.md";

const changeBlockHeight = () => {
  switch (select("size", CardBase.sizes, "small")) {
    case "medium":
      return "300px";
    case "large":
      return "420px";
    default:
      return "200px";
  }
};

const stories = storiesOf("Components/Card/Bases/CardBase", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(CardBaseREADME));

stories
  .add("Default", () => (
    <div style={{ maxWidth: 360 }}>
      <CardBase
        href="https://www.example.com"
        size={select("size", CardBase.sizes, "small")}
        background={select("background", CardBase.backgrounds, "white")}
        borderVariant={select("borderVariant", CardBase.borderVariant, null)}
      >
        <Block color="blue" text="children" height={changeBlockHeight()} />
      </CardBase>
    </div>
  ))
  .add("Language: Arabic (RTL)", () => (
    <div style={{ maxWidth: 360 }}>
      <Language code="ar">
        <CardBase
          href="https://www.example.com"
          size={select("size", CardBase.sizes, "small")}
          background={select("background", CardBase.backgrounds, "white")}
          borderVariant={select("borderVariant", CardBase.borderVariant, null)}
        >
          <Block color="blue" text="الأطفال" height={changeBlockHeight()} />
        </CardBase>
      </Language>
    </div>
  ));
