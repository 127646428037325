import React from "react";
import { bool, func, number, oneOfType, string } from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";

import shouldNextTriggerOnMount from "../../utils/shouldNextTriggerOnMount";
import getPosition from "../../utils/getPosition";
import getNextOffset from "../../utils/getNextOffset";
import getStartOffset from "../../utils/getStartOffset";

const PERCENTAGE_BASE = 100;

class TickerElement extends React.Component {
  static propTypes = {
    /**
     * Children as function
     */
    children: func.isRequired,
    /**
     * To define the direction of the ticker
     */
    direction: string.isRequired,
    /**
     * To define speed of the ticker
     */
    speed: number.isRequired,
    /**
     * Unique id for each ticker element
     */
    id: string.isRequired,
    /**
     * Index for the ticker element
     */
    index: number.isRequired,
    /**
     * To set the mode of the ticker
     */
    mode: string.isRequired,
    /**
     * To make ticker move
     */
    move: bool.isRequired,
    /**
     * To set the next ticker element ready
     */
    onNext: func.isRequired,
    /**
     * Will be called when ticker element animation is completed
     */
    onFinish: func.isRequired,
    /**
     * To set the ticker element position details
     */
    setRect: func.isRequired,
    /**
     * For stating the ticker element
     */
    start: bool.isRequired,
    /**
     * To set the offset for the ticker element
     */
    offset: oneOfType([number, string]),
    /**
     * Width of the screen
     */
    width: number,
    /**
     * Function to play/pause ticker on focus
     */
    toggleTicker: func
  };

  static displayName = "TickerElement";

  constructor(props) {
    super(props);
    this.state = {
      move: props.move,
      position: { from: null, to: null, next: null },
      offset: props.offset,
      rect: null,
      tabIndex: "-1"
    };
    this.xAxisPosition = 0;
    this.isMoving = false;
    this.nextTriggered = false;
    this.elementRef = React.createRef();
    this.animationFrameRef = null;
  }

  componentDidMount = () => {
    this.setPosition(true);
    this.observer = new MutationObserver(this.onMutation);
    this.observer.observe(this.elementRef.current, {
      characterData: true,
      childList: true,
      subtree: true
    });
  };

  componentWillUnmount = () => {
    this.observer.disconnect();
  };

  onMutation = () => {
    this.setPosition();
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { position } = this.state;
    const { move, start } = this.props;
    const { move: prevMove, start: prevStart } = prevProps;
    const { style } = this.elementRef.current;

    const isNewElementToStartTransform =
      !this.xAxisPosition && prevState.position.from !== position.from;

    if (isNewElementToStartTransform) {
      this.xAxisPosition = position.from;
      style.transform = `translate3d(${this.xAxisPosition}px, 0, 0)`;
    }
    const isInitialStart = move && !prevStart && start;
    const moveElement = start && !prevMove && move;
    const startAnimation = isInitialStart || moveElement;

    if (startAnimation) {
      this.animate();
    }
    if (prevMove && !move) {
      this.isMoving = false;
      window.cancelAnimationFrame(this.animationFrameRef);
    }
  };

  setPosition = isMount => {
    const { mode, width, id, onNext, direction, index, setRect } = this.props;
    let { offset } = this.props;

    const rect = this.elementRef.current.getBoundingClientRect();

    const isEmptyElement = rect.width === 0;

    if (isEmptyElement) {
      return;
    }
    const isStartingElement = index === 0;

    if (isStartingElement) {
      offset = getStartOffset({
        offset,
        rect,
        direction,
        width
      });
    }
    const position = getPosition({
      mode,
      rect,
      index,
      offset,
      width,
      direction
    });

    setRect({
      index,
      rect,
      offset,
      nextOffset: getNextOffset({ from: position.from, rect, direction })
    });

    if (isMount) {
      const nextTriggerOnMount = shouldNextTriggerOnMount({
        mode,
        rect,
        position,
        offset,
        direction,
        width
      });

      if (nextTriggerOnMount) {
        onNext({
          id,
          index,
          rect,
          nextOffset: getNextOffset({ from: position.from, rect, direction })
        });
      }
      const createNextElement =
        !nextTriggerOnMount && (offset || isStartingElement);

      if (createNextElement) {
        onNext({ id, index, rect });
      }
      this.nextTriggered = nextTriggerOnMount;
    }

    this.setState({
      rect,
      offset,
      position
    });
  };

  shouldTriggerNext = () => {
    if (this.nextTriggered) {
      return false;
    }

    return this.props.direction === "toLeft"
      ? this.xAxisPosition <= this.state.position.next
      : this.xAxisPosition >= this.state.position.next;
  };

  triggerNext = () => {
    if (this.shouldTriggerNext()) {
      this.nextTriggered = true;
      this.props.onNext({
        id: this.props.id,
        index: this.props.index,
        rect: this.state.rect
      });
    }
  };

  shouldFinish = () => {
    switch (this.props.direction) {
      case "toRight":
        return this.xAxisPosition >= this.state.position.to;
      case "toLeft":
      default:
        return this.xAxisPosition <= this.state.position.to;
    }
  };

  animate = () => {
    if (this.isMoving) {
      return;
    }
    this.isMoving = true;

    let prevTimestamp = null;

    const step = timestamp => {
      if (!this.isMoving || !this.elementRef.current) {
        return;
      }

      const progress = prevTimestamp ? timestamp - prevTimestamp : 0;
      const { speed, onFinish, id, direction } = this.props;
      const { style } = this.elementRef.current;

      const getNextXAxisPosition = toDirection => {
        if (toDirection === "toLeft") {
          return this.xAxisPosition - progress / PERCENTAGE_BASE * speed;
        }

        return this.xAxisPosition + progress / PERCENTAGE_BASE * speed;
      };

      this.xAxisPosition = getNextXAxisPosition(direction);
      style.transform = `translate3d(${this.xAxisPosition}px, 0, 0)`;
      this.triggerNext();
      this.getTabIndex();

      if (this.shouldFinish()) {
        this.isMoving = false;
        prevTimestamp = null;
        onFinish(id);
      } else {
        prevTimestamp = timestamp;
        this.animationFrameRef = window.requestAnimationFrame(step);
      }
    };

    this.animationFrameRef = window.requestAnimationFrame(step);
  };

  getTabIndex = () => {
    const { width: screenWidth } = this.props;
    const { rect } = this.state;

    if (!rect) {
      return;
    }
    const VISIBLE_PERCENTAGE = 30;
    const elementRightOffset = this.xAxisPosition + rect.width;
    const isExits = this.xAxisPosition < 0;
    const isOutsideViewport = elementRightOffset > screenWidth;
    const isWithinViewport =
      elementRightOffset < screenWidth && this.xAxisPosition > 0;

    let visibleWidth;
    let tabIndex = "-1";

    if (isExits) {
      visibleWidth = Math.round(rect.width - Math.abs(this.xAxisPosition));
    } else if (isOutsideViewport) {
      visibleWidth = Math.round(screenWidth - this.xAxisPosition);
    }

    const currentVisibility = visibleWidth / rect.width * PERCENTAGE_BASE;

    if (currentVisibility > VISIBLE_PERCENTAGE || isWithinViewport) {
      tabIndex = "0";
    }

    this.setState({ tabIndex });
  };

  getClassName = getClassNameFactory(TickerElement.displayName);

  render() {
    const { start, children, index, toggleTicker } = this.props;
    const { rect, tabIndex } = this.state;

    return (
      <div
        className={this.getClassName()}
        style={{
          transform: `translate3d(${this.xAxisPosition}px, 0, 0)`,
          visibility: start ? "visible" : "hidden",
          width: rect && `${rect.width}px`
        }}
        ref={this.elementRef}
        data-rehydratable-children
        onFocus={() => {
          toggleTicker({ isFocused: true });
        }}
        onBlur={() => {
          toggleTicker({ isFocused: false });
        }}
        onMouseEnter={() => {
          toggleTicker({ isPaused: true });
        }}
        onMouseLeave={() => {
          toggleTicker({ isPaused: false });
        }}
      >
        {children(index, tabIndex)}
      </div>
    );
  }
}

export default TickerElement;
