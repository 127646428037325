import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { anchorPoints } from "@emcm-ui/component-image";
import { Language } from "@emcm-ui/component-theme";
import PairedCard from "./index";
import "./PairedCard.css";
import Icon from "@emcm-ui/component-icon";

import ArticleCardFooter from "../../footers/ArticleCardFooter";
import LinkCardFooter from "../../footers/LinkCardFooter";
import VideoCardFooter from "../../footers/VideoCardFooter";
import DownloadCardFooter from "../../footers/DownloadCardFooter";
import Section from "@emcm-ui/component-section";

import CardREADME from "../../../../README.md";

const stories = storiesOf("Components/Card/Cards/PairedCard", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(CardREADME));

const headingRanks = [""].concat(PairedCard.headingRanks);

const footers = {
  none: null,
  article: (
    <ArticleCardFooter
      attributionName="Kawenaokalaniokekamaaloha Sharon"
      dateTime="2016-03-02 13:57"
      readingTime="5 min"
      icon={<Icon path="./icons/ui/grey/time-07.svg" size="xs" />}
    />
  ),
  link: (
    <LinkCardFooter
      title="Link"
      type="icon"
      icon={<Icon path="./icons/ui/blue/arrow-right.svg" size="s" />}
    />
  ),
  extrenalLink: (
    <LinkCardFooter
      title="Link"
      type="icon"
      icon={<Icon path="./icons/ui/blue/external.svg" size="s" />}
    />
  ),
  video: (
    <VideoCardFooter
      runningTime="25 min"
      icon={<Icon path="./icons/ui/grey/playoutline-07.svg" size="xs" />}
    />
  ),
  download: (
    <DownloadCardFooter
      dateTime="2016-03-02 13:57"
      fileLabel="Download"
      disabled={false}
      size="20.54KB"
      href="https://www.refinitiv.com/content/dam/marketing/bundle-emcm/static/fonts/all-characters/proximanova-regular-webfont.woff2"
      icon={<Icon path="./icons/ui/grey/download-07.svg" size="s" />}
    />
  )
};

const footersAr = {
  none: null,
  article: (
    <ArticleCardFooter
      attributionName="Sophie P."
      dateTime="2016-03-02 13:57"
      readingTime="5 دقائق"
      icon={<Icon path="./icons/ui/grey/time-07.svg" size="xs" />}
    />
  ),
  link: (
    <LinkCardFooter
      title="حلقة الوصل"
      type="icon"
      icon={<Icon path="./icons/ui/blue/arrow-right.svg" size="s" />}
    />
  ),
  externalLink: (
    <LinkCardFooter
      title="حلقة الوصل"
      type="icon"
      icon={<Icon path="./icons/ui/blue/external.svg" size="s" />}
    />
  ),
  video: (
    <VideoCardFooter
      runningTime="25 دقيقة"
      icon={<Icon path="./icons/ui/grey/playoutline-07.svg" size="xs" />}
    />
  ),
  download: (
    <DownloadCardFooter
      dateTime="2016-03-02 13:57"
      fileLabel={text("fileName", "دقائق")}
      disabled={false}
      size="20.54KB"
      href="https://www.refinitiv.com/content/dam/marketing/bundle-emcm/static/fonts/all-characters/proximanova-regular-webfont.woff2"
      icon={<Icon path="./icons/ui/grey/download-07.svg" size="s" />}
    />
  )
};

stories
  .add("Default", () => (
    <div style={{ maxWidth: 360 }}>
      <PairedCard
        background={select("background", PairedCard.backgrounds, "white")}
        borderVariant={select("borderVariant", PairedCard.borderVariant, null)}
        href={text("href", "https://www.example.com")}
        eyebrow={text("eyebrow", "Eyebrow")}
        heading={text("heading", "Heading")}
        description={text(
          "description",
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porta, ligula sit amet dignissim facilisis, ante nulla aliquam ipsum, in vulputate purus turpis et turpis."
        )}
        headingRank={select("headingRank", headingRanks)}
        footer={footers[select("footer", Object.keys(footers), "link")]}
        imgSrc={text("imgSrc", "test-images/16-9/large_2.jpg")}
        imgSrcSet={text(
          "imgSrc",
          "test-images/16-9/large_2.jpg 2000w, test-images/16-9/medium_2.jpg 800w, test-images/16-9/small_2.jpg 400w"
        )}
        imgAnchor={select("imgAnchor", anchorPoints, "C")}
      />
    </div>
  ))
  .add("With Background Section", () => (
    <Section background={"grayLighter"}>
      <div style={{ maxWidth: 360 }}>
        <PairedCard
          background={select("background", PairedCard.backgrounds, "white")}
          borderVariant={select(
            "borderVariant",
            PairedCard.borderVariant,
            null
          )}
          href={text("href", "https://www.example.com")}
          eyebrow={text("eyebrow", "Eyebrow")}
          heading={text("heading", "Heading")}
          description={text(
            "description",
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porta, ligula sit amet dignissim facilisis, ante nulla aliquam ipsum, in vulputate purus turpis et turpis."
          )}
          headingRank={select("headingRank", headingRanks)}
          footer={footers[select("footer", Object.keys(footers), "link")]}
          imgSrc={text("imgSrc", "test-images/16-9/large_2.jpg")}
          imgSrcSet={text(
            "imgSrc",
            "test-images/16-9/large_2.jpg 2000w, test-images/16-9/medium_2.jpg 800w, test-images/16-9/small_2.jpg 400w"
          )}
          imgAnchor={select("imgAnchor", anchorPoints, "C")}
        />
      </div>
    </Section>
  ))
  .add("Metadata", () => (
    <div style={{ maxWidth: 360 }}>
      <PairedCard
        background={select("background", PairedCard.backgrounds, "white")}
        borderVariant={select("borderVariant", PairedCard.borderVariant, null)}
        href={text("href", "https://www.example.com")}
        eyebrow={text("eyebrow", "Eyebrow")}
        heading={text("heading", "Heading2")}
        description={text(
          "description",
          "Used for event metadata where a date range across more than a year is required with a specific time"
        )}
        headingRank={select("headingRank", headingRanks)}
        footer={footers[select("footer", Object.keys(footers), "article")]}
        metaData={[
          {
            id: 1,
            label: "Date/Time:",
            value: "02 Mar, 2022 10:00-11:00 GMT"
          },
          {
            id: 2,
            label: "Location:",
            value: "London"
          }
        ]}
        imgSrc={text("imgSrc", "test-images/16-9/large_2.jpg")}
        imgSrcSet={text(
          "imgSrc",
          "test-images/16-9/large_2.jpg 2000w, test-images/16-9/medium_2.jpg 800w, test-images/16-9/small_2.jpg 400w"
        )}
        imgAnchor={select("imgAnchor", anchorPoints, "C")}
      />
    </div>
  ))
  .add("Language: Arabic (RTL)", () => (
    <div style={{ maxWidth: 360 }}>
      <Language code="ar">
        <PairedCard
          background={select("background", PairedCard.backgrounds, "white")}
          borderVariant={select(
            "borderVariant",
            PairedCard.borderVariant,
            null
          )}
          href={text("href", "https://www.example.com")}
          eyebrow={text("eyebrow", "حاجب العين")}
          heading={text("heading", "عنوان")}
          headingRank={select("headingRank", headingRanks)}
          footer={
            footersAr[select("footer", Object.keys(footersAr), "article")]
          }
          imgSrc={text("imgSrc", "test-images/16-9/large_2.jpg")}
          imgSrcSet={text(
            "imgSrc",
            "test-images/16-9/large_2.jpg 2000w, test-images/16-9/medium_2.jpg 800w, test-images/16-9/small_2.jpg 400w"
          )}
          imgAnchor={select("imgAnchor", anchorPoints, "C")}
        />
      </Language>
    </div>
  ));
