import Anchor from "@emcm-ui/component-anchor";
import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import classNames from "classnames";

const CardBase = ({ href, size, children, background, borderVariant }) => {
  const getClassName = getClassNameFactory(CardBase.displayName);

  return (
    <div
      className={getClassName({
        modifiers: classNames({
          [size]: true,
          [background]: background,
          [borderVariant]: borderVariant
        })
      })}
    >
      <Anchor
        className={getClassName({
          descendantName: href ? "link" : "wrapper"
        })}
        fallbackElementTag="div"
        href={href || null}
      >
        <div
          className={getClassName({
            descendantName: "inner"
          })}
        >
          {children}
        </div>
      </Anchor>
    </div>
  );
};

CardBase.displayName = "CardBase";

CardBase.sizes = ["small", "medium", "large"];

CardBase.backgrounds = ["white", "transparent"];

CardBase.borderVariant = [null, "borderless", "bottomStroke"];

/* eslint-disable max-len */
CardBase.propTypes = {
  /**
   * Card background color
   */
  background: PropTypes.oneOf(CardBase.backgrounds),
  /**
   * Card border variations
   */
  borderVariant: PropTypes.oneOf(CardBase.borderVariant),
  /**
   * Card URL
   */
  href: PropTypes.string,
  /**
   * Child node
   */
  children: PropTypes.node,
  /**
   * Size of the Card. Will set the minumum height and grow to fit content.
   */
  size: PropTypes.oneOf(CardBase.sizes).isRequired
};
/* eslint-enable max-len */

CardBase.defaultProps = {
  background: "white",
  borderVariant: null
};

export default CardBase;
