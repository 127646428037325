import React from "react";
import { storiesOf } from "@storybook/react";
import { action } from "@storybook/addon-actions";
import { withKnobs, text, number, boolean } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import Cart from "./index";
import "./Cart.css";
import CartREADME from "../README.md";

import Grid from "@emcm-ui/component-grid";

const stories = storiesOf("Components/Cart", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(CartREADME));

const MaximumLicense = 40;

const MaximumLicenseOnline = 25;

const cartSeletionSubmit = action("Cart Details");

const captureCartSelection = value => cartSeletionSubmit(value);

const removeProduct = action("Removed product details");

const captureRemoveProduct = value => removeProduct(value);

stories
  .add("Default", () => (
    <Cart
      title={text("Cart Title", "Your cart")}
      description={text(
        "Cart Description",
        "*Every product licence is billed quarterly"
      )}
      maxLicense={number("Maximum License", MaximumLicense)}
      maxOnlineLicense={number(
        "Maximum License online Buy",
        MaximumLicenseOnline
      )}
      subTotalText={text("Sub Total text", "Subtotal:")}
      taxInfo={text("Sub Total tax text", "(without taxes*)")}
      subTotalInfo={text("Sub Total info text", "Per month")}
      footerText={text(
        "Footer Text",
        "*Taxes will be calculated and visible in invoice"
      )}
      showButton={boolean("Show Button", true)}
      buttonText={text("Button Text", "Continue")}
      buttonBlock={boolean("Block", true)}
      productDetails={{
        productGroupNameLabel: text("Product Name Label", "PRODUCTS"),
        productGroupQtyLabel: text("Product Qty Label", "MONTHLY PRICE"),
        products: [
          {
            id: 1,
            name: "Product Name 1",
            licenseCount: 10,
            price: 9,
            currency: "£"
          },
          {
            id: 2,
            name: "Product Name 2",
            licenseCount: 9,
            price: 10,
            currency: "£"
          }
        ]
      }}
      productValidity={text("Product Validity text", "Billed quarterly")}
      licenseText={text("License text", "Licenses")}
      support={
        <span>
          <a href="https://www.example.com">Contact our sales team</a>
          <span> for orders of 26 or more licenses.</span>
        </span>
      }
      captureCartSelection={captureCartSelection}
      captureRemoveProduct={captureRemoveProduct}
      showRemoveLink={boolean("Show remove link", false)}
      tooltipInfo={text(
        "Tooltip info for non base products",
        "Follows base product count"
      )}
      emptyCartText={text("Empty cart text", "Basket is empty")}
      currency="£"
    />
  ))
  .add("With add-ons and exchanges", () => (
    <Grid variant="2/3,1/3" flex={boolean("flex version", false)} gutterless>
      <Grid.Item />
      <Grid.Item>
        <Cart
          title={text("Cart Title", "Your cart")}
          description={text(
            "Cart Description",
            "*Every product licence is billed quarterly"
          )}
          maxLicense={number("Maximum License", MaximumLicense)}
          maxOnlineLicense={number(
            "Maximum License online Buy",
            MaximumLicenseOnline
          )}
          subTotalText={text("Sub Total text", "Subtotal:")}
          taxInfo={text("Sub Total tax text", "(without taxes*)")}
          subTotalInfo={text("Sub Total info text", "Per month")}
          footerText={text(
            "Footer Text",
            "*Taxes will be calculated and visible in invoice"
          )}
          showButton={boolean("Show Button", true)}
          buttonText={text("Button Text", "Continue")}
          buttonBlock={boolean("Block", true)}
          productDetails={{
            productGroupNameLabel: text("Product Name Label", "PRODUCTS"),
            productGroupQtyLabel: text("Product Qty Label", "MONTHLY PRICE"),
            products: [
              {
                id: 1,
                name: "Product Name 1",
                licenseCount: 10,
                price: 9,
                currency: "£"
              }
            ]
          }}
          addonsDetails={{
            productGroupNameLabel: text("Addons Name Label", "ADD-ONS"),
            productGroupQtyLabel: text("Addons Qty Label", "MONTHLY PRICE"),
            products: [
              {
                id: 2,
                name: "Add on 1",
                price: 9,
                licenseCount: 10,
                currency: "£"
              },
              {
                id: 3,
                name: "Add on 2",
                price: 10,
                licenseCount: 10,
                currency: "£"
              }
            ]
          }}
          exchangesDetails={{
            productGroupNameLabel: text("Exchanges Name Label", "EXCHANGES"),
            productGroupQtyLabel: text("Exchanges Qty Label", "MONTHLY PRICE"),
            products: [
              {
                id: 4,
                name: "Exchange 1",
                price: 9,
                licenseCount: 10,
                currency: "£"
              },
              {
                id: 5,
                name: "Exchange 2",
                price: 10,
                licenseCount: 10,
                currency: "£"
              }
            ]
          }}
          productValidity={text("Product Validity text", "Billed quarterly")}
          licenseText={text("License text", "Licenses")}
          support={
            <span>
              <a href="https://www.example.com">Contact our sales team</a>
              <span> for orders of 26 or more licenses.</span>
            </span>
          }
          captureCartSelection={captureCartSelection}
          captureRemoveProduct={captureRemoveProduct}
          showRemoveLink={boolean("Show remove link", true)}
          tooltipInfo={text(
            "Tooltip info for non base products",
            "Follows base product count"
          )}
          emptyCartText={text("Empty cart text", "Basket is empty")}
          currency="£"
        />
      </Grid.Item>
    </Grid>
  ))
  .add("Empty Basket", () => (
    <Cart
      title={text("Cart Title", "Your cart")}
      description={text(
        "Cart Description",
        "*Every product licence is billed quarterly"
      )}
      maxLicense={number("Maximum License", MaximumLicense)}
      maxOnlineLicense={number(
        "Maximum License online Buy",
        MaximumLicenseOnline
      )}
      subTotalText={text("Sub Total text", "Subtotal:")}
      taxInfo={text("Sub Total tax text", "(without taxes*)")}
      footerText={text(
        "Footer Text",
        "*Taxes will be calculated and visible in invoice"
      )}
      showButton={boolean("Show Button", true)}
      buttonText={text("Button Text", "Continue")}
      buttonBlock={boolean("Block", true)}
      emptyCartText={text("Empty cart text", "Basket is empty")}
      productValidity={text("Product Validity text", "Billed quarterly")}
      licenseText={text("License text", "Licenses")}
      support={
        <span>
          <a href="https://www.example.com">Contact our sales team</a>
          <span> for orders of 26 or more licenses.</span>
        </span>
      }
      captureCartSelection={captureCartSelection}
      currency="£"
    />
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <Cart
        title={text("Cart Title", "Your cart")}
        description={text(
          "Cart Description",
          "*Every product licence is billed quarterly"
        )}
        productNameLabel={text("Product Name Label", "PRODUCTS")}
        productQtyLabel={text("Product Qty Label", "MONTHLY PRICE")}
        maxLicense={number("Maximum License", MaximumLicense)}
        maxOnlineLicense={number(
          "Maximum License online Buy",
          MaximumLicenseOnline
        )}
        subTotalText={text("Sub Total text", "Subtotal:")}
        taxInfo={text("Sub Total tax text", "(without taxes*)")}
        footerText={text(
          "Footer Text",
          "*Taxes will be calculated and visible in invoice"
        )}
        showButton={boolean("Show Button", true)}
        buttonText={text("Button Text", "Continue")}
        buttonBlock={boolean("Block", true)}
        productDetails={{
          productGroupNameLabel: text("Product Name Label", "PRODUCTS"),
          productGroupQtyLabel: text("Product Qty Label", "MONTHLY PRICE"),
          products: [
            {
              id: 1,
              name: "Product Name 1",
              licenseCount: 10,
              price: 9,
              currency: "£"
            }
          ]
        }}
        addonsDetails={{
          productGroupNameLabel: text("Addons Name Label", "ADD-ONS"),
          productGroupQtyLabel: text("Addons Qty Label", "MONTHLY PRICE"),
          products: [
            {
              id: 2,
              name: "Add on 1",
              price: 9,
              licenseCount: 10,
              currency: "£"
            },
            {
              id: 3,
              name: "Add on 2",
              price: 10,
              licenseCount: 10,
              currency: "£"
            }
          ]
        }}
        exchangesDetails={{
          productGroupNameLabel: text("Exchanges Name Label", "EXCHANGES"),
          productGroupQtyLabel: text("Exchanges Qty Label", "MONTHLY PRICE"),
          products: [
            {
              id: 4,
              name: "Exchange 1",
              price: 9,
              licenseCount: 10,
              currency: "£"
            },
            {
              id: 5,
              name: "Exchange 2",
              price: 10,
              licenseCount: 10,
              currency: "£"
            }
          ]
        }}
        productValidity={text("Product Validity text", "Billed quarterly")}
        showRemoveLink={boolean("Show Remove Link", false)}
        licenseText={text("License text", "Licenses")}
        support={
          <span>
            <a href="https://www.example.com">Contact our sales team</a>
            <span> for orders of 26 or more licenses.</span>
          </span>
        }
        captureCartSelection={captureCartSelection}
      />
    </StaticRenderer>
  ));
