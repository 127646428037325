import mountScript from "@emcm-ui/utility-mount-script";

export default async embedUrl => {
  if (window.HSC) {
    return window.HSC;
  }

  try {
    await mountScript(embedUrl);
  } catch (e) {
    window.HSC = false;
  }

  return window.HSC;
};
