import React, { Component } from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { TextCard, PairedCard } from "@emcm-ui/component-card";
import Grid from "@emcm-ui/component-grid";
import CardFooter from "../CardFooter/CardFooter";
import {
  COLUMN_COUNT_DESKTOP,
  COLUMN_COUNT_TABLET,
  MAX_MOBILE_WIDTH,
  CARD_VIDEO,
  CARD_DATA_VISUALIZATION
} from "../../utilities/constants";

class Collage extends Component {
  static displayName = "SearchResults.Collage";

  static propTypes = {
    /**
     * List of cards
     */
    cards: PropTypes.array,
    /**
     * Boolean value to display description of card
     */
    includeDescription: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= MAX_MOBILE_WIDTH
    };

    this.getClassName = getClassNameFactory("SearchResultsMixture");
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({
      isMobile: window.innerWidth <= MAX_MOBILE_WIDTH
    });
  };

  generateCard = (card, index, isLastCard) => {
    const {
      description,
      eyebrow,
      image,
      altText,
      pageType,
      path,
      title,
      ...restCardData
    } = card;
    const { includeDescription } = this.props;
    const descriptionText = includeDescription && description;
    const borderVariant = isLastCard ? "borderless" : "bottomStroke";
    const combinedProps = { ...restCardData, ...this.props };
    const footer = <CardFooter pageType={pageType} {...combinedProps} />;
    const cardProps = {
      key: index,
      href: path,
      size: "small",
      background: "white",
      heading: title,
      headingRank: "h3",
      description: descriptionText,
      borderVariant,
      eyebrow,
      footer
    };

    if (
      pageType.toLowerCase() === CARD_VIDEO ||
      pageType.toLowerCase() === CARD_DATA_VISUALIZATION
    ) {
      return <PairedCard imgSrc={image} alt={altText} {...cardProps} />;
    }

    return <TextCard {...cardProps} />;
  };

  getGridItems = cards => {
    return cards.map((card, index) => {
      const isLastCard = cards.length === index + 1;

      return this.generateCard(card, index, isLastCard);
    });
  };

  splitCards = numberOfitem => {
    const [...cards] = this.props.cards;
    const devidedCards = [];

    for (let index = numberOfitem; index > 0; index--) {
      devidedCards.push(cards.splice(0, Math.ceil(cards.length / index)));
    }

    return devidedCards;
  };

  getGridCollage = numberOfcolumn => {
    const gridVarient = `even-${numberOfcolumn}`;
    const cardsEachColumn = this.splitCards(numberOfcolumn);
    const gridItems = cardsEachColumn.map((cards, index) => (
      <Grid.Item key={index}>{this.getGridItems(cards)}</Grid.Item>
    ));

    return <Grid variant={gridVarient}>{gridItems}</Grid>;
  };

  render() {
    const { isMobile } = this.state;
    const columnCount = isMobile ? COLUMN_COUNT_TABLET : COLUMN_COUNT_DESKTOP;
    const gridsCollage = this.getGridCollage(columnCount);

    return (
      <div
        className={this.getClassName({
          descendantName: "search-result-collage"
        })}
      >
        {gridsCollage}
      </div>
    );
  }
}

export default Collage;
