import React, { Component } from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import classNames from "classnames";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

class ToggleItem extends Component {
  static displayName = "ToggleItem";

  static propTypes = {
    /**
     * Title for toggle tab
     */
    title: PropTypes.string.isRequired,
    /**
     * Location of content to be loaded for toggle tab
     */
    content: PropTypes.string.isRequired,
    /**
     * Unique identifier for toggle tab
     */
    id: PropTypes.string.isRequired,
    /**
     * aria-label for accessibility
     */
    ariaLabel: PropTypes.string.isRequired,
    /**
     * Flag to define toggle tab is active or not
     */
    isActive: PropTypes.bool.isRequired,
    /**
     * Onclick method of the toggle items
     */
    onClick: PropTypes.func,
    /**
     * Onclick method of the toggle items
     */
    onKeyDown: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.itemRef = React.createRef();
  }

  static defaultProps = {
    onClick: () => {},
    onKeyDown: () => {}
  };

  handleClick = e => {
    this.props.onClick(e, this.props.id);
  };

  handleKeyPress = e => {
    this.props.onKeyDown(e, this.props.id);
  };

  componentDidUpdate(oldProps) {
    if (this.itemRef && !oldProps.isActive && this.props.isActive) {
      this.itemRef.current.focus();
    }
  }

  render() {
    const { title, id, ariaLabel, isActive } = this.props;

    const getClassName = getClassNameFactory(ToggleItem.displayName);

    const itemProps = {
      className: getClassName({
        modifiers: classNames(),
        states: classNames({
          isActive
        })
      }),
      onClick: this.handleClick,
      onKeyDown: this.handleKeyPress,
      ...(isActive ? {} : { tabIndex: "-1" }),
      "aria-pressed": isActive,
      ...(isActive && { "aria-label": `${title} Selected. ${ariaLabel}` }),
      id: `toggle-${id}`
    };

    return (
      <button ref={this.itemRef} {...itemProps}>
        <span
          className={getClassName({
            descendantName: "title",
            className: typestack("h5")
          })}
        >
          {title}
        </span>
      </button>
    );
  }
}

export default ToggleItem;
