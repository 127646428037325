import React from "react";
import GlobalNav from "./GlobalNav";

export default async (domNode, rehydrateChildren) => {
  const childrenNode = domNode.querySelector("[data-rehydratable-children]");
  const reactifiedChildren = await rehydrateChildren(childrenNode);

  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data ? JSON.parse(data) : null;
  };

  const props = {
    selected: readData("selected"),
    options: readData("options"),
    title: readData("title")
  };

  return <GlobalNav {...props}>{reactifiedChildren}</GlobalNav>;
};
