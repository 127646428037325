import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { FIRST_PAGE } from "../../utilities/constants";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const ResultSummary = ({ viewText, page, search }) => {
  const getClassName = getClassNameFactory("SearchResultsMixture");
  const { currentPage } = page;
  const { totalResults, searchKey, hasSearchTerm, cardsCount } = search;

  if (!hasSearchTerm) {
    return false;
  }

  const getCount = pageNumber => {
    if (pageNumber > 0) {
      return cardsCount * pageNumber;
    }

    return 0;
  };

  const getSearchResultsSummary = () => {
    const pageCount = currentPage || FIRST_PAGE;
    const previousPageCount = getCount(pageCount - 1);
    const endCount = getCount(pageCount);

    const start = previousPageCount + 1;
    const end = endCount > totalResults ? totalResults : endCount;

    const summary = viewText
      .replace("{start}", start)
      .replace("{end}", end)
      .replace("{total}", totalResults)
      .replace("{term}", `"${searchKey}"`);

    return summary;
  };

  return (
    <div
      className={getClassName({
        descendantName: "context-header"
      })}
    >
      <div
        className={getClassName({
          descendantName: "result-summary",
          className: typestack("subline2")
        })}
      >
        <span>{getSearchResultsSummary()}</span>
      </div>
    </div>
  );
};

ResultSummary.displayName = "SearchResults.ResultSummary";

ResultSummary.propTypes = {
  /**
   * Text for view result text
   */
  viewText: PropTypes.string,
  /**
   * page information
   */
  page: PropTypes.object,
  /**
   * search information
   */
  search: PropTypes.object
};

export default ResultSummary;
