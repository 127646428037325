import React from "react";
import StockTicker from "./StockTicker";

// eslint-disable-next-line require-await
export default async domNode => {
  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data ? JSON.parse(data) : null;
  };

  const props = {
    apiUrl: domNode.getAttribute("data-api-url"),
    scrollDelay: readData("scroll-delay"),
    backgroundType: domNode.getAttribute("data-background-type"),
    indexCodes: readData("index-codes"),
    staleAriaLabel: domNode.getAttribute("data-stale-aria-label"),
    decreasedAriaLabel: domNode.getAttribute("data-decreased-aria-label"),
    increasedAriaLabel: domNode.getAttribute("data-increased-aria-label"),
    playAriaLabel: domNode.getAttribute("data-play-aria-label"),
    pauseAriaLabel: domNode.getAttribute("data-pause-aria-label"),
    previousAriaLabel: domNode.getAttribute("data-previous-aria-label"),
    nextAriaLabel: domNode.getAttribute("data-next-aria-label"),
    skipLinkLabel: domNode.getAttribute("data-skip-link-label"),
    skipLinkTargetElement: domNode.getAttribute(
      "data-skip-link-target-element"
    ),
    hideDate: readData("hide-date"),
    hideTime: readData("hide-time")
  };

  return <StockTicker {...props} />;
};
