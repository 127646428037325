import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const VideoCardFooter = ({ runningTime, icon }) => {
  const getClassName = getClassNameFactory(VideoCardFooter.displayName);

  return (
    <div className={getClassName({ className: typestack("p3") })}>
      <div className={getClassName({ descendantName: "icon" })}>{icon}</div>
      {runningTime ? <time>{runningTime}</time> : null}
    </div>
  );
};

VideoCardFooter.displayName = "VideoCardFooter";

/* eslint-disable max-len */
VideoCardFooter.propTypes = {
  /**
   * Running time for the content, if relevant. Use SI and common non-SI format (English: s = seconds, min = minutes, h = hours, d = days, i.e. "15 min" or "1 h". See https://en.wikipedia.org/wiki/International_System_of_Units#Units_and_prefixes.)
   */
  runningTime: PropTypes.string,
  /**
   * Icon.
   */
  icon: PropTypes.node
};
/* eslint-enable max-len */

VideoCardFooter.defaultProps = {};

export default VideoCardFooter;
