import React, { Component } from "react";
import PropTypes from "prop-types";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

class FloatingTooltip extends Component {
  static displayName = "FloatingTooltip";

  static propTypes = {
    /**
     * To set onclick function for the Tooltip close button
     */
    handleTooltip: PropTypes.func,

    /**
     * The Tooltip text
     */
    tooltipText: PropTypes.string,

    /**
     * The Tooltip Reference
     */
    tooltipRef: PropTypes.object
  };

  static defaultProps = {
    handletooltipClick: () => {}
  };

  getClassName = getClassNameFactory("FloatingTooltip");
  render() {
    const { tooltipText, handleTooltip, tooltipRef } = this.props;

    return (
      <div
        role="tooltip"
        className={this.getClassName({ descendantName: "tooltip" })}
      >
        <div
          ref={tooltipRef}
          aria-label={`Tooltip that reads ${tooltipText}`}
          className={this.getClassName({
            descendantName: "text",
            className: typestack("p1")
          })}
        >
          {tooltipText}
        </div>
        <div className={this.getClassName({ descendantName: "close" })}>
          <button
            className={this.getClassName({ descendantName: "button" })}
            onClick={handleTooltip}
            aria-label="Click here to close the tooltip"
            type="button"
          >
            <SVGIcon
              name="tooltip-close"
              viewBox="0 0 16 16"
              aria-label="Tooltip icon"
            />
          </button>
        </div>
        <div className={this.getClassName({ descendantName: "arrow" })} />
      </div>
    );
  }
}

export default FloatingTooltip;
