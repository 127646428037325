import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, select, text } from "@storybook/addon-knobs";
import { action } from "@storybook/addon-actions";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import Section from "@emcm-ui/component-section";
import PlayPauseControl from "./index";
import "./PlayPauseControl.css";
import PlayPauseControlREADME from "../README.md";

const stories = storiesOf("Components/PlayPauseControl", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(PlayPauseControlREADME));

const BackgroundType = ["light", "dark"];
const Background = [
  "white",
  "black",
  "blue",
  "grayDark",
  "grayLighter",
  "grayLightest"
];

stories
  .add("Default", () => (
    <Section background={select("Background", Background, "grayLighter")}>
      <PlayPauseControl
        backgroundType={select("Background Type", BackgroundType, "light")}
        playAriaLabel={text("Play aria label", "Play button")}
        pauseAriaLabel={text("Pause aria label", "Pause button")}
        handleClick={action("pause-click")}
      />
    </Section>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Section background={select("Background", Background, "grayLighter")}>
        <PlayPauseControl
          backgroundType={select("Background Type", BackgroundType, "light")}
          playAriaLabel={text("Play aria label", "Play button")}
          pauseAriaLabel={text("Pause aria label", "Pause button")}
          handleClick={action("pause-click")}
        />
      </Section>
    </Language>
  ));
