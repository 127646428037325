import PropTypes from "prop-types";
import React, { Component } from "react";

import getClassNameFactory from "@emcm-ui/utility-class-names";
import SharePriceTracker from "@emcm-ui/component-share-price-tracker";
import ContinuousTicker from "@emcm-ui/component-continuous-ticker";
import PlayPauseControl from "@emcm-ui/component-play-pause-control";
import AccessibilityLink from "@emcm-ui/component-accessibility-link";

import { Consumer as StockTickerContextConsumer } from "../../StockTickerContext";

class TickerContinuous extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isStopped: false
    };
  }

  static displayName = "TickerContinuous";

  static propTypes = {
    /**
     *To get list of share prices data
     */
    shareDetails: PropTypes.array,
    /**
     * To set the background color
     */
    backgroundType: PropTypes.string,
    /**
     * To set the delay for total scroll
     */
    scrollDelay: PropTypes.number,
    /**
     * For date format
     */
    dateFormat: PropTypes.string,
    /**
     * To set the aria label for if no price change
     */
    staleAriaLabel: PropTypes.string,
    /**
     * To set the aria label for if increase in share price change
     */
    increasedAriaLabel: PropTypes.string,
    /**
     * To set the aria label for if decrease in price change
     */
    decreasedAriaLabel: PropTypes.string,
    /**
     * To set the aria label for play button
     */
    playAriaLabel: PropTypes.string,
    /**
     * To set the aria label for pause button
     */
    pauseAriaLabel: PropTypes.string,
    /**
     * Skip Link label
     */
    skipLinkLabel: PropTypes.string,
    /**
     * Skip link target element
     */
    skipLinkTargetElement: PropTypes.string,
    /**
     * Hide the time in the stock
     */
    hideTime: PropTypes.bool
  };

  handleClick = () => {
    this.setState(prevState => ({
      isStopped: !prevState.isStopped
    }));
  };

  getClassName = getClassNameFactory(TickerContinuous.displayName);

  render() {
    const {
      backgroundType,
      shareDetails,
      scrollDelay,
      dateFormat,
      staleAriaLabel,
      decreasedAriaLabel,
      increasedAriaLabel,
      playAriaLabel,
      pauseAriaLabel,
      skipLinkLabel,
      skipLinkTargetElement,
      hideTime
    } = this.props;

    const { isStopped } = this.state;
    const targetElement = skipLinkTargetElement || "skip-to-ticker-list-end";

    return (
      <div className={this.getClassName({ modifiers: backgroundType })}>
        <div className={this.getClassName({ descendantName: "inner" })}>
          <AccessibilityLink
            targetElement={targetElement}
            label={skipLinkLabel}
            backgroundType={backgroundType}
          />
          <ContinuousTicker
            offset={0}
            mode="chain"
            speed={scrollDelay}
            move={!isStopped}
          >
            {(index, tabIndex) => {
              const modIndex =
                index + 1 > shareDetails.length
                  ? index % shareDetails.length
                  : index;

              return (
                <SharePriceTracker
                  overlay
                  format={dateFormat}
                  indexes={shareDetails[modIndex]}
                  ticker={true}
                  backgroundType={backgroundType}
                  useLocalTimeZone={true}
                  href={`${shareDetails[modIndex].indexDetails.pagePath}`}
                  staleAriaLabel={staleAriaLabel}
                  decreasedAriaLabel={decreasedAriaLabel}
                  increasedAriaLabel={increasedAriaLabel}
                  tabIndex={tabIndex}
                  showTimeZone={!hideTime}
                />
              );
            }}
          </ContinuousTicker>
        </div>
        <div
          className={this.getClassName({
            descendantName: "fade-mask",
            modifiers: backgroundType
          })}
        />
        <div className={this.getClassName({ descendantName: "buttonWrapper" })}>
          <PlayPauseControl
            id={"ticker-play-pause-button"}
            backgroundType={backgroundType}
            handleClick={this.handleClick}
            defaultState={isStopped}
            playAriaLabel={playAriaLabel}
            pauseAriaLabel={pauseAriaLabel}
          />
        </div>
        <span
          className="skip-to-ticker-list-end"
          tabIndex="-1"
          aria-hidden="true"
        />
      </div>
    );
  }
}

TickerContinuous.defaultProps = {
  playAriaLabel: "Play stock ticker",
  pauseAriaLabel: "Pause stock ticker"
};

const TickerContinuousWithContext = ({ ...props }) => (
  <StockTickerContextConsumer>
    {contextValue => <TickerContinuous {...contextValue} {...props} />}
  </StockTickerContextConsumer>
);

export default TickerContinuousWithContext;
