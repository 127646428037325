import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Section from "@emcm-ui/component-section";
import Grid from "@emcm-ui/component-grid";
import { PairedCard } from "@emcm-ui/component-card";
import CardFooter from "../CardFooter/CardFooter";
import Typestack from "@emcm-ui/component-typestack";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";
import { FEATURED_SECTION_CARDS_COUNT } from "../../utilities/constants";

const FeaturedCards = ({ featured, includeDescription, ...footerProps }) => {
  const getClassName = getClassNameFactory("SearchResultsMixture");
  const { featuredText, featuredCards } = featured;
  const featuredSectionCards = featuredCards.slice(
    0,
    FEATURED_SECTION_CARDS_COUNT
  );

  const cards = featuredSectionCards.map((card, index) => {
    const {
      path,
      eyebrow,
      title,
      description,
      image,
      featureBillBoardImageAltText,
      ...cardFooter
    } = card;
    const combinedProps = { ...cardFooter, ...footerProps };

    return (
      <Grid.Item key={index}>
        <PairedCard
          background="transparent"
          borderVariant="borderless"
          href={path}
          eyebrow={eyebrow}
          heading={title}
          description={includeDescription && description}
          headingRank="h4"
          imgSrc={image}
          alt={featureBillBoardImageAltText}
          footer={<CardFooter {...combinedProps} />}
        />
      </Grid.Item>
    );
  });

  return (
    <div className={getClassName({ descendantName: "featuredCards" })}>
      <Section background="grayLightest">
        <VerticalSpacing size="m">
          <div className={getClassName({ descendantName: "featuredHeading" })}>
            <Typestack align="left" rank="h3" type="h3">
              {featuredText}
            </Typestack>
          </div>
        </VerticalSpacing>
        <Grid variant="even-3">{cards}</Grid>
      </Section>
    </div>
  );
};

FeaturedCards.displayName = "SearchResults.FeaturedCards";

FeaturedCards.propTypes = {
  /**
   * featured information
   */
  featured: PropTypes.object,
  /**
   * Boolean value to display description of card
   */
  includeDescription: PropTypes.bool
};

export default FeaturedCards;
