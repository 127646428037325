import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text, number } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import ResultSummary from "./index";
import "./ResultSummary";
import SearchResultsMixtureREADME from ".../../../README.md";

const stories = storiesOf(
  "Components/SearchMixedCard/Components/ResultSummary",
  module
);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(SearchResultsMixtureREADME));

const total = 100;
const count = 18;
const current = 1;

stories
  .add("Default", () => (
    <ResultSummary
      search={{
        totalResults: number("Total results", total),
        searchKey: text("Search key", "key"),
        hasSearchTerm: true,
        cardsCount: number("Cards par page", count)
      }}
      page={{
        currentPage: number("Current page", current)
      }}
      viewText={text(
        "View text",
        "Viewing {start}-{end} of {total} for {term}"
      )}
    />
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <ResultSummary
        search={{
          totalResults: number("Total results", total),
          searchKey: text("Search key", "key"),
          hasSearchTerm: true,
          cardsCount: number("Cards par page", count)
        }}
        page={{
          currentPage: number("Current page", current)
        }}
        viewText={text(
          "View text",
          "عرض {البداية}-{النهاية} من {total} لمدة {term}"
        )}
      />
    </Language>
  ));
