import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const Standfirst = ({ children }) => {
  const getClassName = getClassNameFactory(Standfirst.displayName);

  return (
    <p className={getClassName({ className: typestack("subline2") })}>
      {children}
    </p>
  );
};

Standfirst.displayName = "Standfirst";

Standfirst.propTypes = {
  /**
   *  Children should be restricted to text, <a>, <em> and <strong>
   */
  children: PropTypes.node
};

export default Standfirst;
