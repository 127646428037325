import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import Icon from "@emcm-ui/component-icon";
import GlobalHeader from "@emcm-ui/component-global-header";
import Section from "@emcm-ui/component-section";
import Link from "@emcm-ui/component-link";
import AccessibilityLink from "./index";
import GlobalNav from "@emcm-ui/component-global-nav";
import "./AccessibilityLink.css";
import AccessibilityLinkREADME from "../README.md";

const leftItems = (
  <React.Fragment>
    <GlobalHeader.Item
      narrow
      label="explore"
      longLabel="Explore Refinitiv"
      href="/directory"
    >
      <GlobalHeader.Column>
        <GlobalHeader.NavList>
          <GlobalHeader.NavItem
            title="Contact item 1"
            href="#"
            icon={<Icon path="./icons/ui/grey/tick-07.svg" size="s" />}
          />
          <GlobalHeader.NavItem
            title="Contact item 2"
            href="#"
            icon={<Icon path="./icons/ui/grey/document-07.svg" size="s" />}
          />
          <GlobalHeader.NavItem
            title="Contact item 3 with more text"
            href="#"
            icon={<Icon path="./icons/ui/grey/unlocked-07.svg" size="s" />}
          />
        </GlobalHeader.NavList>
      </GlobalHeader.Column>
    </GlobalHeader.Item>
  </React.Fragment>
);

const rightItems = (
  <React.Fragment>
    <GlobalHeader.Login
      accountLabel="Account"
      accountOverviewLabel="Account overview"
      accountOverviewIcon={<Icon path="./icons/ui/grey/user-07.svg" size="s" />}
      helloLabel="Hello"
      id="login"
      loginLabel="Login"
      logoutLabel="Logout"
    />
    <GlobalHeader.Item narrow label="Contact" href="/contact">
      <GlobalHeader.Column>
        <GlobalHeader.NavList title="explore our sites">
          <GlobalHeader.NavItem
            title="Contact item 1"
            href="#"
            icon={<Icon path="./icons/ui/grey/tick-07.svg" size="s" />}
          />
          <GlobalHeader.NavItem
            title="Contact item 2"
            href="#"
            icon={<Icon path="./icons/ui/grey/document-07.svg" size="s" />}
          />
          <GlobalHeader.NavItem
            title="Contact item 3 with more text"
            href="#"
            icon={<Icon path="./icons/ui/grey/unlocked-07.svg" size="s" />}
          />
        </GlobalHeader.NavList>
      </GlobalHeader.Column>
    </GlobalHeader.Item>
  </React.Fragment>
);

const options = [
  {
    value: "https://www.lseg.com/",
    label: "LSEG"
  },
  {
    value: "https://www.refinitiv.com/",
    label: "Refinitiv"
  },
  {
    value: "https://www.ftserussell.com/",
    label: "FTSE Russell"
  },
  {
    value: "https://www.londonstockexchange.com/",
    label: "London Stock Exchange"
  },
  {
    value: "https://www.lseg.com/en/solutions/fx",
    label: "LSEG FX"
  },
  {
    value: "https://www.lch.com/",
    label: "LCH"
  }
];

const selectedIndex = 0;

const stories = storiesOf("Components/AccessibilityLink", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(AccessibilityLinkREADME));

stories
  .add("Default", () => (
    <React.Fragment>
      <AccessibilityLink
        href={text("href", "#content-section")}
        label={text("text", "Skip navigations")}
        position={select("position", AccessibilityLink.positions, "left")}
      />
      <GlobalHeader leftItems={leftItems} rightItems={rightItems} />
      <div id="content-section">
        <Section>
          <Link href="#">Link from content section</Link>
        </Section>
      </div>
    </React.Fragment>
  ))
  .add("By Target Element", () => (
    <React.Fragment>
      <AccessibilityLink
        targetElement={text("Target Element", "content-section")}
        label={text("text", "Skip navigations")}
        position={select("position", AccessibilityLink.positions, "left")}
        backgroundType={select(
          "background type",
          AccessibilityLink.backgroundType,
          "dark"
        )}
      />
      <GlobalHeader leftItems={leftItems} rightItems={rightItems} />
      <div className="content-section">
        <Section>
          <Link href="#">Link from content section</Link>
        </Section>
      </div>
    </React.Fragment>
  ))
  .add("With Global Nav", () => (
    <React.Fragment>
      <AccessibilityLink
        href={text("href", "#content-section")}
        label={text("text", "Skip navigations")}
        position={select("position", AccessibilityLink.positions, "left")}
        backgroundType={select(
          "background type",
          AccessibilityLink.backgroundType,
          "dark"
        )}
      />
      <GlobalNav
        options={options}
        selected={selectedIndex}
        title="Our businesses:"
      />
      <div id="content-section">
        <Section>
          <Link href="#">Link from content section</Link>
        </Section>
      </div>
    </React.Fragment>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <AccessibilityLink
        href="#content-section"
        position="right"
        label={text("Text", "مرحبا بالعالم")}
        backgroundType={select(
          "background type",
          AccessibilityLink.backgroundType,
          "dark"
        )}
      />
      <GlobalHeader leftItems={leftItems} rightItems={rightItems} />
      <div id="content-section">
        <Section>
          <Link href="#">Link from content section</Link>
        </Section>
      </div>
    </Language>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <AccessibilityLink
        href={text("href", "#content-section")}
        label={text("text", "Skip navigations")}
        position={select("position", AccessibilityLink.positions, "left")}
        backgroundType={select(
          "background type",
          AccessibilityLink.backgroundType,
          "dark"
        )}
      />
      <GlobalHeader leftItems={leftItems} rightItems={rightItems} />
      <div id="content-section">
        <Section>
          <Link href="#">Link from content section</Link>
        </Section>
      </div>
    </StaticRenderer>
  ));
