import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

class Tooltip extends Component {
  static displayName = "Tooltip";
  static Positions = ["top", "bottom"];

  static propTypes = {
    /**
     * React node where we need to show the tooltip
     */
    children: PropTypes.node,
    /**
     * Tooltip text
     */
    tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /**
     * Position of tooltip either top or bottom.
     */
    position: PropTypes.oneOf(Tooltip.Positions),
    /**
     * Where we need to attach the tooltip. Default is body
     */
    portToElement: PropTypes.any
  };

  static defaultProps = {
    position: "top",
    portToElement: typeof document === "object" && document.body
  };

  tooltipRef = React.createRef(null);
  state = {
    isVisible: false
  };

  getClassName = getClassNameFactory(Tooltip.displayName);

  show = element => {
    this.setState({ isVisible: true });
    this.setTooltipPosition(element);
  };

  hide = () => this.setState({ isVisible: false });

  setTooltipPosition = element => {
    const NUMBER_TWO = 2;
    const { x, y, width, height } = element.target.getBoundingClientRect();
    const leftPosition = x + width / NUMBER_TWO;
    let topPosition = y + (window.scrollY - height / NUMBER_TWO);

    if (this.props.position === "bottom") {
      topPosition = y + height + window.scrollY;
    }
    this.tooltipRef.current = {
      left: leftPosition, // add half the width of the element for centering
      top: topPosition // add scrollY offset, as soon as getBoundingClientRect takes on screen coords
    };
  };

  handleKeyDown = element => {
    const enterKey = 13;
    const spaceKey = 32;
    const isAllowedKey =
      element.keyCode === enterKey || element.keyCode === spaceKey;

    if (isAllowedKey) {
      this.show(element);
    } else {
      this.hide(element);
    }
  };

  render() {
    const { children, tooltipText, position, portToElement } = this.props;
    const { isVisible } = this.state;
    const { current } = this.tooltipRef;

    /* eslint-disable jsx-a11y/mouse-events-have-key-events */
    return (
      <div
        className={this.getClassName()}
        data-tool-tip-text={tooltipText}
        data-position={position}
        data-rehydratable={getRehydratableName(Tooltip.displayName)}
        role="tooltip"
      >
        <button
          aria-label={tooltipText}
          aria-haspopup="true"
          aria-expanded="false"
          aria-controls="popover"
          onMouseEnter={this.show}
          onMouseLeave={this.hide}
          ref={this.wrapper}
          className={this.getClassName({
            descendantName: "inner"
          })}
          tabIndex={0}
          onKeyDown={this.handleKeyDown}
        >
          {children}
          {isVisible &&
            ReactDOM.createPortal(
              <div
                style={{ ...current }}
                className={this.getClassName({
                  descendantName: "label",
                  modifiers: position
                })}
                id="popover"
              >
                {tooltipText}

                <div
                  className={this.getClassName({
                    descendantName: "arrow",
                    modifiers: position
                  })}
                />
              </div>,
              portToElement
            )}
        </button>
      </div>
    );
    /* eslint-enable jsx-a11y/mouse-events-have-key-events */
  }
}

export default Tooltip;
