import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";
import dateFnsFormat from "date-fns/format";
import DateTime from "@emcm-ui/component-date-time";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { constructAriaLabel } from "@emcm-ui/utility-accessibility";
import { canFireEvents, analyticsClasses } from "@emcm-ui/utility-analytics";
import Heading from "@emcm-ui/component-heading";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";
import momenttimezone from "moment-timezone";

import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

class SharePriceTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {}
    };
    this.isUnMounted = false;
    this.anchorRef = React.createRef();
  }

  static displayName = "SharePriceTracker";

  static propTypes = {
    /**
     * The share price tracker title.
     */
    title: PropTypes.string,
    /**
     * Background color
     */
    backgroundType: PropTypes.string,
    /**
     *To get share price data by api
     */
    apiUrl: PropTypes.string,
    /**
     *To set share price tracker on absolute position
     */
    overlay: PropTypes.bool,
    /**
     *To set date time format
     */
    format: PropTypes.string,
    /**
     * Index details for the tracker without api call
     */
    indexes: PropTypes.object,
    /**
     * Will remove borders if it true
     */
    borderless: PropTypes.bool,
    /**
     * To set the ticker variant
     */
    ticker: PropTypes.bool,
    /**
     * To convert the date time to user location
     */
    useLocalTimeZone: PropTypes.bool,
    /**
     * To show/hide the time zone label
     */
    showTimeZone: PropTypes.bool,
    /**
     * Link to page to navigate on click
     */
    href: PropTypes.string,
    /**
     * To set the aria label for if no price change. It requires placeholders like
     * {code} -> Will replace by share price name
     * {price} -> will replace by share price last value
     * {currency} -> will replace by share price currency
     * {date} -> will replace by last updated date and time
     */
    staleAriaLabel: PropTypes.string,
    /**
     * To set the aria label for if increase in share price change. It requires placeholder like
     * {code} -> Will replace by share price name
     * {netChange} -> will replace by share price change from last value
     * {percentualChange} -> will replace by percentage of share price change from last value
     * {price} -> will replace by share price last value
     * {currency} -> will replace by share price currency
     * {date} -> will replace by last updated date and time
     */
    increasedAriaLabel: PropTypes.string,
    /**
     * To set the aria label for if decrease in price change. It requires placeholder like
     * {code} -> Will replace by share price name
     * {netChange} -> will replace by share price change from last value
     * {percentualChange} -> will replace by percentage of share price change from last value
     * {price} -> will replace by share price last value
     * {currency} -> will replace by share price currency
     * {date} -> will replace by last updated date and time
     */
    decreasedAriaLabel: PropTypes.string,
    /**
     * To set the tabIndex for the anchor tag
     */
    tabIndex: PropTypes.string
  };

  componentDidMount() {
    if (this.props.apiUrl) {
      fetch(this.props.apiUrl, {
        method: "GET",
        credentials: "same-origin"
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          }
        })
        .then(json => {
          if (!this.isUnMounted) {
            this.setState({
              data: json.data
            });
          }
        })
        .catch(err => {
          return err;
        });
    }
  }

  componentWillUnmount() {
    this.isUnMounted = true;
  }

  numberWithCommas = (value, cur) => {
    const formatter = new Intl.NumberFormat("en-US", {
      currency: cur,
      minimumFractionDigits: 1
    });

    return formatter.format(value);
  };

  getClassName = getClassNameFactory(SharePriceTracker.displayName);

  getPriceChangeClass = price => {
    if (price === 0 || !price) {
      return "";
    }

    if (price > 0) {
      return "positive";
    }

    return "negative";
  };

  getPriceChangeData = (price, sign = "") => {
    if (price === 0 || !price) {
      return `0.0${sign}`;
    }

    if (price > 0) {
      return `+${price.toFixed(1)}${sign}`;
    }

    return `${price.toFixed(1)}${sign}`;
  };

  getAriaLabel = () => {
    const {
      indexes,
      apiUrl,
      format,
      staleAriaLabel,
      decreasedAriaLabel,
      increasedAriaLabel
    } = this.props;
    const { data } = this.state;
    const indexData = indexes && !apiUrl ? indexes : data;
    const {
      code,
      netChange,
      price,
      percentualChange,
      time,
      currency
    } = indexData;
    let dateString;

    if (time) {
      const theDate = new Date(time.replace(/ /g, "T"));
      const dateTime = dateFnsFormat(theDate, format);

      dateString = `${dateTime} ${this.getLocalTimeZone()}`;
    }
    let placeholderString = staleAriaLabel;
    const placeholderObj = {
      code,
      price,
      netChange: Math.abs(netChange.toFixed(1)),
      percentualChange: Math.abs(percentualChange.toFixed(1)),
      currency: currency || "",
      date: dateString || ""
    };

    if (netChange < 0) {
      placeholderString = decreasedAriaLabel;
    } else if (netChange > 0) {
      placeholderString = increasedAriaLabel;
    }

    const ariaLabel = constructAriaLabel(placeholderString, placeholderObj);

    return ariaLabel;
  };

  getFormattedDate = (timestamp, format) => {
    const { showTimeZone } = this.props;
    const iso = new Date(timestamp).toISOString();
    const theiso = iso.replace("Z", "");

    return (
      <React.Fragment>
        <DateTime dateTime={theiso} format={format} /> {showTimeZone && "GMT"}
      </React.Fragment>
    );
  };

  /**
   * To get the user local timezone in short form. Ex: "BST", "IST", "PST" etc.,
   * @returns string
   */
  getLocalTimeZone = () => {
    const zone = momenttimezone.tz.guess();
    const localTimeZone = momenttimezone.tz(zone).format("z");

    return localTimeZone;
  };

  getLocalFormattedDate = (timestamp, format) => {
    const { showTimeZone } = this.props;

    return (
      <React.Fragment>
        <DateTime dateTime={timestamp} format={format} />{" "}
        {showTimeZone && this.getLocalTimeZone()}
      </React.Fragment>
    );
  };

  isMarketOpen = data => {
    if (!data) {
      return false;
    }

    return this.getPriceChangeClass(data.price) !== "";
  };

  getAttributes = () => {
    const { href, apiUrl, indexes } = this.props;
    const { data } = this.state;

    if (!href) {
      return;
    }

    const getStockDetails = () => {
      const indexData = indexes && !apiUrl ? indexes : data;
      const {
        price,
        currency = "",
        netChange,
        percentualChange,
        code
      } = indexData;
      const priceChange = this.getPriceChangeData(netChange);
      const percentageChange = this.getPriceChangeData(percentualChange, "%");

      return {
        code,
        stockDetails: `${price}${currency} ${priceChange} (${percentageChange})`
      };
    };

    const analyticAttributes = {};
    const ariaLabel = this.getAriaLabel();

    if (canFireEvents()) {
      const { code, stockDetails } = getStockDetails();

      analyticAttributes["data-asset-title"] = `${code} | ${stockDetails}`;
      analyticAttributes[
        "data-find-method"
      ] = `Stock Ticker Link | ${stockDetails}`;
    }

    return {
      href,
      "aria-label": ariaLabel,
      ...analyticAttributes
    };
  };

  render() {
    const {
      apiUrl,
      backgroundType,
      overlay,
      format,
      title,
      indexes,
      borderless,
      useLocalTimeZone,
      href,
      tabIndex,
      ticker
    } = this.props;
    const { data } = this.state;

    const indexData = indexes && !apiUrl ? indexes : data;

    const marketOpen = this.isMarketOpen(indexData);

    const {
      price,
      currency,
      netChange,
      percentualChange,
      code,
      time
    } = indexData;
    const Element = href ? "a" : "div";
    const isNoChangeInPrice = !(netChange === 0 || !netChange);

    return (
      <div
        className={this.getClassName()}
        data-rehydratable={getRehydratableName(SharePriceTracker.displayName)}
        data-api-url={apiUrl}
        data-date-format={format}
        data-overlay={overlay}
        data-background-type={backgroundType}
        data-title={title}
        data-borderless={borderless}
        data-ticker={ticker}
      >
        {(indexes || marketOpen) && (
          <React.Fragment>
            {title && <Heading type="xxs">{title}</Heading>}
            <Element
              ref={this.anchorRef}
              className={classNames(
                this.getClassName({
                  descendantName: "container",
                  modifiers: classNames(backgroundType, overlay ? "" : "tool"),
                  states: classNames({ ticker, borderless, href })
                }),
                analyticsClasses.trackClicks
              )}
              data-rehydratable={getRehydratableName(
                SharePriceTracker.displayName
              )}
              {...tabIndex && { tabIndex }}
              {...this.getAttributes()}
            >
              {!overlay && (
                <span
                  className={this.getClassName({
                    descendantName: "code",
                    className: typestack("p1Bold")
                  })}
                >
                  {code}:
                </span>
              )}

              <span
                className={this.getClassName({
                  descendantName: "price",
                  className: typestack("h5")
                })}
              >
                {this.numberWithCommas(price, currency)}
              </span>

              <span
                className={this.getClassName({
                  descendantName: "currency",
                  className: typestack("p1")
                })}
              >
                {currency}
              </span>

              {isNoChangeInPrice && (
                <React.Fragment>
                  <span
                    className={this.getClassName({
                      modifiers: classNames(
                        "shareValues",
                        this.getPriceChangeClass(netChange)
                      ),
                      className: typestack("p1")
                    })}
                  >
                    {/* The significance of adding "&lrm;" is to make sign should follow mandatory left to right */}
                    &lrm;{this.getPriceChangeData(netChange)}
                  </span>

                  <span
                    className={this.getClassName({
                      modifiers: classNames(
                        "shareValues",
                        this.getPriceChangeClass(percentualChange)
                      ),
                      className: typestack("p1")
                    })}
                  >
                    {/* The significance of adding "&lrm;" is to make brackets should follow mandatory left to right */}
                    &lrm;({this.getPriceChangeData(percentualChange, "%")})
                  </span>
                </React.Fragment>
              )}
              <div
                className={this.getClassName({
                  descendantName: "calendar"
                })}
              >
                {overlay && (
                  <span
                    className={this.getClassName({
                      descendantName: "title",
                      className: typestack("p2Bold")
                    })}
                  >
                    {code}
                  </span>
                )}

                {time && (
                  <span
                    className={this.getClassName({
                      descendantName: "date",
                      className: typestack("p3")
                    })}
                  >
                    {useLocalTimeZone
                      ? this.getLocalFormattedDate(time, format)
                      : this.getFormattedDate(time, format)}
                  </span>
                )}
              </div>
            </Element>
          </React.Fragment>
        )}
      </div>
    );
  }
}

SharePriceTracker.defaultProps = {
  backgroundType: "light",
  overlay: true,
  format: "dd MMM, hh:mm z",
  title: null,
  staleAriaLabel:
    "{code} stock has no change to {price} {currency}. Updated on {date}.",
  increasedAriaLabel:
    "{code} stock has increased by {netChange} or {percentualChange} percent to {price} {currency}. Updated on {date}.",
  decreasedAriaLabel:
    "{code} stock has decreased by {netChange} or {percentualChange} percent to {price} {currency}. Updated on {date}.",
  showTimeZone: true
};

export default SharePriceTracker;
