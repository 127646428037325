import React from "react";
import PropTypes from "prop-types";
import PageNav from "@emcm-ui/component-page-nav";
import {
  ELASTIC_SEARCH_QUERY_KEY,
  FILTER_THEMES_ID
} from "../../utilities/constants";

const Filters = ({ filterSetting, search }) => {
  const { filters, activeFilter, onFilterSelect } = filterSetting;
  const { hasSearchTerm } = search;

  if (hasSearchTerm) {
    return null;
  }

  const onClick = ({ name }) => {
    if (onFilterSelect) {
      const link = filters.filter(item => item.name === name)[0].link;
      const linkParams = new URLSearchParams(link);

      const response = {
        activeFilter: name,
        link,
        ...(link && {
          lastAppliedFilterKey:
            linkParams.get(ELASTIC_SEARCH_QUERY_KEY) || FILTER_THEMES_ID
        })
      };

      onFilterSelect(response);
    }
  };

  const filterList = filters.map(({ title, name }, index) => {
    return (
      <PageNav.Item
        active={name === activeFilter}
        title={title}
        key={index}
        href=""
        onClick={onClick}
        datasets={JSON.stringify({ name })}
      />
    );
  });

  return (
    <PageNav sticky={false} spy={false}>
      {filterList}
    </PageNav>
  );
};

Filters.displayName = "SearchResults.Filters";

Filters.propTypes = {
  /**
   * filters information
   */
  filterSetting: PropTypes.object,
  /**
   * search information
   */
  search: PropTypes.object
};

export default Filters;
