import PropTypes from "prop-types";
import kebabCase from "lodash.kebabcase";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Menu from "./components/Menu";
import Item from "./components/Item";
import { Provider as VerticalNavProvider } from "./verticalNavContext";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import { AjaxContentContext } from "@emcm-ui/component-ajax-content";
import classNames from "classnames";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

class VerticalNav extends Component {
  static displayName = "VerticalNav";

  static propTypes = {
    /**
     * children for Verical Navigation
     */
    children: PropTypes.node,
    /**
     * To know whether rehydration happens or not. Don't use it
     */
    isRehydrating: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.stickyRef = React.createRef();
    this.stickyOffset = 0;
    this.initialVerticalNavTop = 0;
    this.verticalNavRect = null;
  }

  static getLocationHash = () => {
    if (window && window.location && window.location.hash) {
      return window.location.hash.substr(1);
    }

    return null;
  };

  static setLocationHash = hash => {
    if (window && window.location) {
      window.location.hash = hash;
    }
  };

  state = {
    selectedItem: "",
    expanded: false,
    sticky: false
  };

  handleClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  updateFromHash() {
    const locationHash = VerticalNav.getLocationHash();
    const queryElement = document.querySelector(`[data-id=${locationHash}]`);

    if (locationHash && queryElement) {
      queryElement.click();
    }
  }

  handleScroll = () => {
    this.verticalNavRect = this.stickyRef.current.getBoundingClientRect();

    if (this.verticalNavRect.top <= this.stickyOffset) {
      this.stickyRef.current.style.top = `${this.stickyOffset}px`;
      this.setState({ sticky: true });
    }

    if (document.documentElement.scrollTop <= this.initialVerticalNavTop) {
      this.setState({ sticky: false });
    }
  };

  componentDidMount() {
    const getPageNavClassName = getClassNameFactory("PageNav");
    const getPageNavAnchorClassName = getClassNameFactory("PageNavAnchor");

    this.verticalNavRect = this.stickyRef.current.getBoundingClientRect();

    if (
      document.querySelectorAll(`.${getPageNavAnchorClassName()}`).length > 0
    ) {
      const pageNavRect = document
        .querySelector(`.${getPageNavClassName()}--sticky`)
        .getBoundingClientRect();

      document
        .querySelectorAll(`.${getPageNavAnchorClassName()}`)
        .forEach(pageNavAnchor => {
          const pageNavAnchorRect = pageNavAnchor.getBoundingClientRect();

          if (this.verticalNavRect.top === pageNavAnchorRect.top) {
            // inside pagenav
            this.stickyOffset = pageNavRect.height;
          }
        });
    }

    this.initialVerticalNavTop = this.verticalNavRect.top;

    this.updateFromHash();
    if (window) {
      window.addEventListener("hashchange", this.updateFromHash);
      document.addEventListener("scroll", this.handleScroll);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.handleScroll);
  }

  onItemClick = ({
    label,
    href,
    ajaxContext,
    event,
    isAjax,
    isUrlHashActive,
    dataAnchor
  }) => {
    if (isAjax) {
      if (event) {
        event.preventDefault();
      }
      ajaxContext.updateHref(href);
      if (isUrlHashActive) {
        VerticalNav.setLocationHash(`#${kebabCase(label)}`);
      }
    } else if (event) {
      this.scrollToSection(href);
    }

    this.setState({ selectedItem: label, expanded: false });

    if (dataAnchor) {
      this.scrollToAnchor(dataAnchor);
    }
  };

  scrollToSection = href => {
    const isMobile = this.verticalNavRect.width > this.verticalNavRect.height;
    const offset = isMobile
      ? this.stickyOffset + this.verticalNavRect.height
      : this.stickyOffset;

    if (href === "#") {
      location.href = href;

      return;
    }

    window.scrollTo({
      top: document.querySelector(href).offsetTop - offset,
      left: 0,
      behavior: "smooth"
    });
  };

  scrollToAnchor = dataAnchor => {
    const anchorID = `#${dataAnchor}`;
    const anchorTop = document.querySelector(anchorID).offsetTop;

    window.scrollTo({
      top: anchorTop - this.stickyOffset,
      left: 0,
      behavior: "smooth"
    });
  };

  render() {
    const { children } = this.props;
    const { selectedItem, expanded, sticky } = this.state;
    const getClassName = getClassNameFactory(VerticalNav.displayName);
    const contextValue = {
      selectedItem,
      onItemClick: this.onItemClick
    };

    return (
      <AjaxContentContext.Consumer>
        {ajaxContext => {
          return (
            <VerticalNavProvider value={contextValue}>
              <div
                className={getClassName({
                  states: classNames({ expanded }, { sticky })
                })}
                data-rehydratable={getRehydratableName(VerticalNav.displayName)}
                ref={this.stickyRef}
              >
                <button
                  className={getClassName({
                    descendantName: "currentItem"
                  })}
                  onClick={this.handleClick}
                >
                  <span
                    className={getClassName({
                      descendantName: "currentItemIcon"
                    })}
                  >
                    <SVGIcon name="menu" size="s" />
                  </span>
                  <span
                    className={getClassName({
                      descendantName: "currentItemTitle"
                    })}
                  >
                    {selectedItem}
                  </span>
                </button>

                <div
                  className={getClassName({
                    descendantName: "children",
                    className: typestack("p1")
                  })}
                >
                  {children &&
                    children.props && (
                      <children.type
                        indent={0}
                        {...children.props}
                        key={children.props.label}
                        ajaxContext={ajaxContext}
                      />
                    )}

                  {children.length > 0 &&
                    children.map(element => {
                      if (element) {
                        const Element = element.type;

                        return (
                          <Element
                            indent={0}
                            {...element.props}
                            key={element.props.label}
                            ajaxContext={ajaxContext}
                          />
                        );
                      }

                      return false;
                    })}
                </div>
              </div>
            </VerticalNavProvider>
          );
        }}
      </AjaxContentContext.Consumer>
    );
  }
}

VerticalNav.Menu = Menu;
VerticalNav.Item = Item;
export default VerticalNav;
