import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import classNames from "classnames";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const Badge = props => {
  const getClassName = getClassNameFactory(Badge.displayName);

  const { text, type, background, color } = props;

  const isBackground = background === "dark";

  return (
    <div
      className={getClassName({
        modifiers: classNames(type, color, isBackground && "background"),
        className: typestack("subline1")
      })}
    >
      {text}
    </div>
  );
};

Badge.displayName = "Badge";

Badge.type = ["badge", "tag"];

Badge.color = ["blue", "orange", "green"];

Badge.background = ["light", "dark"];

Badge.propTypes = {
  /**
   * Badge text
   */
  text: PropTypes.string,
  /**
   * Badge shape squared or pilled
   */
  type: PropTypes.string,
  /**
   * Badge background style
   */
  background: PropTypes.string,
  /**
   * Badge color style
   */
  color: PropTypes.string
};

export default Badge;
