import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Section from "@emcm-ui/component-section";
import Grid from "@emcm-ui/component-grid";
import Icon from "@emcm-ui/component-icon";
import RichText from "@emcm-ui/component-rich-text";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";
import Link from "@emcm-ui/component-link";
import Poster from "@emcm-ui/component-poster";
import Eyebrow from "@emcm-ui/component-eyebrow";
import Typestack from "@emcm-ui/component-typestack";
import {
  FEATURED_SECTION_CARDS_COUNT,
  FEATURED_BILLBOARD_CARD_INDEX
} from "../../utilities/constants";

const BillBoard = ({ featured, icon, readArticle }) => {
  const getClassName = getClassNameFactory("SearchResultsMixture");
  const { featuredCards } = featured;
  const shouldShowBillBoard =
    featuredCards.length > FEATURED_SECTION_CARDS_COUNT;

  if (!shouldShowBillBoard) {
    return null;
  }

  const billboardCard = featuredCards[FEATURED_BILLBOARD_CARD_INDEX];
  const {
    eyebrow,
    title,
    description,
    path,
    featureBillBoardImage
  } = billboardCard;

  return (
    <div className={getClassName({ descendantName: "billboard" })}>
      <Section background="grayDark" fullWidth>
        <Poster
          height="xs"
          imageSrc={featureBillBoardImage}
          imageInlineAtNarrow
        >
          <Grid variant="even-2" gutterlessAtNarrow>
            <Grid.Item>
              <VerticalSpacing size="xs">
                <Eyebrow text={eyebrow} />
              </VerticalSpacing>
              <VerticalSpacing size="xs">
                <Typestack align="left" rank="h2" type="h2">
                  {title}
                </Typestack>
              </VerticalSpacing>
              <VerticalSpacing size="l">
                <RichText large>
                  <p>{description}</p>
                </RichText>
              </VerticalSpacing>
              {readArticle && (
                <Link
                  href={path}
                  type="icon"
                  icon={<Icon path={icon.arrow} size="s" />}
                  iconPosition={"right"}
                >
                  {readArticle}
                </Link>
              )}
            </Grid.Item>
          </Grid>
        </Poster>
      </Section>
    </div>
  );
};

BillBoard.displayName = "SearchResults.BillBoard";

BillBoard.propTypes = {
  /**
   * featured information
   */
  featured: PropTypes.object,
  /**
   * icon
   */
  icon: PropTypes.object,
  /**
   * read article text
   */
  readArticle: PropTypes.string
};

export default BillBoard;
