import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text, boolean, select } from "@storybook/addon-knobs";
import { action } from "@storybook/addon-actions";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import AddonsAndExchanges from "./index";
import "./AddonsAndExchanges.css";
import AddonsAndExchangesREADME from "../README.md";

const stories = storiesOf("Components/E-Commerce/AddonsAndExchanges", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(AddonsAndExchangesREADME));

const ranks = ["h1", "h2", "h3", "h4", "h5"];

stories
  .add("Default", () => (
    <AddonsAndExchanges
      title={text("Title text", "Title placeholder")}
      showBadge={boolean("Show Badge", true)}
      badgeLabel={text("Badge Label", "Badge")}
      titleRank={select("Select title rank", ranks, ranks[2])}
      priceRank={select("Select price rank", ranks, ranks[4])}
      description={text(
        "Description",
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries"
      )}
      price={text("Price", "$0000")}
      priceDescription={text(
        "Price description",
        "Billed by LSEG on behalf of our third-party partners. LSEG monthly administration fee of 9% applies"
      )}
      buttonGroupAriaLabel={text("Aria-label for button group", "button group")}
      primaryButtonProps={{
        kind: select("Primary Button Kind", ["button", "link"], "button"),
        action: action("Primary button click"),
        label: text("Primary button label", "Add Item"),
        href: text("Primary Button Href", "https://example.com"),
        showIcon: boolean("Primary button icon", true)
      }}
      secondaryButtonProps={{
        kind: select("Secondary Button Kind", ["button", "link"], "button"),
        action: action("Secondary button click"),
        label: text("Secondary button label", "Learn More"),
        href: text("Secondary Button Href", "https://example.com"),
        showIcon: boolean("Secondary button icon", false)
      }}
    />
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <AddonsAndExchanges
        title="إيكون التحليل المالي"
        description={` إيكون هو التحليل المالي سطح المكتب والحل المحمول الذي يربط لك ذات
            الصلة، محتوى موثوق بها، رويترز الأخبار، وأماكن، والأسواق، ومجمعات
            السيولة، والزملاء.`}
        showBadge={boolean("Show Badge", true)}
        badgeLabel={text("Badge Label", "Badge")}
        price={"$0000"}
        priceDescription={`* إيكون هو التحليل المالي سطح المكتب والحل المحمول الذي يربط لك ذات
            الصلة، محتوى موثوق بها، رويترز الأخبار،`}
        buttonGroupAriaLabel={text(
          "Aria-label for button group",
          "button group"
        )}
        primaryButtonProps={{
          kind: select("Primary Button Kind", ["button", "link"], "button"),
          action: action("Primary button click"),
          label: text("Primary button label", "Add Item"),
          href: text("Primary Button Href", "https://example.com"),
          showIcon: boolean("Primary button icon", true)
        }}
        secondaryButtonProps={{
          kind: select("Secondary Button Kind", ["button", "link"], "button"),
          action: action("Secondary button click"),
          label: text("Secondary button label", "Learn More"),
          href: text("Secondary Button Href", "https://example.com"),
          showIcon: boolean("Secondary button icon", false)
        }}
      />
    </Language>
  ));
