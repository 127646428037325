import Anchor from "@emcm-ui/component-anchor";
import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";

class Button extends Component {
  static displayName = "Button";

  static colorOptions = [
    "primary",
    "secondary",
    "secondaryDark",
    "actionPositive"
  ];
  static buttonTypes = ["standard", "pill", "utility"];
  static buttonAuthentication = ["unauthenticated", "authenticated"];

  static propTypes = {
    /**
     * Aria label for anchor
     */
    ariaLabel: PropTypes.string,
    /**
     * Aria label for anchor
     */
    ariaLabelledBy: PropTypes.string,
    /**
     *To set button to active state. CSS class name : 'is-active'
     */
    active: PropTypes.bool,
    /**
     *To set button to block mode i.e. to take full width of the parent. CSS class name: tr-Button--block
     */
    block: PropTypes.bool,
    /**
     *To assign children components of the button
     */
    children: PropTypes.string.isRequired,
    /**
     *To set AJAX load content into modal, should provide href for a fallback where possible.
     */
    contentLocation: PropTypes.string,
    /**
     *To set color ( Primary | Secondary | ActionPositive ). CSS class name: (tr-Button--primary | tr-Button--secondary | tr-Button--actionPositive)
     */
    color: PropTypes.oneOf(Button.colorOptions),
    /**
     *To enable or disable the button. CSS class name: is-disabled
     */
    disabled: PropTypes.bool,
    /**
     *To set redirect link for the button.
     */
    href: PropTypes.string,
    /**
     *To set icon for the button.
     */
    userIcon: PropTypes.node,
    /**
     *To set icon for the button.
     */
    icon: PropTypes.node,
    /**
     *To make the button icon only. CSS class name: tr-Button--iconOnly
     */
    iconOnly: PropTypes.bool,
    /**
     * Unique identifier of button
     */
    id: PropTypes.string,
    /**
     *To set link/ button kind ( button | link | submit )
     */
    kind: PropTypes.oneOf(["button", "link", "submit"]).isRequired,
    /**
     * Modal Style value
     */
    modalStyle: PropTypes.oneOf(["flyInStyle", "dialogStyle"]),
    /**
     *Modal title of the opened modal flyin
     */
    modalTitle: PropTypes.string,
    /**
     *Close label of the opened modal flyin
     */
    closeLabel: PropTypes.string,
    /**
     *Bool for controlling modal close on away click
     */
    closeOnAwayClick: PropTypes.bool,
    /**
     *To set new window for redirect URL.
     */
    newWindow: PropTypes.bool,
    /**
     *To set onclick function for the button to do some custom action
     */
    onClick: PropTypes.func,
    /**
     *To set button type ( standard | pill ).CSS class name: (tr-Button--standard | tr-Button--pill)
     */
    type: PropTypes.oneOf(Button.buttonTypes),
    /**
     *To set button type ( standard | pill ).CSS class name: (tr-Button--standard | tr-Button--pill)
     */
    authentication: PropTypes.oneOf(Button.buttonAuthentication)
  };

  constructor(props) {
    super(props);

    this.getClassName = getClassNameFactory(Button.displayName);
  }

  render() {
    const {
      active,
      block,
      children,
      color,
      contentLocation,
      disabled,
      href,
      userIcon,
      icon,
      iconOnly,
      id,
      kind,
      onClick,
      newWindow,
      type,
      authentication,
      ariaLabel,
      ariaLabelledBy,
      modalStyle,
      modalTitle,
      closeLabel,
      closeOnAwayClick
    } = this.props;

    const elementId = id && `${id}-label`;
    const ariaLabelledByIds =
      ariaLabelledBy && `${ariaLabelledBy} ${elementId || ""}`;

    const bodyProps = {
      id: elementId,
      className: this.getClassName({
        descendantName: "body",
        utilities: icon && iconOnly && "hiddenVisually"
      })
    };

    const userIconProps = {
      className: this.getClassName({ descendantName: "userIcon" })
    };

    const iconProps = {
      className: this.getClassName({ descendantName: "icon" })
    };

    const changeTypestack = () => {
      switch (type) {
        case "pill":
          return "p2Bold";
        case "utility":
          return "p1";
        default:
          return "buttonBold";
      }
    };

    return (
      <Anchor
        href={href}
        className={this.getClassName({
          modifiers: classNames(
            color,
            type ? type : null,
            authentication ? authentication : null,
            block ? "block" : null,
            icon && iconOnly ? "iconOnly" : null
          ),
          states: classNames({
            active,
            disabled
          })
        })}
        typestackClass={changeTypestack()}
        contentLocation={contentLocation}
        disabled={disabled}
        newWindow={newWindow}
        onClick={onClick}
        kind={kind}
        ariaLabel={ariaLabel}
        ariaLabelledBy={ariaLabelledByIds}
        modalStyle={modalStyle}
        modalTitle={modalTitle}
        closeLabel={closeLabel}
        closeOnAwayClick={closeOnAwayClick}
      >
        {userIcon && <span {...userIconProps}>{userIcon}</span>}
        <span {...bodyProps}>{children}</span>
        {icon && <span {...iconProps}>{icon}</span>}
      </Anchor>
    );
  }
}

export default Button;
