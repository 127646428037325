import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";

import { typestack } from "@emcm-ui/component-typestack/lib/utilities";
import CartItem from "../Item/Item";

const List = props => {
  const {
    maxLicense,
    maxOnlineLicense,
    productValidity,
    licenseText,
    support,
    showRemoveLink,
    tooltipInfo,
    productDetails,
    productsType,
    removeProduct,
    getTotalPrice,
    products
  } = props;

  const getClassName = getClassNameFactory(List.displayName);

  const { productGroupNameLabel, productGroupQtyLabel } = productDetails;

  return (
    <React.Fragment key={productsType}>
      <div
        className={getClassName({
          descendantName: "productheader",
          className: typestack("p3Bold")
        })}
      >
        <p>{productGroupNameLabel}</p>
        <p>{productGroupQtyLabel}</p>
      </div>
      <ul className={getClassName({ descendantName: "products" })}>
        {products.map((item, count) => {
          return (
            <CartItem
              key={item.id}
              id={item.id}
              count={count}
              name={item.name}
              currency={item.currency}
              price={item.price}
              productValidity={productValidity}
              licenseText={licenseText}
              maxLicense={maxLicense}
              maxOnlineLicense={maxOnlineLicense}
              products={products}
              support={support}
              onChange={getTotalPrice}
              showRemoveLink={showRemoveLink}
              isBaseProduct={productsType === "products"}
              productsType={productsType}
              onRemove={removeProduct}
              tooltipInfo={tooltipInfo}
            />
          );
        })}
      </ul>
    </React.Fragment>
  );
};

List.displayName = "List";

List.propTypes = {
  /**
   * Maximum license options possible on the dropdown
   */
  maxLicense: PropTypes.number.isRequired,
  /**
   * Maximum purchasable online license possible
   */
  maxOnlineLicense: PropTypes.number.isRequired,
  /**
   * Object of products details
   */
  productDetails: PropTypes.object,
  /**
   * Validity for product license
   */
  productValidity: PropTypes.string.isRequired,
  /**
   * License text for the product item
   */
  licenseText: PropTypes.string.isRequired,
  /**
   * Support team information node
   */
  support: PropTypes.node.isRequired,
  /**
   * To hide/show remove link in each product
   */
  showRemoveLink: PropTypes.bool,
  /**
   * Tooltip info for the non base products
   */
  tooltipInfo: PropTypes.string,
  /**
   * Each product list type
   */
  productsType: PropTypes.oneOf(["products", "addons", "exchanges"]),
  /**
   * call back method to remove the product
   */
  removeProduct: PropTypes.func,
  /**
   * Callback function to update and get the total price
   */
  getTotalPrice: PropTypes.func,
  /**
   * Each product list
   */
  products: PropTypes.array
};

export default List;
