import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import classNames from "classnames";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import TypeaheadItem from "./components/TypeaheadItem";
import debounce from "lodash.debounce";
import uniqueId from "lodash.uniqueid";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const getRandomInt = (minFloat, maxFloat) => {
  const min = Math.ceil(minFloat);
  const max = Math.floor(maxFloat);

  return Math.floor(Math.random() * (max - min + 1)) + min;
};

class SearchInput extends Component {
  static displayName = "SearchInput";

  /* eslint-disable max-len */
  static propTypes = {
    /**
     * Whether this search input should have focus on page load. Note that only one input can have `autoFocus` set on a page.
     */
    autoFocus: PropTypes.bool,
    /**
     * Color theme of the search input.
     */
    colorTheme: PropTypes.oneOf(["light", "dark"]),
    /**
     * Blur callback.
     */
    onBlur: PropTypes.func,
    /**
     * handleInputChange callback.
     */
    handleInputChange: PropTypes.func,
    /**
     * Submission callback. Prevents default form submit if specified.
     */
    onSubmit: PropTypes.func,
    /**
     * Name of the input, used for form submission. Must be unique to form.
     */
    name: PropTypes.string,
    /**
     * Placeholder text.
     */
    placeholder: PropTypes.string,
    /**
     * Use typeahead for search suggestions.
     */
    typeahead: PropTypes.bool,
    /**
     * JSONP URL for typeahead. Required if `typeahead = true`.
     */
    typeaheadUrl: PropTypes.string,
    /**
     * Value for the input. Overrides internal value if updated.
     */
    value: PropTypes.string,
    /**
     * JSON URL for elasticKey.
     */
    elasticKey: PropTypes.string,
    /**
     * JSON URL for autosuggestion.
     */
    autosuggestion: PropTypes.string,
    /**
     * JSON URL for size.
     */
    size: PropTypes.number,
    /**
     * enables/disables reset search term functionality
     */
    resetSearch: PropTypes.bool,
    /**
     * enables/disables query parameter check
     */
    queryParam: PropTypes.bool,
    /**
     * minimum search character limit
     */
    searchMinLength: PropTypes.number,
    /**
     * Search button aria-label
     */
    searchSubmitAriaLabel: PropTypes.string,
    /**
     * Search clear button aria-label
     */
    searchClearAriaLabel: PropTypes.string
  };
  /* eslint-enable max-len */

  static defaultProps = {
    autoFocus: false,
    colorTheme: "light",
    name: "q",
    placeholder: "Search",
    typeahead: true,
    typeaheadUrl: "",
    value: "",
    elasticKey: "",
    autosuggestion: "description,w_internal_title",
    size: 7,
    resetSearch: false,
    queryParam: false,
    searchMinLength: 3,
    searchSubmitAriaLabel: "Submit search",
    searchClearAriaLabel: "Clear search"
  };

  constructor(props) {
    super(props);

    this.getClassName = getClassNameFactory(SearchInput.displayName);

    this.input = null;
    this.submitButton = null;

    const searchParams = this.getInitialQuery();

    this.state = {
      userQuery: searchParams,
      query: searchParams,
      searchSuggestions: [],
      selectedSuggestion: null,
      searchTermBeforeSelection: null
    };

    const searchSuggestionDebounceTimeout = 100;

    this.getSearchSuggestionsDebounced = debounce(
      this.getSearchSuggestions,
      searchSuggestionDebounceTimeout
    );

    this.id = uniqueId("searchInput-");

    const cacheBustMin = 1;
    const cacheBustMax = 999999999999;

    // We only bust cache once per component, rather than with every request.
    this.cacheBust = getRandomInt(cacheBustMin, cacheBustMax); // get value between 1 and 999999999999
  }

  getInitialQuery() {
    let searchParams = this.props.value;
    const isSearchEnable =
      typeof window === "object" && window.location && window.location.search;

    if (isSearchEnable && this.props.queryParam) {
      searchParams = new URLSearchParams(window.location.search).get(
        this.props.name
      );
    }

    return searchParams;
  }

  getSearchSuggestions(queryString) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: this.props.elasticKey
      },
      body: JSON.stringify({
        query: queryString,
        types: {
          documents: {
            fields: this.props.autosuggestion.split(",")
          }
        },
        size: this.props.size
      })
    };

    return fetch(this.props.typeaheadUrl, requestOptions)
      .then(response => response.json())
      .then(data => {
        const retrievedData = data.results.documents;
        const restructuredData = [];

        for (let i = 0; i < retrievedData.length; i++) {
          restructuredData.push(retrievedData[i].suggestion);
        }
        this.setState({
          searchSuggestions: restructuredData,
          selectedSuggestion: null
        });
      });
  }

  focusAppropriateSuggestion(e) {
    let selectedSuggestion = this.state.selectedSuggestion;

    if (e.key === "ArrowDown") {
      if (selectedSuggestion === null) {
        selectedSuggestion = 0;
      } else if (selectedSuggestion < this.state.searchSuggestions.length - 1) {
        selectedSuggestion = selectedSuggestion + 1;
      }
    } else if (e.key === "ArrowUp") {
      selectedSuggestion =
        selectedSuggestion > 0 ? selectedSuggestion - 1 : null;
    }

    this.setState({
      query:
        selectedSuggestion === null
          ? this.state.userQuery
          : this.state.searchSuggestions[selectedSuggestion],
      selectedSuggestion
    });
  }

  reset() {
    this.setState({
      userQuery: "",
      query: "",
      searchSuggestions: [],
      selectedSuggestion: null,
      searchTermBeforeSelection: null
    });
  }

  handleClearClick(e) {
    e.preventDefault();

    this.reset();

    // Retain focus
    this.input.focus();
  }

  handleInputChange(e) {
    const hasQuery = e.target.value.length >= this.props.searchMinLength;

    this.setState({
      query: e.target.value,
      userQuery: e.target.value,
      searchSuggestions: hasQuery ? this.state.searchSuggestions : []
    });

    if (hasQuery && this.props.typeahead) {
      if (this.props.handleInputChange) {
        this.props.handleInputChange(
          e.target.value,
          this.handleSearchSuggestions
        );
      } else if (this.props.typeaheadUrl) {
        this.getSearchSuggestionsDebounced(e.target.value);
      }
    }
  }

  handleSearchSuggestions = searchSuggestions => {
    this.setState({ searchSuggestions });
  };

  handleSearchSubmit(e) {
    if (this.state.query === "" && !this.props.resetSearch) {
      e.preventDefault();
      this.input.focus();
    } else if (this.props.onSubmit) {
      e.preventDefault();
      this.props.onSubmit(
        this.state.query,
        this.state.selectedSuggestion,
        this.state.searchTermBeforeSelection
      );
    }

    this.setState({
      searchSuggestions: [],
      ...(this.state.query === "" &&
        this.props.value !== "" &&
        this.props.resetSearch && {
          query: this.props.value
        })
    });
  }

  handleEscape(e) {
    e.preventDefault();
    if (this.state.searchSuggestions.length > 0) {
      this.setState({
        searchSuggestions: [],
        query: this.state.userQuery
      });
    } else {
      this.input.blur();
    }
  }

  handleInputKeyDown(e) {
    if (e.key === "Enter" && this.props.onSubmit) {
      // this is a feature which lets react persist even after the event execution
      // We need this to make sure we are able to execute
      // our event(handleSearchSubmit) even after state change
      e.persist();
      this.setState(
        {
          selectedSuggestion: null
        },
        () => {
          this.handleSearchSubmit(e);
        }
      );
    } else if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      e.preventDefault();
      this.focusAppropriateSuggestion(e);
    } else if (e.key === "Escape") {
      this.handleEscape(e);
    }
  }

  handleSuggestionClick(e, selectedSuggestion) {
    // Although this is a submit event, native form submissions rely on the
    // state being up to date. setState happens asynchronously, but event
    // callbacks happen synchronously. In order to support both JS and native
    // form submissions in this scenario, we use an escape hatch by
    // programatically firing a click event on the submit button after the state
    // has updated.

    e.preventDefault();

    this.setState(
      {
        query: e.currentTarget.value,
        selectedSuggestion,
        searchTermBeforeSelection: this.state.query
      },
      () => this.submitButton.click()
    );
  }

  handleBlur(e) {
    // document.activeElement is IE11 fallback
    const relatedTarget = e.relatedTarget || document.activeElement;

    if (this.ref && relatedTarget && this.ref.contains(relatedTarget)) {
      return;
    }

    this.setState({
      searchSuggestions: []
    });

    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  handleRef(ref) {
    this.ref = ref;
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.value !== this.props.value &&
      this.props.value !== this.state.query
    ) {
      this.setState({
        userQuery: this.props.value,
        query: this.props.value
      });
    }
  }

  render() {
    return (
      <div
        className={this.getClassName({
          states: classNames({
            expanded: this.state.searchSuggestions.length > 0,
            active: this.state.query && this.state.query !== ""
          }),
          modifiers: classNames({
            dark: this.props.colorTheme === "dark"
          })
        })}
        ref={this.handleRef.bind(this)}
        onBlur={this.handleBlur.bind(this)}
        data-auto-focus={JSON.stringify(this.props.autoFocus)}
        data-color-theme={JSON.stringify(this.props.colorTheme)}
        data-name={JSON.stringify(this.props.name)}
        data-placeholder={JSON.stringify(this.props.placeholder)}
        data-rehydratable={getRehydratableName(SearchInput.displayName)}
        data-typeahead={JSON.stringify(this.props.typeahead)}
        data-typeahead-url={JSON.stringify(this.props.typeaheadUrl)}
        data-elastic-key={JSON.stringify(this.props.elasticKey)}
        data-autosuggestion={JSON.stringify(this.props.autosuggestion)}
        data-size={JSON.stringify(this.props.size)}
        data-reset-search={JSON.stringify(this.props.resetSearch)}
        data-query-param={JSON.stringify(this.props.queryParam)}
        data-value={JSON.stringify(this.props.value)}
        data-search-min-length={JSON.stringify(this.props.searchMinLength)}
        data-search-submit-label={JSON.stringify(
          this.props.searchSubmitAriaLabel
        )}
        data-clear-search-label={JSON.stringify(
          this.props.searchClearAriaLabel
        )}
        tabIndex="-1"
      >
        {/* eslint-disable */}
        {/**
         * disabling linting for this label, lint requires us to define htmlFor AND wrap the
         * input field. Here we're only definining the for attribute.
         */}
        <label
          className={this.getClassName({
            descendantName: "label",
            utilities: "hiddenVisually"
          })}
          htmlFor={this.id}
        >
          Search
        </label>
        {/* eslint-enable */}

        <div className={this.getClassName({ descendantName: "box" })}>
          <input
            // disabling the line below, autoFocus is a prop of this component jsx-a11y/no-autofocus
            // eslint-disable-next-line
            autoFocus={this.props.autoFocus}
            className={this.getClassName({
              descendantName: "input",
              className: typestack("p1")
            })}
            autoComplete="off"
            placeholder={this.props.placeholder}
            id={this.id}
            onChange={this.handleInputChange.bind(this)}
            onKeyDown={this.handleInputKeyDown.bind(this)}
            ref={ref => {
              this.input = ref;
            }}
            type="search"
            value={this.state.query}
            name={this.props.name}
            aria-haspopup="true"
            aria-owns={`${this.id}-typeahead`}
            aria-expanded={this.state.query === "" ? "false" : "true"}
            aria-autocomplete="both"
            aria-label="Search"
            // disabling the line below, aria is valid jsx-a11y/role-has-required-aria-props
            // eslint-disable-next-line
            role="combobox"
            aria-activedescendant={
              this.state.selectedSuggestion === null
                ? ""
                : `${this.id}-typeaheadItem-${this.state.selectedSuggestion}`
            }
          />

          <div className={this.getClassName({ descendantName: "buttons" })}>
            {/*
              Explicitly set type="button" since submit is default type, and
              we don't want to fire "clearButton" on enter
            */}
            <button
              aria-label={this.props.searchClearAriaLabel}
              className={this.getClassName({ descendantName: "clearButton" })}
              onClick={this.handleClearClick.bind(this)}
              type="button"
            >
              <div className={this.getClassName({ descendantName: "icon" })}>
                <SVGIcon name="close" />
              </div>
            </button>

            <button
              aria-label={this.props.searchSubmitAriaLabel}
              className={this.getClassName({ descendantName: "searchButton" })}
              onClick={e => {
                e.persist();
                this.setState(
                  {
                    selectedSuggestion: null
                  },
                  () => {
                    this.handleSearchSubmit(e);
                  }
                );
              }}
              type="submit"
              ref={ref => (this.submitButton = ref)}
            >
              <div className={this.getClassName({ descendantName: "icon" })}>
                <SVGIcon name="search" />
              </div>
            </button>
          </div>
        </div>

        <div
          className={this.getClassName({ descendantName: "typeahead" })}
          tabIndex="-1"
          aria-live="polite"
        >
          <ul
            className={this.getClassName({ descendantName: "typeaheadItems" })}
            id={`${this.id}-typeahead`}
            role="listbox"
            aria-label="Search results"
          >
            {this.state.searchSuggestions.map((suggestion, i) => (
              <TypeaheadItem
                colorTheme={this.props.colorTheme}
                highlight={this.state.userQuery}
                id={`${this.id}-typeaheadItem-${i}`}
                key={i}
                onClick={e => this.handleSuggestionClick(e, i)}
                selected={this.state.selectedSuggestion === i}
                value={suggestion}
              />
            ))}
          </ul>
          <span
            className={this.getClassName({
              utilities: "hiddenVisually"
            })}
            role="status"
          >
            {this.state.searchSuggestions.length || 0} results found
          </span>
        </div>
      </div>
    );
  }
}

export default SearchInput;
