import React from "react";
import Block from "@emcm-ui/component-block";
import Button from "@emcm-ui/component-button";
import Eyebrow from "@emcm-ui/component-eyebrow";
import Group from "@emcm-ui/component-group";
import Heading from "@emcm-ui/component-heading";
import Image from "@emcm-ui/component-image";
import Icon from "@emcm-ui/component-icon";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import { getSocialIcon } from "./icons";
import Link from "@emcm-ui/component-link";
import { Language } from "@emcm-ui/component-theme";
import RichText from "@emcm-ui/component-rich-text";
import Sandwich from "./index";
import TitleTextAsset from "@emcm-ui/component-title-text-asset";
import SandwichREADME from "../README.md";
import Section from "@emcm-ui/component-section";
import Standfirst from "@emcm-ui/component-standfirst";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";
import VideoPlayer from "@emcm-ui/component-video-player";
import { storiesOf } from "@storybook/react";
import { boolean, withKnobs, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import "./Sandwich.css";
import "@emcm-ui/component-title-text-asset/lib/TitleTextAsset.css";
import "@emcm-ui/component-block/lib/Block.css";

const stories = storiesOf("Components/Sandwich", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(SandwichREADME));

stories
  .add("Default", () => (
    <Sandwich
      leadItems={[
        <Block text="Leading Children" color="blue" height="100px" />
      ]}
      media={<Block text="Media Child" color="orange" height="300px" />}
      layoutVariant={select(
        "layoutVariant",
        Sandwich.layoutVariants,
        "content-3/5,media-2/5"
      )}
      alignMiddle={boolean("alignMiddle", false)}
    >
      <Block text="Content Children" color="green" height="200px" />
    </Sandwich>
  ))
  .add("CTA's Button", () => (
    <TitleTextAsset
      variant="horizontal"
      assetSize={select("Asset Size", TitleTextAsset.assetSize, "Small")}
      layouts={select("Layouts", TitleTextAsset.layouts, "Right")}
      leadItems={[
        <Heading type="small-caps">small caps</Heading>,
        <Heading rank="1" type="xl">
          <span>Heading</span>
        </Heading>,
        <Standfirst>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porta,
          ligula sit amet dignissim facilisis, ante nulla aliquam ipsum, in
          vulputate purus turpis et turpis.
        </Standfirst>
      ]}
      media={
        <Image
          alt="Placeholder image"
          src="test-images/original/large_5.1x.jpg"
          fit="cover"
        />
      }
    >
      <TitleTextAsset.ContentItem>
        <RichText>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porta,
            ligula sit amet dignissim facilisis, ante nulla aliquam ipsum, in
            vulputate purus turpis et turpis.
          </p>
        </RichText>
      </TitleTextAsset.ContentItem>
      <Group>
        <Group.Item>
          <Button color="primary" kind="button" href="https://example.com">
            Button
          </Button>
        </Group.Item>
        <Group.Item>
          <Button color="secondary" kind="button" href="https://example.com">
            Button
          </Button>
        </Group.Item>
      </Group>
    </TitleTextAsset>
  ))
  .add("CTA's Primary Link", () => (
    <TitleTextAsset
      variant="horizontal"
      assetSize={select("Asset Size", TitleTextAsset.assetSize, "Small")}
      layouts={select("Layouts", TitleTextAsset.layouts, "Right")}
      leadItems={[
        <Heading type="small-caps">small caps</Heading>,
        <Heading rank="1" type="xl">
          <span>Heading</span>
        </Heading>,
        <Standfirst>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porta,
          ligula sit amet dignissim facilisis, ante nulla aliquam ipsum, in
          vulputate purus turpis et turpis.
        </Standfirst>
      ]}
      media={
        <Image
          alt="Placeholder image"
          src="test-images/original/large_5.1x.jpg"
          fit="cover"
        />
      }
    >
      <TitleTextAsset.ContentItem>
        <RichText>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porta,
            ligula sit amet dignissim facilisis, ante nulla aliquam ipsum, in
            vulputate purus turpis et turpis.
          </p>
        </RichText>
      </TitleTextAsset.ContentItem>
      <TitleTextAsset.ContentItem>
        <Link
          href="https://example.com"
          type="icon"
          icon={<Icon path="./icons/ui/blue/arrow-right.svg" size="s" />}
          iconPosition={"right"}
        >
          Primary link
        </Link>
      </TitleTextAsset.ContentItem>
    </TitleTextAsset>
  ))
  .add("CTA's Share", () => (
    <TitleTextAsset
      variant="horizontal"
      assetSize={select("Asset Size", TitleTextAsset.assetSize, "Small")}
      layouts={select("Layouts", TitleTextAsset.layouts, "Right")}
      leadItems={[
        <Heading type="small-caps">small caps</Heading>,
        <Heading rank="1" type="xl">
          <span>Heading</span>
        </Heading>,
        <Standfirst>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porta,
          ligula sit amet dignissim facilisis, ante nulla aliquam ipsum, in
          vulputate purus turpis et turpis.
        </Standfirst>
      ]}
      media={
        <Image
          alt="Placeholder image"
          src="test-images/original/large_5.1x.jpg"
          fit="cover"
        />
      }
    >
      <TitleTextAsset.ContentItem>
        <RichText>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porta,
            ligula sit amet dignissim facilisis, ante nulla aliquam ipsum, in
            vulputate purus turpis et turpis.
          </p>
        </RichText>
      </TitleTextAsset.ContentItem>
      <TitleTextAsset.ContentItem>
        <Group compact>
          <Group.Item>
            <a href="https://notvisited.com">
              <SVGIcon
                name="facebook"
                size="m"
                fill="#4D4D4D"
                getExternalIconPath={getSocialIcon}
              />
            </a>
          </Group.Item>
          <Group.Item>
            <a href="https://notvisited.com">
              <SVGIcon
                name="twitter"
                size="m"
                fill="#4D4D4D"
                getExternalIconPath={getSocialIcon}
              />
            </a>
          </Group.Item>
          <Group.Item>
            <a href="https://notvisited.com">
              <SVGIcon
                name="linkedin"
                size="m"
                fill="#4D4D4D"
                getExternalIconPath={getSocialIcon}
              />
            </a>
          </Group.Item>
          <Group.Item>
            <a href="https://notvisited.com">
              <SVGIcon
                name="email"
                size="m"
                fill="#4D4D4D"
                getExternalIconPath={getSocialIcon}
              />
            </a>
          </Group.Item>
        </Group>
      </TitleTextAsset.ContentItem>
    </TitleTextAsset>
  ))
  .add("Various content lengths", () => (
    <Section>
      <VerticalSpacing size="l">
        <Sandwich
          leadItems={[
            <Block text="Leading Child" color="blue" height="50px" />,
            <Block text="Leading Child" color="blue" height="50px" />,
            <Block text="Leading Child" color="blue" height="50px" />
          ]}
          media={<Block text="Media Child" color="orange" height="500px" />}
          layoutVariant={select(
            "layoutVariant",
            Sandwich.layoutVariants,
            "content-3/5,media-2/5"
          )}
          alignMiddle={boolean("alignMiddle", false)}
        >
          <Sandwich.ContentItem>
            <Block text="Content Child" color="green" height="50px" />
          </Sandwich.ContentItem>
          <Sandwich.ContentItem>
            <Block text="Content Child" color="green" height="50px" />
          </Sandwich.ContentItem>
          <Sandwich.ContentItem>
            <Block text="Content Child" color="green" height="50px" />
          </Sandwich.ContentItem>
        </Sandwich>
      </VerticalSpacing>
      <VerticalSpacing size="l">
        <Sandwich
          leadItems={[
            <Block text="Leading Children" color="blue" height="400px" />
          ]}
          media={<Block text="Media Child" color="orange" height="200px" />}
          layoutVariant={select(
            "layoutVariant",
            Sandwich.layoutVariants,
            "content-3/5,media-2/5"
          )}
          alignMiddle={boolean("alignMiddle", false)}
        >
          <Block text="Content Children" color="green" height="50px" />
        </Sandwich>
      </VerticalSpacing>
      <VerticalSpacing size="l">
        <Sandwich
          leadItems={[
            <Block text="Leading Children" color="blue" height="50px" />
          ]}
          media={<Block text="Media Child" color="orange" height="200px" />}
          layoutVariant={select(
            "layoutVariant",
            Sandwich.layoutVariants,
            "content-3/5,media-2/5"
          )}
          alignMiddle={boolean("alignMiddle", false)}
        >
          <Block text="Content Children" color="green" height="400px" />
        </Sandwich>
      </VerticalSpacing>
      <VerticalSpacing size="l">
        <Sandwich
          leadItems={[
            <Block text="Leading Children" color="blue" height="100px" />
          ]}
          media={<Block text="Media Child" color="orange" height="200px" />}
          layoutVariant={select(
            "layoutVariant",
            Sandwich.layoutVariants,
            "content-3/5,media-2/5"
          )}
          alignMiddle={boolean("alignMiddle", false)}
        >
          <Block text="Content Children" color="green" height="200px" />
        </Sandwich>
      </VerticalSpacing>
    </Section>
  ))
  .add("Standard Teaser #1", () => (
    <Sandwich
      leadItems={[
        <Eyebrow text="september 18, 2017 | Lorem Ipsum Dolor" />,
        <Heading rank="1" type="xl">
          <span>
            Providing answers across <b>every industry</b>
          </span>
        </Heading>,
        <Standfirst>
          Before 2016 had even begun, Reuters Breakingviews provided a
          fictionalized account of a Britain where Prime Minister David Cameron
          had backed the campaign to leave
        </Standfirst>
      ]}
      media={
        <Image alt="Placeholder image" src="test-images/original/large_2.jpg" />
      }
    >
      <Sandwich.ContentItem>
        <RichText>
          <p>
            Before 2016 had even begun, Reuters Breakingviews provided a
            fictionalized account of a Britain where Prime Minister David
            Cameron had backed the campaign to leave the European Union.
            Purportedly written by a private secretary to the Prime Minister,
            Breakingviews chronicled the events in the month after UK voters
            decided to leave the EU – an imagined vision of a weakened pound, a
            stalling economy and mountains of unsold pork pies.
          </p>
        </RichText>
      </Sandwich.ContentItem>
      <Sandwich.ContentItem>
        <Group>
          <Group.Item>
            <Button color="primary" href="https://example.com" kind="button">
              Lorem Ipsum
            </Button>
          </Group.Item>
        </Group>
      </Sandwich.ContentItem>
    </Sandwich>
  ))
  .add("Standard Teaser #2", () => (
    <Sandwich
      leadItems={[
        <Heading rank="1" type="xl">
          Providing answers across every industry
        </Heading>,
        <Standfirst>
          Before 2016 had even begun, Reuters Breakingviews provided a
          fictionalized account of a Britain where Prime Minister David Cameron
          had backed the campaign to leave
        </Standfirst>
      ]}
      media={
        <VideoPlayer
          duration="PT1M35S"
          kind="vimeo"
          playLabel="play video"
          videoId="114765613"
        >
          <Image
            alt="Placeholder image"
            src="test-images/original/large_2.jpg"
            fit="cover"
          />
        </VideoPlayer>
      }
    >
      <Sandwich.ContentItem>
        <RichText>
          <p>
            Before 2016 had even begun, Reuters Breakingviews provided a
            fictionalized account of a Britain where Prime Minister David
            Cameron had backed the campaign to leave the European Union.
            Purportedly written by a private secretary to the Prime Minister,
            Breakingviews chronicled the events in the month after UK voters
            decided to leave the EU – an imagined vision of a weakened pound, a
            stalling economy and mountains of unsold pork pies.
          </p>
        </RichText>
      </Sandwich.ContentItem>
      <Sandwich.ContentItem>
        <Group>
          <Group.Item>
            <Button color="secondary" href="https://example.com" kind="button">
              Lorem Ipsum
            </Button>
          </Group.Item>
          <Group.Item>
            <Button color="secondary" href="https://example.com" kind="button">
              Lorem Ipsum
            </Button>
          </Group.Item>
        </Group>
      </Sandwich.ContentItem>
    </Sandwich>
  ))
  .add("Standard Teaser #3", () => (
    <Sandwich
      leadItems={[
        <Eyebrow text="september 18, 2017 | Lorem Ipsum Dolor" />,
        <Heading rank="1" type="xl">
          <span>
            Providing answers across <b>every industry</b>
          </span>
        </Heading>
      ]}
      media={
        <VideoPlayer
          duration="PT1M35S"
          kind="vimeo"
          playLabel="play video"
          videoId="114765613"
        >
          <Image
            alt="Placeholder image"
            src="test-images/original/large_2.jpg"
            fit="cover"
          />
        </VideoPlayer>
      }
    >
      <Sandwich.ContentItem>
        <RichText>
          <p>
            Before 2016 had even begun, Reuters Breakingviews provided a
            fictionalized account of a Britain where Prime Minister David
            Cameron had backed the campaign to leave the European Union.
            Purportedly written by a private secretary to the Prime Minister,
            Breakingviews chronicled the events in the month after UK voters
            decided to leave the EU – an imagined vision of a weakened pound, a
            stalling economy and mountains of unsold pork pies.
          </p>
        </RichText>
      </Sandwich.ContentItem>
      <Sandwich.ContentItem>
        <Link
          href="https://example.com"
          type="icon"
          icon={<Icon path="./icons/ui/blue/arrow-right.svg" size="s" />}
          iconPosition={"right"}
        >
          Primary link
        </Link>
      </Sandwich.ContentItem>
    </Sandwich>
  ))
  .add("Standard Teaser #4", () => (
    <Sandwich
      leadItems={[
        <Heading rank="1" type="xl">
          <span>James C. Smith</span>
        </Heading>,
        <Standfirst>President and Chief Executive Officer</Standfirst>
      ]}
      media={
        <Image alt="Placeholder image" src="test-images/original/large_2.jpg" />
      }
    >
      <Sandwich.ContentItem>
        <RichText>
          <p>
            Jim began his career as a journalist and rose through the ranks at
            Thomson Newspapers to become responsible for operations in North
            America. He then led a number of professional publishing businesses
            serving the legal, regulatory and academic markets. He served as
            global head of Human Resources before becoming Chief Operating
            Officer of The Thomson Corporation. Following the acquisition of
            Reuters in 2008, Jim ran the Professional division of the combined
            company. He was named Chief Executive Officer in January 2012.
          </p>

          <p>
            Jim is a director of Pfizer, Inc. He also serves on the board of the
            World Economic Forum’s Partnering Against Corruption Initiative and
            is a member of the Forum’s International Business Council, as well
            as on the International Advisory Boards of British American Business
            and the Atlantic Council.
          </p>

          <p>Jim graduated from Marshall University.</p>
        </RichText>
      </Sandwich.ContentItem>
      <Sandwich.ContentItem>
        <Group compact>
          <Group.Item>
            <a href="https://notvisited.com">
              <SVGIcon
                name="facebook"
                size="m"
                fill="#4D4D4D"
                getExternalIconPath={getSocialIcon}
              />
            </a>
          </Group.Item>
          <Group.Item>
            <a href="https://notvisited.com">
              <SVGIcon
                name="twitter"
                size="m"
                fill="#4D4D4D"
                getExternalIconPath={getSocialIcon}
              />
            </a>
          </Group.Item>
          <Group.Item>
            <a href="https://notvisited.com">
              <SVGIcon
                name="linkedin"
                size="m"
                fill="#4D4D4D"
                getExternalIconPath={getSocialIcon}
              />
            </a>
          </Group.Item>
          <Group.Item>
            <a href="https://notvisited.com">
              <SVGIcon
                name="email"
                size="m"
                fill="#4D4D4D"
                getExternalIconPath={getSocialIcon}
              />
            </a>
          </Group.Item>
        </Group>
      </Sandwich.ContentItem>
    </Sandwich>
  ))
  .add("Page Header - CTA (Primary + Secondary)", () => (
    <Sandwich
      leadItems={[
        <Eyebrow text="september 18, 2017 | Lorem Ipsum Dolor" />,
        <Heading rank="1" type="xl">
          <span>
            Providing answers across <b>every industry</b>
          </span>
        </Heading>,
        <Standfirst>
          Before 2016 had even begun, Reuters Breakingviews provided a
          fictionalized account of a Britain where Prime Minister David Cameron
          had backed the campaign to leave
        </Standfirst>
      ]}
      media={
        <Image alt="Placeholder image" src="test-images/original/large_2.jpg" />
      }
    >
      <Sandwich.ContentItem>
        <RichText>
          <p>
            Before 2016 had even begun, Reuters Breakingviews provided a
            fictionalized account of a Britain where Prime Minister David
            Cameron had backed the campaign to leave the European Union.
            Purportedly written by a private secretary to the Prime Minister,
            Breakingviews chronicled the events in the month after UK voters
            decided to leave the EU – an imagined vision of a weakened pound, a
            stalling economy and mountains of unsold pork pies.
          </p>
        </RichText>
      </Sandwich.ContentItem>
      <Sandwich.ContentItem>
        <Group>
          <Group.Item>
            <Button color="primary" href="https://example.com" kind="button">
              Lorem Ipsum
            </Button>
          </Group.Item>
          <Group.Item>
            <Button color="secondary" href="https://example.com" kind="button">
              Lorem Ipsum
            </Button>
          </Group.Item>
        </Group>
      </Sandwich.ContentItem>
    </Sandwich>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Sandwich
        leadItems={[
          <Eyebrow text="ولأسرته. ويتضمن ذلك التغذي" />,
          <Heading rank="1" type="xl">
            <span>ولأسرته. ويتضمن ذلك التغذي</span>
          </Heading>
        ]}
        media={
          <Image alt="cityscape view" src="test-images/original/large_1.jpg" />
        }
        layoutVariant={select(
          "layoutVariant",
          Sandwich.layoutVariants,
          "content-3/5,media-2/5"
        )}
        alignMiddle={boolean("alignMiddle", false)}
      >
        <Sandwich.ContentItem>
          <RichText>
            <p>
              لكل شخص الحق في مستوى من المعيشة كاف للمحافظة على الصحة والرفاهية
              له ولأسرته. ويتضمن ذلك التغذية والملبس والمسكن والعناية الطبية
              وكذلك الخدمات الاجتماعية اللازمة. وله الحق في تأمين معيشته في
              حالات البطالة والمرض والعجز والترمل والشيخوخة وغير ذلك من فقدان
              وسائل العيش نتيجة لظروف خارجة عن إرادته.
            </p>
          </RichText>
        </Sandwich.ContentItem>
        <Sandwich.ContentItem>
          <Group>
            <Group.Item>
              <Button color="primary" href="https://example.com" kind="button">
                مستوى
              </Button>
            </Group.Item>
          </Group>
        </Sandwich.ContentItem>
      </Sandwich>
    </Language>
  ));
