// Polyfills - import these before anything else.
import "intersection-observer";
import "pubsub-js";
import "whatwg-fetch";
import "element-closest";
import "nodelist-foreach-polyfill";

import { action } from "@storybook/addon-actions";
import { configure, addDecorator } from "@storybook/react";
import { setOptions } from "@storybook/addon-options";
import fetchMockDecorator from "./fetchMockDecorator";
import styleDecorator from "./styleDecorator";
import modalDecorator from "./modalDecorator";
import "./storybook.css";

window.digitalData = {};
window.PubSub.subscribe("analytics", action("PubSub analytics event"));

global.emcmUiConfig = require("../emcm-ui/config.js");

window.emcmUiEnableConsole = true;

document.documentElement.lang = "en";
document.body.dataset.trackingCookieName = "wel_tracking_params";
document.body.dataset.trackingParams = JSON.stringify([
  "utm_campaign",
  "selectedStory"
]);

addDecorator(fetchMockDecorator);
addDecorator(modalDecorator);
addDecorator(styleDecorator);

setOptions({
  name: "emcm-ui",
  url: "https://git.sami.int.thomsonreuters.com/emcm/emcm-ui/"
});

const loadStories = () => {
  const req = require.context(
    "../../packages",
    true,
    /^\.\/(component|dc)-[a-z-]+\/(.*)\.stories\.js$/
  );

  // prev regex
  // /^\.\/component-[a-z-]+\/src\/(.*)\.stories\.js$/
  req.keys().forEach(filename => req(filename));
};

configure(loadStories, module);
