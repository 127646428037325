import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Avatar from "@emcm-ui/component-avatar";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const Profile = ({ href, location, name, title, imgSrc, imgSrcSet }) => {
  const getClassName = getClassNameFactory("ProfileGallery");

  const NameElement = href ? "a" : "div";
  const nameProps = {
    className: getClassName({
      descendantName: "profileName",
      className: typestack("p1")
    })
  };

  if (href) {
    nameProps.href = href;
  }

  return (
    <li className={getClassName({ descendantName: "profile" })}>
      <div className={getClassName({ descendantName: "profileHeadshot" })}>
        <Avatar alt="" sizes="144px" src={imgSrc} srcSet={imgSrcSet} />
      </div>
      <div className={getClassName({ descendantName: "profileInner" })}>
        <NameElement {...nameProps}>{name}</NameElement>
        <div
          className={getClassName({
            descendantName: "profileTitle",
            className: typestack("p3")
          })}
        >
          {title}
        </div>
        <div
          className={getClassName({
            descendantName: "profileLocation",
            className: typestack("p3Bold")
          })}
        >
          {location}
        </div>
      </div>
    </li>
  );
};

Profile.displayName = "ProfileGallery.Item";

Profile.propTypes = {
  /**
   * URL to link to
   */
  href: PropTypes.string,
  /**
   * Location of the person, i.e. "New York"
   */
  location: PropTypes.string.isRequired,
  /**
   * Name of the person
   */
  name: PropTypes.string.isRequired,
  /**
   * Job title of the person
   */
  title: PropTypes.string.isRequired,
  /**
   * Image `src`. Used if srcSet is not defined, or as a fallback.
   */
  imgSrc: PropTypes.string.isRequired,
  /**
   * Image URL or comma-separated list indicating a set of possible image sources for the user agent to use for different screen sizes. Same format as [srcSet](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#Example_4_Using_the_srcset_and_sizes_attributes), i.e. `my-image-200.png 200w, my-image-200.png 200w`. Must be square.
   */
  imgSrcSet: PropTypes.string
};

export default Profile;
