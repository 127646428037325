import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import BillBoard from "./index";
import "./BillBoard.css";
import SearchResultsMixtureREADME from ".../../../README.md";
import { ICON } from "../../utilities/constants";

const stories = storiesOf(
  "Components/SearchMixedCard/Components/BillBoard",
  module
);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(SearchResultsMixtureREADME));

const mockPath = "https://www.example.com";

stories
  .add("Default", () => {
    const eyebrow = text("eyebrow", "CORPORATE TREASURY");
    const title = text("title", "Primary Data Centre Move");
    const description = text(
      "description",
      "Current date status and key links to documents relating to the LSEG Primary Data Centre Move project."
    );
    const path = text("path", mockPath);
    const featureBillBoardImage = text(
      "featureBillBoardImage",
      "test-images/16-9/large_5.jpg"
    );
    const readArticle = text("Read article text", "Read article");

    const billboardCard = {
      eyebrow,
      title,
      description,
      path,
      featureBillBoardImage
    };
    const featured = {
      featuredCards: [{}, {}, {}, billboardCard]
    };

    return (
      <BillBoard featured={featured} readArticle={readArticle} icon={ICON} />
    );
  })
  .add("Language: Arabic (RTL)", () => {
    const eyebrow = text("eyebrow", "خزينة الشركة");
    const title = text("title", "نقل مركز البيانات الأساسي");
    const description = text(
      "description",
      "حالة التاريخ الحالي والروابط الرئيسية للمستندات المتعلقة بمشروع نقل مركز البيانات الأساسي LSEG."
    );
    const path = text("path", mockPath);
    const featureBillBoardImage = text(
      "featureBillBoardImage",
      "test-images/16-9/large_5.jpg"
    );
    const readArticle = text("Read article text", "اقرأ المقال");

    const billboardCard = {
      eyebrow,
      title,
      description,
      path,
      featureBillBoardImage
    };
    const featured = {
      featuredCards: [{}, {}, {}, billboardCard]
    };

    return (
      <Language code="ar">
        <BillBoard featured={featured} readArticle={readArticle} icon={ICON} />
      </Language>
    );
  });
