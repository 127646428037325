import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, select, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import Eyebrow from "@emcm-ui/component-eyebrow";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";
import TextCard from "./index";
import "./TextCard.css";
import Icon from "@emcm-ui/component-icon";

import ArticleCardFooter from "../../footers/ArticleCardFooter";
import DownloadCardFooter from "../../footers/DownloadCardFooter";
import LinkCardFooter from "../../footers/LinkCardFooter";
import VideoCardFooter from "../../footers/VideoCardFooter";
import Section from "@emcm-ui/component-section";

import CardREADME from "../../../../README.md";

const stories = storiesOf("Components/Card/Cards/TextCard", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(CardREADME));

const headingRanks = [""].concat(TextCard.headingRanks);

const footers = {
  article: (
    <ArticleCardFooter
      attributionName="Sophie P."
      dateTime="2016-03-02 13:57"
      readingTime="5 min"
      icon={<Icon path="./icons/ui/grey/time-07.svg" size="xs" />}
    />
  ),
  link: (
    <LinkCardFooter
      title="Link"
      type="icon"
      icon={<Icon path="./icons/ui/blue/arrow-right.svg" size="s" />}
    />
  ),
  extrenalLink: (
    <LinkCardFooter
      title="Link"
      type="icon"
      icon={<Icon path="./icons/ui/blue/external.svg" size="s" />}
    />
  ),
  video: (
    <VideoCardFooter
      runningTime="25 min"
      icon={<Icon path="./icons/ui/grey/playoutline-07.svg" size="xs" />}
    />
  ),
  download: (
    <DownloadCardFooter
      dateTime="2016-03-02 13:57"
      fileLabel="Download"
      disabled={false}
      size="20.54KB"
      href="https://www.refinitiv.com/content/dam/marketing/bundle-emcm/static/fonts/all-characters/proximanova-regular-webfont.woff2"
      icon={<Icon path="./icons/ui/grey/download-07.svg" size="s" />}
    />
  )
};

const footersAr = {
  none: null,
  article: (
    <ArticleCardFooter
      attributionName="Sophie P."
      dateTime="2016-03-02 13:57"
      readingTime="5 دقائق"
      icon={<Icon path="./icons/ui/grey/time-07.svg" size="xs" />}
    />
  ),
  link: (
    <LinkCardFooter
      title="حلقة الوصل"
      type="icon"
      icon={<Icon path="./icons/ui/blue/arrow-right.svg" size="s" />}
    />
  ),
  externalLink: (
    <LinkCardFooter
      title="حلقة الوصل"
      type="icon"
      icon={<Icon path="./icons/ui/blue/external.svg" size="s" />}
    />
  ),
  video: (
    <VideoCardFooter
      runningTime="25 دقيقة"
      icon={<Icon path="./icons/ui/grey/playoutline-07.svg" size="xs" />}
    />
  ),
  download: (
    <DownloadCardFooter
      dateTime="2016-03-02 13:57"
      fileLabel={text("fileName", "دقائق")}
      disabled={false}
      size="20.54KB"
      href="https://www.refinitiv.com/content/dam/marketing/bundle-emcm/static/fonts/all-characters/proximanova-regular-webfont.woff2"
      icon={<Icon path="./icons/ui/grey/download-07.svg" size="s" />}
    />
  )
};

stories
  .add("Default", () => (
    <div style={{ maxWidth: 360 }}>
      <TextCard
        href={text("href", "https://www.example.com")}
        size={select("size", TextCard.sizes, "small")}
        background={select("background", TextCard.backgrounds, "white")}
        borderVariant={select("borderVariant", TextCard.borderVariant, null)}
        eyebrow={text("eyebrow", "Eyebrow")}
        heading={text("heading", "Heading")}
        headingRank={select("headingRank", headingRanks)}
        description={text("description", "Description")}
        footer={footers[select("footer", Object.keys(footers), "article")]}
      />
    </div>
  ))
  .add("With Background Section", () => (
    <Section background={"grayLighter"}>
      <div style={{ maxWidth: 360 }}>
        <TextCard
          href={text("href", "https://www.example.com")}
          size={select("size", TextCard.sizes, "small")}
          background={select("background", TextCard.backgrounds, "white")}
          borderVariant={select("borderVariant", TextCard.borderVariant, null)}
          eyebrow={text("eyebrow", "Eyebrow")}
          heading={text("heading", "Heading")}
          headingRank={select("headingRank", headingRanks)}
          description={text(
            "description",
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
          )}
          footer={footers[select("footer", Object.keys(footers), "article")]}
        />
      </div>
    </Section>
  ))
  .add("Download", () => (
    <div style={{ maxWidth: 360 }}>
      <TextCard
        href={text("href", "https://www.example.com")}
        size={select("size", TextCard.sizes, "small")}
        background={select("background", TextCard.backgrounds, "white")}
        borderVariant={select("borderVariant", TextCard.borderVariant, null)}
        eyebrow={text("eyebrow", "Eyebrow")}
        heading={text("heading", "Heading")}
        headingRank={select("headingRank", headingRanks)}
        description={text("description", "Description")}
        footer={footers[select("footer", Object.keys(footers), "download")]}
      />
    </div>
  ))
  .add("Add-ons", () => (
    <div style={{ maxWidth: 576 }}>
      <TextCard
        eyebrow={text("eyebrow", "")}
        href={text("href", "https://www.example.com")}
        size={select("size", TextCard.sizes, "medium")}
        background={select("background", TextCard.backgrounds, "white")}
        borderVariant={select("borderVariant", TextCard.borderVariant, null)}
        heading={text("heading", "Transfer Pricing Documenter")}
        headingRank={select("headingRank", headingRanks)}
        description={
          <div>
            <VerticalSpacing size="s">
              Compile, analyze and manage your transfer pricing documentation
              with the consistency and efficiency you need to meet global
              compliance. Compile, analyze and manage your transfer pricing
              documentation with the consistency and efficiency you need to meet
              global compliance.
            </VerticalSpacing>
            <Eyebrow text="Add-ons for this product:" />
            <div>Benchmark, Industry Reports Module, Loan Analysis Module</div>
          </div>
        }
        footer={
          <LinkCardFooter
            title="Product details"
            type="icon"
            icon={<Icon path="./icons/ui/blue/arrow-right.svg" size="s" />}
          />
        }
      />
    </div>
  ))
  .add("Language: Arabic (RTL)", () => (
    <div style={{ maxWidth: 360 }}>
      <Language code="ar">
        <TextCard
          href={text("href", "https://www.example.com")}
          size={select("size", TextCard.sizes, "small")}
          background={select("background", TextCard.backgrounds, "white")}
          borderVariant={select("borderVariant", TextCard.borderVariant, null)}
          eyebrow={text("eyebrow", "حاجب العين")}
          heading={text("heading", "عنوان")}
          headingRank={select("headingRank", headingRanks)}
          description={text("description", "وصف")}
          footer={
            footersAr[select("footer", Object.keys(footersAr), "article")]
          }
        />
      </Language>
    </div>
  ));
