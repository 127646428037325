export default function getTallestElementHeight(elements) {
  let maxHeight = 0;

  elements.forEach(el => {
    const currentEleHeight = el.rect && el.rect.height;

    if (currentEleHeight > maxHeight) {
      maxHeight = currentEleHeight;
    }
  });

  return maxHeight;
}
