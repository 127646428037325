import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

import AjaxPanel from "@emcm-ui/component-panel/lib/Panel/Panel";
import Tab from "./components/Tab";
import TabList from "./components/TabList";
import { Provider as AjaxPanelProvider } from "@emcm-ui/component-panel/lib/ajaxPanelProvider";

class Tabs extends Component {
  static displayName = "Tabs";

  static propTypes = {
    /**
     * An analytics function, accepting category, action, and label as arguments.
     */
    analytics: PropTypes.func,

    /**
     * The content of the tabs. These should be of type `Tabs.Picker` or `Tabs.Panel`.
     */
    children: PropTypes.node,

    /**
     * Default tab for the tracker.
     */
    defaultTab: PropTypes.string,

    /**
     * Optional modifier to switch layout variants
     */
    layoutVariant: PropTypes.oneOf(["verticalAtDesktop", "wrapped"])
  };

  static defaultProps = {
    analytics: () => {}
  };

  static getLocationHash = () => {
    if (window && window.location && window.location.hash) {
      return window.location.hash.substr(1);
    }

    return null;
  };

  static setLocationHash = hash => {
    if (window && window.location) {
      window.location.hash = hash;
    }
  };

  static getTabs(children) {
    const tabs = [];

    React.Children.forEach(children, child => {
      if (child.type === AjaxPanel) {
        tabs.push({
          contentLocation: child.props.contentLocation,
          pageLocation: child.props.pageLocation,
          relatedId: child.props.id,
          title: child.props.tabTitle
        });
      }
    });

    return tabs;
  }

  constructor(props) {
    super(props);

    this.getClassName = getClassNameFactory(Tabs.displayName);

    const tabs = Tabs.getTabs(props.children);

    this.state = {
      currentOpenPanel:
        props.defaultTab || (tabs[0] && tabs[0].relatedId) || null,
      tabs
    };

    this.updateFromHash = this.updateFromHash.bind(this);
    this.handleTabClick = this.handleTabClick.bind(this);
    this.handleTabListKeyDown = this.handleTabListKeyDown.bind(this);
    this.setOpenPanel = this.setOpenPanel.bind(this);
  }

  setOpenPanel(panelId) {
    this.setState({
      currentOpenPanel: panelId
    });

    Tabs.setLocationHash(panelId);

    const tabIndex = this.state.tabs.findIndex(tab => {
      return tab.relatedId === panelId;
    });
    const tab = this.state.tabs[tabIndex];

    this.props.analytics("Tab navigation", `Click ${tabIndex + 1}`, tab.title);
  }

  handleTabListKeyDown(e) {
    const currentOpenPanelIndex = this.state.tabs.findIndex(
      tab => tab.relatedId === this.state.currentOpenPanel
    );

    const desktopViewportWidth = 768;
    const direction = getComputedStyle(this.ref).direction;

    let nextKey = direction === "ltr" ? "ArrowRight" : "ArrowLeft";
    let previousKey = direction === "ltr" ? "ArrowLeft" : "ArrowRight";
    let focusPanelKey = "ArrowDown";

    if (
      this.props.layoutVariant === "verticalAtDesktop" &&
      window.innerWidth >= desktopViewportWidth
    ) {
      nextKey = "ArrowDown";
      previousKey = "ArrowUp";
      focusPanelKey = direction === "ltr" ? "ArrowRight" : "ArrowLeft";
    }

    if ([nextKey, previousKey].includes(e.key)) {
      let newOpenPanel;

      if (e.key === previousKey) {
        newOpenPanel = this.state.tabs[Math.max(0, currentOpenPanelIndex - 1)]
          .relatedId;
      } else {
        newOpenPanel = this.state.tabs[
          Math.min(this.state.tabs.length - 1, currentOpenPanelIndex + 1)
        ].relatedId;
      }

      this.setOpenPanel(newOpenPanel);
    } else if (e.key === focusPanelKey) {
      // See https://inclusive-components.design/tabbed-interfaces/#aproblemreadingpanels
      this.ref.querySelector(`#${this.state.currentOpenPanel}`).focus();
    }

    this.keepTabsInItsPosition();
  }

  handleTabClick(e, relatedId) {
    e.preventDefault();

    this.setOpenPanel(relatedId);

    this.keepTabsInItsPosition();
  }

  keepTabsInItsPosition() {
    const element = this.ref.querySelector(`#${this.state.currentOpenPanel}`)
      .parentElement;

    const rect = element.getBoundingClientRect();
    const offsetFromTop = element.offsetTop;
    const tabPosition = offsetFromTop - rect.top;

    window.scrollTo(0, tabPosition);
  }

  updateFromHash() {
    const locationHash = Tabs.getLocationHash();

    if (
      locationHash &&
      locationHash !== this.state.currentOpenPanel &&
      this.state.tabs.map(tab => tab.relatedId).includes(locationHash)
    ) {
      this.setOpenPanel(locationHash);
    }
  }

  componentDidMount() {
    this.updateFromHash();

    if (window) {
      window.addEventListener("hashchange", this.updateFromHash);
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    const newState = {
      tabs: Tabs.getTabs(nextProps.children)
    };

    if (newState.tabs.length === 0) {
      newState.currentOpenPanel = null;
    } else if (
      !newState.tabs.map(tab => tab.relatedId).includes(state.currentOpenPanel)
    ) {
      // The previously open tab no longer exists. Open either the default tab,
      // or the first tab if no default is set.
      newState.currentOpenPanel =
        nextProps.defaultTab || newState.tabs[0].relatedId;
    }

    return newState;
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener("hashchange", this.updateFromHash);
    }
  }

  render() {
    const { children, defaultTab, layoutVariant } = this.props;

    return (
      <div
        className={this.getClassName({ modifiers: classNames(layoutVariant) })}
        data-default-tab={defaultTab}
        data-layout-variant={layoutVariant}
        data-rehydratable={getRehydratableName(Tabs.displayName)}
        ref={ref => (this.ref = ref)}
      >
        <div className={this.getClassName({ descendantName: "list" })}>
          <TabList layoutVariant={layoutVariant}>
            {this.state.tabs.map(tab => (
              <Tab
                {...tab}
                key={tab.relatedId}
                layoutVariant={layoutVariant}
                onClick={this.handleTabClick}
                onKeyDown={this.handleTabListKeyDown}
                selected={tab.relatedId === this.state.currentOpenPanel}
              />
            ))}
          </TabList>
        </div>

        <div className={this.getClassName({ descendantName: "panels" })}>
          <AjaxPanelProvider value={this.state.currentOpenPanel}>
            {children}
          </AjaxPanelProvider>
        </div>
      </div>
    );
  }
}

Tabs.Panel = AjaxPanel;
Tabs.TabList = TabList;
Tabs.Tab = Tab;

export default Tabs;
