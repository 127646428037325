import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, boolean, select, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import Icon from "@emcm-ui/component-icon";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import ListOfLinks from "./index";
import "./ListOfLinks.css";
import ListOfLinksREADME from "../README.md";

const getClassName = getClassNameFactory(ListOfLinks.BlockLink.displayName);

const singleColStories = storiesOf(
  "Components/ListOfLinks/Single Column",
  module
);
const multiColStories = storiesOf(
  "Components/ListOfLinks/Multi Column",
  module
);
const arabicStories = storiesOf(
  "Components/ListOfLinks/Language: Arabic (RTL)",
  module
);

singleColStories.addDecorator(withKnobs);
singleColStories.addDecorator(withReadme(ListOfLinksREADME));
multiColStories.addDecorator(withKnobs);
multiColStories.addDecorator(withReadme(ListOfLinksREADME));
arabicStories.addDecorator(withKnobs);
arabicStories.addDecorator(withReadme(ListOfLinksREADME));

const iconName = ["arrow", "download", "external"];
const contextualIconName = ["", "contact", "email"];
const columnCounts = [2, 3, 4]; // eslint-disable-line no-magic-numbers
const defaultColumns = 2;
const icons = {
  arrow: <Icon path="./icons/ui/grey/arrow-right-07.svg" size="s" />,
  download: <Icon path="./icons/ui/grey/download-07.svg" size="s" />,
  external: <Icon path="./icons/ui/grey/external-07.svg" size="s" />,
  contact: <Icon path="./icons/ui/blue/contact.svg" size="l" />,
  email: <Icon path="./icons/ui/blue/email.svg" size="l" />
};

singleColStories
  .add("Simple", () => (
    <ListOfLinks topBorder={boolean("Top Border", true)}>
      <ListOfLinks.Link href="https://www.example.com" type="inline">
        {text("Title", "Link")}
      </ListOfLinks.Link>
      <ListOfLinks.Link href="https://www.example.com" type="inline">
        {text("Title", "Link")}
      </ListOfLinks.Link>
      <ListOfLinks.Link href="https://www.example.com" type="inline">
        {text("Title", "Link")}
      </ListOfLinks.Link>
      <ListOfLinks.Link href="https://www.example.com" type="inline">
        {text("Title", "Link")}
      </ListOfLinks.Link>
      <ListOfLinks.Link
        href="https://www.example.com"
        type="icon"
        icon={icons[select("CTA Icon", iconName, "arrow")]}
        iconPosition={"right"}
      >
        Link
      </ListOfLinks.Link>
    </ListOfLinks>
  ))
  .add("Nested", () => (
    <ListOfLinks topBorder={boolean("Top Border", true)}>
      <ListOfLinks.Link href="https://www.example.com" type="inline">
        Munere Omnesque
      </ListOfLinks.Link>
      <ListOfLinks.Link href="https://www.example.com" type="inline">
        Legendos Posidonium
      </ListOfLinks.Link>
      <ListOfLinks.Link href="https://www.example.com" type="inline">
        Voluptaria Referrentur Mei
      </ListOfLinks.Link>

      <ListOfLinks.Group
        title="Non Link Parent Moderatius Delicatissim"
        type="icon"
      >
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          Laoreet Minimum
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          Legendos Posidonium
        </ListOfLinks.Link>
      </ListOfLinks.Group>

      <ListOfLinks.Link href="https://www.example.com" type="inline">
        Munere Omnesque
      </ListOfLinks.Link>
      <ListOfLinks.Link href="https://www.example.com" type="inline">
        Legendos Posidonium
      </ListOfLinks.Link>
      <ListOfLinks.Link href="https://www.example.com" type="inline">
        Voluptaria Referrentur Mei
      </ListOfLinks.Link>

      <ListOfLinks.Group
        title="Link Parent Moderatius Delicatissim"
        href="https://www.example.com"
        type="icon"
        icon={icons[select("CTA Icon", iconName, "arrow")]}
        iconPosition={"right"}
      >
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          Laoreet Minimum
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          Legendos Posidonium
        </ListOfLinks.Link>
      </ListOfLinks.Group>

      <ListOfLinks.Link href="https://www.example.com" type="inline">
        Munere Omnesque
      </ListOfLinks.Link>
      <ListOfLinks.Link href="https://www.example.com" type="inline">
        Legendos Posidonium
      </ListOfLinks.Link>
      <ListOfLinks.Link href="https://www.example.com" type="icon">
        View More Resources
      </ListOfLinks.Link>
    </ListOfLinks>
  ))
  .add("BlockLink", () => (
    <ListOfLinks>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        eyebrow={text("Eyebrow", "Eyebrow")}
        icon={icons[select("CTA Icon", iconName, "arrow")]}
        contextualIcon={icons[select("Contextual Icon", contextualIconName)]}
        isCompactViewActive={boolean("Enable Compact View", false)}
        description={text("Description", "Block link description.")}
      >
        {text("Title", "BlockLink")}
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        eyebrow={text("Eyebrow", "Eyebrow")}
        icon={icons[select("CTA Icon", iconName, "arrow")]}
        contextualIcon={icons[select("Contextual Icon", contextualIconName)]}
        metadata={
          <React.Fragment>
            Metadata
            <span
              className={getClassName({ descendantName: "dotSeparator" })}
              aria-hidden="true"
            >
              •
            </span>
            Metadata
            <span
              className={getClassName({ descendantName: "dotSeparator" })}
              aria-hidden="true"
            >
              •
            </span>
            Metadata
          </React.Fragment>
        }
        isCompactViewActive={boolean("Enable Compact View", false)}
        description={text("Description", "Block link description.")}
      >
        {text("Title", "BlockLink")}
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        eyebrow={text("Eyebrow", "Eyebrow")}
        icon={icons[select("CTA Icon", iconName, "arrow")]}
        contextualIcon={icons[select("Contextual Icon", contextualIconName)]}
        isCompactViewActive={boolean("Enable Compact View", false)}
        description={text("Description", "Block link description.")}
      >
        {text("Title", "BlockLink")}
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        eyebrow={text("Eyebrow", "Eyebrow")}
        icon={icons[select("CTA Icon", iconName, "arrow")]}
        contextualIcon={icons[select("Contextual Icon", contextualIconName)]}
        isCompactViewActive={boolean("Enable Compact View", false)}
        description={text("Description", "Block link description.")}
      >
        {text("Title", "BlockLink")}
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        eyebrow={text("Eyebrow", "Eyebrow")}
        icon={icons[select("CTA Icon", iconName, "arrow")]}
        contextualIcon={icons[select("Contextual Icon", contextualIconName)]}
        isCompactViewActive={boolean("Enable Compact View", false)}
        description={text("Description", "Block link description.")}
      >
        BlockLink with a long title. Lorem ipsum dolor sit amet, consectetur
        adipiscing elit.
      </ListOfLinks.BlockLink>
    </ListOfLinks>
  ))
  .add("Link to file", () => (
    <ListOfLinks>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        icon={icons[select("CTA Icon", iconName, "arrow")]}
        metadata={
          <React.Fragment>
            285KB
            <span
              className={getClassName({ descendantName: "dotSeparator" })}
              aria-hidden="true"
            >
              •
            </span>
            PDF
          </React.Fragment>
        }
      >
        {text("Title", "Link text")}
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        icon={icons[select("CTA Icon", iconName, "arrow")]}
        metadata={
          <React.Fragment>
            285KB
            <span
              className={getClassName({ descendantName: "dotSeparator" })}
              aria-hidden="true"
            >
              •
            </span>
            PDF
          </React.Fragment>
        }
      >
        {text("Title", "Link text")}
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        icon={icons[select("CTA Icon", iconName, "arrow")]}
        metadata={
          <React.Fragment>
            285KB
            <span
              className={getClassName({ descendantName: "dotSeparator" })}
              aria-hidden="true"
            >
              •
            </span>
            PDF
          </React.Fragment>
        }
      >
        {text("Title", "Link text")}
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        icon={icons[select("CTA Icon", iconName, "arrow")]}
        metadata={
          <React.Fragment>
            285KB
            <span
              className={getClassName({ descendantName: "dotSeparator" })}
              aria-hidden="true"
            >
              •
            </span>
            PDF
          </React.Fragment>
        }
      >
        {text("Title", "Link text")}
      </ListOfLinks.BlockLink>
    </ListOfLinks>
  ))
  .add("BlockLink Small", () => (
    <ListOfLinks>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        size="small"
        icon={icons[select("CTA Icon", iconName, "arrow")]}
      >
        {text("Title", "BlockLink")}
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        size="small"
        icon={icons[select("CTA Icon", iconName, "arrow")]}
      >
        {text("Title", "BlockLink")}
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        size="small"
        icon={icons[select("CTA Icon", iconName, "arrow")]}
      >
        {text("Title", "BlockLink")}
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        size="small"
        icon={icons[select("CTA Icon", iconName, "arrow")]}
      >
        {text("Title", "BlockLink")}
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        size="small"
        icon={icons[select("CTA Icon", iconName, "arrow")]}
      >
        BlockLink with a long title. Lorem ipsum dolor sit amet, consectetur
        adipiscing elit.
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        size="small"
        metadata={
          <React.Fragment>
            New York
            <span
              className={getClassName({ descendantName: "dotSeparator" })}
              aria-hidden="true"
            >
              •
            </span>
            3 openings
          </React.Fragment>
        }
        icon={icons[select("CTA Icon", iconName, "arrow")]}
      >
        Small BlockLink with Metadata
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        size="small"
        description={`The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin language which more or less corresponds to 'proper' Latin. It contains a series of real Latin words. This ancient dummy text is also incomprehensible, but it imitates the rhythm of most European languages in Latin script.`}
        icon={icons[select("CTA Icon", iconName, "arrow")]}
      >
        Small BlockLink with Description
      </ListOfLinks.BlockLink>
    </ListOfLinks>
  ))

  .add("Small BlockLink for Events", () => (
    <ListOfLinks>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        size="small"
        eventMonth="JUL"
        eventDay="30"
        metadata={
          <React.Fragment>
            Mumbai
            <span
              className={getClassName({ descendantName: "separator" })}
              aria-hidden="true"
            >
              ,
            </span>
            India
          </React.Fragment>
        }
        icon={icons[select("CTA Icon", iconName, "arrow")]}
      >
        Developer World - Mumbai
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        size="small"
        eventMonth="JUL"
        eventDay="22"
        metadata={
          <React.Fragment>
            Baar
            <span
              className={getClassName({ descendantName: "separator" })}
              aria-hidden="true"
            >
              ,
            </span>
            Switzerland
          </React.Fragment>
        }
        icon={icons[select("CTA Icon", iconName, "arrow")]}
      >
        Hack the Valley
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        size="small"
        eventMonth="OCT"
        eventDay="4"
        metadata={
          <React.Fragment>
            Excel
            <span
              className={getClassName({ descendantName: "separator" })}
              aria-hidden="true"
            >
              ,
            </span>
            London
          </React.Fragment>
        }
        icon={icons[select("CTA Icon", iconName, "arrow")]}
      >
        Code Central 2019
      </ListOfLinks.BlockLink>
    </ListOfLinks>
  ))

  .add("BlockLink with Meta Separator", () => (
    <ListOfLinks>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        size="small"
        metadata={
          <React.Fragment>
            New York
            <span
              className={getClassName({ descendantName: "dotSeparator" })}
              aria-hidden="true"
            >
              -
            </span>
            3 openings
          </React.Fragment>
        }
        icon={icons[select("CTA Icon", iconName, "arrow")]}
      >
        Small BlockLink with Metadata
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        size="small"
        eventMonth="JUL"
        eventDay="30"
        metadata={
          <React.Fragment>
            Mumbai
            <span
              className={getClassName({ descendantName: "dotSeparator" })}
              aria-hidden="true"
            >
              •
            </span>
            India
          </React.Fragment>
        }
        icon={icons[select("CTA Icon", iconName, "arrow")]}
      >
        Developer World - Mumbai
      </ListOfLinks.BlockLink>
    </ListOfLinks>
  ))

  .add("BlockLink with Single Item", () => (
    <ListOfLinks.BlockLink
      eyebrow={text("Eyebrow", "Eyebrow")}
      href={text("Link", "https://www.example.com")}
      icon={icons[select("CTA Icon", iconName, "arrow")]}
      contextualIcon={
        icons[select("Contextual Icon", contextualIconName, "contact")]
      }
      isCompactViewActive={boolean("Enable Compact View", true)}
      isOutlineActive={boolean("Enable Outline Border", true)}
      description={text("Description", "We typically respond promptly.")}
      isSingleViewElement={true}
      isTitleLargeHeading={true}
    >
      {text("Title", "Contact us")}
    </ListOfLinks.BlockLink>
  ));

multiColStories
  .add("Simple", () => (
    <ListOfLinks
      columns={select("Columns", columnCounts, defaultColumns)}
      topBorder={boolean("Top Border", true)}
    >
      <ListOfLinks.Link href="https://www.example.com" type="inline">
        {text("Title", "Link 1")}
      </ListOfLinks.Link>
      <ListOfLinks.Link href="https://www.example.com" type="inline">
        {text("Title", "Link 2")}
      </ListOfLinks.Link>
      <ListOfLinks.Link href="https://www.example.com" type="inline">
        {text("Title", "Link 3")}
      </ListOfLinks.Link>
      <ListOfLinks.Link href="https://www.example.com" type="inline">
        {text("Title", "Link 4")}
      </ListOfLinks.Link>
      <ListOfLinks.Link href="https://www.example.com" type="inline">
        {text("Title", "Link 5")}
      </ListOfLinks.Link>

      <ListOfLinks.Link href="https://www.example.com" type="inline">
        {text("Title", "Link 6")}
      </ListOfLinks.Link>
      <ListOfLinks.Link href="https://www.example.com" type="inline">
        {text("Title", "Link 7")}
      </ListOfLinks.Link>
      <ListOfLinks.Link href="https://www.example.com" type="inline">
        {text("Title", "Link 8")}
      </ListOfLinks.Link>
      <ListOfLinks.Link href="https://www.example.com" type="inline">
        {text("Title", "Link 9")}
      </ListOfLinks.Link>
      <ListOfLinks.Link href="https://www.example.com" type="inline">
        {text("Title", "Link 10")}
      </ListOfLinks.Link>
    </ListOfLinks>
  ))
  .add("Nested, aka Large List Of Links", () => (
    <ListOfLinks
      columns={select("Columns", columnCounts, defaultColumns)}
      topBorder={boolean("Top Border", true)}
    >
      <ListOfLinks.Column>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          Munere Omnesque
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          Legendos Posidonium
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          Voluptaria Referrentur Mei
        </ListOfLinks.Link>

        <ListOfLinks.Group
          title="Non Link Parent Moderatius Delicatissim"
          type="icon"
        >
          <ListOfLinks.Link href="https://www.example.com" type="inline">
            Laoreet Minimum
          </ListOfLinks.Link>
          <ListOfLinks.Link href="https://www.example.com" type="inline">
            Legendos Posidonium
          </ListOfLinks.Link>
        </ListOfLinks.Group>
      </ListOfLinks.Column>

      <ListOfLinks.Column>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          Munere Omnesque
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          Legendos Posidonium
        </ListOfLinks.Link>
        <ListOfLinks.Group
          title="Link Parent Moderatius Delicatissim"
          href="https://www.example.com"
          type="icon"
          icon={icons[select("CTA Icon", iconName, "arrow")]}
          iconPosition={"right"}
        >
          <ListOfLinks.Link href="https://www.example.com" type="inline">
            Laoreet Minimum
          </ListOfLinks.Link>
          <ListOfLinks.Link href="https://www.example.com" type="inline">
            Legendos Posidonium
          </ListOfLinks.Link>
        </ListOfLinks.Group>

        <ListOfLinks.Link href="https://www.example.com" type="inline">
          Munere Omnesque
        </ListOfLinks.Link>
      </ListOfLinks.Column>

      <ListOfLinks.Column>
        <ListOfLinks.Group
          title="Link Parent Moderatius Delicatissim"
          href="https://www.example.com"
          type="icon"
          icon={icons[select("CTA Icon", iconName, "arrow")]}
          iconPosition={"right"}
        >
          <ListOfLinks.Link href="https://www.example.com" type="inline">
            Laoreet Minimum
          </ListOfLinks.Link>
          <ListOfLinks.Link href="https://www.example.com" type="inline">
            Legendos Posidonium
          </ListOfLinks.Link>
        </ListOfLinks.Group>

        <ListOfLinks.Link href="https://www.example.com" type="inline">
          Munere Omnesque
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          Legendos Posidonium
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="icon">
          View More Resources
        </ListOfLinks.Link>
      </ListOfLinks.Column>
    </ListOfLinks>
  ))
  .add("BlockLink", () => (
    <ListOfLinks columns={select("Columns", columnCounts, defaultColumns)}>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        eyebrow={text("Eyebrow", "Eyebrow")}
        icon={icons[select("CTA Icon", iconName, "arrow")]}
      >
        {text("Title", "BlockLink 1")}
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        eyebrow={text("Eyebrow", "Eyebrow")}
        icon={icons[select("CTA Icon", iconName, "arrow")]}
      >
        {text("Title", "BlockLink 2")}
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        eyebrow={text("Eyebrow", "Eyebrow")}
        icon={icons[select("CTA Icon", iconName, "arrow")]}
      >
        {text("Title", "BlockLink 3")}
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        eyebrow={text("Eyebrow", "Eyebrow")}
        icon={icons[select("CTA Icon", iconName, "arrow")]}
      >
        {text("Title", "BlockLink 4")}
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        eyebrow={text("Eyebrow", "Eyebrow")}
        icon={icons[select("CTA Icon", iconName, "arrow")]}
      >
        {text("Title", "BlockLink 5")}
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        eyebrow={text("Eyebrow", "Eyebrow")}
        icon={icons[select("CTA Icon", iconName, "arrow")]}
      >
        {text("Title", "BlockLink 6")}
      </ListOfLinks.BlockLink>
    </ListOfLinks>
  ))
  .add("BlockLink Small", () => (
    <ListOfLinks columns={select("Columns", columnCounts, defaultColumns)}>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        size="small"
        icon={icons[select("CTA Icon", iconName, "arrow")]}
      >
        Small BlockLink 1
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        size="small"
        icon={icons[select("CTA Icon", iconName, "arrow")]}
      >
        Small BlockLink 2
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        size="small"
        icon={icons[select("CTA Icon", iconName, "arrow")]}
      >
        Small BlockLink 3
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        size="small"
        icon={icons[select("CTA Icon", iconName, "arrow")]}
      >
        Small BlockLink 4
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        size="small"
        icon={icons[select("CTA Icon", iconName, "arrow")]}
      >
        Small BlockLink 5
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        href={text("Link", "https://www.example.com")}
        size="small"
        icon={icons[select("CTA Icon", iconName, "arrow")]}
      >
        Small BlockLink 6
      </ListOfLinks.BlockLink>
    </ListOfLinks>
  ));

arabicStories.add("Multi Column: Nested", () => (
  <Language code="ar">
    <ListOfLinks
      columns={select("Columns", columnCounts, defaultColumns)}
      topBorder={boolean("Top Border", true)}
    >
      <ListOfLinks.Column>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          قام جدول شعار قد, عرض
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          كل للصين تحرّكت. أخ
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          تعديل وفنلندا
        </ListOfLinks.Link>

        <ListOfLinks.Group
          title="أي تُصب الخارجية لكل Non Link Parent"
          type="inline"
        >
          <ListOfLinks.Link href="https://www.example.com" type="inline">
            ما, لان الشمل أجزاء
          </ListOfLinks.Link>
          <ListOfLinks.Link href="https://www.example.com" type="inline">
            ويكيبيديا ان
          </ListOfLinks.Link>
        </ListOfLinks.Group>
      </ListOfLinks.Column>

      <ListOfLinks.Column>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          قام جدول شعار قد, عرض
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          كل للصين تحرّكت. أخ
        </ListOfLinks.Link>
        <ListOfLinks.Group
          title="أي تُصب الخارجية لكل Link Parent"
          href="https://www.example.com"
          type="icon"
          icon={<Icon path="./icons/ui/blue/arrow-right.svg" size="s" />}
          iconPosition={"right"}
        >
          <ListOfLinks.Link href="https://www.example.com" type="inline">
            ما, لان الشمل أجزاء
          </ListOfLinks.Link>
          <ListOfLinks.Link href="https://www.example.com" type="inline">
            ويكيبيديا ان
          </ListOfLinks.Link>
        </ListOfLinks.Group>

        <ListOfLinks.Link href="https://www.example.com" type="inline">
          قام جدول شعار قد, عرض
        </ListOfLinks.Link>
      </ListOfLinks.Column>

      <ListOfLinks.Column>
        <ListOfLinks.Group
          title="أي تُصب الخارجية لكل Link Parent"
          href="https://www.example.com"
          type="icon"
          icon={<Icon path="./icons/ui/blue/arrow-right.svg" size="s" />}
          iconPosition={"right"}
        >
          <ListOfLinks.Link href="https://www.example.com" type="inline">
            قام جدول شعار قد, عرض
          </ListOfLinks.Link>
          <ListOfLinks.Link href="https://www.example.com" type="inline">
            كل للصين تحرّكت. أخ
          </ListOfLinks.Link>
        </ListOfLinks.Group>

        <ListOfLinks.Link href="https://www.example.com" type="inline">
          قام جدول شعار قد, عرض
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          كل للصين تحرّكت. أخ
        </ListOfLinks.Link>
        <ListOfLinks.Link
          href="https://www.example.com"
          type="icon"
          icon={<Icon path="./icons/ui/blue/arrow-right.svg" size="s" />}
          iconPosition={"right"}
        >
          تعديل وفنلندا
        </ListOfLinks.Link>
      </ListOfLinks.Column>
    </ListOfLinks>
  </Language>
));
