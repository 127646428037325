import React from "react";
import TagCloud from "./TagCloud";

// eslint-disable-next-line require-await
export default async domNode => {
  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data ? JSON.parse(data) : null;
  };

  const props = {
    isRehydrating: true,
    tagList: readData("tag-list"),
    showMore: domNode.getAttribute("data-show-more"),
    showLess: domNode.getAttribute("data-show-less"),
    heading: domNode.getAttribute("data-heading")
  };

  return <TagCloud {...props} />;
};
