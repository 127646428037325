import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, select, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import TwitterCard from "./index";
import "./TwitterCard.css";

import CardREADME from "../../../../README.md";

const stories = storiesOf("Components/Card/Cards/TwitterCard", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(CardREADME));

const borderVariants = [null, "borderless"];

stories
  .add("Default", () => (
    <div style={{ maxWidth: 360 }}>
      <TwitterCard
        borderVariant={select("borderVariants", borderVariants, null)}
        timelineDataSource={{
          sourceType: "profile",
          screenName: text("Twitter handle", "thomsonreuters")
        }}
        eyebrow={text("Eyebrow", "@thomsonreuters on Twitter")}
      />
    </div>
  ))
  .add("Language: Arabic (RTL)", () => (
    <div style={{ maxWidth: 360 }}>
      <Language code="ar">
        <TwitterCard
          timelineDataSource={{
            sourceType: "profile",
            screenName: text("Twitter handle", "arareuters")
          }}
          eyebrow={text("eyebrow", "حاجب العين")}
          timelineOptions={{
            chrome: "noheader",
            lang: "ar"
          }}
        />
      </Language>
    </div>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <div style={{ maxWidth: 360 }}>
        <TwitterCard
          timelineDataSource={{
            sourceType: "profile",
            screenName: "thomsonreuters"
          }}
          eyebrow="@thomsonreuters on Twitter"
        />
      </div>
    </StaticRenderer>
  ));
