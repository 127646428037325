import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Filters from "../Filters/Filters";
import TagFilters from "../TagFilters/TagFilters";
import Alignment from "@emcm-ui/component-alignment";
import Loader from "@emcm-ui/component-loader";

const SearchFilters = props => {
  const getClassName = getClassNameFactory("SearchResultsMixture");
  const { tagsLoading } = props;

  return (
    <div className={getClassName({ descendantName: "SearchFilters" })}>
      <Filters {...props} />
      {tagsLoading && (
        <Alignment horizontal="center" vertical="center">
          <Loader />
        </Alignment>
      )}
      <TagFilters {...props} />
    </div>
  );
};

SearchFilters.displayName = "SearchResults.searchFilters";

SearchFilters.propTypes = {
  /**
   * boolean value for tags loading
   */
  tagsLoading: PropTypes.bool
};

export default SearchFilters;
