import React, { Component } from "react";
import PropTypes from "prop-types";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import classNames from "classnames";

class FloatingButton extends Component {
  static displayName = "FloatingButton";
  static propTypes = {
    /**
     * Onclick function for the button to do some custom action
     */
    handleClick: PropTypes.func,
    /**
     * Icon to show inside the Floating-Button
     */
    icon: PropTypes.string,
    /**
     * To show the button icon based on the button click
     */
    showFloatingButton: PropTypes.bool,
    /**
     * To enable or disable the button. CSS class name: is-disabled
     */
    disabled: PropTypes.bool,
    /**
     * Header Text used in button aria-label
     */
    headerText: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.getClassName = getClassNameFactory(FloatingButton.displayName);
  }

  render() {
    const {
      showFloatingButton,
      disabled,
      handleClick,
      icon,
      headerText
    } = this.props;
    const buttonAriaLabel = showFloatingButton ? "minimise" : "open";

    return (
      <div className={this.getClassName({ descendantName: "floatBadge" })}>
        <button
          aria-label={`Click here to ${buttonAriaLabel} ${headerText}`}
          aria-expanded={showFloatingButton ? "true" : "false"}
          aria-controls="floatingbadge_frameContainer"
          className={this.getClassName({
            descendantName: "button",
            states: classNames({
              disabled
            })
          })}
          onClick={handleClick}
          type="button"
          disabled={disabled}
        >
          {showFloatingButton ? (
            <div
              aria-hidden="true"
              className={this.getClassName({ descendantName: "iconMaximise" })}
            >
              <SVGIcon
                name="caret-down"
                size="xs"
                viewBox="0 0 44 44"
                aria-label="Badge icon"
              />
            </div>
          ) : (
            <div
              aria-hidden="true"
              className={this.getClassName({ descendantName: "iconMinimise" })}
            >
              <img
                src={icon}
                alt=""
                className={this.getClassName({ descendantName: "badgeIcon" })}
              />
            </div>
          )}
        </button>
      </div>
    );
  }
}

export default FloatingButton;
