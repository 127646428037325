export default () => {
  const STRING_BASE = 16;
  const CONVERT_BASE = 0x10000;
  const s4 = () =>
    ((1 + Math.random()) * CONVERT_BASE || 0)
      .toString(STRING_BASE)
      .substring(1);

  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};
