import { canFireEvents } from "@emcm-ui/utility-analytics";

export const analyticsEventsInitialized = () => {
  const HSC = window.digitalData.FloatingBadge || {};
  const component = HSC.component || {};
  const category = HSC.category || {};

  HSC.component = {
    ...component,
    info: component.info || {}
  };

  HSC.category = {
    ...category,
    primary: category.primary || {}
  };
};

export const setAnalyticsValue = (eventName, eventData) => {
  analyticsEventsInitialized();
  window.digitalData.FloatingBadge = {
    event: eventName,
    ...eventData
  };

  return window.digitalData;
};

const fireAnalyticsEvent = (eventName, eventData) => {
  if (canFireEvents()) {
    const publishObject = setAnalyticsValue(eventName, eventData);

    window.PubSub.publish(eventName, publishObject);
  }
};

export const fireFloatingButtonMount = params => {
  fireAnalyticsEvent("analytics.floatingButtonLoad", params);
};

export const fireFloatingButtonOpen = params => {
  fireAnalyticsEvent("analytics.floatingButtonOpen", params);
};

export const fireTooltipOnClose = params => {
  fireAnalyticsEvent("analytics.tooltipClose", params);
};

export const fireFloatingButtonMinimise = params => {
  fireAnalyticsEvent("analytics.floatingButtonMinimise", params);
};
