import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const Alert = ({ state, children }) => {
  const getClassName = getClassNameFactory(Alert.displayName);

  return (
    <div className={getClassName({ states: state })} role="alert">
      <div className={getClassName({ descendantName: "icon", states: state })}>
        {
          {
            success: <SVGIcon name="success" size="s" />,
            failure: <SVGIcon name="error" size="s" />
          }[state]
        }
      </div>
      <div
        className={getClassName({
          descendantName: "content",
          states: state,
          className: typestack("p1")
        })}
      >
        {children}
      </div>
    </div>
  );
};

Alert.displayName = "Alert";

Alert.propTypes = {
  /**
   * Message to show.
   */
  children: PropTypes.node,

  /**
   * The state of the alert.
   */
  state: PropTypes.oneOf(["success", "failure"]).isRequired
};

export default Alert;
