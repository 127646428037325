/**
 * @define Expandable.Header
 * Expandable.Item.Header Component
 */

// Vendor
import React from "react";
import PropTypes from "prop-types";

// emcm-ui
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Typestack from "@emcm-ui/component-typestack";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

const Header = ({
  children,
  onClick,
  isExpanded,
  headingRank,
  headingType = "h4",
  type = "normal",
  showMoreType = "p1Bold"
}) => {
  const getClassName = getClassNameFactory(Header.displayName);

  const caretClassName = getClassName({ descendantName: "caret" });

  const caretShowMoreClassName = getClassName({
    descendantName: "caretShowMore"
  });

  const displayTypestack = () => {
    if (type === "showMore") {
      return (
        <Typestack align="left" rank="p" type={showMoreType}>
          <span
            className={getClassName({
              descendantName: "headingInner"
            })}
          >
            {children}
          </span>
        </Typestack>
      );
    }

    return (
      <Typestack align="left" rank={headingRank} type={headingType}>
        <span
          className={getClassName({
            descendantName: "headingInner"
          })}
        >
          {children}
        </span>
      </Typestack>
    );
  };

  return (
    <button
      onClick={onClick}
      className={getClassName({
        states: isExpanded ? "expanded" : "collapsed",
        modifiers: type === "showMore" ? type : ""
      })}
      aria-expanded={isExpanded ? "true" : "false"}
    >
      {type === "showMore" && (
        <div className={caretShowMoreClassName} role="presentation">
          {isExpanded ? (
            <SVGIcon
              name="caret"
              style={{ transform: "rotate(180deg)" }}
              size="s"
            />
          ) : (
            <SVGIcon name="caret" size="s" />
          )}
        </div>
      )}
      {displayTypestack()}
      {type === "normal" && (
        <div className={caretClassName} role="presentation">
          {isExpanded ? (
            <SVGIcon
              name="caret"
              style={{ transform: "rotate(180deg)" }}
              size="s"
            />
          ) : (
            <SVGIcon name="caret" size="s" />
          )}
        </div>
      )}
    </button>
  );
};

Header.propTypes = {
  /**
   * Children elements to be displayed within internal Heading component
   */
  children: PropTypes.node.isRequired,
  /**
   * Callback when expanded state is toggled via a lcick
   */
  onClick: PropTypes.func.isRequired,
  /**
   * Heading rank to use for the default heading
   */
  headingRank: PropTypes.oneOf(Typestack.ranks),
  /**
   * Heading type to use for the default heading
   */
  headingType: PropTypes.oneOf(Typestack.types),
  /**
   * Expanded state for controlled component mode
   */
  isExpanded: PropTypes.bool.isRequired,
  /**
   * default is normal and showMore is for Show More
   */
  type: PropTypes.oneOf(["normal", "showMore"]),
  /**
   * Type to use for the show more heading
   */
  showMoreType: PropTypes.string
};

Header.displayName = "Expandable.Item.Header";
export default Header;
