import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, object, boolean } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import Collage from "./index";
import "./Collage.css";
import SearchResultsMixtureREADME from ".../../../README.md";
import * as data from "../../../__mocks__/searchMock.json";
import { ICON } from "../../utilities/constants";

const stories = storiesOf(
  "Components/SearchMixedCard/Components/CardCollage",
  module
);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(SearchResultsMixtureREADME));

stories
  .add("Default", () => (
    <Collage
      includeDescription={boolean("Include description", true, "Attributes")}
      cards={object("cards", data.searchResults, "Data")}
      icon={ICON}
    />
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Collage
        includeDescription={boolean("Include description", true, "Attributes")}
        cards={object("cards", data.searchResults, "Data")}
        icon={ICON}
      />
    </Language>
  ));
