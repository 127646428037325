import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import classNames from "classnames";
import CardBase from "../CardBase";
import Eyebrow from "@emcm-ui/component-eyebrow";
import Typestack from "@emcm-ui/component-typestack";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const TextCardBase = ({
  anchor,
  description,
  eyebrow,
  footer,
  heading,
  headingRank,
  href,
  largeHeading,
  size,
  renderContent,
  metaData,
  background,
  borderVariant
}) => {
  const getClassName = getClassNameFactory(TextCardBase.displayName);

  return (
    <div
      className={getClassName({
        modifiers: classNames({
          [anchor]: true
        })
      })}
    >
      <CardBase
        anchor={anchor}
        background={background}
        borderVariant={borderVariant}
        href={href}
        size={size}
      >
        {renderContent(
          <div
            className={getClassName({
              descendantName: "content"
            })}
          >
            {eyebrow ? (
              <div
                className={getClassName({
                  descendantName: "eyebrow"
                })}
              >
                <Eyebrow text={eyebrow} />
              </div>
            ) : null}

            <div
              className={getClassName({
                descendantName: "body"
              })}
            >
              <div className={getClassName({ descendantName: "heading" })}>
                <Typestack
                  align="left"
                  rank={headingRank}
                  type={largeHeading ? "h2" : "h5"}
                >
                  {heading}
                </Typestack>
              </div>

              {metaData && metaData.length > 0 ? (
                <div
                  className={getClassName({ descendantName: "metadataGroup" })}
                >
                  {metaData.map(data => {
                    return (
                      <dl
                        className={getClassName({ descendantName: "metadata" })}
                        key={data.id}
                      >
                        <dt
                          className={getClassName({
                            descendantName: "label",
                            className: typestack("p1Bold")
                          })}
                        >
                          {data.label}
                        </dt>
                        <dd
                          className={getClassName({
                            descendantName: "value",
                            className: typestack("p1")
                          })}
                        >
                          {data.value}
                        </dd>
                      </dl>
                    );
                  })}
                </div>
              ) : null}

              {description ? (
                <div
                  className={getClassName({
                    descendantName: "description",
                    className: typestack("p1")
                  })}
                >
                  {description}
                </div>
              ) : null}
            </div>

            {footer ? (
              <div
                className={getClassName({
                  descendantName: "footer"
                })}
              >
                {footer}
              </div>
            ) : null}
          </div>
        )}
      </CardBase>
    </div>
  );
};

TextCardBase.displayName = "TextCardBase";

TextCardBase.sizes = CardBase.sizes;
TextCardBase.headingRanks = Typestack.ranks;
TextCardBase.backgrounds = CardBase.backgrounds;
TextCardBase.borderVariant = CardBase.borderVariant;

/* eslint-disable max-len */
TextCardBase.propTypes = {
  /**
   * Anchor the eyebrow, heading and description to the top or bottom of the card.
   */
  anchor: PropTypes.oneOf(["bottom", "top"]),
  /**
   * Card background color
   */
  background: PropTypes.oneOf(TextCardBase.backgrounds),
  /**
   * Card border variations
   */
  borderVariant: PropTypes.oneOf(TextCardBase.borderVariant),
  /**
   * Description text
   */
  description: PropTypes.node,
  /**
   * Eyebrow text
   */
  eyebrow: PropTypes.string,
  /**
   * Footer. Should be a valid card footer, like ArticleCardFooter.
   */
  footer: PropTypes.node,
  /**
   * Heading text
   */
  heading: PropTypes.string.isRequired,
  /**
   * Heading rank. 1 will render `<h1 />`, and so on. Defaults to 3.
   */
  headingRank: PropTypes.oneOf(TextCardBase.headingRanks),
  /**
   * Card URL
   */
  href: PropTypes.string,
  /**
   * Enable large heading font size
   */
  largeHeading: PropTypes.bool,
  /**
   * A method to wrap the rendered card content. Use to apply a different visual treatment to the content, such as adding a background image. Usage: `renderContent={content => {<div>{content}</div>}}`.
   */
  renderContent: PropTypes.func,
  /**
   * Size of the Card. Will set the minumum height and grow to fit content.
   */
  size: PropTypes.oneOf(TextCardBase.sizes).isRequired,
  /**
   * Metadata content object array with label and value
   */
  metaData: PropTypes.array
};
/* eslint-enable max-len */

TextCardBase.defaultProps = {
  anchor: "top",
  headingRank: "h4",
  renderContent: content => content // eslint-disable-line react/display-name
};

export default TextCardBase;
