import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

import NavList from "./components/NavList";
import NavItem from "./components/NavItem";
import SiteFooterSearch from "./SiteFooterSearch";

import { Provider as SetOpenMenuProvider } from "./setOpenMenuContext";
import { Provider as CurrentMenuProvider } from "./currentMenuContext";

class SiteFooter extends Component {
  static displayName = "SiteFooter";

  /* eslint-disable max-len */
  static propTypes = {
    /**
     * The SiteFooter contents. These should be `SiteFooter.List`s
     */
    children: PropTypes.node,

    /**
     * Whether or not the component is rehydrating. Do not use this.
     */
    isRehydrating: PropTypes.bool,

    /**
     * URL for the logo link
     */
    logoHref: PropTypes.string.isRequired,

    /**
     * Alternative text for the logo
     */
    logoText: PropTypes.string,

    /**
     * path to logo file
     */
    logoPath: PropTypes.string,

    /**
     * logo size options
     */
    logoSize: PropTypes.string,

    /**
     * Search form action
     */
    searchFormAction: PropTypes.string.isRequired,

    /**
     * Search form method
     */
    searchFormMethod: PropTypes.string,

    /**
     * Search input name
     */
    searchInputName: PropTypes.string,

    /**
     * Search Typeahead URL
     */
    typeaheadUrl: PropTypes.string.isRequired,
    /**
     * JSON URL for elasticKey.
     */
    elasticKey: PropTypes.string,
    /**
     * JSON URL for autosuggestion.
     */
    autosuggestion: PropTypes.string,
    /**
     * JSON URL for size.
     */
    size: PropTypes.number,
    /**
     * minimum search character limit
     */
    searchMinLength: PropTypes.number,
    /**
     * placeholder text for the search input
     */
    placeholder: PropTypes.string,
    /**
     * Search button aria-label
     */
    searchSubmitAriaLabel: PropTypes.string,
    /**
     * Search clear button aria-label
     */
    searchClearAriaLabel: PropTypes.string
  };
  /* eslint-enable max-len */

  static defaultProps = {
    logoText: "London Stock Exchange Group logo",
    logoSize: "horizontal",
    isRehydrating: false,
    searchFormMethod: "get",
    searchInputName: "q",
    searchSubmitAriaLabel: "Submit search",
    searchClearAriaLabel: "Clear search"
  };

  constructor(props) {
    super(props);

    this.getClassName = getClassNameFactory(SiteFooter.displayName);

    this.state = {
      currentMenu: null
    };

    this.setOpenMenu = this.setOpenMenu.bind(this);
  }

  setOpenMenu(menuId) {
    this.setState({
      currentMenu: menuId
    });
  }

  render() {
    const { children, logoHref, logoText, logoPath, logoSize } = this.props;

    return (
      <SetOpenMenuProvider value={this.setOpenMenu}>
        <CurrentMenuProvider value={this.state.currentMenu}>
          <div
            className={this.getClassName()}
            data-logo-href={logoHref}
            data-logo-text={logoText}
            data-logo-path={logoPath}
            data-logo-size={logoSize}
            data-rehydratable={getRehydratableName(SiteFooter.displayName)}
            data-search-form-action={this.props.searchFormAction}
            data-search-form-method={this.props.searchFormMethod}
            data-search-input-name={this.props.searchInputName}
            data-typeahead-url={this.props.typeaheadUrl}
            data-elastic-key={this.props.elasticKey}
            data-search-min-length={this.props.searchMinLength}
            data-autosuggestion={this.props.autosuggestion}
            data-size={this.props.size}
            data-placeholder={this.props.placeholder}
            data-search-submit-label={this.props.searchSubmitAriaLabel}
            data-clear-search-label={this.props.searchClearAriaLabel}
          >
            <div className={this.getClassName({ descendantName: "inner" })}>
              <SiteFooterSearch
                getClassName={this.getClassName}
                searchFormAction={this.props.searchFormAction}
                searchFormMethod={this.props.searchFormMethod}
                searchInputName={this.props.searchInputName}
                typeaheadUrl={this.props.typeaheadUrl}
                elasticKey={this.props.elasticKey}
                searchMinLength={this.props.searchMinLength}
                autosuggestion={this.props.autosuggestion}
                size={this.props.size}
                placeholder={this.props.placeholder}
                searchSubmitAriaLabel={this.props.searchSubmitAriaLabel}
                searchClearAriaLabel={this.props.searchClearAriaLabel}
              />

              {logoPath ? (
                <div
                  className={this.getClassName({ descendantName: "container" })}
                >
                  <a
                    className={this.getClassName({
                      descendantName: "logo"
                    })}
                    href={logoHref}
                  >
                    <div
                      className={this.getClassName({
                        descendantName: "logoContainer"
                      })}
                    >
                      <img
                        className={this.getClassName({
                          descendantName: "logoImg",
                          className: `${this.getClassName()}-${logoSize}`
                        })}
                        loading="lazy"
                        src={logoPath}
                        alt={logoText}
                      />
                    </div>
                  </a>
                  <nav
                    className={this.getClassName({ descendantName: "lists" })}
                  >
                    <ul
                      className={this.getClassName({
                        descendantName: "listsInner"
                      })}
                    >
                      {children}
                    </ul>
                  </nav>
                </div>
              ) : (
                <nav className={this.getClassName({ descendantName: "lists" })}>
                  <ul
                    className={this.getClassName({
                      descendantName: "listsInner"
                    })}
                  >
                    {children}
                  </ul>
                </nav>
              )}
            </div>
          </div>
        </CurrentMenuProvider>
      </SetOpenMenuProvider>
    );
  }
}

SiteFooter.NavList = NavList;
SiteFooter.NavItem = NavItem;

export default SiteFooter;
