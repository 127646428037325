import PropTypes from "prop-types";
import React, { Component } from "react";
import momenttimezone from "moment-timezone";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import Header from "@emcm-ui/component-header";
import Heading from "@emcm-ui/component-heading";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";
import Grid from "@emcm-ui/component-grid";
import Pagination from "@emcm-ui/component-pagination";
import HorizontalOverflow from "@emcm-ui/component-horizontal-overflow";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";
import Table from "./components/Table";
import TagFilter from "./components/TagFilter";
import {
  getTimezoneByRegion,
  getValidatedDate,
  getTableCellIndexByLabel,
  getPropertyByName,
  getTableRows
} from "./utilities";

// to avoid pulling in lodash get this get fn lets us use similar behavior.
const get = fn => {
  try {
    return fn();
  } catch (e) {
    return undefined; // eslint-disable-line no-undefined
  }
};

class TableWithFilters extends Component {
  paginationResultSummary = null;
  scrollTimeout = null;
  isSupportsSmoothScroll = true;

  static displayName = "TableWithFilters";

  static propTypes = {
    /**
     * Data object to generate table and filters
     */
    data: PropTypes.object.isRequired,

    /**
     * Remove selected tag from available filter options
     */
    filtersHideSelectedOptions: PropTypes.bool,

    /**
     * Message displayed in a filter if there is no option resulting from the typeahead input
     */
    filtersNoOptionsMessage: PropTypes.string,

    /**
     * Placeholder for the filter input
     */
    filtersPlaceholder: PropTypes.string,

    /**
     * Information text below the title
     */
    infoText: PropTypes.string.isRequired,

    /**
     * Allow multiple tags to be selected in a filter
     */
    isMulti: PropTypes.bool,

    /**
     * Message displayed if there are no results in the table
     */
    noResultsMessage: PropTypes.string.isRequired,

    /**
     * Max number of pages shown in the pagination to be navigable directly
     */
    pagesNavigableOnSide: PropTypes.number.isRequired,

    /**
     * Text for pagination result summary
     */
    paginationResultSummaryText: PropTypes.string.isRequired,

    /**
     * Number of table rows displayed per page in pagination
     */
    rowsPerPage: PropTypes.number.isRequired,

    /**
     * Subtitle heading rank of the filters' panel
     */
    subtitleHeadingRank: PropTypes.oneOf(Heading.ranks),

    /**
     * Title of the filters' panel
     */
    title: PropTypes.string.isRequired,

    /**
     * Title heading rank of the filters' panel
     */
    titleHeadingRank: PropTypes.oneOf(Heading.ranks),

    /**
     * Sub Title information for adding link
     */
    infoLink: PropTypes.object,

    /**
     * Background color of the data table
     */
    backgroundColor: PropTypes.string,

    /**
     * Table headers mapping with filters drop down list
     */
    filterHeader: PropTypes.object,

    /**
     * Language selected option object to show the selected item into languages drop down list
     */
    languageSelectedOption: PropTypes.array,

    /**
     * Timezone selected option object to show the selected item into timrzone drop down list
     */
    timezoneSelectedOption: PropTypes.array,

    /**
     * List of timezones items
     */
    timezones: PropTypes.array,

    /**
     * Boolean flag to enable and disable horizontal overflow
     */
    isOverflow: PropTypes.bool
  };

  static defaultProps = {
    infoText: "Find a broker by asset, region, route or name.",
    noResultsMessage:
      "No results have been found as a result of the table being over constrained. To see results, please try removing filters or try and different combination of filters to find the information you’re looking for.",
    pagesNavigableOnSide: 2,
    paginationResultSummaryText: "Viewing {1} of {2} results:",
    rowsPerPage: 50,
    subtitleHeadingRank: "3",
    title: "Search Our Network",
    titleHeadingRank: "2",
    isMulti: true,
    isOverflow: true,
    languageSelectedOption: [],
    timezoneSelectedOption: [],
    backgroundColor: "white"
  };

  constructor(props) {
    super(props);

    this.getClassName = getClassNameFactory(TableWithFilters.displayName);

    this.timezone = momenttimezone.tz.guess();

    this.timezoneFormat = "DD-MMM-YYYY hh:mm A";

    this.filterTimeout = null;

    this.filterTimezoneIndex = 0;

    // Create a deep copy
    const data = JSON.parse(JSON.stringify(this.props.data));

    // Change array order of the filters to match they display order
    // This needs to be subsequent to adding keys addKeys()
    if (data.filter && data.filter.cells) {
      data.filter.cells.sort((a, b) => {
        if (
          typeof a.displayOrder === "number" &&
          typeof b.displayOrder === "number"
        ) {
          return a.displayOrder - b.displayOrder;
        }

        return 0;
      });
    }

    this.state = this.getInitialState();

    this.isSupportsSmoothScroll = this.isSupportsSmoothScroll();
  }

  componentDidMount() {
    if (this.props.timezones) {
      const timezone = getPropertyByName(
        this.props.timezoneSelectedOption,
        "value"
      );

      this.timezone = timezone ? timezone : this.timezone;

      this.filterLanguage();

      this.filterTimeout = setTimeout(() => {
        this.filterTimezone(
          this.timezone,
          this.filterTimezoneIndex,
          this.state.selectedItem
        );
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.filterTimeout);
  }

  componentDidUpdate(prevProps) {
    // Initialize pagination. Needed for refresh in Storybook.
    const rows = get(() => this.props.data.table.bodies[0].rows.length) || 0;

    if (this.props.rowsPerPage !== prevProps.rowsPerPage) {
      this.setState({
        pagination: {
          currentPage: 1,
          pages: Math.ceil(rows / this.props.rowsPerPage),
          rows
        }
      });
    }
  }

  getSelectedFilterItem(table) {
    let selectedItem = {};

    if (this.props.languageSelectedOption) {
      const itemName = getPropertyByName(
        this.props.languageSelectedOption,
        "label"
      );

      selectedItem = {
        content: itemName,
        key: getTableCellIndexByLabel(table, itemName)
      };
    }

    return selectedItem;
  }

  getInitialState() {
    const data = JSON.parse(JSON.stringify(this.props.data));

    // Add keys to table rows and cells
    this.addKeys(data);

    // Initialize pagination
    const rows = get(() => data.table.bodies[0].rows.length) || 0;

    // Change array order of the filters to match they display order
    // This needs to be subsequent to adding keys addKeys()
    if (data.filter && data.filter.cells) {
      data.filter.cells.sort((a, b) => {
        if (
          typeof a.displayOrder === "number" &&
          typeof b.displayOrder === "number"
        ) {
          return a.displayOrder - b.displayOrder;
        }

        return 0;
      });
    }

    return {
      filter: data.filter || {},
      table: data.table || {},
      selectedItem: this.state
        ? this.state.selectedItem
        : this.getSelectedFilterItem(data.table),
      pagination: {
        currentPage: 1,
        pages: Math.ceil(rows / this.props.rowsPerPage),
        rows
      }
    };
  }

  filterLanguage() {
    if (
      this.props.languageSelectedOption &&
      this.props.languageSelectedOption.length
    ) {
      const languageLabel = getPropertyByName(
        this.props.languageSelectedOption,
        "value"
      );

      this.handleChange(
        this.props.languageSelectedOption,
        getTableCellIndexByLabel(this.state.table, languageLabel)
      );
    }
  }

  getFormattedDate(item, region, format) {
    const formattedDate = momenttimezone
      .tz(getValidatedDate(item.content), item.region)
      .format();

    return `${momenttimezone
      .tz(formattedDate, region)
      .format(format)} ${getTimezoneByRegion(this.props.timezones, region)}`;
  }

  getFormattedTableData(items, region, format) {
    const table = JSON.parse(JSON.stringify(items));

    getTableRows(table).forEach(row => {
      const { cells } = row;
      const date = cells[0];

      date.content = this.getFormattedDate(date, region, format);
    });

    return table;
  }

  tagFilterHandleChange(selected, key, isTimezoneFilterAction) {
    if (isTimezoneFilterAction) {
      const initialState = JSON.parse(JSON.stringify(this.getInitialState()));

      initialState.filter = JSON.parse(JSON.stringify(this.state.filter));
      initialState.pagination = JSON.parse(
        JSON.stringify(this.state.pagination)
      );

      this.setState(initialState, () => {
        this.filterTimezone(selected, key, this.state.selectedItem);
      });
    } else {
      this.handleChange(selected, key);
    }
  }

  filterTimezone(region, key = 0, language = {}) {
    const filterData = JSON.parse(JSON.stringify(this.state.filter));
    const tableData = JSON.parse(JSON.stringify(this.state.table));
    const table = this.getFormattedTableData(
      tableData,
      region,
      this.timezoneFormat
    );

    const filter = { ...filterData };
    const filterCell = filter.cells.find(cell => cell.key === key);

    if (filterCell) {
      filterCell.selected = [{ label: region, value: region }];
    }

    getTableRows(table).forEach(row => {
      let filteredOut = true;
      const cellIndex = row.cells.findIndex(
        item =>
          (item.content === language.content && item.key === language.key) ||
          (!language.content || !language.key)
      );

      if (cellIndex >= 0) {
        filteredOut = false;
      }

      row.hidden = filteredOut;
    });

    this.setState({ table, filter });
  }

  getFilterItems(tagFilterOptions, selector, label) {
    const isPropertyExist = this.props.hasOwnProperty(selector);
    const newItem = [
      {
        label: this.props.filterHeader[label].placeholder,
        value: ""
      },
      ...tagFilterOptions
    ];

    return isPropertyExist ? this.props[selector] : newItem;
  }

  render() {
    const { filter, table, pagination } = this.state;
    const {
      filtersHideSelectedOptions,
      filtersNoOptionsMessage,
      filtersPlaceholder,
      infoText,
      isMulti,
      paginationResultSummaryText,
      pagesNavigableOnSide,
      noResultsMessage,
      rowsPerPage,
      subtitleHeadingRank,
      title,
      titleHeadingRank,
      infoLink,
      backgroundColor,
      languageSelectedOption,
      timezoneSelectedOption,
      timezones,
      filterHeader,
      isOverflow
    } = this.props;

    return (
      <div
        className={this.getClassName({
          modifiers: backgroundColor
        })}
        data-table-data={JSON.stringify(this.props.data)}
        data-filters-hide-selected-options={JSON.stringify(
          filtersHideSelectedOptions
        )}
        data-filters-no-options-message={JSON.stringify(
          filtersNoOptionsMessage
        )}
        data-filters-placeholder={JSON.stringify(filtersPlaceholder)}
        data-info-text={JSON.stringify(infoText)}
        data-no-results-message={JSON.stringify(noResultsMessage)}
        data-pages-navigable-on-side={JSON.stringify(pagesNavigableOnSide)}
        data-pagination-result-summary-text={JSON.stringify(
          paginationResultSummaryText
        )}
        data-rehydratable={getRehydratableName(TableWithFilters.displayName)}
        data-rows-per-page={JSON.stringify(rowsPerPage)}
        data-subtitle-heading-rank={JSON.stringify(subtitleHeadingRank)}
        data-title={JSON.stringify(title)}
        data-title-heading-rank={JSON.stringify(titleHeadingRank)}
        data-is-multi={JSON.stringify(isMulti)}
        data-info-link={JSON.stringify(infoLink)}
        data-background-color={JSON.stringify(backgroundColor)}
        data-language-selected-option={JSON.stringify(languageSelectedOption)}
        data-timezone-selected-option={JSON.stringify(timezoneSelectedOption)}
        data-timezones={JSON.stringify(timezones)}
        data-filters-mapping-with-table-headers={JSON.stringify(filterHeader)}
        data-is-overflow={JSON.stringify(isOverflow)}
      >
        <div>
          <VerticalSpacing size="l">
            <div
              className={this.getClassName({
                descendantName: "filtersPanel"
              })}
            >
              {title && (
                <VerticalSpacing size="xs">
                  <Heading type="m" rank={titleHeadingRank}>
                    {title}
                  </Heading>
                </VerticalSpacing>
              )}
              {infoText && (
                <VerticalSpacing>
                  <Header
                    heading={
                      <Heading rank={subtitleHeadingRank} type="xxs">
                        {infoText}{" "}
                        {infoLink && (
                          <a
                            href={infoLink.link}
                            target={infoLink.newWindow ? "_blank" : "_self"}
                          >
                            {infoLink.text}
                          </a>
                        )}
                      </Heading>
                    }
                  />
                </VerticalSpacing>
              )}
              <Grid variant="even-2">
                {get(() => filter.cells) &&
                  filter.cells.map((cell, index) => {
                    const key =
                      typeof cell.key === "undefined" ? index : cell.key;
                    let label = get(
                      () =>
                        cell.label
                          ? cell.label
                          : table.head.rows[0].cells[key].content || ""
                    );
                    let placeholder = cell.placeholder
                      ? cell.placeholder
                      : filtersPlaceholder;
                    let tagFilterOptions = this.getOptions(key);
                    let isTimezoneFilterAction = false;

                    if (filterHeader && filterHeader[label]) {
                      const selector = filterHeader[label].selector;

                      isTimezoneFilterAction =
                        selector && this.props.hasOwnProperty(selector); // used to remove no-unneeded-ternary violation
                      tagFilterOptions = this.getFilterItems(
                        tagFilterOptions,
                        selector,
                        label
                      );
                      placeholder = filterHeader[label].placeholder;
                      label = filterHeader[label].label;
                    }

                    return (
                      cell.display && (
                        <Grid.Item key={key.toString()}>
                          <TableWithFilters.TagFilter
                            index={index}
                            handleChange={selected => {
                              this.tagFilterHandleChange(
                                selected,
                                key,
                                isTimezoneFilterAction
                              );
                            }}
                            hideSelectedOptions={filtersHideSelectedOptions}
                            isMulti={isMulti}
                            label={label}
                            options={tagFilterOptions}
                            placeholder={placeholder}
                            noOptionsMessage={filtersNoOptionsMessage}
                            value={
                              get(
                                () =>
                                  filter.cells.find(
                                    cellFilter => cellFilter.key === key
                                  ).selected
                              ) || []
                            }
                          />
                        </Grid.Item>
                      )
                    );
                  })}
              </Grid>
            </div>
          </VerticalSpacing>
          <VerticalSpacing size="xs">
            <div
              className={this.getClassName({
                descendantName: "paginationResultSummary",
                className: typestack("p1")
              })}
              name="paginationResultSummary"
              ref={el => {
                this.paginationResultSummary = el;
              }}
            >
              <span role="status">{this.getPaginationResultSummaryText()}</span>
              {this.getPaginationMarkup("top")}
            </div>
          </VerticalSpacing>
          <VerticalSpacing size="m">
            <div
              className={`${this.getClassName({
                descendantName: "table",
                modifiers: !isOverflow && "auto"
              })}`}
            >
              {isOverflow ? (
                <HorizontalOverflow>
                  <TableWithFilters.Table
                    data={this.getPaginatedTable()}
                    startRowIndex={
                      (pagination.currentPage - 1) * this.props.rowsPerPage
                    }
                  />
                </HorizontalOverflow>
              ) : (
                <TableWithFilters.Table
                  data={this.getPaginatedTable()}
                  startRowIndex={
                    (pagination.currentPage - 1) * this.props.rowsPerPage
                  }
                />
              )}

              {pagination.pages < 1 && (
                <p
                  className={this.getClassName({
                    descendantName: "noResultsMessage"
                  })}
                >
                  {noResultsMessage}
                </p>
              )}
            </div>
          </VerticalSpacing>
          {this.getPaginationMarkup()}
        </div>
      </div>
    );
  }

  addKeys({ filter, table }) {
    if (get(() => table.head.rows)) {
      table.head.rows.forEach((row, indexRow) => {
        row.key = indexRow;
        if (row.cells) {
          row.cells.forEach((cell, indexCell) => {
            cell.key = indexCell;
          });
        }
      });
    }
    if (get(() => table.bodies[0].rows)) {
      table.bodies[0].rows.forEach((row, indexRow) => {
        row.key = indexRow;
        if (row.cells) {
          row.cells.forEach((cell, indexCell) => {
            cell.key = indexCell;

            // Link filters with table cells via "key" attribute
            if (indexRow === 0) {
              if (get(() => filter.cells && filter.cells[indexCell])) {
                filter.cells[indexCell].key = indexCell;
              }
            }
          });
        }
      });
    }
  }

  sortByMonth(list) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

    return list.sort((a, b) => months.indexOf(a) - months.indexOf(b));
  }

  getOptions(key) {
    const options = [];
    const { table } = this.state;

    getTableRows(table).forEach(row => {
      const { cells } = row;
      const option = cells[key].content;

      if (!options.includes(option)) {
        options.push(option);
      }
    });

    return this.sortByMonth(options).map(option => ({
      label: option,
      value: option
    }));
  }

  getPaginationMarkup = placement => {
    const { pagination: { currentPage, pages } } = this.state;
    const { pagesNavigableOnSide } = this.props;
    const getClassName = this.getClassName;

    const PaginationWrapper = this.getPaginationWrapper(placement);
    const topPagination = placement === "top";
    const pageRange = 2 * pagesNavigableOnSide + 1; // eslint-disable-line no-magic-numbers

    if (pages > 1) {
      return (
        <PaginationWrapper>
          <div
            className={getClassName({
              descendantName: "paginationInnerWrapper"
            })}
          >
            {topPagination ? (
              <Pagination
                borderlessAtStandard={true}
                condensed={true}
                condensedAtNarrow={false}
                initialPage={currentPage}
                onPageChanged={this.goToPage}
                pageCount={pages}
                pageRangeCount={pageRange}
              />
            ) : (
              <Pagination
                initialPage={currentPage}
                onPageChanged={this.goToPage}
                pageCount={pages}
                pageRangeCount={pageRange}
              />
            )}
          </div>
        </PaginationWrapper>
      );
    }

    return null;
  };

  getPaginationResultSummaryText = () => {
    const { paginationResultSummaryText, rowsPerPage } = this.props;
    const { pagination } = this.state;

    return paginationResultSummaryText
      .replace(
        "{1}",
        `${
          pagination.rows > 0
            ? (pagination.currentPage - 1) * rowsPerPage + 1
            : 0
        }-${
          pagination.currentPage * rowsPerPage < pagination.rows
            ? pagination.currentPage * rowsPerPage
            : pagination.rows
        }`
      )
      .replace("{2}", pagination.rows);
  };

  getPaginatedTable() {
    const { table, pagination } = this.state;
    const { rowsPerPage } = this.props;

    // Create a deep copy
    const paginatedTable = JSON.parse(JSON.stringify(table));

    if (get(() => paginatedTable.bodies[0].rows)) {
      const pageRows = paginatedTable.bodies[0].rows
        .filter(row => !row.hidden)
        .slice(
          (pagination.currentPage - 1) * rowsPerPage,
          pagination.currentPage * rowsPerPage
        );

      if (pageRows.length) {
        paginatedTable.bodies[0].rows = pageRows;
      }
    }

    return paginatedTable;
  }

  getPaginationWrapper = placement => ({ children }) => {
    const topPagination = placement === "top";

    if (!topPagination) {
      return <VerticalSpacing size="xs">{children}</VerticalSpacing>;
    }

    return children;
  };

  goToPage = page => {
    // Create a deep copy
    const pagination = JSON.parse(JSON.stringify(this.state.pagination));

    if (page >= 1 && page <= pagination.pages) {
      pagination.currentPage = page;
      this.setState({ pagination });

      this.handlePageChange();
    }
  };

  handleChange(item, index) {
    // Create a deep copy
    const filter = JSON.parse(JSON.stringify(this.state.filter));
    const table = JSON.parse(JSON.stringify(this.state.table));
    const pagination = JSON.parse(JSON.stringify(this.state.pagination));
    const { rowsPerPage, timezones } = this.props;
    const selectedItem = {
      content: item instanceof Array ? getPropertyByName(item, "label") : item,
      key: index
    };
    let selected = item;

    if (!(selected instanceof Array)) {
      selected = [
        {
          label: item,
          value: item
        }
      ];
    }

    if (get(() => table.bodies[0].rows && table.bodies[0].rows.length)) {
      filter.cells.forEach(cell => {
        if (cell.key === index) {
          cell.selected = selected;
        }
      });

      getTableRows(table).forEach(row => {
        const filteredOut = row.cells.some(cell => {
          const filterCell = filter.cells.find(filterCellFind => {
            return timezones
              ? filterCellFind.key === cell.key && cell.key === index
              : filterCellFind.key === cell.key;
          });
          const selectedFilter =
            filterCell && filterCell.selected && filterCell.selected.length;

          if (selectedFilter) {
            return !filterCell.selected.some(
              option => option.value === cell.content || option.value === ""
            );
          }

          return false;
        });

        row.hidden = filteredOut;
      });

      pagination.rows = getTableRows(table).filter(row => !row.hidden).length;
      pagination.pages = Math.ceil(pagination.rows / rowsPerPage);
    }
    pagination.currentPage = 1;

    this.setState({
      filter,
      table,
      pagination,
      selectedItem
    });
  }

  handlePageChange = () => {
    const delay = 100;

    if (this.paginationResultSummary) {
      clearTimeout(this.scrollTimeout);
      this.scrollTimeout = setTimeout(() => {
        const rect = this.paginationResultSummary.getBoundingClientRect();
        const resultSummaryCointainerTopPosition = rect.y || rect.top; // IE 11 does not return back x/y values, in that case need to fallback to top/left values.
        const windowScrollY = window.scrollY || window.pageYOffset; // IE 11 does not support window scrollY, in that case need to fallback to pageYOffset value.

        if (this.isSupportsSmoothScroll) {
          window.scrollTo({
            top: resultSummaryCointainerTopPosition + windowScrollY,
            behavior: "smooth"
          });
        } else {
          window.scrollTo(
            0,
            resultSummaryCointainerTopPosition + windowScrollY
          );
        }
      }, delay);
    }
  };

  isSupportsSmoothScroll = () => {
    let supports = false;

    try {
      const div = document.createElement("div");

      div.scrollTo({
        top: 0,
        get behavior() {
          supports = true;

          return "smooth";
        }
      });
    } catch (err) {
      supports = false;
    }

    return supports;
  };
}

TableWithFilters.TagFilter = TagFilter;
TableWithFilters.Table = Table;

export default TableWithFilters;
