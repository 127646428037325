let doughnutToolTipLabelValue = "";
let isDoughnut;
let isHorizontalBar;
let updatedColors;
let updatedLegends;
const firstElement = 0;
const secondElement = 1;
const multiplicationVariable = 10;

export const customToolTip = (tooltipModel, doughnutToolTipLabel) => {
  let tooltipEl = document.getElementById("chartjs-tooltip");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.id = "chartjs-tooltip";
    tooltipEl.innerHTML = '<table class="tooltip-bar"></table>';
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0;

    return;
  }

  // Set caret Position
  tooltipEl.classList.remove("above", "below", "no-transform");
  tooltipEl.classList.add("left");
  // Set Text
  if (tooltipModel.body) {
    let innerHtml = "";

    innerHtml += "<tbody>";
    tooltipModel.body.map((textValue, index) => {
      innerHtml += textValue.lines[index];
      if (doughnutToolTipLabelValue || doughnutToolTipLabel) {
        doughnutToolTipLabelValue =
          doughnutToolTipLabelValue || doughnutToolTipLabel;
        innerHtml += ` ${doughnutToolTipLabelValue}`;
      }

      return innerHtml;
    });
    const tableRoot = tooltipEl.querySelector("table");

    tableRoot.innerHTML = innerHtml;
  }
  // `this` will be the overall tooltip
  const canvasElement = document.querySelector("canvas");
  let position;

  if (canvasElement) {
    position = canvasElement.getBoundingClientRect();
  }

  // Display, position, and set styles for font
  tooltipEl.style.background = "#FFFFFF";
  tooltipEl.style.border = "1px solid #D2EBCB";
  tooltipEl.style.opacity = 1;
  tooltipEl.style.position = "absolute";
  if (position) {
    tooltipEl.style.left = `
      ${position.left + window.pageXOffset + tooltipModel.caretX}px`;
    tooltipEl.style.top = `${position.top +
      window.pageYOffset +
      tooltipModel.caretY}px`;
  }
  tooltipEl.style.fontSize = "14px";
  tooltipEl.style.padding = `
    ${tooltipModel.yPadding}px  ${tooltipModel.xPadding}px`;
  tooltipEl.style.textAlign = "left";
  tooltipEl.style.borderRadius = "5px";
  tooltipEl.style.pointerEvents = "none";
};

const xAxisGridConfig = {
  color: "#CCC",
  drawOnChartArea: false,
  offsetGridLines: true,
  tickMarkLength: 4
};

const doughnutTooltip = {
  enabled: false,
  mode: "index",
  custom: customToolTip
};

const getDataSet = (value, dataSetColors, dataSetLegend) => {
  let border = {
    borderColor: dataSetColors,
    borderWidth: 0,
    hoverBorderWidth: null,
    hoverBorderColor: null,
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: "miter"
  };

  if (isDoughnut) {
    border = {
      ...border,
      borderColor: "#FFFFFF",
      borderWidth: 2,
      hoverBorderWidth: 2,
      hoverBorderColor: "#FFFFFF"
    };
  }

  return {
    ...border,
    label: dataSetLegend,
    fill: false,
    lineTension: 0.1,
    backgroundColor: dataSetColors,
    barThickness: 20,
    pointBorderColor: dataSetColors,
    pointBackgroundColor: dataSetColors,
    pointBorderWidth: 3,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: dataSetColors,
    pointHoverBorderColor: dataSetColors,
    pointHoverBorderWidth: 2,
    pointRadius: 3,
    pointHitRadius: 5,
    data: value
  };
};

const getDataSetList = data => {
  const dataSetList =
    isHorizontalBar ||
    (typeof data[firstElement] === "object" && data[firstElement].length > 0);

  const dataSet = data.map((value, index) => {
    const updatedValue = Object.keys(value).length ? value : [value];

    return getDataSet(
      updatedValue,
      updatedColors[index] || updatedColors,
      updatedLegends[index] || updatedLegends
    );
  });

  const getDataSetValue = dataSetList
    ? dataSet
    : [getDataSet(data, updatedColors, updatedLegends)];

  return getDataSetValue;
};

const getchartData = (labels, datasets, data) => {
  return {
    labels,
    datasets: datasets || getDataSetList(data)
  };
};

const chartConfig = ({
  data,
  labels,
  colors,
  legends,
  chartTitle,
  options,
  tooltipTexts,
  type,
  numberOfGrids,
  yLabel,
  stepSize,
  scaleType,
  doughnutToolTipLabel,
  isStacked,
  datasets,
  maxValue = 0
}) => {
  isHorizontalBar = type === "horizontalBar";
  isDoughnut = type === "doughnut";
  const chartType = !isDoughnut && !isHorizontalBar;
  const isAlign = doughnutToolTipLabel
    ? { yAlign: "bottom" }
    : { yAlign: "right" };
  const tooltipConfig = isDoughnut || isHorizontalBar ? isAlign : {};

  updatedColors = colors.length === 1 ? colors[firstElement] : colors;
  updatedLegends = legends.length === 1 ? legends[firstElement] : legends;
  let max;
  let updatedStepSize = stepSize;

  doughnutToolTipLabelValue = doughnutToolTipLabel;
  if (!updatedStepSize) {
    max =
      Math.ceil(maxValue / (numberOfGrids * multiplicationVariable)) *
      (numberOfGrids * multiplicationVariable);
    updatedStepSize = Math.ceil(max / numberOfGrids);
  }
  const sizeConfig = {
    max,
    stepSize: updatedStepSize
  };

  const isMultiple =
    typeof data[firstElement] === "object" &&
    typeof data[secondElement] === "object";

  const chartOptions =
    Object.keys(options).length !== 0 && options.constructor !== Object
      ? options
      : {
          responsive: true,
          maintainAspectRatio: false,
          cutoutPercentage: 75,
          scales: {
            xAxes: [
              {
                stacked: isStacked,
                display: chartType,
                gridLines: {
                  display: chartType,
                  drawBorder: false,
                  ...xAxisGridConfig
                },
                ticks: {
                  fontSize: 13,
                  precision: 0
                },
                ...(isMultiple && {
                  categoryPercentage: 0.3,
                  barPercentage: 0.7
                })
              }
            ],
            yAxes: [
              {
                stacked: isStacked,
                display: chartType,
                gridLines: {
                  display: chartType,
                  drawBorder: false
                },
                ticks: {
                  fontSize: 13,
                  padding: 10,
                  precision: 0,
                  beginAtZero: true,
                  ...sizeConfig,
                  ...(scaleType && {
                    callback: val => `${val}${scaleType}`
                  })
                },
                scaleLabel: {
                  display: true,
                  labelString: yLabel
                }
              }
            ]
          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 20
            }
          },
          legend: {
            display: false,
            position: isDoughnut ? "right" : "bottom",
            labels: {
              fontSize: 13
            },
            align: "center"
          },
          title: {
            display: chartType && chartTitle,
            text: chartTitle,
            fontSize: 15,
            lineHeight: 1.6,
            fontWeight: "500"
          },
          tooltips: isDoughnut
            ? doughnutTooltip
            : {
                backgroundColor: "#FFF",
                borderColor: "#dddddd",
                borderWidth: 1,
                callbacks: {
                  labelTextColor() {
                    return "#543453";
                  },
                  title(tooltipItem) {
                    const { index, label } = tooltipItem[firstElement];

                    return tooltipTexts[index] || label;
                  }
                },
                labelTextColors: "#4F4F4F",
                titleFontColor: "#4F4F4F",
                titleFontSize: 15,
                titleMarginBottom: 15,
                xPadding: 20,
                yPadding: 20,
                ...tooltipConfig
              }
        };

  return {
    chartData: getchartData(labels, datasets, data),
    options: chartOptions
  };
};

export default chartConfig;
