import React from "react";
import { storiesOf } from "@storybook/react";
import {
  withKnobs,
  text,
  select,
  object,
  boolean
} from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import PlanCard from "./index";
import "./PlanCard.css";
import PlanCardREADME from "../README.md";
import Button from "@emcm-ui/component-button";
import Badge from "@emcm-ui/component-badge";
import Typestack from "@emcm-ui/component-typestack";
import Section from "@emcm-ui/component-section";
import Grid from "@emcm-ui/component-grid";

const colorOptions = Button.colorOptions;

const type = Badge.type;

const color = Badge.color;

const background = Badge.background;

const stories = storiesOf("Components/E-Commerce/PlanCard", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(PlanCardREADME));

const headingRankOptions = Typestack.ranks;

stories
  .add("Default", () => (
    <Section>
      <Grid variant="even-3">
        <Grid.Item>
          <PlanCard
            id={text("Plancard id", "plancard1")}
            heading={text("Heading", "Heading")}
            headingRank={select("Heading rank", headingRankOptions, "h3")}
            isBadgeRequired={boolean("Badge Required", true)}
            badgeText={text("Badge Text", "NEW")}
            badgeType={select("Badge Type", type, "tag")}
            background={select("Badge Background", background, "dark")}
            badgeColor={select("Badge Style", color, "blue")}
            price={text("Price", "£500")}
            priceHeadingRank={select(
              "Price heading rank",
              headingRankOptions,
              "h3"
            )}
            priceInfo={text("Price Info", "per month excluding tax and VAT")}
            priceNote={text(
              "Price note",
              "Prices shown in local currency post registration."
            )}
            isValuePointsRequired={boolean("Value Points Required", true)}
            valuePoints={text("Value Points", "66,667")}
            valuePointsInfo={text("Value Points Info", "/ annual value points")}
            bulletPoints={object("Bullet Points", [
              {
                id: 1,
                feature: "Line item 1_Content placeholder",
                helpText: "Additional Information feature 1"
              },
              {
                id: 2,
                feature: "Line item 2_Content placeholder",
                helpText: "Additional Information feature 2"
              },
              {
                id: 3,
                feature: "Line item 3_Content placeholder",
                helpText: "Additional Information feature 3"
              },
              {
                id: 4,
                feature: "Line item 4_Content placeholder",
                helpText: "Additional Information feature 4"
              }
            ])}
            isShowMoreRequired={boolean("Showmore", true)}
            showMoreText={text("Show More Text", "Show More")}
            showLessText={text("Show Less Text", "Show Less")}
            isTooltipRequired={boolean("Tooltip on bullet points", true)}
            href={text("Button href", "http://example.com/")}
            buttonText={text("Button Label", "Primary CTA")}
            buttonColor={select("Color", colorOptions, "primary")}
            secondButtonText={text("Second Button Label", "Secondary CTA")}
            secondButtonHref={text("Second Button href", "http://example.com/")}
            secondButtonColor={select(
              "Second Button Color",
              colorOptions,
              "secondary"
            )}
          >
            <PlanCard.RichText>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry&apos;s standard
                dummy text ever since the 1500s, when an unknown printer took a
                galley of type and scrambled it to make a type specimen book. It
                has survived not only five centuries.
              </p>
            </PlanCard.RichText>
          </PlanCard>
        </Grid.Item>
      </Grid>
    </Section>
  ))
  .add("Long description", () => (
    <Section>
      <Grid variant="even-3">
        <Grid.Item>
          <PlanCard
            id={text("Plancard id", "plancard1")}
            heading={text("Heading", "Heading")}
            headingRank={select("Heading rank", headingRankOptions, "h3")}
            isBadgeRequired={boolean("Badge Required", true)}
            badgeText={text("Badge Text", "NEW")}
            badgeType={select("Badge Type", type, "tag")}
            background={select("Badge Background", background, "dark")}
            badgeColor={select("Badge Style", color, "blue")}
            price={text("Price", "£500")}
            priceHeadingRank={select(
              "Price heading rank",
              headingRankOptions,
              "h3"
            )}
            priceInfo={text("Price Info", "per month excluding tax and VAT")}
            priceNote={text(
              "Price note",
              "Prices shown in local currency post registration."
            )}
            isValuePointsRequired={boolean("Value Points Required", true)}
            valuePoints={text("Value Points", "66,667")}
            valuePointsInfo={text("Value Points Info", "/ annual value points")}
            bulletPoints={object("Bullet Points", [
              {
                id: 1,
                feature: "Line item 1_Content placeholder",
                helpText: "Additional Information feature 1"
              },
              {
                id: 2,
                feature: "Line item 2_Content placeholder",
                helpText: "Additional Information feature 2"
              },
              {
                id: 3,
                feature: "Line item 3_Content placeholder",
                helpText: "Additional Information feature 3"
              },
              {
                id: 4,
                feature: "Line item 4_Content placeholder",
                helpText: "Additional Information feature 4"
              }
            ])}
            isShowMoreRequired={boolean("Showmore", true)}
            showMoreText={text("Show More Text", "Show More")}
            showLessText={text("Show Less Text", "Show Less")}
            isTooltipRequired={boolean("Tooltip on bullet points", true)}
            href={text("Button href", "http://example.com/")}
            buttonText={text("Button Label", "Primary CTA")}
            buttonColor={select("Color", colorOptions, "primary")}
            secondButtonText={text("Second Button Label", "Secondary CTA")}
            secondButtonHref={text("Second Button href", "http://example.com/")}
            secondButtonColor={select(
              "Second Button Color",
              colorOptions,
              "secondary"
            )}
          >
            <PlanCard.RichText>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry&apos;s standard
                dummy text ever since the 1500s, when an unknown printer took a
                galley of type and scrambled it to make a type specimen book. It
                has survived not only five centuries.
              </p>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry&apos;s standard
                dummy text ever since the 1500s, when an unknown printer took a
                galley of type and scrambled it to make a type specimen book. It
                has survived not only five centuries.
              </p>
            </PlanCard.RichText>
          </PlanCard>
        </Grid.Item>
      </Grid>
    </Section>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <Section>
        <Grid variant="even-3">
          <Grid.Item>
            <PlanCard
              id={text("Plancard id", "plancard1")}
              heading={text("Heading", "Heading")}
              headingRank={select("Heading rank", headingRankOptions, "h3")}
              isBadgeRequired={boolean("Badge Required", true)}
              badgeText={text("Badge Text", "NEW")}
              badgeType={select("Badge Type", type, "tag")}
              background={select("Badge Background", background, "dark")}
              badgeColor={select("Badge Style", color, "blue")}
              price={text("Price", "£500")}
              priceHeadingRank={select(
                "Price heading rank",
                headingRankOptions,
                "h3"
              )}
              priceInfo={text("Price Info", "per month excluding tax and VAT")}
              priceNote={text(
                "Price note",
                "Prices shown in local currency post registration."
              )}
              isValuePointsRequired={boolean("Value Points Required", true)}
              valuePoints={text("Value Points", "66,667")}
              valuePointsInfo={text(
                "Value Points Info",
                "/ annual value points"
              )}
              bulletPoints={object("Bullet Points", [
                {
                  id: 1,
                  feature: "Line item 1_Content placeholder",
                  helpText: "Additional Information feature 1"
                },
                {
                  id: 2,
                  feature: "Line item 2_Content placeholder",
                  helpText: "Additional Information feature 2"
                },
                {
                  id: 3,
                  feature: "Line item 3_Content placeholder",
                  helpText: "Additional Information feature 3"
                },
                {
                  id: 4,
                  feature: "Line item 4_Content placeholder",
                  helpText: "Additional Information feature 4"
                }
              ])}
              isShowMoreRequired={boolean("Showmore", true)}
              showMoreText={text("Show More Text", "Show More")}
              showLessText={text("Show Less Text", "Show Less")}
              isTooltipRequired={boolean("Tooltip on bullet points", true)}
              href={text("Button href", "http://example.com/")}
              buttonText={text("Button Label", "Primary CTA")}
              buttonColor={select("Color", colorOptions, "primary")}
              secondButtonText={text("Second Button Label", "Secondary CTA")}
              secondButtonHref={text(
                "Second Button href",
                "http://example.com/"
              )}
              secondButtonColor={select(
                "Second Button Color",
                colorOptions,
                "secondary"
              )}
            >
              <PlanCard.RichText>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry&apos;s
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries.
                </p>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry&apos;s
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries.
                </p>
              </PlanCard.RichText>
            </PlanCard>
          </Grid.Item>
        </Grid>
      </Section>
    </StaticRenderer>
  ));
