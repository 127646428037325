import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import Block from "@emcm-ui/component-block";
import { Language } from "@emcm-ui/component-theme";
import Panel from "./index";
import "./Panel.css";
import PanelREADME from "../README.md";
import { Provider as AjaxPanelProvider } from "../../component-panel/src/ajaxPanelProvider";
import AjaxPanel from "../../component-panel/src/Panel/Panel";

const stories = storiesOf("Components/Panel", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(PanelREADME));

stories
  .add("Default", () => (
    <Panel>
      <Block color="orange" height="6rem" text="children" />
    </Panel>
  ))
  .add("Ajax Panel", () => (
    <AjaxPanelProvider value={true}>Your data</AjaxPanelProvider>
  ))
  .add("Ajax Conditional Content", () => (
    <AjaxPanelProvider value={"chocolate"}>
      <AjaxPanel
        id="chocolate"
        tabTitle="chocolate"
        contentLocation={`./chocolate.html`}
      />
      <AjaxPanel
        id="vanilla"
        tabTitle="vanilla"
        contentLocation={`./vanilla.html`}
      />
      <AjaxPanel
        id="strawberry"
        tabTitle="strawberry"
        contentLocation={`./strawberry.html`}
      />
    </AjaxPanelProvider>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Panel>
        <Block color="orange" height="6rem" text="الأطفال" />
      </Panel>
    </Language>
  ));
