import React from "react";
import { storiesOf } from "@storybook/react";
import { boolean, text, withKnobs } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import Link from "@emcm-ui/component-link";
import Icon from "@emcm-ui/component-icon";
import SearchResults from "./index";
import "./SearchResults.css";
import SearchResultsREADME from "../README.md";

const stories = storiesOf("Components/SearchResults", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(SearchResultsREADME));

stories
  .add("Default", () => (
    <SearchResults title="Viewing 1-12 of 272 for 'financial'">
      <SearchResults.Result
        dateTime={
          boolean("Show date? (deprecated)", false) ? "2018-03-02" : null
        }
        description={
          <span>
            Eikon Messenger gives you access to the world’s largest verified
            directory of <strong>financial</strong> market participants for
            <strong>financial</strong> messaging via the open messaging network.
          </span>
        }
        href="https://www.example.com"
        links={[
          <Link
            href="https://www.example.com"
            type="icon"
            icon={<Icon path="./icons/ui/grey/user-07.svg" size="xs" />}
            iconPosition={"left"}
          >
            Login
          </Link>,
          <a href="https://www.example.com">Support</a>,
          <a href="https://www.example.com">Training</a>
        ]}
        title="Eikon Messenger for Financial Messaging"
      />
      <SearchResults.Result
        dateTime={
          boolean("Show date? (deprecated)", false) ? "2018-03-02" : null
        }
        description={
          <span>
            Eikon is the <strong>financial</strong> analysis desktop and mobile
            solution that connects you to relevant, trusted content, Reuters
            news, venues, markets, liquidity pools, and colleagues.
          </span>
        }
        href="https://www.example.com"
        imgSrc="test-images/16-9/large_2.jpg"
        imgSrcSet="test-images/16-9/large_2.jpg 2000w, test-images/16-9/medium_2.jpg 800w, test-images/16-9/small_2.jpg 400w"
        links={[
          <Link
            href="https://www.example.com"
            type="icon"
            icon={<Icon path="./icons/ui/grey/user-07.svg" size="xs" />}
            iconPosition={"left"}
          >
            Login
          </Link>,
          <a href="https://www.example.com">Support</a>,
          <a href="https://www.example.com">Training</a>
        ]}
        title="Eikon Financial Analysis"
      />
      <SearchResults.Result
        dateTime={
          boolean("Show date? (deprecated)", false) ? "2018-03-02" : null
        }
        description={
          <span>
            Thomson Reuters is adding foreign exchange (FX) pricing granularity
            for customers trading Australian Dollars (AUD) vs. U.S. Dollars
            (USD), creating greater accuracy in price quotes in AUD/USD.
          </span>
        }
        eyebrow="Blog post"
        href="https://www.example.com"
        footer={
          <SearchResults.Result.ArticleInfoFooter
            dateTime="2017-11-14"
            duration="PT5M"
            durationText="5 min"
            durationIcon={<Icon path="./icons/ui/grey/time-07.svg" size="xs" />}
            name="Carla Y."
          />
        }
        title="Thomson Reuters to enhance FX trading accuracy by adding further pricing precision for AUD/USD | Thomson Reuters"
        showFeatured={boolean("Show featured?", false)}
        featuredText={text("Featured Text", "featured")}
        footerLinkTitle={"Footer Link Title"}
        altText="tax dictionary image altText"
      />
      <SearchResults.Result
        dateTime={
          boolean("Show date? (deprecated)", false) ? "2018-03-02" : null
        }
        description={
          <span>
            The WG&L Tax Dictionary is a complete desktop reference guide to all
            tax and tax-related <strong>financial</strong> terminology.
            Definitions contain important references to: Internal Revenue Code
            sections, Treasury regulations IRS rulings, Court decisions,
            Legislative history, Law review articles
          </span>
        }
        eyebrow="Reference guide"
        href="https://www.example.com"
        footer={
          <SearchResults.Result.TagFooter>
            <SearchResults.Result.TagFooter.Tag>
              Corporations
            </SearchResults.Result.TagFooter.Tag>
            <SearchResults.Result.TagFooter.Tag>
              Accounting forms
            </SearchResults.Result.TagFooter.Tag>
            <SearchResults.Result.TagFooter.Tag>
              Governments
            </SearchResults.Result.TagFooter.Tag>
          </SearchResults.Result.TagFooter>
        }
        title="Tax Dictionary"
        imgSrc="test-images/16-9/large_2.jpg"
        imgSrcSet="test-images/16-9/large_2.jpg 2000w, test-images/16-9/medium_2.jpg 800w, test-images/16-9/small_2.jpg 400w"
        showFeatured={boolean("Show featured?", false)}
        featuredText={text("Featured Text", "featured")}
        footerLinkTitle={"Footer Link Title"}
        altText="tax dictionary image altText"
      />
      <SearchResults.Result
        description={
          <span>
            The WG&L Tax Dictionary is a complete desktop reference guide to all
            tax and tax-related <strong>financial</strong> terminology.
            Definitions contain important references to: Internal Revenue Code
            sections, Treasury regulations IRS rulings, Court decisions,
            Legislative history, Law review articles
          </span>
        }
        href="https://www.example.com"
        footer={
          <SearchResults.Result.EventInfoFooter
            eventDate="2017-11-14"
            eventTime="11:00 AM"
            location="London"
          />
        }
        subtitle={text("Subtitle", "Document")}
        iconInMobile={boolean("Icon in Mobile View", true)}
        icon={
          <Icon path="./icons/nucleo/files-folders/large/pptx.svg" size="l" />
        }
        title="Thomson Reuters to enhance FX trading accuracy by adding further pricing precision for AUD/USD | Thomson Reuters"
      />
    </SearchResults>
  ))

  .add("Grid View", () => (
    <SearchResults
      title="Viewing 1-12 of 272 for 'financial'"
      resultView="card-grid"
    >
      <SearchResults.ResultCard
        dateTime={
          boolean("Show date? (deprecated)", false) ? "2018-03-02" : null
        }
        description={
          <span>
            Eikon Messenger gives you access to the world’s largest verified
            directory of <strong>financial</strong> market participants for
            <strong>financial</strong> messaging via the open messaging network.
          </span>
        }
        href="https://www.example.com"
        links={[
          <Link
            href="https://www.example.com"
            type="icon"
            icon={<Icon path="./icons/ui/grey/user-07.svg" size="xs" />}
            iconPosition={"left"}
          >
            Login
          </Link>,
          <a href="https://www.example.com">Support</a>,
          <a href="https://www.example.com">Training</a>
        ]}
        title="Eikon Messenger for Financial Messaging"
      />
      <SearchResults.ResultCard
        dateTime={
          boolean("Show date? (deprecated)", false) ? "2018-03-02" : null
        }
        description={
          <span>
            Eikon is the <strong>financial</strong> analysis desktop and mobile
            solution that connects you to relevant, trusted content, Reuters
            news, venues, markets, liquidity pools, and colleagues.
          </span>
        }
        href="https://www.example.com"
        imgSrc="test-images/16-9/large_2.jpg"
        imgSrcSet="test-images/16-9/large_2.jpg 2000w, test-images/16-9/medium_2.jpg 800w, test-images/16-9/small_2.jpg 400w"
        links={[
          <Link
            href="https://www.example.com"
            type="icon"
            icon={<Icon path="./icons/ui/grey/user-07.svg" size="xs" />}
            iconPosition={"left"}
          >
            Login
          </Link>,
          <a href="https://www.example.com">Support</a>,
          <a href="https://www.example.com">Training</a>
        ]}
        title="Eikon Financial Analysis"
      />
      <SearchResults.ResultCard
        dateTime={
          boolean("Show date? (deprecated)", false) ? "2018-03-02" : null
        }
        description={
          <span>
            Thomson Reuters is adding foreign exchange (FX) pricing granularity
            for customers trading Australian Dollars (AUD) vs. U.S. Dollars
            (USD), creating greater accuracy in price quotes in AUD/USD.
          </span>
        }
        eyebrow="Blog post"
        href="https://www.example.com"
        footer={
          <SearchResults.Result.ArticleInfoFooter
            dateTime="2017-11-14"
            duration="PT5M"
            durationText="5 min"
            durationIcon={<Icon path="./icons/ui/grey/time-07.svg" size="xs" />}
            name="Carla Y."
          />
        }
        title="Thomson Reuters to enhance FX trading accuracy by adding further pricing precision for AUD/USD | Thomson Reuters"
        showFeatured={boolean("Show featured?", false)}
        featuredText={text("Featured Text", "featured")}
        includeFooterLink={boolean("Include Footer Link?", false)}
        footerLinkTitle="Footer Link Title"
        altText="tax dictionary image altText"
      />
      <SearchResults.ResultCard
        dateTime={
          boolean("Show date? (deprecated)", false) ? "2018-03-02" : null
        }
        description={
          <span>
            The WG&L Tax Dictionary is a complete desktop reference guide to all
            tax and tax-related <strong>financial</strong> terminology.
            Definitions contain important references to: Internal Revenue Code
            sections, Treasury regulations IRS rulings, Court decisions,
            Legislative history, Law review articles
          </span>
        }
        eyebrow="Reference guide"
        href="https://www.example.com"
        footer={
          <SearchResults.Result.TagFooter>
            <SearchResults.Result.TagFooter.Tag>
              Corporations
            </SearchResults.Result.TagFooter.Tag>
            <SearchResults.Result.TagFooter.Tag>
              Accounting forms
            </SearchResults.Result.TagFooter.Tag>
            <SearchResults.Result.TagFooter.Tag>
              Governments
            </SearchResults.Result.TagFooter.Tag>
          </SearchResults.Result.TagFooter>
        }
        title="Tax Dictionary"
        imgSrc="test-images/16-9/large_2.jpg"
        imgSrcSet="test-images/16-9/large_2.jpg 2000w, test-images/16-9/medium_2.jpg 800w, test-images/16-9/small_2.jpg 400w"
        showFeatured={boolean("Show featured?", false)}
        featuredText={text("Featured Text", "featured")}
        includeFooterLink={boolean("Include Footer Link?", false)}
        footerLinkTitle="Footer Link Title"
        altText="tax dictionary image altText"
      />
      <SearchResults.ResultCard
        description={
          <span>
            The WG&L Tax Dictionary is a complete desktop reference guide to all
            tax and tax-related <strong>financial</strong> terminology.
            Definitions contain important references to: Internal Revenue Code
            sections, Treasury regulations IRS rulings, Court decisions,
            Legislative history, Law review articles
          </span>
        }
        href="https://www.example.com"
        footer={
          <SearchResults.Result.EventInfoFooter
            eventDate="2017-11-14"
            eventTime="11:00 AM"
            location="London"
          />
        }
        subtitle={text("Subtitle", "Document")}
        iconInMobile={boolean("Icon in Mobile View", true)}
        icon={
          <Icon path="./icons/nucleo/files-folders/large/pptx.svg" size="l" />
        }
        title="Thomson Reuters to enhance FX trading accuracy by adding further pricing precision for AUD/USD | Thomson Reuters"
      />
    </SearchResults>
  ))
  .add("No Results", () => (
    <SearchResults title="We did not find any matches for 'financial'">
      <SearchResults.NoResults />
    </SearchResults>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <SearchResults title="عرض 1-12 من 272 ل 'المالية'">
        <SearchResults.Result
          dateTime={
            boolean("Show date? (deprecated)", false) ? "2018-03-02" : null
          }
          description={
            <span>
              تنشيط سير العمل الخاص بك من البداية الى النهاية والاستفادة من
              إدارة بيانات المؤسسة مع بيانات السوق كاملة والتكنولوجيا المالية
              تقدم.
            </span>
          }
          href="https://www.example.com"
          links={[
            <Link
              href="https://www.example.com"
              type="icon"
              icon={<Icon path="./icons/ui/grey/user-07.svg" size="xs" />}
              iconPosition={"left"}
            >
              تسجيل الدخول
            </Link>,
            <a href="https://www.example.com">الدعم</a>,
            <a href="https://www.example.com">تدريب</a>
          ]}
          title="إيكون التحليل المالي"
        />
        <SearchResults.Result
          dateTime={
            boolean("Show date? (deprecated)", false) ? "2018-03-02" : null
          }
          description={
            <span>
              تمنحك خدمات تسعير الأصول الخاصة ب تومسون ريوترز بيانات تسعير مالية
              شفافة ومستقلة ودقيقة يمكنك الاعتماد عليها.
            </span>
          }
          href="https://www.example.com"
          imgSrc="test-images/16-9/large_2.jpg"
          imgSrcSet="test-images/16-9/large_2.jpg 2000w, test-images/16-9/medium_2.jpg 800w, test-images/16-9/small_2.jpg 400w"
          links={[
            <Link
              href="https://www.example.com"
              type="icon"
              icon={<Icon path="./icons/ui/grey/user-07.svg" size="xs" />}
              iconPosition={"left"}
            >
              تسجيل الدخول
            </Link>,
            <a href="https://www.example.com">الدعم</a>,
            <a href="https://www.example.com">تدريب</a>
          ]}
          title="إيكون التحليل المالي"
        />
        <SearchResults.Result
          dateTime={
            boolean("Show date? (deprecated)", false) ? "2018-03-02" : null
          }
          description={
            <span>
              إيكون رسول يتيح لك الوصول إلى أكبر دليل التحقق في العالم من
              المشاركين في السوق المالية للرسائل المالية عبر شبكة الرسائل
              المفتوحة.
            </span>
          }
          eyebrow="مشاركة مدونة"
          href="https://www.example.com"
          footer={
            <SearchResults.Result.ArticleInfoFooter
              dateTime="2017-11-14"
              duration="PT5M"
              durationText="مرحبا بالعالم"
              durationIcon={
                <Icon path="./icons/ui/grey/time-07.svg" size="xs" />
              }
              name="عبلة"
            />
          }
          title="إيكون التحليل المالي"
        />
        <SearchResults.Result
          dateTime={
            boolean("Show date? (deprecated)", false) ? "2018-03-02" : null
          }
          description={
            <span>
              إيكون هو التحليل المالي سطح المكتب والحل المحمول الذي يربط لك ذات
              الصلة، محتوى موثوق بها، رويترز الأخبار، وأماكن، والأسواق، ومجمعات
              السيولة، والزملاء.
            </span>
          }
          eyebrow="للرسائل المالية"
          href="https://www.example.com"
          footer={
            <SearchResults.Result.TagFooter title="إلى عن على">
              <SearchResults.Result.TagFooter.Tag>
                يربط
              </SearchResults.Result.TagFooter.Tag>
              <SearchResults.Result.TagFooter.Tag>
                المكتب والحل
              </SearchResults.Result.TagFooter.Tag>
              <SearchResults.Result.TagFooter.Tag>
                والزملاء
              </SearchResults.Result.TagFooter.Tag>
            </SearchResults.Result.TagFooter>
          }
          title="إيكون التحليل المالي"
        />
      </SearchResults>
    </Language>
  ));
