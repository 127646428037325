import React, { Component } from "react";
import { bool, func, number, oneOfType, string } from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import debounce from "lodash.debounce";

import getTallestElementHeight from "./utils/getTallestElementHeight";
import getUUID from "./utils/guidGenerator";
import TickerElement from "./components/TickerElement/TickerElement";

const DEBOUNCE_TIME = 150;

class ContinuousTicker extends Component {
  static displayName = "ContinuousTicker";

  static propTypes = {
    /**
     * Need to pass children as a function
     */
    children: func.isRequired,
    /**
     * Direction ticker will go left of right
     */
    direction: string,
    /**
     * chain By default, the elements follow one and another immediately.
     * wait A new element appears as soon as the previous one has disappeared completely.
     * smooth A new element appears as soon as the previous one starts to disappear.
     */
    mode: string,
    /**
     * Default true. Set to false stops the Ticker.
     */
    move: bool,
    /**
     * By default, the first element in the Ticker will align to the Tickers left side.
     * Fixed Offset: A number will move the Ticker's first child to the right by n pixel.
     * Relative Offset: The offset can also be defined in percent of the Ticker’s width.
     * Run-in: The string run-in hides the first element, so the Ticker starts empty.
     */
    offset: oneOfType([number, string]),
    /**
     * Defines the speed of the ticker. Default 5
     */
    speed: number,
    /**
     * Auto-height: By default,
     * the Ticker will adapt the height of its highest visible child.
     * Fixed height: Alternatively you can give it a fixed height:
     * A number will be set as pixels, a string can be everything.
     */
    height: oneOfType([number, string]),
    /**
     * To set the aria label for ticker marquee title
     */
    tickerTitleAriaLabel: string
  };

  static defaultProps = {
    offset: 0,
    speed: 5,
    direction: "toLeft",
    mode: "chain",
    move: true,
    height: 0
  };

  constructor(props) {
    super(props);
    const innerWidth = typeof window === "object" && window.innerWidth;
    const offsetWidth = innerWidth ? innerWidth : 1;

    this.state = this.getDefaultState(props.offset, offsetWidth);
    this.next = null;
    this.tickerRef = React.createRef();
    this.debounceOnResize = debounce(this.onResize, DEBOUNCE_TIME);
  }

  getDefaultState = (offset, width) => ({
    elements: [
      {
        id: getUUID(),
        index: 0,
        height: 0,
        start: false,
        offset,
        rect: null
      }
    ],
    width,
    height: 0,
    isPaused: false,
    isFocused: false
  });

  componentDidMount = () => {
    this.setState({
      width: this.tickerRef.current.offsetWidth,
      height: this.props.height
    });
    window.addEventListener("resize", this.debounceOnResize);
    window.addEventListener("visibilitychange", this.handleDocumentVisible);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.debounceOnResize);
    window.removeEventListener("visibilitychange", this.handleDocumentVisible);
  };

  setRect = ({ index, rect, nextOffset }) => {
    const { height } = this.props;

    this.setState(prevState => {
      const elements = prevState.elements.map(el => {
        if (el.index === index) {
          el.rect = rect;
        }
        const isNextElement = el.index === index + 1;

        if (isNextElement && el.offset) {
          el.offset = nextOffset;
        }

        return el;
      });

      return {
        elements,
        height: height ? prevState.height : getTallestElementHeight(elements)
      };
    });
  };

  onResize = () => {
    const { offset, height } = this.props;

    if (
      !this.tickerRef.current ||
      this.tickerRef.current.offsetWidth === this.state.width
    ) {
      return;
    }

    this.setState({
      ...this.getDefaultState(offset, this.tickerRef.current.offsetWidth),
      height
    });
  };

  onFinish = id => {
    this.setState(prevState => ({
      elements: prevState.elements.filter(el => el.id !== id)
    }));
  };

  onNext = ({ index, rect, nextOffset }) => {
    this.setState(prevState => ({
      elements: [
        ...prevState.elements.map(el => {
          if (el.index === index) {
            el.rect = rect;
          }
          const isStartingElement = el.index === 0;
          const isNextElement = el.index === index + 1;

          if (isStartingElement || el.offset || isNextElement) {
            el.start = true;
          }

          return el;
        }),
        {
          id: getUUID(),
          index: prevState.elements[prevState.elements.length - 1].index + 1,
          height: 0,
          start: false,
          offset: nextOffset,
          rect: null
        }
      ]
    }));
  };

  handleDocumentVisible = () => {
    const isVisibilityHidden = document.visibilityState === "hidden";

    this.toggleTicker({ isPaused: isVisibilityHidden });
  };

  toggleTicker = value => {
    this.setState({
      ...value
    });
  };

  getClassName = getClassNameFactory(ContinuousTicker.displayName);

  render() {
    const { offset, mode, speed, direction, move, children } = this.props;

    const { width, elements, height, isPaused, isFocused } = this.state;
    const tickerHeight = height && `${height}px`;

    return (
      <div
        className={this.getClassName()}
        ref={this.tickerRef}
        style={{
          height: tickerHeight
        }}
        data-rehydratable={getRehydratableName(ContinuousTicker.displayName)}
        data-offset={offset}
        data-mode={mode}
        data-speed={speed}
        data-direction={direction}
        data-move={move}
        role="marquee"
        aria-live="off"
      >
        {width &&
          elements.map(el => (
            <TickerElement
              key={el.id}
              id={el.id}
              index={el.index}
              start={el.start}
              offset={el.offset}
              direction={direction}
              mode={mode}
              move={move && !isPaused && !isFocused}
              speed={speed}
              onFinish={this.onFinish}
              onNext={this.onNext}
              setRect={this.setRect}
              width={width}
              toggleTicker={this.toggleTicker}
            >
              {children}
            </TickerElement>
          ))}
      </div>
    );
  }
}

export default ContinuousTicker;
