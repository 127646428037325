import React, { Component } from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import classNames from "classnames";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

class Tab extends Component {
  static propTypes = {
    /**
     * Event handler for clicking this tab
     */
    onClick: PropTypes.func,

    /**
     * Event handler for pressing a key while focused on this tab
     */
    onKeyDown: PropTypes.func,
    /**
     * Step description on the tab
     */
    description: PropTypes.string.isRequired,

    /**
     * The HTML ID of the associated tab panel
     */
    relatedId: PropTypes.string.isRequired,

    /**
     * Whether or not this tab is the currently in Progress tab
     */
    inprogress: PropTypes.bool,

    /**
     * Whether or not this tab is the completed tab
     */
    completed: PropTypes.bool,

    /**
     * Whether or not this tab is the next tab
     */
    incomplete: PropTypes.bool,

    /**
     * The content location of the related Panel, to AJAX in content
     */
    contentLocation: PropTypes.string,

    /**
     * The page location of the related Panel content for AJAX content fallback
     */
    pageLocation: PropTypes.string,
    /**
     * Property to make completed tabs disable
     */
    isdisabled: PropTypes.bool
  };

  static defaultProps = {
    onClick: null,
    onKeyDown: null
  };

  static displayName = "ProgressTab.Tab";

  /**
   * Get Tab props from DOM node, used by Tabs component rehydration.
   */
  static getTabsProps = domNode => {
    return {
      contentLocation: domNode.getAttribute("data-content-location"),
      pageLocation: domNode.getAttribute("data-page-location"),
      relatedId: domNode.getAttribute("data-related-id"),
      description: domNode.getAttribute("data-description")
    };
  };

  constructor(props) {
    super(props);

    this.anchorRef = React.createRef();
    this.getClassName = getClassNameFactory(Tab.displayName);
  }

  handleClick = e => {
    this.props.onClick(e, this.props.relatedId);
  };

  handleKeyDown = e => {
    this.props.onKeyDown(e);
  };

  componentDidUpdate(oldProps) {
    if (this.anchorRef && !oldProps.inprogress && this.props.inprogress) {
      this.anchorRef.current.focus();
    }
  }

  render() {
    const {
      contentLocation,
      pageLocation,
      relatedId,
      inprogress,
      completed,
      incomplete,
      description,
      isdisabled
    } = this.props;
    const getClassName = getClassNameFactory("ProgressTabTab");

    const anchorProps = {
      className: getClassName({ descendantName: "link" }),
      href: completed && isdisabled ? "#" : pageLocation || `#${relatedId}`,
      onClick: completed && isdisabled ? null : this.handleClick,
      onKeyDown: completed && isdisabled ? null : this.handleKeyDown,
      id: `tab-${relatedId}`,
      ...(inprogress ? {} : { tabIndex: "-1" })
    };

    const disabled = completed && isdisabled ? isdisabled : null;

    const itemProps = {
      className: getClassName({
        modifiers: classNames(),
        className: typestack("subline2"),
        states: classNames({
          inprogress,
          completed,
          incomplete,
          disabled
        })
      }),
      ...(inprogress
        ? { "aria-current": true, "aria-label": "active" }
        : { "aria-current": false }),
      ...(inprogress ? {} : { tabIndex: "-1" })
    };

    return (
      <li
        {...itemProps}
        data-content-location={contentLocation}
        data-page-location={pageLocation}
        data-related-id={relatedId}
        data-description={description}
      >
        <a {...anchorProps} ref={this.anchorRef} target="_self">
          <div
            className={this.getClassName({
              descendantName: "description",
              className: typestack("p1Bold")
            })}
          >
            {description}
          </div>
          <div
            className={this.getClassName({
              descendantName: "pending",
              className: typestack("p1")
            })}
          >
            Pending
          </div>
        </a>
      </li>
    );
  }
}

export default Tab;
