import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import Ticker from "./index";
import "./Ticker.css";
import TickerREADME from "../README.md";

const stories = storiesOf("Components/Ticker", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(TickerREADME));

/* eslint-disable no-magic-numbers */
const oneMinute = 60000;
const time1 = new Date().getTime() - 65 * oneMinute;
const time2 = new Date().getTime() - 10 * oneMinute;
const time3 = new Date().getTime() - 4 * oneMinute;
const time4 = new Date().getTime() - 180 * oneMinute;
const time5 = new Date().getTime() - 120 * oneMinute;
/* eslint-enable no-magic-numbers */

stories
  .add("Default", () => (
    <Ticker>
      <Ticker.Item lead title={text("Lead text", "Latest News")} />
      <Ticker.Item
        title={text("Headline", "Lorem ipsum dolor sit amet")}
        href="#"
        dateTime={new Date(time1).toISOString()}
      />
      <Ticker.Item
        title={text("Headline", "Lorem ipsum dolor sit amet")}
        href="#"
        dateTime={new Date().toISOString()}
      />
      <Ticker.Item
        title={text("Headline", "Lorem ipsum dolor sit amet")}
        href="#"
        dateTime={new Date(time2).toISOString()}
      />
      <Ticker.Item
        title={text("Headline", "Lorem ipsum dolor sit amet")}
        href="#"
        dateTime={new Date(time3).toISOString()}
      />
      <Ticker.Item
        title={text("Headline", "Lorem ipsum dolor sit amet")}
        href="#"
        dateTime={new Date(time4).toISOString()}
      />
      <Ticker.Item
        title={text("Headline", "Lorem ipsum dolor sit amet")}
        href="#"
        dateTime={new Date(time5).toISOString()}
      />
    </Ticker>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Ticker>
        <Ticker.Item lead title={text("Lead text", "الإعلان ")} />
        <Ticker.Item
          title={text("Headline", "الإعلان العالمي لحقوق الإنسان")}
          href="#"
          dateTime={new Date(time1).toISOString()}
          lang="arSA"
        />
        <Ticker.Item
          title={text("Headline", "الإعلان العالمي لحقوق الإنسان")}
          href="#"
          dateTime={new Date().toISOString()}
          lang="arSA"
        />
        <Ticker.Item
          title={text("Headline", "الإعلان العالمي لحقوق الإنسان")}
          href="#"
          dateTime={new Date(time2).toISOString()}
          lang="arSA"
        />
        <Ticker.Item
          title={text("Headline", "الإعلان العالمي لحقوق الإنسان")}
          href="#"
          dateTime={new Date(time3).toISOString()}
          lang="arSA"
        />
        <Ticker.Item
          title={text("Headline", "الإعلان العالمي لحقوق الإنسان")}
          href="#"
          dateTime={new Date(time4).toISOString()}
          lang="arSA"
        />
        <Ticker.Item
          title={text("Headline", "الإعلان العالمي لحقوق الإنسان")}
          href="#"
          dateTime={new Date(time5).toISOString()}
          lang="arSA"
        />
      </Ticker>
    </Language>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <Ticker>
        <Ticker.Item lead title="Latest News" />
        <Ticker.Item
          title="Lorem ipsum dolor sit amet"
          href="#"
          dateTime={new Date(time1).toISOString()}
        />
        <Ticker.Item
          title="Lorem ipsum dolor sit amet"
          href="#"
          dateTime={new Date().toISOString()}
        />
        <Ticker.Item
          title="Lorem ipsum dolor sit amet"
          href="#"
          dateTime={new Date(time2).toISOString()}
        />
        <Ticker.Item
          title="Lorem ipsum dolor sit amet"
          href="#"
          dateTime={new Date(time3).toISOString()}
        />
        <Ticker.Item
          title="Lorem ipsum dolor sit amet"
          href="#"
          dateTime={new Date(time4).toISOString()}
        />
        <Ticker.Item
          title="Lorem ipsum dolor sit amet"
          href="#"
          dateTime={new Date(time5).toISOString()}
        />
      </Ticker>
    </StaticRenderer>
  ));
