import { SEARCH_QUERY_STRING_KEY } from "./constants";

const setEmpty = callback => {
  const { location, history } = window;

  if (typeof window !== "undefined" && history) {
    history.pushState("", document.title, location.pathname + location.search);
  }
  if (callback) {
    callback();
  }
};

export const setHash = ({
  queryLink,
  currentPage,
  lastAppliedFilterKey,
  callback
}) => {
  const parameters = new URLSearchParams(queryLink);

  parameters.delete(SEARCH_QUERY_STRING_KEY);

  const parameterMap = {
    selected: lastAppliedFilterKey,
    page: currentPage
  };

  Object.entries(parameterMap).forEach(([key, value]) => {
    if (value) {
      parameters.set(key, value);
    }
  });

  if (parameters.toString()) {
    window.location.hash = parameters.toString();
  } else {
    setEmpty(callback);
  }
};

export const getHash = () => {
  if (typeof window !== "undefined") {
    const hashValue = window.location.hash.replace("#", "");

    return hashValue;
  }
};
