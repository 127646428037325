import PropTypes from "prop-types";
import React, { Component, createRef } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Button from "@emcm-ui/component-button";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import Eyebrow from "@emcm-ui/component-eyebrow";
import Anchor from "@emcm-ui/component-anchor";
import Expandable from "@emcm-ui/component-expandable";

class TagCloud extends Component {
  static displayName = "TagCloud";

  static propTypes = {
    /**
     * List of tags to display.
     */
    tagList: PropTypes.array,
    /**
     * List of selected tags
     */
    selectedTags: PropTypes.array,
    /**
     * Label for expand more button
     */
    showMore: PropTypes.string,
    /**
     * Label for expended button
     */
    showLess: PropTypes.string,
    /**
     * Label for heading
     */
    heading: PropTypes.string,
    /**
     * Label for clear all
     */
    clearAllText: PropTypes.string,
    /**
     * function returns selected tags
     */
    onClick: PropTypes.func,
    /**
     * function clear the selected tags
     */
    clearAll: PropTypes.func
  };

  static defaultProps = {
    tagList: [],
    selectedTags: [],
    showMore: "Show More",
    showLess: "Show Less",
    clearAllText: "Clear all"
  };

  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false,
      showExpanded: false
    };
    this.getClassName = getClassNameFactory(TagCloud.displayName);
    this.tagContainerRef = createRef();
  }

  componentDidMount() {
    this.updateHeight();
    window.addEventListener("resize", this.updateHeight);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateHeight);
  }

  updateHeight = () => {
    const container = this.tagContainerRef.current;
    const { isExpanded } = this.state;

    if (container && !isExpanded) {
      this.setState(
        {
          showExpanded: container.scrollHeight > container.offsetHeight
        },
        () => this.setPillVisibility()
      );
    }
  };

  setPillVisibility = setFocusElement => {
    const container = this.tagContainerRef.current;
    const pillElements = container.querySelectorAll("button, a");
    const parentSize = container.offsetHeight + container.offsetTop;
    let firstHiddenElement;

    for (const pillElement of pillElements) {
      const isHidden = parentSize < pillElement.offsetTop;
      const hasAriaHidden = pillElement.getAttribute("aria-hidden") === "true";

      pillElement.setAttribute("aria-hidden", isHidden);
      pillElement.setAttribute("tabIndex", isHidden ? "-1" : "");

      if (hasAriaHidden && !firstHiddenElement) {
        firstHiddenElement = pillElement;
      }
    }
    if (setFocusElement && firstHiddenElement) {
      firstHiddenElement.focus();
    }
  };

  toggleExpandable = () => {
    this.setState(
      {
        isExpanded: !this.state.isExpanded
      },
      () => this.setPillVisibility(true)
    );
  };

  onClickHandler = key => {
    if (typeof this.props.onClick === "function") {
      this.props.onClick(key);
    }
  };

  getTags = items => {
    const result = items.map(({ key, href, label, newWindow }, index) => {
      return (
        <li key={index}>
          <Button
            active={this.props.selectedTags.includes(key)}
            block={false}
            color="secondary"
            disabled={false}
            href={href}
            icon={false}
            kind={href ? "link" : "button"}
            newWindow={href ? newWindow : false}
            type="pill"
            onClick={href ? null : () => this.onClickHandler(key)}
          >
            {label}
          </Button>
        </li>
      );
    });

    return (
      <ul
        className={this.getClassName({ descendantName: "pill-btn-container" })}
        ref={this.tagContainerRef}
        style={{ maxHeight: this.state.isExpanded ? "none" : `` }}
      >
        {result}
      </ul>
    );
  };

  render() {
    const {
      tagList,
      heading,
      showMore,
      showLess,
      selectedTags,
      clearAllText,
      clearAll
    } = this.props;
    const { showExpanded, isExpanded } = this.state;

    return (
      <div
        className={this.getClassName()}
        data-rehydratable={getRehydratableName(TagCloud.displayName)}
        data-tag-list={JSON.stringify(tagList)}
        data-show-more={showMore}
        data-show-less={showLess}
        data-heading={heading}
      >
        {heading && (
          <div
            className={this.getClassName({
              descendantName: "heading-text"
            })}
          >
            <Eyebrow text={heading} />
          </div>
        )}
        {selectedTags &&
          selectedTags.length > 0 && (
            <div
              className={this.getClassName({
                descendantName: "clearAll-container"
              })}
            >
              <Anchor
                kind="button"
                className={this.getClassName({
                  descendantName: "clearAll-btn"
                })}
                onClick={clearAll}
              >
                {clearAllText}
              </Anchor>
            </div>
          )}
        {tagList.length > 0 && this.getTags(tagList)}
        {showExpanded && (
          <Expandable.Header
            onClick={this.toggleExpandable.bind(this)}
            isExpanded={isExpanded}
            type="showMore"
            showMoreType="p1"
          >
            {isExpanded ? showLess : showMore}
          </Expandable.Header>
        )}
      </div>
    );
  }
}

export default TagCloud;
