import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import NoResult from "./index";
import "./NoResult.css";
import SearchResultsMixtureREADME from ".../../../README.md";

const stories = storiesOf(
  "Components/SearchMixedCard/Components/NoResult",
  module
);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(SearchResultsMixtureREADME));

stories
  .add("Default", () => (
    <NoResult
      search={{
        searchKey: text("Search key", "key")
      }}
      noMatchesText={text(
        "No match text",
        "We did not find any matches for {term}"
      )}
    />
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <NoResult
        search={{
          searchKey: text("Search key", "key")
        }}
        noMatchesText={text(
          "No match text",
          "لم نعثر على أية تطابقات لـ {term}"
        )}
      />
    </Language>
  ));
