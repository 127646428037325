import React from "react";
import Grid from "@emcm-ui/component-grid";
import Heading from "@emcm-ui/component-heading";
import Link from "@emcm-ui/component-link";
import MarkupContainer from "@emcm-ui/component-markup-container";
import Pagination from "@emcm-ui/component-pagination";
import Poster from "@emcm-ui/component-poster";

import SearchFilters from "@emcm-ui/component-search-filters";
import SearchForm from "@emcm-ui/component-search-form";
import SearchResults from "@emcm-ui/component-search-results";
import Section from "@emcm-ui/component-section";
import SemanticMain from "@emcm-ui/component-semantic-main";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";

import articleLadyJustice from "../../sampleAssets/Article-lady-justice.jpg";
import articleLadyJusticeMobile from "../../sampleAssets/Article-lady-justice.mob.jpg";
import imgLarge2 from "../../sampleAssets/large_2.1x.jpg";
import SampleSchemaOrganization from "../../fragments/schema-organization";
import SampleFooter from "../../fragments/footer";
import SampleHeader from "../../fragments/header";
import Icon from "@emcm-ui/component-icon";

const Icons = {
  user: "./icons/ui/grey/user-07.svg",
  time: "./icons/ui/grey/time-07.svg"
};

const Search = () => {
  return (
    <div>
      <SampleSchemaOrganization />
      <SampleHeader />

      <SemanticMain>
        <Section background="grayDark" fullWidth>
          <Poster
            height="s"
            imageOverlay="scrimBottomDark"
            imageSrc={articleLadyJustice}
            imageSrcSet={`${articleLadyJusticeMobile} 768w, ${articleLadyJustice} 2048w`}
          >
            <Grid variant="7/12,5/12" gutterlessAtNarrow>
              <Grid.Item>
                <VerticalSpacing>
                  <Heading type="xl" rank="1">
                    <strong>Products & services</strong>
                  </Heading>
                </VerticalSpacing>
                <p>
                  We offer comprehensive expert research, fully developed
                  software suites, standalone products, web-based platforms and
                  continuing education.
                </p>
              </Grid.Item>
            </Grid>
          </Poster>
        </Section>
        <Section background="grayLightest">
          <VerticalSpacing size="xl">
            <MarkupContainer>
              <SearchForm
                action="https://www.example.com"
                method="get"
                searchInput={{
                  typeaheadUrl:
                    "//content.atomz.com/autocomplete/sp10/05/0d/51?_=1506335725065"
                }}
              />
            </MarkupContainer>
          </VerticalSpacing>
          <VerticalSpacing size="xl">
            <Grid variant="1/4,3/4">
              <Grid.Item>
                <MarkupContainer>
                  <SearchFilters>
                    <SearchFilters.Section>
                      <SearchFilters.Selected clearAllHref="#">
                        <SearchFilters.SelectedItem href="#">
                          Financial
                        </SearchFilters.SelectedItem>
                        <SearchFilters.SelectedGroup title="Government solutions">
                          <SearchFilters.SelectedItem href="#">
                            Illicit Finance
                          </SearchFilters.SelectedItem>
                          <SearchFilters.SelectedItem href="#">
                            Market Data and Analysis
                          </SearchFilters.SelectedItem>
                        </SearchFilters.SelectedGroup>
                        <SearchFilters.SelectedItem href="#">
                          Automotive
                        </SearchFilters.SelectedItem>
                      </SearchFilters.Selected>
                    </SearchFilters.Section>
                    <SearchFilters.Section>
                      <SearchFilters.Filters>
                        <SearchFilters.FilterSection title="Sector">
                          <SearchFilters.FilterItem href="#" disabled>
                            Financial (4)
                          </SearchFilters.FilterItem>
                          <SearchFilters.FilterItem href="#">
                            Risk management solutions (12)
                          </SearchFilters.FilterItem>
                          <SearchFilters.FilterItem href="#">
                            Tax & Accounting (18)
                          </SearchFilters.FilterItem>
                          <SearchFilters.FilterItem href="#">
                            Thomson Reuters Corporate (16)
                          </SearchFilters.FilterItem>
                          <SearchFilters.FilterItem href="#">
                            Legal (13)
                          </SearchFilters.FilterItem>
                          <SearchFilters.FilterItem href="#">
                            Reuters News Agency (16)
                          </SearchFilters.FilterItem>
                          <SearchFilters.FilterGroup
                            title="Government Solutions (6)"
                            href="#"
                          >
                            <SearchFilters.FilterItem
                              borderVariant="borderless"
                              href="#"
                            >
                              Healthcare Fraud Detection and Prevention (1)
                            </SearchFilters.FilterItem>
                            <SearchFilters.FilterItem
                              borderVariant="borderless"
                              href="#"
                              disabled
                            >
                              Illicit Finance (1)
                            </SearchFilters.FilterItem>
                            <SearchFilters.FilterItem
                              borderVariant="borderless"
                              href="#"
                              disabled
                            >
                              Market Data and Analytics (1)
                            </SearchFilters.FilterItem>
                          </SearchFilters.FilterGroup>
                          <SearchFilters.FilterSectionOverflow>
                            <SearchFilters.FilterItem href="#" disabled>
                              Automotive (5)
                            </SearchFilters.FilterItem>
                            <SearchFilters.FilterItem href="#">
                              Energy (19)
                            </SearchFilters.FilterItem>
                          </SearchFilters.FilterSectionOverflow>
                        </SearchFilters.FilterSection>

                        <SearchFilters.FilterSection title="Page type">
                          <SearchFilters.FilterItem href="#">
                            Blog Post (11)
                          </SearchFilters.FilterItem>
                          <SearchFilters.FilterItem href="#">
                            Press Release (7)
                          </SearchFilters.FilterItem>
                          <SearchFilters.FilterItem href="#">
                            Products & Services (5)
                          </SearchFilters.FilterItem>
                          <SearchFilters.FilterItem href="#">
                            Article (8)
                          </SearchFilters.FilterItem>
                          <SearchFilters.FilterItem href="#">
                            Fact Sheet (8)
                          </SearchFilters.FilterItem>
                          <SearchFilters.FilterItem href="#">
                            Brochure (21)
                          </SearchFilters.FilterItem>
                          <SearchFilters.FilterItem href="#">
                            Careers (11)
                          </SearchFilters.FilterItem>
                          <SearchFilters.FilterItem href="#">
                            White Paper (17)
                          </SearchFilters.FilterItem>
                          <SearchFilters.FilterItem href="#">
                            Report (20)
                          </SearchFilters.FilterItem>
                          <SearchFilters.FilterItem href="#">
                            Careers (11)
                          </SearchFilters.FilterItem>
                          <SearchFilters.FilterSectionOverflow>
                            <SearchFilters.FilterItem href="#">
                              Report (20)
                            </SearchFilters.FilterItem>
                            <SearchFilters.FilterItem href="#">
                              Careers (11)
                            </SearchFilters.FilterItem>
                            <SearchFilters.FilterItem href="#">
                              White Paper (17)
                            </SearchFilters.FilterItem>
                          </SearchFilters.FilterSectionOverflow>
                        </SearchFilters.FilterSection>
                      </SearchFilters.Filters>
                    </SearchFilters.Section>
                  </SearchFilters>
                </MarkupContainer>
              </Grid.Item>
              <Grid.Item>
                <SearchResults title="Viewing 1-12 of 272 for 'financial'">
                  <SearchResults.Result
                    description={
                      <span>
                        Eikon Messenger gives you access to the world’s largest
                        verified directory of <strong>financial</strong> market
                        participants for
                        <strong>financial</strong> messaging via the open
                        messaging network.
                      </span>
                    }
                    href="https://www.example.com"
                    links={[
                      <Link
                        href="https://www.example.com"
                        type="icon"
                        icon={<Icon path={Icons.user} size="xs" />}
                        iconPosition={"left"}
                      >
                        Login
                      </Link>,
                      <a href="https://www.example.com">Support</a>,
                      <a href="https://www.example.com">Training</a>
                    ]}
                    title="Eikon Messenger for Financial Messaging"
                  />
                  <SearchResults.Result
                    description={
                      <span>
                        Eikon is the <strong>financial</strong> analysis desktop
                        and mobile solution that connects you to relevant,
                        trusted content, Reuters news, venues, markets,
                        liquidity pools, and colleagues.
                      </span>
                    }
                    href="https://www.example.com"
                    imgSrc={imgLarge2}
                    links={[
                      <Link
                        href="https://www.example.com"
                        type="icon"
                        icon={<Icon path={Icons.user} size="xs" />}
                        iconPosition={"left"}
                      >
                        Login
                      </Link>,
                      <a href="https://www.example.com">Support</a>,
                      <a href="https://www.example.com">Training</a>
                    ]}
                    title="Eikon Financial Analysis"
                  />
                  <SearchResults.Result
                    description={
                      <span>
                        Thomson Reuters is adding foreign exchange (FX) pricing
                        granularity for customers trading Australian Dollars
                        (AUD) vs. U.S. Dollars (USD), creating greater accuracy
                        in price quotes in AUD/USD.
                      </span>
                    }
                    eyebrow="Blog post"
                    href="https://www.example.com"
                    footer={
                      <SearchResults.Result.ArticleInfoFooter
                        dateTime="2017-11-14"
                        duration="PT5M"
                        durationText="5 min"
                        durationIcon={<Icon path={Icons.time} size="xs" />}
                        name="Carla Y."
                      />
                    }
                    title="Thomson Reuters to enhance FX trading accuracy by adding further pricing precision for AUD/USD | Thomson Reuters"
                  />
                  <SearchResults.Result
                    description={
                      <span>
                        The WG&L Tax Dictionary is a complete desktop reference
                        guide to all tax and tax-related{" "}
                        <strong>financial</strong> terminology. Definitions
                        contain important references to: Internal Revenue Code
                        sections, Treasury regulations IRS rulings, Court
                        decisions, Legislative history, Law review articles
                      </span>
                    }
                    eyebrow="Reference guide"
                    href="https://www.example.com"
                    footer={
                      <SearchResults.Result.TagFooter>
                        <SearchResults.Result.TagFooter.Tag>
                          Corporations
                        </SearchResults.Result.TagFooter.Tag>
                        <SearchResults.Result.TagFooter.Tag>
                          Accounting forms
                        </SearchResults.Result.TagFooter.Tag>
                        <SearchResults.Result.TagFooter.Tag>
                          Governments
                        </SearchResults.Result.TagFooter.Tag>
                      </SearchResults.Result.TagFooter>
                    }
                    title="Tax Dictionary"
                  />
                </SearchResults>
              </Grid.Item>
            </Grid>
          </VerticalSpacing>
          <Pagination>
            <Pagination.NavItem
              direction="left"
              disabled
              href="https://www.example.com"
            />
            <Pagination.PageItem href="https://www.example.com">
              1
            </Pagination.PageItem>
            <Pagination.PageItem href="https://www.example.com">
              2
            </Pagination.PageItem>
            <Pagination.PageItem active href="https://www.example.com">
              3
            </Pagination.PageItem>
            <Pagination.PageItem href="https://www.example.com">
              4
            </Pagination.PageItem>
            <Pagination.PageItem href="https://www.example.com">
              5
            </Pagination.PageItem>
            <Pagination.PageItem>...</Pagination.PageItem>
            <Pagination.PageItem href="https://www.example.com">
              62
            </Pagination.PageItem>
            <Pagination.NavItem
              direction="right"
              href="https://www.example.com"
            />
          </Pagination>
        </Section>
      </SemanticMain>

      <SampleFooter />
    </div>
  );
};

export default Search;
