import React from "react";
import DateSelect from "./DateSelect";

// eslint-disable-next-line require-await
export default async domNode => {
  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data ? JSON.parse(data) : null;
  };

  // NB you can't and shouldn't ever need to rehydrate functions
  const props = {
    isRehydrating: true,
    variant: readData("variant"),
    yearRange: readData("year-range"),
    yearOrder: domNode.getAttribute(`data-year-order`),
    dateFormatter: domNode.getAttribute(`data-format`),
    numberOfYears: readData("number-of-years"),
    dayErrorMessage: domNode.getAttribute("data-day-error-message"),
    monthErrorMessage: domNode.getAttribute("data-month-error-message"),
    yearErrorMessage: domNode.getAttribute("data-year-error-message")
  };

  return <DateSelect {...props} />;
};
