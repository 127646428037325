import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

import BaseItem from "./components/BaseItem";
import Column from "./components/Column";
import Item from "./components/Item";
import Login from "./components/Login";
import NavList from "./components/NavList";
import NavItem from "./components/NavItem";
import { AuthState } from "@emcm-ui/utility-authentication";

class GlobalHeader extends Component {
  constructor(props) {
    super(props);

    if (props.loginManager) {
      props.loginManager.onChange(this.onLoginManagerChange);
    }
  }

  componentWillUnmount() {
    if (this.props.loginManager) {
      this.props.loginManager.offChange(this.onLoginManagerChange);
    }
  }

  onLoginManagerChange = () => {
    this.forceUpdate();
  };

  render() {
    const {
      leftItems,
      rightItems,
      loginManager,
      brandColorBar,
      gradientBackground
    } = this.props;

    const loggedIn =
      loginManager && loginManager.authState === AuthState.LOGGED_IN;

    const getClassName = getClassNameFactory(GlobalHeader.displayName);

    return (
      <div
        className={getClassName({
          modifiers: classNames({
            loggedIn,
            brandColorBar,
            gradientBackground
          }),
          className: typestack("p1")
        })}
        data-rehydratable={getRehydratableName(GlobalHeader.displayName)}
        data-gradient-background={gradientBackground}
        data-brand-color-bar={brandColorBar}
      >
        <div className={getClassName({ descendantName: "inner" })}>
          {leftItems && (
            <ul
              className={getClassName({
                descendantName: "leftItems"
              })}
            >
              {leftItems}
            </ul>
          )}
          {rightItems && (
            <ul
              className={getClassName({
                descendantName: "rightItems"
              })}
            >
              {rightItems}
            </ul>
          )}
        </div>
      </div>
    );
  }
}

GlobalHeader.displayName = "GlobalHeader";

/* eslint-disable max-len */
GlobalHeader.propTypes = {
  /**
   * Child nodes to appear on left hand side. Should be GlobalHeader.Item wrapped in a `<React.Fragment />`.
   */
  leftItems: PropTypes.node,
  /**
   * Child nodes to appear on right hand side. Should be GlobalHeader.Item wrapped in a `<React.Fragment />`.
   */
  rightItems: PropTypes.node,
  /**
   * The LoginManager.
   */
  loginManager: PropTypes.object,
  /**
   * Should we use the graident background style
   */
  gradientBackground: PropTypes.bool,
  /**
   * Should we display the brand colour bar
   */
  brandColorBar: PropTypes.bool
};

GlobalHeader.BaseItem = BaseItem;
GlobalHeader.Column = Column;
GlobalHeader.Item = Item;
GlobalHeader.Login = Login;
GlobalHeader.NavList = NavList;
GlobalHeader.NavItem = NavItem;

export default GlobalHeader;
