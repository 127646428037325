const formConsts = {
  failMessage: "An error occurred during submission.",
  successMessage: "Submission completed successfully.",
  submitButtonText: "Submit",
  submitButtonTextLoading: "Loading",
  submitButtonTextSuccess: "Success",
  message: "Error Message: This field is required",
  autocomplete: "off",
  labelText: "Label",
  optionalText: "(optional)",
  maxlength: "40",
  tabindex: "0",
  textControlName: "input1",
  toggleswitchControlName: "toggleswitch",
  textControlType: "text",
  textareaControlName: "textarea1",
  selectControlName: "select1",
  selectControlPlaceholderText: "Select…",
  checkboxControlName: "checkbox",
  checkboxLabelText: "checkbox label",
  radioControlName: "radio",
  consentName: "consent",
  explicitConsentId: "explicitConsentCheckbox",
  customCompliantsLabel: "Choose at least any one",
  explicitConsentText: "I'd like to receive your newsletter",
  countryDropdown:
    "Could not find the country dropdown specified in dependsOn prop",
  optionalLabel: "Optional",
  lang: "en"
};

export { formConsts };
