import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Tooltip from "@emcm-ui/component-tooltip";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const Item = ({
  eyebrow,
  value,
  description,
  iconType,
  state,
  tooltipText,
  tooltipIcon,
  children
}) => {
  const getClassName = getClassNameFactory("DataCallout");

  const renderState = itemstate => {
    switch (itemstate) {
      case "Increase":
        return "itemIncrease";
      case "Decrease":
        return "itemDecrease";
      case "No change":
        return "itemStale";
    }
  };

  const stateComponent = iconType ? (
    <div
      className={getClassName({
        descendantName: renderState(state),
        className: typestack("p3Bold")
      })}
    >
      <span className={getClassName({ descendantName: "icon" })}>
        {iconType}
      </span>
      <span className={getClassName({ descendantName: "inner" })}>
        {description}
      </span>
    </div>
  ) : (
    <div
      className={getClassName({
        descendantName: renderState(state),
        className: typestack("p3Bold")
      })}
    >
      <span className={getClassName({ descendantName: "inner" })}>
        {description}
      </span>
    </div>
  );

  return (
    <div role="listitem" className={getClassName({ descendantName: "item" })}>
      <div className={getClassName({ descendantName: "section" })}>
        {eyebrow && (
          <div
            className={getClassName({
              descendantName: "eyebrow",
              className: typestack("subline1")
            })}
          >
            {eyebrow}
          </div>
        )}

        <div
          className={getClassName({
            descendantName: "itemValue",
            className: typestack("h3")
          })}
        >
          {value}
        </div>
        {state ? (
          stateComponent
        ) : (
          <div
            className={getClassName({
              descendantName: "itemDescription",
              className: typestack("p1")
            })}
          >
            {description}
          </div>
        )}
        {children ? (
          <div
            className={getClassName({
              descendantName: "itemBody",
              className: typestack("p2")
            })}
          >
            {children}
          </div>
        ) : null}
      </div>
      {tooltipText && (
        <Tooltip tooltipText={tooltipText} position="top">
          {tooltipIcon}
        </Tooltip>
      )}
    </div>
  );
};

Item.displayName = "DataCallout.Item";

Item.propTypes = {
  /**
   * Value description
   */
  description: PropTypes.string.isRequired,
  /**
   * eyebrow of the Value
   */
  eyebrow: PropTypes.string,
  /**
   * type of the icon
   */
  iconType: PropTypes.node,
  /**
   * the state of icon
   */
  state: PropTypes.oneOf(["Increase", "Decrease", "No change"]),
  /**
   * width of the icon
   */
  iconWidth: PropTypes.number,
  /**
   * text on hover of tooltip
   */
  tooltipText: PropTypes.string,
  /**
   * Value
   */
  value: PropTypes.string.isRequired,
  /**
   * Child node, consisting of DateTime component.
   */
  children: PropTypes.node,
  /**
   * Tooltip icon
   */
  tooltipIcon: PropTypes.node
};

export default Item;
