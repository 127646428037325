import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, boolean, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import Typestack from "@emcm-ui/component-typestack";
import Icon from "@emcm-ui/component-icon";
import Link from "@emcm-ui/component-link";
import Section from "@emcm-ui/component-section";
import { Language } from "@emcm-ui/component-theme";
import Header from "./index";
import "./Header.css";
import HeaderREADME from "../README.md";

const stories = storiesOf("Components/Header", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(HeaderREADME));

stories
  .add("Default", () => (
    <div>
      <Section>
        <Header
          align={select("alignment", Header.alignments, "left")}
          eyebrow={boolean("eyebrow") ? "Financial" : null}
          heading={
            <Typestack
              align={select("alignment", Header.alignments, "left")}
              rank="h2"
              type="h2"
            >
              Providing trusted answers across &nbsp;{" "}
              <strong>every industry</strong>
            </Typestack>
          }
          callToAction={
            boolean("callToAction") ? (
              <Link
                href={"https://example.com"}
                icon={<Icon path="./icons/ui/blue/arrow-right.svg" size="s" />}
                iconPosition={"right"}
                type={"icon"}
              >
                Primary link
              </Link>
            ) : null
          }
          standfirst={
            boolean("standfirst")
              ? "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ante elit, mollis vestibulum efficitur sed, elementum eu arcu. Curabitur dapibus, nisl ac dictum lacinia, enim elit auctor mauris."
              : null
          }
        />
      </Section>
      <Section>
        <Header
          align={select("alignment", Header.alignments, "left")}
          heading={
            <Typestack
              align={select("alignment", Header.alignments, "left")}
              rank="h3"
              type="h3"
            >
              Providing trusted answers across &nbsp;{" "}
              <strong> every industry</strong>
            </Typestack>
          }
          callToAction={
            boolean("callToAction") ? (
              <Link
                href={"https://example.com"}
                icon={<Icon path="./icons/ui/blue/arrow-right.svg" size="s" />}
                iconPosition={"right"}
                type={"icon"}
              >
                Primary link
              </Link>
            ) : null
          }
          standfirst={
            boolean("standfirst")
              ? "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ante elit, mollis vestibulum efficitur sed, elementum eu arcu. Curabitur dapibus, nisl ac dictum lacinia, enim elit auctor mauris."
              : null
          }
        />
      </Section>
      <Section>
        <Header
          align={select("alignment", Header.alignments, "left")}
          heading={
            <Typestack
              align={select("alignment", Header.alignments, "left")}
              rank="h5"
              type="subline1"
            >
              Providing trusted answers across &nbsp;{" "}
              <strong> every industry</strong>
            </Typestack>
          }
          callToAction={
            boolean("callToAction") ? (
              <Link
                href={"https://example.com"}
                icon={<Icon path="./icons/ui/blue/arrow-right.svg" size="s" />}
                iconPosition={"right"}
                type={"icon"}
              >
                Primary link
              </Link>
            ) : null
          }
          standfirst={
            boolean("standfirst")
              ? "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ante elit, mollis vestibulum efficitur sed, elementum eu arcu. Curabitur dapibus, nisl ac dictum lacinia, enim elit auctor mauris."
              : null
          }
        />
      </Section>
      <Section background="grayLighter">
        <Header
          align={select("alignment", Header.alignments, "left")}
          eyebrow={boolean("eyebrow") ? "Financial" : null}
          timestamp={boolean("Timer") ? "02:03" : null}
          heading={
            <Typestack
              align={select("alignment", Header.alignments, "left")}
              rank="h3"
              type="h3"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
              {boolean("2 Line Header")
                ? "sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
                : null}
            </Typestack>
          }
          callToAction={
            boolean("callToAction") ? (
              <Link
                href={"https://example.com"}
                icon={<Icon path="./icons/ui/blue/arrow-right.svg" size="s" />}
                iconPosition={"right"}
                type={"icon"}
              >
                Primary link
              </Link>
            ) : null
          }
          standfirst={
            boolean("standfirst")
              ? "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ante elit, mollis vestibulum efficitur sed, elementum eu arcu. Curabitur dapibus, nisl ac dictum lacinia, enim elit auctor mauris."
              : null
          }
        />
      </Section>
    </div>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Section>
        <Header
          align={select("alignment", Header.alignments, "left")}
          eyebrow={boolean("eyebrow") ? "الأمور المالية" : null}
          heading={
            <Typestack
              align={select("alignment", Header.alignments, "left")}
              rank="h2"
              type="h2"
            >
              تقديم إجابات موثوقة عبر &nbsp; <strong>كل صناعة</strong>
            </Typestack>
          }
          callToAction={
            boolean("callToAction") ? (
              <Link
                href={"https://example.com"}
                icon={<Icon path="./icons/ui/blue/arrow-right.svg" size="s" />}
                iconPosition={"right"}
                type={"icon"}
              >
                الرابط الأساسي
              </Link>
            ) : null
          }
          standfirst={
            boolean("standfirst")
              ? "خدمة الجزر أبجد هوز. في المطار، لكنها تصبح لينة عنصر تصنيع الكحول كرة القدم. دردشة البروتين، والروايات، والأمهات، والمؤلف المطور"
              : null
          }
        />
      </Section>
      <Section>
        <Header
          align={select("alignment", Header.alignments, "left")}
          heading={
            <Typestack
              align={select("alignment", Header.alignments, "left")}
              rank="h3"
              type="h3"
            >
              تقديم إجابات موثوقة عبر &nbsp; <strong>كل صناعة</strong>
            </Typestack>
          }
          callToAction={
            boolean("callToAction") ? (
              <Link
                href={"https://example.com"}
                icon={<Icon path="./icons/ui/blue/arrow-right.svg" size="s" />}
                iconPosition={"right"}
                type={"icon"}
              >
                الرابط الأساسي
              </Link>
            ) : null
          }
          standfirst={
            boolean("standfirst")
              ? "خدمة الجزر أبجد هوز. في المطار، لكنها تصبح لينة عنصر تصنيع الكحول كرة القدم. دردشة البروتين، والروايات، والأمهات، والمؤلف المطور"
              : null
          }
        />
      </Section>
      <Section>
        <Header
          align={select("alignment", Header.alignments, "left")}
          heading={
            <Typestack
              align={select("alignment", Header.alignments, "left")}
              rank="h5"
              type="subline1"
            >
              تقديم إجابات موثوقة عبر &nbsp; <strong>كل صناعة</strong>
            </Typestack>
          }
          callToAction={
            boolean("callToAction") ? (
              <Link
                href={"https://example.com"}
                icon={<Icon path="./icons/ui/blue/arrow-right.svg" size="s" />}
                iconPosition={"right"}
                type={"icon"}
              >
                الرابط الأساسي
              </Link>
            ) : null
          }
          standfirst={
            boolean("standfirst")
              ? "خدمة الجزر أبجد هوز. في المطار، لكنها تصبح لينة عنصر تصنيع الكحول كرة القدم. دردشة البروتين، والروايات، والأمهات، والمؤلف المطور"
              : null
          }
        />
      </Section>
    </Language>
  ));
