import PropTypes from "prop-types";
import React, { Component, createElement } from "react";
import classNames from "classnames";
import Eyebrow from "@emcm-ui/component-eyebrow";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const iconTypes = ["arrowRight", "document", "download", "external"];
const sizes = ["small", "large"];

class ListOfLinksBlockLink extends Component {
  static displayName = "ListOfLinks.BlockLink";

  /* eslint-disable max-len */
  static propTypes = {
    /**
     * Title text.
     */
    children: PropTypes.string.isRequired,

    /**
     * Description text.
     */
    description: PropTypes.string,

    /**
     * Metadata node.
     */
    metadata: PropTypes.node,

    /**
     * Eyebrow text for the title. Not visible on small BlockLinks.
     */
    eyebrow: PropTypes.string,

    /**
     * URL.
     */
    href: PropTypes.string.isRequired,

    /**
     * Meta Data Line One
     */
    eventMonth: PropTypes.string,

    /**
     * Meta Data Line Two
     */
    eventDay: PropTypes.string,

    /**
     * Open link in new tab/window.
     */
    newWindow: PropTypes.bool,

    /**
     * SVG Icon
     */
    icon: PropTypes.node,

    /**
     * Size of the BlockLink. Small will hide the eyebrow.
     */
    size: PropTypes.oneOf(sizes),

    /**
     * Contextual SVG icon
     */
    contextualIcon: PropTypes.node,

    /**
     * Enable compact view
     */
    isCompactViewActive: PropTypes.bool,

    /**
     * Enable outline view
     */
    isOutlineActive: PropTypes.bool,

    /**
     * Enable single view element
     */
    isSingleViewElement: PropTypes.bool,

    /**
     * Enable large heading for title
     */
    isTitleLargeHeading: PropTypes.bool
  };
  /* eslint-enable max-len */

  static defaultProps = {
    newWindow: false,
    size: "large",
    icon: (
      <SVGIcon name="arrow" style={{ transform: "rotate(270deg)" }} size="s" />
    ),
    isCompactViewActive: false,
    isOutlineActive: false
  };

  static iconTypes = iconTypes;
  static sizes = sizes;

  constructor(props) {
    super(props);

    this.getClassName = getClassNameFactory(ListOfLinksBlockLink.displayName);
  }

  render() {
    const {
      children,
      metadata,
      description,
      eyebrow,
      href,
      eventMonth,
      eventDay,
      newWindow,
      icon,
      size,
      contextualIcon,
      isCompactViewActive,
      isOutlineActive,
      isSingleViewElement,
      isTitleLargeHeading
    } = this.props;

    const linkAttributes = {
      href
    };

    const withEventData = eventMonth || eventDay;
    const isDescriptionEnabled = description && description.length > 0;
    const eventData = (
      <div className={this.getClassName({ descendantName: "eventData" })}>
        <div
          className={this.getClassName({
            descendantName: "eventMonth",
            className: typestack("p2Bold")
          })}
        >
          {eventMonth}
        </div>
        <div
          className={this.getClassName({
            descendantName: "eventDay",
            className: typestack("h3")
          })}
        >
          {eventDay}
        </div>
      </div>
    );
    const showEyebrow = eyebrow && eyebrow.length > 0;
    const getTitle = (
      <div
        className={this.getClassName({
          descendantName: "title",
          modifiers: classNames(isTitleLargeHeading && "largeHeading"),
          className:
            size === "small" ? typestack("p2Bold") : typestack("p1Bold")
        })}
      >
        {children}
      </div>
    );
    const getContextualIcon = (
      <div
        className={this.getClassName({
          descendantName: "contextualIcon"
        })}
        aria-hidden="true"
      >
        {contextualIcon}
      </div>
    );
    const getCtaIcon = (
      <div
        className={this.getClassName({
          descendantName: "icon"
        })}
        aria-hidden="true"
      >
        {icon}
      </div>
    );
    const getDescription = (
      <div
        className={this.getClassName({
          descendantName: "description",
          className: typestack("p1")
        })}
      >
        {description}
      </div>
    );
    const getMetaData = (
      <div
        className={this.getClassName({
          descendantName: "metadata",
          className: typestack("p3")
        })}
      >
        {metadata}
      </div>
    );
    const BlockLinkContainer = props => {
      const elementClassName = this.getClassName({
        modifiers: classNames(
          {
            [size]: true,
            withEventData
          },
          isCompactViewActive && "compact",
          isOutlineActive && "outline"
        ),
        className: "u-topBorder"
      });

      return createElement(
        isSingleViewElement ? "div" : "li",
        {
          className: elementClassName
        },
        props.children
      );
    };

    if (newWindow) {
      linkAttributes.target = "_blank";
      linkAttributes.rel = "noopener";
    }

    return (
      <BlockLinkContainer {...this.props}>
        <a
          className={this.getClassName({ descendantName: "link" })}
          {...linkAttributes}
        >
          <div className={this.getClassName({ descendantName: "inner" })}>
            {contextualIcon && getContextualIcon}
            <div className={this.getClassName({ descendantName: "container" })}>
              {showEyebrow && (
                <div
                  className={this.getClassName({ descendantName: "eyebrow" })}
                >
                  <Eyebrow text={eyebrow} />
                </div>
              )}
              <div className={this.getClassName({ descendantName: "body" })}>
                {withEventData && eventData}
                <div className={this.getClassName({ descendantName: "text" })}>
                  {getTitle}
                  {metadata && getMetaData}
                  {isDescriptionEnabled && getDescription}
                </div>
                {getCtaIcon}
              </div>
            </div>
          </div>
        </a>
      </BlockLinkContainer>
    );
  }
}

export default ListOfLinksBlockLink;
