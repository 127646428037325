import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Avatar from "@emcm-ui/component-avatar";
import Group from "@emcm-ui/component-group";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const Attribution = ({
  href,
  imgSrc,
  imgSrcSet,
  name,
  socialButtons,
  caption,
  isDark
}) => {
  const getClassName = getClassNameFactory(Attribution.displayName);
  const linkProps = {};
  let ElementType = "div";

  if (href) {
    linkProps.href = href;
    ElementType = "a";
  }

  return (
    <div
      className={getClassName({
        modifiers: classNames({
          noImage: !imgSrc,
          dark: isDark
        })
      })}
    >
      {imgSrc ? (
        <ElementType
          className={getClassName({ descendantName: "image" })}
          {...linkProps}
        >
          <Avatar alt={name} sizes="55px" src={imgSrc} srcSet={imgSrcSet} />
        </ElementType>
      ) : null}

      <div className={getClassName({ descendantName: "body" })}>
        <ElementType
          className={getClassName({
            descendantName: "name",
            className: typestack("p1Bold")
          })}
          {...linkProps}
          aria-label={`${name}, ${caption}`}
        >
          {name}
        </ElementType>
        <div
          className={getClassName({
            descendantName: "caption",
            className: typestack("p3")
          })}
        >
          {caption}
        </div>
        {socialButtons ? (
          <div className={getClassName({ descendantName: "social" })}>
            <Group compact>
              {socialButtons.map((a, i) => (
                <Group.Item key={i}>{a}</Group.Item>
              ))}
            </Group>
          </div>
        ) : null}
      </div>
    </div>
  );
};

Attribution.displayName = "Attribution";

/* eslint-disable max-len */
Attribution.propTypes = {
  /**
   * Profile URL for the attribution.
   */
  href: PropTypes.string,
  /**
   * Image `src`. Used if srcSet is not defined, or as a fallback.
   */
  imgSrc: PropTypes.string,
  /**
   * Image URL or comma-separated list indicating a set of possible image sources for the user agent to use for different screen sizes. Same format as [srcSet](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#Example_4_Using_the_srcset_and_sizes_attributes), i.e. `my-image-200.png 200w, my-image-200.png 200w`. Must be square.
   */
  imgSrcSet: PropTypes.string,
  /**
   * Attribution name.
   */
  name: PropTypes.string.isRequired,
  /**
   * Social buttons rendered alongside the attribution's name.
   */
  socialButtons: PropTypes.node,
  /**
   * Caption that displays underneath the attribution's name.
   */
  caption: PropTypes.node,
  /**
   * IsDark Background
   */
  isDark: PropTypes.bool
};
/* eslint-enable max-len */

Attribution.defaultProps = {
  socialUrls: {}
};

export default Attribution;
