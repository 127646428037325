import PropTypes from "prop-types";
import React, { Component, createRef } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Loader from "@emcm-ui/component-loader";
import Alignment from "@emcm-ui/component-alignment";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import ResultSummary from "./components/ResultSummary/ResultSummary";
import NoResult from "./components/NoResult/NoResult";
import SearchResultCards from "./components/SearchResultCards/SearchResultCards";
import SearchFilters from "./components/SearchFilters/SearchFilters";
import { getHash, setHash } from "./utilities/hash";
import { fetchSearch } from "./utilities/fetchSearch";
import * as Constant from "./utilities/constants";
import { trackFilters, trackPageNumber } from "./utilities/analytics";

class SearchResultsMixture extends Component {
  static displayName = "SearchResultsMixture";

  static propTypes = {
    /**
     * Label for expand more button
     */
    showMore: PropTypes.string,
    /**
     * Label for expended button
     */
    showLess: PropTypes.string,
    /**
     * Label for filter heading
     */
    tagHeading: PropTypes.string,
    /**
     * clear all text
     */
    clearAllText: PropTypes.string,
    /**
     * Label for featured text
     */
    featuredText: PropTypes.string,
    /**
     * check for featured content
     */
    featuredContent: PropTypes.bool,
    /**
     * end point for featured content
     */
    featuredContentUrl: PropTypes.string.isRequired,
    /**
     * end point for search
     */
    endpoint: PropTypes.string.isRequired,
    /**
     * value for search result sort by
     */
    sortBy: PropTypes.string,
    /**
     * list of filters
     */
    topFilters: PropTypes.array.isRequired,
    /**
     * label for all text
     */
    allText: PropTypes.string,
    /**
     * Boolean value to display description of card
     */
    includeDescription: PropTypes.bool,
    /**
     * Boolean value to display read time in card footer section
     */
    includeReadTime: PropTypes.bool,
    /**
     * value for 'min' text
     */
    minLabel: PropTypes.string,
    /**
     * Text for read article
     */
    readArticle: PropTypes.string,
    /**
     * Text for view result text
     */
    viewText: PropTypes.string,
    /**
     * Text for no result found text
     */
    noMatchesText: PropTypes.string,
    /**
     * icons
     */
    icon: PropTypes.shape({
      /**
       * clock icon
       */
      clock: PropTypes.string,
      /**
       * video icon
       */
      video: PropTypes.string,
      /**
       * clock icon
       */
      arrow: PropTypes.string
    }),
    /**
     * number of cards to show each page
     */
    cardsCount: PropTypes.number
  };

  static defaultProps = {
    showLess: "Show Less",
    showMore: "Show More",
    clearAllText: "Clear all",
    tagHeading: "Related topics",
    featuredText: "Featured",
    featuredContent: true,
    includeDescription: true,
    includeReadTime: true,
    viewText: "Viewing {start}-{end} of {total} for {term}",
    noMatchesText: "We did not find any matches for {term}",
    allText: "All",
    minLabel: "min",
    readArticle: "Read article",
    sortBy: "relevance",
    topFilters: [],
    cardsCount: 18
  };

  constructor(props) {
    super(props);

    this.state = {
      pageLoading: true,
      tagsLoading: false,
      resultsLoading: false,
      searchResults: [],
      featuredCards: [],
      filters: [],
      activeFilter: "",
      tagFilters: [],
      selectedTags: [],
      pagination: {},
      totalResults: 0,
      hasSearchTerm: false,
      currentPage: null,
      isTagFilterSelected: false,
      queryLink: "",
      lastAppliedFilterKey: "",
      isMultipleFilterApplied: false,
      isPageLoad: true
    };
    this.getClassName = getClassNameFactory(SearchResultsMixture.displayName);
    this.isPageChanged = false;
    this.searchRef = createRef();
  }

  query = {
    getString: key => {
      if (typeof window !== "undefined") {
        const params = new URLSearchParams(window.location.search);

        return params.get(key);
      }
    },
    getCurrentPage: () => {
      const hashParams = new URLSearchParams(getHash());
      const currentPage = hashParams.get(Constant.PAGE)
        ? Number(hashParams.get(Constant.PAGE))
        : null;

      return currentPage;
    },
    getActiveFilter: () => {
      const { isPageLoad, activeFilter } = this.state;

      if (isPageLoad) {
        let selectedFilter = "";
        const hashParams = new URLSearchParams(getHash());

        for (const [key, value] of hashParams.entries()) {
          const hashValue = value.split(",");

          if (hashValue.includes(Constant.FILTER_THEMES_ID)) {
            const filterQueryKey = key.replace(
              Constant.ELASTIC_SEARCH_QUERY_KEY_PREFIX,
              Constant.ELASTIC_SEARCH_QUERY_VALUE_PREFIX
            );

            selectedFilter = hashParams.get(filterQueryKey);
            break;
          }
        }

        return selectedFilter;
      }

      return activeFilter;
    }
  };

  hash = {
    set: () => {
      const { queryLink, currentPage, lastAppliedFilterKey } = this.state;

      setHash({
        queryLink,
        currentPage,
        lastAppliedFilterKey,
        callback: this.api.fetchData
      });
    },
    onChange: () => {
      this.api.fetchData();
    }
  };

  api = {
    fetchData: async () => {
      try {
        const {
          endpoint,
          featuredContentUrl,
          sortBy,
          featuredContent
        } = this.props;
        const {
          currentPage,
          hasSearchTerm,
          isPageLoad,
          isTagFilterSelected,
          isMultipleFilterApplied
        } = this.state;
        const queryString = this.query.getString(
          Constant.SEARCH_QUERY_STRING_KEY
        );
        const searchParameters = {
          queryString,
          sortBy,
          isTagFilterSelected,
          isMultipleFilterApplied,
          isPageLoad
        };

        this.setState({
          pageLoading: isPageLoad
        });

        const response = await fetchSearch({
          searchEndpoint: endpoint,
          featuredContentUrl,
          featuredContent,
          currentPage,
          hasSearchTerm,
          searchParameters
        });

        this.setSearchResult(response);
      } catch (error) {
        this.setState({
          pageLoading: false,
          tagsLoading: false,
          resultsLoading: false
        });
      }
    }
  };

  pagination = {
    onPageChanged: page => {
      this.setState(
        {
          currentPage: page,
          resultsLoading: true,
          isPageLoad: false
        },
        () => {
          this.isPageChanged = true;
          this.hash.set();
          trackPageNumber(page);
        }
      );
    }
  };

  getFilters = () => {
    const { topFilters, allText } = this.props;
    const UpdatedFilters = [];

    if (Array.isArray(topFilters)) {
      UpdatedFilters.push(...topFilters);
      UpdatedFilters.unshift({
        name: allText,
        link: "",
        title: allText
      });
    }

    return UpdatedFilters;
  };

  componentDidMount() {
    const queryString = this.query.getString(Constant.SEARCH_QUERY_STRING_KEY);
    const hasSearchTerm = queryString !== null && queryString !== "";
    const filters = this.getFilters();
    const currentPage = this.query.getCurrentPage();

    this.componentIsMounted = true;
    this.setState(
      {
        hasSearchTerm,
        filters,
        currentPage
      },
      () => this.api.fetchData()
    );

    window.addEventListener("hashchange", this.hash.onChange);
  }

  componentWillUnmount() {
    window.removeEventListener("hashchange", this.hash.onChange);
    this.componentIsMounted = false;
  }

  setSearchResult = response => {
    // if component is not mounted after fetch, abort
    if (!this.componentIsMounted) {
      return;
    }
    const { tagFilters, selectedTags } = this.state;
    const {
      searchResults,
      featuredCards,
      tagCloudSettings,
      pagination,
      totalResults
    } = response;
    const activeFilter = this.query.getActiveFilter();

    this.setState(
      {
        pageLoading: false,
        tagsLoading: false,
        resultsLoading: false,
        isPageLoad: true,
        searchResults,
        featuredCards,
        tagFilters: tagCloudSettings.tags || tagFilters,
        selectedTags: tagCloudSettings.selectedTags || selectedTags,
        pagination,
        totalResults,
        activeFilter
      },
      () => {
        if (this.isPageChanged) {
          this.searchRef.current.focus();
          this.searchRef.current.scrollIntoView(true);
          this.isPageChanged = false;
        }
      }
    );
  };

  trackFilterAnalytics = () => {
    const { activeFilter, selectedTags } = this.state;

    trackFilters(activeFilter, selectedTags);
  };

  onFilterChanged = () => {
    this.hash.set();
    this.trackFilterAnalytics();
  };

  handleFilterSelect = response => {
    const { activeFilter, link, lastAppliedFilterKey } = response;

    this.setState(
      {
        activeFilter,
        queryLink: link,
        lastAppliedFilterKey,
        isTagFilterSelected: false,
        isMultipleFilterApplied: false,
        currentPage: null,
        tagsLoading: true,
        isPageLoad: false
      },
      () => this.onFilterChanged()
    );
  };

  handleTagSelect = response => {
    const {
      updatedTags,
      isTagSelected,
      filterLink,
      isMultipleFilterApplied,
      lastAppliedFilterKey
    } = response;

    this.setState(
      {
        resultsLoading: true,
        selectedTags: updatedTags,
        isTagFilterSelected: isTagSelected,
        queryLink: filterLink,
        isMultipleFilterApplied,
        lastAppliedFilterKey,
        currentPage: null,
        isPageLoad: false
      },
      () => this.onFilterChanged()
    );
  };

  clearTagSelection = () => {
    const { activeFilter, filters } = this.state;
    const filter = filters.filter(item => item.name === activeFilter)[0];
    const link = filter ? filter.link : "";

    this.setState(
      {
        selectedTags: [],
        isTagFilterSelected: false,
        queryLink: link,
        currentPage: null,
        lastAppliedFilterKey: "",
        isMultipleFilterApplied: false,
        resultsLoading: true,
        isPageLoad: false
      },
      () => this.hash.set()
    );
  };

  render() {
    const {
      pageLoading,
      searchResults,
      featuredCards,
      tagFilters,
      selectedTags,
      activeFilter,
      currentPage,
      pagination,
      totalResults,
      hasSearchTerm,
      filters
    } = this.state;
    const {
      // Tag cloud
      showMore,
      showLess,
      tagHeading,
      clearAllText,
      // Featured Cards Props
      featuredText,
      featuredContent,
      featuredContentUrl,
      // search url parameters
      endpoint,
      sortBy,
      // Filter
      topFilters,
      allText,
      // Content Display Props
      includeDescription,
      includeReadTime,
      minLabel,
      readArticle,
      viewText,
      noMatchesText,
      cardsCount,
      // Icon Props
      icon
    } = this.props;

    const hasFeaturedCards = featuredCards && featuredCards.length > 0;
    const hasSearchCards = searchResults && searchResults.length > 0;
    const shouldShowCards =
      (hasSearchCards || hasFeaturedCards) && !pageLoading;
    const shouldShowNoResults = !shouldShowCards && !pageLoading;

    const searchKey = this.query.getString(Constant.SEARCH_QUERY_STRING_KEY);
    const tagCloud = {
      tagFilters,
      selectedTags,
      showMore,
      showLess,
      tagHeading,
      clearAllText,
      onTagSelect: this.handleTagSelect,
      clearTagSelection: this.clearTagSelection
    };
    const filterSetting = {
      filters,
      activeFilter,
      onFilterSelect: this.handleFilterSelect
    };
    const featured = { featuredCards, featuredText, featuredContent };
    const page = {
      currentPage,
      pagination,
      onPageChanged: this.pagination.onPageChanged
    };
    const search = {
      searchResults,
      totalResults,
      searchKey,
      hasSearchTerm,
      cardsCount
    };
    const combinedProps = {
      tagCloud,
      filterSetting,
      featured,
      page,
      search,
      ...this.props,
      ...this.state
    };

    return (
      <div
        className={this.getClassName()}
        data-rehydratable={getRehydratableName(
          SearchResultsMixture.displayName
        )}
        data-clear-all-text={clearAllText}
        data-show-more={showMore}
        data-show-less={showLess}
        data-filter-heading={tagHeading}
        data-featured-text={featuredText}
        data-featured-content={JSON.stringify(featuredContent)}
        data-featured-content-url={featuredContentUrl}
        data-endpoint={endpoint}
        data-viewtext={viewText}
        data-all-text={allText}
        data-no-matches-text={noMatchesText}
        data-include-description={JSON.stringify(includeDescription)}
        data-include-readtime={JSON.stringify(includeReadTime)}
        data-default-sortby={sortBy}
        data-min-label={minLabel}
        data-top-filter-tags={JSON.stringify(topFilters)}
        data-clock-icon={icon.clock}
        data-video-icon={icon.video}
        data-arrow-icon={icon.arrow}
        data-read-article-text={readArticle}
        data-cards-count={JSON.stringify(cardsCount)}
        ref={this.searchRef}
      >
        {pageLoading && (
          <Alignment horizontal="center" vertical="center">
            <Loader />
          </Alignment>
        )}

        {shouldShowCards && (
          <React.Fragment>
            <ResultSummary {...combinedProps} />
            <SearchFilters {...combinedProps} />
            <SearchResultCards {...combinedProps} />
          </React.Fragment>
        )}

        {shouldShowNoResults && <NoResult {...combinedProps} />}
      </div>
    );
  }
}

export default SearchResultsMixture;
