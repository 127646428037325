/* eslint-disable no-undefined */
import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

class GoogleMap extends Component {
  // call Javascript API, Paid service
  // componentDidMount() {
  //   if (!window.google) {
  //     const s = document.createElement("script");

  //     s.type = "text/javascript";
  //     s.src = `https://maps.google.com/maps/api/js?key=${this.props.apiKey}`;
  //     const x = document.getElementsByTagName("script")[0];

  //     if (x) {
  //       x.parentNode.insertBefore(s, x);
  //       s.addEventListener("load", () => {
  //         this.onGMapScriptLoad();
  //       });
  //     }
  //   } else if (window.google) {
  //     this.onGMapScriptLoad();
  //   }
  // }

  // onGMapScriptLoad = () => {
  //   const options = {
  //     center: {
  //       lat: this.props.latitude,
  //       lng: this.props.longitude
  //     },
  //     mapTypeId: "roadmap",
  //     zoom: this.props.zoom
  //   };

  //   const map = new window.google.maps.Map(
  //     document.getElementById(this.props.id),
  //     options
  //   );

  //   this.onGMapLoad(map);
  // };

  // onGMapLoad = map => {
  //   new window.google.maps.Marker({
  //     position: {
  //       lat: this.props.latitude,
  //       lng: this.props.longitude
  //     },
  //     map
  //   });
  // };

  render() {
    const { id, apiKey, height, latitude, longitude, zoom, width } = this.props;

    const getClassName = getClassNameFactory(GoogleMap.displayName);

    const src = `https://www.google.com/maps/embed/v1/place?key=${
      this.props.apiKey
    }&q=${latitude},${longitude}&zoom=${zoom}`;

    return (
      <div
        className={getClassName({ descendantName: "container" })}
        style={{
          ...(height && { height: `${height}px` }),
          ...(width && { width: `${width}px` })
        }}
        data-id={id}
        data-api-key={apiKey}
        data-latitude={latitude}
        data-longitude={longitude}
        data-zoom={zoom}
        data-height={height}
        data-width={width}
        data-rehydratable={getRehydratableName(GoogleMap.displayName)}
      >
        {height && width ? (
          <div
            className={getClassName({
              descendantName: "map"
            })}
            id={this.props.id}
          >
            {/* calling Embed API solution, free */}
            <iframe width="100%" src={src} />
          </div>
        ) : (
          <div
            className={getClassName({
              descendantName: "containedMap"
            })}
            id={this.props.id}
          >
            {/* calling Embed API solution, free */}
            <iframe width="100%" src={src} />
          </div>
        )}
      </div>
    );
  }
}

GoogleMap.displayName = "GoogleMap";

GoogleMap.propTypes = {
  /**
   * ID for google map
   */
  id: PropTypes.string,

  /**
   * API Key for google map
   */
  apiKey: PropTypes.string.isRequired,

  /**
   * Latitude in google map
   */
  latitude: PropTypes.number.isRequired,

  /**
   * Longitude in google map
   */
  longitude: PropTypes.number.isRequired,

  /**
   * Zoom for google map
   */
  zoom: PropTypes.number.isRequired,

  /**
   * Height of the map container, in pixels
   */
  height: PropTypes.number,

  /**
   * Width of the map container, in pixels
   */
  width: PropTypes.number
};

GoogleMap.defaultProps = {
  zoom: 16,
  apiKey: "",
  id: Date.now().toString()
};

export default GoogleMap;
