import React from "react";
import PropTypes from "prop-types";
import { storiesOf } from "@storybook/react";
import {
  withKnobs,
  select,
  text,
  number,
  boolean
} from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import Section from "@emcm-ui/component-section";
import Link from "@emcm-ui/component-link";
import StockTicker from "./index";
import "./index.css";
import StockTickerREADME from "../README.md";

const stories = storiesOf("Components/StockTicker", module);
const BackgroundType = ["light", "dark"];
const DEFAULT_SPEED = 5;

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(StockTickerREADME));

class StockTickerWrapper extends React.Component {
  // this component is needed to unmount and remount TWF because the data that gets passed
  // through gets stored in state. So any subsequent change to data doesn't cause a re-render.
  // To prevent unwanted side affects to TWF we have this helper in the storybook to show
  // how the ticker would render with different background colors.

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    backgroundType: PropTypes.string,
    scrollDelay: PropTypes.number,
    hideDate: PropTypes.bool,
    hideTime: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      backgroundType: props.backgroundType,
      scrollDelay: props.scrollDelay,
      hideTime: props.hideTime,
      hideDate: props.hideDate,
      updating: false
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.backgroundType !== prevState.backgroundType ||
      nextProps.hideDate !== prevState.hideDate ||
      nextProps.hideTime !== prevState.hideTime ||
      nextProps.scrollDelay !== prevState.scrollDelay
    ) {
      return {
        ...nextProps,
        updating: true
      };
    }

    return null;
  }

  componentDidUpdate() {
    if (this.state.updating === true) {
      this.setState(() => ({ updating: false }));
    }
  }

  componentDidMount() {
    this.setState(() => ({ updating: false }));
  }

  render() {
    const {
      backgroundType,
      updating,
      hideDate,
      hideTime,
      scrollDelay
    } = this.state;

    if (updating) {
      return null;
    }

    return this.props.children({
      backgroundType,
      hideTime,
      hideDate,
      scrollDelay
    });
  }
}

stories
  .add("Default", () => (
    <StockTickerWrapper
      backgroundType={select("Background Type", BackgroundType, "light")}
      scrollDelay={number("Speed", DEFAULT_SPEED)}
      hideDate={boolean("Hide date", false)}
      hideTime={boolean("Hide Time", false)}
    >
      {({ backgroundType, scrollDelay, hideTime, hideDate }) => (
        <React.Fragment>
          <StockTicker
            apiUrl={
              "https://run.mocky.io/v3/060d77e7-34b9-4384-8d31-00b806a31fe6"
            }
            playAriaLabel={text("Play aria-label", "Press to Play")}
            pauseAriaLabel={text("Pause aria-label", "Press to Pause")}
            nextAriaLabel={text("Next aria-label", "Scroll to next script")}
            previousAriaLabel={text(
              "Previous aria-label",
              "Scroll to previous script"
            )}
            backgroundType={backgroundType}
            scrollDelay={scrollDelay}
            skipLinkLabel={text("Skip link text", "Skip Ticker list")}
            skipLinkTargetElement={text(
              "Skip link target Element",
              "content-section"
            )}
            indexCodes={[
              { indexCode: "AW01", pagePath: "#" },
              { indexCode: "RUT", pagePath: "#" },
              { indexCode: "RUI", pagePath: "#" },
              { indexCode: "UKX", pagePath: "#" },
              { indexCode: "MCX", pagePath: "#" },
              { indexCode: "4GGL", pagePath: "#" },
              { indexCode: "FTSEMIB", pagePath: "#" }
            ]}
            hideDate={hideDate}
            hideTime={hideTime}
          />
          <div className="content-section">
            <Section>
              <Link href="#">Link from content section</Link>
            </Section>
          </div>
        </React.Fragment>
      )}
    </StockTickerWrapper>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <StockTickerWrapper
        backgroundType={select("Background Type", BackgroundType, "light")}
        scrollDelay={number("Speed", DEFAULT_SPEED)}
      >
        {({ backgroundType, scrollDelay }) => (
          <React.Fragment>
            <StockTicker
              apiUrl="https://run.mocky.io/v3/060d77e7-34b9-4384-8d31-00b806a31fe6"
              backgroundType={backgroundType}
              scrollDelay={scrollDelay}
              skipLinkLabel={text("Skip link text", "Skip Ticker list")}
              skipLinkTargetElement={text(
                "Skip link target Element",
                "content-section"
              )}
              indexCodes={[
                { indexCode: "AW01", pagePath: "#" },
                { indexCode: "RUT", pagePath: "#" },
                { indexCode: "RUI", pagePath: "#" },
                { indexCode: "UKX", pagePath: "#" },
                { indexCode: "MCX", pagePath: "#" },
                { indexCode: "4GGL", pagePath: "#" },
                { indexCode: "FTSEMIB", pagePath: "#" }
              ]}
            />
            <div className="content-section">
              <Section>
                <Link href="#">Link from content section</Link>
              </Section>
            </div>
          </React.Fragment>
        )}
      </StockTickerWrapper>
    </Language>
  ))

  .add("Rehydrated", () => (
    <StaticRenderer>
      <StockTickerWrapper
        backgroundType={select("Background Type", BackgroundType, "light")}
        scrollDelay={number("Speed", DEFAULT_SPEED)}
        hideDate={boolean("Hide date", false)}
        hideTime={boolean("Hide Time", false)}
      >
        {({ backgroundType, scrollDelay, hideDate, hideTime }) => (
          <React.Fragment>
            <StockTicker
              apiUrl={
                "https://run.mocky.io/v3/060d77e7-34b9-4384-8d31-00b806a31fe6"
              }
              backgroundType={backgroundType}
              scrollDelay={scrollDelay}
              skipLinkLabel={text("Skip link text", "Skip Ticker list")}
              playAriaLabel={text("Play aria-label", "Press to Play")}
              pauseAriaLabel={text("Pause aria-label", "Press to Pause")}
              nextAriaLabel={text("Next aria-label", "Scroll to next script")}
              previousAriaLabel={text(
                "Previous aria-label",
                "Scroll to previous script"
              )}
              skipLinkTargetElement={text(
                "Skip link target Element",
                "content-section"
              )}
              indexCodes={[
                { indexCode: "AW01", pagePath: "#" },
                { indexCode: "RUT", pagePath: "#" },
                { indexCode: "RUI", pagePath: "#" },
                { indexCode: "UKX", pagePath: "#" },
                { indexCode: "MCX", pagePath: "#" },
                { indexCode: "4GGL", pagePath: "#" },
                { indexCode: "FTSEMIB", pagePath: "#" }
              ]}
              hideDate={hideDate}
              hideTime={hideTime}
            />
            <div className="content-section">
              <Section>
                <Link href="#">Link from content section</Link>
              </Section>
            </div>
          </React.Fragment>
        )}
      </StockTickerWrapper>
    </StaticRenderer>
  ));
