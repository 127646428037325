export default function getStartOffset({ offset, rect, direction, width }) {
  const PERCENTAGE_BASE = 100;

  if (offset === "run-in") {
    switch (direction) {
      case "toRight":
        return -rect.width;
      case "toLeft":
      default:
        return width;
    }
  }
  if (typeof offset === "string") {
    const offsetRelative = Number(offset.replace("%", ""));

    if (offsetRelative) {
      return width / PERCENTAGE_BASE * offsetRelative;
    }
  }

  return offset;
}
