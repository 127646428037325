import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, boolean, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";

import Block from "@emcm-ui/component-block";
import Image, { anchorPoints } from "@emcm-ui/component-image";
import { Language } from "@emcm-ui/component-theme";
import VideoPlayer from "@emcm-ui/component-video-player";

import Longform from "./index";
import "./Longform.css";
import LongformREADME from "../README.md";

const stories = storiesOf("Components/Longform", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(LongformREADME));

const videoPlaceholderImages = [
  "placeholder-images/960x540.png 960w",
  "placeholder-images/1920x1080.png 1920w",
  "placeholder-images/3840x2160.png 3840w"
];

const chapterPlaceholderImages = [
  "test-images/4-1/chapter-divider-288.jpg 288w",
  "test-images/4-1/chapter-divider-368.jpg 368w",
  "test-images/4-1/chapter-divider-576.jpg 576w",
  "test-images/4-1/chapter-divider-768.jpg 768w",
  "test-images/4-1/chapter-divider-1168.jpg 1168w",
  "test-images/4-1/chapter-divider-1536.jpg 1536w",
  "test-images/4-1/chapter-divider-2336.jpg 2336w",
  "test-images/4-1/chapter-divider-3504.jpg 3504w"
];

stories
  .add("Default", () => (
    <Longform>
      <Longform.ChapterNav>
        <Longform.ChapterNav.Chapter title="Longform Demo" href="#" />
        <Longform.ChapterNav.Chapter
          title="A new era for trust"
          href="#chapter-one"
          prefix="1."
        />
      </Longform.ChapterNav>

      <Longform.Child width="narrow">
        <Longform.RichText dropcap>
          <p>
            Voters decided that <em>Britain</em> should not remain in the
            European Union (EU), and Prime Minister David Cameron &nbsp;
            <a href="https://www.davidcameron.com">
              announced he would step down by October
            </a>, commentary continues around the financial, economic, political
            and legal implications of a <strong>British</strong> exit (Brexit).
            A British exit from the EU will rock the Union, already shaken by
            differences over migration and the future of the eurozone, by
            ripping away its second-largest economy, one of its top two military
            powers and by far its richest financial center.
          </p>
        </Longform.RichText>
      </Longform.Child>
      <Longform.Child width="wide">
        <Longform.Asset
          caption={
            <span>
              <strong>NYC:</strong> A place where only breakfast means
              breakfast.
            </span>
          }
        >
          <Image
            alt="Homepage Hero responsive"
            sizes={Image.sizes.section}
            src="test-images/16-9/large_3.jpg"
            srcSet="test-images/16-9/large_3.jpg 2000w, test-images/16-9/medium_3.jpg 800w, test-images/16-9/small_3.jpg 400w"
          />
        </Longform.Asset>
      </Longform.Child>
      <Longform.Child>
        <Longform.RichText>
          <p>
            The past year was really something else. 2016 saw the death of
            Justice Antonin Scalia, the end of Merrick Garland’s Supreme Court
            dreams, the death of David Bowie. Not even Mariah Carey made it out
            unscathed. But, before we bid the year a final adieu (or good
            riddance), it’s worth looking back at the Supreme Court’s most
            important decisions. For, throughout all of 2016’s ups and downs,
            the Court continued to shape American law, government, and politics,
            with important rulings in the areas of criminal law, civil rights,
            immigration, and more. Here are our top six.
          </p>
        </Longform.RichText>
      </Longform.Child>
      <Longform.Child width="full">
        <Longform.ChapterDivider
          backgroundAnchor="C"
          backgroundGradient={true}
          backgroundSrc="test-images/4-1/chapter-divider-1168.jpg"
          backgroundSrcSet={chapterPlaceholderImages.join(", ")}
          chapterText="Chapter 1"
          titleText=" A new era for trust"
          id="chapter-one"
        />
      </Longform.Child>
      <Longform.Child>
        <Longform.RichText>
          <p>
            The past year was really something else. 2016 saw the death of
            Justice Antonin Scalia, the end of Merrick Garland’s Supreme Court
            dreams, the death of David Bowie. Not even Mariah Carey made it out
            unscathed. But, before we bid the year a final adieu (or good
            riddance), it’s worth looking back at the Supreme Court’s most
            important decisions. For, throughout all of 2016’s ups and downs,
            the Court continued to shape American law, government, and politics,
            with important rulings in the areas of criminal law, civil rights,
            immigration, and more. Here are our top six.
          </p>
        </Longform.RichText>
      </Longform.Child>
      <Longform.Child width="wide">
        <Longform.Asset>
          <VideoPlayer
            duration="PT1M35S"
            kind="vimeo"
            playLabel="play video"
            videoId="114765613"
          >
            <Image
              alt="Placeholder image"
              src="placeholder-images/960x540.png"
              srcSet={videoPlaceholderImages.join(", ")}
              fit="cover"
            />
          </VideoPlayer>
        </Longform.Asset>
      </Longform.Child>
      <Longform.Child>
        <Longform.RichText>
          <p>
            The past year was really something else. 2016 saw the death of
            Justice Antonin Scalia, the end of Merrick Garland’s Supreme Court
            dreams, the death of David Bowie. Not even Mariah Carey made it out
            unscathed. But, before we bid the year a final adieu (or good
            riddance), it’s worth looking back at the Supreme Court’s most
            important decisions. For, throughout all of 2016’s ups and downs,
            the Court continued to shape American law, government, and politics,
            with important rulings in the areas of criminal law, civil rights,
            immigration, and more. Here are our top six.
          </p>
        </Longform.RichText>
      </Longform.Child>
      <Longform.Child width="wide">
        <Longform.BlockQuote
          cite="https://example.com/article-blockchain.html"
          text="Using blockchain tokens to record and exchange ownership of
            assets and rights is going to transform industries in the
            same way electricity transformed coal-powered factories; not
            to mention streamlining how information is shared across
            supply chains."
        />
      </Longform.Child>
      <Longform.Child>
        <Longform.RichText>
          <p>
            The past year was really something else. 2016 saw the death of
            Justice Antonin Scalia, the end of Merrick Garland’s Supreme Court
            dreams, the death of David Bowie. Not even Mariah Carey made it out
            unscathed. But, before we bid the year a final adieu (or good
            riddance), it’s worth looking back at the Supreme Court’s most
            important decisions. For, throughout all of 2016’s ups and downs,
            the Court continued to shape American law, government, and politics,
            with important rulings in the areas of criminal law, civil rights,
            immigration, and more. Here are our top six.
          </p>
        </Longform.RichText>
      </Longform.Child>
    </Longform>
  ))
  .add("Asset", () => (
    <Longform>
      <Longform.Child width="wide">
        <Longform.Asset
          border={boolean("Border", false)}
          caption={
            <span>
              <strong>NYC:</strong> A place where only breakfast means
              breakfast.
            </span>
          }
        >
          <Image
            alt="Homepage Hero responsive"
            sizes={Image.sizes.section}
            src="test-images/16-9/large_3.jpg"
            srcSet="test-images/16-9/large_3.jpg 2000w, test-images/16-9/medium_3.jpg 800w, test-images/16-9/small_3.jpg 400w"
          />
        </Longform.Asset>
      </Longform.Child>
    </Longform>
  ))
  .add("BlockQuote", () => (
    <Longform>
      <Longform.Child width="wide">
        <Longform.BlockQuote
          cite="https://example.com/article-blockchain.html"
          text="Using blockchain tokens to record and exchange ownership of
            assets and rights is going to transform industries in the
            same way electricity transformed coal-powered factories; not
            to mention streamlining how information is shared across
            supply chains."
        />
      </Longform.Child>
    </Longform>
  ))
  .add("ChapterDivider", () => {
    const colorTheme = select("colorTheme", ["light", "dark"], "light");

    return (
      <Longform>
        <Longform.Child width="full">
          <Longform.ChapterDivider
            backgroundAnchor={select("backgroundAnchor", anchorPoints, "C")}
            backgroundGradient={boolean("backgroundGradient", true)}
            backgroundSrc={
              colorTheme === "light"
                ? "test-images/4-1/chapter-divider-1168.jpg"
                : ""
            }
            backgroundSrcSet={chapterPlaceholderImages.join(", ")}
            chapterText="Chapter 2"
            colorTheme={colorTheme}
            titleText="A new era for trust"
          />
        </Longform.Child>
      </Longform>
    );
  })
  .add("Child", () => (
    <Longform>
      <Longform.Child width="narrow">
        <Block height="6rem" text="Narrow" />
      </Longform.Child>
      <Longform.Child width="wide">
        <Block height="6rem" text="Wide" />
      </Longform.Child>
      <Longform.Child width="full">
        <Block height="6rem" text="Full" />
      </Longform.Child>
    </Longform>
  ))
  .add("RichText", () => (
    <Longform>
      <Longform.Child width="narrow">
        <Longform.RichText dropcap>
          <p>
            Voters decided that <em>Britain</em> should not remain in the
            European Union (EU), and Prime Minister David Cameron &nbsp;
            <a href="https://www.davidcameron.com">
              announced he would step down by October
            </a>, commentary continues around the financial, economic, political
            and legal implications of a <strong>British</strong> exit (Brexit).
            A British exit from the EU will rock the Union, already shaken by
            differences over migration and the future of the eurozone, by
            ripping away its second-largest economy, one of its top two military
            powers and by far its richest financial center.
          </p>
        </Longform.RichText>
      </Longform.Child>
      <Longform.Child width="narrow">
        <Longform.RichText>
          <div>
            <h1>Rich text</h1>
            <h2>Inline link, Bold text</h2>
            <p>
              Lorem <strong>ipsum</strong> sit <em>amet</em>, consectetur{" "}
              <a href="https://not-visited">adipisicing</a> elit, sed{" "}
              <a href="https://example.com">eiusmod</a> tempor incididunt.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <h4>Heading 4</h4>
            <h5>Heading 5</h5>
            <h6>Heading 6</h6>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <h2>Image</h2>
            <h2>Blockquote</h2>
            <blockquote>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </blockquote>
            <h2>Lists</h2>
            <h3>Unordered list (bullets)</h3>
            <ul>
              <li>This is a list item in an unordered list</li>
              <li>
                An unordered list is a list in which the sequence of items is
                not important. Sometimes, an unordered list is a bulleted list.
                And this is a long list item in an unordered list that can wrap
                onto a new line.
              </li>
              <li>
                Lists can be nested inside of each other
                <ul>
                  <li>This is a nested list item</li>
                  <li>This is another nested list item in an unordered list</li>
                </ul>
              </li>
              <li>This is the last list item</li>
            </ul>
            <h3>Unordered list</h3>
            <ul>
              <li>This is a list item in an unordered list</li>
              <li>
                An unordered list is a list in which the sequence of items is
                not important. Sometimes, an unordered list is a bulleted list.
                And this is a long list item in an unordered list that can wrap
                onto a new line.
              </li>
              <li>
                Lists can be nested inside of each other
                <ul>
                  <li>This is a nested list item</li>
                  <li>This is another nested list item in an unordered list</li>
                </ul>
              </li>
              <li>This is the last list item</li>
            </ul>
            <h3>Ordered list</h3>
            <ol>
              <li>This is a list item in an ordered list</li>
              <li>
                An ordered list is a list in which the sequence of items is
                important. An ordered list does not necessarily contain sequence
                characters.
              </li>
              <li>
                Lists can be nested inside of each other
                <ol>
                  <li>
                    This is a nested list item
                    <ol>
                      <li>
                        This is another nested list item in an ordered list
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>This is the last list item</li>
            </ol>
          </div>
        </Longform.RichText>
      </Longform.Child>
    </Longform>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Longform>
        <Longform.ChapterNav>
          <Longform.ChapterNav.Chapter title="شكل طويل تجريبي" href="#" />
          <Longform.ChapterNav.Chapter
            title="حقبة جديدة للثقة"
            href="#chapter-one"
            prefix="1."
          />
        </Longform.ChapterNav>

        <Longform.Child width="narrow">
          <Longform.RichText>
            <p>
              على أنه المستوى المشترك الذي ينبغي أن تستهدفه كافة الشعوب والأمم
              حتى يسعى كل فرد وهيئة في المجتمع، واضعين على الدوام هذا الإعلان
              نصب أعينهم، إلى توطيد احترام هذه الحقوق والحريات عن طريق التعليم
              والتربية واتخاذ إجراءات مطردة، قومية وعالمية، لضمان الإعتراف بها
              ومراعاتها بصورة عالمية فعالة بين الدول الأعضاء ذاتها وشعوب البقاع
              الخاضعة لسلطانها.
            </p>
          </Longform.RichText>
        </Longform.Child>
        <Longform.Child width="wide">
          <Longform.Asset caption={<span>فإن الجمعية العامة</span>}>
            <Image
              alt="Homepage Hero responsive"
              sizes={Image.sizes.section}
              src="test-images/16-9/large_3.jpg"
              srcSet="test-images/16-9/large_3.jpg 2000w, test-images/16-9/medium_3.jpg 800w, test-images/16-9/small_3.jpg 400w"
            />
          </Longform.Asset>
        </Longform.Child>
        <Longform.Child>
          <Longform.RichText>
            <p>
              على أنه المستوى المشترك الذي ينبغي أن تستهدفه كافة الشعوب والأمم
              حتى يسعى كل فرد وهيئة في المجتمع، واضعين على الدوام هذا الإعلان
              نصب أعينهم، إلى توطيد احترام هذه الحقوق والحريات عن طريق التعليم
              والتربية واتخاذ إجراءات مطردة، قومية وعالمية، لضمان الإعتراف بها
              ومراعاتها بصورة عالمية فعالة بين الدول الأعضاء ذاتها وشعوب البقاع
              الخاضعة لسلطانها.
            </p>
          </Longform.RichText>
        </Longform.Child>
        <Longform.Child width="full">
          <Longform.ChapterDivider
            backgroundAnchor="C"
            backgroundGradient={true}
            backgroundSrc="test-images/4-1/chapter-divider-1168.jpg"
            backgroundSrcSet={chapterPlaceholderImages.join(", ")}
            chapterText="الفصل 2"
            titleText="حقبة جديدة للثقة"
            id="chapter-one"
          />
        </Longform.Child>
        <Longform.Child>
          <Longform.RichText>
            <p>
              على أنه المستوى المشترك الذي ينبغي أن تستهدفه كافة الشعوب والأمم
              حتى يسعى كل فرد وهيئة في المجتمع، واضعين على الدوام هذا الإعلان
              نصب أعينهم، إلى توطيد احترام هذه الحقوق والحريات عن طريق التعليم
              والتربية واتخاذ إجراءات مطردة، قومية وعالمية، لضمان الإعتراف بها
              ومراعاتها بصورة عالمية فعالة بين الدول الأعضاء ذاتها وشعوب البقاع
              الخاضعة لسلطانها.
            </p>
          </Longform.RichText>
        </Longform.Child>
        <Longform.Child width="wide">
          <VideoPlayer
            duration="PT1M35S"
            kind="vimeo"
            playLabel="play video"
            videoId="114765613"
          >
            <Image
              alt="Placeholder image"
              src="placeholder-images/960x540.png"
              srcSet={videoPlaceholderImages.join(", ")}
              fit="cover"
            />
          </VideoPlayer>
        </Longform.Child>
        <Longform.Child>
          <Longform.RichText>
            <p>
              على أنه المستوى المشترك الذي ينبغي أن تستهدفه كافة الشعوب والأمم
              حتى يسعى كل فرد وهيئة في المجتمع، واضعين على الدوام هذا الإعلان
              نصب أعينهم، إلى توطيد احترام هذه الحقوق والحريات عن طريق التعليم
              والتربية واتخاذ إجراءات مطردة، قومية وعالمية، لضمان الإعتراف بها
              ومراعاتها بصورة عالمية فعالة بين الدول الأعضاء ذاتها وشعوب البقاع
              الخاضعة لسلطانها.
            </p>
          </Longform.RichText>
        </Longform.Child>
        <Longform.Child width="wide">
          <Longform.BlockQuote text="استخدام رموز بلوكشين لتسجيل وتبادل ملكية الأصول والحقوق هو تحويل الصناعات بنفس الطريقة تحويل الكهرباء مصانع تعمل بالفحم. ناهيك عن تبسيط كيفية تقاسم المعلومات عبر سلاسل التوريد." />
        </Longform.Child>
        <Longform.Child>
          <Longform.RichText>
            <p>
              على أنه المستوى المشترك الذي ينبغي أن تستهدفه كافة الشعوب والأمم
              حتى يسعى كل فرد وهيئة في المجتمع، واضعين على الدوام هذا الإعلان
              نصب أعينهم، إلى توطيد احترام هذه الحقوق والحريات عن طريق التعليم
              والتربية واتخاذ إجراءات مطردة، قومية وعالمية، لضمان الإعتراف بها
              ومراعاتها بصورة عالمية فعالة بين الدول الأعضاء ذاتها وشعوب البقاع
              الخاضعة لسلطانها.
            </p>
          </Longform.RichText>
        </Longform.Child>
      </Longform>
    </Language>
  ));
