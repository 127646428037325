import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";

import DateTime from "@emcm-ui/component-date-time";
import Link from "@emcm-ui/component-link";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const DownloadCardFooter = ({
  dateTime,
  disabled,
  icon,
  fileLabel,
  size,
  href,
  callToAction,
  callToActionHref
}) => {
  const getClassName = getClassNameFactory(DownloadCardFooter.displayName);

  return (
    <div className={getClassName({ modifiers: disabled ? "disabled" : "" })}>
      <div className={getClassName({ descendantName: "nameAndType" })}>
        <Link href={disabled ? "" : href}>
          <span
            className={getClassName({
              descendantName: "fileLabel",
              className: typestack("p1Bold")
            })}
          >
            {fileLabel}
          </span>
          <span className={getClassName({ descendantName: "downloadIcon" })}>
            {icon}
          </span>
        </Link>
      </div>

      <div
        className={getClassName({
          descendantName: "sizeAndDate",
          className: typestack("p3")
        })}
      >
        {size && <React.Fragment>{size}</React.Fragment>}
        <span>
          <span
            className={getClassName({ descendantName: "dotSeparator" })}
            aria-hidden="true"
          >
            •
          </span>
          <DateTime dateTime={dateTime} lang="enUS" />
        </span>
        {callToAction && (
          <span className={getClassName({ descendantName: "callToAction" })}>
            <Link href={callToActionHref}>{callToAction}</Link>
          </span>
        )}
      </div>
    </div>
  );
};

DownloadCardFooter.displayName = "DownloadCardFooter";

/* eslint-disable max-len */
DownloadCardFooter.propTypes = {
  /**
   * Whether the download is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * Call to action to display
   */
  callToAction: PropTypes.string,
  /**
   * Call to action href to link to
   */
  callToActionHref: PropTypes.string,
  /**
   * Label of the file.
   */
  fileLabel: PropTypes.string.isRequired,
  /**
   * href to the file.
   */
  href: PropTypes.string.isRequired,
  /**
   * An ISO 8601, RFC 2822 or `new Date(str)` compatible date string.
   */
  dateTime: PropTypes.string.isRequired,
  /**
   * Icon
   */
  icon: PropTypes.node,
  /**
   * File size in KB/MB/GB
   */
  size: PropTypes.string.isRequired
};
/* eslint-enable max-len */

DownloadCardFooter.defaultProps = {};

export default DownloadCardFooter;
