import React, { Component } from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

import { formConsts } from "../../FormConstant";

import { toggleInvalidClass } from "../../utilities";

class CheckboxControl extends Component {
  static displayName = "FormNew.CheckboxControl";

  static propTypes = {
    /**
     * Check the <input>. Will switch into controlled mode.
     */
    checked: PropTypes.bool,
    /**
     * Set the initial checked state for the <input>.
     */
    defaultChecked: PropTypes.bool,
    /**
     * Disable the <input>
     */
    disabled: PropTypes.bool,
    /**
     * Id attribute for <input> (and <label>'s `for` attribute)
     */
    id: PropTypes.string.isRequired,
    /**
     * Text for <label>
     */
    labelText: PropTypes.any.isRequired,
    /**
     * Text for optional <label>
     */
    optionalText: PropTypes.string.isRequired,
    /**
     * Name attribute for <input>, required for serialisation
     */
    name: PropTypes.string,
    /**
     * Add an onChange callback to the <input>.
     */
    onChange: PropTypes.func,
    /**
     * Set <input> to be required
     */
    required: PropTypes.bool,
    /**
     * tabindex attribute
     */
    tabindex: PropTypes.string,
    /**
     * Text for default error message
     */
    errorMessage: PropTypes.string,
    /**
     * Initialize <input> with a value
     */
    value: PropTypes.any,
    /**
     * Label to be used for dynamic optional value
     */
    optionalLabel: PropTypes.string
  };

  static defaultProps = {
    disabled: false,
    id: formConsts.checkboxControlName,
    labelText: formConsts.checkboxLabelText,
    optionalText: formConsts.optionalText,
    name: formConsts.checkboxControlName,
    required: true,
    tabindex: formConsts.tabindex,
    errorMessage: formConsts.message
  };

  constructor(props) {
    super(props);

    this.getClassName = getClassNameFactory(CheckboxControl.displayName);

    this.refInput = React.createRef();

    this.state = {
      hasError: false
    };
  }

  updateState = (e, onChange) => {
    if (onChange) {
      return onChange(e);
    }

    if (e.target.checked) {
      this.setState({ hasError: false });
    } else {
      this.setState({ hasError: true });
    }
  };

  render() {
    const {
      checked,
      defaultChecked,
      disabled,
      id,
      labelText,
      optionalText,
      name,
      onChange,
      required,
      tabindex,
      errorMessage,
      value,
      optionalLabel
    } = this.props;

    return (
      <div
        className={toggleInvalidClass(
          this.getClassName(),
          this.props.required,
          this.state.hasError
        )}
      >
        <label htmlFor={id}>
          <input
            checked={checked}
            defaultChecked={defaultChecked}
            disabled={disabled}
            id={id}
            name={name}
            onChange={e => {
              this.updateState(e, onChange);
            }}
            required={required}
            tabIndex={tabindex}
            type="checkbox"
            value={value}
            aria-describedby={`error-${id}`}
            {...required &&
              this.state.hasError && {
                "aria-invalid": true
              }}
          />
          <span
            className={this.getClassName({
              descendantName: "labelText",
              className: typestack("p1")
            })}
          >
            {labelText}
            {!required && (
              <span
                className={this.getClassName({ descendantName: "optional" })}
              >
                {optionalLabel ? optionalLabel : optionalText}
              </span>
            )}
          </span>
          {required && (
            <span
              id={`error-${id}`}
              className={this.getClassName({
                descendantName: "message",
                className: typestack("p2")
              })}
              role="alert"
            >
              {errorMessage}
            </span>
          )}
        </label>
      </div>
    );
  }
}

export default CheckboxControl;
