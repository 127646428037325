import React from "react";
import ReactDOMServer from "react-dom/server";
import fetchMock from "fetch-mock";
import AjaxContent from "@emcm-ui/component-ajax-content";
import Block from "@emcm-ui/component-block";
import Button from "@emcm-ui/component-button";
import Form from "@emcm-ui/component-form";
import Grid from "@emcm-ui/component-grid";
import Typestack from "@emcm-ui/component-typestack";
import RichText from "@emcm-ui/component-rich-text";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";
import HomeComposition from "@emcm-ui/component-compositions/lib/compositions/design/Home";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import { storiesOf } from "@storybook/react";
import {
  boolean,
  withKnobs,
  text,
  select,
  number
} from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import Modal from "./index";
import "./Modal.css";
import ModalREADME from "../README.md";
import ModalDemoPlain from "../design-system/components/ModalDemoPlain";
import StaticRenderer from "@emcm-ui/component-static-renderer";

const colorOptions = Button.colorOptions;

const iconColorOptions = ["success", "warning", "error"];

const modalStyleOptions = ["flyInStyle", "dialogStyle"];

const infoIcon = <SVGIcon name="information-07" size="m" />;

const stories = storiesOf("Components/Modal", module);

const dialogHeight = 100;

const buttonColorOptions = [
  "primary",
  "secondary",
  "secondaryDark",
  "actionPositive"
];

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(ModalREADME));

const closeThisComponent = () => {
  // eslint-disable-next-line no-alert
  alert("Modal close button pressed ✅");
};

stories
  .add("Default", () => (
    <React.Fragment>
      <HomeComposition />

      {boolean("Open modal", true) && (
        <Modal
          title={text("Modal Title", "Modal title")}
          closeLabel={text("closeLabel", "Close")}
          closeThisComponent={closeThisComponent}
        >
          <Block
            color="green"
            text="modal content"
            // eslint-disable-next-line no-magic-numbers
            height={boolean("Long content", true) ? "1000px" : "200px"}
          />
        </Modal>
      )}
    </React.Fragment>
  ))
  .add("Rich text", () => (
    <React.Fragment>
      <HomeComposition />

      {boolean("Open modal", true) && (
        <Modal
          closeLabel={text("closeLabel", "Close")}
          closeThisComponent={closeThisComponent}
        >
          <RichText>
            <h2>Type something</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Pellentesque enim ipsum, faucibus eget ante ac, pretium
              consectetur nulla. Morbi dignissim, turpis vel finibus molestie,
              odio nisi hendrerit nisl, vitae tristique mauris arcu a leo. Proin
              eget diam congue mi vulputate interdum sit amet sed nibh. Duis
              posuere turpis eleifend lobortis lobortis. Nullam nec placerat
              nisl. Fusce viverra neque sit amet elit suscipit, et pretium
              lectus placerat. Suspendisse potenti.
            </p>
            <p>
              Aenean facilisis semper nulla id interdum. Ut sit amet orci
              vestibulum est tristique malesuada a id dui. Etiam laoreet elit
              sed nunc rhoncus, et porta ante lacinia. Duis eget odio metus.
              Donec finibus massa nunc, vitae mattis lorem vehicula eu.
              Suspendisse fermentum, nulla ut bibendum fringilla, arcu justo
              ornare ex, vel consectetur arcu quam nec orci. Nam risus tellus,
              tristique at erat non, elementum dignissim purus. Nulla at tellus
              sit amet elit semper ullamcorper.
            </p>
            <p>
              Aliquam ornare lacus id commodo suscipit. Vestibulum aliquam est
              ante. Aenean sollicitudin efficitur egestas. Mauris convallis est
              tristique suscipit placerat. Pellentesque nisi est, aliquam vel
              nulla vel, condimentum varius neque. Nulla ac nisi risus. Donec
              ultricies, est in venenatis aliquam, nisl nulla consequat odio, id
              maximus quam lorem sodales dui. Aliquam erat volutpat. Suspendisse
              massa leo, semper in erat vitae, facilisis porttitor urna. Ut
              interdum dolor massa, ut blandit sapien suscipit vel. Maecenas
              hendrerit vehicula enim, non viverra augue dapibus lobortis. Sed
              ultricies mi nec neque sollicitudin, tristique euismod elit
              maximus.
            </p>
            <p>
              Maecenas lobortis nibh a pellentesque vehicula. Vestibulum
              elementum at tellus ac porta. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. Quisque finibus, ante a viverra
              dictum, quam sapien lacinia felis, in ultricies massa purus sed
              lorem. Donec id condimentum tellus. Ut ornare ipsum ac finibus
              tempor. Nunc commodo tempor dignissim. Nunc posuere augue in velit
              consequat auctor. Suspendisse maximus laoreet est, sit amet
              venenatis velit mollis molestie. Sed finibus ex at ex sollicitudin
              dignissim. Nam bibendum finibus velit sed hendrerit. Vivamus
              scelerisque congue nisl, non convallis lectus interdum non. In
              pulvinar fermentum ullamcorper. Proin accumsan neque vel sem
              pretium, vitae convallis nisi ultricies. Maecenas quis dictum dui,
              at vestibulum dui.
            </p>
            <p>
              Duis consectetur ante id quam rutrum, vehicula tempus lorem
              sagittis. Integer mollis justo sit amet arcu condimentum sagittis.
              Suspendisse vulputate magna non neque scelerisque ultricies. Etiam
              eu dictum urna. Fusce posuere ligula ac neque mattis, in varius
              odio finibus. Donec fringilla urna eu ultricies elementum. In
              euismod lectus non consectetur malesuada. Morbi molestie, neque at
              rhoncus dictum, tortor mauris viverra nulla, a pretium lectus
              augue ut quam. Duis in augue quis orci luctus aliquam vitae eu
              tellus. Aliquam dignissim vel magna nec vehicula. Donec aliquam
              elit eu interdum viverra. Fusce sed tortor placerat, dictum nisl
              sed, placerat sapien. Aenean blandit, massa nec ultricies finibus,
              nibh libero pellentesque ante, id molestie orci erat vitae magna.
              Nulla at blandit augue. Ut semper laoreet eros, eu sollicitudin
              sem ullamcorper eu.
            </p>
          </RichText>
        </Modal>
      )}
    </React.Fragment>
  ))
  .add("Form", () => (
    <React.Fragment>
      <HomeComposition />

      <Block color="orange" text="modal content" height="100vh" />
      {boolean("Open modal", true) && (
        <Modal
          closeLabel={text("closeLabel", "Close")}
          closeThisComponent={closeThisComponent}
        >
          <Grid variant="1/3,2/3">
            <Grid.Item>
              <VerticalSpacing>
                <Typestack align="left" rank="h3" type="h3">
                  Keep learning with ONESOURCE University
                </Typestack>
              </VerticalSpacing>
              <VerticalSpacing>
                <RichText>
                  <p>
                    ONESOURCE University is an online training platform for our
                    Income Tax, Tax Provision, and WorkFlow Manager solutions.
                    Using our centralized platform, you can sign up for
                    different types of training, manage your schedule and
                    progress, and get certifications. Choose the training format
                    that works for you—from webinars and e-learning courses, to
                    hands-on workshops and live consulting services.
                  </p>
                </RichText>
              </VerticalSpacing>
              <VerticalSpacing>
                <Button
                  color="secondary"
                  href="https://example.com"
                  kind="link"
                >
                  Returning user? Sign in.
                </Button>
              </VerticalSpacing>
            </Grid.Item>
            <Grid.Item>
              <Form
                action="https://demo1129453.mockable.io/form/succeed/inline-message"
                header={
                  <Typestack align="left" rank="h5" type="h5">
                    Registration for new members
                  </Typestack>
                }
                submitButtonText="Submit request"
              >
                <VerticalSpacing size="l">
                  <Grid variant="even-2">
                    <Grid.Item>
                      <Form.TextControl
                        id="fullName"
                        labelText="Full name"
                        name="fullName"
                        required
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Form.TextControl
                        id="companyName"
                        labelText="Company name"
                        name="companyName"
                        required
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Form.TextControl
                        hashableIdentifier
                        id="corporateEmailAddress"
                        labelText="Corporate email address"
                        name="corporateEmailAddress"
                        type="email"
                        required
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Form.TextControl
                        id="fourDigitAccountNumber"
                        labelText="Four-digit account number"
                        name="fourDigitAccountNumber"
                        required
                      />
                    </Grid.Item>
                  </Grid>
                </VerticalSpacing>
                <VerticalSpacing>
                  <Form.CheckboxControl
                    id="requestAccessForOneOrMoreTeamMembers"
                    labelText="Request access for one or more team members"
                    name="requestAccessForOneOrMoreTeamMembers"
                    value="teamMemberAccessRequested"
                    required
                  />
                </VerticalSpacing>
              </Form>
            </Grid.Item>
          </Grid>
        </Modal>
      )}
    </React.Fragment>
  ))
  .add("AJAX content", () => {
    // eslint-disable-next-line no-magic-numbers
    const httpDelay = 500;
    const httpErrorCode = 1000;

    const delay = duration =>
      new Promise(resolve => setTimeout(resolve, duration));

    const endpoints = {
      "Basic content": ReactDOMServer.renderToStaticMarkup(
        <Block color="green" text="fetched content" height="200px" />
      ),
      "Long content": ReactDOMServer.renderToStaticMarkup(
        <Block color="green" text="fetched content" height="1000px" />
      ),
      "Server error": httpErrorCode
    };

    fetchMock.restore();

    Object.keys(endpoints).forEach(label => {
      const location = encodeURIComponent(label);

      fetchMock.get(location, async () => {
        await delay(httpDelay);

        return endpoints[label];
      });
    });

    const location = encodeURIComponent(
      select("Content", Object.keys(endpoints), Object.keys(endpoints)[0])
    );

    return (
      <React.Fragment>
        <HomeComposition />

        <Block color="orange" text="modal content" height="100vh" />
        {boolean("Open modal", true) && (
          <Modal
            closeLabel={text("closeLabel", "Close")}
            closeThisComponent={closeThisComponent}
          >
            <AjaxContent
              failedContent={text(
                "AjaxContent failedContent",
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus consectetur magna, luctus venenatis felis interdum eu. Suspendisse ligula justo, euismod sit amet dictum sit amet, bibendum vitae nunc."
              )}
              loadingLabel={text("AjaxContent loadingLabel", "Loading")}
              location={location}
              minHeight={parseInt(text("AjaxContent minHeight"))}
            />
          </Modal>
        )}
      </React.Fragment>
    );
  })
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <HomeComposition />

      <Block color="orange" text="modal content" height="100vh" />
      {boolean("Open modal", true) && (
        <Modal
          closeLabel={text("closeLabel", "قريب")}
          closeThisComponent={closeThisComponent}
        >
          <RichText>
            <h1>فإن الجمعية العامة</h1>
            <p>
              على أنه المستوى المشترك الذي ينبغي أن تستهدفه كافة الشعوب والأمم
              حتى يسعى كل فرد وهيئة في المجتمع، واضعين على الدوام هذا الإعلان
              نصب أعينهم، إلى توطيد احترام هذه الحقوق والحريات عن طريق التعليم
              والتربية واتخاذ إجراءات مطردة، قومية وعالمية، لضمان الإعتراف بها
              ومراعاتها بصورة عالمية فعالة بين الدول الأعضاء ذاتها وشعوب البقاع
              الخاضعة لسلطانها.
            </p>
          </RichText>
        </Modal>
      )}
    </Language>
  ))
  .add("Plain Modal Demo", () => <ModalDemoPlain />)
  .add("Action Required Modal", () => (
    <StaticRenderer>
      <div id="root">
        <div
          id="disclaimer-modal-data"
          data-show-close={boolean("Show Close", false)}
          data-close-label={text("closeLabel", "Close")}
          data-title={text("Title", "Optional Title")}
          data-close-on-away-click={boolean("Close on away click", false)}
          data-primary-button-label={text("Primary Button Label", "Accept")}
          data-primary-button-color={select(
            "Primary Button Color",
            colorOptions,
            colorOptions[0]
          )}
          data-secondary-button-label={text("Secondary Button Label", "")}
          data-secondary-button-url={text("Secondary Button URL", "")}
          data-secondary-link-label={text(
            "Secondary Link Label",
            "Secondry Link"
          )}
          data-secondary-link-url={text("Secondary Link URL", "#")}
          data-content-location="https://forge-testing.vercel.app/components/modal/lorem.html"
          role="dialog"
          data-modal-label="Action Required Modal"
        />
        <div id="modalRoot" />
      </div>
    </StaticRenderer>
  ))
  .add("Action Required Modal (RTL)", () => (
    <StaticRenderer>
      <div id="root">
        <div
          id="disclaimer-modal-data"
          data-show-close={boolean("Show Close", false)}
          data-close-label={text("closeLabel", "Close")}
          data-title={text("Title", "Optional Title")}
          data-close-on-away-click={boolean("Close on away click", false)}
          data-primary-button-label={text("Primary Button Label", "Accept")}
          data-primary-button-color={select(
            "Primary Button Color",
            colorOptions,
            colorOptions[0]
          )}
          data-secondary-button-label={text("Secondary Button Label", "")}
          data-secondary-button-url={text("Secondary Button URL", "")}
          data-secondary-link-label={text(
            "Secondary Link Label",
            "Secondry Link"
          )}
          data-secondary-link-url={text("Secondary Link URL", "#")}
          data-content-location="https://forge-testing.vercel.app/components/modal/lorem.html"
          role="dialog"
          data-modal-label="Action Required Modal"
        />
        <Language code="ar">
          <div id="modalRoot" />
        </Language>
      </div>
    </StaticRenderer>
  ))
  .add("Fly In Style", () => (
    <React.Fragment>
      <HomeComposition />

      {boolean("Open modal", true) && (
        <Modal
          closeLabel={text("closeLabel", "Close")}
          closeThisComponent={closeThisComponent}
          modalStyle={select("Modal Style", modalStyleOptions, "flyInStyle")}
          title={text("Title", "Modal Title")}
        >
          <Block
            color="green"
            text="modal content"
            height={boolean("Long content", true) ? "1000px" : "200px"}
          />
        </Modal>
      )}
    </React.Fragment>
  ))
  .add("Dialog Style", () => (
    <React.Fragment>
      <HomeComposition />

      {boolean("Open modal", true) && (
        <Modal
          closeLabel={text("closeLabel", "")}
          closeThisComponent={closeThisComponent}
          modalStyle={select("Modal Style", modalStyleOptions, "dialogStyle")}
          title={text("Title", "Dialog Title")}
          icon={infoIcon}
          status={select("Icon Type", iconColorOptions, "error")}
          closeOnAwayClick={boolean("Close on away click", false)}
          showClose={boolean("Show close", false)}
          primaryButtonLabel={text("Primary button Label", "Button")}
          primaryButtonColor={select(
            "Primary button color",
            buttonColorOptions,
            "primary"
          )}
          primaryButtonUrl={text("Primary button URL", "http://example.com")}
          secondaryButtonLabel={text("Secondary button Label", "Close")}
          secondaryButtonUrl={text("Secondary button URL")}
        >
          <Block
            color="green"
            text="modal content"
            height={`${number("Content height", dialogHeight)}px`}
          />
        </Modal>
      )}
    </React.Fragment>
  ));
