const constructAriaLabel = (ariaLabelTemplate, placeholderObj) => {
  if (Object.keys(placeholderObj).length === 0) {
    return ariaLabelTemplate;
  }

  let replacedAriaLabel = ariaLabelTemplate;

  for (const key in placeholderObj) {
    replacedAriaLabel = replacedAriaLabel.replace(
      `{${key}}`,
      placeholderObj[key]
    );
  }

  return replacedAriaLabel;
};

export default constructAriaLabel;
