import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import scrollama from "scrollama";
import classNames from "classnames";
import debounce from "lodash.debounce";
import Chapter from "./components/Chapter";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

class ChapterNavInner extends Component {
  static displayName = "Longform.ChapterNavInner";

  static propTypes = {
    children: PropTypes.node,
    spy: PropTypes.bool
  };

  static getChapters(children) {
    const chapters = [];

    React.Children.forEach(children, child => {
      if (child.type === Chapter) {
        chapters.push(child.props);
      } else if (child.type === React.Fragment) {
        chapters.push(...ChapterNavInner.getChapters(child.props.children));
      }
    });

    return chapters;
  }

  static getCurrentChapter(chapters) {
    return Math.max(chapters.findIndex(chapter => chapter.active === true), 0);
  }

  constructor(props) {
    super(props);

    this.getClassName = getClassNameFactory("LongformChapterNavInner");

    const chapters = ChapterNavInner.getChapters(props.children);

    this.state = {
      currentChapterIndex: ChapterNavInner.getCurrentChapter(chapters),
      chapters,
      expanded: false
    };

    this.scrollSpyOffset = 0.1;
    this.resizeDebounceTimeout = 200;

    this.handleBlur = this.handleBlur.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleChapterClick = this.handleChapterClick.bind(this);
    this.handleStepEnter = this.handleStepEnter.bind(this);
    this.handleStepExit = this.handleStepExit.bind(this);
    this.resizeScrollSpy = this.resizeScrollSpy.bind(this);
    this.resizeScrollSpyDebounced = debounce(
      this.resizeScrollSpy,
      this.resizeDebounceTimeout
    ).bind(this);
  }

  componentDidMount() {
    // Run on mount rather than constructor, as page need to have loaded.
    if (this.props.spy && typeof window !== "undefined") {
      this.setupScrollSpy();
    }
  }

  componentWillUnmount() {
    if (this.scroller && typeof window !== "undefined") {
      this.scroller.destroy();
      window.removeEventListener("resize", this.resizeScrollSpyDebounced);
    }
  }

  handleBlur(e) {
    // document.activeElement is IE11 fallback
    const relatedTarget = e.relatedTarget || document.activeElement;

    // This prevents blurring when the next target is within the chapter nav.
    if (this.ref && relatedTarget && this.ref.contains(relatedTarget)) {
      return;
    }

    this.setState({
      expanded: false
    });
  }

  handleClick() {
    this.setState({ expanded: !this.state.expanded });
  }

  handleChapterClick() {
    this.setState({ expanded: false });
  }

  handleStepEnter({ element }) {
    this.setState({
      // Use Math.max to ensure we don't crash if element doesn't exist
      currentChapterIndex: Math.max(
        this.state.chapters.findIndex(
          chapter => chapter.href === `#${element.id}`
        ),
        0
      )
    });
  }

  handleStepExit({ direction }) {
    if (direction === "up") {
      this.setState({
        currentChapterIndex: Math.max(0, this.state.currentChapterIndex - 1)
      });
    }
  }

  setupScrollSpy() {
    this.scroller = scrollama();

    const chapterDividerClassName = getClassNameFactory(
      "LongformChapterDivider"
    )();

    // setup the instance, pass callback functions
    this.scroller
      .setup({
        step: `.${chapterDividerClassName}`,
        offset: this.scrollSpyOffset
      })
      .onStepEnter(this.handleStepEnter)
      .onStepExit(this.handleStepExit);

    window.addEventListener("resize", this.resizeScrollSpyDebounced);
  }

  resizeScrollSpy() {
    this.scroller.resize();
  }

  render() {
    const { chapters, currentChapterIndex, expanded } = this.state;
    const currentChapter = chapters[currentChapterIndex];

    return (
      <div
        className={this.getClassName({ states: classNames({ expanded }) })}
        ref={ref => (this.ref = ref)}
        data-rehydratable={getRehydratableName(ChapterNavInner.displayName)}
        data-spy={this.props.spy ? JSON.stringify(this.props.spy) : null}
      >
        <div
          className={this.getClassName({ descendantName: "chapterContainer" })}
        >
          <button
            className={this.getClassName({
              descendantName: "currentChapter",
              className: typestack("p1")
            })}
            onClick={this.handleClick}
            onBlur={this.handleBlur}
          >
            {currentChapter.prefix ? (
              <span
                className={this.getClassName({
                  descendantName: "currentChapterPrefix",
                  utilities: "typographySmallCaps"
                })}
              >
                {currentChapter.prefix}
              </span>
            ) : null}

            <span
              className={this.getClassName({
                descendantName: "currentChapterTitle"
              })}
            >
              {currentChapter.title}
            </span>

            {expanded ? (
              <span
                className={this.getClassName({
                  descendantName: "currentChapterIcon"
                })}
              >
                <SVGIcon
                  name="caret"
                  style={{ transform: "rotate(180deg)" }}
                  size="s"
                />
              </span>
            ) : (
              <span
                className={this.getClassName({
                  descendantName: "currentChapterIcon",
                  modifiers: "--blue"
                })}
              >
                <SVGIcon name="caret" size="s" />
              </span>
            )}
          </button>

          {/* We use bubbled events on the <ol>, rather than actually setting
            <ol> as interactive, so can ignore the a11y warnings.*/}
          {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions,
            jsx-a11y/click-events-have-key-events */}
          <ol
            className={this.getClassName({ descendantName: "chapters" })}
            onClick={this.handleChapterClick} // Captures bubbled events from links
            onBlur={this.handleBlur}
            data-rehydratable-children
          >
            {chapters.map((chapter, i) => (
              <Chapter
                key={i}
                {...chapter}
                active={currentChapterIndex === i}
              />
            ))}
          </ol>
          {/* eslint-enable jsx-a11y/no-noninteractive-element-interactions,
            jsx-a11y/click-events-have-key-events */}
        </div>
      </div>
    );
  }
}

export default ChapterNavInner;
