import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, select, object } from "@storybook/addon-knobs";
import { Language } from "@emcm-ui/component-theme";
import { action } from "@storybook/addon-actions";
import withReadme from "storybook-readme/with-readme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import ReactDOMServer from "react-dom/server";
import ToggleButton from "./index";
import "./ToggleButton.css";
import ToggleButtonREADME from "../README.md";
import Block from "@emcm-ui/component-block";
import fetchMock from "fetch-mock";
import getAnalytics from "@emcm-ui/utility-analytics";

const stories = storiesOf("Components/E-Commerce/ToggleButton", module);

const analytics = getAnalytics("storybook", action);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(ToggleButtonREADME));

const delay = duration => new Promise(resolve => setTimeout(resolve, duration));
const delayTime = 500;

const alignment = ToggleButton.alignment;

const activeItem = ToggleButton.activeItem;

stories
  .add("Default", () => {
    const items = [
      {
        title: "Topic title 1",
        content: "",
        id: "topic1",
        ariaLabel: "First of two toggle"
      },
      {
        title: "Topic title 2",
        content: "",
        id: "topic2",
        ariaLabel: "second of two toggle"
      }
    ];

    return (
      <ToggleButton
        analytics={analytics}
        alignment={select("Alignment", alignment, "center")}
        items={object("Tab Items", items)}
        activeItem={"first"}
        isAjax={false}
      />
    );
  })
  .add("Language: Arabic (RTL)", () => {
    const items = [
      {
        title: "عنوان الموضوع 1",
        content: "",
        id: "topic1",
        ariaLabel: "First of two toggle"
      },
      {
        title: "عنوان الموضوع 2",
        content: "",
        id: "topic2",
        ariaLabel: "second of two toggle"
      }
    ];

    return (
      <Language code="ar">
        <ToggleButton
          analytics={analytics}
          alignment={select("Alignment", alignment, "center")}
          items={object("Tab Items", items)}
          activeItem={"first"}
          isAjax={false}
        />
      </Language>
    );
  })
  .add("Ajax Panel", () => {
    const firstlocation = "tab1.html";
    const secondLocation = "tab2.html";

    const firstResponse = ReactDOMServer.renderToStaticMarkup(
      <Block text="Panel #1 AJAXed content" color="orange" height="32em" />
    );
    const secondResponse = ReactDOMServer.renderToStaticMarkup(
      <Block text="Panel #2 AJAXed content" color="orange" height="32em" />
    );

    fetchMock.restore();
    fetchMock.get(firstlocation, async () => {
      await delay(delayTime);

      return firstResponse;
    });

    fetchMock.get(secondLocation, async () => {
      await delay(delayTime);

      return secondResponse;
    });

    const items = [
      {
        title: "Topic title 1",
        content: firstlocation,
        id: "topic1",
        ariaLabel: "First of two toggle"
      },
      {
        title: "Topic title 2",
        content: secondLocation,
        id: "topic2",
        ariaLabel: "Second of two toggle"
      }
    ];

    return (
      <ToggleButton
        analytics={analytics}
        alignment={select("Alignment", alignment, "left")}
        items={object("Tab Items", items)}
        activeItem={"first"}
        isAjax={true}
      />
    );
  })
  .add("Rehydrated", () => {
    const firstlocation = "tab1.html";
    const secondLocation = "tab2.html";

    const firstResponse = ReactDOMServer.renderToStaticMarkup(
      <Block text="Panel #1 AJAXed content" color="orange" height="32em" />
    );
    const secondResponse = ReactDOMServer.renderToStaticMarkup(
      <Block text="Panel #2 AJAXed content" color="orange" height="32em" />
    );

    fetchMock.restore();
    fetchMock.get(firstlocation, async () => {
      await delay(delayTime);

      return firstResponse;
    });

    fetchMock.get(secondLocation, async () => {
      await delay(delayTime);

      return secondResponse;
    });

    const items = [
      {
        title: "Topic title 1",
        content: firstlocation,
        id: "topic1",
        ariaLabel: "First of two toggle"
      },
      {
        title: "Topic title 2",
        content: secondLocation,
        id: "topic2",
        ariaLabel: "Second of two toggle"
      }
    ];

    return (
      <StaticRenderer>
        <ToggleButton
          analytics={analytics}
          alignment={select("Alignment", alignment, "center")}
          items={object("Tab Items", items)}
          activeItem={select("Active tab", activeItem, "first")}
          isAjax={true}
        />
      </StaticRenderer>
    );
  });
