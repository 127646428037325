import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import className from "classnames";

class CustomLegend extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: {}
    };
  }

  handleLegendClick = index => {
    const { chartInstance } = this.props;

    const meta = chartInstance.current.getDatasetMeta(index);

    meta.hidden = meta.hidden === null || null;

    chartInstance.current.update();

    this.setState({
      selected: {
        ...this.state.selected,
        [index]: !this.state.selected[index]
      }
    });
  };

  render() {
    const getClassName = getClassNameFactory(CustomLegend.displayName);
    const { legends, color } = this.props;

    const legendList = legends.map((legend, index) => {
      const boxColor = typeof color === "object" ? color[index] : color;

      const isSelected = this.state.selected[index];

      return (
        <li
          className={getClassName({
            descendantName: "legend"
          })}
          key={index}
        >
          <button
            className={getClassName({
              descendantName: "button",
              modifiers: className(isSelected ? "strike" : "")
            })}
            onClick={() => {
              this.handleLegendClick(index);
            }}
          >
            <div
              className={getClassName({
                descendantName: "color"
              })}
              style={{
                backgroundColor: boxColor
              }}
            />
            <span
              className={getClassName({
                descendantName: "label"
              })}
            >
              {legend}
            </span>
          </button>
        </li>
      );
    });

    return (
      <ul className={getClassName({ descendantName: "legends" })}>
        {legendList}
      </ul>
    );
  }
}

CustomLegend.displayName = "Chart";

CustomLegend.propTypes = {
  /**
   * legends in the chart specifies the name of chart used
   */
  legends: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  /**
   * colors in the chart specifies the color used by chart
   */
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  /**
   * getClassName in the chart specifies className
   */
  getClassName: PropTypes.func,
  /**
   * chartInstance in the chart specifies chart instance object
   */
  chartInstance: PropTypes.object
};

export default CustomLegend;
