import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import classNames from "classnames";

import Item from "./components/Item";

const DataCallout = ({
  borderAndBackground,
  children,
  horizontalContentAtDesktop
}) => {
  const getClassName = getClassNameFactory(DataCallout.displayName);

  return (
    <div
      role="list"
      className={getClassName({
        modifiers: classNames({
          borderAndBackground,
          horizontalContentAtDesktop
        })
      })}
    >
      {children}
    </div>
  );
};

DataCallout.displayName = "DataCallout";

DataCallout.propTypes = {
  /**
   * Child nodes, consisting of DataCallout.Item components.
   */
  children: PropTypes.node,
  /**
   * Optional borderless variant.
   */
  borderAndBackground: PropTypes.bool,
  /**
   * Show the items' content side by side with the text appearing next to the number.
   */
  horizontalContentAtDesktop: PropTypes.bool
};

DataCallout.defaultProps = {
  borderAndBackground: false,
  horizontalContentAtDesktop: false
};

DataCallout.Item = Item;

export default DataCallout;
