import React, { Component } from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

import { formConsts } from "../../FormConstant";

import { toggleInvalidClass } from "../../utilities";

const adjustableHeight = 5;

class TextareaControl extends Component {
  static displayName = "FormNew.TextareaControl";

  static propTypes = {
    /**
     * Set autocomplete
     */
    autoComplete: PropTypes.string,
    /**
     * Initialize <textarea> with a value
     */
    defaultValue: PropTypes.string,
    /**
     * Disable the <textarea>
     */
    disabled: PropTypes.bool,
    /**
     * Id attribute for <textarea> (and <label> `for` attribute)
     */
    id: PropTypes.string.isRequired,
    /**
     * Text for <label>
     */
    labelText: PropTypes.string.isRequired,
    /**
     * Text for optional <label>
     */
    optionalText: PropTypes.string.isRequired,
    /**
     * Max. length for <input>
     */
    maxLength: PropTypes.string,
    /**
     * Name attribute for <textarea>, required for serialisation
     */
    name: PropTypes.string.isRequired,
    /**
     * Listen for changes on the <textarea>.
     */
    onChange: PropTypes.func,
    /**
     * Set <textarea> to be required
     */
    required: PropTypes.bool,
    /**
     * tabindex attribute
     */
    tabindex: PropTypes.string,
    /**
     * Text for default error message
     */
    errorMessage: PropTypes.string,
    /**
     * Explicitly set the value of the <textarea>. Will switch to controlled mode
     */
    value: PropTypes.string,
    /**
     * Help text, used to provide hint about the field and the entry
     */
    helpText: PropTypes.string
  };

  static defaultProps = {
    autoComplete: formConsts.autocomplete,
    defaultValue: "",
    disabled: false,
    id: formConsts.textareaControlName,
    labelText: formConsts.labelText,
    optionalText: formConsts.optionalText,
    maxLength: formConsts.maxlength,
    name: formConsts.textareaControlName,
    required: true,
    tabindex: formConsts.tabindex,
    errorMessage: formConsts.message
  };

  constructor(props) {
    super(props);

    this.getClassName = getClassNameFactory(TextareaControl.displayName);

    this.state = {
      hasError: false
    };

    this.ref = React.createRef();
    this.labelRef = React.createRef();
  }

  updateSize = () => {
    const offset = 6;

    if (this.labelRef && this.labelRef.current) {
      this.ref.current.style.paddingTop = `${this.labelRef.current
        .clientHeight + offset}px`;
    }
  };

  updateState = (e, onChange) => {
    if (this.ref && this.ref.current) {
      this.updateSize();
      const scrollHeight = this.ref.current.scrollHeight;

      if (this.ref.current.clientHeight < scrollHeight) {
        this.ref.current.style.height = `${scrollHeight + adjustableHeight}px`;
      }
    }

    if (onChange) {
      return onChange(e);
    }

    if (e.target.value === "") {
      this.setState({ hasError: true });
    } else {
      this.setState({ hasError: false });
    }
  };

  render() {
    const {
      autoComplete,
      defaultValue,
      disabled,
      id,
      labelText,
      optionalText,
      maxLength,
      name,
      onChange,
      required,
      tabindex,
      errorMessage,
      value,
      helpText
    } = this.props;

    /* eslint-disable */
    return (
      <div
        className={toggleInvalidClass(
          this.getClassName(),
          this.props.required,
          this.state.hasError
        )}
        data-autocomplete={autoComplete}
        data-default-value={defaultValue}
        data-disabled={disabled}
        data-id={id}
        data-label-text={labelText}
        data-optional-text={optionalText}
        data-maxlength={maxLength}
        data-name={name}
        data-rehydratable={getRehydratableName(TextareaControl.displayName)}
        data-required={required}
        data-tabindex={tabindex}
        data-error-message={errorMessage}
        data-help-text={helpText}
      >
        <label htmlFor={id}>
          <textarea
            ref={this.ref}
            className={this.getClassName()}
            autoComplete={autoComplete}
            defaultValue={defaultValue}
            disabled={disabled}
            id={id}
            maxLength={maxLength}
            name={name}
            onChange={e => {
              this.updateState(e, onChange);
            }}
            onFocus={this.updateSize}
            placeholder={labelText}
            required={required}
            tabIndex={tabindex}
            value={value}
            {...required && {
              "aria-describedby": `error-${id}`
            }}
            {...required &&
              this.state.hasError && {
                "aria-invalid": true
              }}
          />
          <div
            ref={this.labelRef}
            className={this.getClassName({
              descendantName: "labelText",
              className: typestack("p1")
            })}
          >
            {labelText}
            {!required && (
              <span
                className={this.getClassName({ descendantName: "optional" })}
              >
                {optionalText}
              </span>
            )}
          </div>
          <div className={this.getClassName({ descendantName: "errorIcon" })}>
            <SVGIcon name="error" size="s" />
          </div>
          {required && (
            <span
              id={`error-${id}`}
              className={this.getClassName({
                descendantName: "message",
                className: typestack("p2")
              })}
              role="alert"
            >
              {errorMessage}
            </span>
          )}
          {helpText && (
            <div
              className={this.getClassName({
                descendantName: "helpText",
                className: typestack("p2")
              })}
            >
              {helpText}
            </div>
          )}
        </label>
      </div>
    );
    /* eslint-enabled */
  }
}

export default TextareaControl;
