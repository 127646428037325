import PropTypes, { bool } from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";

import MultiSelectDropdown from "@emcm-ui/component-multi-select-dropdown";
import Dropdown from "@emcm-ui/component-dropdown";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const TagFilter = ({
  index,
  handleChange,
  label,
  noOptionsMessage,
  options,
  placeholder,
  value,
  isMulti
}) => {
  const getClassName = getClassNameFactory(TagFilter.displayName);
  const name = label.replace(/\s+/g, "_") + index;

  return (
    // Control is wrapped in <label>
    <div>
      {label && (
        // eslint-disable-next-line jsx-a11y/label-has-for
        <label htmlFor={name}>
          <span
            className={getClassName({
              descendantName: "label",
              className: typestack("p1Bold")
            })}
          >
            {label}
          </span>
        </label>
      )}

      {isMulti ? (
        <MultiSelectDropdown
          handleChange={handleChange}
          name={name}
          noOptionsMessage={noOptionsMessage}
          options={options}
          placeholder={placeholder}
          value={value && value[0] ? value[0].value : ""}
        />
      ) : (
        <Dropdown
          name={name}
          block={true}
          items={options}
          size={"large"}
          value={value && value[0] ? value[0].value : false}
          placeholder={placeholder}
          onValueChanged={handleChange}
          typeAheadEnabled={true}
        />
      )}
    </div>
  );
};

TagFilter.displayName = "TableWithFilters.TagFilter";

TagFilter.propTypes = {
  /**
   * On change event handler
   */
  handleChange: PropTypes.func.isRequired,

  /**
   * Remove selected tag from available filter options
   */
  index: PropTypes.number.isRequired,

  /**
   * Label for the input
   */
  label: PropTypes.string,

  /**
   * Message displayed in the filter if there is no option resulting from the typeahead input
   */
  noOptionsMessage: PropTypes.string.isRequired,

  /**
   * Data to generate listed options
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),

  /**
   * Placeholder for the filter input
   */
  placeholder: PropTypes.string.isRequired,

  /**
   * Data to generate selected options
   */
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),

  /**
   * Boolean flag to make drop down list as multi select
   */
  isMulti: bool
};

TagFilter.defaultProps = {
  isMulti: true,
  noOptionsMessage:
    "No results found that match your search term. Please try another or check your spelling.",
  placeholder: "Add filter(s)...",
  hideSelectedOptions: false
};

export default TagFilter;
