import {
  FILTER_TOPICS_ID,
  ELASTIC_SEARCH_QUERY_KEY_PREFIX,
  ELASTIC_SEARCH_QUERY_VALUE_PREFIX
} from "./constants";

const updateLinkParameter = (paramKey, filterLinkSearchParams, updatedTags) => {
  if (updatedTags.length > 0) {
    filterLinkSearchParams.set(paramKey, updatedTags);

    return;
  }

  filterLinkSearchParams.delete(paramKey);
  filterLinkSearchParams.delete(
    paramKey.replace(
      ELASTIC_SEARCH_QUERY_VALUE_PREFIX,
      ELASTIC_SEARCH_QUERY_KEY_PREFIX
    )
  );
};

const checkForMultipleFilters = filterLinkSearchParams => {
  const firstFilterKey = `${ELASTIC_SEARCH_QUERY_KEY_PREFIX}1`;
  const secondFilterKey = `${ELASTIC_SEARCH_QUERY_KEY_PREFIX}2`;

  return (
    filterLinkSearchParams.has(firstFilterKey) &&
    filterLinkSearchParams.has(secondFilterKey)
  );
};

export const getRequestParameter = (allTags, filterKey, updatedTags) => {
  let lastAppliedFilterKey = FILTER_TOPICS_ID;

  const filterTag = allTags.find(item => item.key === filterKey);
  const filterLink = filterTag.link;
  const filterLinkSearchParams = new URLSearchParams(filterLink);

  // Check for multiple filter applied
  const isMultipleFilterApplied = checkForMultipleFilters(
    filterLinkSearchParams
  );

  for (const [paramKey, value] of filterLinkSearchParams.entries()) {
    if (value.split(",").includes(filterKey)) {
      lastAppliedFilterKey = filterLinkSearchParams.get(
        paramKey.replace(
          ELASTIC_SEARCH_QUERY_VALUE_PREFIX,
          ELASTIC_SEARCH_QUERY_KEY_PREFIX
        )
      );
      updateLinkParameter(paramKey, filterLinkSearchParams, updatedTags);
    }
  }

  const response = {
    filterLink: filterLinkSearchParams.toString(),
    isMultipleFilterApplied,
    lastAppliedFilterKey
  };

  return response;
};
