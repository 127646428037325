import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const Fieldset = ({ children, legend, optionalText, required }) => {
  const getClassName = getClassNameFactory(Fieldset.displayName);

  return (
    <fieldset className={getClassName()}>
      {legend && (
        <legend
          className={getClassName({
            descendantName: "legend",
            className: typestack("p1")
          })}
        >
          {legend}
          {!required && (
            <span className={getClassName({ descendantName: "optional" })}>
              {optionalText}
            </span>
          )}
        </legend>
      )}
      {children}
    </fieldset>
  );
};

Fieldset.displayName = "FormNew.Fieldset";

Fieldset.propTypes = {
  /**
   * Form controls composition
   */
  children: PropTypes.node.isRequired,
  /**
   * Optional legend for the fieldset (bolding supported with `<b>`)
   */
  legend: PropTypes.node,
  /**
   * Text for optional <label>
   */
  optionalText: PropTypes.string,
  /**
   * Set <input> to be required
   */
  required: PropTypes.bool
};

export default Fieldset;
