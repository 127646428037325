import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const Table = ({ size, children }) => {
  const getClassName = getClassNameFactory(Table.displayName);

  return (
    <div
      className={getClassName({
        modifiers: classNames({
          compact: size === "compact"
        }),
        className: typestack("p1")
      })}
    >
      {children}
    </div>
  );
};

Table.displayName = "Table";

Table.propTypes = {
  /**
   * Table size.
   */
  size: PropTypes.string,
  /**
   * Child nodes passed to the component.
   */
  children: PropTypes.node
};

export default Table;
