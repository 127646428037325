import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import FloatingTooltip from "./components/FloatingTooltip";
import FloatingButton from "./components/FloatingButton";
import {
  fireTooltipOnClose,
  fireFloatingButtonOpen,
  fireFloatingButtonMount,
  fireFloatingButtonMinimise,
  loadHSC
} from "./utilities";

class FloatingBadge extends Component {
  static displayName = "FloatingBadge";

  constructor(props) {
    super(props);
    this.floatingBadgeAnalytics = {
      analyticsData: {
        event: "floatingBadgeInteraction",
        component: {
          info: {
            interactionType: "",
            location: window.location.href,
            name: "floatingBadge"
          },
          category: {
            primary: "floatingBadge"
          }
        }
      },
      interactionType: {
        close: "floatingBadge| close",
        open: "floatingBadge| open",
        view: "floatingBadge| view",
        minimise: "floatingBadge| minimise"
      }
    };
    this.state = {
      tooltipStatus: true,
      showFloatingButton: false,
      buttonDisable: true,
      embedUrlLoad: false,
      analyticsData: this.floatingBadgeAnalytics.analyticsData
    };
    this.floatBadge = null;
    this.getClassName = getClassNameFactory(FloatingBadge.displayName);
    this.flotBadgeRef = React.createRef();
    this.tooltipRef = React.createRef();
  }

  componentDidMount() {
    this.onEmbed();
    if (this.flotBadgeRef.current) {
      this.flotBadgeRef.current.setAttribute("tabIndex", "0");
    }
    if (this.tooltipRef.current) {
      this.tooltipRef.current.setAttribute("tabIndex", "0");
    }
  }

  onEmbed = async () => {
    const { embedUrl } = this.props;

    try {
      this.floatBadge = await loadHSC(embedUrl);
      if (this.floatBadge) {
        const fetchTooltipStatus = this.floatBadge.getTooltipStatus();

        this.setState(
          prevState => ({
            embedUrlLoad: true,
            tooltipStatus: fetchTooltipStatus,
            buttonDisable: false,
            analyticsData: {
              ...prevState.analyticsData,
              component: {
                info: {
                  ...prevState.analyticsData.component.info,
                  interactionType: this.floatingBadgeAnalytics.interactionType
                    .view
                },
                category: {
                  ...prevState.analyticsData.component.category
                }
              }
            }
          }),
          () => {
            fireFloatingButtonMount(this.state.analyticsData);
          }
        );
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error loading FloatBadge:", error);
    }
  };

  onBadgePress = () => {
    const { showFloatingButton, embedUrlLoad } = this.state;

    if (showFloatingButton && embedUrlLoad) {
      this.setState(
        prevState => ({
          analyticsData: {
            ...prevState.analyticsData,
            component: {
              info: {
                ...prevState.analyticsData.component.info,
                interactionType: this.floatingBadgeAnalytics.interactionType
                  .minimise
              },
              category: {
                ...prevState.analyticsData.component.category
              }
            }
          }
        }),
        () => {
          fireFloatingButtonMinimise(this.state.analyticsData);
        }
      );
      this.floatBadge.setHeight();
    } else {
      this.setState(
        prevState => ({
          tooltipStatus: false,
          showFloatingButton: !prevState.showFloatingButton,
          analyticsData: {
            ...prevState.analyticsData,
            component: {
              info: {
                ...prevState.analyticsData.component.info,
                interactionType: this.floatingBadgeAnalytics.interactionType
                  .open
              },
              category: {
                ...prevState.analyticsData.component.category
              }
            }
          }
        }),
        () => {
          fireFloatingButtonOpen(this.state.analyticsData);
        }
      );
      if (embedUrlLoad) {
        this.floatBadge.showContainer(this.props, this.onBadgeClose);
      }
    }
  };

  onTooltipClose = () => {
    const { embedUrlLoad } = this.state;

    this.setState(
      prevState => ({
        tooltipStatus: !prevState.tooltipStatus,
        analyticsData: {
          ...prevState.analyticsData,
          component: {
            info: {
              ...prevState.analyticsData.component.info,
              interactionType: this.floatingBadgeAnalytics.interactionType.close
            },
            category: {
              ...prevState.analyticsData.component.category
            }
          }
        }
      }),
      () => {
        fireTooltipOnClose(this.state.analyticsData);
      }
    );

    if (embedUrlLoad) {
      this.floatBadge.setTooltipStatus();
    }
  };

  onBadgeClose = () => {
    this.setState(prevState => ({
      showFloatingButton: !prevState.showFloatingButton
    }));
  };

  static propTypes = {
    /**
     * Tooltip texts for Floating-Button
     */
    tooltipText: PropTypes.string,
    /**
     * Icon to show inside the Floating-Button
     */
    icon: PropTypes.string,
    /**
     * Landing page URL for show after click of the Floating-Button
     */
    landpageUrl: PropTypes.string,
    /**
     * Product Name text
     */
    productName: PropTypes.string,
    /**
     * Source system text
     */
    sourceSystem: PropTypes.string,
    /**
     * Type text
     */
    type: PropTypes.string,
    /**
     * ClientID
     */
    clientID: PropTypes.string,
    /**
     * Header Text for the container
     */
    headerText: PropTypes.string,
    /**
     * External link Url for load inside this component
     */
    embedUrl: PropTypes.string.isRequired,
    /**
     * source System info object
     */
    sourceMetadata: PropTypes.object
  };

  render() {
    const {
      tooltipText,
      icon,
      landpageUrl,
      sourceSystem,
      productName,
      type,
      clientID,
      headerText,
      embedUrl,
      sourceMetadata
    } = this.props;
    const { tooltipStatus, showFloatingButton, buttonDisable } = this.state;

    return (
      <div
        data-tooltiptext={tooltipText}
        data-icon={icon}
        data-landpage={landpageUrl}
        data-source={sourceSystem}
        data-product={productName}
        data-type={type}
        data-clientid={clientID}
        data-headertext={headerText}
        data-embedscript={embedUrl}
        data-source-metadata={JSON.stringify(sourceMetadata)}
        className={this.getClassName()}
        data-rehydratable={getRehydratableName(FloatingBadge.displayName)}
      >
        <div
          ref={this.flotBadgeRef}
          className={this.getClassName({ descendantName: "container" })}
          data-rehydratable-children
          aria-label={`${headerText} button`}
        >
          {tooltipStatus && (
            <FloatingTooltip
              tooltipRef={this.tooltipRef}
              tooltipText={tooltipText}
              handleTooltip={this.onTooltipClose}
            />
          )}
          <FloatingButton
            icon={icon}
            handleClick={this.onBadgePress}
            showFloatingButton={showFloatingButton}
            disabled={buttonDisable}
            headerText={headerText}
          />
        </div>
      </div>
    );
  }
}

export default FloatingBadge;
