import React from "react";
import InputfieldCounter from "./InputfieldCounter";

export default async (domNode, rehydrateChildren) => {
  const childrenNode = domNode.querySelector("[data-rehydratable-children]");
  const reactifiedChildren = await rehydrateChildren(childrenNode);

  const props = {
    content: domNode.getAttribute("data-content"),
    arialabel: domNode.getAttribute("data-arialabel"),
    variant: domNode.getAttribute("data-variant"),
    inputId: domNode.getAttribute("data-inputid"),
    subtitle: domNode.getAttribute("data-subtitle"),
    changeValue: parseInt(domNode.getAttribute("data-changevalue")),
    increasedArialabel: domNode.getAttribute("data-increased-arialabel"),
    decreasedArialabel: domNode.getAttribute("data-decreased-arialabel"),
    maxlength: parseInt(domNode.getAttribute("data-maxlength"))
  };

  return <InputfieldCounter {...props}>{reactifiedChildren}</InputfieldCounter>;
};
