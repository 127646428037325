import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import classNames from "classnames";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const DownloadButton = ({
  block,
  children,
  disabled,
  fileName,
  fileSize,
  fileType,
  href,
  icon,
  isDark,
  newWindow
}) => {
  const getClassName = getClassNameFactory(DownloadButton.displayName);

  const linkProps = {
    className: getClassName({
      modifiers: classNames({
        block: block ? "block" : null,
        dark: isDark
      }),
      states: classNames({
        disabled
      })
    }),
    download: fileName,
    href
  };

  if (newWindow) {
    linkProps.target = "_blank";
    linkProps.rel = "noopener";
  }

  return (
    <a {...linkProps}>
      <div className={getClassName({ descendantName: "inner" })}>
        <div
          className={getClassName({
            descendantName: "body",
            className: typestack("p1Bold")
          })}
        >
          <div>{children}</div>
          {fileType ? (
            <div
              className={getClassName({
                descendantName: "fileInfo",
                className: typestack("p2")
              })}
            >
              <span className={getClassName({ descendantName: "fileType" })}>
                {fileType}
              </span>
              <span className={getClassName({ descendantName: "fileSize" })}>
                {` - ${fileSize || ""}`}
              </span>
            </div>
          ) : null}
        </div>
        <div
          aria-hidden="true"
          className={getClassName({ descendantName: "icon" })}
        >
          {icon}
        </div>
      </div>
    </a>
  );
};

DownloadButton.displayName = "DownloadButton";

DownloadButton.propTypes = {
  block: PropTypes.bool,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  isDark: PropTypes.bool,
  fileName: PropTypes.string,
  fileSize: PropTypes.string,
  fileType: PropTypes.string,
  href: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  newWindow: PropTypes.bool
};

export default DownloadButton;
