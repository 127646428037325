import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import HorizontalOverflow from "@emcm-ui/component-horizontal-overflow";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const TabListWrapper = ({ children, skipOverflow }) => {
  const getClassName = getClassNameFactory("TabsTabList");

  return skipOverflow ? (
    <div className={getClassName({ descendantName: "wrap" })}>{children}</div>
  ) : (
    <HorizontalOverflow>{children}</HorizontalOverflow>
  );
};

TabListWrapper.propTypes = {
  children: PropTypes.node,
  skipOverflow: PropTypes.bool
};

const TabList = ({ children, layoutVariant }) => {
  const getClassName = getClassNameFactory("TabsTabList");

  return (
    <div className={getClassName({ modifiers: classNames(layoutVariant) })}>
      <TabListWrapper skipOverflow={layoutVariant === "wrapped"}>
        <ul role="tablist" className={getClassName({ descendantName: "list" })}>
          {children}
        </ul>
      </TabListWrapper>
    </div>
  );
};

TabList.propTypes = {
  /**
   * The children of this `TabList`. Should be of type `Tab`
   */
  children: PropTypes.node,

  /**
   * Optional modifier to switch layout variants
   */
  layoutVariant: PropTypes.oneOf(["verticalAtDesktop", "wrapped"])
};

TabList.displayName = "Tabs.TabList";

export default TabList;
