import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const RichTextGrid = ({ label, description, link, item }) => {
  const getClassName = getClassNameFactory("RichTextGrid");

  const richtText = (itemLabel, itemDescription, itemLink) => {
    return (
      <React.Fragment key={itemLabel}>
        <div
          className={getClassName({
            descendantName: "label",
            className: typestack("p1Bold")
          })}
        >
          {itemLabel}
        </div>
        <div
          className={getClassName({
            descendantName: "description",
            className: typestack("p1")
          })}
        >
          {itemDescription}
          {itemLink && (
            <div className={getClassName({ descendantName: "link" })}>
              {itemLink}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className={getClassName()}>
      {item
        ? item.map(element => {
            return richtText(element.label, element.description, element.link);
          })
        : richtText(label, description, link)}
    </div>
  );
};

RichTextGrid.propTypes = {
  /**
   * The label of the RichtextGrid.
   */
  label: PropTypes.string,
  /**
   * The Description of the RichtextGrid.
   */
  description: PropTypes.string,
  /**
   * Multiple Item in a Single Row.
   */
  item: PropTypes.array,
  /**
   * The Link of the RichtextGrid.
   */
  link: PropTypes.node
};

RichTextGrid.displayName = "RichTextGrid";

export default RichTextGrid;
