import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import classNames from "classnames";

import BlockLink from "./components/BlockLink";
import Column from "./components/Column";
import Group from "./components/Group";
import Link from "./components/Link";

const ListOfLinks = ({ children, columns, topBorder }) => {
  const getClassName = getClassNameFactory(ListOfLinks.displayName);

  return (
    <div
      className={getClassName({
        className: topBorder ? "u-topBorder" : ""
      })}
    >
      <div className={getClassName({ descendantName: "inner" })}>
        <ul
          className={getClassName({
            descendantName: "items",
            modifiers: classNames({ [`col-${columns}`]: true })
          })}
        >
          {children}
        </ul>
      </div>
    </div>
  );
};

ListOfLinks.displayName = "ListOfLinks";

ListOfLinks.propTypes = {
  /**
   * Children to render. One of Link, BlockLink, Group or Column.
   */
  children: PropTypes.node,
  /**
   * Number of columns to render.
   */
  columns: PropTypes.oneOf([1, 2, 3, 4]), // eslint-disable-line no-magic-numbers
  /**
   * Render an optional border at the top of the ListOfLinks.
   */
  topBorder: PropTypes.bool
};

ListOfLinks.defaultProps = {
  columns: 1,
  topBorder: false
};

ListOfLinks.BlockLink = BlockLink;
ListOfLinks.Column = Column;
ListOfLinks.Group = Group;
ListOfLinks.Link = Link;

export default ListOfLinks;
