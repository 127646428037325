import React from "react";
import {
  ArticleCardFooter,
  PairedCard,
  TextCard,
  TwitterCard,
  LinkCardFooter
} from "@emcm-ui/component-card";
import Block from "@emcm-ui/component-block";
import Grid from "@emcm-ui/component-grid";
import Heading from "@emcm-ui/component-heading";
import Image from "@emcm-ui/component-image";
import Link from "@emcm-ui/component-link";
import ListOfLinks from "@emcm-ui/component-list-of-links";
import MarkupContainer from "@emcm-ui/component-markup-container";
import Poster from "@emcm-ui/component-poster";
import RichText from "@emcm-ui/component-rich-text";
import Section from "@emcm-ui/component-section";
import SemanticMain from "@emcm-ui/component-semantic-main";
import Tile from "@emcm-ui/component-tile";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";
import VideoPlayer from "@emcm-ui/component-video-player";

import articleLadyJustice from "../../sampleAssets/Article-lady-justice.jpg";
import articleLadyJusticeMobile from "../../sampleAssets/Article-lady-justice.mob.jpg";
import Icon from "@emcm-ui/component-icon";

import imgLarge7 from "../../sampleAssets/large_7.jpg";
import placeholder960 from "../../sampleAssets/placeholder-images/960x540.png";
import placeholder1920 from "../../sampleAssets/placeholder-images/1920x1080.png";
import placeholder3840 from "../../sampleAssets/placeholder-images/3840x2160.png";
import SampleSchemaOrganization from "../../fragments/schema-organization";
import SampleFooter from "../../fragments/footer";
import SampleHeader from "../../fragments/header";

const Icons = {
  home: "./icons/nucleo/energy-environment/large/home.svg",
  atom: "./icons/nucleo/healthcare-medical/large/atom.svg",
  carFront: "./icons/nucleo/transportation/large/car-front.svg",
  flaskConical: "./icons/nucleo/healthcare-medical/large/flask-conical.svg",
  time: "./icons/ui/grey/time-07.svg",
  arrowRightBlue: "./icons/ui/blue/arrow-right.svg",
  plus: "./icons/ui/blue/plus.svg"
};

const AboutUs = () => {
  return (
    <div>
      <SampleSchemaOrganization />
      <SampleHeader />

      <SemanticMain>
        <Section background="grayDark" fullWidth>
          <Poster
            height="l"
            imageOverlay="scrimBottomDark"
            imageSrc={articleLadyJustice}
            imageSrcSet={`${articleLadyJusticeMobile} 768w, ${articleLadyJustice} 2048w`}
            alignBottom
          >
            <Grid variant="7/12,5/12" gutterlessAtNarrow>
              <Grid.Item>
                <Heading type="xl" rank="1">
                  <strong>Three ways the legal world is changing</strong>
                </Heading>
              </Grid.Item>
            </Grid>
          </Poster>
        </Section>
        <Section background="grayLightest">
          <VerticalSpacing size="xl">
            <Heading type="l">
              Providing answer across <strong>each industry</strong>
            </Heading>
          </VerticalSpacing>
          <Grid variant="even-3">
            <Grid.Item>
              <TextCard
                description="Make the tough decisions with confidence, using unrivaled tools to manage risk and reduce cost in the supply chain."
                footer={
                  <LinkCardFooter
                    title="Learn more"
                    type="icon"
                    icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  />
                }
                heading="Automotive"
                href="https://www.example.com"
                size="medium"
              />
            </Grid.Item>
            <Grid.Item>
              <TextCard
                description="Solutions that combine breadth of domain intelligence, technology, and human expertise to help empower you on your mission."
                footer={
                  <LinkCardFooter
                    title="Learn more"
                    type="icon"
                    icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  />
                }
                heading="Government"
                href="https://www.example.com"
                size="medium"
              />
            </Grid.Item>
            <Grid.Item>
              <TextCard
                description="Unrivaled legal solutions that integrate content, expertise, and technologies."
                footer={
                  <LinkCardFooter
                    title="Learn more"
                    type="icon"
                    icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  />
                }
                heading="Legal"
                href="https://www.example.com"
                size="medium"
              />
            </Grid.Item>
            <Grid.Item>
              <TextCard
                description="Build and engage your audience with real-time breaking news and high-impact global multimedia content."
                footer={
                  <LinkCardFooter
                    title="Learn more"
                    type="icon"
                    icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  />
                }
                heading="News"
                href="https://www.example.com"
                size="medium"
              />
            </Grid.Item>
            <Grid.Item>
              <TextCard
                description="Supporting corporate governance, addressing risks, and ensuring compliance for your organization."
                footer={
                  <LinkCardFooter
                    title="Learn more"
                    type="icon"
                    icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  />
                }
                heading="Risk Management"
                href="https://www.example.com"
                size="medium"
              />
            </Grid.Item>
            <Grid.Item>
              <TextCard
                description="Powerful tax and accounting solutions to make your work easier, faster, and more profitable."
                footer={
                  <LinkCardFooter
                    title="Learn more"
                    type="icon"
                    icon={<Icon path={Icons.plus} size="s" />}
                  />
                }
                heading="Tax & Accounting"
                href="https://www.example.com"
                size="medium"
              />
            </Grid.Item>
          </Grid>
        </Section>
        <Section background="grayLighter">
          <Block text="Map Element here" color="blue" height="32em" />
        </Section>
        <Section>
          <VerticalSpacing size="xl">
            <Heading type="l">
              <strong>Our values</strong> shape our culture
            </Heading>
          </VerticalSpacing>
          <Grid variant="even-4" gutterBorder>
            <Grid.Item>
              <Tile
                description="When you tap into our unique information ecosystem, you're getting the intelligence you need to find trusted answers"
                headingRank="2"
                href="https://example.com"
                icon={<Icon path={Icons.home} size="l" />}
                linkTitle="Trust principles"
                linkIcon={<Icon path={Icons.arrowRightBlue} size="s" />}
                title="Trust"
                type="centeredIcon"
              />
            </Grid.Item>
            <Grid.Item>
              <Tile
                description="Thomson Reuters Labs™ around the world collaborate with customers to deliver world-class solutions"
                headingRank="2"
                href="https://example.com"
                icon={<Icon path={Icons.atom} size="l" />}
                linkTitle="Innovation labs"
                linkIcon={<Icon path={Icons.arrowRightBlue} size="s" />}
                title="Innovation"
                type="centeredIcon"
              />
            </Grid.Item>
            <Grid.Item>
              <Tile
                description="Our diverse viewpoints and unique abilities offer added value to our customers"
                headingRank="2"
                href="https://example.com"
                icon={<Icon path={Icons.carFront} size="l" />}
                linkTitle="All case studies"
                linkIcon={<Icon path={Icons.arrowRightBlue} size="s" />}
                title="Partnership"
                type="centeredIcon"
              />
            </Grid.Item>
            <Grid.Item>
              <Tile
                description="We excel at work that positively impacts the world, while delivering results our partners can see"
                headingRank="2"
                href="https://example.com"
                icon={<Icon path={Icons.flaskConical} size="l" />}
                linkTitle="All products"
                linkIcon={<Icon path={Icons.arrowRightBlue} size="s" />}
                title="Performance"
                type="centeredIcon"
              />
            </Grid.Item>
          </Grid>
        </Section>
        <Section background="grayLightest">
          <VerticalSpacing size="xl">
            <Heading type="l">
              The expertise you need to find <strong>trusted answers</strong>
            </Heading>
          </VerticalSpacing>
          <Grid variant="even-3">
            <Grid.Item>
              <PairedCard
                borderVariant="borderless"
                href="https://www.example.com"
                eyebrow="Articles"
                heading="Mercury rising. The heavy social and environmental costs of illegal mining"
                footer={
                  <ArticleCardFooter
                    attributionName="Sophia P."
                    dateTime="2016-02-05 13:57"
                    readingTime="1 min"
                    icon={<Icon path={Icons.time} size="xs" />}
                  />
                }
                imgSrc={imgLarge7}
                imgAnchor="C"
              />
            </Grid.Item>
            <Grid.Item>
              <PairedCard
                borderVariant="borderless"
                href="https://www.example.com"
                eyebrow="Articles"
                heading="Mercury rising. The heavy social and environmental costs of illegal mining"
                footer={
                  <ArticleCardFooter
                    attributionName="Sophia P."
                    dateTime="2016-02-05 13:57"
                    readingTime="1 min"
                    icon={<Icon path={Icons.time} size="xs" />}
                  />
                }
                imgSrc={imgLarge7}
                imgAnchor="C"
              />
            </Grid.Item>
            <Grid.Item>
              <MarkupContainer>
                <TwitterCard
                  borderVariant="borderless"
                  timelineDataSource={{
                    sourceType: "profile",
                    screenName: "westlaw"
                  }}
                  eyebrow="Featured tweets from @westlaw"
                />
              </MarkupContainer>
            </Grid.Item>
          </Grid>
        </Section>
        <Section>
          <Block text="Ask the Expert here" color="blue" height="32em" />
        </Section>
        <Section background="grayLighter">
          <Block text="Meet our team here" color="blue" height="16em" />
        </Section>
        <Section>
          <VerticalSpacing size="xl">
            <Heading type="l">
              Opportunities on a <strong>global scale</strong>
            </Heading>
          </VerticalSpacing>
          <Grid variant="1/3,2/3">
            <Grid.Item>
              <VerticalSpacing>
                <Heading type="m">What’s it like #WorkingAtTR</Heading>
              </VerticalSpacing>
              <VerticalSpacing>
                <MarkupContainer>
                  <VideoPlayer
                    description="Hear some of our employees talk about how they bring their work and passions together at Thomson Reuters."
                    duration="PT3M47S"
                    kind="vimeo"
                    name="What's it like #WorkingAtTR"
                    playLabel="play video"
                    thumbnailUrl={`https://www.example.com${placeholder960}`}
                    uploadDate="2017-04-01"
                    videoId="114765613"
                  >
                    <Image
                      alt="Placeholder image"
                      anchor="C"
                      srcSet={`${placeholder960} 960w, ${placeholder1920} 1920w, ${placeholder3840} 3840w`}
                      fit="cover"
                    />
                  </VideoPlayer>
                </MarkupContainer>
              </VerticalSpacing>
              <VerticalSpacing>
                <RichText>
                  <p>
                    Hear some of our employees talk about how they bring their
                    work and passions together at Thomson Reuters.
                  </p>
                </RichText>
              </VerticalSpacing>
              <VerticalSpacing>
                <Link
                  href="https://example.com"
                  type="icon"
                  icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  iconPosition={"right"}
                >
                  Our company culture
                </Link>
              </VerticalSpacing>
            </Grid.Item>
            <Grid.Item>
              <VerticalSpacing>
                <Heading type="m">Join our Team</Heading>
              </VerticalSpacing>
              <ListOfLinks columns={2}>
                <ListOfLinks.Column>
                  <ListOfLinks.BlockLink
                    size="small"
                    href="https://www.example.com"
                    description="New York  •  3 openings"
                  >
                    Legal product developer
                  </ListOfLinks.BlockLink>
                  <ListOfLinks.BlockLink
                    size="small"
                    href="https://www.example.com"
                    description="Toronto  •  1 opening"
                  >
                    Sales specialist, legal information solutions (graduate)
                  </ListOfLinks.BlockLink>
                  <ListOfLinks.BlockLink
                    size="small"
                    href="https://www.example.com"
                    description="Mumbai  •  2 openings"
                  >
                    Legal document automation consultant
                  </ListOfLinks.BlockLink>
                </ListOfLinks.Column>
                <ListOfLinks.Column>
                  <ListOfLinks.BlockLink
                    size="small"
                    href="https://www.example.com"
                    description="New York  •  3 openings"
                  >
                    Industry practice group market lead, legal
                  </ListOfLinks.BlockLink>
                  <ListOfLinks.BlockLink
                    size="small"
                    href="https://www.example.com"
                    description="New York  •  1 opening"
                  >
                    Product manager (legal subscriptions portfolio manager -
                    primary law)
                  </ListOfLinks.BlockLink>
                  <ListOfLinks.BlockLink
                    size="small"
                    href="https://www.example.com"
                    description="New York  •  2 openings"
                  >
                    Legal product developer - Westlaw
                  </ListOfLinks.BlockLink>
                </ListOfLinks.Column>
              </ListOfLinks>
            </Grid.Item>
          </Grid>
        </Section>
      </SemanticMain>

      <SampleFooter />
    </div>
  );
};

export default AboutUs;
