import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";

import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const EmptyCart = ({ emptyCartText }) => {
  const getClassName = getClassNameFactory(EmptyCart.displayName);

  return (
    <p
      className={getClassName({
        className: typestack("p1")
      })}
    >
      {emptyCartText}
    </p>
  );
};

EmptyCart.displayName = "EmptyCart";

EmptyCart.propTypes = {
  emptyCartText: PropTypes.string
};

export default EmptyCart;
