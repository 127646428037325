import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

class NavItem extends Component {
  static displayName = "SiteHeader.NavItem";

  static propTypes = {
    /**
     * The description of this `NavItem`
     */
    description: PropTypes.string,

    /**
     * The destination that this `NavItem` links to
     */
    href: PropTypes.string.isRequired,

    /**
     * external icon for `NavItem` link
     */
    isExternal: PropTypes.bool,

    /**
     * Whether or not this is a summary item, like "All Products ->"
     */
    summary: PropTypes.bool,

    /**
     * The title of this `NavItem`
     */
    title: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.getClassName = getClassNameFactory("SiteHeaderNavItem");
  }

  render() {
    const { description, href, isExternal, summary, title } = this.props;

    return (
      <li
        className={this.getClassName({
          modifiers: classNames({
            summary
          })
        })}
      >
        <a
          className={this.getClassName({ descendantName: "link" })}
          href={href}
          role="menuitem"
          onMouseOver={this.enter}
          onMouseOut={this.leave}
          onFocus={this.enter}
          onBlur={this.leave}
        >
          <span
            className={this.getClassName({
              descendantName: "linkInner",
              className: typestack("p1Bold")
            })}
          >
            <span className={this.getClassName({ descendantName: "linkText" })}>
              {title}
            </span>
            {isExternal && (
              <span
                className={this.getClassName({ descendantName: "linkIcon" })}
              >
                <SVGIcon
                  name={"externallink"}
                  size="xs"
                  viewBox="0 0 16 16"
                  title={"external link"}
                />
              </span>
            )}
            {summary && (
              <span
                className={this.getClassName({ descendantName: "linkIcon" })}
              >
                <SVGIcon
                  name={"arrow"}
                  size="s"
                  style={{ transform: "rotate(270deg)" }}
                />
              </span>
            )}
          </span>
          {description &&
            !summary && (
              <span
                className={this.getClassName({
                  descendantName: "linkDescription",
                  className: typestack("p3")
                })}
              >
                {description}
              </span>
            )}
        </a>
      </li>
    );
  }
}

export default NavItem;
