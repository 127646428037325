import React from "react";
import PropTypes from "prop-types";
import { storiesOf } from "@storybook/react";
import {
  withKnobs,
  text,
  number,
  select,
  boolean
} from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import { Language } from "@emcm-ui/component-theme";
import Heading from "@emcm-ui/component-heading";
import ContinuousTicker from "./index";
import ContinuousTickerREADME from "../README.md";
import "./index.css";

const stories = storiesOf("Components/ContinuousTicker", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(ContinuousTickerREADME));

const DEFAULT_SPEED = 5;
const modeOptions = ["chain", "smooth", "wait"];
const offsetOptions = [0, "25%", "50%", "75%", "100%", "run-in"];
const directionOptions = ["toLeft", "toRight"];

class ContinuousTickerWrapper extends React.Component {
  // this component is needed to unmount and remount TWF because the data that gets passed
  // through gets stored in state. So any subsequent change to data doesn't cause a re-render.
  // To prevent unwanted side affects to TWF we have this helper in the storybook to show

  static propTypes = {
    children: PropTypes.func,
    offset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    mode: PropTypes.string,
    speed: PropTypes.number,
    text: PropTypes.string,
    direction: PropTypes.string,
    updating: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      offset: props.offset,
      mode: props.mode,
      speed: props.speed,
      text: props.text,
      direction: props.direction,
      updating: false
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.offset !== prevState.offset ||
      nextProps.mode !== prevState.mode ||
      nextProps.text !== prevState.text ||
      nextProps.speed !== prevState.speed ||
      nextProps.direction !== prevState.direction
    ) {
      return {
        ...nextProps,
        updating: true
      };
    }

    return null;
  }

  componentDidUpdate() {
    if (this.state.updating === true) {
      this.setState(() => ({ updating: false }));
    }
  }

  componentDidMount() {
    this.setState(() => ({ updating: false }));
  }

  render() {
    if (this.state.updating) {
      return null;
    }

    return this.props.children({ ...this.state });
  }
}

stories
  .add("Default", () => (
    <ContinuousTickerWrapper
      offset={select("Offset", offsetOptions, 0)}
      mode={select("Mode", modeOptions, "chain")}
      speed={number("Speed", DEFAULT_SPEED)}
      text={text("Headline", "Lorem ipsum dolor sit amet +-+ ")}
      direction={select("Direction", directionOptions, "toLeft")}
    >
      {({ offset, mode, speed, direction, text: innerText }) => (
        <ContinuousTicker
          offset={offset}
          mode={mode}
          speed={speed}
          direction={direction}
          move={!boolean("Pause Ticker", false)}
        >
          {() => (
            <span style={{ whiteSpace: "nowrap" }}>
              <Heading rank="2" type="l">
                {innerText}
              </Heading>
            </span>
          )}
        </ContinuousTicker>
      )}
    </ContinuousTickerWrapper>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <ContinuousTickerWrapper
        offset={select("Offset", offsetOptions, "run-in")}
        mode={select("Mode", modeOptions, "chain")}
        speed={number("Speed", DEFAULT_SPEED)}
        text={text("Text", "مرحبا بالعالم")}
        direction={select("Direction", directionOptions, "toRight")}
      >
        {({ offset, mode, speed, direction, text: innerText }) => (
          <ContinuousTicker
            offset={offset}
            mode={mode}
            speed={speed}
            direction={direction}
            move={!boolean("Pause Ticker", false)}
          >
            {() => (
              <span style={{ whiteSpace: "nowrap" }}>
                <Heading rank="2" type="l">
                  {innerText}
                </Heading>
              </span>
            )}
          </ContinuousTicker>
        )}
      </ContinuousTickerWrapper>
    </Language>
  ))
  .add("Rehydrated", () => (
    <ContinuousTickerWrapper
      offset={select("Offset", offsetOptions, "run-in")}
      mode={select("Mode", modeOptions, "chain")}
      speed={number("Speed", DEFAULT_SPEED)}
      text={text("Headline", "Lorem ipsum dolor sit amet +-+ ")}
      direction={select("Direction", directionOptions, "toLeft")}
    >
      {({ offset, mode, speed, direction, text: innerText }) => (
        <StaticRenderer>
          <ContinuousTicker
            offset={offset}
            mode={mode}
            speed={speed}
            direction={direction}
            move={!boolean("Pause Ticker", false)}
          >
            {() => (
              <span style={{ whiteSpace: "nowrap" }}>
                <Heading rank="2" type="l">
                  {innerText}
                </Heading>
              </span>
            )}
          </ContinuousTicker>
        </StaticRenderer>
      )}
    </ContinuousTickerWrapper>
  ));
