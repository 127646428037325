import PropTypes from "prop-types";
import React from "react";
import className from "classnames";

import Eyebrow from "@emcm-ui/component-eyebrow";
import Standfirst from "@emcm-ui/component-standfirst";

import getClassNameFactory from "@emcm-ui/utility-class-names";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const Header = ({
  align,
  callToAction,
  eyebrow,
  heading,
  standfirst,
  timestamp
}) => {
  const getClassName = getClassNameFactory(Header.displayName);

  return (
    <div
      className={getClassName({
        modifiers: className({ [align]: align !== "left" })
      })}
    >
      {eyebrow && (
        <div className={getClassName({ descendantName: "eyebrow" })}>
          <Eyebrow text={eyebrow} />
        </div>
      )}
      <div className={getClassName({ descendantName: "heading" })}>
        {heading}
        {timestamp && (
          <p
            className={getClassName({
              descendantName: "timestamp",
              className: typestack("subline2")
            })}
          >
            {timestamp}
          </p>
        )}
      </div>
      {standfirst && (
        <div className={getClassName({ descendantName: "standfirst" })}>
          <Standfirst>{standfirst}</Standfirst>
        </div>
      )}
      {callToAction && (
        <div className={getClassName({ descendantName: "callToAction" })}>
          {callToAction}
        </div>
      )}
    </div>
  );
};

Header.alignments = ["center", "left"];

Header.defaultProps = { align: "left" };

Header.displayName = "Header";

Header.propTypes = {
  align: PropTypes.oneOf(Header.alignments),
  eyebrow: PropTypes.string,
  heading: PropTypes.node.isRequired,
  callToAction: PropTypes.node,
  standfirst: PropTypes.node,
  timestamp: PropTypes.node
};

export default Header;
