import React from "react";
import { storiesOf } from "@storybook/react";
import {
  withKnobs,
  text,
  number,
  object,
  boolean
} from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import SearchResultCards from "./index";
import SearchResultsMixtureREADME from ".../../../README.md";
import * as featuredData from "../../../__mocks__/featuredMock.json";
import * as searchData from "../../../__mocks__/searchMock.json";
import { ICON } from "../../utilities/constants";

const stories = storiesOf(
  "Components/SearchMixedCard/Components/SearchResultCards",
  module
);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(SearchResultsMixtureREADME));

stories
  .add("Default", () => {
    const featuredContent = boolean("featuredContent", true, "Attributes");

    const featured = {
      featuredText: text("Featured text", "Featured", "Attributes"),
      featuredCards: object(
        "cards",
        featuredData.searchResults,
        "Featured Data"
      ),
      featuredContent: featuredContent ? featuredContent : null
    };
    const search = {
      searchResults: object(
        "searchResults",
        searchData.searchResults,
        "Search Data"
      ),
      hasSearchTerm: boolean("hasSearchTerm", false, "Attributes")
    };
    const page = {
      currentPage: number("Current page", 1, "Attributes")
    };
    const includeDescription = boolean(
      "Include description",
      true,
      "Attributes"
    );

    return (
      <SearchResultCards
        featured={featured}
        search={search}
        page={page}
        includeDescription={includeDescription}
        icon={ICON}
      />
    );
  })
  .add("Language: Arabic (RTL)", () => {
    const featuredContent = boolean("featuredContent", true, "Attributes");

    const featured = {
      featuredText: text("Featured text", "Featured", "Attributes"),
      featuredCards: object(
        "cards",
        featuredData.searchResults,
        "Featured Data"
      ),
      featuredContent: featuredContent ? featuredContent : null
    };
    const search = {
      searchResults: object(
        "searchResults",
        searchData.searchResults,
        "Search Data"
      ),
      hasSearchTerm: boolean("hasSearchTerm", false, "Attributes")
    };
    const page = {
      currentPage: number("Current page", 1, "Attributes")
    };
    const includeDescription = boolean(
      "Include description",
      true,
      "Attributes"
    );

    return (
      <Language code="ar">
        <SearchResultCards
          featured={featured}
          search={search}
          page={page}
          includeDescription={includeDescription}
          icon={ICON}
        />
      </Language>
    );
  });
