import React from "react";

import Alignment from "@emcm-ui/component-alignment";
import Attribution from "@emcm-ui/component-attribution";
import Callout from "@emcm-ui/component-callout";
import DateTime from "@emcm-ui/component-date-time";
import Duration from "@emcm-ui/component-duration";
import Eyebrow from "@emcm-ui/component-eyebrow";
import Grid from "@emcm-ui/component-grid";
import Group from "@emcm-ui/component-group";
import Header from "@emcm-ui/component-header";
import Heading from "@emcm-ui/component-heading";
import Image from "@emcm-ui/component-image";
import Link from "@emcm-ui/component-link";
import ListOfLinks from "@emcm-ui/component-list-of-links";
import Longform from "@emcm-ui/component-longform";
import MarkupContainer from "@emcm-ui/component-markup-container";
import Poster from "@emcm-ui/component-poster";
import Section from "@emcm-ui/component-section";
import SemanticArticle from "@emcm-ui/component-semantic-article";
import SemanticAside from "@emcm-ui/component-semantic-aside";
import SemanticMain from "@emcm-ui/component-semantic-main";
import SocialDropdown from "@emcm-ui/component-social-dropdown";
import SocialShare from "@emcm-ui/component-social-share";
import Tile from "@emcm-ui/component-tile";
import Icon from "@emcm-ui/component-icon";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";
import VideoPlayer from "@emcm-ui/component-video-player";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import { getSocialIcon } from "../../sampleAssets/icons";

import SampleSchemaOrganization from "../../fragments/schema-organization";
import SampleFooter from "../../fragments/footer";
import SampleHeader from "../../fragments/header";

import articleAbigailFisher from "../../sampleAssets/Article-Abigail-Fisher.jpg";
import articleCourtHouseGradient from "../../sampleAssets/Article-court-house-gradient.jpg";
import articleHeadshot from "../../sampleAssets/Article-headshot.jpg";
import articleLadyJustice from "../../sampleAssets/Article-lady-justice.jpg";
import articleLadyJusticeMobile from "../../sampleAssets/Article-lady-justice.mob.jpg";
import articlePhones from "../../sampleAssets/Article-phones.jpg";
import articlePhones2x from "../../sampleAssets/Article-phones.2x.jpg";
import trFoundationLogo from "../../sampleAssets/TR-foundation-horizontal.svg";

const Icons = {
  arrowRightBlue: "./icons/ui/blue/arrow-right.svg",
  facebookWhite: "./icons/social/white/facebook.svg",
  linkedinWhite: "./icons/social/white/linkedin.svg",
  twitterWhite: "./icons/social/white/twitter.svg",
  emailWhite: "./icons/ui/white/email.svg",

  home: "./icons/nucleo/energy-environment/large/home.svg",
  atom: "./icons/nucleo/healthcare-medical/large/atom.svg",
  carFront: "./icons/nucleo/transportation/large/car-front.svg",
  flaskConical: "./icons/nucleo/healthcare-medical/large/flask-conical.svg",
  chartBarGrowth: "./icons/nucleo/business-finance/large/Chart-bar-growth.svg",

  timeWhite: "./icons/ui/white/time.svg"
};

const attributionSocialButtons = [
  <a href="https://example.com">
    <SVGIcon name="facebook" size="s" getExternalIconPath={getSocialIcon} />
  </a>,
  <a href="https://example.com">
    <SVGIcon name="twitter" size="s" getExternalIconPath={getSocialIcon} />
  </a>,
  <a href="https://example.com">
    <SVGIcon name="linkedin" size="s" getExternalIconPath={getSocialIcon} />
  </a>,
  <a href="https://example.com">
    <SVGIcon name="email" size="s" getExternalIconPath={getSocialIcon} />
  </a>,
  <a href="https://example.com">
    <SVGIcon name="instagram" size="s" getExternalIconPath={getSocialIcon} />
  </a>
];

const Article = () => {
  return (
    <div>
      <SampleSchemaOrganization />
      <SampleHeader />

      <SemanticMain>
        <SemanticArticle>
          <Section background="grayDark" fullWidth>
            <Poster
              height="l"
              imageOverlay="scrimBottomDark"
              imageSrc={articleLadyJustice}
              imageSrcSet={`${articleLadyJusticeMobile} 768w, ${articleLadyJustice} 2048w`}
              alignBottom
              share={
                <MarkupContainer>
                  <SocialDropdown>
                    <SocialDropdown.Item>
                      <SocialShare
                        pageUrl="https://www.lseg.com"
                        type="facebook"
                      >
                        <SVGIcon
                          name="facebook"
                          size="s"
                          getExternalIconPath={getSocialIcon}
                        />
                      </SocialShare>
                    </SocialDropdown.Item>
                    <SocialDropdown.Item>
                      <SocialShare
                        pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
                        pageUrl="https://www.lseg.com"
                        type="twitter"
                      >
                        <SVGIcon
                          name="twitter"
                          size="s"
                          getExternalIconPath={getSocialIcon}
                        />
                      </SocialShare>
                    </SocialDropdown.Item>
                    <SocialDropdown.Item>
                      <SocialShare
                        pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
                        pageUrl="https://www.lseg.com"
                        type="linkedIn"
                      >
                        <SVGIcon
                          name="linkedin"
                          size="s"
                          getExternalIconPath={getSocialIcon}
                        />
                      </SocialShare>
                    </SocialDropdown.Item>
                    <SocialDropdown.Item>
                      <SocialShare
                        pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
                        pageUrl="https://www.lseg.com"
                        type="email"
                      >
                        <SVGIcon
                          name="email"
                          size="s"
                          getExternalIconPath={getSocialIcon}
                        />
                      </SocialShare>
                    </SocialDropdown.Item>
                  </SocialDropdown>
                </MarkupContainer>
              }
            >
              <Grid variant="7/12,5/12" gutterlessAtNarrow>
                <Grid.Item>
                  <VerticalSpacing size="xs">
                    <Eyebrow text="Legal" />
                  </VerticalSpacing>
                  <VerticalSpacing>
                    <Heading type="xl" rank="1">
                      <strong>
                        The six most important Supreme Court decisions of 2016
                      </strong>
                    </Heading>
                  </VerticalSpacing>
                  <Group>
                    <Group.Item>
                      <Attribution
                        isDark={true}
                        href="https://www.example.com"
                        imgSrc="headshot.jpg"
                        name="Casey S."
                        caption="Senior writer, large & medium law firms"
                        socialButtons={attributionSocialButtons}
                      />
                    </Group.Item>
                    <Group.Item>
                      <Alignment vertical="center">
                        <DateTime dateTime="2016-03-02 13:57" format="PP" />
                      </Alignment>
                    </Group.Item>
                    <Group.Item>
                      <Alignment vertical="center">
                        <Duration
                          duration="P15M"
                          icon={<Icon path={Icons.timeWhite} size="xs" />}
                        >
                          15 min
                        </Duration>
                      </Alignment>
                    </Group.Item>
                  </Group>
                </Grid.Item>
              </Grid>
            </Poster>
          </Section>
          <Longform>
            <Longform.Child>
              <Longform.RichText dropcap>
                <p>
                  The past year was really something else. 2016 saw the death of
                  Justice Antonin Scalia, the end of Merrick Garland’s Supreme
                  Court dreams,&nbsp;
                  <a href="https://example.com">the death of David Bowie</a>.
                  Not even Mariah Carey made it out unscathed. But, before we
                  bid the year a final adieu (or good riddance), it’s worth
                  looking back at the Supreme Court’s most important decisions.
                  For, throughout all of 2016’s ups and downs, the Court
                  continued to shape American law, government, and politics,
                  with important rulings in the areas of criminal law, civil
                  rights, immigration, and more. Here are our top six.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child>
              <SemanticAside>
                <Callout
                  asset={<Icon path={Icons.chartBarGrowth} size="l" />}
                  assetWidth="square"
                  eyebrowText="Good to know"
                >
                  A total of 137,410 persons were under post-conviction
                  supervision on September 30, 2016, an increase of one percent
                  over the total one year earlier.
                </Callout>
              </SemanticAside>
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <h2>
                  1. United States v. Texas, the end of Obama’s immigration
                  reform plans
                </h2>
                <p>
                  The President Obama’s immigration reform ended not with a
                  bang, but a whimper. A deadlocked, eight-justice Supreme Court
                  affirmed a Fifth Circuit ruling halting Obama’s Deferred
                  Action for Parents of Americans and Lawful Permanent Residents
                  plan, or DAPA, which sought to create a path towards legal
                  status for millions of immigrants. With the election of Donald
                  Trump months later, the future for such immigrants has become
                  increasingly uncertain.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child width="wide">
              <SemanticAside>
                <Longform.BlockQuote
                  attribution={
                    <Attribution
                      href="https://example.com"
                      imgSrc={articleHeadshot}
                      name="Shaun S."
                      caption="Managing director of corporate law"
                      socialButtons={attributionSocialButtons}
                    />
                  }
                  text={
                    "I don’t believe it’s going to be the extinction of all lawyers, legal librarians, and chief " +
                    "information officers, neither is it going to look like 2007 any time soon …"
                  }
                />
              </SemanticAside>
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <h2>
                  2. Fisher v. University of Texas at Austin, affirmative action
                  in college admissions
                </h2>
                <p>
                  In Fisher, which is technically Fisher II, the Supreme Court
                  ruled that the University of Texas’s consideration of race, as
                  part of a holistic admissions process, does not violate the
                  Equal Protection Clause. The use of race in public university
                  admissions has had a tenuous future in the Court almost since
                  it was first endorsed in 1978 and many thought that Fisher
                  could deal such race-conscious admissions their final blow.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child width="wide">
              <SemanticAside>
                <Longform.Asset
                  caption={
                    <span>
                      <strong>Abigail Fisher:</strong> the plaintiff in a
                      discrimination suit against the University of Texas,
                      prepares to speak at a news conference in front of the
                      U.S. Supreme Court in Washington, D.C.
                    </span>
                  }
                >
                  <MarkupContainer>
                    <VideoPlayer
                      description="Abigail Fisher: the plaintiff in a discrimination suit against the University of Texas, prepares to speak at a news conference in front of the U.S. Supreme Court in Washington, D.C."
                      duration="PT3M2S"
                      kind="vimeo"
                      name="Thomson Reuters + Axoni"
                      playLabel="Play video"
                      thumbnailUrl={`https://www.example.com${articleAbigailFisher}`}
                      uploadDate="2017-04-01"
                      videoId="208682545"
                    >
                      <Image alt="" src={articleAbigailFisher} fit="cover" />
                    </VideoPlayer>
                  </MarkupContainer>
                </Longform.Asset>
              </SemanticAside>
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <h2>3. Welch v. United States, limits to 3-strikes laws</h2>
                <p>
                  In Welch, the Supreme Court extended its ruling in Johnson v.
                  United States, allowing it to be applied to those convicted
                  before Johnson was decided. In Johnson, the Court held that
                  the federal three strikes law’s residual clause was
                  unconstitutionally vague. That opened up thousands of
                  sentences to challenge and Welch quickly became the most cited
                  case of the Court’s last term.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <h2>
                  4. Whole Woman’s Health v. Hellerstedt, striking down
                  restrictions on abortion providers
                </h2>
                <p>
                  In a roundabout attempt to limit abortion, the Texas
                  legislature adopted some of the strictest abortion regulations
                  in the country — but these regulations were targeted not at
                  women seeking abortion services, but the clinics providing
                  them, effectively shutting down most clinics in the state.
                  Those restrictions were struck down in Whole Woman’s Health,
                  as the Supreme Court ruled that abortion provider regulations
                  must be based on convincing medical evidence and cannot unduly
                  burden a woman’s right to abortion.
                </p>
                <h2>5. Samsung Electronics v. Apple, design patents</h2>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child width="wide">
              <SemanticAside>
                <Longform.Asset
                  caption={
                    <span>
                      <strong>
                        Interview with Frans Van Houten, CEO & Chairman,
                        Philips:
                      </strong>{" "}
                      Philips is one of the largest electronic companies in the
                      world, employing around 105,000 people across 60
                      countries.
                    </span>
                  }
                >
                  <Image
                    alt="an iPhone and a Samsung Galaxy, side-by-side"
                    src={articlePhones}
                    srcSet={`${articlePhones} 1x, ${articlePhones2x} 2x`}
                  />
                </Longform.Asset>
              </SemanticAside>
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <p>
                  In latest battle in the long running patent war between
                  Samsung and Apple, the Supreme Court addressed design patents
                  for the first time in decades. On the same day that it decided
                  Salman, the Supreme Court ruled that Samsung’s infringement of
                  Apple’s design patents on its smartphones did not necessarily
                  require the company to turn over all profits from those
                  phones. Rather, damages could be limited to the profits
                  attributable to infringing parts.
                </p>
                <p>
                  The ruling, of course, is not revolutionary. It’s certainly no
                  “one man, one vote.” But though utility patents significantly
                  outnumber design patents, design patents are quicker to obtain
                  and are a growing source of IP litigation. The Court’s
                  unanimous decision in Samsung limits the windfalls successful
                  design patent litigation might bring, though it still leaves
                  plenty of issues for lower courts to work through.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child>
              <SemanticAside>
                <ListOfLinks>
                  <ListOfLinks.BlockLink
                    eyebrow="Legal"
                    href="https://www.example.com"
                  >
                    7 cases to follow in the upcoming Supreme Court term
                  </ListOfLinks.BlockLink>
                  <ListOfLinks.BlockLink
                    eyebrow="Corporate Counsel"
                    href="https://www.example.com"
                  >
                    Chief Justice releases year-end report
                  </ListOfLinks.BlockLink>
                </ListOfLinks>
              </SemanticAside>
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <h2>
                  4. Whole Woman’s Health v. Hellerstedt, striking down
                  restrictions on abortion providers
                </h2>
                <p>
                  It’s somewhat unnatural to look at Supreme Court cases by
                  calendar year. The Court, after all, organizes itself around
                  terms that stretch from October of one year to June of the
                  next, with most of the major decisions coming out in June.
                  That means that almost all of this year’s most impactful
                  opinions come from last term.
                </p>
                <p>
                  But, there are a few recently decided cases that are worthy of
                  inclusion. Salman tops that list. Decided in early December,
                  this insider trading case upheld the presumption of a benefit
                  when an insider gives confidential information to a family
                  member who then trades on it. The ruling undoes a recent
                  Second Circuit opinion that threatened to upend the so-called
                  “friends and family” rule and which could have significantly
                  limited insider trading prosecutions.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child width="full">
              <Longform.ChapterDivider
                backgroundAnchor="N"
                backgroundGradient={false}
                backgroundSrc={articleCourtHouseGradient}
                chapterText="Chapter Two"
                titleText="Three ways the legal world is changing"
              />
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <p>
                  For many global industries, the world is going to be a very
                  different place tomorrow than it is today, including for the
                  legal industry. And while I don’t believe it’s going to be the
                  extinction of all lawyers, legal librarians, and chief
                  information officers, neither is it going to look like 2007
                  any time soon.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child>
              <SemanticAside>
                <Callout
                  asset={
                    <Image
                      alt=""
                      src={trFoundationLogo}
                      height={38}
                      width={165}
                    />
                  }
                  assetWidth="wide"
                >
                  In 2014 the Thomson Reuters Foundation launched the TrustLaw
                  Index of Pro Bono, an annual survey that captures and analyzes
                  global pro bono trends across the legal industry.
                </Callout>
              </SemanticAside>
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <h2>New legal ecosystem</h2>
                <p>
                  A new legal ecosystem has emerged with new players and new
                  answers that include pure-play technology, as well as
                  technology-enabled legal services, to meet client and
                  institutional demands for more efficient, cost-effective
                  solutions. Clients are asking to be charged for the value of
                  the matter, pushing for unbundled services so they get what
                  they want. This leaves room for disruption and innovation and
                  puts pressure on the law firm business model.
                </p>
                <p>
                  There is an opportunity to bridge the client interest and law
                  firm interest with new technologies like Allegory Law, a
                  software tool that integrates critical case information and
                  provides clients with more effective data management. And
                  Seyfarth Shaw’s Seyfarth Lean, a value-driven client service
                  model that improves process with project management and
                  tailored technology solutions. Meanwhile, BakerHostetler
                  became the first BigLaw firm to engage ROSS, an “A.I. lawyer
                  that helps lawyers research faster and focus on clients.”
                </p>
                <p>
                  Serving clients is the whole point of the profession, and if
                  we can help lawyers serve clients better with innovative
                  technologies and valuable ideas, then we’re all in a better
                  position.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child>
              <SemanticAside>
                {/* Custom Experience Component here */}
              </SemanticAside>
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <h2>Increased cross-boundary collaboration</h2>
                <p>
                  Law firms, in-house counsel, and third-party partners
                  including alternative legal services providers are working
                  together to disrupt the traditional client/law firm model.
                  General counsel aren’t looking to do away with their law firms
                  – they need them. Today, there is more regulation and more
                  risk and they need the know-how of lawyers to overcome these
                  challenges.
                </p>
                <p>
                  One example of this is Avis budget group’s rigorous Request
                  for Proposal process to compile its panel of law firms. They
                  went from 700 law firms to an “all star team” of seven
                  fixed-price law firms globally who would work together. The
                  result is a successful collaboration for both Avis and the
                  panel law firms.
                </p>
                <p>
                  Or look at Thomson Reuters own partnership with Clifford
                  Chance. This collaboration helps global financial institutions
                  tackle their most pressing regulatory obligations relating to
                  margin rules for uncleared, over-the-counter (OTC) derivatives
                  in a timely, efficient and more cost-effective manner.
                </p>
                <table>
                  <caption>Registered entities in Belgium</caption>
                  <thead>
                    <tr>
                      <th scope="col">Name of Entity</th>
                      <th scope="col">Registered Address</th>
                      <th scope="col">Registered Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Thomson Reuters (Markets) SA., Belgium Branch</td>
                      <td>Marnixlaan 17 1000, Brussels Belgium</td>
                      <td>0473.542.617</td>
                    </tr>
                    <tr>
                      <td>eXimius NV</td>
                      <td>Zandvoortstraat 45C 2800, Mechelen Belgium</td>
                      <td>BTW BE 0881.564.110 RPR Mechelen</td>
                    </tr>
                    <tr>
                      <td>Aktor Knowledge Technology N.V.</td>
                      <td>Sint Pietersvliet 7 Antwerp, 2000 Belgium</td>
                      <td>HRA 332462</td>
                    </tr>
                    <tr>
                      <td>Compu-Mark N.V.</td>
                      <td>St Pietersvliet 7 B-2000 Antwerpen Belgium</td>
                      <td>271 928</td>
                    </tr>
                  </tbody>
                </table>
              </Longform.RichText>
            </Longform.Child>
          </Longform>
          <Section>
            <VerticalSpacing size="xl">
              <Header
                heading={
                  <Heading rank="2" type="l">
                    <b>Our values</b> shape our culture
                  </Heading>
                }
                callToAction={
                  <Link
                    href="https://example.com"
                    type="icon"
                    icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                    iconPosition={"right"}
                  >
                    Corporate responsibility & inclusion
                  </Link>
                }
              />
            </VerticalSpacing>
            <Grid variant="even-4" gutterBorder>
              <Grid.Item>
                <Tile
                  title="Trust"
                  description="When you tap into our unique information ecosystem, you're getting the intelligence you need to find trusted answers"
                  linkTitle="Trust principles"
                  linkIcon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  href="https://example.com"
                  type="centeredIcon"
                  icon={<Icon path={Icons.home} size="l" />}
                />
              </Grid.Item>
              <Grid.Item>
                <Tile
                  title="Innovation"
                  description="Thomson Reuters Labs™ around the world collaborate with customers to deliver world-class solutions"
                  linkTitle="Innovation labs"
                  linkIcon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  href="https://example.com"
                  type="centeredIcon"
                  icon={<Icon path={Icons.atom} size="l" />}
                />
              </Grid.Item>
              <Grid.Item>
                <Tile
                  title="Partnership"
                  description="Our diverse viewpoints and unique abilities offer added value to our customers"
                  linkTitle="All case studies"
                  linkIcon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  href="https://example.com"
                  type="centeredIcon"
                  icon={<Icon path={Icons.carFront} size="l" />}
                />
              </Grid.Item>
              <Grid.Item>
                <Tile
                  title="Performance"
                  description="We excel at work that positively impacts the world, while delivering results our partners can see"
                  linkTitle="All products"
                  linkIcon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  href="https://example.com"
                  type="centeredIcon"
                  icon={<Icon path={Icons.flaskConical} size="l" />}
                />
              </Grid.Item>
            </Grid>
          </Section>
        </SemanticArticle>
      </SemanticMain>

      <SampleFooter />
    </div>
  );
};

export default Article;
