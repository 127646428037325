import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const Item = ({ href, lang, title }) => {
  const getClassName = getClassNameFactory("LanguageSwitcherItem");

  return (
    <li className={getClassName({ className: typestack("p1") })}>
      <a
        href={href}
        lang={lang}
        className={getClassName({ descendantName: "link" })}
      >
        {title}
      </a>
    </li>
  );
};

Item.displayName = "LanguageSwitcher.Item";

Item.propTypes = {
  /**
   * The page that this item should link to.
   */
  href: PropTypes.string.isRequired,

  /**
   * The language code, matching both the title and the linked page.
   */
  lang: PropTypes.string.isRequired,

  /**
   * The title of this LanguageSwitcherItem. This should be in the language of the linked page, and will generally be the name of the language.
   */
  title: PropTypes.string.isRequired
};

export default Item;
