import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import { formConsts } from "../../FormConstant";

class ToggleSwitch extends Component {
  static displayName = "FormNew.ToggleSwitchControl";

  static propTypes = {
    /**
     * Children node.
     */
    children: PropTypes.node,
    /**
     * Disable option for the toggle component
     */
    disabled: PropTypes.bool,
    /**
     * Active option for the toggle component
     */
    active: PropTypes.bool,
    /**
     * Click function for the toggle component
     */
    onClick: PropTypes.func,
    /**
     * Active text for the toggle component
     */
    activeText: PropTypes.string,
    /**
     * Inactive text for the toggle component
     */
    inActiveText: PropTypes.string,
    /**
     * Id for the input of toggle component
     */
    id: PropTypes.string.isRequired,
    /**
     * Name for the input of toggle component
     */
    name: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    const { active } = props;

    this.state = { buttonActive: active };
  }

  getClassName = getClassNameFactory(ToggleSwitch.displayName);

  handleOnChange = () => {
    const { buttonActive } = this.state;
    const { onClick } = this.props;

    this.setState({ buttonActive: !buttonActive });
    if (onClick) {
      onClick(!buttonActive);
    }
  };
  handleKeyPress = e => {
    const { buttonActive } = this.state;
    const { onClick } = this.props;
    const code = e.keyCode || e.which;
    const enterKeycode = 13;

    e.preventDefault();
    if (code === enterKeycode) {
      this.setState({ buttonActive: !buttonActive });
      if (onClick) {
        onClick(!buttonActive);
      }
    }
  };

  componentDidUpdate(prevProps) {
    const { active } = this.props;
    const { active: activeProps } = prevProps;

    if (active !== activeProps) {
      this.setState({ buttonActive: active });
    }
  }

  render() {
    const { buttonActive } = this.state;
    const { disabled, activeText, inActiveText, active, id, name } = this.props;

    return (
      <div
        data-rehydratable={getRehydratableName(ToggleSwitch.displayName)}
        data-activetext={activeText}
        data-inactivetext={inActiveText}
        data-active={active}
        data-name={name}
        data-id={id}
        data-disabled={disabled}
        className={this.getClassName({ descendantName: "container" })}
      >
        {(activeText || inActiveText) && (
          <p
            className={this.getClassName({
              className: typestack("p2"),
              descendantName: "text"
            })}
            id="toggle"
          >
            {buttonActive ? activeText : inActiveText}
          </p>
        )}
        <label
          htmlFor={id}
          className={this.getClassName({
            descendantName: "switch",
            modifiers: disabled && "disabled"
          })}
        >
          <input
            className={this.getClassName({ descendantName: "input" })}
            aria-disabled={disabled}
            id={id}
            aria-labelledby="toggle"
            disabled={disabled}
            onKeyPress={this.handleKeyPress}
            onChange={this.handleOnChange}
            onClick={this.handleOnChange}
            type="checkbox"
            role="switch"
            aria-checked={buttonActive}
            checked={buttonActive}
            name={name}
          />
          <span
            className={this.getClassName({
              descendantName: "slider",
              modifiers: disabled && "disabled"
            })}
          />
        </label>
      </div>
    );
  }
}

ToggleSwitch.defaultProps = {
  disabled: false,
  name: formConsts.toggleswitchControlName,
  id: "toggleswitch"
};

export default ToggleSwitch;
