import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import Attribution from "./index";
import { Language } from "@emcm-ui/component-theme";
import "./Attribution.css";
import AttributionREADME from "../README.md";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import { getSocialIcon } from "./icons";

const stories = storiesOf("Components/Attribution", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(AttributionREADME));

const socialButtons = [
  <a href="https://example.com" aria-label="View Facebook profile">
    <SVGIcon name="facebook" size="s" getExternalIconPath={getSocialIcon} />
  </a>,
  <a href="https://example.com" aria-label="View Twitter profile">
    <SVGIcon name="twitter" size="s" getExternalIconPath={getSocialIcon} />
  </a>,
  <a href="https://example.com" aria-label="View LinkedIn profile">
    <SVGIcon name="linkedin" size="s" getExternalIconPath={getSocialIcon} />
  </a>,
  <a href="https://example.com" aria-label="View email profile">
    <SVGIcon name="email" size="s" getExternalIconPath={getSocialIcon} />
  </a>,
  <a href="https://example.com" aria-label="View Instagram profile">
    <SVGIcon name="instagram" size="s" getExternalIconPath={getSocialIcon} />
  </a>
];

stories
  .add("Default", () => (
    <Attribution
      href={text("href", "https://www.example.com")}
      imgSrc={text("imgSrc", "headshot.jpg")}
      name={text("name", "Casey S.")}
      caption={text("caption", "Senior writer, large & medium law firms")}
      socialButtons={socialButtons} // eslint-disable-line no-magic-numbers
    />
  ))
  .add("On dark background", () => (
    <div style={{ backgroundColor: "#4d4d4d", color: "#f4f4f4" }}>
      <Attribution
        isDark={true}
        href={text("href", "https://www.example.com")}
        imgSrc={text("imgSrc", "headshot.jpg")}
        name={text("name", "Casey S.")}
        caption={
          <span>
            <a href="https://not-visited.com">Senior writer</a>,{" "}
            <strong>large</strong> & <em>medium</em> law firms
          </span>
        }
        socialButtons={socialButtons} // eslint-disable-line no-magic-numbers
      />
    </div>
  ))
  .add("Sociable", () => (
    <Attribution
      href={text("href", "https://www.example.com")}
      imgSrc={text("imgSrc", "headshot.jpg")}
      name={text("name", "Casey S.")}
      caption={text("caption", "Senior writer, large & medium law firms")}
      socialButtons={socialButtons}
    />
  ))
  .add("Inline markup", () => (
    <Attribution
      href={text("href", "https://www.example.com")}
      imgSrc={text("imgSrc", "headshot.jpg")}
      name={text("name", "Casey S.")}
      caption={
        <span>
          <a href="https://not-visited.com">Senior writer</a>,{" "}
          <strong>large</strong> & <em>medium</em> law firms
        </span>
      }
      socialButtons={socialButtons} // eslint-disable-line no-magic-numbers
    />
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Attribution
        href={text("href", "https://www.example.com")}
        imgSrc={text("imgSrc", "headshot.jpg")}
        name={text("name", "عبلة")}
        caption={text(
          "caption",
          "الذي ينبغي أن تستهدفه كافة الشعوب والأمم حتى"
        )}
        socialButtons={socialButtons} // eslint-disable-line no-magic-numbers
      />
    </Language>
  ));
