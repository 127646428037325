import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import Form from "@emcm-ui/component-form";

class PrivacyControl extends Component {
  static displayName = "Form.PrivacyControl";

  static defaultProps = {
    consentName: "consent",
    explicitConsentId: "explicitConsentCheckbox",
    customCompliantsLabel: "Choose at least any one",
    optionalLabel: "Optional"
  };

  static propTypes = {
    /**
     * List of all countries that require strict privacy control
     */
    compliantCountries: PropTypes.array.isRequired,
    /**
     * Name attribute of the hidden input that passes the consent value
     */
    consentName: PropTypes.string.isRequired,
    /**
     * Default consent message
     */
    consentText: PropTypes.node.isRequired,
    /**
     * Name attribute of the country dropdown Privacy Control depends on
     */
    dependsOn: PropTypes.string.isRequired,
    /**
     * Id attribute for the explicit consent opt-in checkbox
     */
    explicitConsentId: PropTypes.string,
    /**
     * Explicitly opt-in consent for GDPR-affected countries
     */
    explicitConsentText: PropTypes.string.isRequired,
    /**
     * Attribute for the accepted contact channel
     */
    customCompliants: PropTypes.array,
    /**
     * Custom Compliants label
     */
    customCompliantsLabel: PropTypes.string,
    /**
     * Label to be used for dynamic optional value
     */
    optionalLabel: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      hasGivenExplicitConsent: "",
      showExplicitConsent: false,
      customCompliants: null,
      explicitConsentText: this.props.explicitConsentText
    };

    this.myRef = React.createRef();

    this.getClassName = getClassNameFactory(PrivacyControl.displayName);

    this.getCountryDropdown = this.getCountryDropdown.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleExplicitConsentChange = this.handleExplicitConsentChange.bind(
      this
    );
  }

  convertArrayToObject = channels => {
    return channels.map(channel => {
      return { ...channel, checkbox: false };
    });
  };

  getCountryDropdown() {
    const name = this.props.dependsOn;

    const countryDropdown = this.myRef.current
      .closest("form")
      .querySelector(`[name=${name}]`);

    if (countryDropdown === null) {
      throw new Error(
        "Could not find the country dropdown specified in dependsOn prop"
      );
    }

    return countryDropdown;
  }

  handleCountryChange(event) {
    const selectedCountry = event.target.value;
    const isCompliantCountry = this.props.compliantCountries.includes(
      selectedCountry
    );
    const isCustomCompliant = this.props.customCompliants.find(
      ({ country }) => country === selectedCountry
    );

    const showExplicitConsentText = isCompliantCountry && !isCustomCompliant; // TRUE + FALSE
    const showExplicitConsentCheckbox = isCompliantCountry && isCustomCompliant; // TRUE + TRUE
    const showFooter = !isCompliantCountry && !isCustomCompliant; // FALSE + FALSE

    if (showExplicitConsentText) {
      this.setState({
        hasGivenExplicitConsent: false,
        showExplicitConsent: true,
        explicitConsentText: this.props.explicitConsentText,
        customCompliants: null
      });
    }

    if (showExplicitConsentCheckbox) {
      this.setState({
        hasGivenExplicitConsent: false,
        showExplicitConsent: true,
        explicitConsentText: isCustomCompliant.text,
        customCompliants: {
          ...isCustomCompliant,
          consentCheckbox: false,
          channelCheckbox: this.convertArrayToObject(
            isCustomCompliant.channels
          ),
          isUserSelectedAnyChannel: false
        }
      });
    }

    if (showFooter) {
      this.setState({
        hasGivenExplicitConsent: "",
        showExplicitConsent: false,
        explicitConsentText: this.props.consentText,
        customCompliants: null
      });
    }
  }

  handleExplicitConsentChange(event, channel) {
    const target = event.target;
    const value = target.checked;
    const { showExplicitConsent, customCompliants } = this.state;
    const isCustomCompliant = showExplicitConsent && customCompliants;

    if (isCustomCompliant && !channel) {
      this.setState({
        hasGivenExplicitConsent: value,
        customCompliants: {
          ...this.state.customCompliants,
          consentCheckbox: value
        }
      });
    }

    if (isCustomCompliant && channel) {
      const state = this.state;
      const findIndex = state.customCompliants.channelCheckbox.findIndex(
        data => data.name === channel.name
      );

      state.customCompliants.channelCheckbox[findIndex].checkbox = value;
      const findTruly = state.customCompliants.channelCheckbox.some(
        data => data.checkbox === true
      );

      state.customCompliants.isUserSelectedAnyChannel = findTruly;
      this.setState(state);
    }

    if (!isCustomCompliant) {
      this.setState({
        hasGivenExplicitConsent: value
      });
    }
  }

  componentDidMount() {
    const countryDropdown = this.getCountryDropdown();

    countryDropdown.addEventListener("change", this.handleCountryChange);
  }

  componentWillUnmount() {
    const countryDropdown = this.getCountryDropdown();

    countryDropdown.removeEventListener("change", this.handleCountryChange);
  }

  render() {
    const {
      compliantCountries,
      consentName,
      consentText,
      dependsOn,
      explicitConsentId,
      optionalLabel
    } = this.props;
    const {
      hasGivenExplicitConsent,
      showExplicitConsent,
      customCompliants,
      explicitConsentText
    } = this.state;
    const isCustomCompliant = showExplicitConsent && customCompliants;

    return (
      <div
        className={this.getClassName()}
        data-compliant-countries={JSON.stringify(compliantCountries)}
        data-custom-consent-channel={JSON.stringify(
          this.props.customCompliants
        )}
        data-custom-consent-label={this.props.customCompliantsLabel}
        data-consent-name={consentName}
        data-depends-on={dependsOn}
        data-explicit-consent-id={explicitConsentId}
        data-explicit-consent-text={explicitConsentText}
        data-rehydratable={getRehydratableName(PrivacyControl.displayName)}
        data-optional-label={optionalLabel}
        ref={this.myRef}
      >
        {showExplicitConsent && (
          <Form.CheckboxControl
            checked={hasGivenExplicitConsent}
            value={hasGivenExplicitConsent.toString()}
            name={hasGivenExplicitConsent.toString()}
            id={explicitConsentId}
            labelText={explicitConsentText}
            optionalLabel={optionalLabel}
            onChange={this.handleExplicitConsentChange}
          />
        )}

        {isCustomCompliant &&
          this.state.customCompliants.consentCheckbox && (
            <div
              className={this.getClassName({
                descendantName: "custom-compliants-group"
              })}
            >
              <div
                className={this.getClassName({
                  descendantName: "custom-compliants-label"
                })}
              >
                {this.props.customCompliantsLabel}
              </div>

              <div
                className={this.getClassName({
                  descendantName: "custom-compliants-channel"
                })}
              >
                {customCompliants.channelCheckbox.map(channel => {
                  const userSelected = !customCompliants.isUserSelectedAnyChannel;
                  const condition = userSelected || channel.checkbox === true;

                  return (
                    <Form.CheckboxControl
                      key={channel.name}
                      checked={channel.checkbox}
                      value={channel.checkbox}
                      id={`channel-${channel.name}`}
                      name={`channel-${channel.name}`.toString()}
                      required={condition}
                      labelText={channel.displayname}
                      optionalLabel={optionalLabel}
                      onChange={e => {
                        return this.handleExplicitConsentChange(e, channel);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          )}

        {!showExplicitConsent && (
          <div className={this.getClassName({ descendantName: "consent" })}>
            {consentText}
          </div>
        )}
        <input
          name={consentName}
          type="hidden"
          value={hasGivenExplicitConsent}
        />
      </div>
    );
  }
}

export default PrivacyControl;
