import PropTypes from "prop-types";
import React, { Component } from "react";
import Dropdown from "@emcm-ui/component-dropdown";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";
import Tooltip from "@emcm-ui/component-tooltip";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

class CartItem extends Component {
  static displayName = "CartItem";

  static propTypes = {
    /**
     * Id of the product item
     */
    id: PropTypes.number.isRequired,
    /**
     * License count of the product item
     */
    count: PropTypes.number.isRequired,
    /**
     * Name of the product item
     */
    name: PropTypes.string.isRequired,
    /**
     * Currency of the product item
     */
    currency: PropTypes.string.isRequired,
    /**
     * Price of the product item
     */
    price: PropTypes.number.isRequired,
    /**
     * Validity information of the product item
     */
    productValidity: PropTypes.string.isRequired,
    /**
     * Maximun license of the product item
     */
    maxLicense: PropTypes.number.isRequired,
    /**
     * Maximum online purchasable license of the product item
     */
    maxOnlineLicense: PropTypes.number.isRequired,
    /**
     * Array of the products objects
     */
    products: PropTypes.array.isRequired,
    /**
     * License Text for the license dropdown
     */
    licenseText: PropTypes.string.isRequired,
    /**
     * Support team information node
     */
    support: PropTypes.node.isRequired,
    /**
     * Function to update the parent state
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Tooltip info for the non base products
     */
    showRemoveLink: PropTypes.bool.isRequired,
    /**
     * True if products are base products
     */
    isBaseProduct: PropTypes.bool,
    /**
     * Type of the products addons or exchanges
     */
    productsType: PropTypes.string,
    /**
     * Function to update parent state on remove product
     */
    onRemove: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.getClassName = getClassNameFactory(CartItem.displayName);

    this.state = {
      products: this.props.products
    };

    this.digitCount = 2;
  }

  getProductQuantity = maxLicense => {
    const quantity = [];

    for (let count = 1; count <= maxLicense; count++) {
      const formattedCount = count.toString().padStart(this.digitCount, "0");

      const option = { value: `${formattedCount}`, label: `${formattedCount}` };

      quantity.push(option);
    }

    return quantity;
  };

  handleChange = (id, value) => {
    this.setState(prevState => ({
      products: prevState.products.map(
        item => (item.id === id ? { ...item, licenseCount: value } : item)
      )
    }));
    this.props.onChange(id, value);
  };

  handleRemove = id => {
    const { productsType, onRemove } = this.props;

    if (productsType === "products") {
      this.setState(prevState => ({
        products: prevState.products.filter(product => product.id !== id)
      }));
    }
    onRemove(productsType, id);
  };

  render() {
    const {
      id,
      count,
      name,
      currency,
      price,
      productValidity,
      licenseText,
      maxLicense,
      maxOnlineLicense,
      support,
      showRemoveLink,
      isBaseProduct,
      products
    } = this.props;

    let quantity = this.getProductQuantity(maxLicense);
    let selectedCount = this.state.products[count].licenseCount;

    if (!isBaseProduct) {
      quantity = products[count].licenseCount;
      selectedCount = products[count].licenseCount;
    }

    const IsMaxLicenseOrdered = selectedCount > maxOnlineLicense;

    const displayCount = IsMaxLicenseOrdered ? maxOnlineLicense : selectedCount;

    const totalPrice = displayCount * price;

    const selectedLicenseCount = Math.min(
      Number(selectedCount),
      maxOnlineLicense
    ).toString();

    return (
      <li
        role="listitem"
        className={this.getClassName({ descendantName: "product" })}
      >
        <div
          className={this.getClassName({ descendantName: "producttitle" })}
          aria-label={`${name} ${price}${currency} per license. Total ${totalPrice}${currency} ${productValidity} for ${selectedCount} licenses.`}
        >
          <h3
            className={this.getClassName({
              descendantName: "productname",
              className: typestack("h4")
            })}
          >
            {name}
          </h3>
          <h3
            className={this.getClassName({
              descendantName: "productprice",
              className: typestack("h4")
            })}
          >
            {currency}
            {price}
          </h3>
        </div>
        <p
          className={this.getClassName({
            descendantName: "billedtext",
            className: typestack("p2")
          })}
        >
          {productValidity}
        </p>
        <p
          className={this.getClassName({
            descendantName: "license",
            className: typestack("p2Bold")
          })}
        >
          {licenseText}
        </p>
        <div className={this.getClassName({ descendantName: "productfooter" })}>
          {isBaseProduct ? (
            <div
              className={this.getClassName({
                descendantName: "productqty",
                className: typestack("p1")
              })}
            >
              <Dropdown
                block={true}
                items={quantity}
                onValueChanged={e => this.handleChange(id, e)}
                placeholder={`${selectedCount}`}
                value={selectedLicenseCount}
              />
            </div>
          ) : (
            <div className={this.getClassName({ descendantName: "qtyinfo" })}>
              <p
                className={this.getClassName({
                  descendantName: "quantity",
                  className: typestack("subline2")
                })}
              >
                {quantity}
              </p>
              <Tooltip tooltipText="This is sample tooltip" position={"top"}>
                <SVGIcon name="question-07" size="xs" />
              </Tooltip>
            </div>
          )}
          <h3
            className={this.getClassName({
              descendantName: "producttotal",
              className: typestack("h3")
            })}
          >
            {currency}
            {totalPrice}
          </h3>
        </div>
        {IsMaxLicenseOrdered && (
          <p className={this.getClassName({ descendantName: "maxvalidation" })}>
            {support}
          </p>
        )}
        {showRemoveLink && (
          <button
            className={this.getClassName({
              descendantName: "remove-link",
              className: typestack("p1Link")
            })}
            onClick={() => {
              this.handleRemove(id);
            }}
          >
            Remove
          </button>
        )}
      </li>
    );
  }
}

export default CartItem;
