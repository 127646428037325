import React from "react";
import PlanCard from "./PlanCard";

export default async (domNode, rehydrateChildren) => {
  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data && JSON.parse(data);
  };

  const childrenNode = domNode.querySelector("[data-rehydratable-children]");
  const reactifiedChildren = await rehydrateChildren(childrenNode);

  const props = {
    id: domNode.getAttribute("data-id"),
    price: domNode.getAttribute("data-price"),
    priceHeadingRank: domNode.getAttribute("data-price-heading-rank"),
    heading: domNode.getAttribute("data-heading"),
    headingRank: domNode.getAttribute("data-heading-rank"),
    isValuePointsRequired: readData("value-points-required"),
    valuePoints: domNode.getAttribute("data-value-points"),
    valuePointsInfo: domNode.getAttribute("data-value-points-info"),
    priceInfo: domNode.getAttribute("data-price-info"),
    priceNote: domNode.getAttribute("data-price-note"),
    href: domNode.getAttribute("data-href"),
    buttonText: domNode.getAttribute("data-button-text"),
    buttonColor: domNode.getAttribute("data-button-color"),
    secondButtonText: domNode.getAttribute("data-second-button-text"),
    secondButtonHref: domNode.getAttribute("data-second-button-href"),
    secondButtonColor: domNode.getAttribute("data-second-button-color"),
    bulletPoints: readData("bullet-points"),
    isTooltipRequired: readData("tooltip-required"),
    isShowMoreRequired: readData("show-more-required"),
    showMoreText: domNode.getAttribute("data-show-more-text"),
    showLessText: domNode.getAttribute("data-show-less-text"),
    isBadgeRequired: readData("badge-required"),
    badgeText: domNode.getAttribute("data-badge-text"),
    badgeType: domNode.getAttribute("data-badge-type"),
    badgeColor: domNode.getAttribute("data-badge-color"),
    background: domNode.getAttribute("data-badge-background")
  };

  return <PlanCard {...props}>{reactifiedChildren}</PlanCard>;
};
