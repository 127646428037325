import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const Link = ({ children, disabled, href }) => {
  const getClassName = getClassNameFactory("SearchFiltersLink");

  const ElementType = href ? "a" : "span";

  const linkProps = {
    className: getClassName({
      modifiers: disabled ? "disabled" : "",
      className: typestack("p1")
    })
  };

  if (href) {
    linkProps.href = href;
    linkProps.rel = "nofollow";
  }

  return <ElementType {...linkProps}>{children}</ElementType>;
};

Link.displayName = "SearchFilters.Link";

Link.propTypes = {
  /**
   * The content of this Link
   */
  children: PropTypes.node,

  /**
   * Show as disabled
   */
  disabled: PropTypes.bool,

  /**
   * The destination for the title of this group.
   */
  href: PropTypes.string
};

export default Link;
