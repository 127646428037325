import PropTypes from "prop-types";
import React, { Component } from "react";
import VideoPlayerEmbed from "./VideoPlayerEmbed";
import videoPlayerRegistry from "./registry/VideoPlayerRegistry";
import Cookies from "js-cookie";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

import AjaxContent from "@emcm-ui/component-ajax-content";
import Modal, { IsModalOpenConsumer } from "@emcm-ui/component-modal";
import { FormOnSubmitSuccessContextProvider } from "@emcm-ui/component-form";
import { formatDuration } from "./utilities";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const ewpCookieName = "tr_ewp_submitted_forms";

const isFormInCookie = (gatedCookieId, gatedFormId) => {
  const cookie = Cookies.getJSON(gatedCookieId);

  return Boolean(cookie && cookie.forms && cookie.forms.includes(gatedFormId));
};

const propTypes = {
  /**
   * The analytics function to use.
   */
  analytics: PropTypes.func,

  /**
   * Should this video start automatically if it's inside a <Modal />?
   */
  autoplayInModal: PropTypes.bool,

  /**
   * The poster image.
   */
  children: PropTypes.node,

  /**
   * An ISO 8601 duration.
   */
  duration: PropTypes.string.isRequired,

  /**
   * Name of browser cookie where submitted form IDs will be stored.
   */
  gatedCookieName: PropTypes.string,

  /**
   * Path to the form content; loaded via AJAX to present a gated form.
   */
  gatedFormContentLocation: PropTypes.string,

  /**
   * Unique ID used to identify the form and lock/unlock the gated content.
   */
  gatedFormId: PropTypes.string,

  /**
   * The video provider.
   */
  kind: PropTypes.oneOf(["dam", "qq", "vidyard", "vimeo", "youtube", "kaltura"])
    .isRequired,

  /**
   * Callback, called on loading new AJAX form content.
   */
  onAjaxChange: PropTypes.func,

  /**
   * Accessibility label to be used on the "Play" button
   */
  playLabel: PropTypes.string,

  /**
   * Rehydration options.
   *
   * If unspecified, will use the default (empty) AjaxContent options.
   */
  reactFromMarkupOptions: PropTypes.object,

  /**
   * A list of rehydrators that are available. Used to rehydrate markup from
   * the server.
   *
   * If unspecified, will use the default (empty) AjaxContent rehydrators.
   */
  reactFromMarkupRehydrators: PropTypes.object,

  /**
   * The ID of the video. Used to retrieve the video from the video provider.
   */
  videoId: PropTypes.string,

  /**
   * Kaltura object in JSON.stringify() format
   */
  kaltura: PropTypes.string
};

export class VideoPlayerCore extends Component {
  static displayName = "VideoPlayerCore";

  static propTypes = {
    ...propTypes,

    /**
     * Start playing on mount. Will usually come from context - see VideoPlayerCoreWithContext.
     */
    allowAutoplay: PropTypes.bool
  };

  static defaultProps = {
    gatedCookieName: ewpCookieName
  };

  state = {
    isActive: false
  };

  getClassName = getClassNameFactory(VideoPlayerCore.displayName);

  handleClickLoad = () => {
    if (!this.checkIsGateOpen()) {
      this.setState({ isModalOpen: true });

      return;
    }

    this.playVideo();
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleFormComplete = () => {
    this.setState({
      isModalOpen: false
    });

    if (this.checkIsGateOpen()) {
      this.playVideo();
    }
  };

  checkIsGateOpen = () => {
    const {
      gatedFormContentLocation,
      gatedCookieName,
      gatedFormId
    } = this.props;

    if (gatedFormContentLocation && gatedCookieName && gatedFormId) {
      return isFormInCookie(gatedCookieName, gatedFormId);
    }

    return true;
  };

  playVideo = () => {
    videoPlayerRegistry.deactivateAll(this);
    this.setState({ isActive: true });
  };

  deactivate() {
    if (this.state.isActive) {
      this.setState({
        isActive: false
      });
    }
  }

  componentDidMount() {
    videoPlayerRegistry.register(this);
  }

  componentWillUnmount() {
    videoPlayerRegistry.deregister(this);
  }

  render() {
    const {
      allowAutoplay,
      autoplayInModal,
      children,
      duration,
      gatedFormContentLocation,
      gatedCookieName,
      gatedFormId,
      kind,
      onAjaxChange,
      playLabel,
      reactFromMarkupOptions,
      reactFromMarkupRehydrators,
      videoId,
      kaltura
    } = this.props;
    const { isActive, isModalOpen } = this.state;
    const formattedDuration = formatDuration(duration);
    let content;

    const modal = isModalOpen ? (
      <Modal
        ariaLabel="Gated Video Dialog"
        closeLabel="Close"
        closeThisComponent={this.handleCloseModal}
      >
        <FormOnSubmitSuccessContextProvider value={this.handleFormComplete}>
          <AjaxContent
            failedContent="Failed to retrieve content"
            loadingLabel="Loading"
            location={gatedFormContentLocation}
            onChange={onAjaxChange}
            reactFromMarkupOptions={reactFromMarkupOptions}
            reactFromMarkupRehydrators={reactFromMarkupRehydrators}
          />
        </FormOnSubmitSuccessContextProvider>
      </Modal>
    ) : null;

    if (allowAutoplay || isActive) {
      content = (
        <VideoPlayerEmbed getClassName={this.getClassName} {...this.props} />
      );
    } else {
      content = (
        <div className={this.getClassName({ descendantName: "poster" })}>
          <div
            className={this.getClassName({ descendantName: "posterImage" })}
            data-rehydratable-children
          >
            {children}
          </div>

          <div
            className={this.getClassName({
              descendantName: "duration",
              className: typestack("p2")
            })}
          >
            {formattedDuration}
          </div>

          <button
            aria-label={playLabel}
            className={this.getClassName({ descendantName: "loadAction" })}
            onClick={this.handleClickLoad}
          >
            <span
              className={this.getClassName({
                descendantName: "loadActionInner"
              })}
            />
          </button>
        </div>
      );
    }

    return (
      <div
        className={this.getClassName()}
        data-autoplay-in-modal={autoplayInModal}
        data-duration={duration}
        data-gated-cookie-name={gatedCookieName}
        data-gated-form-content-location={gatedFormContentLocation}
        data-gated-form-id={gatedFormId}
        data-video-id={videoId}
        data-kind={kind}
        data-play-label={playLabel}
        data-rehydratable={getRehydratableName(VideoPlayerCore.displayName)}
        data-kaltura={kaltura}
      >
        {content}
        {modal}
      </div>
    );
  }
}

const VideoPlayerCoreWithContext = ({ autoplayInModal, ...props }) => (
  <IsModalOpenConsumer>
    {isModalOpen => (
      <VideoPlayerCore
        {...props}
        allowAutoplay={autoplayInModal && isModalOpen}
        autoplayInModal={autoplayInModal}
      />
    )}
  </IsModalOpenConsumer>
);

VideoPlayerCoreWithContext.propTypes = {
  ...propTypes
};
VideoPlayerCoreWithContext.displayName = "VideoPlayerCore";

export default VideoPlayerCoreWithContext;
