import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

class GlobalFooter extends Component {
  static displayName = "GlobalFooter";

  /* eslint-disable max-len */
  static propTypes = {
    /**
     * GlobalFooterItems representing the links
     */
    children: PropTypes.node.isRequired,
    /**
     * URL for the logo link
     */
    logoHref: PropTypes.string.isRequired,
    /**
     * Alternative text for the logo
     */
    logoText: PropTypes.string,
    /**
     * path to logo file
     */
    logoPath: PropTypes.string,

    /**
     * logo size options
     */
    logoSize: PropTypes.string
  };
  /* eslint-enable max-len */

  static defaultProps = {
    logoText: "LSEG",
    logoSize: "horizontal"
  };

  constructor(props) {
    super(props);

    this.getClassName = getClassNameFactory(GlobalFooter.displayName);
    this.ref = null;

    const mobileWidth = 768;

    if (typeof window !== "undefined" && window.innerWidth <= mobileWidth) {
      this.state = {
        expanded: false
      };
    } else
      this.state = {
        expanded: true
      };
  }

  componentDidUpdate() {
    if (!this.ref) {
      return;
    }

    if (this.state.expanded) {
      const componentBottomY = this.ref.offsetTop + this.ref.offsetHeight;

      // if bottom of component below viewport
      if (componentBottomY > window.innerHeight) {
        this.ref.scrollIntoView(true);
      }
    }
  }

  toggleExpand() {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    const { children, logoHref, logoText, logoPath, logoSize } = this.props;
    const { expanded } = this.state;

    return (
      <div
        className={this.getClassName({
          modifiers: classNames({
            expanded
          })
        })}
        data-logo-href={logoHref}
        data-logo-text={logoText}
        data-logo-path={logoPath}
        data-logo-size={logoSize}
        data-rehydratable={getRehydratableName(GlobalFooter.displayName)}
        ref={ref => (this.ref = ref)}
      >
        <div className={this.getClassName({ descendantName: "inner" })}>
          <div className={this.getClassName({ descendantName: "top" })}>
            <a
              className={this.getClassName({
                descendantName: "logo"
              })}
              href={logoHref}
            >
              {logoPath ? (
                <div
                  className={this.getClassName({
                    descendantName: "logoContainer"
                  })}
                >
                  <img
                    className={this.getClassName({
                      descendantName: "logoImg",
                      className: `${this.getClassName()}-${logoSize}`
                    })}
                    loading="lazy"
                    src={logoPath}
                    alt={logoText}
                  />
                </div>
              ) : (
                <div
                  className={this.getClassName({
                    descendantName: "logoInner",
                    className: `${this.getClassName()}-${logoSize}`
                  })}
                >
                  <span
                    className={this.getClassName({
                      descendantName: "logoLabel",
                      utilities: "hiddenVisually"
                    })}
                  >
                    {logoText}
                  </span>
                </div>
              )}
            </a>

            <button
              className={this.getClassName({ descendantName: "button" })}
              onClick={this.toggleExpand.bind(this)}
              aria-expanded={expanded}
              aria-label="More links"
            >
              <span className={this.getClassName({ descendantName: "caret" })}>
                {expanded ? (
                  <SVGIcon
                    name="caret"
                    style={{ transform: "rotate(180deg)" }}
                    size="s"
                  />
                ) : (
                  <SVGIcon name="caret" size="s" />
                )}
              </span>
            </button>
          </div>

          <nav
            className={classNames({
              "u-hiddenVisually": !expanded,
              [`${this.getClassName({ modifiers: "hideOnMobile" })}`]: !expanded
            })}
          >
            <ul
              className={this.getClassName({ descendantName: "items" })}
              data-rehydratable-children
            >
              {children}
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}

export default GlobalFooter;
