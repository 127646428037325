import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const BreadcrumbItem = ({ current, href, text }) => {
  const getClassName = getClassNameFactory(BreadcrumbItem.displayName);

  return (
    <li
      className={getClassName({
        states: current ? "current" : null
      })}
    >
      <a
        {...current && {
          "aria-current": "page"
        }}
        className={getClassName({
          descendantName: "inner",
          className: typestack("p3Link")
        })}
        href={href}
      >
        {text}
      </a>
    </li>
  );
};

BreadcrumbItem.displayName = "BreadcrumbItem";

BreadcrumbItem.propTypes = {
  /**
   * Whether or not this is the current item.
   */
  current: PropTypes.bool,

  /**
   * Path this item leads to. Should always be specified, even if it's the
   * current item, in order to complete the schema.org object.
   */
  href: PropTypes.string.isRequired,

  /**
   * Text to show in this item.
   */
  text: PropTypes.string.isRequired
};

export default BreadcrumbItem;
