const getFromOffset = function({ rect, offset, direction }) {
  switch (direction) {
    case "toRight":
      return offset - rect.width;
    case "toLeft":
    default:
      return offset;
  }
};

const elementFromPosition = function({
  index,
  rect,
  offset,
  width,
  direction
}) {
  if (index === 0) {
    return offset;
  }

  if (typeof offset === "number") {
    return getFromOffset({ rect, offset, direction });
  }

  switch (direction) {
    case "toRight":
      return -rect.width;
    case "toLeft":
    default:
      return width;
  }
};

const elementToPosition = function({ rect, width, direction }) {
  switch (direction) {
    case "toRight":
      return width;
    case "toLeft":
    default:
      return -rect.width;
  }
};

const nextElementFromPosition = function({
  mode,
  from,
  direction,
  rect,
  width
}) {
  const start = from || 0;
  const initialElementWidth = rect.width + start;

  switch (mode) {
    case "wait":
      switch (direction) {
        case "toRight":
          return width;
        case "toLeft":
        default:
          return -rect.width;
      }
    case "smooth": {
      const isElementWidthIsGreater = rect.width > width;
      const remainingScreenWidth = width - rect.width;

      switch (direction) {
        case "toRight":
          return isElementWidthIsGreater ? 0 : remainingScreenWidth;
        case "toLeft":
        default:
          return isElementWidthIsGreater ? remainingScreenWidth : 0;
      }
    }
    case "chain":
    default:
      switch (direction) {
        case "toRight":
          return 0;
        case "toLeft":
        default:
          return initialElementWidth > width
            ? width - rect.width
            : width - rect.left - rect.width;
      }
  }
};

export default ({ mode, index, rect, offset, width, direction }) => {
  // transform from position
  const from = elementFromPosition({ index, rect, offset, width, direction });
  // transform end position
  const to = elementToPosition({ rect, width, direction });

  return {
    from,
    to,
    // next element transform start position.
    next: nextElementFromPosition({ mode, from, direction, rect, width })
  };
};
