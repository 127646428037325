import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import classNames from "classnames";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

import DateTime from "@emcm-ui/component-date-time";

const ArticleCardFooter = ({
  attributionName,
  dateTime,
  locked,
  readingTime,
  icon,
  lockedIcon,
  pageType
}) => {
  const getClassName = getClassNameFactory(ArticleCardFooter.displayName);

  const getDotSeparator = showSeparator =>
    showSeparator && (
      <span
        className={getClassName({ descendantName: "dotSeparator" })}
        aria-hidden="true"
      >
        •
      </span>
    );

  return (
    <div
      className={getClassName({
        modifiers: classNames({}),
        className: typestack("p3")
      })}
    >
      <div className={getClassName({ descendantName: "dateAndAttribution" })}>
        {dateTime && <DateTime dateTime={dateTime} lang="enUS" />}
        {attributionName && (
          <span>
            {getDotSeparator(dateTime)}
            {attributionName}
          </span>
        )}
      </div>

      {readingTime && (
        <div className={getClassName({ descendantName: "readingTime" })}>
          {getDotSeparator(dateTime || attributionName)}
          {icon && (
            <div
              className={getClassName({ descendantName: "readingTimeIcon" })}
            >
              {icon}
            </div>
          )}
          {readingTime}
        </div>
      )}

      {pageType && (
        <div className={getClassName({ descendantName: "pageType" })}>
          {getDotSeparator(dateTime || attributionName || readingTime)}
          {pageType}
        </div>
      )}

      {locked && (
        <div className={getClassName({ descendantName: "lockIcon" })}>
          {lockedIcon}
        </div>
      )}
    </div>
  );
};

ArticleCardFooter.displayName = "ArticleCardFooter";

/* eslint-disable max-len */
ArticleCardFooter.propTypes = {
  /**
   * Name of the article attribution.
   */
  attributionName: PropTypes.string,
  /**
   * An ISO 8601, RFC 2822 or `new Date(str)` compatible date string.
   */
  dateTime: PropTypes.string,
  /**
   * Optionally display a locked content indicator
   */
  locked: PropTypes.bool,
  /**
   * Reading time for the article. Use SI and common non-SI format (English: s = seconds, min = minutes, h = hours, d = days, i.e. "15 min" or "1 h". See https://en.wikipedia.org/wiki/International_System_of_Units#Units_and_prefixes.)
   */
  readingTime: PropTypes.string,
  /**
   * Time icon
   */
  icon: PropTypes.node,
  /**
   * Locked icon
   */
  lockedIcon: PropTypes.node,
  /**
   *  page type meta data for cards
   */
  pageType: PropTypes.string
};
/* eslint-enable max-len */

ArticleCardFooter.defaultProps = {};

export default ArticleCardFooter;
