import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Typestack from "@emcm-ui/component-typestack";
import Image from "@emcm-ui/component-image";

import ResultContent from "./ResultContent";
import ArticleInfoFooter from "./components/ArticleInfoFooter";
import TagFooter from "./components/TagFooter";
import EventInfoFooter from "./components/EventInfoFooter";

const Result = ({
  dateTime,
  description,
  eyebrow,
  footer,
  href,
  imgSrc,
  imgSrcSet,
  altText = "",
  links,
  title,
  icon,
  subtitle,
  iconInMobile,
  showFeatured,
  featuredText = "featured"
}) => {
  const getClassName = getClassNameFactory("Result");

  return (
    <div className={getClassName({ descendantName: "list" })}>
      <ResultContent
        type="list"
        dateTime={dateTime}
        description={description}
        eyebrow={eyebrow}
        footer={footer}
        links={links}
        icon={icon}
        subtitle={subtitle}
        title={title}
        iconInMobile={iconInMobile}
        showFeatured={showFeatured}
        featuredText={featuredText}
      >
        <a
          className={getClassName({ descendantName: "title" })}
          href={href}
          title={title}
        >
          <Typestack align="left" rank="h5" type="h5">
            {title}
          </Typestack>
        </a>
      </ResultContent>

      {imgSrc && (
        <div className={getClassName({ descendantName: "image" })}>
          <Image
            sizes={"364px"}
            src={imgSrc}
            srcSet={imgSrcSet}
            alt={altText}
            cover
          />
        </div>
      )}
    </div>
  );
};

Result.displayName = "SearchResults.Result";

Result.propTypes = {
  /**
   * DEPRECATED: The date associated with this search result. Should be ISO 8601.
   */
  dateTime: PropTypes.string,

  /**
   * Description for the result. Can contain markup.
   */
  description: PropTypes.node.isRequired,
  /**
   * Eyebrow text
   */
  eyebrow: PropTypes.string,
  /**
   * Footer to show on this result.
   */
  footer: PropTypes.element,
  /**
   * URL for the result.
   */
  href: PropTypes.string.isRequired,
  /**
   * Image `src`. Used if srcSet is not defined, or as a fallback.
   */
  imgSrc: PropTypes.string,
  /**
   * A comma-separated list indicating a set of possible image sources for the result. The image will be fit to cover. This is used by the user agent to determine the correct image for different screen sizes. Same format as [srcSet](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#Example_4_Using_the_srcset_and_sizes_attributes), i.e. `my-image-200.png 200w, my-image-200.png 200w`.
   */
  imgSrcSet: PropTypes.string,
  /**
   * Image `alt`. Used for image alternative information.
   */
  altText: PropTypes.string,
  /**
   * Additional links that appear at the bottom of the result.
   */
  links: PropTypes.arrayOf(PropTypes.node),
  /**
   * Title text.
   */
  title: PropTypes.string.isRequired,
  /**
   * Icon of Document Type
   */
  icon: PropTypes.node,
  /**
   * Show Icon in Mobile
   */
  iconInMobile: PropTypes.bool,
  /**
   * Show Sub Title
   */
  subtitle: PropTypes.string,
  /**
   * Show Featured Tag
   */
  showFeatured: PropTypes.bool,
  /**
   * Show Featured Text
   */
  featuredText: PropTypes.string
};

Result.ArticleInfoFooter = ArticleInfoFooter;
Result.TagFooter = TagFooter;
Result.EventInfoFooter = EventInfoFooter;

export default Result;
