import React from "react";
import { storiesOf } from "@storybook/react";
import { number, withKnobs, text, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import GlobalFooter, { GlobalFooterItem } from "./index";
import { Language } from "@emcm-ui/component-theme";
import "./GlobalFooter.css";
import GlobalFooterREADME from "../README.md";

const stories = storiesOf("Components/GlobalFooter", module);

const logoOption = {
  Refinitiv: "../images/refinitiv-brand-logo-white.svg",
  "Refinitiv with strapline": "../images/refinitiv-with-strapline-white.svg",
  LSEG: "./images/lseg-brand-logo-white.svg",
  "LSEG FX": "./images/lseg-fx-brand-logo-white.svg",
  LCH: "./images/lch-brand-logo-white.svg",
  "LSEG Data & Analytics": "./images/lseg-data-brand-logo-white.svg",
  LSE: "./images/lse-brand-logo-white.svg",
  "LSE with strapline": "./images/lse-brand-logo-with-strapline-white.svg",
  "FTSE Russell": "./images/ftse-brand-logo-white.svg",
  "FTSE Russell with strapline":
    "./images/ftse-brand-logo-with-strapline-white.svg"
};

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(GlobalFooterREADME));

const items = [
  <GlobalFooterItem href="" title="Cookie policy" key="1" />,
  <GlobalFooterItem href="#" title="Privacy statement" key="2" />,
  <GlobalFooterItem href="#" title="Terms of use" key="3" />,
  <GlobalFooterItem href="#" title="Copyright" key="4" />,
  <GlobalFooterItem href="#" title="Sitemap" key="5" />,
  <GlobalFooterItem href="#" title="Disclosures" key="6" />,
  <GlobalFooterItem href="#" title="Third-party restrictions" key="7" />
];

const arabicItems = [
  <GlobalFooterItem href="#" title="سياسة ملفات الارتباط" key="1" />,
  <GlobalFooterItem href="#" title="بيان الخصوصية" key="2" />,
  <GlobalFooterItem href="#" title="تعليمات الاستخدام" key="3" />,
  <GlobalFooterItem href="#" title="حقوق النشر" key="4" />,
  <GlobalFooterItem href="#" title="خريطة الموقع" key="5" />,
  <GlobalFooterItem href="#" title="افصاحات" key="6" />,
  <GlobalFooterItem href="#" title="قيود الجهات الخارجية" key="7" />
];

const logoSizeValue = (displayName, options, defaultValue) => {
  const horizontal = "horizontal";
  const vertical = "vertical";
  const selectFn = select(displayName, options, defaultValue);

  if (selectFn === null) return horizontal;
  switch (selectFn) {
    case logoOption.Refinitiv:
      return horizontal;
    case logoOption["Refinitiv with strapline"]:
      return horizontal;
    case logoOption.LSEG:
      return horizontal;
    case logoOption["LSEG FX"]:
      return horizontal;
    case logoOption["LSEG Data & Analytics"]:
      return horizontal;
    case logoOption["Risk Intelligence"]:
      return horizontal;
    case logoOption["Post Trade"]:
      return horizontal;
    case logoOption["Regulatory Reporting"]:
      return vertical;
    case logoOption.LSE:
      return vertical;
    case logoOption["LSE with strapline"]:
      return vertical;
    case logoOption.LCH:
      return vertical;
    case logoOption["FTSE Russell"]:
      return vertical;
    case logoOption["FTSE Russell with strapline"]:
      return vertical;
    default:
      return horizontal;
  }
};

stories
  .add("Default", () => (
    <GlobalFooter
      logoHref="#"
      logoPath={select("Logo", logoOption, null)}
      logoSize={text(
        "Logo Size",
        logoSizeValue("Logo", logoOption, null),
        null
      )}
    >
      {items.slice(0, number("Number of links", items.length))}
    </GlobalFooter>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <GlobalFooter
        logoHref="#"
        logoPath={select("Logo", logoOption, null)}
        logoSize={text(
          "Logo Size",
          logoSizeValue("Logo", logoOption, null),
          null
        )}
      >
        {arabicItems.slice(0, number("Number of links", arabicItems.length))}
      </GlobalFooter>
    </Language>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <GlobalFooter
        logoHref="#"
        logoPath={select("Logo", logoOption, null)}
        logoSize={text(
          "Logo Size",
          logoSizeValue("Logo", logoOption, null),
          null
        )}
      >
        {items}
      </GlobalFooter>
    </StaticRenderer>
  ));
