import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";
import ModalFooter from "./ModalFooter";
import uniqueId from "lodash.uniqueid";

import { Provider as IsModalOpenProvider } from "./isOpenContext";

import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

const ESCAPE_KEY_CODE = 27;

const getFocusableDescendants = node => {
  /*
   * this is based on the code provided by:
   * https://www.w3.org/TR/wai-aria-practices-1.1/examples/dialog-modal/dialog.html
   * at `aria.Utils.isFocusable`
   */
  const selector = [
    "button:not([disabled])",
    "[href]",
    "input:not([disabled])",
    "select:not([disabled])",
    "textarea:not([disabled])",
    "[tabindex]:not([tabindex='-1'])"
  ].join(", ");

  return node.querySelectorAll(selector);
};

class Modal extends Component {
  static displayName = "Modal";

  static mountedModals = [];

  static propTypes = {
    /**
     * Modal children
     */
    children: PropTypes.node,

    /**
     * label for the modal close button
     */
    closeLabel: PropTypes.string,

    /**
     * Close this component callback
     * modal must closed by parent, this function is called when a modal
     * is forced to close either by another event or the user has chosen
     * to close it.
     */
    closeThisComponent: PropTypes.func.isRequired,

    /**
     * Modal title shown in header
     */
    title: PropTypes.string,

    /**
     * Modal title shown in header
     */
    showClose: PropTypes.bool,

    /**
     * Close the modal when overlay is clicked
     */
    closeOnAwayClick: PropTypes.bool,

    /**
     * Modal Style value
     */
    modalStyle: PropTypes.oneOf(["flyInStyle", "dialogStyle"]),

    /**
     * Icon Node
     */
    icon: PropTypes.node,

    /**
     * To set the icon color based on icon type - success, warning or error
     */
    status: PropTypes.oneOf(["success", "warning", "error"])
  };

  static defaultProps = {
    showClose: true,
    closeOnAwayClick: true,
    modalStyle: "",
    closeLabel: "close",
    status: "success"
  };

  constructor(props) {
    super(props);

    this.contentRef = React.createRef();
    this.dialogRef = React.createRef();
    this.ref = React.createRef();

    this.appRoot = document.getElementById("root");
    this.modalRoot = document.getElementById("modalRoot");

    this.body = document.body;
    this.el = document.createElement("div");

    this.getClassName = getClassNameFactory(Modal.displayName);

    this.supportPassive = false;
  }

  componentDidMount() {
    /*
     * The portal element is inserted in the DOM tree after
     * the Modal's children are mounted, meaning that children
     * will be mounted on a detached DOM node. If a child
     * component requires to be attached to the DOM tree
     * immediately when mounted, for example to measure a
     * DOM node, or uses 'autoFocus' in a descendant, add
     * state to Modal and only render the children when Modal
     * is inserted in the DOM tree.
     */
    this.modalRoot.appendChild(this.el);

    /**
     * hide all other page content from screen readers
     * https://www.w3.org/TR/wai-aria-1.0/states_and_properties#aria-hidden
     */
    this.appRoot.setAttribute("aria-hidden", "true");

    const scrollTop = window.scrollY || window.pageYOffset || 0;

    this.lastScrollRatio = scrollTop / this.body.scrollHeight;

    this.body.classList.add("is-scrollLocked");

    document.addEventListener("focus", this.handleFocus, true);
    document.addEventListener("keydown", this.handleKeydown, false);

    this.lastFocusEl = this.contentRef;
    this.contentRef.current.focus();

    // close all other modals, and add this instance to the list
    Modal.mountedModals.forEach(e => e.handleClose());
    Modal.mountedModals.push(this);
  }

  componentWillUnmount() {
    this.modalRoot.removeChild(this.el);

    this.appRoot.removeAttribute("aria-hidden");

    this.body.classList.remove("is-scrollLocked");

    document.removeEventListener("focus", this.handleFocus, true);
    document.removeEventListener("keydown", this.handleKeydown, false);

    const scrollTop = this.lastScrollRatio * this.body.scrollHeight;

    if (typeof window.scroll !== "undefined") {
      window.scroll(0, scrollTop);
    }

    // remove this instance from the list of mounted modals
    Modal.mountedModals = Modal.mountedModals.filter(e => e !== this);
  }

  handleClose = event => {
    if (event) {
      event.stopPropagation();
    }

    this.props.closeThisComponent();
  };

  handleKeydown = event => {
    if (event.keyCode === ESCAPE_KEY_CODE && this.props.closeOnAwayClick) {
      this.handleClose();
    }
  };

  handleOverlayClick = event => {
    // close if target element is the overlay, not the dialog
    if (event.target === this.ref.current && this.props.closeOnAwayClick) {
      this.handleClose(event);
    }
  };

  /*
   * this is based on the code provided by:
   * https://www.w3.org/TR/wai-aria-practices-1.1/examples/dialog-modal/dialog.html
   * at `aria.Dialog.prototype.trapFocus`
   */
  handleFocus = event => {
    const isTargetWithinModal = this.dialogRef.current.contains(event.target);

    if (isTargetWithinModal) {
      this.lastFocusEl = event.target;

      return;
    }

    const focusableDialogDescendants = getFocusableDescendants(
      this.dialogRef.current
    );

    if (!focusableDialogDescendants.length) {
      this.contentRef.focus();

      return;
    }

    if (this.lastFocusEl === focusableDialogDescendants[0]) {
      focusableDialogDescendants[focusableDialogDescendants.length - 1].focus();
    } else {
      focusableDialogDescendants[0].focus();
    }

    this.lastFocusEl = document.activeElement;
  };

  render() {
    /*
     * Bracket the dialog node with two invisible, focusable nodes.
     * While this dialog is open, we use these to make sure that focus never
     * leaves the document even if dialogNode is the first or last node.
     *
     * following example of:
     * https://www.w3.org/TR/wai-aria-practices-1.1/examples/dialog-modal/dialog.html
     * see: `aria.Dialog` constructor
     */
    const { modalStyle, icon, status } = this.props;
    const dialogId = uniqueId("modalDialog-");

    return ReactDOM.createPortal(
      <IsModalOpenProvider value={true}>
        {/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          className={this.getClassName({
            modifiers: classNames(`${modalStyle}`)
          })}
          onClick={this.handleOverlayClick}
          ref={this.ref}
        >
          {/* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex="0"
          />
          <div
            id={dialogId}
            aria-modal={true}
            aria-labelledby={`${dialogId}-title`}
            className={this.getClassName({ descendantName: "dialog" })}
            role="dialog"
            ref={this.dialogRef}
          >
            <div className={this.getClassName({ descendantName: "header" })}>
              <div
                className={this.getClassName({ descendantName: "headerOuter" })}
              >
                <div
                  className={this.getClassName({
                    descendantName: "headerInner"
                  })}
                >
                  {icon && (
                    <span
                      className={this.getClassName({
                        descendantName: "icon",
                        modifiers: status
                      })}
                      data-heading-icon
                    >
                      {icon}
                    </span>
                  )}
                  {this.props.title && (
                    <h2
                      id={`${dialogId}-title`}
                      className={this.getClassName({
                        descendantName: "title",
                        className: typestack("h2")
                      })}
                    >
                      {this.props.title}
                    </h2>
                  )}
                  {this.props.showClose && (
                    <button
                      aria-label={this.props.closeLabel}
                      className={this.getClassName({
                        descendantName: "button"
                      })}
                      onClick={this.handleClose}
                    >
                      <span
                        className={this.getClassName({
                          descendantName: "buttonLabel",
                          utilities: "typographySmallCaps"
                        })}
                      >
                        {this.props.closeLabel}
                      </span>
                      <span
                        className={this.getClassName({
                          descendantName: "buttonClose"
                        })}
                      >
                        <SVGIcon name="close" size="s" />
                      </span>
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div
              className={this.getClassName({ descendantName: "content" })}
              ref={this.contentRef}
            >
              <div
                className={this.getClassName({
                  descendantName: "contentOuter"
                })}
              >
                <div
                  className={this.getClassName({
                    descendantName: "contentInner"
                  })}
                >
                  {this.props.children}
                  <ModalFooter {...this.props} />
                </div>
              </div>
            </div>
          </div>
          <div
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex="0"
          />
        </div>
      </IsModalOpenProvider>,
      this.el
    );
  }
}

export default Modal;
