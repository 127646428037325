import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text, number, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import Grid from "@emcm-ui/component-grid";

import InputfieldCounter from "./index";
import "./InputfieldCounter.css";
import { action } from "@storybook/addon-actions";

import InputfieldCounterREADME from "../README.md";

const stories = storiesOf("Components/E-Commerce/InputfieldCounter", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(InputfieldCounterREADME));
const inputFieldChange = action("Input state");

const captureinputState = value => inputFieldChange(value);

const defaultMaxLength = 4;

stories
  .add("Default", () => (
    <InputfieldCounter
      variant={select("variant", Grid.variants, "2/3,1/3")}
      content={text(
        "Content",
        "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries"
      )}
      changeValue={number("Change factor", 1, { min: 0 })}
      onInputChange={captureinputState}
      arialabel={text("Aria-label", "counter")}
      subtitle={text("Subtitle", "Subtitle")}
      inputId="input-counter"
      increasedArialabel={text(
        "Aria-label for increase button",
        "Increase the counter by"
      )}
      decreasedArialabel={text(
        "Aria-label for decrease button",
        "Decrease the counter by"
      )}
      maxlength={number("Max-length", defaultMaxLength, { min: 1 })}
    />
  ))

  .add("Rehydrated", () => (
    <StaticRenderer>
      <InputfieldCounter
        variant={select("variant", Grid.variants, "2/3,1/3")}
        content={text(
          "Content",
          "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries"
        )}
        changeValue={number("Change factor", 1, { min: 0 })}
        onInputChange={captureinputState}
        arialabel={text("Aria-label", "counter")}
        subtitle={text("Subtitle", "Subtitle")}
        inputId="input-counter"
        increasedArialabel={text(
          "Aria-label for increase button",
          "Increase the counter by"
        )}
        decreasedArialabel={text(
          "Aria-label for decrease button",
          "Decrease the counter by"
        )}
        maxlength={number("Max-length", defaultMaxLength, { min: 1 })}
      />
    </StaticRenderer>
  ));
