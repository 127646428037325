import React from "react";
import TextControl from "./TextControl";

// eslint-disable-next-line require-await
export default async domNode => {
  const props = {
    isRehydrating: true,
    autoComplete: domNode.getAttribute("data-autocomplete"),
    defaultValue: domNode.getAttribute("data-default-value"),
    disabled: domNode.getAttribute("data-disabled") === "true",
    id: domNode.getAttribute("data-id"),
    labelText: domNode.getAttribute("data-label-text"),
    optionalText: domNode.getAttribute("data-optional-text"),
    maxLength: domNode.getAttribute("data-maxlength"),
    name: domNode.getAttribute("data-name"),
    required: domNode.getAttribute("data-required") === "true",
    tabIndex: domNode.getAttribute("data-tabindex"),
    errorMessage: domNode.getAttribute("data-error-message"),
    type: domNode.getAttribute("data-type"),
    pattern: domNode.getAttribute("data-pattern"),
    helpText: domNode.getAttribute("data-help-text"),
    lang: domNode.getAttribute("data-lang")
  };

  return <TextControl {...props} />;
};
