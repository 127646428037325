import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import TagCloud from "./index";
import "./TagCloud.css";
import TagCloudREADME from "../README.md";
import { Section } from "@emcm-ui/component-section";
import PropTypes from "prop-types";
import StaticRenderer from "@emcm-ui/component-static-renderer";

const stories = storiesOf("Components/TagCloud", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(TagCloudREADME));

const staticTagList = [];
const filterTags = [];
const totalCount = 30;

for (let index = 1; index < totalCount; index++) {
  staticTagList.push({
    key: `item ${index}`,
    label: `item ${index}`,
    href: "#_",
    newWindow: true
  });
  filterTags.push({ key: `item ${index}`, label: `item ${index}` });
}

class Demo extends React.Component {
  static propTypes = {
    /**
     * Label for expand more button
     */
    showMore: PropTypes.string,
    /**
     * Label for expended button
     */
    showLess: PropTypes.string,
    /**
     * Label for clear all
     */
    clearAllText: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedTags: []
    };
  }

  onTagClick = tag => {
    const { selectedTags } = this.state;
    const isTagSelected = selectedTags.includes(tag);
    let updatedTags;

    if (isTagSelected) {
      const index = selectedTags.indexOf(tag);

      updatedTags = [
        ...selectedTags.slice(0, index),
        ...selectedTags.slice(index + 1)
      ];
    } else {
      updatedTags = [...selectedTags, tag];
    }

    this.setState({
      selectedTags: updatedTags
    });
  };

  clearAll = () => {
    this.setState({
      selectedTags: []
    });
  };

  render() {
    const { showMore, showLess, clearAllText } = this.props;

    return (
      <TagCloud
        tagList={filterTags}
        selectedTags={this.state.selectedTags}
        showMore={showMore}
        showLess={showLess}
        clearAllText={clearAllText}
        clearAll={this.clearAll.bind(this)}
        onClick={this.onTagClick.bind(this)}
      />
    );
  }
}

stories
  .add("Default", () => (
    <Section
      background={select(
        "Section background",
        [""].concat(Section.backgrounds)
      )}
    >
      <TagCloud
        tagList={staticTagList}
        showMore={text("Show more text", "Show more")}
        showLess={text("Show less text", "Show less")}
        heading={text("Heading Text", "Trending topics")}
      />
    </Section>
  ))
  .add("Filter tag with click handler", () => (
    <Demo
      showMore={text("Show more text", "Show more")}
      showLess={text("Show less text", "Show less")}
      clearAllText={text("Clear all text", "Clear all")}
    />
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Section
        background={select(
          "Section background",
          [""].concat(Section.backgrounds)
        )}
      >
        <TagCloud
          tagList={staticTagList}
          showMore={text("Show more text", "أظهر المزيد")}
          showLess={text("Show less text", "عرض أقل")}
          heading={text("Heading Text", "الموضوعات الشائعة")}
        />
      </Section>
    </Language>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <Section
        background={select(
          "Section background",
          [""].concat(Section.backgrounds)
        )}
      >
        <TagCloud
          tagList={staticTagList}
          showMore={text("Show more text", "Show more")}
          showLess={text("Show less text", "Show less")}
          heading={text("Heading Text", "Trending topics")}
        />
      </Section>
    </StaticRenderer>
  ));
