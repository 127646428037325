import { getHash } from "./hash";
import {
  SORT,
  SEARCH_QUERY_STRING_KEY,
  FEATURED_CARD_COUNT,
  FILTER_TOPIC_KEY,
  FETCH_RESPONSE_INDEX_SEARCH_RESULT,
  FETCH_RESPONSE_INDEX_FEATURED_CARD,
  SELECTED,
  DESELECTED,
  CROSS_FACET_FILTER
} from "./constants";
import { trackSearch } from "./analytics";

// Regex that match the query parameter key, start with x or q and followed by numeric
const queryParameterRegex = /^[x|q][0-9]?$/gi;

const getParammeters = (urlParams, parameters) => {
  const {
    sortBy,
    queryString,
    isMultipleFilterApplied,
    isTagFilterSelected,
    isPageLoad
  } = parameters;
  const urlInfo = {
    [SORT]: sortBy,
    [SEARCH_QUERY_STRING_KEY]: queryString,
    [CROSS_FACET_FILTER]: isMultipleFilterApplied,
    [DESELECTED]: isTagFilterSelected
  };

  if (isPageLoad) {
    urlParams.delete(SELECTED);
  }

  return urlInfo;
};

const getSearchUrl = (searchEndpoint, parameters) => {
  const urlParams = new URLSearchParams(getHash());
  const urlInfo = getParammeters(urlParams, parameters);

  Object.entries(urlInfo).forEach(([key, value]) => {
    if (value) {
      urlParams.set(key, value);
    }
  });

  return `${searchEndpoint}?${urlParams}`;
};

const getFeaturedCardUrl = (featuredContentUrl, parameters) => {
  const urlParams = new URLSearchParams(getHash());
  const parsedUrl = new URLSearchParams("");
  const urlInfo = getParammeters(urlParams, parameters);

  let parametersQuery = "";
  const minusIndex = -1;

  for (const [key, value] of urlParams.entries()) {
    if (
      (featuredContentUrl.includes(key) || key in urlInfo) &&
      key.match(queryParameterRegex)
    ) {
      const index = Number(key.charAt(key.length - 1));
      const newKey = key.slice(0, minusIndex) + (index + 1);

      urlInfo[newKey] = value;
    } else {
      urlInfo[key] = value;
    }
  }

  Object.entries(urlInfo).forEach(([key, value]) => {
    if (value) {
      parsedUrl.set(key, value);
    }
  });

  parametersQuery = parsedUrl.toString() ? `&${parsedUrl}` : "";

  return `${featuredContentUrl}${parametersQuery}`;
};

const getTagCloudSettings = searchResponse => {
  let tags = null;
  let selectedTags = null;

  const filters = searchResponse.filterSettings.filter(
    item => item.facetName === FILTER_TOPIC_KEY
  )[0];

  if (filters && filters.resultFilters.length > 0) {
    selectedTags = [];
    tags = filters.resultFilters
      .map(({ name, title, isSelected, ...rest }) => {
        if (isSelected) {
          selectedTags.push(name);
        }

        return {
          key: name,
          label: title,
          ...rest
        };
      })
      .sort((topic1, topic2) => topic1.label.localeCompare(topic2.label));
  }

  const tagCloudSettings = { tags, selectedTags };

  return tagCloudSettings;
};

const removeDuplicateCards = (searchResponse, featuredData) => {
  const featuredCards = featuredData
    ? featuredData.searchResults.slice(0, FEATURED_CARD_COUNT)
    : [];

  if (
    searchResponse &&
    searchResponse.searchResults.length > 0 &&
    featuredCards.length > 0
  ) {
    searchResponse.searchResults = searchResponse.searchResults.filter(
      result => {
        const filterResult = featuredCards.filter(
          card => card.path === result.path
        );

        return filterResult.length === 0;
      }
    );
  }

  const response = {
    searchResults: searchResponse.searchResults,
    featuredCards
  };

  return response;
};

export const fetchSearch = async ({
  searchEndpoint,
  featuredContentUrl,
  featuredContent,
  currentPage,
  hasSearchTerm,
  searchParameters
}) => {
  const { queryString } = searchParameters;
  const searchUrl = getSearchUrl(searchEndpoint, searchParameters);
  const featuredUrl = getFeaturedCardUrl(featuredContentUrl, searchParameters);
  const fetchPromises = [fetch(searchUrl)];

  const hasFeaturedContent = featuredContent !== null;
  const isCurrentPageFirst = currentPage === null || currentPage === 1;

  if (hasFeaturedContent && !hasSearchTerm && isCurrentPageFirst) {
    fetchPromises.push(fetch(featuredUrl));
  }

  try {
    const responses = await Promise.allSettled(fetchPromises);
    const searchResponse = await responses[
      FETCH_RESPONSE_INDEX_SEARCH_RESULT
    ].value.json();
    const featuredData = responses[1]
      ? await responses[FETCH_RESPONSE_INDEX_FEATURED_CARD].value.json()
      : null;

    const filteredCard = removeDuplicateCards(searchResponse, featuredData);

    const finalResult = {
      searchResults: filteredCard.searchResults,
      featuredCards: filteredCard.featuredCards,
      tagCloudSettings: getTagCloudSettings(searchResponse),
      pagination: searchResponse.pagination,
      totalResults: searchResponse.totalResults
    };

    if (hasSearchTerm) {
      trackSearch(searchResponse.totalResults, queryString);
    }

    return finalResult;
  } catch (error) {
    return error;
  }
};
