import React from "react";
import PropTypes from "prop-types";
import { ArticleCardFooter } from "@emcm-ui/component-card";
import Icon from "@emcm-ui/component-icon";
import {
  CARD_RESEARCH,
  CARD_DATA_VISUALIZATION,
  CARD_EVENT,
  CARD_VIDEO
} from "../../utilities/constants";

const CardFooter = ({
  pageType,
  author,
  publishedDate,
  readTime,
  eventInfoTime,
  includeReadTime,
  minLabel,
  icon
}) => {
  const pagetype = pageType.toLowerCase();
  const readingTime = readTime > 0 ? `${readTime} ${minLabel}` : "";
  const readTimeIcon = (
    <Icon path={pagetype === "video" ? icon.video : icon.clock} size="xs" />
  );
  let showAuthorName = true;
  let showReadingTime = true;
  let showIcon = true;

  switch (pagetype) {
    case CARD_RESEARCH:
    case CARD_DATA_VISUALIZATION:
      showAuthorName = false;
      showReadingTime = false;
      break;
    case CARD_VIDEO:
      showAuthorName = false;
      break;
    case CARD_EVENT:
      showIcon = false;
      showAuthorName = false;
      break;
  }

  return (
    <ArticleCardFooter
      dateTime={publishedDate || ""}
      pageType={pageType}
      {...showIcon && {
        icon: readTimeIcon
      }}
      {...showAuthorName && {
        attributionName: author
      }}
      {...showReadingTime &&
        includeReadTime && {
          readingTime: eventInfoTime || readingTime
        }}
    />
  );
};

CardFooter.displayName = "SearchResults.CardFooter";

CardFooter.propTypes = {
  /**
   * page type text
   */
  pageType: PropTypes.string,
  /**
   * page author text
   */
  author: PropTypes.string,
  /**
   * read time
   */
  readTime: PropTypes.number,
  /**
   * event info time
   */
  eventInfoTime: PropTypes.string,
  /**
   * published date
   */
  publishedDate: PropTypes.string,
  /**
   * boolean value for include read time
   */
  includeReadTime: PropTypes.bool,
  /**
   * min label text
   */
  minLabel: PropTypes.string,
  /**
   * icon
   */
  icon: PropTypes.object
};

export default CardFooter;
