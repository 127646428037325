import React from "react";
import SearchResultsMixture from "./SearchResultsMixture";

// eslint-disable-next-line require-await
export default async domNode => {
  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data ? JSON.parse(data) : null;
  };

  const cardsCount = readData("cards-count");

  const props = {
    isRehydrating: true,
    clearAllText: domNode.getAttribute("data-clear-all-text"),
    showMore: domNode.getAttribute("data-show-more"),
    showLess: domNode.getAttribute("data-show-less"),
    tagHeading: domNode.getAttribute("data-filter-heading"),
    featuredText: domNode.getAttribute("data-featured-text"),
    featuredContent: readData("featured-content"),
    featuredContentUrl: domNode.getAttribute("data-featured-content-url"),
    endpoint: domNode.getAttribute("data-endpoint"),
    viewText: domNode.getAttribute("data-viewtext"),
    noMatchesText: domNode.getAttribute("data-no-matches-text"),
    allText: domNode.getAttribute("data-all-text"),
    includeDescription: readData("include-description"),
    includeReadTime: readData("include-readtime"),
    sortBy: domNode.getAttribute("data-default-sortby"),
    minLabel: domNode.getAttribute("data-min-label"),
    topFilters: readData("top-filter-tags"),
    icon: {
      clock: domNode.getAttribute("data-clock-icon"),
      video: domNode.getAttribute("data-video-icon"),
      arrow: domNode.getAttribute("data-arrow-icon")
    },
    readArticle: domNode.getAttribute("data-read-article-text"),
    ...(cardsCount && { cardsCount })
  };

  return <SearchResultsMixture {...props} />;
};
