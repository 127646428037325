import React, { Component } from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

class Filters extends Component {
  static displayName = "SearchFilters.Filters";

  static propTypes = {
    /**
     * The child sections. Should be of type `SearchFilters.FilterSection`.
     */
    children: PropTypes.node,

    /**
     * The title of this group.
     */
    title: PropTypes.string
  };

  static defaultProps = {
    title: "Narrow by"
  };

  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false
    };

    this.getClassName = getClassNameFactory("SearchFiltersFilters");
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle() {
    this.setState({
      isExpanded: !this.state.isExpanded
    });
  }

  render() {
    return (
      <div
        className={this.getClassName({
          states: this.state.isExpanded ? "expanded" : ""
        })}
        data-rehydratable={getRehydratableName(Filters.displayName)}
        data-title={this.props.title}
      >
        <button
          className={this.getClassName({
            descendantName: "title",
            className: typestack("p1Bold")
          })}
          onClick={this.handleToggle}
        >
          <span className={this.getClassName({ descendantName: "titleText" })}>
            {this.props.title}
          </span>

          <div className={this.getClassName({ descendantName: "toggleIcon" })}>
            {this.state.isExpanded ? (
              <span
                className={this.getClassName({ descendantName: "caretUp" })}
              >
                <SVGIcon name="caret" size="s" />
              </span>
            ) : (
              <SVGIcon name="caret" size="s" />
            )}
          </div>
        </button>

        <div className={this.getClassName({ descendantName: "inner" })}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Filters;
