import PropTypes from "prop-types";
import React from "react";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import classNames from "classnames";
import GoogleMap from "@emcm-ui/component-google-map";
import Dropdown from "@emcm-ui/component-dropdown";
import CardBase from "../../bases/CardBase";
import EventCardBase from "./components/EventCardBase";
import Block from "@emcm-ui/component-block";
import Image from "@emcm-ui/component-image";

const itemsArrayShape = {
  href: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string.isRequired
};

const EventCard = ({
  id,
  apiKey,
  latitude,
  longitude,
  zoom,
  height,
  width,
  block,
  items,
  placeholder,
  placeholderIcon,
  borderVariant,
  eventSize,
  day,
  date,
  time,
  orgName,
  orgAddress,
  orgCity,
  clockIcon,
  locationIcon,
  calendarSubject,
  calendarStartDate,
  calendarEndDate,
  calendarLocation,
  isGoogleMapBlocked,
  isEventOnline,
  eventOnlineDetails
}) => {
  const getClassName = getClassNameFactory(EventCard.displayName);
  const isCoordinateAvailable = latitude && longitude;

  return (
    <div
      className={getClassName({
        modifiers: classNames({
          [borderVariant]: borderVariant
        })
      })}
      data-is-event-online={isEventOnline}
      data-event-online-details={JSON.stringify(eventOnlineDetails)}
      data-border-variant={borderVariant}
      data-api-key={apiKey}
      data-latitude={latitude}
      data-longitude={longitude}
      data-zoom={zoom}
      data-block={block}
      data-placeholder={placeholder}
      data-placeholder-icon={placeholderIcon}
      data-event-size={eventSize}
      data-day={day}
      data-date={date}
      data-time={time}
      data-org-name={orgName}
      data-org-address={orgAddress}
      data-org-city={orgCity}
      data-items={JSON.stringify(items)}
      data-clock-icon={clockIcon}
      data-location-icon={locationIcon}
      data-rehydratable={getRehydratableName(EventCard.displayName)}
    >
      {isEventOnline && (
        <div
          className={getClassName({
            modifiers: classNames({
              eventOnlineBanner: "eventOnlineBanner"
            })
          })}
        >
          <Image
            alt={eventOnlineDetails.label}
            overlay={null}
            sizes="100vw"
            src={eventOnlineDetails.banner}
          />
        </div>
      )}

      {!isEventOnline && !isGoogleMapBlocked && isCoordinateAvailable ? (
        <GoogleMap
          id={id}
          apiKey={apiKey}
          latitude={latitude}
          longitude={longitude}
          zoom={zoom}
          height={height}
          width={width}
        />
      ) : (
        !isEventOnline && (
          <Block color="red" height={48} text="Unable to load google map" />
        )
      )}

      <EventCardBase
        size={eventSize}
        day={day}
        date={date}
        time={time}
        orgName={orgName}
        orgAddress={orgAddress}
        orgCity={orgCity}
        clockIcon={clockIcon}
        locationIcon={locationIcon}
        isEventOnline={isEventOnline}
        eventOnlineDetails={eventOnlineDetails}
      />

      <Dropdown
        block={block}
        items={items}
        placeholder={placeholder}
        onValueChanged={value => {
          if (value === "apple") {
            const icsMSG = `BEGIN:VCALENDAR\nVERSION:2.0\nBEGIN:VEVENT\nDTSTART:${calendarStartDate}\nDTEND:${calendarEndDate}\nLOCATION:${calendarLocation}\nSUMMARY:${calendarSubject}\nEND:VEVENT\nEND:VCALENDAR`;

            window.open(`data:text/calendar;charset=utf8,${escape(icsMSG)}`);
          } else if (value === "google") {
            const url = "https://calendar.google.com/calendar/r/eventedit";
            const sprop = "website:my.refinitiv.com";
            const details = "Event+from+MyRefinitiv";
            const website = "https://my.refinitiv.com";

            const eventURL = `${url}?text=${calendarSubject}&dates=${calendarStartDate}/${calendarEndDate}&sprop=${sprop}&location=${calendarLocation}&details=${details}&website=${website}`;

            window.open(eventURL);
          } else if (value === "outlook") {
            const url = "https://outlook.live.com/owa/";
            const rru = "addevent";
            const body = "Event+from+Refinitiv";
            const path = "/calendar/view/Month&allday=";

            const eventURL = `${url}?rru=${rru}&subject=${calendarSubject}&startdt=${calendarStartDate}&enddt=${calendarEndDate}&location=${calendarLocation}&body=${body}&path=${path}`;

            window.open(eventURL);
          }
        }}
        size={"large"}
        placeholderIcon={placeholderIcon}
      />
    </div>
  );
};

EventCard.displayName = "EventCard";

EventCard.borderVariant = [null, "borderless"];

EventCard.propTypes = {
  /**
   * ID for google map
   */
  id: PropTypes.string,
  /**
   * API Key for google map
   */
  apiKey: PropTypes.string.isRequired,
  /**
   * Latitude in google map
   */
  latitude: PropTypes.number.isRequired,
  /**
   * Longitude in google map
   */
  longitude: PropTypes.number.isRequired,
  /**
   * Zoom for google map
   */
  zoom: PropTypes.number.isRequired,
  /**
   * Height of the map container, in pixels
   */
  height: PropTypes.number,
  /**
   * Width of the map container, in pixels
   */
  width: PropTypes.number,
  /**
   * Render the Dropdown with 100% width.
   */
  block: PropTypes.bool,
  /**
   * Array of dropdown items. Items can contain value or href, not both.
   */
  items: PropTypes.arrayOf(PropTypes.shape(itemsArrayShape)).isRequired,
  /**
   * Callback, called when value changes (unless set by property). Receives a `value` arguement.
   */
  onValueChanged: PropTypes.func,
  /**
   * Placeholder
   */
  placeholder: PropTypes.string.isRequired,
  /**
   * Placeholder Icon
   */
  placeholderIcon: PropTypes.string,
  /**
   * Value of the selected item. Will always override the existing value when set.
   */
  value: PropTypes.any,
  /**
   * day
   */
  day: PropTypes.string,
  /**
   * date
   */
  date: PropTypes.string,
  /**
   * time
   */
  time: PropTypes.string,
  /**
   * orgName
   */
  orgName: PropTypes.string,
  /**
   * orgAddress
   */
  orgAddress: PropTypes.string,
  /**
   * orgCity
   */
  orgCity: PropTypes.string,
  /**
   * Card border variations
   */
  borderVariant: PropTypes.oneOf(EventCard.borderVariant),
  /**
   * Size of the Card. Will set the minumum height and grow to fit content.
   */
  eventSize: PropTypes.oneOf(CardBase.sizes).isRequired,
  /**
   * clockIcon.
   */
  clockIcon: PropTypes.string,
  /**
   * locationIcon.
   */
  locationIcon: PropTypes.string,
  /**
   * calendarSubject.
   */
  calendarSubject: PropTypes.string,
  /**
   * calendarStartDate.
   */
  calendarStartDate: PropTypes.string,
  /**
   * calendarEndDate.
   */
  calendarEndDate: PropTypes.string,
  /**
   * calendarLocation.
   */
  calendarLocation: PropTypes.string,
  /**
   * Show Google Map.
   */
  isGoogleMapBlocked: PropTypes.bool,
  /**
   * Show Online Event.
   */
  isEventOnline: PropTypes.bool,
  /**
   * Online event details.
   */
  eventOnlineDetails: PropTypes.object
};

EventCard.defaultProps = {
  isGoogleMapBlocked: false,
  isEventOnline: false,
  clockIcon: "./icons/ui/grey/time-07.svg",
  locationIcon: "./icons/ui/grey/location.svg"
};

export default EventCard;
