import PropTypes from "prop-types";
import React from "react";

import Eyebrow from "@emcm-ui/component-eyebrow";
import Image, { anchorPoints } from "@emcm-ui/component-image";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const ChapterDivider = ({
  backgroundAnchor,
  backgroundGradient,
  backgroundSrc,
  backgroundSrcSet,
  chapterText,
  colorTheme,
  id,
  titleText
}) => {
  const getClassName = getClassNameFactory("LongformChapterDivider");

  return (
    <div className={getClassName({ modifiers: colorTheme })} id={id}>
      <div className={getClassName({ descendantName: "background" })}>
        <div className={getClassName({ descendantName: "backgroundImage" })}>
          {backgroundSrc ? (
            <Image
              alt=""
              anchor={backgroundAnchor}
              fit="cover"
              sizes={Image.sizes.edgeToEdge}
              src={backgroundSrc}
              srcSet={backgroundSrcSet}
            />
          ) : null}
        </div>
      </div>

      {backgroundSrc && backgroundGradient ? (
        <div className={getClassName({ descendantName: "background" })}>
          <div
            className={getClassName({ descendantName: "backgroundGradient" })}
          />
        </div>
      ) : null}

      <div className={getClassName({ descendantName: "content" })}>
        <div className={getClassName({ descendantName: "inner" })}>
          {chapterText && (
            <div
              className={getClassName({
                descendantName: "chapter",
                utilities: "textAntialiased"
              })}
            >
              <Eyebrow text={chapterText} />
            </div>
          )}
          <h2
            className={getClassName({
              descendantName: "title",
              className: typestack("h2")
            })}
          >
            {titleText}
          </h2>
        </div>
      </div>
    </div>
  );
};

/* eslint-disable max-len */
ChapterDivider.propTypes = {
  /**
   * Anchor point for the background image.
   */
  backgroundAnchor: PropTypes.oneOf(anchorPoints),

  /**
   * A backgroundGradient to apply over the background image. Should not be used if the image has a rasterized backgroundGradient.
   */
  backgroundGradient: PropTypes.bool,

  /**
   * Background image `src`. Used if srcSet is not defined, or as a fallback.
   */
  backgroundSrc: PropTypes.string,

  /**
   * A comma-separated list indicating a set of possible image sources for the background. The image will be fit to cover. This is used by the user agent to determine the correct image for different screen sizes. Same format as [srcSet](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#Example_4_Using_the_srcset_and_sizes_attributes), i.e. `my-image-200.png 200w, my-image-200.png 200w`.
   */
  backgroundSrcSet: PropTypes.string,

  /**
   * Used for the chapter text of this section
   */
  chapterText: PropTypes.string,

  /**
   * Color theme. This is the color of the text, not the asset.
   */
  colorTheme: PropTypes.oneOf(["dark", "light"]),

  /**
   * ID for the element. Use with ChapterNav.
   */
  id: PropTypes.string,

  /**
   * Used for the title of this section
   */
  titleText: PropTypes.string.isRequired
};
/* eslint-enable max-len */

ChapterDivider.defaultProps = {
  backgroundAnchor: "C",
  backgroundGradient: true,
  colorTheme: "light"
};

ChapterDivider.displayName = "Longform.ChapterDivider";

export default ChapterDivider;
