import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import SiteHeader from "./SiteHeader";

const rehydrator = async (domNode, rehydrateChildren) => {
  const getClassName = getClassNameFactory("SiteHeader");

  const childrenNode = domNode.querySelector(
    `.${getClassName({ descendantName: "menus" })}`
  );
  const reactifiedChildren = await rehydrateChildren(childrenNode);

  const logoLinkNode = domNode.querySelector(
    `a.${getClassName({ descendantName: "logo" })}`
  );
  const siteNameNode = domNode.querySelector(
    `.${getClassName({ descendantName: "siteName" })}`
  );
  const getSiteNameNodeContent = () => {
    return siteNameNode
      ? domNode.getAttribute("data-site-name") ||
          siteNameNode.textContent.replace(/[\n\r]+|[\s]{2,}/g, " ").trim() ||
          siteNameNode.innerText
      : null;
  };

  const autosuggestion = domNode.getAttribute("data-autosuggestion");
  const size = domNode.getAttribute("data-size");
  const searchMinLength = domNode.getAttribute("data-search-min-length");
  const placeholder = domNode.getAttribute("data-placeholder");
  const searchSubmitAriaLabel = domNode.getAttribute(
    "data-search-submit-label"
  );
  const searchClearAriaLabel = domNode.getAttribute("data-clear-search-label");

  const props = {
    logoHref: logoLinkNode.href,
    logoText: domNode.getAttribute("data-logo-text"),
    logoPath: domNode.getAttribute("data-logo-path"),
    logoSize: domNode.getAttribute("data-logo-size"),
    search: domNode.getAttribute("data-search") === "true",
    searchFormAction: domNode.getAttribute("data-search-form-action") || null,
    searchFormMethod: domNode.getAttribute("data-search-form-method"),
    searchInputName: domNode.getAttribute("data-search-input-name"),
    siteName: getSiteNameNodeContent(),
    typeaheadUrl: domNode.getAttribute("data-typeahead-url") || null,
    elasticKey: domNode.getAttribute("data-elastic-key") || null,
    languageEnabled: domNode.getAttribute("data-language-enabled") === "true",
    languageItems: JSON.parse(domNode.getAttribute("data-language-items")),
    languageValue: domNode.getAttribute("data-language-value") || null,
    ...(searchMinLength && {
      searchMinLength: parseInt(searchMinLength)
    }),
    ...(autosuggestion && {
      autosuggestion
    }),
    ...(size && {
      size: parseInt(size)
    }),
    ...(placeholder && {
      placeholder
    }),
    ...(searchSubmitAriaLabel && {
      searchSubmitAriaLabel
    }),
    ...(searchClearAriaLabel && {
      searchClearAriaLabel
    })
  };

  return <SiteHeader {...props}>{reactifiedChildren}</SiteHeader>;
};

export default rehydrator;
