import React from "react";
import Tabs from "./Tabs";
import Tab from "./components/Tab";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import AjaxPanel from "@emcm-ui/component-panel/lib/Panel/Panel";

const getClassName = getClassNameFactory(Tabs.displayName);

export default async (domNode, rehydrateChildren, extra) => {
  const getPanelClassName = getClassNameFactory(AjaxPanel.displayName);
  const getTabClassName = getClassNameFactory(Tab.displayName);

  const panelNodes = domNode.querySelectorAll(
    `.${getClassName({ descendantName: "panels" })} > .${getPanelClassName()}`
  );
  const tabNodes = domNode.querySelectorAll(
    `.${getClassName({ descendantName: "list" })} .${getTabClassName()}`
  );

  const panels = [];
  const tabs = [];

  for (let i = 0; i < panelNodes.length; i++) {
    panels.push(
      await AjaxPanel.getPanelProps(panelNodes[i], rehydrateChildren, extra)
    );
  }

  for (let i = 0; i < tabNodes.length; i++) {
    tabs.push(Tab.getTabsProps(tabNodes[i]));
  }

  // derive the children from the tab links list
  // and then merge with any content available in the children
  const derivedChildren = tabs
    .reduce((acc, tab) => {
      const panel = panels.find(p => p.id === tab.relatedId) || {};

      acc.push(Object.assign(tab, panel));

      return acc;
    }, [])
    .map((childProps, index) => (
      <AjaxPanel
        key={index}
        reactFromMarkupRehydrators={extra.rehydrators}
        reactFromMarkupOptions={{ extra }}
        {...childProps}
      />
    ));

  const props = {
    analytics: extra.analytics,
    defaultTab: domNode.getAttribute("data-default-tab"),
    layoutVariant: domNode.getAttribute("data-layout-variant")
  };

  return <Tabs {...props}>{derivedChildren}</Tabs>;
};
