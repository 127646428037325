import React from "react";
import Figure from "./Figure";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const getClassName = getClassNameFactory("Figure");

const rehydrator = async (domNode, rehydrateChildren, extra) => {
  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data ? JSON.parse(data) : null;
  };

  const captionNode = domNode.querySelector(
    `.${getClassName({ descendantName: "caption" })}`
  );
  const childrenNode = domNode.querySelector(
    `.${getClassName({ descendantName: "imageWrapper" })}`
  );
  const reactifiedCaption = captionNode
    ? await rehydrateChildren(captionNode, extra)
    : null;
  const reactifiedChildren = childrenNode
    ? await rehydrateChildren(childrenNode, extra)
    : null;

  const props = {
    attribution: domNode.getAttribute("data-attribution"),
    caption: reactifiedCaption,
    children: reactifiedChildren,
    expandable: readData("expandable"),
    expandableUrl: domNode.getAttribute("data-expandable-url"),
    expandAriaLabel: domNode.getAttribute("data-expand-aria-label"),
    imageAlt: domNode.getAttribute("data-image-alt"),
    closeLabel: domNode.getAttribute("data-close-label")
  };

  return <Figure {...props} />;
};

export default rehydrator;
