import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory, {
  toggleInvalidClass
} from "@emcm-ui/utility-class-names";
import classNames from "classnames";
import AutosizeInput from "react-input-autosize";
import MultiDownshift from "./components/MultiDownshift";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import { getItems } from "./utilities";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const CONST_FOCUS = "focus";
const CONST_BACKSPACE = "Backspace";

class MultiSelectDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.input = React.createRef();
    this.multiSelectContainer = React.createRef();

    this.state = {
      hasFocus: false,
      hasError: false
    };
  }

  onInputKeyUp = (event, multiSelectAttributes) => {
    const { inputValue, removeItem, selectedItems } = multiSelectAttributes;

    if (event.key === CONST_BACKSPACE && !inputValue) {
      const lastIndex = -1;
      const lastItem = selectedItems.at(lastIndex);

      removeItem(lastItem);
    }
  };

  updateState = event => {
    const hasFocus = event.type === CONST_FOCUS;

    this.setState({ hasFocus });
  };

  handleToggleInvalidClass = () => {
    if (this.state.hasError === false) {
      const inputContainer = this.multiSelectContainer.current.parentNode;

      if (inputContainer && inputContainer.classList.contains("is-invalid"))
        inputContainer.classList.remove("is-invalid");
    }
  };

  displayMessage = (inputValue, selectedItems, isOpen) => {
    const { labelText, placeholder } = this.props;
    const isInputEmpty = selectedItems.length === 0 && !inputValue;

    if (!isInputEmpty) {
      return;
    }

    const shouldDisplayLabel = !isOpen && labelText;
    let descendantName = "placeholder";
    let displayText = placeholder;

    if (shouldDisplayLabel) {
      descendantName = "labelText";
      displayText = labelText;
    }

    return (
      <span className={this.getClassName({ descendantName })}>
        {displayText}
      </span>
    );
  };

  render() {
    const {
      id,
      name,
      noOptionsMessage,
      options,
      placeholder,
      errorMessage,
      required,
      labelText,
      helpText
    } = this.props;

    const { hasFocus } = this.state;

    return (
      <MultiDownshift
        onChange={this.handleSelection}
        itemToString={this.itemToString}
      >
        {({
          getInputProps,
          getToggleButtonProps,
          getMenuProps,
          getRemoveButtonProps,
          removeItem,
          isOpen,
          inputValue,
          selectedItems,
          getItemProps,
          highlightedIndex,
          toggleMenu
        }) => {
          const formInputValues = selectedItems
            .map(item => item.value)
            .toString();

          const dropdownOnClickKeypressHandler = () => {
            toggleMenu();
            if (!isOpen) {
              this.input.current.focus();
            }
          };

          return (
            <div
              className={toggleInvalidClass(
                this.getClassName(),
                this.props.required,
                this.state.hasError
              )}
              data-rehydratable={getRehydratableName(
                MultiSelectDropdown.displayName
              )}
              data-id={id}
              data-name={name}
              data-placeholder={placeholder}
              data-options={JSON.stringify(options)}
              data-no-options-message={noOptionsMessage}
              data-required={required}
              data-error-message={errorMessage}
              data-label-text={labelText}
              data-help-text={helpText}
            >
              <div
                ref={this.multiSelectContainer}
                className={toggleInvalidClass(
                  this.getClassName({
                    descendantName: "dropdown",
                    states: classNames({
                      open: isOpen,
                      focus: hasFocus,
                      populated: selectedItems.length > 0
                    })
                  }),
                  this.props.required,
                  this.state.hasError
                )}
                onClick={dropdownOnClickKeypressHandler}
                onKeyPress={dropdownOnClickKeypressHandler}
                tabIndex={0}
                role="button"
                data-testid="dropdown"
              >
                <input
                  className={this.getClassName({
                    descendantName: "form-input"
                  })}
                  name={name}
                  value={formInputValues}
                  required={required}
                  type="text"
                  {...required &&
                    this.state.hasError && {
                      "aria-invalid": true
                    }}
                />
                {this.displayMessage(inputValue, selectedItems, isOpen)}
                <ul
                  className={this.getClassName({
                    descendantName: "inner"
                  })}
                >
                  {(selectedItems.length > 0 || inputValue) &&
                    selectedItems.map(item => (
                      <li
                        key={item.value}
                        className={this.getClassName({
                          descendantName: "multiValue"
                        })}
                        data-testid="multiValue"
                      >
                        <button
                          {...getRemoveButtonProps({
                            item,
                            className: this.getClassName({
                              descendantName: "multiValueClose"
                            }),
                            "data-testid": "multiValueClose"
                          })}
                          aria-label={`Remove filter: ${item.label}`}
                        >
                          <SVGIcon name="close" size="xs" />
                          <span
                            className={this.getClassName({
                              descendantName: "multiValueLabel",
                              className: typestack("p1")
                            })}
                          >
                            {item.label}
                          </span>
                        </button>
                      </li>
                    ))}
                  <AutosizeInput
                    {...getInputProps({
                      ref: this.input,
                      onKeyUp: event => {
                        const multiSelectAttributes = {
                          inputValue,
                          removeItem,
                          selectedItems
                        };

                        this.onInputKeyUp(event, multiSelectAttributes);
                      },
                      onFocus: this.updateState,
                      onBlur: this.updateState,
                      inputClassName: this.getClassName({
                        descendantName: "input"
                      }),
                      "data-input": "autosize",
                      "data-testid": "textInput",
                      id: `autosizeinput-${name}`,
                      value: inputValue || ""
                    })}
                  />
                </ul>
                <button
                  aria-hidden={true}
                  tabIndex={-1}
                  {...getToggleButtonProps({
                    // prevents the menu from immediately toggling
                    // closed (due to our custom click handler above).
                    onClick(event) {
                      event.stopPropagation();
                    }
                  })}
                  className={this.getClassName({
                    descendantName: "controllerButton"
                  })}
                  data-testid="controllerButton"
                >
                  <span
                    className={this.getClassName({
                      descendantName: "controllerArrow",
                      states: classNames({ open: isOpen })
                    })}
                  >
                    <SVGIcon name="caret" size="s" />
                  </span>
                </button>
                <div
                  className={this.getClassName({ descendantName: "errorIcon" })}
                >
                  <SVGIcon name="error" size="s" />
                </div>
              </div>
              <ul
                {...getMenuProps()}
                className={this.getClassName({
                  descendantName: "menu",
                  states: classNames({ open: isOpen })
                })}
                data-testid="menu"
              >
                {isOpen
                  ? (() => {
                      const items = getItems({ options, filter: inputValue });

                      return items.length ? (
                        items.map((item, index) => (
                          <li
                            key={item.value}
                            {...getItemProps({
                              item,
                              index
                            })}
                            className={this.getClassName({
                              descendantName: "item",
                              states: classNames({
                                active: highlightedIndex === index,
                                selected: selectedItems.some(
                                  i =>
                                    this.itemToString(i) ===
                                    this.itemToString(item)
                                )
                              })
                            })}
                            data-testid="menuItem"
                          >
                            {item.label}
                          </li>
                        ))
                      ) : (
                        <li
                          className={this.getClassName({
                            descendantName: "noOptionsMessage"
                          })}
                          data-testid="noOptionsMessage"
                        >
                          {noOptionsMessage}
                        </li>
                      );
                    })()
                  : null}
              </ul>
              <span>
                {required && (
                  <span
                    id={`error-${id}`}
                    className={this.getClassName({ descendantName: "message" })}
                    role="alert"
                  >
                    {errorMessage}
                  </span>
                )}
              </span>
              {helpText && (
                <div
                  className={this.getClassName({ descendantName: "helpText" })}
                >
                  {helpText}
                </div>
              )}
            </div>
          );
        }}
      </MultiDownshift>
    );
  }

  getClassName = getClassNameFactory(MultiSelectDropdown.displayName);

  itemToString = item => (item ? item.value : "");

  handleSelection = (event, ...args) => {
    const { handleChange } = this.props;

    if (handleChange) {
      handleChange(event, ...args);

      return;
    }

    this.setState({ hasError: event.length === 0 }, () =>
      this.handleToggleInvalidClass()
    );
  };
}

MultiSelectDropdown.displayName = "MultiSelectDropdown";

MultiSelectDropdown.propTypes = {
  /**
   * On change event handler
   */
  handleChange: PropTypes.func,
  /**
   * Text input id
   */
  id: PropTypes.string,
  /**
   * Text input name/id
   */
  name: PropTypes.string.isRequired,
  /**
   * Set <input> to be required
   */
  required: PropTypes.bool,
  /**
   * Explicitly set the value of the <input>.
   */
  value: PropTypes.string,
  /**
   * Text for default error message
   */
  errorMessage: PropTypes.string,
  /**
   * Message displayed in the filter if there is no option resulting from the typeahead input
   */
  noOptionsMessage: PropTypes.string.isRequired,
  /**
   * Data to generate listed options
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  /**
   * Placeholder for the filter input
   */
  placeholder: PropTypes.string.isRequired,
  /**
   * Text for <label>
   */
  labelText: PropTypes.string,
  /**
   * Help text, used to provide hint about the field and the entry
   */
  helpText: PropTypes.string
};

MultiSelectDropdown.defaultProps = {
  required: false,
  errorMessage: "Error Message: This field is required"
};

export default MultiSelectDropdown;
