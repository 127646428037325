import React, { Component } from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

class Items extends Component {
  static displayName = "Items";

  static propTypes = {
    /**
     * List of sites and url in key, value pairs
     */
    options: PropTypes.array,

    /**
     * Selected site index - starts from 0 to n
     */
    selected: PropTypes.number
  };

  options = this.props.options;

  getClassName = getClassNameFactory("GlobalNavItem");

  render() {
    return (
      <ul
        className={this.getClassName({ descendantName: "list" })}
        data-rehydratable-children
      >
        {this.options.map((option, index) => (
          <li
            key={index}
            className={this.getClassName({
              states: this.props.selected === index ? "selected" : ""
            })}
          >
            <div className={this.getClassName({ descendantName: "inner" })}>
              <a
                className={this.getClassName({
                  descendantName: "link",
                  className: typestack("navRegular")
                })}
                href={option.value}
                target="_top"
              >
                {option.label}
              </a>
            </div>
          </li>
        ))}
      </ul>
    );
  }
}

export default Items;
