import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, boolean, text, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import Heading from "./Heading";
import "./Heading.css";
import HeadingREADME from "../README.md";

const stories = storiesOf("Components/Heading", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(HeadingREADME));

const ranks = [""].concat(Heading.ranks);

stories
  .add("Default", () => {
    // const light = text("Light/300", "Light");
    const regular = text("Regular/400", "Proxima Nova in");
    const regularLseg = text("Regular/400", "LSEG Motto in");
    // const medium = text("Medium/500", "Medium");
    // const semiBold = text("Semi bold/500", "Semi Bold");
    const bold = text("Bold/700", "forge");
    const storyHeadingStyle = {
      fontSize: "1.76rem",
      fontWeight: "300",
      lineHeight: "1.387",
      marginTop: "1.5rem"
    };
    const alignment = select("alignment", Heading.alignments, "left");

    storyHeadingStyle.textAlign = alignment;

    return (
      <div>
        <h1 style={storyHeadingStyle}>Heading XXL</h1>
        <Heading
          align={alignment}
          boldOrange={boolean("boldOrange", false)}
          rank={select("rank", ranks)}
          type="xxl"
        >
          {regularLseg} <strong>{bold}</strong>
        </Heading>
        <h1 style={storyHeadingStyle}>Heading XL</h1>
        <Heading
          align={alignment}
          boldOrange={boolean("boldOrange", false)}
          rank={select("rank", ranks)}
          type="xl"
        >
          {regularLseg} <strong>{bold}</strong>
        </Heading>
        <h2 style={storyHeadingStyle}>Heading L</h2>
        <Heading
          align={alignment}
          boldOrange={boolean("boldOrange", false)}
          rank={select("rank", ranks)}
          type="l"
        >
          {regularLseg} <strong>{bold}</strong>
        </Heading>
        <h3 style={storyHeadingStyle}>Heading M</h3>
        <Heading
          align={alignment}
          boldOrange={boolean("boldOrange", false)}
          rank={select("rank", ranks)}
          type="m"
        >
          {regular} <strong>{bold}</strong>
        </Heading>
        <h4 style={storyHeadingStyle}>Heading S</h4>
        <Heading
          align={alignment}
          boldOrange={boolean("boldOrange", false)}
          rank={select("rank", ranks)}
          type="s"
        >
          {regular} <strong>{bold}</strong>
        </Heading>
        <h5 style={storyHeadingStyle}>Heading XS</h5>
        <Heading
          align={alignment}
          boldOrange={boolean("boldOrange", false)}
          rank={select("rank", ranks)}
          type="xs"
        >
          {regular} <strong>{bold}</strong>
        </Heading>
        <h2 style={storyHeadingStyle}>Heading Small Caps</h2>
        <Heading
          align={alignment}
          boldOrange={boolean("boldOrange", false)}
          rank={select("rank", ranks)}
          type="small-caps"
        >
          {regular} <strong>{bold}</strong>
        </Heading>
      </div>
    );
  })
  .add("Language: Arabic (RTL)", () => {
    const regular = text("Text", "بطيئة الكلب الأسود الانحناء قبل");
    const bold = text("Bold text", "الثعلب ملكي");
    const storyHeadingStyle = {
      fontSize: "1.76rem",
      fontWeight: "300",
      lineHeight: "1.387",
      marginTop: "1.5rem"
    };
    const alignment = select("alignment", Heading.alignments, "left");

    /* eslint-disable no-nested-ternary */
    storyHeadingStyle.textAlign =
      alignment === "left"
        ? "right"
        : alignment === "right" ? "left" : alignment;
    /* eslint-enable no-nested-ternary */

    return (
      <Language code="ar">
        <h2 style={storyHeadingStyle}>Heading XL</h2>
        <Heading
          align={alignment}
          boldOrange={boolean("boldOrange", false)}
          rank={select("rank", ranks)}
          type="xl"
        >
          {regular} <strong>{bold}</strong>
        </Heading>
        <h2 style={storyHeadingStyle}>Heading L</h2>
        <Heading
          align={alignment}
          boldOrange={boolean("boldOrange", false)}
          rank={select("rank", ranks)}
          type="l"
        >
          {regular} <strong>{bold}</strong>
        </Heading>
        <h2 style={storyHeadingStyle}>Heading M</h2>
        <Heading
          align={alignment}
          boldOrange={boolean("boldOrange", false)}
          rank={select("rank", ranks)}
          type="m"
        >
          {regular} <strong>{bold}</strong>
        </Heading>
        <h2 style={storyHeadingStyle}>Heading S</h2>
        <Heading
          align={alignment}
          boldOrange={boolean("boldOrange", false)}
          rank={select("rank", ranks)}
          type="s"
        >
          <strong>
            {regular} {bold}
          </strong>
        </Heading>
        <Heading
          align={alignment}
          boldOrange={boolean("boldOrange", false)}
          rank={select("rank", ranks)}
          type="s"
        >
          {regular} {bold}
        </Heading>
        <h2 style={storyHeadingStyle}>Heading XS</h2>
        <Heading
          align={alignment}
          boldOrange={boolean("boldOrange", false)}
          rank={select("rank", ranks)}
          type="xs"
        >
          <strong>
            {regular} {bold}
          </strong>
        </Heading>
        <Heading
          align={alignment}
          boldOrange={boolean("boldOrange", false)}
          rank={select("rank", ranks)}
          type="xs"
        >
          {regular} {bold}
        </Heading>
        <h2 style={storyHeadingStyle}>Heading Small Caps</h2>
        <Heading
          align={alignment}
          boldOrange={boolean("boldOrange", false)}
          rank={select("rank", ranks)}
          type="small-caps"
        >
          {regular} <strong>{bold}</strong>
        </Heading>
      </Language>
    );
  });
