import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import SocialShare from "@emcm-ui/component-social-share";
import SocialDropdown from "./index";
import "./SocialDropdown.css";
import SocialDropdownREADME from "../README.md";

import { getSocialIcon } from "./icons";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

const colorTypes = ["white", "gray"];

const stories = storiesOf("Components/SocialDropdown", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(SocialDropdownREADME));

stories
  .add("Default", () => (
    <SocialDropdown color={select("color", colorTypes, "white")}>
      <SocialDropdown.Item>
        <SocialShare
          pageUrl="https://www.lseg.com"
          type="facebook"
          ariaLabel="view facebook profile"
        >
          <SVGIcon
            name="facebook"
            size="s"
            getExternalIconPath={getSocialIcon}
          />
        </SocialShare>
      </SocialDropdown.Item>
      <SocialDropdown.Item>
        <SocialShare
          pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
          pageUrl="https://www.lseg.com"
          type="twitter"
          ariaLabel="view twiiter profile"
        >
          <SVGIcon
            name="twitter"
            size="s"
            getExternalIconPath={getSocialIcon}
          />
        </SocialShare>
      </SocialDropdown.Item>
      <SocialDropdown.Item>
        <SocialShare
          pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
          pageUrl="https://www.lseg.com"
          type="linkedIn"
          ariaLabel="view linkedIn profile"
        >
          <SVGIcon
            name="linkedin"
            size="s"
            getExternalIconPath={getSocialIcon}
          />
        </SocialShare>
      </SocialDropdown.Item>
      <SocialDropdown.Item>
        <SocialShare
          pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
          pageUrl="https://www.lseg.com"
          type="weibo"
          ariaLabel="view weido profile"
        >
          <SVGIcon name="weibo" size="s" getExternalIconPath={getSocialIcon} />
        </SocialShare>
      </SocialDropdown.Item>
      <SocialDropdown.Item>
        <SocialShare
          pageUrl="https://www.lseg.com"
          type="whatsApp"
          ariaLabel="view whatsApp profile"
        >
          <SVGIcon
            name="whatsapp"
            size="s"
            getExternalIconPath={getSocialIcon}
          />
        </SocialShare>
      </SocialDropdown.Item>
      <SocialDropdown.Item>
        <SocialShare pageUrl="https://www.lseg.com" type="line">
          <SVGIcon name="line" size="s" getExternalIconPath={getSocialIcon} />
        </SocialShare>
      </SocialDropdown.Item>
      <SocialDropdown.Item>
        <SocialShare pageUrl="https://www.lseg.com" type="mixi">
          <SVGIcon name="mixi" size="s" getExternalIconPath={getSocialIcon} />
        </SocialShare>
      </SocialDropdown.Item>
      <SocialDropdown.Item>
        <SocialShare
          pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
          pageUrl="https://www.lseg.com"
          type="email"
        >
          <SVGIcon name="email" size="s" getExternalIconPath={getSocialIcon} />
        </SocialShare>
      </SocialDropdown.Item>
      <SocialDropdown.Item>
        <SocialShare type="print" ariaLabel="Print this article">
          <SVGIcon name="print" size="s" getExternalIconPath={getSocialIcon} />
        </SocialShare>
      </SocialDropdown.Item>
    </SocialDropdown>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <SocialDropdown color={select("color", colorTypes, "white")}>
        <SocialDropdown.Item>
          <SocialShare
            pageUrl="https://www.lseg.com"
            type="facebook"
            ariaLabel="view facebook profile"
          >
            <SVGIcon
              name="facebook"
              size="s"
              getExternalIconPath={getSocialIcon}
            />
          </SocialShare>
        </SocialDropdown.Item>
        <SocialDropdown.Item>
          <SocialShare
            pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
            pageUrl="https://www.lseg.com"
            type="twitter"
            ariaLabel="view twiiter profile"
          >
            <SVGIcon
              name="twitter"
              size="s"
              getExternalIconPath={getSocialIcon}
            />
          </SocialShare>
        </SocialDropdown.Item>
        <SocialDropdown.Item>
          <SocialShare
            pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
            pageUrl="https://www.lseg.com"
            type="linkedIn"
            ariaLabel="view linkedIn profile"
          >
            <SVGIcon
              name="linkedin"
              size="s"
              getExternalIconPath={getSocialIcon}
            />
          </SocialShare>
        </SocialDropdown.Item>
        <SocialDropdown.Item>
          <SocialShare
            pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
            pageUrl="https://www.lseg.com"
            type="weibo"
            ariaLabel="view weido profile"
          >
            <SVGIcon
              name="weibo"
              size="s"
              getExternalIconPath={getSocialIcon}
            />
          </SocialShare>
        </SocialDropdown.Item>
        <SocialDropdown.Item>
          <SocialShare
            pageUrl="https://www.lseg.com"
            type="whatsApp"
            ariaLabel="view whatsApp profile"
          >
            <SVGIcon
              name="whatsapp"
              size="s"
              getExternalIconPath={getSocialIcon}
            />
          </SocialShare>
        </SocialDropdown.Item>
        <SocialDropdown.Item>
          <SocialShare pageUrl="https://www.lseg.com" type="line">
            <SVGIcon name="line" size="s" getExternalIconPath={getSocialIcon} />
          </SocialShare>
        </SocialDropdown.Item>
        <SocialDropdown.Item>
          <SocialShare pageUrl="https://www.lseg.com" type="mixi">
            <SVGIcon name="mixi" size="s" getExternalIconPath={getSocialIcon} />
          </SocialShare>
        </SocialDropdown.Item>
        <SocialDropdown.Item>
          <SocialShare
            pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
            pageUrl="https://www.lseg.com"
            type="email"
          >
            <SVGIcon
              name="email"
              size="s"
              getExternalIconPath={getSocialIcon}
            />
          </SocialShare>
        </SocialDropdown.Item>
        <SocialDropdown.Item>
          <SocialShare type="print">
            <SVGIcon
              name="print"
              size="s"
              getExternalIconPath={getSocialIcon}
            />
          </SocialShare>
        </SocialDropdown.Item>
      </SocialDropdown>
    </Language>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <SocialDropdown color={select("color", colorTypes, "white")}>
        <SocialDropdown.Item>
          <SocialShare
            pageUrl="https://www.lseg.com"
            type="facebook"
            ariaLabel="view facebook profile"
          >
            <SVGIcon
              name="facebook"
              size="s"
              getExternalIconPath={getSocialIcon}
            />
          </SocialShare>
        </SocialDropdown.Item>
        <SocialDropdown.Item>
          <SocialShare
            pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
            pageUrl="https://www.lseg.com"
            type="twitter"
            ariaLabel="view twitter profile"
          >
            <SVGIcon
              name="twitter"
              size="s"
              getExternalIconPath={getSocialIcon}
            />
          </SocialShare>
        </SocialDropdown.Item>
        <SocialDropdown.Item>
          <SocialShare
            pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
            pageUrl="https://www.lseg.com"
            type="linkedIn"
            ariaLabel="view linkedIn profile"
          >
            <SVGIcon
              name="linkedin"
              size="s"
              getExternalIconPath={getSocialIcon}
            />
          </SocialShare>
        </SocialDropdown.Item>
        <SocialDropdown.Item>
          <SocialShare
            pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
            pageUrl="https://www.lseg.com"
            type="weibo"
            ariaLabel="view weibo profile"
          >
            <SVGIcon
              name="weibo"
              size="s"
              getExternalIconPath={getSocialIcon}
            />
          </SocialShare>
        </SocialDropdown.Item>
        <SocialDropdown.Item>
          <SocialShare
            pageUrl="https://www.lseg.com"
            type="whatsApp"
            ariaLabel="view whatsApp profile"
          >
            <SVGIcon
              name="whatsapp"
              size="s"
              getExternalIconPath={getSocialIcon}
            />
          </SocialShare>
        </SocialDropdown.Item>
        <SocialDropdown.Item>
          <SocialShare pageUrl="https://www.lseg.com" type="line">
            <SVGIcon name="line" size="s" getExternalIconPath={getSocialIcon} />
          </SocialShare>
        </SocialDropdown.Item>
        <SocialDropdown.Item>
          <SocialShare pageUrl="https://www.lseg.com" type="mixi">
            <SVGIcon name="mixi" size="s" getExternalIconPath={getSocialIcon} />
          </SocialShare>
        </SocialDropdown.Item>
        <SocialDropdown.Item>
          <SocialShare
            pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
            pageUrl="https://www.lseg.com"
            type="email"
          >
            <SVGIcon
              name="email"
              size="s"
              getExternalIconPath={getSocialIcon}
            />
          </SocialShare>
        </SocialDropdown.Item>
        <SocialDropdown.Item>
          <SocialShare type="print">
            <SVGIcon
              name="print"
              size="s"
              getExternalIconPath={getSocialIcon}
            />
          </SocialShare>
        </SocialDropdown.Item>
      </SocialDropdown>
    </StaticRenderer>
  ));
