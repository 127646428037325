import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Typestack from "@emcm-ui/component-typestack";

import NoResults from "./components/NoResults";
import Result from "./components/Result";
import ResultCard from "./components/Result/ResultCard";

const SearchResults = ({ children, title, sort, resultView }) => {
  const getClassName = getClassNameFactory(SearchResults.displayName);

  return (
    <div className={getClassName()}>
      <div className={getClassName({ descendantName: "title" })}>
        <Typestack align="left" rank="h5" type="subline2">
          {title}
        </Typestack>
        {sort}
      </div>
      <div
        className={getClassName({
          descendantName: "results",
          modifiers: resultView === "card-grid" ? "grid" : ""
        })}
      >
        {children}
      </div>
    </div>
  );
};

SearchResults.displayName = "SearchResults";

SearchResults.propTypes = {
  /**
   * Children nodes. Either `SearchResults.Result`, `SearchResults.ResultCard` or `SearchResults.NoResults`.
   */
  children: PropTypes.node.isRequired,
  /**
   * Title text.
   */
  title: PropTypes.string.isRequired,
  /**
   * Sort component
   */
  sort: PropTypes.element,
  /**
   * Result list type. Either List or grid(card) view
   */
  resultView: PropTypes.string
};

SearchResults.NoResults = NoResults;
SearchResults.Result = Result;
SearchResults.ResultCard = ResultCard;

export default SearchResults;
