import React from "react";
import { storiesOf } from "@storybook/react";
import { action } from "@storybook/addon-actions";
import {
  withKnobs,
  text,
  boolean,
  select,
  number
} from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import SearchInput from "./index";
import "./SearchInput.css";
import SearchInputREADME from "../README.md";

const stories = storiesOf("Components/SearchInput", module);

const submittedViaJSAction = action("Submitted via JS");
const inputChangeViaJSAction = (val, callback) => {
  callback(["Apple", "Banana", "Carrot", "Dates", "Eggfruit"]);
};
const blurAction = action("Input Blurred");

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(SearchInputREADME));

const getQueryParamValByName = param => {
  const params = new URLSearchParams(window.top.document.location.search);

  if (params.get(param) === null) {
    return "";
  }

  return params.get(param);
};

const elasticURL =
  "https://run.mocky.io/v3/fa0967b7-8058-4356-a92f-48d2197205e2?";

const elasticResultSizeLimit = 7;
const searchMinLength = 3;

stories
  .add("Default", () => (
    <form
      action={text("formAction", "https://www.example.com")}
      method="get"
      style={{ padding: 10, backgroundColor: "#d0d0d0" }}
    >
      <SearchInput
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={boolean("autoFocus", false)}
        colorTheme={select("colorTheme", ["dark", "light"], "light")}
        name={text("name", "q")}
        placeholder={text("placeholder", "Search")}
        typeahead={boolean("typeahead", true)}
        typeaheadUrl={text(
          "typeaheadUrl",
          "//content.atomz.com/autocomplete/sp10/05/0d/51?_=1506335725065"
        )}
        value={text("value", "")}
        resetSearch={boolean("resetSearch", false)}
        queryParam={boolean("queryParam", false)}
        searchMinLength={number("searchMinLength", searchMinLength)}
        searchSubmitAriaLabel={text("Search button aria-label", "Search")}
        searchClearAriaLabel={text("Search Clear aria-label", "Clear Search")}
        onBlur={blurAction}
        {...(boolean("Use onSubmit", true)
          ? {
              onSubmit: submittedViaJSAction
            }
          : {})}
      />
    </form>
  ))
  .add("Custom Input", () => (
    <form
      action={text("formAction", "https://www.example.com")}
      method="get"
      style={{ padding: 10, backgroundColor: "#d0d0d0" }}
    >
      <SearchInput
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={boolean("autoFocus", false)}
        colorTheme={select("colorTheme", ["dark", "light"], "light")}
        name={text("name", "q")}
        placeholder={text("placeholder", "Search")}
        typeahead={boolean("typeahead", true)}
        typeaheadUrl={text(
          "typeaheadUrl",
          "//content.atomz.com/autocomplete/sp10/05/0d/51?_=1506335725065"
        )}
        value={text("value", "")}
        searchMinLength={number("searchMinLength", searchMinLength)}
        resetSearch={boolean("resetSearch", false)}
        queryParam={boolean("queryParam", false)}
        onBlur={blurAction}
        {...(boolean("Use onSubmit", true)
          ? {
              onSubmit: submittedViaJSAction
            }
          : {})}
        {...(boolean("Use handleInputChange", true)
          ? {
              handleInputChange: inputChangeViaJSAction
            }
          : {})}
      />
    </form>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <form
        action={text("formAction", "https://www.example.com")}
        method="get"
        style={{ padding: 10, backgroundColor: "#d0d0d0" }}
      >
        <SearchInput
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={boolean("autoFocus", false)}
          colorTheme={select("colorTheme", ["dark", "light"], "light")}
          name={text("name", "q")}
          placeholder={text("placeholder", "Search")}
          typeahead={boolean("typeahead", true)}
          typeaheadUrl={text(
            "typeaheadUrl",
            "//content.atomz.com/autocomplete/sp10/05/0d/51?_=1506335725065"
          )}
          value={text("value", "")}
          searchMinLength={number("searchMinLength", searchMinLength)}
          resetSearch={boolean("resetSearch", false)}
          queryParam={boolean("queryParam", false)}
          onBlur={blurAction}
          {...(boolean("Use onSubmit", true)
            ? {
                onSubmit: submittedViaJSAction
              }
            : {})}
        />
      </form>
    </Language>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <form
        action={text("formAction", "https://www.example.com")}
        method="get"
        style={{ padding: 10, backgroundColor: "#d0d0d0" }}
      >
        <SearchInput
          typeaheadUrl="//content.atomz.com/autocomplete/sp10/05/0d/51?_=1506335725065"
          value="My Value"
        />
      </form>
    </StaticRenderer>
  ))
  .add("Elastic Search", () => (
    <form
      action={text("formAction", "https://www.example.com")}
      method="get"
      style={{ padding: 10, backgroundColor: "#d0d0d0" }}
    >
      <SearchInput
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={boolean("autoFocus", false)}
        colorTheme={select("colorTheme", ["dark", "light"], "light")}
        name={text("name", "q")}
        placeholder={text("placeholder", "Search")}
        typeahead={boolean("typeahead", true)}
        typeaheadUrl={text("typeaheadUrl", elasticURL)}
        value={text("value", "")}
        resetSearch={boolean("resetSearch", false)}
        queryParam={boolean("queryParam", false)}
        autosuggestion={text("autosuggestion", "description,w_internal_title")}
        size={number("size", elasticResultSizeLimit)}
        searchMinLength={number("searchMinLength", searchMinLength)}
        elasticKey={text("elasticKey", getQueryParamValByName("elasticKey"))}
        onBlur={blurAction}
        {...(boolean("Use onSubmit", true)
          ? {
              onSubmit: submittedViaJSAction
            }
          : {})}
      />
    </form>
  ))
  .add("RehydratedElastic Search", () => (
    <StaticRenderer>
      <form
        action={text("formAction", "https://www.example.com")}
        method="get"
        style={{ padding: 10, backgroundColor: "#d0d0d0" }}
      >
        <SearchInput
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={boolean("autoFocus", false)}
          colorTheme={select("colorTheme", ["dark", "light"], "light")}
          name={text("name", "q")}
          placeholder={text("placeholder", "Search")}
          typeahead={boolean("typeahead", true)}
          typeaheadUrl={text("typeaheadUrl", elasticURL)}
          resetSearch={boolean("resetSearch", false)}
          queryParam={boolean("queryParam", false)}
          value={text("value", "")}
          autosuggestion={text(
            "autosuggestion",
            "description,w_internal_title"
          )}
          size={number("size", elasticResultSizeLimit)}
          searchMinLength={number("searchMinLength", searchMinLength)}
          elasticKey={text("elasticKey", getQueryParamValByName("elasticKey"))}
          onBlur={blurAction}
          {...(boolean("Use onSubmit", true)
            ? {
                onSubmit: submittedViaJSAction
              }
            : {})}
        />
      </form>
    </StaticRenderer>
  ));
