import { AuthState } from "@emcm-ui/utility-authentication";
import BaseItem from "../BaseItem";
import Column from "../Column";
import NavList from "../NavList";
import NavItem from "../NavItem";
import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

class Login extends Component {
  static displayName = "GlobalHeader.Login";

  static propTypes = {
    /**
     * The account label to show on this Login.
     */
    accountLabel: PropTypes.string.isRequired,

    /**
     * The account overview label to show on this Login.
     */
    accountOverviewLabel: PropTypes.string.isRequired,

    /**
     * The account overview icon type id, to be passed into <NavItem>.
     */
    accountOverviewIcon: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string
    ]),

    /**
     * The hello label to show on this Login.
     */
    helloLabel: PropTypes.string.isRequired,

    /**
     * The login label to show on this Login.
     */
    loginLabel: PropTypes.string.isRequired,

    /**
     * The logout label to show on this Login.
     */
    logoutLabel: PropTypes.string.isRequired,

    /**
     * LoginManager instance, required at rehydration.
     */
    loginManager: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.handleLogin = this.handleLogin.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.loginManagerChange = this.loginManagerChange.bind(this);

    if (this.props.loginManager) {
      this.props.loginManager.onChange(this.loginManagerChange);
    }
  }

  componentWillUnmount() {
    if (this.props.loginManager) {
      this.props.loginManager.offChange(this.loginManagerChange);
    }
  }

  handleLogin() {
    if (this.props.loginManager) {
      this.props.loginManager.login();
    }
  }

  handleLogout() {
    if (this.props.loginManager) {
      this.props.loginManager.logout();
    }
  }

  loginManagerChange() {
    this.forceUpdate();
  }

  render() {
    const getClassName = getClassNameFactory("GlobalHeaderLogin");

    const Wrapper = ({ children }) => (
      <li
        className={getClassName()}
        data-account-label={this.props.accountLabel}
        data-account-overview-label={this.props.accountOverviewLabel}
        data-account-overview-icon={this.props.accountOverviewIcon}
        data-hello-label={this.props.helloLabel}
        data-login-label={this.props.loginLabel}
        data-logout-label={this.props.logoutLabel}
        data-rehydratable={getRehydratableName(Login.displayName)}
      >
        {children}
      </li>
    );

    if (!this.props.loginManager) {
      return <Wrapper />;
    }

    const loginUserDisplayName =
      this.props.loginManager.userFirstName || this.props.loginManager.userName;

    return (
      <Wrapper>
        {this.props.loginManager.authState === AuthState.LOGGED_IN ? (
          <BaseItem
            {...this.props}
            label={this.props.accountLabel}
            longLabel={`${this.props.helloLabel}, ${loginUserDisplayName}`}
          >
            <Column>
              <NavList>
                {this.props.accountOverviewLabel && (
                  <NavItem
                    title={this.props.accountOverviewLabel}
                    href={this.props.loginManager.accountOverviewUrl}
                    icon={this.props.accountOverviewIcon}
                  />
                )}
                <div
                  className={getClassName({
                    descendantName: "logoutHorizontalRule"
                  })}
                />
                {this.props.logoutLabel && (
                  <NavItem
                    title={this.props.logoutLabel}
                    onClick={this.handleLogout}
                    href={this.props.loginManager.logoutUrl}
                  />
                )}
              </NavList>
            </Column>
          </BaseItem>
        ) : (
          <BaseItem
            {...this.props}
            label={this.props.loginLabel}
            onClick={this.handleLogin}
            href={this.props.loginManager.loginUrl}
          />
        )}
      </Wrapper>
    );
  }
}

export default Login;
