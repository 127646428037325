import PropTypes from "prop-types";
import React from "react";
import Typestack from "@emcm-ui/component-typestack";
import Link from "@emcm-ui/component-link";

import getClassNameFactory from "@emcm-ui/utility-class-names";

import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

const FilterHeader = ({
  header,
  isFilterOpened,
  showFilterBody,
  filterOpenLabel
}) => {
  const getClassName = getClassNameFactory(FilterHeader.displayName);
  const filterIconElm = isFilterOpened ? (
    <div
      className={getClassName({
        descendantName: "open"
      })}
    >
      <SVGIcon name="close" size="xxs" />
    </div>
  ) : (
    <div
      className={getClassName({
        descendantName: "close"
      })}
    >
      <SVGIcon name="filter" size="s" />
    </div>
  );

  return (
    <div className={getClassName({ descendantName: "header" })}>
      <div className={getClassName({ descendantName: "heading" })}>
        <Typestack align="left" rank="h2" type="h2">
          {header}
        </Typestack>
      </div>
      <div
        className={getClassName({ descendantName: "icon" })}
        onClick={showFilterBody}
        onKeyDown={showFilterBody}
        role="button"
      >
        <Link
          block={false}
          icon={filterIconElm}
          iconPosition={"left"}
          type={"icon"}
        >
          {filterOpenLabel}
        </Link>
      </div>
    </div>
  );
};

FilterHeader.displayName = "FilterHeader";

FilterHeader.propTypes = {
  /**
   * name.
   */
  header: PropTypes.string.isRequired,
  /**
   * isFilterOpened.
   */
  isFilterOpened: PropTypes.bool.isRequired,
  /**
   * showFilterBody.
   */
  showFilterBody: PropTypes.func.isRequired,
  /**
   * showFilterBody.
   */
  filterOpenLabel: PropTypes.string.isRequired
};

export default FilterHeader;
