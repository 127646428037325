import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const SocialShare = props => {
  const { type, ariaLabel } = props;
  const getClassName = getClassNameFactory(SocialShare.displayName);
  const href = SocialShare.getHref(props);

  return (
    <a
      className={`${getClassName({
        modifiers: classNames(type)
      })} ${getClassName({ descendantName: "button" })}`}
      href={href}
      aria-label={ariaLabel}
    >
      {props.children}
    </a>
  );
};

SocialShare.displayName = "SocialShare";

SocialShare.types = [
  "email",
  "facebook",
  "line",
  "linkedIn",
  "mixi",
  "print",
  "twitter",
  "weibo",
  "whatsApp"
];

SocialShare.getHref = ({ type, pageTitle, pageUrl }) => {
  switch (type) {
    case "email":
      return `mailto:?subject=${pageTitle}&body=${pageUrl}`;
    case "facebook":
      return `https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`;
    case "line":
      return `https://lineit.line.me/share/ui?url=${pageUrl}`;
    case "linkedIn":
      return `https://www.linkedin.com/shareArticle?mini=true&url=${pageUrl}&title=${pageTitle}`;
    case "mixi":
      return `https://mixi.jp/share.pl?u=${pageUrl}`;
    case "print":
      return "javascript:window.print()"; // eslint-disable-line no-script-url
    case "twitter":
      return `https://twitter.com/intent/tweet?url=${pageUrl}&text=${pageTitle}`;
    case "weibo":
      return `https://service.weibo.com/share/share.php?url=${pageUrl}&title=${pageTitle}`;
    case "whatsApp":
      return `whatsapp://send?text=${pageUrl}`;
    default:
      return "";
  }
};

/* eslint-disable max-len */
SocialShare.propTypes = {
  /**
   * Aria label for anchor
   */
  ariaLabel: PropTypes.string,

  /**
   * Child nodes
   */
  children: PropTypes.node.isRequired,

  /**
   * Type of sharing service
   */
  type: PropTypes.oneOf(SocialShare.types).isRequired,

  /**
   * URL of page to be shared
   */
  pageUrl: PropTypes.string,

  /**
   * Title of page to be shared
   */
  pageTitle: PropTypes.string
};
/* eslint-enable max-len */

export default SocialShare;
