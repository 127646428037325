/**
 * @define Expandable.Item
 * Expandable.Item Component
 */

// Vendor
import React, { Component } from "react";
import PropTypes from "prop-types";

// emcm-ui
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

// local
import Header from "../Header";

const CSS_TANSITION_DELAY = 100;

class Item extends Component {
  static displayName = "Expandable.Item";

  static defaultProps = {
    header: props => <Header {...props}>{props.title || "Show More"}</Header>,
    isExpanded: false,
    headingRank: "h4",
    headingType: "h4",
    direction: "down",
    expandedTitle: "",
    type: "normal"
  };

  static propTypes = {
    /**
     * Children elements to be displayed within the ExpandableContent
     */
    children: PropTypes.object.isRequired,
    /**
     * Header render prop function to be used as the header
     */
    header: PropTypes.func,
    /**
     * Children elements to be displayed within subcomponent's internal
     * Heading component
     */
    title: PropTypes.node,
    /**
     * Expanded state for controlled component mode
     */
    isExpanded: PropTypes.bool,
    /**
     * Heading rank to use for the default heading - default is 4
     */
    headingRank: PropTypes.string,
    /**
     * Heading type to use for the default heading - default is 4
     */
    headingType: PropTypes.string,
    /**
     * direction option - default is downwards
     */
    direction: PropTypes.string,
    /**
     * Text to be displayed once the component is expanded.
     */
    expandedTitle: PropTypes.string,
    /**
     * default is normal expandable and showMore is for show more mode
     */
    type: PropTypes.oneOf(["normal", "showMore"]),
    /**
     * removes the top padding, used especially when expandable content is a continuation of the previous component
     */
    noTopPadding: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      isExpanded: this.props.isExpanded
    };

    this.elementRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.isExpanded !== prevProps.isExpanded) {
      this.setState({ isExpanded: this.props.isExpanded });
    }
  }

  getClassName = getClassNameFactory(Item.displayName);

  onClick = () => {
    const { direction } = this.props;

    this.setState(
      state => {
        return { isExpanded: !state.isExpanded };
      },
      () => {
        if (direction === "up" && this.state.isExpanded) {
          const element =
            this.elementRef &&
            this.elementRef.current &&
            this.elementRef.current.querySelector(
              'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
            );

          if (element) {
            window.setTimeout(() => {
              element.setAttribute("autofocus", "true");
              element.focus();
            }, CSS_TANSITION_DELAY);
          }
        }
      }
    );
  };

  render() {
    const {
      children,
      header,
      title,
      headingRank,
      headingType,
      direction,
      expandedTitle,
      type,
      noTopPadding
    } = this.props;
    const { isExpanded } = this.state;
    const expandedClassName = this.getClassName({
      descendantName: "content",
      states: "expanded",
      modifiers: noTopPadding ? "noTopPadding" : ""
    });
    const collapsedClassName = this.getClassName({
      descendantName: "content",
      states: "collapsed"
    });

    return (
      <div
        className={this.getClassName()}
        data-is-expanded={isExpanded}
        data-heading-rank={headingRank}
        data-heading-type={headingType}
        data-direction={direction}
        data-expanded-title={expandedTitle}
        data-type={type}
        data-rehydratable={getRehydratableName(Item.displayName)}
        data-no-top-padding={noTopPadding}
      >
        {direction === "up" && (
          <div
            className={isExpanded ? expandedClassName : collapsedClassName}
            aria-hidden={isExpanded ? "false" : "true"}
            ref={this.elementRef}
          >
            {children}
          </div>
        )}
        {header({
          isExpanded,
          onClick: this.onClick,
          title: isExpanded && expandedTitle > "" ? expandedTitle : title,
          headingRank,
          headingType,
          type
        })}
        {direction === "down" && (
          <div
            className={isExpanded ? expandedClassName : collapsedClassName}
            aria-hidden={isExpanded ? "false" : "true"}
          >
            {children}
          </div>
        )}
      </div>
    );
  }
}

Item.Header = Header;
export default Item;
