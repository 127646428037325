import React from "react";
import ContinuousTicker from "./ContinuousTicker";

const rehydrator = async (domNode, rehydrateChildren) => {
  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data ? JSON.parse(data) : null;
  };
  const props = {
    offset: domNode.getAttribute("data-offset"),
    mode: domNode.getAttribute("data-mode"),
    speed: readData("speed"),
    direction: domNode.getAttribute("data-direction"),
    move: readData("move")
  };
  const childrenNode = domNode.querySelector("[data-rehydratable-children]");
  const reactifiedChildren = await rehydrateChildren(childrenNode);

  return (
    <ContinuousTicker {...props}>{() => reactifiedChildren}</ContinuousTicker>
  );
};

export default rehydrator;
