import React, { Component } from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import DateTime from "@emcm-ui/component-date-time";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

class TickerItem extends Component {
  static displayName = "Ticker.Item";

  static propTypes = {
    /**
     * An ISO 8601, RFC 2822, or `new Date(str)` compatible date string, which is used to compare relative time. Defaults to the time that the current page loaded; you should set this if you want the time to dynamically update as time progresses.
     */
    currentTime: PropTypes.string,
    /**
     * An ISO 8601, RFC 2822 or `new Date(str)` compatible date string.
     */
    dateTime: PropTypes.string,
    /**
     * A link to this story. Will render without an anchor element if this is not provided.
     */
    href: PropTypes.string,
    /**
     * The language of this item. Used to localize the relative time.
     */
    lang: PropTypes.string,
    /**
     * Whether this should display as the first item or not.
     */
    lead: PropTypes.bool,
    /**
     * The title of this item.
     */
    title: PropTypes.string.isRequired
  };

  static defaultProps = {
    lang: "enUS"
  };

  constructor(props) {
    super(props);

    this.getClassName = getClassNameFactory("TickerItem");
  }

  render() {
    const titleEl = (
      <span
        className={this.getClassName({
          descendantName: "title",
          className: this.props.dateTime
            ? typestack("p2Bold")
            : typestack("subline1"),
          modifiers: this.props.dateTime ? "dated" : ""
        })}
      >
        {this.props.title}
      </span>
    );

    const timeEl = this.props.dateTime ? (
      <span
        className={this.getClassName({
          descendantName: "time"
        })}
      >
        <DateTime
          currentTime={this.props.currentTime}
          dateTime={this.props.dateTime}
          lang={this.props.lang}
          relative
        />
      </span>
    ) : null;

    return (
      <li
        className={this.getClassName({
          utilities: this.props.lead ? "typographySmallCaps" : ""
        })}
        data-rehydratable={getRehydratableName(TickerItem.displayName)}
        data-date-time={this.props.dateTime}
        data-href={this.props.href}
        data-lang={this.props.lang}
        data-lead={this.props.lead}
        data-title={this.props.title}
      >
        {this.props.href ? (
          <a
            href={this.props.href}
            className={this.getClassName({
              descendantName: "link"
            })}
          >
            {titleEl}
            {timeEl}
          </a>
        ) : (
          <span>
            {titleEl}
            {timeEl}
          </span>
        )}
      </li>
    );
  }
}

export default TickerItem;
