import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, object } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import AppNav from "./index";
import "./AppNav.css";
import AppNavREADME from "../README.md";

const stories = storiesOf("Components/AppNav", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(AppNavREADME));

const items = [
  {
    icon:
      "M28 26.6667C28 27.0203 27.8595 27.3594 27.6095 27.6095C27.3594 27.8595 27.0203 28 26.6667 28H5.33333C4.97971 28 4.64057 27.8595 4.39052 27.6095C4.14048 27.3594 4 27.0203 4 26.6667V12.652C3.99986 12.4488 4.04616 12.2483 4.13535 12.0657C4.22455 11.8832 4.35429 11.7234 4.51467 11.5987L15.1813 3.30265C15.4154 3.12058 15.7035 3.02173 16 3.02173C16.2965 3.02173 16.5846 3.12058 16.8187 3.30265L27.4853 11.5987C27.6457 11.7234 27.7754 11.8832 27.8646 12.0657C27.9538 12.2483 28.0001 12.4488 28 12.652V26.6667ZM25.3333 25.3333V13.304L16 6.04399L6.66667 13.304V25.3333H25.3333Z",
    label: "Home",
    link: "https://www.lseg.com/iframe.html",
    notification: false
  },
  {
    icon:
      "M24.0414 22.156L29.7521 27.8653L27.8654 29.752L22.1561 24.0413C20.0317 25.7443 17.3894 26.6705 14.6667 26.6666C8.04275 26.6666 2.66675 21.2906 2.66675 14.6666C2.66675 8.04263 8.04275 2.66663 14.6667 2.66663C21.2907 2.66663 26.6667 8.04263 26.6667 14.6666C26.6706 17.3893 25.7444 20.0316 24.0414 22.156ZM21.3667 21.1666C23.0589 19.4265 24.0039 17.0939 24.0001 14.6666C24.0001 9.51063 19.8227 5.33329 14.6667 5.33329C9.51075 5.33329 5.33341 9.51063 5.33341 14.6666C5.33341 19.8226 9.51075 24 14.6667 24C17.094 24.0038 19.4266 23.0588 21.1667 21.3666L21.3667 21.1666Z",
    label: "Search",
    link: "https://www.lseg.com/search",
    notification: false
  },
  {
    icon:
      "M28.5685 22.1792C28.5672 25.8494 25.9881 28.917 22.5435 29.67L21.7279 27.2231C23.2671 26.9719 24.5753 26.0335 25.3276 24.7332H22.1764C20.7642 24.7332 19.6195 23.5885 19.6195 22.1764V17.0627C19.6195 15.6505 20.7642 14.5058 22.1764 14.5058H25.9325C25.3034 9.46077 20.9997 5.55685 15.7843 5.55685C10.5688 5.55685 6.26509 9.46077 5.63597 14.5058H9.39213C10.8042 14.5058 11.949 15.6505 11.949 17.0627V22.1764C11.949 23.5885 10.8042 24.7332 9.39213 24.7332H5.55685C4.14474 24.7332 3 23.5885 3 22.1764V15.7843C3 8.7237 8.7237 3 15.7843 3C22.8447 3 28.5685 8.7237 28.5685 15.7843V17.061V17.0627V22.1764V22.1792ZM26.0117 22.1764V17.0627H22.1764V22.1764H26.0117ZM5.55685 17.0627V22.1764H9.39213V17.0627H5.55685Z",
    label: "Contact",
    link: "https://www.lseg.com/contact",
    notification: false
  },
  {
    icon:
      "M6.445 15.3716L2 12.7788L15.335 5L28.67 12.7788V22.2244H26.4475V14.0752L24.225 15.3716V22.7926L23.9776 23.0983C21.9422 25.6136 18.8263 27.225 15.335 27.225C11.8436 27.225 8.72783 25.6136 6.6924 23.0983L6.445 22.7926V15.3716ZM8.6675 16.6681V21.9929C10.2979 23.8401 12.6804 25.0025 15.335 25.0025C17.9897 25.0025 20.3721 23.8401 22.0025 21.9929V16.6681L15.335 20.5575L8.6675 16.6681ZM6.41085 12.7788L15.335 17.9845L24.2591 12.7788L15.335 7.573L6.41085 12.7788Z",
    label: "Learn",
    link: "https://www.lseg.com/learn",
    notification: true
  }
];

stories
  .add("Default", () => <AppNav items={object("Navigation items", items)} />)

  .add("Rehydrated", () => (
    <StaticRenderer>
      <AppNav items={items} />
    </StaticRenderer>
  ));
