import React, { Component } from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import classNames from "classnames";
import debounce from "lodash.debounce";

/* Forge */
import Alert from "@emcm-ui/component-alert";
import Button from "@emcm-ui/component-button";
import Group from "@emcm-ui/component-group";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";
import MultiSelectDropdown from "@emcm-ui/component-multi-select-dropdown";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

/* Local */
import CampaignControl from "./components/CampaignControl";
import CheckboxControl from "./components/CheckboxControl";
import ToggleSwitchControl from "./components/ToggleSwitchControl";
import Fieldset from "./components/Fieldset";
import PrivacyControl from "./components/PrivacyControl";
import RadioControl from "./components/RadioControl";
import SelectControl from "./components/SelectControl";
import TextareaControl from "./components/TextareaControl";
import TextControl from "./components/TextControl";
import { Consumer as FormOnSubmitSuccessContextConsumer } from "./formOnSubmitSuccessContext";
import { formConsts } from "./FormConstant";
import {
  fireFormStart,
  fireFormSubmitSuccess,
  fireFormSubmitFailure,
  submit
} from "./utilities";

// Debounce the fireFormSubmitFailure event to not fire for every input fail
const DEBOUNCE_TIME = 1000;

const fireFormFailureDebounced = debounce(fireFormSubmitFailure, DEBOUNCE_TIME);

class FormsNewStyle extends Component {
  static displayName = "FormNew";
  static recaptchaName = "Recaptcha";

  static responseType = {
    NONE: "none",
    LOADING: "loading",
    SUCCESS: "success",
    FAILURE: "failure"
  };

  static propTypes = {
    /**
     * Form controls composition
     */
    children: PropTypes.node.isRequired,

    /**
     * Form action
     */
    action: PropTypes.string.isRequired,

    /**
     * Enable/disable AJAX form submission
     */
    ajax: PropTypes.bool,

    /**
     * The name of this form, used when analytics events occur.
     */
    analyticsName: PropTypes.string,

    /**
     * The node names of this form, used when analytics events occur.
     * eg : products can be tracked under name "product_family"
     * textcontrol has name as "input1"
     */
    analyticsNodes: PropTypes.object,

    /**
     * The type of this form, used when analytics events occur.
     */
    analyticsType: PropTypes.string,

    /**
     * Set autocomplete
     */
    autocomplete: PropTypes.string,

    /**
     * The default fail message, used if no message is provided by the server.
     */
    defaultFailMessage: PropTypes.string,

    /**
     * The default success message, used if no message is provided by the server
     */
    defaultSuccessMessage: PropTypes.string,

    /**
     * Header above the form
     */
    header: PropTypes.node,

    /**
     * Form id attribute
     */
    id: PropTypes.string,

    /**
     * IsDark Background
     */
    isDark: PropTypes.bool,

    /**
     * Footer below form controls
     */
    footer: PropTypes.node,

    /**
     * Form submission method to use. Defaults to POST for convenience.
     */
    method: PropTypes.oneOf(["get", "post"]),

    /**
     * Color of the submit button.
     */
    submitButtonColor: PropTypes.oneOf(Button.colorOptions),

    /**
     * Text on the submit button.
     */
    submitButtonText: PropTypes.string,

    /**
     * Text on the submit button when the form is in the process of submitting.
     * Not visible, but should be specified for accessibility.
     */
    submitButtonTextLoading: PropTypes.string,

    /**
     * Text shown on the submit button after a form has submitted.
     */
    submitButtonTextSuccess: PropTypes.string,

    /**
     *  Keyword indicating where to display the response that is received after
     *  submitting the form
     */
    target: PropTypes.oneOf(["_blank", "_parent", "_self", "_top"]),

    /**
     * URL to redirect on the successfull form submit.
     */
    redirectPage: PropTypes.string,

    /**
     * After submit, to perform redirect or to show inline messages
     */
    confirmationType: PropTypes.string,

    /**
     *  Enables multiple form submits, re enables the submit button when subsequent change happens
     */
    allowFormReSubmit: PropTypes.bool
  };

  static defaultProps = {
    ajax: true,
    analyticsName: "",
    analyticsNodes: {},
    analyticsType: "",
    autocomplete: formConsts.autocomplete,
    defaultFailMessage: formConsts.failMessage,
    defaultSuccessMessage: formConsts.successMessage,
    isDark: false,
    method: "post",
    submitButtonColor: "actionPositive",
    submitButtonText: formConsts.submitButtonText,
    submitButtonTextLoading: formConsts.submitButtonTextLoading,
    submitButtonTextSuccess: formConsts.submitButtonTextSuccess
  };

  constructor(props) {
    super(props);

    this.state = {
      hasStarted: false,
      responseMessage: "",
      responseState: FormsNewStyle.responseType.NONE
    };

    this.getClassName = getClassNameFactory(FormsNewStyle.displayName);

    this.getCaptchaClassName = getClassNameFactory(FormsNewStyle.recaptchaName);
  }

  // Removes the invalid class when user updates a field
  removeInvalidClass = event => {
    event.target.parentElement.parentElement.classList.remove("is-invalid");
    if (event.target.type !== "radio") {
      event.target.removeAttribute("aria-invalid");
    }
  };

  handleInvalid = event => {
    event.preventDefault();

    const inputContainer = event.target.parentElement.parentElement;

    if (inputContainer && !inputContainer.classList.contains("is-invalid")) {
      inputContainer.className = classNames(
        inputContainer.className,
        "is-invalid"
      );
      if (event.target.type !== "radio" && event.target.id) {
        event.target.setAttribute("aria-invalid", "true");
      }
    }

    const invalidFieldNames = [];
    const invalidFields = this.formEl.querySelectorAll(":invalid");

    this.setState({
      responseMessage: this.props.defaultFailMessage,
      responseState: FormsNewStyle.responseType.FAILURE
    });

    for (const field of invalidFields) {
      invalidFieldNames.push(field.name);
    }

    fireFormFailureDebounced({
      invalidFieldNames,
      name: this.props.analyticsName,
      nodes: this.props.analyticsNodes,
      type: this.props.analyticsType
    });

    this.formEl.scrollIntoView(true);
  };

  handleFocus = () => {
    if (this.state.hasStarted === false) {
      fireFormStart({
        name: this.props.analyticsName,
        type: this.props.analyticsType
      });

      this.setState({
        hasStarted: true
      });
    }
  };

  isCaptchaEnabled = event => {
    const captchaSelector = `.${this.getCaptchaClassName()}`;
    const responseSelector = `.${this.getCaptchaClassName()} .g-recaptcha-response`;
    const requiredMessageSelector = `.${this.getCaptchaClassName({
      descendantName: "required"
    })}`;

    const invalidFieldNames = [];

    if (
      event.target &&
      event.target.querySelector(captchaSelector) &&
      event.target.querySelector(responseSelector).value === ""
    ) {
      event.target.querySelector(requiredMessageSelector).style.display =
        "block";

      invalidFieldNames.push(FormsNewStyle.recaptchaName);

      fireFormFailureDebounced({
        invalidFieldNames,
        name: this.props.analyticsName,
        nodes: this.props.analyticsNodes,
        type: this.props.analyticsType
      });

      return false;
    } else if (
      event.target &&
      event.target.querySelector(captchaSelector) &&
      event.target.querySelector(responseSelector).value !== ""
    ) {
      event.target.querySelector(requiredMessageSelector).style.display =
        "none";
      event.target.querySelector(
        "#captchaResponse"
      ).value = event.target.querySelector(responseSelector).value;

      return true;
    }
  };

  handleSubmit = async (event, onSubmitSuccess = () => {}) => {
    if (
      this.props.ajax &&
      this.state.responseState !== FormsNewStyle.responseType.LOADING
    ) {
      event.preventDefault();

      const captchaSelector = `.${this.getCaptchaClassName()}`;

      if (
        event.target &&
        event.target.querySelector(captchaSelector) &&
        !this.isCaptchaEnabled(event)
      ) {
        return false;
      }

      this.setState({
        responseMessage: "",
        responseState: FormsNewStyle.responseType.LOADING
      });

      const submitAction = this.props.action;
      const formData = new FormData(this.formEl);
      const response = await submit(submitAction, formData);
      const actionType = this.props.confirmationType || response.action;
      let responseMessage;

      if (response.state === "success") {
        fireFormSubmitSuccess({
          name: this.props.analyticsName,
          nodes: this.props.analyticsNodes,
          type: this.props.analyticsType
        });

        switch (actionType) {
          case "redirect":
            // Wait until the call stack has cleared before completing the redirect.
            // This should give the browser time to commit any cookies set by the
            // network response.
            setTimeout(
              () =>
                window.location.assign(
                  this.props.redirectPage || response.location
                ),
              0
            );

            break;

          case "downloadFile":
            responseMessage = this.props.defaultSuccessMessage;
            setTimeout(() => window.open(response.location, "_blank"), 0);
            break;

          case "inlineMessage":
            responseMessage =
              this.props.defaultSuccessMessage || response.message;
            break;

          case "unknown":
            responseMessage = this.props.defaultSuccessMessage;
            break;
        }

        onSubmitSuccess();
      }

      if (response.state === "failure" || response.state === "fail") {
        switch (response.failType) {
          case "message":
            responseMessage = response.failMessage;
            break;

          default:
            responseMessage = this.props.defaultFailMessage;
            break;
        }

        fireFormSubmitFailure({
          name: this.props.analyticsName,
          nodes: this.props.analyticsNodes,
          type: this.props.analyticsType
        });
        this.formEl.scrollIntoView(true);

        if (response.action === "redirect") {
          // Wait until the call stack has cleared before completing the redirect.
          // This should give the browser time to commit any cookies set by the
          // network response.
          setTimeout(() => window.location.assign(response.location), 0);
        }
      }

      this.setState({
        responseMessage,
        responseState:
          response.state === "success"
            ? FormsNewStyle.responseType.SUCCESS
            : FormsNewStyle.responseType.FAILURE
      });

      if (responseMessage) {
        this.responseEl.scrollIntoView(true);
      }

      if (response.exception) {
        // Re-throw for traceability.
        throw response.exception;
      }
    }
  };

  handleChange = () => {
    this.setState({
      responseMessage: "",
      responseState: FormsNewStyle.responseType.NONE
    });
  };

  componentDidMount = () => {
    this.formEl.addEventListener("input", this.removeInvalidClass);
  };

  componentWillUnmount = () => {
    this.formEl.removeEventListener("input", this.removeInvalidClass);
  };

  render() {
    const { responseMessage, responseState } = this.state;
    const {
      action,
      ajax,
      analyticsName,
      analyticsNodes,
      analyticsType,
      autocomplete,
      defaultFailMessage,
      defaultSuccessMessage,
      children,
      footer,
      header,
      id,
      isDark,
      method,
      submitButtonColor,
      submitButtonText,
      submitButtonTextLoading,
      submitButtonTextSuccess,
      target,
      confirmationType,
      redirectPage,
      allowFormReSubmit
    } = this.props;

    let buttonText;
    let isDisabled = false;
    let isLoading = false;

    switch (responseState) {
      case FormsNewStyle.responseType.SUCCESS:
        buttonText = submitButtonTextSuccess;
        isDisabled = true; // Disable submission; it's already been submitted
        break;
      case FormsNewStyle.responseType.FAILURE:
        buttonText = submitButtonText; // Allow for resubmission
        break;
      case FormsNewStyle.responseType.LOADING:
        buttonText = submitButtonTextLoading;
        isLoading = true;
        break;

      default:
        buttonText = submitButtonText;
        break;
    }

    return (
      <div
        className={this.getClassName({
          modifiers: classNames({
            dark: isDark
          })
        })}
        data-action={action}
        data-ajax={ajax}
        data-analytics-name={analyticsName}
        data-analytics-nodes={JSON.stringify(analyticsNodes)}
        data-analytics-type={analyticsType}
        data-autocomplete={autocomplete}
        data-default-fail-message={defaultFailMessage}
        data-default-success-message={defaultSuccessMessage}
        data-id={id}
        data-is-dark={isDark}
        data-rehydratable={getRehydratableName(FormsNewStyle.displayName)}
        data-submit-button-color={submitButtonColor}
        data-submit-button-text={submitButtonText}
        data-submit-button-text-loading={submitButtonTextLoading}
        data-submit-button-text-success={submitButtonTextSuccess}
        data-redirect-page={redirectPage}
        data-message-type={confirmationType}
        data-allow-form-resubmit={allowFormReSubmit}
      >
        <FormOnSubmitSuccessContextConsumer>
          {onSubmitSuccess => (
            <form
              action={action}
              autoComplete={autocomplete}
              id={id}
              method={method}
              onFocus={this.handleFocus}
              onInvalid={this.handleInvalid}
              onSubmit={event => {
                this.handleSubmit(event, onSubmitSuccess);
              }}
              {...allowFormReSubmit && {
                onChange: this.handleChange
              }}
              ref={ref => {
                this.formEl = ref;
              }}
              target={target}
            >
              {header && (
                <VerticalSpacing size="s">
                  <div
                    className={this.getClassName({ descendantName: "header" })}
                  >
                    {header}
                  </div>
                </VerticalSpacing>
              )}
              {responseMessage ? (
                <VerticalSpacing>
                  <div
                    ref={ref => {
                      this.responseEl = ref;
                    }}
                  >
                    <Alert state={responseState}>{responseMessage}</Alert>
                  </div>
                </VerticalSpacing>
              ) : null}
              <VerticalSpacing>
                <div data-rehydratable-children>{children}</div>
              </VerticalSpacing>
              {footer && (
                <VerticalSpacing>
                  <div
                    className={this.getClassName({
                      descendantName: "footer",
                      className: typestack("p3")
                    })}
                  >
                    {footer}
                  </div>
                </VerticalSpacing>
              )}
              <VerticalSpacing>
                <Group>
                  <Group.Item>
                    <Button
                      kind="submit"
                      color={submitButtonColor}
                      disabled={isLoading || isDisabled}
                    >
                      {buttonText}
                    </Button>
                  </Group.Item>
                </Group>
              </VerticalSpacing>
            </form>
          )}
        </FormOnSubmitSuccessContextConsumer>
      </div>
    );
  }
}

FormsNewStyle.CampaignControl = CampaignControl;
FormsNewStyle.CheckboxControl = CheckboxControl;
FormsNewStyle.ToggleSwitchControl = ToggleSwitchControl;
FormsNewStyle.Fieldset = Fieldset;
FormsNewStyle.PrivacyControl = PrivacyControl;
FormsNewStyle.RadioControl = RadioControl;
FormsNewStyle.SelectControl = SelectControl;
FormsNewStyle.MultiSelectDropdown = MultiSelectDropdown;
FormsNewStyle.TextareaControl = TextareaControl;
FormsNewStyle.TextControl = TextControl;

export default FormsNewStyle;
