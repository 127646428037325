import React from "react";

import Alignment from "@emcm-ui/component-alignment";
import Attribution from "@emcm-ui/component-attribution";
import Button from "@emcm-ui/component-button";
import DateTime from "@emcm-ui/component-date-time";
import Duration from "@emcm-ui/component-duration";
import Eyebrow from "@emcm-ui/component-eyebrow";
import Grid from "@emcm-ui/component-grid";
import Group from "@emcm-ui/component-group";
import Typestack from "@emcm-ui/component-typestack";
import Link from "@emcm-ui/component-link";
import MarkupContainer from "@emcm-ui/component-markup-container";
import Poster from "@emcm-ui/component-poster";
import RichText from "@emcm-ui/component-rich-text";
import PageHeadingWrapper from "@emcm-ui/component-page-heading-wrapper";
import Section from "@emcm-ui/component-section";
import SocialDropdown from "@emcm-ui/component-social-dropdown";
import SocialShare from "@emcm-ui/component-social-share";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";
import Icon from "@emcm-ui/component-icon";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import { getSocialIcon } from "../../sampleAssets/icons";
import SalesCard from "@emcm-ui/component-sales-card";

import heroOne from "../../sampleAssets/heroes/hero-one-1440.jpg";
import heroTwo from "../../sampleAssets/heroes/hero-two-1440.jpg";
import heroThree from "../../sampleAssets/heroes/hero-three-1440.jpg";
import heroFour from "../../sampleAssets/heroes/hero-four-1440.jpg";
import heroFive from "../../sampleAssets/heroes/hero-five-1440.jpg";
import heroSix from "../../sampleAssets/heroes/hero-six-1440.jpg";
import heroSeven from "../../sampleAssets/heroes/hero-seven-1440.jpg";
import heroEight from "../../sampleAssets/heroes/hero-eight-1440.jpg";
import heroNine from "../../sampleAssets/heroes/hero-nine-1440.jpg";
import heroTen from "../../sampleAssets/heroes/hero-ten-1440.jpg";
import heroEleven from "../../sampleAssets/heroes/hero-eleven-1440.jpg";
import heroTwelve from "../../sampleAssets/heroes/hero-twelve-1440.jpg";
import heroThirteen from "../../sampleAssets/heroes/hero-thirteen-1440.jpg";

const Icons = {
  arrowRightWhite: "./icons/ui/white/arrow-right.svg",
  arrowRightBlue: "./icons/ui/blue/arrow-right.svg",
  timeWhite: "./icons/ui/white/time.svg",
  arrowLeft: "./icons/ui/grey/arrow-left-07.svg"
};

const playtriangleIcon = (
  <svg
    fill="currentColor"
    viewBox="0 0 32 32"
    className="tr-Icon tr-Icon--s"
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <defs />
    <defs>
      <path d="M29 16L3 30V2z" id="a" />
    </defs>
    <use xlinkHref="#a" fillRule="evenodd" />
  </svg>
);

const attributionSocialButtons = [
  <a href="https://example.com">
    <SVGIcon name="facebook" size="s" getExternalIconPath={getSocialIcon} />
  </a>,
  <a href="https://example.com">
    <SVGIcon name="twitter" size="s" getExternalIconPath={getSocialIcon} />
  </a>,
  <a href="https://example.com">
    <SVGIcon name="linkedin" size="s" getExternalIconPath={getSocialIcon} />
  </a>,
  <a href="https://example.com">
    <SVGIcon name="email" size="s" getExternalIconPath={getSocialIcon} />
  </a>,
  <a href="https://example.com">
    <SVGIcon name="instagram" size="s" getExternalIconPath={getSocialIcon} />
  </a>
];

const Heroes = () => {
  return (
    <div>
      <Section background="grayDark" fullWidth>
        <Poster
          height="s"
          videoSrc="video-loop/player.html"
          videoWidth={1280}
          videoHeight={720}
        >
          <Grid variant="7/12,5/12" gutterlessAtNarrow>
            <Grid.Item>
              <VerticalSpacing>
                <Typestack align="left" rank="h1" type="h1">
                  Legal
                </Typestack>
              </VerticalSpacing>
              <VerticalSpacing size="l">
                <RichText id="description-1" large>
                  <p>
                    Providing unrivaled services and solutions that integrate
                    content, expertise and&nbsp;technologies
                  </p>
                </RichText>
              </VerticalSpacing>
              <Link
                contentLocation="https://www.mocky.io/v2/5b8527613000008100729143"
                href="https://example.com"
                modalLabel="example modal"
                type="icon"
                icon={<Icon path={Icons.arrowRightWhite} size="s" />}
                iconPosition={"right"}
                id="link-1"
                ariaLabelledBy="title-1 description-1"
              >
                Learn more
              </Link>
            </Grid.Item>
          </Grid>
        </Poster>
      </Section>
      <Section fullWidth>
        <Poster height="s" imageSrc={heroOne}>
          <Grid variant="7/12,5/12" gutterlessAtNarrow>
            <Grid.Item>
              <VerticalSpacing>
                <Typestack align="left" rank="h1" type="h1">
                  Legal
                </Typestack>
              </VerticalSpacing>
              <VerticalSpacing size="l">
                <RichText id="description-2" large>
                  <p>
                    Providing unrivaled services and solutions that integrate
                    content, expertise and&nbsp;technologies
                  </p>
                </RichText>
              </VerticalSpacing>
              <Link
                contentLocation="https://www.mocky.io/v2/5b8527613000008100729143"
                href="https://example.com"
                modalLabel="example modal"
                type="icon"
                icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                iconPosition={"right"}
                id="link-2"
                ariaLabelledBy="title-2 description-2"
              >
                Learn more
              </Link>
            </Grid.Item>
          </Grid>
        </Poster>
      </Section>
      <Section background="grayDark" fullWidth>
        <Poster height="s" imageOverlay="scrimBottomDark" imageSrc={heroTwo}>
          <Grid variant="7/12,5/12" gutterlessAtNarrow>
            <Grid.Item>
              <VerticalSpacing>
                <Typestack align="left" rank="h1" type="h1">
                  Legal
                </Typestack>
              </VerticalSpacing>
              <VerticalSpacing size="l">
                <RichText id="description-3" large>
                  <p>
                    Providing unrivaled services and solutions that integrate
                    content, expertise and&nbsp;technologies
                  </p>
                </RichText>
              </VerticalSpacing>
              <Link
                href="https://example.com"
                type="icon"
                icon={<Icon path={Icons.arrowRightWhite} size="s" />}
                iconPosition={"right"}
                id="link-3"
                ariaLabelledBy="title-3 description-3"
              >
                Learn more
              </Link>
            </Grid.Item>
          </Grid>
        </Poster>
      </Section>
      <Section fullWidth>
        <Poster height="l" imageSrc={heroThree}>
          <Grid variant="7/12,5/12" gutterlessAtNarrow>
            <Grid.Item>
              <VerticalSpacing size="xs">
                <Eyebrow text="News Agency" />
              </VerticalSpacing>
              <VerticalSpacing>
                <Typestack align="left" rank="h1" type="h1">
                  Mining trusted news from LSEG
                </Typestack>
              </VerticalSpacing>
              <Button
                color="secondary"
                contentLocation="https://www.mocky.io/v2/5b8527613000008100729143"
                kind="link"
                href="https://example.com"
                modalLabel="example modal"
                id="button-4"
                ariaLabelledBy="title-4"
              >
                Learn more
              </Button>
            </Grid.Item>
          </Grid>
        </Poster>
      </Section>
      <Section background="grayDark" fullWidth>
        <Poster height="l" imageSrc={heroFour}>
          <Grid variant="7/12,5/12" gutterlessAtNarrow>
            <Grid.Item>
              <VerticalSpacing size="xs">
                <Eyebrow text="Financial &amp; Risk" />
              </VerticalSpacing>
              <VerticalSpacing>
                <Typestack align="left" rank="h1" type="h1">
                  Mining trusted news from LSEG
                </Typestack>
              </VerticalSpacing>
              <Button
                color="primary"
                kind="link"
                href="https://example.com"
                id="button-5"
                ariaLabelledBy="title-5"
              >
                View product details
              </Button>
            </Grid.Item>
          </Grid>
        </Poster>
      </Section>
      <Section background="grayDark" fullWidth>
        <Poster
          height="l"
          imageOverlay="scrimBottomDark"
          imageSrc={heroFive}
          alignBottom
          share={
            <MarkupContainer>
              <SocialDropdown>
                <SocialDropdown.Item>
                  <SocialShare pageUrl="https://www.lseg.com" type="facebook">
                    <SVGIcon
                      name="facebook"
                      size="s"
                      getExternalIconPath={getSocialIcon}
                    />
                  </SocialShare>
                </SocialDropdown.Item>
                <SocialDropdown.Item>
                  <SocialShare
                    pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
                    pageUrl="https://www.lseg.com"
                    type="twitter"
                  >
                    <SVGIcon
                      name="twitter"
                      size="s"
                      getExternalIconPath={getSocialIcon}
                    />
                  </SocialShare>
                </SocialDropdown.Item>
                <SocialDropdown.Item>
                  <SocialShare
                    pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
                    pageUrl="https://www.lseg.com"
                    type="linkedIn"
                  >
                    <SVGIcon
                      name="linkedin"
                      size="s"
                      getExternalIconPath={getSocialIcon}
                    />
                  </SocialShare>
                </SocialDropdown.Item>
                <SocialDropdown.Item>
                  <SocialShare
                    pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
                    pageUrl="https://www.lseg.com"
                    type="email"
                  >
                    <SVGIcon
                      name="email"
                      size="s"
                      getExternalIconPath={getSocialIcon}
                    />
                  </SocialShare>
                </SocialDropdown.Item>
              </SocialDropdown>
            </MarkupContainer>
          }
        >
          <Grid variant="7/12,5/12" gutterlessAtNarrow>
            <Grid.Item>
              <VerticalSpacing size="xs">
                <Eyebrow text="Brexit" />
              </VerticalSpacing>
              <VerticalSpacing>
                <Typestack align="left" rank="h1" type="h1">
                  Gazing into the crystal ball: The global enforcement regime
                  for conduct risk
                </Typestack>
              </VerticalSpacing>
              <Group>
                <Group.Item>
                  <Attribution
                    isDark={true}
                    href="https://www.example.com"
                    imgSrc="headshot.jpg"
                    name="Casey S."
                    caption="Senior writer, large & medium law firms"
                    socialButtons={attributionSocialButtons}
                  />
                </Group.Item>
                <Group.Item>
                  <Alignment vertical="center">
                    <DateTime dateTime="2016-03-02 13:57" format="PP" />
                  </Alignment>
                </Group.Item>
                <Group.Item>
                  <Alignment vertical="center">
                    <Duration
                      duration="P15M"
                      icon={<Icon path={Icons.timeWhite} size="xs" />}
                    >
                      15 min
                    </Duration>
                  </Alignment>
                </Group.Item>
              </Group>
            </Grid.Item>
          </Grid>
        </Poster>
      </Section>
      <Section background="grayDark" fullWidth>
        <Poster
          height="l"
          imageOverlay="scrimBottomDark"
          imageSrc={heroFive}
          share={
            <MarkupContainer>
              <SocialDropdown>
                <SocialDropdown.Item>
                  <SocialShare pageUrl="https://www.lseg.com" type="facebook">
                    <SVGIcon
                      name="facebook"
                      size="s"
                      getExternalIconPath={getSocialIcon}
                    />
                  </SocialShare>
                </SocialDropdown.Item>
                <SocialDropdown.Item>
                  <SocialShare
                    pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
                    pageUrl="https://www.lseg.com"
                    type="twitter"
                  >
                    <SVGIcon
                      name="twitter"
                      size="s"
                      getExternalIconPath={getSocialIcon}
                    />
                  </SocialShare>
                </SocialDropdown.Item>
                <SocialDropdown.Item>
                  <SocialShare
                    pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
                    pageUrl="https://www.lseg.com"
                    type="linkedIn"
                  >
                    <SVGIcon
                      name="linkedin"
                      size="s"
                      getExternalIconPath={getSocialIcon}
                    />
                  </SocialShare>
                </SocialDropdown.Item>
                <SocialDropdown.Item>
                  <SocialShare
                    pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
                    pageUrl="https://www.lseg.com"
                    type="email"
                  >
                    <SVGIcon
                      name="email"
                      size="s"
                      getExternalIconPath={getSocialIcon}
                    />
                  </SocialShare>
                </SocialDropdown.Item>
              </SocialDropdown>
            </MarkupContainer>
          }
        >
          <Grid variant="7/12,5/12" gutterlessAtNarrow>
            <Grid.Item>
              <VerticalSpacing size="xs">
                <Eyebrow text="Brexit" />
              </VerticalSpacing>
              <VerticalSpacing>
                <Typestack align="left" rank="h1" type="h1">
                  Gazing into the crystal ball: The global enforcement regime
                  for conduct risk
                </Typestack>
              </VerticalSpacing>
            </Grid.Item>
          </Grid>
        </Poster>
      </Section>
      <Section background="grayDark" fullWidth>
        <Poster height="xs" imageSrc={heroSix}>
          <Grid variant="7/12,5/12" gutterlessAtNarrow>
            <Grid.Item>
              <Typestack align="left" rank="h1" type="h1">
                Latest insights including reports, white papers, case studies,
                and
              </Typestack>
            </Grid.Item>
          </Grid>
        </Poster>
      </Section>
      <Section fullWidth>
        <Poster height="xs" imageSrc={heroSeven}>
          <Grid variant="7/12,5/12" gutterlessAtNarrow>
            <Grid.Item>
              <VerticalSpacing size="xl">
                <Link
                  href="https://example.com"
                  type="icon"
                  icon={<Icon path={Icons.arrowLeft} size="s" />}
                  iconPosition={"left"}
                >
                  Back to all brands
                </Link>
              </VerticalSpacing>
              <VerticalSpacing>
                <Typestack align="left" rank="h1" type="h1">
                  ONESOURCE is powerful tax technology for global businesses
                </Typestack>
              </VerticalSpacing>
            </Grid.Item>
          </Grid>
        </Poster>
      </Section>
      <Section fullWidth>
        <Poster height="l" imageSrc={heroEight}>
          <Grid variant="7/12,5/12" gutterlessAtNarrow>
            <Grid.Item>
              <VerticalSpacing>
                <Typestack align="left" rank="h1" type="h1">
                  UltraTax CS
                </Typestack>
              </VerticalSpacing>
              <VerticalSpacing size="l">
                <RichText id="description-6" compact large>
                  <p>Professional Tax Software for Preparers</p>
                </RichText>
              </VerticalSpacing>
              <Group>
                <Group.Item>
                  <Button
                    color="primary"
                    kind="link"
                    href="https://example.com"
                    id="button-6"
                    ariaLabelledBy="title-6 description-6"
                  >
                    Try it for free
                  </Button>
                </Group.Item>
                <Group.Item>
                  <Button
                    color="secondary"
                    kind="link"
                    href="https://example.com"
                  >
                    Contact sales
                  </Button>
                </Group.Item>
              </Group>
            </Grid.Item>
          </Grid>
        </Poster>
      </Section>
      <Section background="grayDark" fullWidth>
        <Poster height="s" imageSrc={heroNine}>
          <Grid variant="7/12,5/12" gutterlessAtNarrow>
            <Grid.Item>
              <VerticalSpacing>
                <Typestack align="left" rank="h1" type="h1">
                  BEPS
                </Typestack>
              </VerticalSpacing>
              <VerticalSpacing size="l">
                <RichText compact large>
                  <p>
                    BEPS is shaking up international tax law—and global
                    businesses need to respond with smart strategies.
                  </p>
                </RichText>
              </VerticalSpacing>
            </Grid.Item>
          </Grid>
        </Poster>
      </Section>
      <Section fullWidth>
        <Poster height="s" imageSrc={heroTen}>
          <Grid variant="7/12,5/12" gutterlessAtNarrow>
            <Grid.Item>
              <VerticalSpacing>
                <Typestack align="left" rank="h2" type="h2">
                  In a profession that’s becoming increasingly commoditized,{" "}
                  payroll is a rare bright spot for accounting firms.
                </Typestack>
              </VerticalSpacing>
            </Grid.Item>
          </Grid>
        </Poster>
      </Section>
      <Section background="grayDark" fullWidth>
        <Poster height="l" imageSrc={heroEleven}>
          <Grid variant="7/12,5/12" gutterlessAtNarrow>
            <Grid.Item>
              <VerticalSpacing size="xs">
                <Eyebrow text="Tradeshow" />
              </VerticalSpacing>
              <VerticalSpacing>
                <Typestack align="left" rank="h1" type="h1">
                  SYNERGY 2017 Professional Conference
                </Typestack>
              </VerticalSpacing>
              <VerticalSpacing size="l">
                <RichText id="description-7" compact large>
                  <p>
                    Today is the most exciting time to be a tax professional.
                    That’s because tax is moving to the forefront of business.
                    And now, so can you.
                  </p>
                </RichText>
              </VerticalSpacing>
              <Group>
                <Group.Item>
                  <Button
                    color="primary"
                    kind="link"
                    href="https://example.com"
                    id="button-7"
                    ariaLabelledBy="title-7 description-7"
                  >
                    Register now
                  </Button>
                </Group.Item>
                <Group.Item>
                  <Button
                    color="secondaryDark"
                    href="https://example.com"
                    icon={playtriangleIcon}
                    kind="link"
                  >
                    Watch how it’s done
                  </Button>
                </Group.Item>
              </Group>
            </Grid.Item>
          </Grid>
        </Poster>
      </Section>
      <Section background="grayDark" fullWidth>
        <Poster height="l" imageSrc={heroTwelve} alignBottom>
          <Grid variant="7/12,5/12" gutterlessAtNarrow>
            <Grid.Item>
              <VerticalSpacing size="xs">
                <Eyebrow text="White Paper" />
              </VerticalSpacing>
              <VerticalSpacing>
                <Typestack align="left" rank="h1" type="h1">
                  Top Five Ways to Ensure a Successful Tax Christmas
                </Typestack>
              </VerticalSpacing>
              <Group>
                <Group.Item>Progressive Media Group, Inc.</Group.Item>
                <Group.Item>
                  <DateTime dateTime="2016-03-02 13:57" format="PP" />
                </Group.Item>
                <Group.Item>
                  <Duration
                    duration="P15M"
                    icon={<Icon path={Icons.timeWhite} size="xs" />}
                  >
                    15 min
                  </Duration>
                </Group.Item>
              </Group>
            </Grid.Item>
          </Grid>
        </Poster>
      </Section>
      <Section fullWidth>
        <Poster height="l" imageSrc={heroThirteen}>
          <Grid variant="7/12,5/12" gutterlessAtNarrow>
            <Grid.Item>
              <Typestack align="left" rank="h1" type="h1">
                Corporate solutions that transform the way tax teams work
              </Typestack>
            </Grid.Item>
          </Grid>
        </Poster>
      </Section>
      <Section>
        <Typestack align="left" rank="h2" type="h2">
          Search results for ‘Indirect Tax’
        </Typestack>
      </Section>
      <Section background="grayDark" fullWidth>
        <Poster height="s" imageSrc={heroNine}>
          <Grid variant="7/12,5/12" gutterlessAtNarrow>
            <Grid.Item>
              <PageHeadingWrapper
                compact
                description="BEPS is shaking up international tax law—and global
                businesses need to respond with smart strategies."
                heading={<span>Page&shy;Heading&shy;Wrapper example</span>}
                large
              />
            </Grid.Item>
          </Grid>
        </Poster>
      </Section>
      <Section fullWidth>
        <Poster height="s" imageSrc={heroTen}>
          <Grid variant="7/12,5/12" gutterlessAtNarrow>
            <Grid.Item>
              <PageHeadingWrapper
                description="BEPS is shaking up international tax law—and global
                businesses need to respond with smart strategies."
                heading="Page&shy;Heading&shy;Wrapper example"
              />
            </Grid.Item>
          </Grid>
        </Poster>
      </Section>
      <Section fullWidth>
        <Poster height="s" imageSrc={heroTen}>
          <Grid variant="even-2" gutterVerticalSmall>
            <Grid.Item>
              <VerticalSpacing>
                <Typestack align="left" rank="h1" type="h1">
                  Legal
                </Typestack>
              </VerticalSpacing>
              <VerticalSpacing size="l">
                <RichText large>
                  <p>
                    Providing unrivaled services and solutions that integrate
                    content, expertise and&nbsp;technologies
                  </p>
                </RichText>
              </VerticalSpacing>
            </Grid.Item>
            <Grid.Item>
              <SalesCard
                theme="dark"
                currency="£"
                price={259} // eslint-disable-line no-magic-numbers
                isPriceTermRequired={true}
                headingStyle="Default"
                descriptiveCard="Benefits"
                validity="( per license / month )"
                descriptionType="Default"
                description="Short description about the product the users is actually buying"
                isBtnRequired={true}
                href="http://example.com/"
                buttonText="Add to Cart"
                isLegalCopyRequired={false}
                legalCopyText="*Legal copy"
                descriptionArray={[
                  { name: "List item 1" },
                  { name: "List item 2" },
                  { name: "List item 3" }
                ]}
              />
            </Grid.Item>
          </Grid>
        </Poster>
      </Section>
    </div>
  );
};

export default Heroes;
