import { getClassName as baseGetClassName } from "react-suitcssify/dist/lib/utility";
import configuration from "@emcm-ui/utility-configuration";
import classNames from "classnames";

const getClassName = args => {
  return baseGetClassName({
    namespace: configuration.current.styles.namespace,
    ...args
  });
};

const getClassNameFactory = componentName => {
  return args =>
    getClassName({
      componentName,
      ...args
    });
};

const toggleInvalidClass = (elementClassName, required, hasError) => {
  return classNames(elementClassName, { "is-invalid": required && hasError });
};

export default getClassNameFactory;
export { getClassName, toggleInvalidClass };
