import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, boolean, number } from "@storybook/addon-knobs";
import { action } from "@storybook/addon-actions";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import Pagination from "./index";
import "./Pagination.css";
import PaginationREADME from "../README.md";

const stories = storiesOf("Components/Pagination", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(PaginationREADME));

stories
  .add("Client: Default", () => (
    <Pagination
      condensed={boolean("Condensed", false)}
      condensedAtNarrow={boolean("Condensed At Narrow", true)}
      initialPage={1}
      baseUrl="https://www.example.com/?page={currentPage}"
      borderlessAtStandard={boolean("borderLess at Standard", true)}
      displayNavigationButtonLabels={boolean(
        "Display Navigation button labels",
        false
      )}
      onPageChanged={action("Page Changed")}
      outerBoundPageCount={number("Outer Bound Page Count", 1)}
      pageCount={number("Page Count", 20)} // eslint-disable-line no-magic-numbers
      pageRangeCount={number("Page Range Count", 5)} // eslint-disable-line no-magic-numbers
      showPageItemAtNarrow={boolean("Show page item at Narrow", true)}
    />
  ))
  .add("Server: Default", () => (
    <Pagination
      initialPage={1}
      baseUrl="https://www.example.com/?page={currentPage}"
      outerBoundPageCount={number("Outer Bound Page Count", 1)}
      pageCount={number("Page Count", 20)} // eslint-disable-line no-magic-numbers
      pageRangeCount={number("Page Range Count", 5)} // eslint-disable-line no-magic-numbers
      showPageItemAtNarrow={boolean("Show page item at Narrow", true)}
    />
  ))
  .add("Server: Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Pagination
        initialPage={1}
        baseUrl="https://www.example.com/?page={currentPage}"
        outerBoundPageCount={number("Outer Bound Page Count", 1)}
        pageCount={number("Page Count", 20)} // eslint-disable-line no-magic-numbers
        pageRangeCount={number("Page Range Count", 5)} // eslint-disable-line no-magic-numbers
        showPageItemAtNarrow={boolean("Show page item at Narrow", true)}
      />
    </Language>
  ));
