/**
 * @define Expandable
 * Expandable component for displaying multiple expandable items.
 *
 */

// Vendor
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

// emcm-ui
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

// Local
import Item from "../Item";

const List = ({ children, ordered }) => {
  const getClassName = getClassNameFactory(List.displayName);

  const ListElement = ordered ? "ol" : "ul";

  return (
    <ListElement
      className={getClassName({ modifiers: classNames({ ordered }) })}
    >
      {React.Children.map(
        children,
        (child, index) =>
          child.type === Item && (
            <li className={getClassName({ descendantName: "itemContainer" })}>
              {ordered && (
                <span
                  className={getClassName({
                    descendantName: "itemNumber",
                    className: typestack("h5")
                  })}
                >
                  {index + 1}
                </span>
              )}
              {child}
            </li>
          )
      )}
    </ListElement>
  );
};

List.displayName = "Expandable.List";

List.propTypes = {
  /**
   * The content of the list items. These should be of type `Expandable.Item`.
   */
  children: PropTypes.node,
  /**
   * Ordered list modifier
   */
  ordered: PropTypes.bool
};

export default List;
