import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import * as TabEvents from "./tabevents/tabevents";

import AjaxPanel from "../../component-panel/src/Panel/Panel";
import Tab from "./components/Tab";
import TabList from "./components/TabList";
import { Provider as AjaxPanelProvider } from "../../component-panel/src/ajaxPanelProvider";

class ProgressTracker extends Component {
  static displayName = "ProgressTracker";

  static propTypes = {
    /**
     * An analytics function, accepting category, action, and label as arguments.
     */
    analytics: PropTypes.func,

    /**
     * The content of the tabs. These should be of type `Tabs.Picker` or `Tabs.Panel`.
     */
    children: PropTypes.node,

    /**
     * Default tab for the tracker.
     */
    defaultTab: PropTypes.string,
    /**
     * Boolean for for making disabled the completed tabs
     */
    disableVisited: PropTypes.bool,
    /**
     * Tab title type
     */
    tabTitleType: PropTypes.string
  };

  static defaultProps = {
    analytics: () => {}
  };

  static setLocationParam = param => {
    if (history.pushState) {
      const url = new URL(window.location.href);

      url.searchParams.set("activeTab", param);
      window.history.pushState({ path: url.href }, "", url.href);
    }
  };

  static getTabs(children) {
    const tabs = [];

    React.Children.forEach(children, child => {
      if (child.type === AjaxPanel) {
        tabs.push({
          contentLocation: child.props.contentLocation,
          pageLocation: child.props.pageLocation,
          relatedId: child.props.id,
          title: child.props.tabTitle,
          description: child.props.description
        });
      }
    });

    return tabs;
  }

  constructor(props) {
    super(props);

    this.getClassName = getClassNameFactory(ProgressTracker.displayName);

    this.visibleFromRight = getClassNameFactory("VisibleFromRight");
    this.visibleFromLeft = getClassNameFactory("VisibleFromLeft");

    const tabs = ProgressTracker.getTabs(props.children);

    const url = new URL(window.location.href);
    const param = url.searchParams.get("activeTab");

    this.trackerConfigurations = {
      tabWidth: 160,
      tabletMinWidth: 768,
      tabletMaxWidth: 1024,
      progressDividerNumber: 2,
      adjustPosition: 50
    };

    this.state = {
      currentOpenPanel:
        param || props.defaultTab || (tabs[0] && tabs[0].relatedId) || null,
      tabs
    };

    this.tabRef = React.createRef();
  }

  keyboardTracker = {
    findNextTab: (currentOpenPanelIndex, key, direction) => {
      const nextKey = direction === "ltr" ? "ArrowRight" : "ArrowLeft";
      const previousKey = direction === "ltr" ? "ArrowLeft" : "ArrowRight";

      if (
        [nextKey, previousKey].includes(key) &&
        !(key === previousKey && this.props.disableVisited)
      ) {
        const nextIndex = currentOpenPanelIndex + (key === nextKey ? 1 : -1); // eslint-disable-line no-magic-numbers
        const nextTab = this.state.tabs[
          Math.min(this.state.tabs.length - 1, nextIndex)
        ];

        return nextTab;
      }

      return null;
    },
    handleTabListKeyDown: e => {
      const { tabs, currentOpenPanel } = this.state;

      const currentOpenPanelIndex = tabs.findIndex(
        tab => tab.relatedId === currentOpenPanel
      );

      const direction = getComputedStyle(this.tabRef.current).direction;
      const focusPanelKey = "ArrowDown";
      const nextTab = this.keyboardTracker.findNextTab(
        currentOpenPanelIndex,
        e.key,
        direction
      );

      if (nextTab) {
        this.setOpenPanel(nextTab.relatedId);
        ProgressTracker.setLocationParam(nextTab.relatedId);
      } else if (e.key === focusPanelKey) {
        this.tabRef.current.querySelector(`#${currentOpenPanel}`).focus();
      }

      this.keepTabsInItsPosition();
    }
  };

  userEvent = {
    handleTabClick: (e, relatedId) => {
      e.preventDefault();

      this.setOpenPanel(relatedId);

      this.keepTabsInItsPosition();
    }
  };

  setOpenPanel = panelId => {
    this.setState({
      currentOpenPanel: panelId
    });

    const { tabs } = this.state;

    ProgressTracker.setLocationParam(panelId);

    const tabIndex = tabs.findIndex(tab => {
      return tab.relatedId === panelId;
    });
    const tab = tabs[tabIndex];

    this.props.analytics("Tab navigation", `Click ${tabIndex + 1}`, tab.title);
  };

  keepTabsInItsPosition() {
    const element = this.tabRef.current.querySelector(
      `#${this.state.currentOpenPanel}`
    ).parentElement;

    const rect = element.getBoundingClientRect();
    const offsetFromTop = element.offsetTop;
    const tabPosition = offsetFromTop - rect.top;

    window.scrollTo(0, tabPosition);
  }

  componentDidMount() {
    const state = this.state;

    const tabElement = this.tabRef.current;

    const visibleFromRight = this.visibleFromRight();

    const visibleFromLeft = this.visibleFromLeft();

    const trackerConfigurations = this.trackerConfigurations;

    const tabDetails = {
      state,
      tabElement,
      visibleFromRight,
      visibleFromLeft,
      trackerConfigurations
    };

    TabEvents.position.fixTabFromRight(tabDetails);
  }

  componentDidUpdate() {
    const state = this.state;

    const tabElement = this.tabRef.current;

    const visibleFromLeft = this.visibleFromLeft();

    const visibleFromRight = this.visibleFromRight();

    const trackerConfigurations = this.trackerConfigurations;

    const tabDetails = {
      state,
      tabElement,
      visibleFromRight,
      visibleFromLeft,
      trackerConfigurations
    };

    TabEvents.position.fixTabFromRight(tabDetails);
  }

  render() {
    const { children, defaultTab, disableVisited, tabTitleType } = this.props;

    const { tabs } = this.state;

    const selectedTabCount = TabEvents.tabEvents.getSelectedTabCount(
      this.state
    );

    return (
      <div
        className={this.getClassName({ modifiers: classNames() })}
        data-default-tab={defaultTab}
        data-disabled-visited={disableVisited}
        data-rehydratable={getRehydratableName(ProgressTracker.displayName)}
        data-tab-title-type={tabTitleType}
        ref={this.tabRef}
        aria-label={ProgressTracker.displayName}
      >
        <div
          className={this.getClassName({
            descendantName: "list",
            modifiers: tabTitleType
          })}
        >
          <TabList>
            {tabs.map((tab, count) => {
              const isInProgress =
                tab.relatedId === this.state.currentOpenPanel;

              return (
                <Tab
                  {...tab}
                  key={tab.relatedId}
                  onClick={this.userEvent.handleTabClick}
                  onKeyDown={this.keyboardTracker.handleTabListKeyDown}
                  inprogress={isInProgress}
                  isdisabled={disableVisited && !isInProgress}
                  completed={count < selectedTabCount}
                  incomplete={count >= selectedTabCount}
                />
              );
            })}
          </TabList>
        </div>

        <div className={this.getClassName({ descendantName: "panels" })}>
          <AjaxPanelProvider value={this.state.currentOpenPanel}>
            {children}
          </AjaxPanelProvider>
        </div>
      </div>
    );
  }
}

ProgressTracker.Panel = AjaxPanel;

ProgressTracker.tabTitleType = [
  "number",
  "upper-alpha",
  "lower-alpha",
  "lower-roman",
  "upper-roman"
];

export default ProgressTracker;
