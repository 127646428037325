import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, select, number, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import Grid from "@emcm-ui/component-grid";
import Tile from "./index";
import TileREADME from "../README.md";
import "./Tile.css";
import Icon from "@emcm-ui/component-icon";

const stories = storiesOf("Components/Tile", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(TileREADME));

const loremIpsum =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation";

const arabicSentence =
  "الشعوب والأمم حتى يسعى كل فرد وهيئة في المجتمع، واضعين على الدوام هذا الإعلان نصب أعينهم، إلى توطيد احترام هذه الحقوق والحريات عن طريق التعليم والتربية واتخاذ إجراءات مطردة، قومي";

const supportedGridVariants = ["even-2", "even-3", "even-4"];

const randomSentenceLength = seedSentence => {
  /* eslint-disable no-magic-numbers */
  const minimum = 5;
  const words = seedSentence.split(" ");

  const randomRangeValue = Math.floor(Math.random() * (words.length - minimum));

  return words.slice(0, (randomRangeValue + minimum) % words.length).join(" ");
};

const tileIcons = [
  "./icons/nucleo/business-finance/large/agenda.svg",
  "./icons/nucleo/business-finance/large/building.svg",
  "./icons/nucleo/business-finance/large/chart-bar-growth.svg",
  "./icons/nucleo/business-finance/large/chart-pie-2.svg",
  "./icons/nucleo/business-finance/large/signature.svg"
];

stories
  .add("Default", () => (
    <Grid variant={select("Grid variant", supportedGridVariants, "even-4")}>
      {[...Array(number("Number of tiles", 5)).keys()].map(i => {
        return (
          <Grid.Item key={i}>
            <Tile
              title={`${text("Tile title", "Tile heading")} #${i + 1}`}
              description={randomSentenceLength(loremIpsum)}
              type={select("Tile type", Tile.types, "centeredIcon")}
              linkTitle="Trust principles"
              linkIcon={
                <Icon path="./icons/ui/blue/arrow-right.svg" size="s" />
              }
              href="https://example.com"
              icon={<Icon path={tileIcons[i]} size="l" />}
              headingRank="h5"
            />
          </Grid.Item>
        );
      })}
    </Grid>
  ))
  .add("Without links", () => (
    <Grid variant={select("Grid variant", supportedGridVariants, "even-4")}>
      {[...Array(number("Number of tiles", 5)).keys()].map(i => {
        return (
          <Grid.Item key={i}>
            <Tile
              title={`${text("Tile title", "Tile heading")} #${i + 1}`}
              description={randomSentenceLength(loremIpsum)}
              type={select("Tile type", Tile.types, "centeredIcon")}
              icon={<Icon path={tileIcons[i]} size="l" />}
            />
          </Grid.Item>
        );
      })}
    </Grid>
  ))
  .add("Within 1180px area", () => (
    <div
      style={{
        maxWidth: "78.7em",
        marginLeft: "auto",
        marginRight: "auto"
      }}
    >
      <Grid variant={select("Grid variant", supportedGridVariants, "even-4")}>
        {[...Array(number("Number of tiles", 4)).keys()].map(i => {
          return (
            <Grid.Item key={i}>
              <Tile
                title={`${text("Tile title", "Tile heading")} #${i + 1}`}
                description={randomSentenceLength(loremIpsum)}
                type={select("Tile type", Tile.types, "centeredIcon")}
                href="https://example.com"
                icon={<Icon path={tileIcons[i]} size="l" />}
              />
            </Grid.Item>
          );
        })}
      </Grid>
    </div>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Grid variant={select("Grid variant", supportedGridVariants, "even-4")}>
        {[...Array(number("Number of tiles", 5)).keys()].map(i => {
          return (
            <Grid.Item key={i}>
              <Tile
                title={`${(i + 1).toLocaleString("ar-EG")} ${text(
                  "Tile title",
                  "مارد لأداء لفرنسا"
                )}`}
                description={randomSentenceLength(arabicSentence)}
                linkTitle="مارد لأداء لفرنسا"
                href="https://example.com"
                type={select("Tile type", Tile.types, "centeredIcon")}
                icon={<Icon path={tileIcons[i]} size="l" />}
              />
            </Grid.Item>
          );
        })}
      </Grid>
    </Language>
  ));
