import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const TabList = ({ children }) => {
  const getClassName = getClassNameFactory("ProgressTabTabList");

  return (
    <div className={getClassName({ modifiers: classNames() })}>
      <div className={getClassName({ descendantName: "shadowLeft" })} />
      <ul className={getClassName({ descendantName: "list" })}>{children}</ul>
      <div className={getClassName({ descendantName: "shadowRight" })} />
    </div>
  );
};

TabList.propTypes = {
  /**
   * The children of this `TabList`. Should be of type `Tab`
   */
  children: PropTypes.node
};

TabList.defaultProps = {
  children: null
};

TabList.displayName = "ProgressTab.ProgressTabList";

export default TabList;
