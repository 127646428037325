import React from "react";
import ToggleSwitchControl from "./ToggleSwitchControl";

// eslint-disable-next-line require-await
export default async domNode => {
  const props = {
    active: JSON.parse(domNode.getAttribute("data-active")),
    activeText: domNode.getAttribute("data-activetext"),
    inActiveText: domNode.getAttribute("data-inactivetext"),
    name: domNode.getAttribute("data-name"),
    id: domNode.getAttribute("data-id"),
    disabled: JSON.parse(domNode.getAttribute("data-disabled"))
  };

  return <ToggleSwitchControl {...props} />;
};
