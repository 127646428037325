import React from "react";
import { storiesOf } from "@storybook/react";
import {
  withKnobs,
  text,
  select,
  number,
  boolean,
  object
} from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import SalesCard from "./SalesCard.js";
import "./SalesCard.css";
import SalesCardREADME from "../README.md";
import StaticRenderer from "@emcm-ui/component-static-renderer";

const stories = storiesOf("Components/E-Commerce/SalesCard", module);

const themeTypes = SalesCard.themeTypes;

const headingStyle = SalesCard.headingStyle;

const currency = SalesCard.currency;

const descriptionType = SalesCard.descriptionType;

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(SalesCardREADME));

stories.add("Default", () => (
  <SalesCard
    theme={select("Theme", themeTypes, themeTypes[0])}
    currency={select("Currency", currency, currency[0])}
    price={number("Price", 259)} // eslint-disable-line no-magic-numbers
    isPriceTermRequired={boolean("Price term required", true)}
    headingStyle={select("Heading Style", headingStyle, headingStyle[0])}
    descriptiveCard={text("Descriptive Card Text", "Benefits")}
    validity={text("License Label", "( per license / month )")}
    descriptionType={select(
      "Description Type",
      descriptionType,
      descriptionType[0]
    )}
    description={text(
      "Description",
      "Short description about the product the users is actually buying"
    )}
    isBtnRequired={boolean("Show Button", true)}
    href={text("Button href", "http://example.com/")}
    buttonText={text("Button Label", "Add to Cart")}
    isSecondaryBtnRequired={boolean("Show secondary Button", false)}
    secondaryHref={text("Secondary button href", "http://example.com/")}
    secondaryButtonText={text("Secondary button Label", "Button")}
    isLegalCopyRequired={boolean("Legal Copy Required", false)}
    legalCopyText={text("Legal Copy Text", "*Legal copy")}
    descriptionArray={object("List Items", [
      { name: "List item 1" },
      { name: "List item 2" },
      { name: "List item 3" }
    ])}
  />
));

stories.add("With secondary button", () => (
  <SalesCard
    theme={select("Theme", themeTypes, themeTypes[0])}
    currency={select("Currency", currency, currency[0])}
    price={number("Price", 259)} // eslint-disable-line no-magic-numbers
    isPriceTermRequired={boolean("Price term required", true)}
    headingStyle={select("Heading Style", headingStyle, headingStyle[0])}
    descriptiveCard={text("Descriptive Card Text", "Benefits")}
    validity={text("License Label", "( per license / month )")}
    descriptionType={select(
      "Description Type",
      descriptionType,
      descriptionType[0]
    )}
    description={text(
      "Description",
      "Short description about the product the users is actually buying"
    )}
    isBtnRequired={boolean("Show Button", true)}
    href={text("Button href", "http://example.com/")}
    buttonText={text("Button Label", "Add to Cart")}
    isSecondaryBtnRequired={boolean("Show secondary Button", true)}
    secondaryHref={text("Secondary button href", "http://example.com/")}
    secondaryButtonText={text("Secondary button Label", "Button")}
    isLegalCopyRequired={boolean("Legal Copy Required", false)}
    legalCopyText={text("Legal Copy Text", "*Legal copy")}
    descriptionArray={object("List Items", [
      { name: "List item 1" },
      { name: "List item 2" },
      { name: "List item 3" }
    ])}
  />
));

stories.add("Descriptive", () => (
  <SalesCard
    theme={select("Theme", themeTypes, themeTypes[0])}
    currency={select("Currency", currency, currency[0])}
    price={number("Price", 259.2)} // eslint-disable-line no-magic-numbers
    isPriceTermRequired={boolean("Price term required", false)}
    headingStyle={select("Heading Style", headingStyle, headingStyle[1])}
    descriptiveCard={text("Descriptive Card Text", "Benefits")}
    validity={text("License Label", "( per license / month )")}
    descriptionType={select(
      "Description Type",
      descriptionType,
      descriptionType[1]
    )}
    description={text(
      "Description",
      "Short description about the product the users is actually buying"
    )}
    isBtnRequired={boolean("Show Button", true)}
    href={text("Button href", "http://example.com/")}
    buttonText={text("Button Label", "Add to Cart")}
    isSecondaryBtnRequired={boolean("Show secondary Button", false)}
    secondaryHref={text("Secondary button href", "http://example.com/")}
    secondaryButtonText={text("Secondary button Label", "Button")}
    isLegalCopyRequired={boolean("Legal Copy Required", false)}
    legalCopyText={text("Legal Copy Text", "*Legal copy")}
    descriptionArray={object("List Items", [
      { name: "List item 1" },
      { name: "List item 2" },
      { name: "List item 3" }
    ])}
  />
));

stories.add("Rehydrated", () => (
  <StaticRenderer>
    <SalesCard
      theme={select("Theme", themeTypes, themeTypes[0])}
      currency={select("Currency", currency, currency[2])}
      price={number("Price", 259)} // eslint-disable-line no-magic-numbers
      isPriceTermRequired={boolean("Price term required", false)}
      headingStyle={select("Heading Style", headingStyle, headingStyle[0])}
      descriptiveCard={text("Descriptive Card Text", "Benefits")}
      validity={text("License Label", "( per license / month )")}
      descriptionType={select(
        "Description Type",
        descriptionType,
        descriptionType[0]
      )}
      description={text(
        "Description",
        "Short description about the product the users is actually buying"
      )}
      isBtnRequired={boolean("Show Button", true)}
      href={text("Button href", "http://example.com/")}
      buttonText={text("Button Label", "Add to Cart")}
      isSecondaryBtnRequired={boolean("Show secondary Button", false)}
      secondaryHref={text("Secondary button href", "http://example.com/")}
      secondaryButtonText={text("Secondary button Label", "Button")}
      isLegalCopyRequired={boolean("Legal Copy Required", true)}
      legalCopyText={text("Legal Copy Text", "*Legal copy")}
      descriptionArray={object("List Items", [
        { name: "List item 1" },
        { name: "List item 2" },
        { name: "List item 3" }
      ])}
    />
  </StaticRenderer>
));
