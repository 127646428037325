import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Grid from "@emcm-ui/component-grid";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

class InputfieldCounter extends Component {
  static displayName = "InputfieldCounter";

  static propTypes = {
    /**
     * Increment/decrement factor for the inputfield-counter component.
     */
    changeValue: (props, propName) => {
      if (!Number.isInteger(props[propName]) || props[propName] < 1) {
        return new Error("Invalid");
      }
    },
    /**
     * Action-logger function for the inputfield-counter component.
     */
    onInputChange: PropTypes.func,
    /**
     * Label for the inputfield-counter component.
     */
    content: PropTypes.string,
    /**
     * Layout variants for the inputfield-counter component.
     */
    variant: PropTypes.string.isRequired,
    /**
     * Aria-label for the inputfield-counter component.
     */
    arialabel: PropTypes.string,
    /**
     * InputId for the input of inputfield-counter component.
     */
    inputId: PropTypes.string,
    /**
     * Subtitle for the inputfield-counter component.
     */
    subtitle: PropTypes.string,
    /**
     * Aria label for increase button.
     */
    increasedArialabel: PropTypes.string,
    /**
     * Aria label for decrease button.
     */
    decreasedArialabel: PropTypes.string,
    /**
     * Max length of the counter.
     */
    maxlength: PropTypes.number
  };

  constructor(props) {
    super(props);

    this.state = {
      inputValue: 0
    };
    this.singleMaxNumber = 9;
  }

  getClassName = getClassNameFactory(InputfieldCounter.displayName);

  handleClick = button => {
    const { inputValue } = this.state;
    const { changeValue, onInputChange, maxlength } = this.props;

    /**
     * if value is incremented add the incoming value to the existing value
     * if value is decremented subtract the incoming value with the existing value and  add checks so that value does not go below 0
     */

    let netValue = 0;

    if (button === "add") {
      const newValue = inputValue + parseInt(changeValue);
      const allowedLength = newValue.toString().length <= maxlength;

      netValue = allowedLength ? newValue : inputValue;
    } else {
      const value = inputValue - parseInt(changeValue);

      netValue = Math.max(value, 0);
    }

    this.setState({ ...this.state, inputValue: parseInt(netValue) });
    if (this.props.onInputChange) {
      onInputChange(parseInt(netValue));
    }
  };
  handleChange = e => {
    const { maxlength, onInputChange } = this.props;
    const value =
      e.target.value.length >= maxlength
        ? e.target.value.slice(0, maxlength)
        : e.target.value;

    /**
     * dont allow the number to be negative and backspace should remove the value
     */
    const selectedValue = value === "" ? "" : parseInt(value);

    e.target.value = "";
    if (selectedValue >= 0 || selectedValue === "") {
      this.setState({ ...this.state, inputValue: selectedValue });
      if (this.props.onInputChange) {
        onInputChange(parseInt(selectedValue));
      }
    }
  };

  handleKeyPress = e => {
    const isKeyInvalid = ["e", "E", "+", "-", "."].includes(e.key);

    if (isKeyInvalid) {
      e.preventDefault();
    } else {
      this.handleChange(e);
    }
  };

  render() {
    const {
      arialabel,
      content,
      variant,
      inputId,
      changeValue,
      subtitle,
      increasedArialabel,
      decreasedArialabel,
      maxlength
    } = this.props;
    const { inputValue } = this.state;

    let maxnumber = "";

    for (let i = 0; i < maxlength; i++) {
      maxnumber = maxnumber + this.singleMaxNumber;
    }

    const maxValue = parseInt(maxnumber);

    const updatedIncreasedArialabel = `${increasedArialabel} ${changeValue}`;
    const updatedDecreasedArialabel = `${decreasedArialabel} ${changeValue}`;
    const isDisable = inputValue < 1;

    const addedValue = inputValue + changeValue;
    const addedValueLength = addedValue.toString().length;

    const isAddDisable = addedValueLength > maxlength;

    const inputFieldSection = (
      <div className={this.getClassName({ descendantName: "counter" })}>
        <button
          aria-label={updatedDecreasedArialabel}
          aria-controls={inputId}
          aria-disabled={isDisable}
          disabled={isDisable}
          onClick={() => this.handleClick("subtract")}
          className={this.getClassName({
            descendantName: "button",
            modifiers: isDisable && "disabled"
          })}
        >
          <SVGIcon
            name={"subtract"}
            size="s"
            fill="#4d4d4d"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          />
        </button>
        <label htmlFor={inputId}>
          <input
            id={inputId}
            aria-label={arialabel}
            aria-live="polite"
            value={inputValue}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            type="number"
            min="0"
            max={maxValue}
            className={this.getClassName({
              descendantName: "input",
              className: typestack("h4")
            })}
          />
        </label>
        <button
          aria-label={updatedIncreasedArialabel}
          aria-controls={inputId}
          aria-disabled={isAddDisable}
          disabled={isAddDisable}
          onClick={() => this.handleClick("add")}
          className={this.getClassName({
            descendantName: "button",
            modifiers: isAddDisable && "disabled"
          })}
        >
          <SVGIcon
            name={"plus"}
            size="s"
            fill="#4d4d4d"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          />
        </button>
      </div>
    );

    return (
      <div
        data-rehydratable={getRehydratableName(InputfieldCounter.displayName)}
        data-arialabel={arialabel}
        data-content={content}
        data-changevalue={changeValue}
        data-variant={variant}
        data-inputid={inputId}
        data-subtitle={subtitle}
        data-increased-arialabel={increasedArialabel}
        data-decreased-arialabel={decreasedArialabel}
        data-maxlength={maxlength}
      >
        {content ? (
          <Grid variant={variant} gutterVerticalSmall={true}>
            <Grid.Item>
              <div
                className={this.getClassName({
                  descendantName: "text-container"
                })}
              >
                {subtitle && (
                  <h5
                    className={this.getClassName({
                      className: typestack("h5")
                    })}
                  >
                    {subtitle}
                  </h5>
                )}
                <p
                  className={this.getClassName({ className: typestack("p1") })}
                >
                  {content}
                </p>
              </div>
            </Grid.Item>
            <Grid.Item>
              <div
                className={this.getClassName({ descendantName: "container" })}
              >
                {inputFieldSection}
              </div>
            </Grid.Item>
          </Grid>
        ) : (
          <React.Fragment>{inputFieldSection}</React.Fragment>
        )}
      </div>
    );
  }
}

InputfieldCounter.defaultProps = {
  changeValue: 0,
  variant: "2/3,1/3",
  maxlength: 4
};
export default InputfieldCounter;
