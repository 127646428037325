import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

class GlobalNavDropdown extends Component {
  static displayName = "GlobalNavDropdown";

  static propTypes = {
    /**
     * List of sites and url in key, value pairs
     */
    options: PropTypes.array,

    /**
     * Selected site index - starts from 0 to n
     */
    selected: PropTypes.number,

    /**
     * Title - on mobile view
     */
    title: PropTypes.string
  };

  static defaultProps = {
    options: [
      {
        value: "https://www.lseg.com/",
        label: "LSEG"
      }
    ],
    selected: 0,
    title: "Our businesses:"
  };

  getClassName = getClassNameFactory(GlobalNavDropdown.displayName);

  state = {
    isOpen: false,
    selectedIndex: this.props.selected
  };

  options = this.props.options;
  title = this.props.title;

  toggleList = () => {
    this.setState(state => ({
      isOpen: !state.isOpen
    }));
  };

  setSelected = index => {
    this.setState({
      selectedIndex: index,
      isOpen: false
    });

    if (typeof window !== "undefined") {
      window.location.href = this.options[index].value;
    }
  };

  handleKeyDown = index => e => {
    switch (e.key) {
      case " ":
      case "SpaceBar":
      case "Enter":
        e.preventDefault();
        this.setSelected(index);
        break;
    }
  };

  handleListKeyDown = e => {
    switch (e.key) {
      case "Enter": {
        e.preventDefault();
        this.setState({ isOpen: !this.state.isOpen });
        break;
      }
      case "Escape": {
        e.preventDefault();
        this.setState({ isOpen: false });
        break;
      }
      case "ArrowUp": {
        e.preventDefault();
        const prev = this.state.selectedIndex - 1;
        const index = (prev + this.options.length) % this.options.length;

        this.setState({ selectedIndex: index });
        break;
      }
      case "ArrowDown": {
        e.preventDefault();
        const { options, state: { selectedIndex } } = this;
        const nextIndex = (selectedIndex + 1) % options.length;

        this.setState({ selectedIndex: nextIndex });

        break;
      }

      default:
        break;
    }
  };

  render() {
    return (
      <div className={this.getClassName()} data-rehydratable-children>
        <div className={this.getClassName({ descendantName: "container" })}>
          <span
            className={this.getClassName({
              descendantName: "title",
              className: typestack("p3")
            })}
          >
            {this.title}
          </span>
          <div
            tabIndex={0}
            role="button"
            aria-haspopup="listbox"
            aria-controls="combo-box-options"
            aria-expanded={this.state.isOpen}
            className={this.getClassName({
              descendantName: "button",
              className: typestack("navRegular"),
              modifiers: classNames({
                expanded: this.state.isOpen
              })
            })}
            onClick={this.toggleList}
            onKeyDown={this.handleListKeyDown}
          >
            {this.options[this.state.selectedIndex].label}

            <span className={this.getClassName({ descendantName: "icon" })}>
              {this.state.isOpen ? (
                <SVGIcon
                  name="caret"
                  style={{ transform: "rotate(180deg)" }}
                  size="s"
                  fill="#FFFFFF"
                />
              ) : (
                <SVGIcon name="caret" size="s" fill="#FFFFFF" />
              )}
            </span>
          </div>
        </div>

        <ul
          className={this.getClassName({
            descendantName: "listbox",
            modifiers: classNames({
              isOpen: this.state.isOpen ? "is-open" : ""
            })
          })}
          role="listbox"
          id="combo-box-options"
          tabIndex={-1}
          onKeyDown={this.handleListKeyDown}
        >
          {this.options.map((option, index) => (
            <li
              key={index}
              role="option"
              className={this.getClassName({
                descendantName: "list"
              })}
              aria-selected={this.state.selectedIndex === index}
              tabIndex={0}
              onKeyDown={this.handleKeyDown(index)}
              onClick={() => {
                this.setSelected(index);
              }}
            >
              <span
                className={this.getClassName({
                  descendantName: "link",
                  className: typestack("navRegularSmall")
                })}
                href={option.value}
                target="_top"
              >
                {option.label}
              </span>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default GlobalNavDropdown;
