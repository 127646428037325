import React from "react";
import MultiSelectDropdown from "./MultiSelectDropdown";

// eslint-disable-next-line require-await
export default async domNode => {
  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data ? JSON.parse(data) : null;
  };

  // NB you can't and shouldn't ever need to rehydrate functions
  const props = {
    isRehydrating: true,
    id: domNode.getAttribute("data-id"),
    name: domNode.getAttribute("data-name"),
    noOptionsMessage: domNode.getAttribute("data-no-options-message"),
    options: readData("options"),
    placeholder: domNode.getAttribute("data-placeholder"),
    required: readData("required"),
    errorMessage: domNode.getAttribute("data-error-message"),
    labelText: domNode.getAttribute("data-label-text"),
    helpText: domNode.getAttribute("data-help-text")
  };

  return <MultiSelectDropdown {...props} />;
};
