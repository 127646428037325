import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import GlobalNav from "./index";
import "./GlobalNav.css";
import GlobalNavREADME from "../README.md";

const stories = storiesOf("Components/GlobalNav", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(GlobalNavREADME));

const options = [
  {
    value: "https://www.lseg.com/",
    label: "LSEG"
  },
  {
    value: "https://www.refinitiv.com/",
    label: "Refinitiv"
  },
  {
    value: "https://www.ftserussell.com/",
    label: "FTSE Russell"
  },
  {
    value: "https://www.londonstockexchange.com/",
    label: "London Stock Exchange"
  },
  {
    value: "https://www.lseg.com/en/solutions/fx",
    label: "LSEG FX"
  },
  {
    value: "https://www.lch.com/",
    label: "LCH"
  }
];

const knobOptions = options.map(item => item.label);

const getSelectedIndex = selectedLink => {
  return knobOptions.indexOf(selectedLink);
};

stories
  .add("Default", () => (
    <GlobalNav
      options={options}
      selected={getSelectedIndex(
        select("Active Link", knobOptions, knobOptions[0])
      )}
      title="Our businesses:"
    />
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <GlobalNav
        options={options}
        selected={getSelectedIndex(
          select("Active Link", knobOptions, knobOptions[0])
        )}
        title="شركاتنا:"
      />
    </Language>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <GlobalNav
        options={options}
        selected={getSelectedIndex(
          select("Active Link", knobOptions, knobOptions[0])
        )}
        title="Our businesses:"
      />
    </StaticRenderer>
  ));
