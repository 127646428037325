import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";

import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

class ContentLike extends Component {
  static displayName = "ContentLike";

  static propTypes = {
    text: PropTypes.string,
    count: PropTypes.number,
    handleClick: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.getClassName = getClassNameFactory(ContentLike.displayName);
    this.state = {
      count: props.count,
      fillColor: "#808080"
    };
  }

  static getDerivedStateFromProps(props) {
    return { count: props.count };
  }

  handleMouseUpCapture = () => {
    this.setState({ fillColor: "#001EFF" });
  };

  handleMouseOut = () => {
    setTimeout(() => {
      this.setState({ fillColor: "#4D4D4D" });
    }, 5000); // eslint-disable-line no-magic-numbers
  };

  handleClick = async () => {
    this.setState({
      fillColor: "#001EFF"
    });

    const isCountIncrementEnabled =
      this.props.handleClick && (await this.props.handleClick());

    if (isCountIncrementEnabled !== false) {
      this.setState(prevState => {
        return {
          ...prevState,
          count: prevState.count + 1
        };
      });
    }
  };

  render() {
    const { text } = this.props;
    const getClassName = getClassNameFactory(ContentLike.displayName);

    return (
      <div
        className={getClassName()}
        data-rehydratable={getRehydratableName(ContentLike.displayName)}
        data-text={text}
        data-count={this.state.count}
      >
        <div
          role="button"
          tabIndex="0"
          aria-label={text}
          className={getClassName({
            descendantName: "text",
            className: typestack("p1Bold")
          })}
        >
          {text}
        </div>

        <div className={getClassName({ descendantName: "countWrap" })}>
          <div
            aria-label="Click here to like this article"
            role="button"
            className={getClassName({ descendantName: "iconType" })}
            tabIndex="0"
            onMouseUpCapture={this.handleMouseUpCapture}
            onClick={this.handleClick}
            onMouseOut={this.handleMouseOut}
            onBlur={this.handleMouseOut}
            onKeyDown={this.handleClick}
          >
            <SVGIcon
              class="icon"
              name="clap"
              size="m"
              fill={this.state.fillColor}
            />
          </div>

          <div
            tabIndex="0"
            className={getClassName({
              descendantName: "count",
              className: typestack("p1Bold")
            })}
            aria-label={`${this.state.count} likes`}
            role="button"
          >
            {this.state.count}
            <span
              aria-label={`${this.state.count} likes`}
              className={getClassName({
                descendantName: "count",
                utilities: "hiddenVisually"
              })}
            >
              {this.state.count}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

ContentLike.defaultProps = {
  text: "Found this article helpful?",
  count: 0
};

export default ContentLike;
