import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, boolean, select, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import Attribution from "@emcm-ui/component-attribution";
import Avatar from "@emcm-ui/component-avatar";
import Grid from "@emcm-ui/component-grid";
import Icon from "@emcm-ui/component-icon";
import Link from "@emcm-ui/component-link";
import Section from "@emcm-ui/component-section";
import { Language } from "@emcm-ui/component-theme";
import BlockQuote from "./index";
import "./BlockQuote.css";
import BlockQuoteREADME from "../README.md";

const changeIcon = () => {
  switch (select("Section background", [""].concat(Section.backgrounds))) {
    case "black":
      return <Icon path="./icons/ui/white/arrow-right.svg" size="s" />;
    case "blue":
      return <Icon path="./icons/ui/white/arrow-right.svg" size="s" />;
    case "grayDark":
      return <Icon path="./icons/ui/white/arrow-right.svg" size="s" />;
    default:
      return <Icon path="./icons/ui/blue/arrow-right.svg" size="s" />;
  }
};

const stories = storiesOf("Components/BlockQuote", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(BlockQuoteREADME));

stories
  .add("Default", () => (
    <Section
      background={select(
        "Section background",
        [false].concat(Section.backgrounds)
      )}
    >
      <BlockQuote
        attribution={
          boolean("Attribution", false) && (
            <Attribution
              caption="Tax Manager, Jones Lang LaSalle"
              href="https://example.com"
              name="Emily Bernard"
            />
          )
        }
        avatar={
          boolean("Avatar", false) && <Avatar alt="" src="headshot.jpg" />
        }
        cite={text("Cite", "https://not-visited.com")}
        link={
          boolean("Link", false) && (
            <Link
              href="https://not-visited.com"
              icon={changeIcon()}
              iconPosition={"right"}
              type={"icon"}
            >
              Read the case study
            </Link>
          )
        }
        small={boolean("Small", false)}
        text={text(
          "Text",
          "We easily save $30,000 to $50,000 annually by using ONESOURCE Transfer Pricing instead of outsourcing the\u00a0work."
        )}
      />
    </Section>
  ))
  .add("Grid", () => (
    <Section
      background={select(
        "Section background",
        [false].concat(Section.backgrounds)
      )}
    >
      <Grid gutterBorder variant="even-3">
        <Grid.Item>
          <BlockQuote
            attribution={
              boolean("Attribution", false) && (
                <Attribution
                  caption="Tax Manager, Jones Lang LaSalle"
                  href="https://example.com"
                  name="Emily Bernard"
                />
              )
            }
            avatar={
              boolean("Avatar", false) && <Avatar alt="" src="headshot.jpg" />
            }
            cite={text("Cite", "https://not-visited.com")}
            link={
              boolean("Link", false) && (
                <Link
                  href="https://not-visited.com"
                  icon={changeIcon()}
                  iconPosition={"right"}
                  type={"icon"}
                >
                  Read the case study
                </Link>
              )
            }
            small
            text={text(
              "Text",
              "We easily save $30,000 to $50,000 annually by using ONESOURCE Transfer Pricing instead of outsourcing the\u00a0work."
            )}
          />
        </Grid.Item>
        <Grid.Item>
          <BlockQuote
            attribution={
              boolean("Attribution", false) && (
                <Attribution
                  caption="Tax Manager, Jones Lang LaSalle"
                  href="https://example.com"
                  name="Emily Bernard"
                />
              )
            }
            avatar={
              boolean("Avatar", false) && <Avatar alt="" src="headshot.jpg" />
            }
            cite={text("Cite", "https://not-visited.com")}
            link={
              boolean("Link", false) && (
                <Link
                  href="https://not-visited.com"
                  icon={changeIcon()}
                  iconPosition={"right"}
                  type={"icon"}
                >
                  Read the case study
                </Link>
              )
            }
            small
            text={text(
              "Text",
              "We easily save $30,000 to $50,000 annually by using ONESOURCE Transfer Pricing instead of outsourcing the\u00a0work."
            )}
          />
        </Grid.Item>
        <Grid.Item>
          <BlockQuote
            attribution={
              boolean("Attribution", false) && (
                <Attribution
                  caption="Tax Manager, Jones Lang LaSalle"
                  href="https://example.com"
                  name="Emily Bernard"
                />
              )
            }
            avatar={
              boolean("Avatar", false) && <Avatar alt="" src="headshot.jpg" />
            }
            cite={text("Cite", "https://not-visited.com")}
            link={
              boolean("Link", false) && (
                <Link
                  href="https://not-visited.com"
                  icon={changeIcon()}
                  iconPosition={"right"}
                  type={"icon"}
                >
                  Read the case study
                </Link>
              )
            }
            small
            text={text(
              "Text",
              "We easily save $30,000 to $50,000 annually by using ONESOURCE Transfer Pricing instead of outsourcing the\u00a0work."
            )}
          />
        </Grid.Item>
      </Grid>
    </Section>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Section
        background={select(
          "Section background",
          [false].concat(Section.backgrounds)
        )}
      >
        <BlockQuote
          attribution={
            boolean("Attribution", false) && (
              <Attribution
                caption="مدير الضرائب ، جونز لانج لاسال"
                href="https://not-visited.com"
                name="إميلي برنار"
              />
            )
          }
          avatar={
            boolean("Avatar", false) && <Avatar alt="" src="headshot.jpg" />
          }
          link={
            boolean("Link", false) && (
              <Link
                href="https://not-visited.com"
                icon={changeIcon()}
                iconPosition={"right"}
                type={"icon"}
              >
                قراءة دراسة الحالة
              </Link>
            )
          }
          cite={text(
            "Cite",
            "https://blogs.thomsonreuters.com/answerson/vat-middle-east-region/"
          )}
          small={boolean("Small", false)}
          text={text(
            "Text",
            "على أنه المستوى المشترك الذي ينبغي أن تستهدفه كافة الشعوب والأمم حتى" +
              "يسعى كل فرد وهيئة في المجتمع، واضعين على الدوام هذا الإعلان نصب أعينهم"
          )}
        />
      </Section>
    </Language>
  ));
