import React from "react";
import { storiesOf } from "@storybook/react";
import {
  withKnobs,
  text,
  boolean,
  number,
  select
} from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import Icon from "@emcm-ui/component-icon";
import Link from "./index";
import "./Link.css";
import LinkREADME from "../README.md";

const stories = storiesOf("Components/Link", module);

const iconName = ["arrow", "external"];
const blueIcons = {
  arrow: <Icon path="./icons/ui/blue/arrow-right.svg" size="s" />,
  external: <Icon path="./icons/ui/blue/external.svg" size="s" />
};
const greyIcons = {
  arrow: <Icon path="./icons/ui/grey/arrow-right-07.svg" size="s" />,
  external: <Icon path="./icons/ui/grey/external-07.svg" size="s" />
};
const whiteIcons = {
  arrow: <Icon path="./icons/ui/white/arrow-right.svg" size="s" />,
  external: <Icon path="./icons/ui/white/external.svg" size="s" />
};

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(LinkREADME));

stories
  .add("Default", () => (
    /* eslint-disable no-magic-numbers */
    <div style={{ fontSize: number("Parent font-size", 16) }}>
      <div style={{ marginBottom: 24 }}>
        <Link
          block={boolean("block mode", false)}
          href={text("href", "https://example.com")}
          icon={
            boolean("Dark", false)
              ? whiteIcons[select("icon", iconName, "arrow")]
              : blueIcons[select("icon", iconName, "arrow")]
          }
          iconPosition={"right"}
          dark={boolean("Dark", false)}
          newWindow={boolean("new window", false)}
          type={"icon"}
        >
          Primary style
        </Link>
      </div>
      <div style={{ marginBottom: 24 }}>
        <Link
          block={boolean("block mode", false)}
          href={text("href", "https://example.com")}
          icon={
            boolean("Dark", false)
              ? whiteIcons[select("icon", iconName, "arrow")]
              : greyIcons[select("icon", iconName, "arrow")]
          }
          iconPosition={"right"}
          dark={boolean("Dark", false)}
          newWindow={boolean("new window", false)}
          type={"icon"}
        >
          Secondary style
        </Link>
      </div>
      <div style={{ marginBottom: 24 }}>
        <Link
          block={boolean("block mode", false)}
          href={text("href", "https://example.com")}
          newWindow={boolean("new window", false)}
          type={"inline"}
        >
          Inline style
        </Link>
      </div>
      <div style={{ marginBottom: 24 }}>
        <Link
          block={boolean("block mode", false)}
          href={text("href", "https://example.com")}
          newWindow={boolean("new window", false)}
          type={"inlineGrey"}
        >
          Inline grey style
        </Link>
      </div>
      <div style={{ marginBottom: 24 }}>
        <Link
          block={boolean("block mode", false)}
          href={text("href", "https://example.com")}
          icon={
            boolean("Dark", false) ? (
              <Icon path="./icons/ui/white/arrow-left.svg" size="s" />
            ) : (
              <Icon path="./icons/ui/blue/arrow-left.svg" size="s" />
            )
          }
          newWindow={boolean("new window", false)}
          iconPosition={"left"}
          dark={boolean("Dark", false)}
          type={"icon"}
        >
          With icon left
        </Link>
      </div>
    </div>
  ))
  .add("Download link", () => (
    <Link
      href="/udhr.eng.pdf"
      icon={
        boolean("Dark", false) ? (
          <Icon path="./icons/ui/white/download.svg" size="s" />
        ) : (
          <Icon path="./icons/ui/blue/download.svg" size="s" />
        )
      }
      iconPosition={"right"}
      download={text("filename", "") || true}
      dark={boolean("Dark", false)}
      type={"icon"}
    >
      Download link
    </Link>
  ))
  .add("open modal window", () => (
    <Link
      contentLocation="https://www.mocky.io/v2/5b8527613000008100729143"
      href="https://example.com"
      modalLabel="modal example"
      dark={boolean("Dark", false)}
    >
      Open modal window
    </Link>
  ))
  .add("Multi-line block", () => (
    /* eslint-disable no-magic-numbers */
    <div style={{ fontSize: 16 }}>
      <Link
        href={text("href", "https://example.com")}
        icon={
          boolean("Dark", false) ? (
            <Icon path="./icons/ui/white/arrow-right.svg" size="s" />
          ) : (
            <Icon path="./icons/ui/blue/arrow-right.svg" size="s" />
          )
        }
        iconPosition={"right"}
        dark={boolean("Dark", false)}
        type="icon"
        block
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo
      </Link>
    </div>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <div style={{ fontSize: number("parent font size", 16) }}>
        <div style={{ marginBottom: 24 }}>
          <Link
            block={boolean("block mode", false)}
            href={text("href", "https://example.com")}
            icon={
              boolean("Dark", false) ? (
                <Icon path="./icons/ui/white/arrow-right.svg" size="s" />
              ) : (
                <Icon path="./icons/ui/blue/arrow-right.svg" size="s" />
              )
            }
            newWindow={boolean("new window", false)}
            iconPosition={"right"}
            dark={boolean("Dark", false)}
            type={"icon"}
          >
            نمط الارتباط
          </Link>
        </div>
        <div style={{ marginBottom: 24 }}>
          <Link
            block={boolean("block mode", false)}
            href={text("href", "https://example.com")}
            icon={
              boolean("Dark", false) ? (
                <Icon path="./icons/ui/white/arrow-right.svg" size="s" />
              ) : (
                <Icon path="./icons/ui/blue/arrow-right.svg" size="s" />
              )
            }
            newWindow={boolean("new window", false)}
            iconPosition={"right"}
            dark={boolean("Dark", false)}
            type={"icon"}
          >
            نمط الارتباط
          </Link>
        </div>
        <div style={{ marginBottom: 24 }}>
          <Link
            block={boolean("block mode", false)}
            href={text("href", "https://example.com")}
            newWindow={boolean("new window", false)}
            type={"inline"}
          >
            نمط الارتباط
          </Link>
        </div>
        <div style={{ marginBottom: 24 }}>
          <Link
            block={boolean("block mode", false)}
            href={text("href", "https://example.com")}
            icon={
              boolean("Dark", false) ? (
                <Icon path="./icons/ui/white/arrow-right.svg" size="s" />
              ) : (
                <Icon path="./icons/ui/blue/arrow-right.svg" size="s" />
              )
            }
            newWindow={boolean("new window", false)}
            iconPosition={"left"}
            dark={boolean("Dark", false)}
            type={"icon"}
          >
            مضمنة مع أيقونة اليسار
          </Link>
        </div>
      </div>
    </Language>
  ));
