import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, boolean, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import Section from "@emcm-ui/component-section";
import Table from "@emcm-ui/component-table";
import RichText from "./index";
import RichTextGrid from "./RichTextGrid";
import "./RichText.css";
import "./RichTextGrid.css";
import RichTextREADME from "../README.md";
import Grid from "@emcm-ui/components/Grid";
import Link from "@emcm-ui/component-link";
import Icon from "@emcm-ui/component-icon";

const stories = storiesOf("Components/RichText", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(RichTextREADME));

stories
  .add("Default", () => (
    <Section>
      <Grid variant="10/12,2/12">
        <Grid.Item>
          <RichText
            alignCenter={boolean("Align Center", false)}
            compact={boolean("Compact", false)}
            large={boolean("Large", false)}
          >
            <h1>Rich text</h1>
            <h2>Inline link, Bold text</h2>
            <p>
              Lorem <strong>ipsum</strong> sit <em>amet</em>, consectetur{" "}
              <a href="https://not-visited">adipisicing</a> elit, sed{" "}
              <a href="https://example.com">eiusmod</a> tempor incididunt.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <h4>Heading 4</h4>
            <h5>Heading 5</h5>
            <h6>Heading 6</h6>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <h2>Image</h2>
            <p>
              <img
                alt=""
                src="https://placehold.it/1280x720&amp;text=16%20x%209"
              />
            </p>
            <h2>Blockquote</h2>
            <blockquote>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </blockquote>
            <h2>Lists</h2>
            <h3>Unordered list (bullets)</h3>
            <ul>
              <li>This is a list item in an unordered list</li>
              <li>
                An unordered list is a list in which the sequence of items is
                not important. Sometimes, an unordered list is a bulleted list.
                And this is a long list item in an unordered list that can wrap
                onto a new line.
              </li>
              <li>
                Lists can be nested inside of each other
                <ul>
                  <li>This is a nested list item</li>
                  <li>This is another nested list item in an unordered list</li>
                </ul>
              </li>
              <li>This is the last list item</li>
            </ul>
            <h3>Unordered list (ticks)</h3>
            <ul className="tr-List--ticks">
              <li>This is a list item in an unordered list</li>
              <li>
                An unordered list is a list in which the sequence of items is
                not important. Sometimes, an unordered list is a bulleted list.
                And this is a long list item in an unordered list that can wrap
                onto a new line.
              </li>
              <li>
                Lists can be nested inside of each other
                <ul>
                  <li>This is a nested list item</li>
                  <li>This is another nested list item in an unordered list</li>
                </ul>
              </li>
              <li>This is the last list item</li>
            </ul>
            <h3>Ordered list</h3>
            <ol>
              <li>This is a list item in an ordered list</li>
              <li>
                An ordered list is a list in which the sequence of items is
                important. An ordered list does not necessarily contain sequence
                characters.
              </li>
              <li>
                Lists can be nested inside of each other
                <ol>
                  <li>
                    This is a nested list item
                    <ol>
                      <li>
                        This is another nested list item in an ordered list
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>This is the last list item</li>
            </ol>
            <h3>Nesting an unordered list in an ordered list</h3>
            <ol>
              <li>An ordered list item</li>
              <li>
                An ordered list is a list in which the sequence of items is
                important. An ordered list does not necessarily contain sequence
                characters.
              </li>
              <li>
                Lists can be nested inside of each other
                <ul>
                  <li>An unordered list (bullets) item</li>
                  <li>An unordered list (bullets) item</li>
                </ul>
              </li>
              <li>
                Lists can be nested inside of each other
                <ul className="tr-List--ticks">
                  <li>An unordered list (ticks) item</li>
                  <li>An unordered list (ticks) item</li>
                </ul>
              </li>
              <li>The final, and fourth, ordered list item</li>
            </ol>
            <h3>Nesting an ordered list in an unordered list (bullets)</h3>
            <ul>
              <li>This is unordered</li>
              <li>This is unordered</li>
              <li>
                This is unordered
                <ol>
                  <li>This is ordered</li>
                  <li>This is ordered</li>
                </ol>
              </li>
              <li>This is unordered</li>
              <li>This is unordered</li>
            </ul>
            <h3>Nesting an ordered list in an unordered list (ticks)</h3>
            <ul className="tr-List--ticks">
              <li>This is unordered</li>
              <li>This is unordered</li>
              <li>
                This is unordered
                <ol>
                  <li>This is ordered</li>
                  <li>This is ordered</li>
                </ol>
              </li>
              <li>This is unordered</li>
              <li>This is unordered</li>
            </ul>
          </RichText>
        </Grid.Item>
      </Grid>
    </Section>
  ))
  .add("Small (Disclaimers)", () => (
    <Section>
      <Grid variant="10/12,2/12">
        <Grid.Item>
          <RichText
            compact={boolean("Compact", false)}
            Large={boolean("Large", true)}
            small={boolean("Small", true)}
          >
            <h1>Rich text</h1>
            <h2>Small (Disclaimers)</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </RichText>
        </Grid.Item>
        <Grid.Item />
      </Grid>
    </Section>
  ))
  .add("RichText Grid", () => (
    <Section>
      <RichTextGrid
        label="Label"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
      />
      <RichTextGrid
        label="Label"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
      />
      <RichTextGrid
        label="Label"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
      />
      <RichTextGrid
        label="Label"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet"
      />
      <RichTextGrid
        label="Label"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet"
        link={
          <Link
            block={false}
            href={"https://example.com"}
            icon={<Icon path="./icons/ui/blue/arrow-right.svg" size="s" />}
            iconPosition={"right"}
            newWindow={true}
            type={"icon"}
          >
            {" "}
            Primary style
          </Link>
        }
      />
      <RichTextGrid
        item={[
          {
            label: "Label",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          },
          { label: "Label1", description: "Lorem ipsum dolor sit amet" },
          {
            label: "Label2",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            link: (
              <Link
                block={false}
                href={"https://example.com"}
                icon={<Icon path="./icons/ui/blue/arrow-right.svg" size="s" />}
                iconPosition={"right"}
                newWindow={true}
                type={"icon"}
              >
                {" "}
                Primary style
              </Link>
            )
          }
        ]}
      />
    </Section>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Section>
        <Grid variant="10/12,2/12">
          <Grid.Item>
            <RichText
              compact={boolean("Compact", false)}
              large={boolean("Large", false)}
            >
              <h1>فإن الجمعية العامة</h1>
              <p>
                على أنه المستوى المشترك الذي ينبغي أن تستهدفه كافة الشعوب والأمم
                حتى يسعى كل فرد وهيئة في المجتمع، واضعين على الدوام هذا الإعلان
                نصب أعينهم، إلى توطيد احترام هذه الحقوق والحريات عن طريق التعليم
                والتربية واتخاذ إجراءات مطردة، قومية وعالمية، لضمان الإعتراف بها
                ومراعاتها بصورة عالمية فعالة بين الدول الأعضاء ذاتها وشعوب
                البقاع الخاضعة لسلطانها.
              </p>
            </RichText>
          </Grid.Item>
          <Grid.Item />
        </Grid>
      </Section>
    </Language>
  ))
  .add("Table in RichText", () => (
    <Section>
      <Grid variant="10/12,2/12">
        <Grid.Item>
          <RichText
            alignCenter={boolean("Align Center", false)}
            compact={boolean("Compact", false)}
            large={boolean("Large", false)}
          >
            <Table size={select("size", ["default", "compact"], "default")}>
              <table>
                <thead>
                  <tr>
                    <th scope="col">Title 1</th>
                    <th scope="col">Title 2</th>
                    <th scope="col">Title 3</th>
                    <th scope="col">Title 4</th>
                    <th scope="col">Title with More Characters</th>
                    <th scope="col">Last Title</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Row Title</th>
                    <td>Value name</td>
                    <td>Value name</td>
                    <td>Value name</td>
                    <td>Value name</td>
                    <td>Value name</td>
                  </tr>
                </tbody>
              </table>
            </Table>
          </RichText>
        </Grid.Item>
      </Grid>
    </Section>
  ));
