import fetchMock from "fetch-mock";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { storiesOf } from "@storybook/react";
import { action } from "@storybook/addon-actions";
import { boolean, select, withKnobs } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import Block from "@emcm-ui/component-block";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import getAnalytics from "@emcm-ui/utility-analytics";
import ProgressTracker from "./index";
import "./ProgressTracker.css";
import ProgressTrackerREADME from "../README.md";

const stories = storiesOf("Components/ProgressTracker", module);

const analytics = getAnalytics("storybook", action);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(ProgressTrackerREADME));

const delay = duration => new Promise(resolve => setTimeout(resolve, duration));
const halfSecond = 500;

const tabTitleType = ProgressTracker.tabTitleType;

stories
  .add("Default", () => {
    const tab2Location = "tab2.html";
    const tab4Location = "tab4.html";
    const tab2Response = ReactDOMServer.renderToStaticMarkup(
      <Block text="Panel #2 AJAXed content" color="green" height="32em" />
    );
    const tab4Response = ReactDOMServer.renderToStaticMarkup(
      <Block text="Panel #4 AJAXed content" color="green" height="32em" />
    );

    fetchMock.restore();
    fetchMock.get(tab2Location, async () => {
      await delay(halfSecond);

      return tab2Response;
    });
    fetchMock.get(tab4Location, async () => {
      await delay(halfSecond);

      return tab4Response;
    });

    return (
      <ProgressTracker
        analytics={analytics}
        disableVisited={boolean("Disable completed steps", false)}
        tabTitleType={select("Tab item style", tabTitleType, tabTitleType[0])}
      >
        <ProgressTracker.Panel
          id="tab1"
          tabTitle="tab1"
          description="Step 1 description"
        >
          <Block text="Panel 1" color="blue" height="32em" />
        </ProgressTracker.Panel>

        <ProgressTracker.Panel
          id="tab2"
          tabTitle="tab2"
          description="Step 2 description"
          contentLocation={tab2Location}
        />

        <ProgressTracker.Panel
          id="tab3"
          tabTitle="tab3"
          description="Step 3 description"
        >
          <Block text="Panel 3" color="purple" height="32em" />
        </ProgressTracker.Panel>

        <ProgressTracker.Panel
          id="tab4"
          tabTitle="tab4"
          description="Step 4 description"
          contentLocation={tab4Location}
        />
        <ProgressTracker.Panel
          id="tab5"
          tabTitle="tab5"
          description="Step 5 description"
        >
          <Block text="Panel 5" color="blue" height="32em" />
        </ProgressTracker.Panel>
        <ProgressTracker.Panel
          id="tab6"
          tabTitle="tab6"
          description="Step 6 description"
        >
          <Block text="Panel 6" color="blue" height="32em" />
        </ProgressTracker.Panel>
      </ProgressTracker>
    );
  })

  .add("Rehydrated", () => {
    const tab2Location = "tab2.html";
    const tab2Response = ReactDOMServer.renderToStaticMarkup(
      <Block text="Panel #2 AJAXed content" color="green" height="32em" />
    );

    fetchMock.restore();
    fetchMock.get(tab2Location, async () => {
      await delay(halfSecond);

      return tab2Response;
    });

    return (
      <StaticRenderer>
        <ProgressTracker
          disableVisited={boolean("Disable completed steps", false)}
          tabTitleType={select("Tab item style", tabTitleType, tabTitleType[1])}
        >
          <ProgressTracker.Panel id="tab1" tabTitle="tab1" description="Step 1">
            <Block text="Panel 1" color="blue" height="32em" />
          </ProgressTracker.Panel>

          <ProgressTracker.Panel
            id="tab2"
            tabTitle="tab2"
            description="Step 2"
            contentLocation={tab2Location}
          />

          <ProgressTracker.Panel id="tab3" tabTitle="tab3" description="Step 3">
            <Block text="Panel 3" color="purple" height="32em" />
          </ProgressTracker.Panel>

          <ProgressTracker.Panel id="tab4" tabTitle="tab4" description="Step 4">
            <Block text="Panel 4" color="blue" height="32em" />
          </ProgressTracker.Panel>
          <ProgressTracker.Panel id="tab5" tabTitle="tab5" description="Step 5">
            <Block text="Panel 5" color="purple" height="32em" />
          </ProgressTracker.Panel>
        </ProgressTracker>
      </StaticRenderer>
    );
  });
