import React from "react";
import FloatingBadge from "./FloatingBadge";

export default async (domNode, rehydrateChildren) => {
  const childrenNode = domNode.querySelector("[data-rehydratable-children]");
  const reactifiedChildren = await rehydrateChildren(childrenNode);
  const readSourceMetadata = domNode.getAttribute("data-source-metadata");
  const dataSourceMetadata = readSourceMetadata
    ? JSON.parse(readSourceMetadata)
    : null;

  const props = {
    isRehydrating: true,
    icon: domNode.getAttribute("data-icon"),
    tooltipText: domNode.getAttribute("data-tooltiptext"),
    landpageUrl: domNode.getAttribute("data-landpage"),
    sourceSystem: domNode.getAttribute("data-source"),
    productName: domNode.getAttribute("data-product"),
    type: domNode.getAttribute("data-type"),
    clientID: domNode.getAttribute("data-clientid"),
    headerText: domNode.getAttribute("data-headertext"),
    embedUrl: domNode.getAttribute("data-embedscript"),
    sourceMetadata: dataSourceMetadata
  };

  return <FloatingBadge {...props}>{reactifiedChildren}</FloatingBadge>;
};
