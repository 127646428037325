import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import FeaturedCards from "../FeaturedCards/FeaturedCards";
import Collage from "../Collage/Collage";
import SearchPagination from "../Pagination/Pagination";
import BillBoard from "../BillBoard/BillBoard";
import Section from "@emcm-ui/component-section";
import {
  CARD_PER_SECTION,
  FEATURED_SECTION_CARDS_COUNT
} from "../../utilities/constants";
import Alignment from "@emcm-ui/component-alignment";
import Loader from "@emcm-ui/component-loader";

const SearchResultCards = props => {
  const { featured, search, page, resultsLoading, tagsLoading } = props;
  const { searchResults, hasSearchTerm } = search;
  const { featuredCards, featuredContent } = featured;
  const { currentPage } = page;

  const getClassName = getClassNameFactory("SearchResultsMixture");

  const hasFeaturedContent = featuredContent !== null;
  const isCurrentPageFirst = currentPage === null || currentPage === 1;
  const hasFeaturedCards = featuredCards && featuredCards.length > 0;
  const showSecondaryFeatureCard =
    hasFeaturedCards && featuredCards.length > FEATURED_SECTION_CARDS_COUNT;
  const showFeatureCard =
    hasFeaturedContent &&
    !hasSearchTerm &&
    isCurrentPageFirst &&
    hasFeaturedCards;
  let cardsFirstSection = searchResults;

  if (showSecondaryFeatureCard) {
    cardsFirstSection = searchResults.slice(0, CARD_PER_SECTION);
  }
  const cardsLastSection =
    searchResults && searchResults.slice(CARD_PER_SECTION);
  const showResults = !(resultsLoading || tagsLoading);

  const getSearchCollage = cards => {
    return <Collage cards={cards} {...props} />;
  };

  return (
    <div className={getClassName({ descendantName: "SearchResultCards" })}>
      {resultsLoading && (
        <Alignment horizontal="center" vertical="center">
          <Loader />
        </Alignment>
      )}
      {showResults && (
        <React.Fragment>
          {showFeatureCard && (
            <React.Fragment>
              <FeaturedCards {...props} />
              <Section>{getSearchCollage(cardsFirstSection)}</Section>
              <BillBoard {...props} />
              {showSecondaryFeatureCard && (
                <Section>{getSearchCollage(cardsLastSection)}</Section>
              )}
            </React.Fragment>
          )}
          {!showFeatureCard && (
            <div
              className={getClassName({
                descendantName: "searchResultContainer"
              })}
            >
              {getSearchCollage(searchResults)}
            </div>
          )}
          <SearchPagination {...props} />
        </React.Fragment>
      )}
    </div>
  );
};

SearchResultCards.displayName = "SearchResults.resultsCard";

SearchResultCards.propTypes = {
  /**
   * featured information
   */
  featured: PropTypes.object,
  /**
   * page information
   */
  page: PropTypes.object,
  /**
   * search information
   */
  search: PropTypes.object,
  /**
   * boolean value to show tags loading
   */
  tagsLoading: PropTypes.bool,
  /**
   * boolean value to show results loading
   */
  resultsLoading: PropTypes.bool
};

export default SearchResultCards;
