/**
 * @define Playlist
 * Playlist component for displaying list of videos.
 *
 */

// Vendor
import PropTypes from "prop-types";
import React from "react";

// emcm-ui
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

// Local
import PlaylistItem from "./PlaylistItem";
import { durationFormatter, generateUrl, findIndexByList } from "./utilities";

class Playlist extends React.Component {
  constructor(props) {
    super(props);

    this.playlist = {
      lineItems: props.lineItems,
      activeItemId: props.activeItemId,
      name: props.name,
      url: props.url,
      label: props.label,
      states: props.states
    };
  }

  getStateLabel = (stateLabel, stateItem) => {
    return stateItem && stateItem.state
      ? stateLabel[stateItem.state.toLowerCase()]
      : "";
  };

  getDescription = (duration, label) => {
    let description = `${durationFormatter(duration)}`;

    if (label) {
      description = `${durationFormatter(duration)} - ${label}`;
    }

    return description;
  };

  getStateByClassName = videoState => {
    let selectedClassName = "";

    if (videoState && videoState.seek) {
      selectedClassName = videoState.state.toString().toLowerCase();
    }

    return selectedClassName;
  };

  getPlayUrl = (isActive, item, id) => {
    return isActive
      ? `javascript: void(0);`
      : generateUrl(item.url, item.name, id);
  };

  render() {
    const getClassName = getClassNameFactory(Playlist.displayName);

    return (
      <div
        className={getClassName()}
        data-line-items={JSON.stringify(this.playlist.lineItems)}
        data-url={this.playlist.url}
        data-playlist-label={this.playlist.label.playlist}
        data-playing-label={this.playlist.label.playing}
        data-paused-label={this.playlist.label.paused}
        data-viewed-label={this.playlist.label.viewed}
        data-states={JSON.stringify(this.playlist.states)}
        data-active-item-id={this.playlist.activeItemId}
        data-rehydratable={getRehydratableName(Playlist.displayName)}
      >
        <div
          className={getClassName({
            descendantName: "counter",
            className: typestack("subline1")
          })}
        >
          {this.playlist.label.playlist} ({this.playlist.lineItems.length})
        </div>
        {this.playlist.lineItems.map((item, key) => {
          const stateItemIndex = findIndexByList(
            this.playlist.states,
            "id",
            item.id
          );
          const stateItem = this.playlist.states[stateItemIndex];
          const isActive = this.playlist.activeItemId === item.id;
          const state = this.getStateLabel(this.playlist.label, stateItem);
          const description = this.getDescription(item.duration, state);
          const playUrl = this.getPlayUrl(isActive, this.playlist, item.id);

          return (
            <Playlist.Item
              {...item}
              url={playUrl}
              description={description}
              isActive={isActive}
              state={state}
              label={this.playlist.label}
              callback={this.props.callback}
              key={key}
            />
          );
        })}
      </div>
    );
  }
}

Playlist.displayName = "Playlist";
Playlist.Item = PlaylistItem;
Playlist.propTypes = {
  /**
   * Url
   */
  label: PropTypes.object,

  /**
   * Name of video id key parameter
   */
  name: PropTypes.string,

  /**
   * Playlist line items array of objects.
   */
  lineItems: PropTypes.array.isRequired,

  /**
   * States
   */
  states: PropTypes.array,

  /**
   * Active item id
   */
  activeItemId: PropTypes.string,

  /**
   * Callback
   */
  callback: PropTypes.object,

  /**
   * Url
   */
  url: PropTypes.string.isRequired
};

Playlist.defaultProps = {
  name: "videoId",
  lineItems: [],
  url: "",
  activeItemId: null,
  label: {
    playlist: "PLAYLIST",
    playing: "PLAYING",
    paused: "PAUSED",
    viewed: "VIEWED"
  },
  states: []
};

export default Playlist;
