import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, select, boolean } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import Section from "@emcm-ui/component-section";
import Grid from "@emcm-ui/component-grid";
import RichText from "@emcm-ui/component-rich-text";
import Table from "./index";
import "./Table.css";
import TableREADME from "../README.md";

const stories = storiesOf("Components/Table", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(TableREADME));

stories
  .add("Default", () => (
    <Section>
      <Table size={select("size", ["default", "compact"], "default")}>
        <table>
          <caption>Caption for table</caption>
          <thead>
            <tr>
              <th scope="col">Name of Entity</th>
              <th scope="col">Registered Address</th>
              <th scope="col">Registered Number</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Thomson Reuters (Markets) SA., Belgium Branch</td>
              <td>Marnixlaan 17 1000, Brussels Belgium</td>
              <td>0473.542.617</td>
            </tr>
            <tr>
              <td>eXimius NV</td>
              <td>Zandvoortstraat 45C 2800, Mechelen Belgium</td>
              <td>BTW BE 0881.564.110 RPR Mechelen</td>
            </tr>
            <tr>
              <td>Aktor Knowledge Technology N.V.</td>
              <td>Sint Pietersvliet 7 Antwerp, 2000 Belgium</td>
              <td>HRA 332462</td>
            </tr>
            <tr>
              <td>Compu-Mark N.V.</td>
              <td>St Pietersvliet 7 B-2000 Antwerpen Belgium</td>
              <td>271 928</td>
            </tr>
          </tbody>
        </table>
      </Table>
    </Section>
  ))
  .add("Border example", () => (
    <Section>
      <Table size={select("size", ["default", "compact"], "default")}>
        <table>
          <caption>Caption for table</caption>
          <thead>
            <tr>
              <th scope="col">Name of Entity</th>
              <th scope="col">Registered Address</th>
              <th scope="col">Registered Number</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Thomson Reuters (Markets) SA., Belgium Branch</td>
              <td>Marnixlaan 17 1000, Brussels Belgium</td>
              <td>0473.542.617</td>
            </tr>
            <tr>
              <td border="medium">eXimius NV</td>
              <td border="medium">
                Zandvoortstraat 45C 2800, Mechelen Belgium
              </td>
              <td border="medium">BTW BE 0881.564.110 RPR Mechelen</td>
            </tr>
            <tr>
              <td border="dark">Aktor Knowledge Technology N.V.</td>
              <td border="dark">Sint Pietersvliet 7 Antwerp, 2000 Belgium</td>
              <td border="dark">HRA 332462</td>
            </tr>
          </tbody>
        </table>
      </Table>
    </Section>
  ))
  .add("Earnings example", () => (
    <Section>
      <Table size={select("size", ["default", "compact"], "default")}>
        <table>
          <caption>Caption for table</caption>
          <thead>
            <tr>
              <th scope="col">
                IFRS Financial Measures <sup>1</sup>
              </th>
              <th scope="col">2017</th>
              <th scope="col">2016</th>
              <th scope="col">Change</th>
              <th scope="col">Change at Constant Currency</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Revenues</td>
              <td>$2,782</td>
              <td>$2,769</td>
              <td>0%</td>
              <td />
            </tr>
            <tr>
              <td>Operating profit</td>
              <td>$399</td>
              <td>$401</td>
              <td>0%</td>
              <td />
            </tr>
            <tr>
              <td>Diluted EPS (includes discontinued operations)</td>
              <td>$0.27</td>
              <td>$0.45</td>
              <td>-40%</td>
              <td />
            </tr>
            <tr>
              <td>
                Cash flow from operations (includes discontinued operations)
              </td>
              <td>$834</td>
              <td>$770</td>
              <td>8%</td>
              <td />
            </tr>
            <tr>
              <td>
                Non-IFRS Financial Measures <sup>1</sup>
              </td>
              <td />
              <td />
              <td />
              <td />
            </tr>
            <tr>
              <td>Revenues</td>
              <td>$2,782</td>
              <td>$2,769</td>
              <td>0%</td>
              <td>2%</td>
            </tr>
            <tr>
              <td>Adjusted EBITDA</td>
              <td>$838</td>
              <td>$757</td>
              <td>11%</td>
              <td>11%</td>
            </tr>
            <tr>
              <td>
                <i>Adjusted EBITDA margin</i>
              </td>
              <td>
                <i>30.1%</i>
              </td>
              <td>
                <i>27.3%</i>
              </td>
              <td>
                <i>280bp</i>
              </td>
              <td>
                <i>230bp</i>
              </td>
            </tr>
            <tr>
              <td>Adjusted EPS</td>
              <td>$0.60</td>
              <td>$0.47</td>
              <td>28%</td>
              <td>28%</td>
            </tr>
            <tr>
              <td>Free cash flow (includes discontinued operations)</td>
              <td>$580</td>
              <td>$525</td>
              <td>10%</td>
              <td />
            </tr>
          </tbody>
        </table>
      </Table>
    </Section>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Section>
        <Table size={select("size", ["default", "compact"], "default")}>
          <table>
            <caption>فإن الجمعية العامة</caption>
            <thead>
              <tr>
                <th scope="col">فإن الجمعية العامة</th>
                <th scope="col">فإن الجمعية العامة</th>
                <th scope="col">فإن الجمعية العامة</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>فإن الجمعية العامة</td>
                <td>فإن الجمعية العامة</td>
                <td>فإن الجمعية العامة</td>
              </tr>
              <tr>
                <td>فإن الجمعية العامة</td>
                <td>فإن الجمعية العامة</td>
                <td>فإن الجمعية العامة</td>
              </tr>
              <tr>
                <td>فإن الجمعية العامة</td>
                <td>فإن الجمعية العامة</td>
                <td>فإن الجمعية العامة</td>
              </tr>
              <tr>
                <td>فإن الجمعية العامة</td>
                <td>فإن الجمعية العامة</td>
                <td>فإن الجمعية العامة</td>
              </tr>
            </tbody>
          </table>
          <table>
            <caption>فإن الجمعية العامة</caption>
            <thead>
              <tr>
                <th scope="col">
                  فإن الجمعية العامة<sup>1</sup>
                </th>
                <th scope="col">2017</th>
                <th scope="col">2016</th>
                <th scope="col">Change</th>
                <th scope="col">فإن الجمعية العامة</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>فإنة</td>
                <td>$2,782</td>
                <td>$2,769</td>
                <td>0%</td>
                <td />
              </tr>
              <tr>
                <td>فإن الجمعية </td>
                <td>$399</td>
                <td>$401</td>
                <td>0%</td>
                <td />
              </tr>
              <tr>
                <td>فإن الجمعية العامة</td>
                <td>$0.27</td>
                <td>$0.45</td>
                <td>-40%</td>
                <td />
              </tr>
              <tr>
                <td>فإن الجمعية العامة</td>
                <td>$834</td>
                <td>$770</td>
                <td>8%</td>
                <td />
              </tr>
              <tr>
                <td>
                  فإن الجمعية العامة <sup>1</sup>
                </td>
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr>
                <td>فإن الجمعية </td>
                <td>$2,782</td>
                <td>$2,769</td>
                <td>0%</td>
                <td>2%</td>
              </tr>
              <tr>
                <td>فإن الجمعية </td>
                <td>$838</td>
                <td>$757</td>
                <td>11%</td>
                <td>11%</td>
              </tr>
              <tr>
                <td>
                  <i>فإن الجمعية العامة</i>
                </td>
                <td>
                  <i>30.1%</i>
                </td>
                <td>
                  <i>27.3%</i>
                </td>
                <td>
                  <i>280bp</i>
                </td>
                <td>
                  <i>230bp</i>
                </td>
              </tr>
              <tr>
                <td>فإن الجمعية العامة</td>
                <td>$0.60</td>
                <td>$0.47</td>
                <td>28%</td>
                <td>28%</td>
              </tr>
              <tr>
                <td>فإن الجمعية العامة</td>
                <td>$580</td>
                <td>$525</td>
                <td>10%</td>
                <td />
              </tr>
            </tbody>
          </table>
        </Table>
      </Section>
    </Language>
  ))
  .add("Overflow Table", () => (
    <Section>
      <Grid variant="2/3,1/3">
        <Grid.Item>
          <RichText
            alignCenter={boolean("Align Center", false)}
            compact={boolean("Compact", false)}
            large={boolean("Large", false)}
          >
            <Table size={select("size", ["default", "compact"], "default")}>
              <table>
                <thead>
                  <tr>
                    <th scope="col">Title 1</th>
                    <th scope="col">Title 2</th>
                    <th scope="col">Title 3</th>
                    <th scope="col">Title 4</th>
                    <th scope="col">Title 5</th>
                    <th scope="col">Title 6</th>
                    <th scope="col">Title 7</th>
                    <th scope="col">Title 8</th>
                    <th scope="col">Title 9</th>
                    <th scope="col">Title 10</th>
                    <th scope="col">Title 11</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Row Title</th>
                    <td>Value name 1</td>
                    <td>Value name 2</td>
                    <td>Value name 3</td>
                    <td>Value name 4</td>
                    <td>Value name 5</td>
                    <td>Value name 6</td>
                    <td>Value name 7</td>
                    <td>Value name 8</td>
                    <td>Value name 9</td>
                    <td>Value name 10</td>
                  </tr>
                </tbody>
              </table>
            </Table>
          </RichText>
        </Grid.Item>
        <Grid.Item>
          <p>
            There have been various green bond guidelines in Chinaâ€™s bond
            regulatory landscape (Exhibit 3). The key development in recent
            years, however, is the convergence of these guidelines and
            taxonomies, which have been seen in two areas. Firstly, the
            standardisation of green bond issuing and reporting rules has been
            reflected by the release of China Green Bond Principles (China GBP)
            As a set of voluntary rules that complement current regulations, the
            China GBP will help bridge the gaps between the widely accepted ICMA
            Green Bond Principles and various Chinese green bond guidelines.
            Secondly, there have been harmonisations of the eligible green
            project definitions with the update of the China Green Bond
            Catalogue and the release of the China-EU Common Ground Taxonomy,
            signaling a positive step towards further harmonisation both
            domestically and internationally. In addition, while many Chinese
            green bonds address wider environmental issues such as air pollution
            reduction, the guidelines on issuing â€˜Carbon Neutralityâ€™ bonds
            rolled out by both NAFMII and Shanghai Stock Exchange reflect that
            Climate Change mitigation has become a key priority contributing to
            Chinaâ€™s Net Zero commitment.
          </p>
        </Grid.Item>
      </Grid>
    </Section>
  ));
