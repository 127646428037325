import React from "react";

import Attribution from "@emcm-ui/component-attribution";
import Block from "@emcm-ui/component-block";
import {
  ArticleCardFooter,
  LinkCardFooter,
  PairedCard,
  TextCard
} from "@emcm-ui/component-card";
import Callout from "@emcm-ui/component-callout";
import Eyebrow from "@emcm-ui/component-eyebrow";
import Grid from "@emcm-ui/component-grid";
import Header from "@emcm-ui/component-header";
import Heading from "@emcm-ui/component-heading";
import Image from "@emcm-ui/component-image";
import Link from "@emcm-ui/component-link";
import ListOfLinks from "@emcm-ui/component-list-of-links";
import Longform from "@emcm-ui/component-longform";
import MarkupContainer from "@emcm-ui/component-markup-container";
import Poster from "@emcm-ui/component-poster";
import SocialDropdown from "@emcm-ui/component-social-dropdown";
import SocialShare from "@emcm-ui/component-social-share";
import { Section, SectionBridge } from "@emcm-ui/component-section";
import SemanticArticle from "@emcm-ui/component-semantic-article";
import SemanticAside from "@emcm-ui/component-semantic-aside";
import SemanticMain from "@emcm-ui/component-semantic-main";
import Icon from "@emcm-ui/component-icon";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";
import VideoPlayer from "@emcm-ui/component-video-player";
import SampleSchemaOrganization from "../../fragments/schema-organization";
import SampleFooter from "../../fragments/footer";
import SampleHeader from "../../fragments/header";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import { getSocialIcon } from "../../sampleAssets/icons";

import blockchainCardOne288 from "../../sampleAssets/blockchain/blockchain-card-one-288.jpg";
import blockchainCardOne368 from "../../sampleAssets/blockchain/blockchain-card-one-368.jpg";
import blockchainCardOne576 from "../../sampleAssets/blockchain/blockchain-card-one-576.jpg";
import blockchainCardOne768 from "../../sampleAssets/blockchain/blockchain-card-one-768.jpg";
import blockchainCardOne1168 from "../../sampleAssets/blockchain/blockchain-card-one-1168.jpg";
import blockchainCardOne1536 from "../../sampleAssets/blockchain/blockchain-card-one-1536.jpg";

import blockchainCardTwo288 from "../../sampleAssets/blockchain/blockchain-card-two-288.jpg";
import blockchainCardTwo368 from "../../sampleAssets/blockchain/blockchain-card-two-368.jpg";
import blockchainCardTwo576 from "../../sampleAssets/blockchain/blockchain-card-two-576.jpg";
import blockchainCardTwo768 from "../../sampleAssets/blockchain/blockchain-card-two-768.jpg";
import blockchainCardTwo1168 from "../../sampleAssets/blockchain/blockchain-card-two-1168.jpg";
import blockchainCardTwo1536 from "../../sampleAssets/blockchain/blockchain-card-two-1536.jpg";

import blockchainCardThree288 from "../../sampleAssets/blockchain/blockchain-card-three-288.jpg";
import blockchainCardThree368 from "../../sampleAssets/blockchain/blockchain-card-three-368.jpg";
import blockchainCardThree576 from "../../sampleAssets/blockchain/blockchain-card-three-576.jpg";
import blockchainCardThree768 from "../../sampleAssets/blockchain/blockchain-card-three-768.jpg";
import blockchainCardThree1168 from "../../sampleAssets/blockchain/blockchain-card-three-1168.jpg";
import blockchainCardThree1536 from "../../sampleAssets/blockchain/blockchain-card-three-1536.jpg";

import blockchainChapterTwo288 from "../../sampleAssets/blockchain/blockchain-chapter-two-288.jpg";
import blockchainChapterTwo368 from "../../sampleAssets/blockchain/blockchain-chapter-two-368.jpg";
import blockchainChapterTwo576 from "../../sampleAssets/blockchain/blockchain-chapter-two-576.jpg";
import blockchainChapterTwo768 from "../../sampleAssets/blockchain/blockchain-chapter-two-768.jpg";
import blockchainChapterTwo1168 from "../../sampleAssets/blockchain/blockchain-chapter-two-1168.jpg";
import blockchainChapterTwo1536 from "../../sampleAssets/blockchain/blockchain-chapter-two-1536.jpg";
import blockchainChapterTwo2336 from "../../sampleAssets/blockchain/blockchain-chapter-two-2336.jpg";
import blockchainChapterTwo3504 from "../../sampleAssets/blockchain/blockchain-chapter-two-3504.jpg";

import blockchainChapterThree288 from "../../sampleAssets/blockchain/blockchain-chapter-three-288.jpg";
import blockchainChapterThree368 from "../../sampleAssets/blockchain/blockchain-chapter-three-368.jpg";
import blockchainChapterThree576 from "../../sampleAssets/blockchain/blockchain-chapter-three-576.jpg";
import blockchainChapterThree768 from "../../sampleAssets/blockchain/blockchain-chapter-three-768.jpg";
import blockchainChapterThree1168 from "../../sampleAssets/blockchain/blockchain-chapter-three-1168.jpg";
import blockchainChapterThree1536 from "../../sampleAssets/blockchain/blockchain-chapter-three-1536.jpg";
import blockchainChapterThree2336 from "../../sampleAssets/blockchain/blockchain-chapter-three-2336.jpg";
import blockchainChapterThree3504 from "../../sampleAssets/blockchain/blockchain-chapter-three-3504.jpg";

import blockchainChapterFour288 from "../../sampleAssets/blockchain/blockchain-chapter-four-288.jpg";
import blockchainChapterFour368 from "../../sampleAssets/blockchain/blockchain-chapter-four-368.jpg";
import blockchainChapterFour576 from "../../sampleAssets/blockchain/blockchain-chapter-four-576.jpg";
import blockchainChapterFour768 from "../../sampleAssets/blockchain/blockchain-chapter-four-768.jpg";
import blockchainChapterFour1168 from "../../sampleAssets/blockchain/blockchain-chapter-four-1168.jpg";
import blockchainChapterFour1536 from "../../sampleAssets/blockchain/blockchain-chapter-four-1536.jpg";
import blockchainChapterFour2336 from "../../sampleAssets/blockchain/blockchain-chapter-four-2336.jpg";
import blockchainChapterFour3504 from "../../sampleAssets/blockchain/blockchain-chapter-four-3504.jpg";

import blockchainChapterFive288 from "../../sampleAssets/blockchain/blockchain-chapter-five-288.jpg";
import blockchainChapterFive368 from "../../sampleAssets/blockchain/blockchain-chapter-five-368.jpg";
import blockchainChapterFive576 from "../../sampleAssets/blockchain/blockchain-chapter-five-576.jpg";
import blockchainChapterFive768 from "../../sampleAssets/blockchain/blockchain-chapter-five-768.jpg";
import blockchainChapterFive1168 from "../../sampleAssets/blockchain/blockchain-chapter-five-1168.jpg";
import blockchainChapterFive1536 from "../../sampleAssets/blockchain/blockchain-chapter-five-1536.jpg";
import blockchainChapterFive2336 from "../../sampleAssets/blockchain/blockchain-chapter-five-2336.jpg";
import blockchainChapterFive3504 from "../../sampleAssets/blockchain/blockchain-chapter-five-3504.jpg";

import blockchainChapterSix288 from "../../sampleAssets/blockchain/blockchain-chapter-six-288.jpg";
import blockchainChapterSix368 from "../../sampleAssets/blockchain/blockchain-chapter-six-368.jpg";
import blockchainChapterSix576 from "../../sampleAssets/blockchain/blockchain-chapter-six-576.jpg";
import blockchainChapterSix768 from "../../sampleAssets/blockchain/blockchain-chapter-six-768.jpg";
import blockchainChapterSix1168 from "../../sampleAssets/blockchain/blockchain-chapter-six-1168.jpg";
import blockchainChapterSix1536 from "../../sampleAssets/blockchain/blockchain-chapter-six-1536.jpg";
import blockchainChapterSix2336 from "../../sampleAssets/blockchain/blockchain-chapter-six-2336.jpg";
import blockchainChapterSix3504 from "../../sampleAssets/blockchain/blockchain-chapter-six-3504.jpg";

import blockchainFigure288 from "../../sampleAssets/blockchain/blockchain-figure-288.png";
import blockchainFigure368 from "../../sampleAssets/blockchain/blockchain-figure-368.png";
import blockchainFigure576 from "../../sampleAssets/blockchain/blockchain-figure-576.png";
import blockchainFigure768 from "../../sampleAssets/blockchain/blockchain-figure-768.png";
import blockchainFigure1168 from "../../sampleAssets/blockchain/blockchain-figure-1168.png";
import blockchainFigure1536 from "../../sampleAssets/blockchain/blockchain-figure-1536.png";
import blockchainFigure2336 from "../../sampleAssets/blockchain/blockchain-figure-2336.png";
import blockchainFigure3504 from "../../sampleAssets/blockchain/blockchain-figure-3504.png";

import blockchainChart288 from "../../sampleAssets/blockchain/blockchain-chart-288.jpg";
import blockchainChart368 from "../../sampleAssets/blockchain/blockchain-chart-368.jpg";
import blockchainChart576 from "../../sampleAssets/blockchain/blockchain-chart-576.jpg";
import blockchainChart768 from "../../sampleAssets/blockchain/blockchain-chart-768.jpg";
import blockchainChart1168 from "../../sampleAssets/blockchain/blockchain-chart-1168.jpg";
import blockchainChart1536 from "../../sampleAssets/blockchain/blockchain-chart-1536.jpg";

import blockchainDiagram288 from "../../sampleAssets/blockchain/blockchain-diagram-288.png";
import blockchainDiagram368 from "../../sampleAssets/blockchain/blockchain-diagram-368.png";
import blockchainDiagram576 from "../../sampleAssets/blockchain/blockchain-diagram-576.png";
import blockchainDiagram768 from "../../sampleAssets/blockchain/blockchain-diagram-768.png";
import blockchainDiagram1168 from "../../sampleAssets/blockchain/blockchain-diagram-1168.png";
import blockchainDiagram1536 from "../../sampleAssets/blockchain/blockchain-diagram-1536.png";

import blockchainHero288 from "../../sampleAssets/blockchain/blockchain-hero-288.jpg";
import blockchainHero368 from "../../sampleAssets/blockchain/blockchain-hero-368.jpg";
import blockchainHero576 from "../../sampleAssets/blockchain/blockchain-hero-576.jpg";
import blockchainHero768 from "../../sampleAssets/blockchain/blockchain-hero-768.jpg";
import blockchainHero1168 from "../../sampleAssets/blockchain/blockchain-hero-1168.jpg";
import blockchainHero1536 from "../../sampleAssets/blockchain/blockchain-hero-1536.jpg";
import blockchainHero2336 from "../../sampleAssets/blockchain/blockchain-hero-2336.jpg";
import blockchainHero3504 from "../../sampleAssets/blockchain/blockchain-hero-3504.jpg";

import blockchainPresentation288 from "../../sampleAssets/blockchain/blockchain-presentation-288.jpg";
import blockchainPresentation368 from "../../sampleAssets/blockchain/blockchain-presentation-368.jpg";
import blockchainPresentation576 from "../../sampleAssets/blockchain/blockchain-presentation-576.jpg";
import blockchainPresentation768 from "../../sampleAssets/blockchain/blockchain-presentation-768.jpg";
import blockchainPresentation1168 from "../../sampleAssets/blockchain/blockchain-presentation-1168.jpg";
import blockchainPresentation1536 from "../../sampleAssets/blockchain/blockchain-presentation-1536.jpg";
import blockchainPresentation2336 from "../../sampleAssets/blockchain/blockchain-presentation-2336.jpg";

import blockchainVideo288 from "../../sampleAssets/blockchain/blockchain-video-288.jpg";
import blockchainVideo368 from "../../sampleAssets/blockchain/blockchain-video-368.jpg";
import blockchainVideo576 from "../../sampleAssets/blockchain/blockchain-video-576.jpg";
import blockchainVideo768 from "../../sampleAssets/blockchain/blockchain-video-768.jpg";
import blockchainVideo1168 from "../../sampleAssets/blockchain/blockchain-video-1168.jpg";
import blockchainVideo1536 from "../../sampleAssets/blockchain/blockchain-video-1536.jpg";
import blockchainVideo2336 from "../../sampleAssets/blockchain/blockchain-video-2336.jpg";

const Icons = {
  arrowRightBlue: "./icons/ui/blue/arrow-right.svg",
  facebookWhite: "./icons/social/white/facebook.svg",
  linkedinWhite: "./icons/social/white/linkedin.svg",
  twitterWhite: "./icons/social/white/twitter.svg",
  emailWhite: "./icons/ui/white/email.svg",
  transform2d: "./icons/nucleo/design-development/large/transform-2d.svg",
  time: "./icons/ui/grey/time-07.svg"
};

const chapters = [
  {
    anchor: "chapter-one",
    prefix: "Chapter One",
    title: " A new era for trust"
  },
  {
    anchor: "chapter-two",
    prefix: "Chapter Two",
    title: "The “internet of finance”"
  },
  {
    anchor: "chapter-three",
    prefix: "Chapter Three",
    title: "The cryptocurrency gold rush"
  },
  {
    anchor: "chapter-four",
    prefix: "Chapter Four",
    title: "Beyond FinTech: blockchain across industries"
  },
  {
    anchor: "chapter-five",
    prefix: "Chapter Five",
    title: "Building the future of blockchain"
  }
];

const Article = () => {
  return (
    <div>
      <SampleSchemaOrganization />
      <SampleHeader />
      <SemanticMain>
        <SemanticArticle>
          <Section background="grayDark" fullWidth>
            <Poster
              height="l"
              imageOverlay="scrimBottomDark"
              imageSrc={blockchainHero2336}
              imageSrcSet={`${blockchainHero288} 288w, ${blockchainHero368} 368w, ${blockchainHero576} 576w, ${blockchainHero768} 768w, ${blockchainHero1168} 1168w, ${blockchainHero1536} 1536w, ${blockchainHero2336} 2336w, ${blockchainHero3504} 3504w`}
              alignBottom
              share={
                <MarkupContainer>
                  <SocialDropdown>
                    <SocialDropdown.Item>
                      <SocialShare
                        pageUrl="https://www.lseg.com"
                        type="facebook"
                      >
                        <SVGIcon
                          name="facebook"
                          size="s"
                          getExternalIconPath={getSocialIcon}
                        />
                      </SocialShare>
                    </SocialDropdown.Item>
                    <SocialDropdown.Item>
                      <SocialShare
                        pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
                        pageUrl="https://www.lseg.com"
                        type="twitter"
                      >
                        <SVGIcon
                          name="twitter"
                          size="s"
                          getExternalIconPath={getSocialIcon}
                        />
                      </SocialShare>
                    </SocialDropdown.Item>
                    <SocialDropdown.Item>
                      <SocialShare
                        pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
                        pageUrl="https://www.lseg.com"
                        type="linkedIn"
                      >
                        <SVGIcon
                          name="linkedin"
                          size="s"
                          getExternalIconPath={getSocialIcon}
                        />
                      </SocialShare>
                    </SocialDropdown.Item>
                    <SocialDropdown.Item>
                      <SocialShare
                        pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
                        pageUrl="https://www.lseg.com"
                        type="email"
                      >
                        <SVGIcon
                          name="email"
                          size="s"
                          getExternalIconPath={getSocialIcon}
                        />
                      </SocialShare>
                    </SocialDropdown.Item>
                  </SocialDropdown>
                </MarkupContainer>
              }
            >
              <Grid variant="7/12,5/12" gutterlessAtNarrow>
                <Grid.Item>
                  <VerticalSpacing size="s">
                    <Eyebrow text="Beyond bitcoin" />
                  </VerticalSpacing>
                  <Heading type="xl" rank="1">
                    Are you ready for <strong>blockchain?</strong>
                  </Heading>
                </Grid.Item>
              </Grid>
            </Poster>
          </Section>
          <Longform>
            <MarkupContainer sticky>
              <Longform.ChapterNav>
                <Longform.ChapterNav.Chapter
                  title="Introduction"
                  href="#longform"
                />
                {chapters.map((chapter, i) => (
                  <Longform.ChapterNav.Chapter
                    key={i}
                    href={`#${chapter.anchor}`}
                    title={chapter.title}
                    prefix={`${i + 1}.`}
                  />
                ))}
              </Longform.ChapterNav>
            </MarkupContainer>

            <Longform.Child>
              <Longform.RichText dropcap>
                <p>
                  Blockchain reached a critical milestone in 2017 when it peaked
                  on research firm Gartner’s closely-watched{" "}
                  <a href="https://www.gartner.com/smarterwithgartner/top-trends-in-the-gartner-hype-cycle-for-emerging-technologies-2017/">
                    hype cycle
                  </a>, a ranking of fledgling technologies based on how the
                  market perceives them and how far they are away from
                  mainstream adoption. In bestowing this status on the
                  technology, Gartner predicted that blockchain is still five to
                  10 years away from going mainstream, writing “blockchain
                  technologies are extremely hyped, evolving at different
                  trajectories, but should not be ignored.”
                </p>
                <p>
                  To put that hype in perspective, a keyword search for the word
                  “blockchain” on the major press release distribution services,
                  PR Newswire and Business Wire, turns up a total of 1,970 press
                  releases issued in the first three quarters of 2017.
                </p>
                <p>
                  Everyone is talking about blockchain, but who’s really doing
                  something with the distributed ledger technology?
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child>
              <SemanticAside>
                <Callout
                  eyebrowText="What is blockchain?"
                  asset={<Icon path={Icons.transform2d} size="xl" />}
                >
                  Software that first emerged as the system underpinning
                  bitcoin. Also known as distributed ledger technology (DLT), it
                  is a shared record of information that is maintained and
                  updated by a network of computers rather than a central
                  authority. It is protected and secured by advanced
                  cryptography.
                </Callout>
              </SemanticAside>
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <p>
                  That list is decidedly smaller, but the real-world
                  applications of this technology that are being developed,
                  tested, and – in some cases – rolled out to the marketplace
                  will play a critical role in shaping the future of blockchain
                  development and determining just how quickly the technology
                  goes mainstream.
                </p>
                <p>
                  To help demystify some of this rapid-fire new technology
                  development, which received about{" "}
                  <a href="https://ww2.cfo.com/technology/2017/08/much-ado-blockchain/">
                    $1.4 billion in new investment
                  </a>{" "}
                  last year alone, this report will outline some of the key
                  blockchain projects that are currently in the works, share
                  insights on real-world application of the technology across
                  different industries, and weigh in on some of the most
                  promising use cases that will be first to move beyond the hype
                  cycle and into our everyday workflows.
                </p>
                <p>
                  But before any of that will really make sense, some baseline
                  background on blockchain is required. In its simplest possible
                  form, blockchain is a digital platform for recording and
                  verifying transactions. It traces its roots to bitcoin, the
                  digital “cryptocurrency” created in a{" "}
                  <a href="https://bitcoin.org/bitcoin.pdf">2009 white paper</a>{" "}
                  written by an unknown author(s) using the pseudonym Satoshi
                  Nakamoto.
                </p>
                <p>
                  The paper outlines the process of creating a purely
                  peer-to-peer version of electronic cash that can be sent
                  directly from one party to another without going through a
                  financial institution. The key to maintaining the integrity of
                  that system is a digital ledger that time-stamps transactions
                  by logging them into an ongoing chain of record, providing
                  proof of all transactions on the network.
                </p>
                <p>
                  This unbreakable, un-hackable, crowd-sourced chain of record
                  is the blockchain.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child width="wide">
              <SemanticAside>
                <Longform.Asset
                  border
                  caption={
                    <span>
                      <strong>Source</strong>: Financial Times
                    </span>
                  }
                >
                  <Image
                    alt="How blockchain works"
                    sizes={Image.sizes.section}
                    src={blockchainDiagram1168}
                    srcSet={`${blockchainDiagram288} 288w, ${blockchainDiagram368} 368w, ${blockchainDiagram576} 576w, ${blockchainDiagram768} 768w, ${blockchainDiagram1168} 1168w, ${blockchainDiagram1536} 1536w`}
                  />
                </Longform.Asset>
              </SemanticAside>
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <p>
                  Because it is decentralized and theoretically lives forever
                  digitally, the blockchain record provides a standardized
                  accounting of all touch points in any transaction. That means
                  contracts, financial transactions, bills of lading, property
                  titles, and tax filings that are the defining structures of
                  our economic system could be seamlessly digitized and recorded
                  forever in an open, distributed ledger.
                </p>
                <p>
                  Accordingly, blockchain has been touted as everything from a{" "}
                  <a href="https://www.businessinsider.com/blockchain-could-change-the-way-the-stock-market-operates-2017-4">
                    replacement for conventional stock exchanges
                  </a>{" "}
                  to a{" "}
                  <a href="https://www.mckinsey.com/industries/high-tech/our-insights/how-blockchains-could-change-the-world">
                    new distribution mechanism
                  </a>{" "}
                  for digital music, but most viable uses for the technology are
                  decidedly more practical.
                </p>
                <p>
                  Some of the most promising blockchain use cases currently in
                  development include{" "}
                  <a href="https://annual-report.thomsonreuters.com/blockchain-gets-real.html">
                    financial instrument and trade identification
                  </a>,{" "}
                  <a href="/en/press-releases/2017/june/thomson-reuters-releases-blockone-iq-into-blockchain-developer-community.html">
                    data delivery
                  </a>,{" "}
                  <a href="https://blogs.thomsonreuters.com/answerson/blockchain-in-finance-pioneers-prototypes-and-pitfalls/">
                    payment systems
                  </a>,{" "}
                  <a href="https://ww2.cfo.com/technology/2017/08/much-ado-blockchain/">
                    land registration
                  </a>,{" "}
                  <a href="https://blogs.thomsonreuters.com/answerson/legal-field-blockchain-comes-age/">
                    contract law
                  </a>, and even{" "}
                  <a href="https://blogs.thomsonreuters.com/answerson/digital-identity-authentication-technologies/">
                    identity verification
                  </a>.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child width="full">
              <Longform.ChapterDivider
                backgroundAnchor="C"
                backgroundGradient={true}
                backgroundSrc={blockchainChapterTwo1168}
                backgroundSrcSet={`${blockchainChapterTwo288} 288w, ${blockchainChapterTwo368} 368w, ${blockchainChapterTwo576} 576w, ${blockchainChapterTwo768} 768w, ${blockchainChapterTwo1168} 1168w, ${blockchainChapterTwo1536} 1536w, ${blockchainChapterTwo2336} 2336w, ${blockchainChapterTwo3504} 3504w`}
                chapterText={chapters[0].prefix}
                titleText={chapters[0].title}
                id={chapters[0].anchor}
              />
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <p>
                  For all of the excitement that exists around blockchain, there
                  is a lingering stigma. In large part, this is due to the
                  initial association of the digital currency bitcoin with the
                  black-market website known as Silk Road. Starting in 2011,
                  drug dealers began taking payment in bitcoin for sales made on
                  the site, leading to a major FBI investigation and the
                  eventual shutdown of the site in 2013.
                </p>
                <p>
                  Taking advantage of what they perceived to be digital
                  anonymity when using bitcoin, combined with some sophisticated
                  money-laundering techniques, Silk Road generated revenue worth
                  more than 9.5 million bitcoins, which translated to about $1.3
                  billion in 2013 valuations.
                </p>
                <p>
                  On the surface, some skepticism about the technology following
                  the Silk Road case was justified. The idea of a criminal
                  network of drug dealers transacting business on the dark web
                  with a digital currency that’s mined by high-speed computer
                  processing in a sort of digital alchemy that few people really
                  understand sounds sort of scary.
                </p>
                <p>
                  Digging deeper, however, it becomes clear that the same
                  immutability of the underlying ledger that makes blockchain so
                  attractive to contract lawyers and financial clearinghouses,
                  also made it relatively easy to catch the bad guys in the Silk
                  Road case. Ultimately, the ledger{" "}
                  <a href="https://www.theverge.com/2015/1/14/7546669/silk-road-trial-bitcoin-tracking">
                    detailed all of the illicit transactions
                  </a>, allowing prosecutors to put the 30-year-old mastermind
                  of the Silk Road empire, Ross Ulbricht,{" "}
                  <a href="https://www.wired.com/2017/05/silk-road-creator-ross-ulbricht-loses-life-sentence-appeal/">
                    in jail for life
                  </a>.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child>
              <SemanticAside>
                <ListOfLinks>
                  <ListOfLinks.BlockLink
                    eyebrow="Reuters"
                    href="https://www.reuters.com/article/us-usa-cyber-silkroad/ex-agent-in-silk-road-probe-gets-more-prison-time-for-bitcoin-theft-idUSKBN1D804H"
                  >
                    Ex-agent in Silk Road probe gets more prison time for
                    bitcoin theft
                  </ListOfLinks.BlockLink>
                  <ListOfLinks.BlockLink
                    eyebrow="Breakingviews"
                    href="https://www.reuters.com/article/us-bitcoin-exchanges-breakingviews/breakingviews-crypto-currencies-strength-becomes-their-weakness-idUSKCN1C41JU"
                  >
                    Cryptocurrencies’ strength becomes their weakness
                  </ListOfLinks.BlockLink>
                  <ListOfLinks.BlockLink
                    eyebrow="Reuters investigates"
                    href="https://www.reuters.com/investigates/special-report/bitcoin-exchanges-risks/"
                  >
                    Chaos and hackers stalk investors on cryptocurrency
                    exchanges
                  </ListOfLinks.BlockLink>
                  <ListOfLinks.BlockLink
                    eyebrow="Reuters investigates"
                    href="https://www.reuters.com/investigates/special-report/bitcoin-wright-patents/"
                  >
                    Bitcoin’s “creator” races to patent technology with gambling
                    tycoon
                  </ListOfLinks.BlockLink>
                </ListOfLinks>
              </SemanticAside>
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <p>
                  After reviewing all of the case materials, prosecutors
                  concluded it actually would have been easier for the Silk Road
                  criminals to conceal their assets in the traditional financial
                  system.
                </p>
                <p>
                  Ironically, because of all of this, the technology that came
                  to the mainstream consciousness in a flurry of stories about
                  cybercriminals may actually create a new model for trust in
                  the global financial system.
                </p>
                <p>
                  Cryptocurrencies have succeeded without the backing of a
                  central bank to ensure the safe management of currency as a
                  store of value. Instead, users of cryptocurrencies trust in
                  the transparent and permanent record of transactions stored by
                  every user on the blockchain. Trust is not dependent on one
                  central authority; it is federated between all users, while,
                  on some level, that may be scary for some who will need to
                  cede control of proprietary systems of checks and balances in
                  favor of an open, transparent approach.
                </p>
                <p>
                  The implications will be far-reaching. Beyond having an
                  immediate financial impact on many institutions who’ve been
                  charged with auditing, assigning identification tags, and
                  processing the contracts, financial instruments, and documents
                  that can now all be tracked on blockchain, the mere existence
                  of this record fundamentally changes our foundations of trust.
                </p>
                <p>
                  Reflecting on the{" "}
                  <a href="https://blogs.thomsonreuters.com/answerson/rebuilding-trust-after-northern-rock-bank-run/">
                    10 years since the credit crunch
                  </a>{" "}
                  and subsequent global financial crisis,{" "}
                  <a href="https://www.thomsonreuters.com/en/profiles/david-craig.html">
                    David Craig
                  </a>{" "}
                  – President, Financial & Risk – explains: “Trust today in
                  financial services has recovered from the shocks of ten years
                  ago. But in a decade’s time, new models of how we transact
                  with each other will be established and trust will be very
                  different from what it is today.”
                </p>
                <p>
                  Instead of putting our trust solely in the hands of
                  traditional institutions, we’ll be putting our trust in a
                  federated network of digital fingerprints that’s keeping an
                  ongoing record of everything. Of course, the safekeeping of
                  that digital record will be critical to this new trust
                  foundation, and will introduce an entirely new world of
                  digital security requirements.
                </p>
                <p>
                  With new blockchain initiatives launching pretty much daily,
                  the path ahead is anything but certain, but the implications
                  of the technology on many of the fundamental underpinnings of
                  our business and legal structures are already starting to
                  become clear.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child width="full">
              <Longform.ChapterDivider
                backgroundAnchor="C"
                backgroundGradient={true}
                backgroundSrc={blockchainChapterThree1168}
                backgroundSrcSet={`${blockchainChapterThree288} 288w, ${blockchainChapterThree368} 368w, ${blockchainChapterThree576} 576w, ${blockchainChapterThree768} 768w, ${blockchainChapterThree1168} 1168w, ${blockchainChapterThree1536} 1536w, ${blockchainChapterThree2336} 2336w, ${blockchainChapterThree3504} 3504w`}
                chapterText={chapters[1].prefix}
                titleText={chapters[1].title}
                id={chapters[1].anchor}
              />
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <p>
                  Due in part to its roots as the backbone of a digital
                  currency, blockchain is furthest along on the development
                  curve in the financial services industry. Most of the
                  attention on the technology has been focused on using the
                  technology to streamline back-office processes such as trade
                  processing, clearing, and settlement.
                </p>
                <p>
                  This potential led the Bank of England to suggest in a{" "}
                  <a href="https://www.bankofengland.co.uk/publications/Documents/quarterlybulletin/2014/qb14q3digitalcurrenciesbitcoin1.pdf">
                    2014 white paper
                  </a>{" "}
                  that blockchain could become the “Internet of finance.” The
                  bank explained:
                </p>
                <p>
                  “With conventional bank deposits, banks hold the digital
                  record and are trusted to ensure its validity. With digital
                  currencies, by contrast, the ledger containing the record of
                  all transactions by all users is publicly available to all.
                  Rather than requiring users to have trust in special
                  institutions, reliance is placed on the network and the rules
                  established to reliably change the ledger.”
                </p>
                <p>
                  What that means is that blockchain can remove the need for
                  transaction intermediaries there to create trust between
                  participants because the technology itself establishes the
                  trust, while at the same time, creating a pure, digital record
                  that exists independent of any single institution and cannot
                  be tampered with or exploited in any way.
                </p>
                <p>That’s a big deal.</p>
                <p>
                  In one fell swoop, by taking all of the third parties and
                  proprietary processes out of the equation, blockchain
                  addresses everything from anti-money laundering and corporate
                  transparency issues to seamless trade identification and
                  reporting in a single, elegant package.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child width="wide">
              <SemanticAside>
                <Longform.Asset
                  caption={
                    <span>
                      Axel Threlfall, Reuters Editor-at-Large, chats with David
                      Craig, President of Financial & Risk at Thomson Reuters,
                      and Peter Smith, CEO of Blockchain, about the impact
                      blockchain is having and will have on the financial
                      services industry (<a href="https://blogs.thomsonreuters.com/answerson/impact-blockchain-financial-services/">
                        transcript
                      </a>).
                    </span>
                  }
                >
                  <MarkupContainer>
                    <VideoPlayer
                      description="Axel Threlfall, Reuters Editor-at-Large, chats with David Craig, President of Financial & Risk at Thomson Reuters, and Peter Smith, CEO of Blockchain, about the impact blockchain is having and will have on the financial services industry."
                      duration="PT1M36S"
                      kind="vimeo"
                      name="Thomson Reuters + Axoni"
                      playLabel="Play video"
                      thumbnailUrl={`https://www.example.com${blockchainVideo576}`}
                      uploadDate="2017-04-01"
                      videoId="208682545"
                    >
                      <Image
                        alt=""
                        fit="cover"
                        sizes={Image.sizes.section}
                        src={blockchainVideo1168}
                        srcSet={`${blockchainVideo288} 288w, ${blockchainVideo368} 368w, ${blockchainVideo576} 576w, ${blockchainVideo768} 768w, ${blockchainVideo1168} 1168w, ${blockchainVideo1536} 1536w, ${blockchainVideo2336} 2336w`}
                      />
                    </VideoPlayer>
                  </MarkupContainer>
                </Longform.Asset>
              </SemanticAside>
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <p>
                  Accordingly, Thomson Reuters has been one of the first
                  established industry players to develop fully functioning
                  blockchain technologies for financial market participants.
                  Among these are a tool called{" "}
                  <a href="https://www.reuters.com/article/us-thomsonreuters-blockchain/thomson-reuters-makes-its-market-data-blockchain-friendly-idUSKBN1950RZ">
                    BlockOne IQ
                  </a>, which integrates Thomson Reuters market data directly
                  into trading systems that are running on the two primary
                  blockchains, Ethereum and Corda. Another is{" "}
                  <a href="https://blockoneid.thomsonreuters.com/">
                    BlockOne ID
                  </a>, which is an identity authentication capability that
                  developers of blockchain technology can deploy to create
                  permissioned Ethereum-based applications.
                </p>
                <p>
                  Ultimately, what’s unfolding here is a rapid maturation of the
                  blockchain ecosystem as financial firms begin to bring
                  together all of the checks and balances required to comply
                  with current regulatory and administrative requirements, while
                  making the transition to distributed ledger technology.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child width="full">
              <Longform.ChapterDivider
                backgroundAnchor="C"
                backgroundGradient={true}
                backgroundSrc={blockchainChapterFour1168}
                backgroundSrcSet={`${blockchainChapterFour288} 288w, ${blockchainChapterFour368} 368w, ${blockchainChapterFour576} 576w, ${blockchainChapterFour768} 768w, ${blockchainChapterFour1168} 1168w, ${blockchainChapterFour1536} 1536w, ${blockchainChapterFour2336} 2336w, ${blockchainChapterFour3504} 3504w`}
                chapterText={chapters[2].prefix}
                titleText={chapters[2].title}
                id={chapters[2].anchor}
              />
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <p>
                  Financial professionals were initially more interested in the
                  ledger technology underlying cryptocurrency, than the currency
                  itself. That all started to change this year as a<a href="https://qz.com/994466/the-new-cryptocurrency-gold-rush-digital-tokens-that-raise-millions-in-minutes/">
                    cryptocurrency gold rush
                  </a>{" "}
                  has made bitcoin the{" "}
                  <a href="https://www.etf.com/sections/features-and-news/why-bitcoin-etfs-are-closer-reality?nopaging=1">
                    hottest financial asset of 2017
                  </a>.
                </p>
                <p>
                  And, while mainstream institutional investors have largely{" "}
                  <a href="https://www.reuters.com/article/us-markets-bitcoin-institutional/big-money-stays-away-from-booming-bitcoin-idUSKBN1CS0EB?utm_source=ao_newsletter_tech&amp;utm_campaign=email_activation&amp;utm_medium=email&amp;utm_content=2017-11-01">
                    stayed clear of the nascent market
                  </a>, there are financial leaders pushing for continued
                  exploration of FinTech in the global financial community.
                  Consider IMF’s Christine Lagarde’s comments on virtual
                  currencies in{" "}
                  <a href="https://www.imf.org/en/News/Articles/2017/09/28/sp092917-central-banking-and-fintech-a-brave-new-world">
                    her remarks at the Bank of England’s conference
                  </a>{" "}
                  at the end of September 2017: “I think it may not be wise to
                  dismiss virtual currencies. For instance, think of countries
                  with weak institutions and unstable national currencies.
                  Instead of adopting the currency of another country—such as
                  the U.S. dollar—some of these economies might see a growing
                  use of virtual currencies. Call it dollarization 2.0.”
                </p>
                <p>
                  So just how valuable are cryptocurrencies? The below graphics
                  show the top cryptocurrencies by market capitalization, and
                  compares them to several country GDPs and various cultural
                  financial powerhouses.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child width="wide">
              <Block
                height="6rem"
                text="Top cryptocurrencies by market capitalization"
              />
            </Longform.Child>
            <Longform.Child width="wide">
              <SemanticAside>
                <VerticalSpacing size="s">
                  <Longform.RichText>
                    <h3>Cryptocurrencies, compared</h3>
                  </Longform.RichText>
                </VerticalSpacing>
                <Longform.Asset
                  caption={
                    <span>
                      <strong>Source:</strong> Thomson Reuters Labs
                    </span>
                  }
                >
                  <Image
                    alt="Cryptocurrencies, compared"
                    sizes={Image.sizes.section}
                    src={blockchainFigure1168}
                    srcSet={`${blockchainFigure288} 288w, ${blockchainFigure368} 368w, ${blockchainFigure576} 576w, ${blockchainFigure768} 768w, ${blockchainFigure1168} 1168w, ${blockchainFigure1536} 1536w, ${blockchainFigure2336} 2336w, ${blockchainFigure3504} 3504w`}
                  />
                </Longform.Asset>
              </SemanticAside>
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <p>
                  According to{" "}
                  <a href="https://www.coinschedule.com/stats">Coinschedule</a>,
                  new cryptocurrency offerings – which are called initial coin
                  offerings, or ICOs – have raised more than $3.4 billion this
                  year, and the price of bitcoin has risen from about $910 in
                  January 2017 to{" "}
                  <a href="https://www.reuters.com/article/uk-markets-bitcoin/bitcoin-hits-another-record-high-in-march-towards-20000-idUSKBN1E60PE">
                    over $17,000
                  </a>{" "}
                  in December 2017, catching the eye of mainstream investors and
                  financial markets professionals alike.<br />
                </p>
                <p>
                  There’s even talk of new bitcoin ETFs being offered to
                  mainstream consumers, following the U.S.{" "}
                  <a href="https://www.cftc.gov/PressRoom/PressReleases/pr7592-17">
                    Commodity Futures Trading Commission (CFTC) order
                  </a>{" "}
                  granting <a href="https://ledgerx.com/">LedgerX</a>{" "}
                  registration as a derivatives clearing organization. The move
                  allows the company to start offering options on bitcoin to
                  institutional investors this year, making it the first
                  federally regulated bitcoin options exchange. Those watching
                  these developments closely anticipate that the institutional
                  uptake of cryptocurrency trading on regulated platforms could
                  pave the way for consumer ETFs offering cryptocurrencies in
                  the near future.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child width="wide">
              <SemanticAside>
                <Longform.Asset
                  caption={
                    <span>
                      <strong>Source</strong>: Thomson Reuters Eikon, showing
                      the price of bitcoin from November 6, 2016 through
                      November 6, 2017.
                    </span>
                  }
                >
                  <Image
                    alt="How blockchain works"
                    sizes={Image.sizes.section}
                    src={blockchainChart1168}
                    srcSet={`${blockchainChart288} 288w, ${blockchainChart368} 368w, ${blockchainChart576} 576w, ${blockchainChart768} 768w, ${blockchainChart1168} 1168w, ${blockchainChart1536} 1536w`}
                  />
                </Longform.Asset>
              </SemanticAside>
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <p>
                  Amid all of this activity, wealth management professionals are
                  already fielding calls from clients asking why they are not
                  recommending cryptocurrencies as part of their portfolios. In
                  fact, the Swiss private bank Falcon has{" "}
                  <a href="https://www.reuters.com/article/us-swiss-banks-falcon-bitcoin/swiss-private-bank-falcon-introduces-bitcoin-asset-management-idUSKBN19X28L">
                    begun offering
                  </a>{" "}
                  clients the ability to store and trade bitcoin with their cash
                  holdings.
                </p>
                <h3>Cryptocurrencies around the world</h3>
                <p>
                  Where the story of cryptocurrencies becomes even more
                  interesting and complex, however, is how cryptocurrencies are
                  treated and recognized around the world.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child width="wide">
              <Block
                height="6rem"
                text="Global acceptance of cryptocurrencies"
              />
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <p>
                  The above data visualization shows governmental attitudes
                  toward cryptocurrencies, not limited to bitcoin alone. The
                  picture produced across the world is patchy. Some countries
                  have become global advocates, while others have actively
                  banned cryptocurrencies completely, with various shades in
                  between.
                </p>
                <p>
                  The most notable disrupter is Japan, which has passed a law
                  accepting bitcoin as legal tender. At the other end of the
                  spectrum, Bangladesh passed a law in 2014 stating that anybody
                  caught using the virtual currency could be jailed under the
                  country’s strict anti-money-laundering laws.
                </p>
                <p>
                  All of this is still version 1.0 of the burgeoning
                  cryptocurrency revolution, and the lay of the land will
                  certainly change considerably over the next several months,
                  but the pieces are all coming together to support a very
                  robust infrastructure for blockchain and related
                  cryptocurrencies to get the institutional – and eventually
                  mainstream – credibility they need to continue growing.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child width="full">
              <Longform.ChapterDivider
                backgroundAnchor="C"
                backgroundGradient={true}
                backgroundSrc={blockchainChapterFive1168}
                backgroundSrcSet={`${blockchainChapterFive288} 288w, ${blockchainChapterFive368} 368w, ${blockchainChapterFive576} 576w, ${blockchainChapterFive768} 768w, ${blockchainChapterFive1168} 1168w, ${blockchainChapterFive1536} 1536w, ${blockchainChapterFive2336} 2336w, ${blockchainChapterFive3504} 3504w`}
                chapterText={chapters[3].prefix}
                titleText={chapters[3].title}
                id={chapters[3].anchor}
              />
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <p>
                  It’s not just the financial services industry that’s being
                  disrupted by blockchain. In fact, the potential in the tax and
                  accounting and legal industries may be even more disruptive
                  than what we’ve seen taking shape in finance.
                </p>
                <p>
                  It all goes back to the role of blockchain as a decentralized
                  digital platform for recording and verifying transactions.
                  Think about the potential for a technology like that in the
                  world of land registry for tax identification and collection
                  purposes.
                </p>
                <p>
                  Currently, when you buy a house in the United States, you
                  enter into an archaic system of paperwork and bureaucratic red
                  tape that typically takes anywhere from 60 to 90 days to
                  resolve. Title searches need to be conducted, surveys need to
                  be vetted, tax records need to be verified, and financing
                  documents need to be passed around to a ragtag array of
                  lawyers, realtors, government authorities, lenders, and the
                  parties to the sale.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child>
              <SemanticAside>
                <VerticalSpacing>
                  <Longform.RichText>
                    <h3>Blockchain across industries</h3>
                  </Longform.RichText>
                </VerticalSpacing>
                <ListOfLinks>
                  <ListOfLinks.BlockLink
                    eyebrow="Trade"
                    href="https://blogs.thomsonreuters.com/answerson/blockchain-poised-radically-disrupt-trade/"
                  >
                    Blockchain is poised to radically disrupt trade
                  </ListOfLinks.BlockLink>
                  <ListOfLinks.BlockLink
                    eyebrow="Legal"
                    href="https://blogs.thomsonreuters.com/answerson/legal-field-blockchain-comes-age/"
                  >
                    In legal field, blockchain comes of age
                  </ListOfLinks.BlockLink>
                  <ListOfLinks.BlockLink
                    eyebrow="Tax &amp; Accounting"
                    href="https://blogs.thomsonreuters.com/answerson/blockchain-impact-tax-and-accounting-industry/"
                  >
                    Blockchain: Why tax and accounting professionals must get on
                    board
                  </ListOfLinks.BlockLink>
                </ListOfLinks>
              </SemanticAside>
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <p>
                  That’s all before the transaction occurs and the records can
                  be filed and monitored for ongoing property taxation. And
                  that’s in the developed world, where – for the most part –
                  property lines are clearly marked and records of ownership
                  have been meticulously documented and stored for years. In the
                  developing world, where many records of land ownership have
                  either been destroyed by civil unrest, distorted by corrupt
                  government officials, or simply never existed, the challenge
                  is even more serious.
                </p>
                <p>
                  Blockchain has the power to fix all of that by creating a
                  consistent, immutable record that cannot be distorted.
                </p>
                <p>
                  Sweden’s land registry authority, Lantmäteriet, has{" "}
                  <a href="https://www.computerweekly.com/news/450421958/Sweden-trials-blockchain-for-land-registry-management">
                    already begun testing blockchain
                  </a>{" "}
                  for this very purpose by implementing a pilot program earlier
                  this year for recording property transactions. The successful
                  program allowed copies of records held by the land registry
                  and all other parties to the transaction, such as banks and
                  real estate agents, and each step of the property purchase
                  process to be verified and recorded on blockchain for all
                  parties to see. In their assessment of the pilot program,
                  Lantmäteriet said they believe using blockchain will cut the
                  time taken for writing a purchasing contract through to
                  registering a property title from four months to a few days.
                </p>
                <p>
                  Similarly, Luxembourg has{" "}
                  <a href="https://www.americanbanker.com/news/blockchain-startup-forms-partnership-to-develop-identity-platform">
                    begun developing
                  </a>{" "}
                  a blockchain-based identity platform that will be used in
                  everything from tax filing to regulatory enforcement.
                </p>
                <p>
                  <a href="https://www.thomsonreuters.com/en/profiles/brian-peccarelli.html">
                    Brian Peccarelli
                  </a>, president of Thomson Reuters Tax &amp; Accounting
                  business, points to these initial government-backed projects
                  as clear signs of a major transformation that businesses will
                  need to address sooner than later,{" "}
                  <a href="https://ww2.cfo.com/technology/2017/08/much-ado-blockchain/">
                    commenting
                  </a>{" "}
                  in a recent CFO article: “The fact that government authorities
                  have become so active in blockchain experimentation means that
                  we will soon reach a point where corporations will be expected
                  to interact with those authorities using the distributed
                  ledger technology. Whether through basic blockchain-based
                  registration processes or more elaborate data-transfer
                  protocols, corporations need to start thinking about whether
                  or not their existing technologies and ERP systems can support
                  this type of dialogue.”
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child width="wide">
              <SemanticAside>
                <Longform.Asset
                  caption={
                    <span>
                      “Blockchain is to value as the Internet was to
                      information.” Irish McIntyre, vice president, product
                      management, Tax and Accounting, explains how blockchain
                      will disrupt the tax and accounting profession.
                    </span>
                  }
                >
                  <VideoPlayer
                    description="“Blockchain is to value as the Internet was to information.” Irish McIntyre, vice president, product management, Tax and Accounting, explains how blockchain will disrupt the tax and accounting profession."
                    duration="PT1M36S"
                    kind="vimeo"
                    name="How will blockchain disrupt tax and accounting"
                    playLabel="Play video"
                    thumbnailUrl={`https://www.example.com${blockchainPresentation576}`}
                    uploadDate="2017-04-01"
                    videoId="208682545"
                  >
                    <Image
                      alt=""
                      fit="cover"
                      sizes={Image.sizes.section}
                      src={blockchainPresentation1168}
                      srcSet={`${blockchainPresentation288} 288w, ${blockchainPresentation368} 368w, ${blockchainPresentation576} 576w, ${blockchainPresentation768} 768w, ${blockchainPresentation1168} 1168w, ${blockchainPresentation1536} 1536w, ${blockchainPresentation2336} 2336w`}
                    />
                  </VideoPlayer>
                </Longform.Asset>
              </SemanticAside>
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <p>
                  With far-reaching implications that cut across tax, legal,
                  financial, technology, and operations functions, blockchain is
                  well on its way to becoming a serious disrupter in every
                  industry. The sooner corporations get on board, the better
                  position they will be in to exploit the technology to
                  streamline their operations.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child width="full">
              <Longform.ChapterDivider
                backgroundAnchor="C"
                backgroundGradient={true}
                backgroundSrc={blockchainChapterSix1168}
                backgroundSrcSet={`${blockchainChapterSix288} 288w, ${blockchainChapterSix368} 368w, ${blockchainChapterSix576} 576w, ${blockchainChapterSix768} 768w, ${blockchainChapterSix1168} 1168w, ${blockchainChapterSix1536} 1536w, ${blockchainChapterSix2336} 2336w, ${blockchainChapterSix3504} 3504w`}
                chapterText={chapters[4].prefix}
                titleText={chapters[4].title}
                id={chapters[4].anchor}
              />
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <p>
                  It’s tempting to focus on a handful of blockchain use cases
                  and compartmentalize the technology as a nifty piece of
                  back-office technology that will help improve record keeping
                  and streamline contract processing, but not necessarily change
                  the world. Taken as a whole, however, the technology is bigger
                  than that.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child width="wide">
              <SemanticAside>
                <Longform.BlockQuote
                  attribution={
                    <Attribution
                      href="https://example.com"
                      name="Sam Chadwick"
                      caption="Director of Strategy and Innovation,
                      Financial &amp; Risk"
                    />
                  }
                  text="Using blockchain tokens to record and exchange ownership of
                    assets and rights is going to transform industries in the
                    same way electricity transformed coal-powered factories; not
                    to mention streamlining how information is shared across
                    supply chains."
                />
              </SemanticAside>
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <p>
                  In virtually every industry around the world, blockchain is
                  disintermediating traditional supply chains and, in turn,
                  upending decades-old regulatory structures that have been
                  built around a certain way of doing business. The question for
                  many businesses confronting this reality is:
                </p>
                <h3>How do you stay one step ahead of this transformation?</h3>
                <h4>Join the blockchain ecosystem</h4>
                <p>
                  At Thomson Reuters, we have developed an{" "}
                  <a href="https://annual-report.thomsonreuters.com/blockchain-gets-real.html">
                    ecosystem of blockchain partnership
                  </a>{" "}
                  with hundreds of entrepreneurs and start-ups as well as with
                  key industry organizations. We build blockchain applications
                  and experiment with them.
                </p>
                <h4>Hack your way in</h4>
                <p>
                  We{" "}
                  <a href="https://blogs.thomsonreuters.com/answerson/hack-the-valley-blockchain-hackathon/">
                    host blockchain hackathons
                  </a>{" "}
                  to explore blockchain’s potential to disrupt and transform
                  industries. We have invested, alongside the best venture and
                  strategic investors, in start-ups that demonstrate
                  transformational ideas with solid business models. This sets
                  us apart and opens the door to more opportunities and
                  partnerships.
                </p>
                <h4>Use an open foundation</h4>
                <p>
                  We also have an{" "}
                  <a href="/en/products-services/open-platform.html">
                    open platform approach
                  </a>, whereby we allow our data, analytics, and insights to be
                  integrated easily with third-party applications, flexible APIs
                  that are interoperable with those of other trusted providers,
                  and ongoing collaboration with industry partners, academia,
                  and our customers to break down the silos that have kept these
                  functions separated for so long.
                </p>
                <h4>Build on BlockOne</h4>
                <p>
                  That’s the thinking behind our launch of the{" "}
                  <a href="https://blockone.thomsonreuters.com/">
                    BlockOne IQ and BlockOne ID tool kits
                  </a>{" "}
                  to foster co-innovation and enable our clients and partners to
                  experiment with new approaches to implementing blockchain
                  technology into their existing workflows.
                </p>
                <p>
                  Significant challenges still exist on the way to full-scale
                  adoption of blockchain, but the progress made thus far will
                  only be amplified as incremental milestones are achieved, and
                  that’s where the hype will start to become reality.
                </p>
                <p>Will you be ready?</p>
              </Longform.RichText>
            </Longform.Child>
          </Longform>
        </SemanticArticle>
        <Section>
          <VerticalSpacing size="l">
            <Heading type="m" rank="2">
              <strong>Join our ecosystem</strong>
            </Heading>
          </VerticalSpacing>
          <Grid variant="even-4">
            <Grid.Item>
              <TextCard
                description="An identity mapping service for Ethereum blockchains."
                eyebrow="Beta"
                footer={
                  <LinkCardFooter
                    title="See details"
                    type="icon"
                    icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  />
                }
                heading="BlockOne IDD"
                href="https://blockoneid.thomsonreuters.com/"
                size="medium"
              />
            </Grid.Item>
            <Grid.Item>
              <TextCard
                description="Blockchains need data. We provide it."
                eyebrow="Beta"
                footer={
                  <LinkCardFooter
                    title="See details"
                    type="icon"
                    icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  />
                }
                heading="BlockOne IQ"
                href="https://blockoneiq.thomsonreuters.com/"
                size="medium"
              />
            </Grid.Item>
            <Grid.Item>
              <TextCard
                description="A global network collaborating with customers to solve big problems and rapidly prototype and validate solutions using data science and lean techniques."
                eyebrow="Innovation"
                footer={
                  <LinkCardFooter
                    title="See details"
                    type="icon"
                    icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  />
                }
                heading="Thomson Reuters Labs"
                href="https://innovation.thomsonreuters.com/en/labs.html"
                size="medium"
              />
            </Grid.Item>
            <Grid.Item>
              <TextCard
                description="Six-month start-up incubator in Thomson Reuters Labs Zurich Region."
                eyebrow="Partnership"
                footer={
                  <LinkCardFooter
                    title="Learn more"
                    type="icon"
                    icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  />
                }
                heading="The Incubator"
                href="https://innovation.thomsonreuters.com/en/labs/incubator.html"
                size="medium"
              />
            </Grid.Item>
          </Grid>
        </Section>
        <SectionBridge>
          <Block height="12rem" text="Sign up and stay informed" />
        </SectionBridge>
        <Section background="grayLightest">
          <VerticalSpacing size="l">
            <Header
              heading={
                <Heading rank="2" type="m">
                  <strong>More answers on blockchain</strong>
                </Heading>
              }
              callToAction={
                <Link
                  href="https://blogs.thomsonreuters.com/answerson/topic/blockchain/"
                  type="icon"
                  icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  iconPosition={"right"}
                >
                  See all blockchain articles
                </Link>
              }
            />
          </VerticalSpacing>
          <Grid variant="even-3">
            <Grid.Item>
              <PairedCard
                footer={
                  <ArticleCardFooter
                    dateTime="2017-10-24 13:00"
                    readingTime="6 min"
                    icon={<Icon path={Icons.time} size="xs" />}
                  />
                }
                heading="Implications of blockchain technology"
                href="https://blogs.thomsonreuters.com/answerson/implications-of-blockchain-technology/"
                imgAnchor="C"
                imgSrc={blockchainCardOne368}
                imgSrcSet={`${blockchainCardOne288} 288w, ${blockchainCardOne368} 368w, ${blockchainCardOne576} 576w, ${blockchainCardOne768} 768w, ${blockchainCardOne1168} 1168w, ${blockchainCardOne1536} 1536w`}
              />
            </Grid.Item>
            <Grid.Item>
              <PairedCard
                footer={
                  <ArticleCardFooter
                    dateTime="2017-08-07 13:00"
                    readingTime="5 min"
                    icon={<Icon path={Icons.time} size="xs" />}
                  />
                }
                heading="In legal field, blockchain comes of age"
                href="https://blogs.thomsonreuters.com/answerson/legal-field-blockchain-comes-age/"
                imgAnchor="C"
                imgSrc={blockchainCardTwo368}
                imgSrcSet={`${blockchainCardTwo288} 288w, ${blockchainCardTwo368} 368w, ${blockchainCardTwo576} 576w, ${blockchainCardTwo768} 768w, ${blockchainCardTwo1168} 1168w, ${blockchainCardTwo1536} 1536w`}
              />
            </Grid.Item>
            <Grid.Item>
              <PairedCard
                footer={
                  <ArticleCardFooter
                    dateTime="2017-08-02 13:00"
                    readingTime="5 min"
                    icon={<Icon path={Icons.time} size="xs" />}
                  />
                }
                heading="Blockchain: Why tax and accounting professionals must get on board"
                href="https://blogs.thomsonreuters.com/answerson/blockchain-impact-tax-and-accounting-industry/"
                imgAnchor="C"
                imgSrc={blockchainCardThree368}
                imgSrcSet={`${blockchainCardThree288} 288w, ${blockchainCardThree368} 368w, ${blockchainCardThree576} 576w, ${blockchainCardThree768} 768w, ${blockchainCardThree1168} 1168w, ${blockchainCardThree1536} 1536w`}
              />
            </Grid.Item>
          </Grid>
        </Section>
      </SemanticMain>
      <SampleFooter />
    </div>
  );
};

export default Article;
