import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text, boolean } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import MultiSelectDropdown from "./index";
import "./MultiSelectDropdown.css";
import MultiSelectDropdownREADME from "../README.md";

const stories = storiesOf("Components/MultiSelectDropdown", module);
const icecreamFlavours = [
  {
    value: "chocolate",
    label: "Chocolate"
  },
  {
    value: "strawberry",
    label: "Strawberry"
  },
  {
    value: "vanilla",
    label: "Vanilla"
  }
];

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(MultiSelectDropdownREADME));

stories
  .add("Default", () => (
    <MultiSelectDropdown
      id={text("id", "IceCreams")}
      name={text("Name", "IceCreams")}
      noOptionsMessage={text("noOptionsMessage", "No Item Found")}
      options={icecreamFlavours}
      placeholder={text("placeholder", "Select from the list")}
      required={boolean("required", false)}
      errorMessage={text(
        "errorMessage",
        "Error Message: This field is required"
      )}
      labelText={text("labelText", "Label")}
      helpText={text("Help Text", "This is a hint about this field")}
    />
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <MultiSelectDropdown
        id={text("id", "IceCreams")}
        name={text("Name", "IceCreams")}
        noOptionsMessage={text("noOptionsMessage", "No Item Found")}
        options={icecreamFlavours}
        placeholder={text("placeholder", "Select from the list")}
        required={boolean("required", false)}
        errorMessage={text(
          "errorMessage",
          "Error Message: This field is required"
        )}
        labelText={text("labelText", "Label")}
        helpText={text("Help Text", "This is a hint about this field")}
      />
    </Language>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <MultiSelectDropdown
        id={text("id", "IceCreams")}
        name={text("Name", "IceCreams")}
        noOptionsMessage={text("noOptionsMessage", "No Item Found")}
        options={icecreamFlavours}
        placeholder={text("placeholder", "Select from the list")}
        required={boolean("required", false)}
        errorMessage={text(
          "errorMessage",
          "Error Message: This field is required"
        )}
        labelText={text("labelText", "Label")}
        helpText={text("Help Text", "This is a hint about this field")}
      />
    </StaticRenderer>
  ));
