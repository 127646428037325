import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import ToggleItem from "./components/ToggleItem/ToggleItem";
import AjaxPanel from "../../component-panel/src/Panel/Panel";
import { Provider as AjaxPanelProvider } from "../../component-panel/src/ajaxPanelProvider";

class ToggleButton extends Component {
  static displayName = "ToggleButton";

  static propTypes = {
    /**
     * An analytics function, accepting category, action, and label as arguments.
     */
    analytics: PropTypes.func,
    /**
     * Toggle alignment center, left and right positions
     */
    alignment: PropTypes.oneOf(["center", "left", "right"]).isRequired,
    /**
     * Toggle active item
     */
    activeItem: PropTypes.oneOf(["first", "second"]).isRequired,
    /**
     * Toggle items with title, content, id & aria label
     */
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        ariaLabel: PropTypes.string
      })
    ).isRequired,
    /**
     * Boolean for ajax panel required or not
     */
    isAjax: PropTypes.bool
  };

  static defaultProps = {
    analytics: () => {}
  };

  getClassName = getClassNameFactory(ToggleButton.displayName);

  constructor(props) {
    super(props);

    const activeId =
      props &&
      ToggleButton.activeItem.findIndex(item => item === props.activeItem);

    this.state = {
      activeTab: props.items[activeId].id,
      tabItems: props.items
    };

    this.tabRef = React.createRef();
  }

  getSelectedItemIndex = (tabItems, itemId) => {
    const selectedTabId = tabItems.findIndex(tab => {
      return tab.id === itemId;
    });

    return selectedTabId;
  };

  clickHandler = (e, id) => {
    e.preventDefault();
    this.setActiveTab(id);

    const { tabItems } = this.state;
    const tabIndex = this.getSelectedItemIndex(tabItems, id);
    const tab = tabItems[tabIndex];

    this.props.analytics("Toggle Change", "Click", tab.title);
  };

  getPreviousNextKey = () => {
    const direction = getComputedStyle(this.tabRef.current).direction;
    const nextKey = direction === "ltr" ? "ArrowRight" : "ArrowLeft";
    const previousKey = direction === "ltr" ? "ArrowLeft" : "ArrowRight";

    return [nextKey, previousKey];
  };

  findNextTab = (currentOpenPanelIndex, key) => {
    const allowedKeys = this.getPreviousNextKey();

    if (allowedKeys.includes(key)) {
      const nextIndex =
        currentOpenPanelIndex + (key === allowedKeys[0] ? 1 : -1); // eslint-disable-line no-magic-numbers
      const nextTab = this.state.tabItems[
        Math.min(this.state.tabItems.length - 1, nextIndex)
      ];

      return nextTab;
    }

    return null;
  };

  keyPressHandler = e => {
    const { tabItems, activeTab } = this.state;
    const currentOpenPanelIndex = this.getSelectedItemIndex(
      tabItems,
      activeTab
    );

    const nextTab = this.findNextTab(currentOpenPanelIndex, e.key);

    if (nextTab) {
      this.setActiveTab(nextTab.id);
    }
  };

  setActiveTab = id => {
    this.setState({
      activeTab: id
    });
  };

  render() {
    const { alignment, activeItem, items, isAjax } = this.props;
    const { activeTab, tabItems } = this.state;
    const tabs = tabItems.map(toggleItem => {
      return (
        <ToggleItem
          key={toggleItem.id}
          title={toggleItem.title}
          content={toggleItem.content}
          id={toggleItem.id}
          ariaLabel={toggleItem.ariaLabel}
          isActive={toggleItem.id === activeTab}
          onClick={this.clickHandler}
          onKeyDown={this.keyPressHandler}
        />
      );
    });

    const panels = tabItems.map(panel => {
      return (
        <AjaxPanel
          key={panel.id}
          id={panel.id}
          tabTitle={panel.title}
          contentLocation={panel.content}
        />
      );
    });

    return (
      <div
        className={this.getClassName()}
        data-rehydratable={getRehydratableName(ToggleButton.displayName)}
        data-alignment={alignment}
        data-activeitem={activeItem}
        data-items={JSON.stringify(items)}
        data-is-ajax={isAjax}
        ref={this.tabRef}
      >
        <div
          className={this.getClassName({
            descendantName: "list",
            modifiers: alignment
          })}
        >
          <div className={this.getClassName({ descendantName: "items" })}>
            {tabs}
          </div>
        </div>
        {isAjax && (
          <div className={this.getClassName({ descendantName: "panels" })}>
            <AjaxPanelProvider value={this.state.activeTab}>
              {panels}
            </AjaxPanelProvider>
          </div>
        )}
      </div>
    );
  }
}

ToggleButton.alignment = ["center", "left", "right"];

ToggleButton.activeItem = ["first", "second"];

ToggleButton.defaultProps = {
  alignment: "center",
  activeItem: "first",
  analytics: () => {}
};

export default ToggleButton;
