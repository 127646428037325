import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, boolean, text, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";

import Block from "@emcm-ui/component-block";
import { Language } from "@emcm-ui/component-theme";
import StaticRenderer from "@emcm-ui/component-static-renderer";

import Button from "@emcm-ui/component-button";
import SocialDropdown from "@emcm-ui/component-social-dropdown";
import SocialShare from "@emcm-ui/component-social-share";
import "@emcm-ui/component-button/lib/Button.css";
import { getSocialIcon } from "./icons";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

import PageNav from "./index";
import "./PageNav.css";
import PageNavREADME from "../README.md";

import Poster from "@emcm-ui/component-poster";
import Section from "@emcm-ui/component-section";
import Image, { anchorPoints } from "@emcm-ui/component-image";

const stories = storiesOf("Components/PageNav", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(PageNavREADME));

const socialDropdown = (
  <SocialDropdown>
    <SocialDropdown.Item>
      <SocialShare pageUrl="https://www.lseg.com" type="facebook">
        <SVGIcon name="facebook" size="s" getExternalIconPath={getSocialIcon} />
      </SocialShare>
    </SocialDropdown.Item>
    <SocialDropdown.Item>
      <SocialShare
        pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
        pageUrl="https://www.lseg.com"
        type="twitter"
      >
        <SVGIcon name="twitter" size="s" getExternalIconPath={getSocialIcon} />
      </SocialShare>
    </SocialDropdown.Item>
    <SocialDropdown.Item>
      <SocialShare
        pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
        pageUrl="https://www.lseg.com"
        type="linkedIn"
      >
        <SVGIcon name="linkedin" size="s" getExternalIconPath={getSocialIcon} />
      </SocialShare>
    </SocialDropdown.Item>
    <SocialDropdown.Item>
      <SocialShare
        pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
        pageUrl="https://www.lseg.com"
        type="weibo"
      >
        <SVGIcon name="weibo" size="s" getExternalIconPath={getSocialIcon} />
      </SocialShare>
    </SocialDropdown.Item>
    <SocialDropdown.Item>
      <SocialShare pageUrl="https://www.lseg.com" type="whatsApp">
        <SVGIcon name="whatsapp" size="s" getExternalIconPath={getSocialIcon} />
      </SocialShare>
    </SocialDropdown.Item>
    <SocialDropdown.Item>
      <SocialShare pageUrl="https://www.lseg.com" type="line">
        <SVGIcon name="line" size="s" getExternalIconPath={getSocialIcon} />
      </SocialShare>
    </SocialDropdown.Item>
    <SocialDropdown.Item>
      <SocialShare pageUrl="https://www.lseg.com" type="mixi">
        <SVGIcon name="mixi" size="s" getExternalIconPath={getSocialIcon} />
      </SocialShare>
    </SocialDropdown.Item>
    <SocialDropdown.Item>
      <SocialShare
        pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
        pageUrl="https://www.lseg.com"
        type="email"
      >
        <SVGIcon name="email" size="s" getExternalIconPath={getSocialIcon} />
      </SocialShare>
    </SocialDropdown.Item>
    <SocialDropdown.Item>
      <SocialShare type="print">
        <SVGIcon name="print" size="s" getExternalIconPath={getSocialIcon} />
      </SocialShare>
    </SocialDropdown.Item>
  </SocialDropdown>
);

class Demo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: ["Item One", "Item Two", "Item Three"],
      activeItem: "Item One"
    };
  }

  onClick = ({ item }) => {
    this.setState({
      activeItem: item
    });
  };

  getItems = () => {
    const { items, activeItem } = this.state;

    return items.map((item, index) => {
      return (
        <PageNav.Item
          active={item === activeItem}
          title={item}
          key={index}
          href=""
          onClick={this.onClick}
          datasets={JSON.stringify({ item })}
        />
      );
    });
  };

  render() {
    const { activeItem } = this.state;

    return (
      <div>
        <PageNav sticky={boolean("sticky", false)} spy={boolean("spy", false)}>
          {this.getItems()}
        </PageNav>

        <Block height="200px" text={`Active Item: ${activeItem}`} />
      </div>
    );
  }
}

stories
  .add("Default", () => (
    <div>
      <PageNav
        sticky={boolean("sticky", true)}
        actions={
          <Button block color="primary" kind="link">
            Register now
          </Button>
        }
      >
        <PageNav.Item
          title="PageNav Demo"
          href="#"
          datasets={`{"data-link-type":"stringOne","data-asset-title":"stringTwo","data-href-analytics":"stringThree","data-link-text":"stringFour","data-find-method":"stringFive"}`}
        />
        <PageNav.Item title="Item One" href="#item-one" />
        <PageNav.Item title="Item Two" href="#item-two" />
        <PageNav.Item title="Item Three" href="#item-three" />
        <PageNav.Item title="External" href="https://www.notvisited.com" />
      </PageNav>

      <Block height="200px" />
      <PageNav.Anchor id="item-one" />
      <Block height="1000px" color="green" />
      <PageNav.Anchor id="item-two" />
      <Block height="1000px" color="orange" />
      <PageNav.Anchor id="item-three" />
      <Block height="1000px" color="red" />
    </div>
  ))
  .add("Show Primary action on sticky", () => (
    <div>
      <Section
        background={
          select(
            "Section background",
            [null].concat(Section.backgrounds),
            "grayDark"
          ) || null
        }
        fullWidth
      >
        <Poster
          alignBottom={boolean("alignBottom", false)}
          height={select("height", Poster.heights, "xs")}
          imageAnchor={select("imageAnchor", anchorPoints, "C")}
          imageInlineAtNarrow={boolean("imageInlineAtNarrow", false)}
          imageOverlay={
            select("imageOverlay", [null].concat(Image.overlays)) || null
          }
          imageSrc={text("imageSrc", "test-images/16-9/large_9.jpg")}
          imageSrcSet={text(
            "imageSrcSet",
            "test-images/16-9/large_9.jpg 2000w, test-images/16-9/medium_9.jpg 800w, test-images/16-9/small_9.jpg 400w"
          )}
          share={boolean("share", false) && socialDropdown}
        >
          <Block color="red" height="6rem" text="children" />
        </Poster>
      </Section>
      <PageNav
        showPrimaryActionOnlyOnSticky={boolean(
          "showPrimaryActionOnlyOnSticky",
          true
        )}
        sticky={boolean("sticky", true)}
        actions={
          <Button block color="primary" kind="link">
            Register now
          </Button>
        }
      >
        <PageNav.Item title="PageNav Demo" href="#" />
        <PageNav.Item title="Item One" href="#item-one" />
        <PageNav.Item title="Item Two" href="#item-two" />
        <PageNav.Item title="Item Three" href="#item-three" />
        <PageNav.Item title="External" href="https://www.notvisited.com" />
      </PageNav>

      <Block height="200px" />
      <PageNav.Anchor id="item-one" />
      <Block height="1000px" color="green" />
      <PageNav.Anchor id="item-two" />
      <Block height="1000px" color="orange" />
      <PageNav.Anchor id="item-three" />
      <Block height="1000px" color="red" />
    </div>
  ))
  .add("Social sharing", () => (
    <div>
      <Section
        background={
          select(
            "Section background",
            [null].concat(Section.backgrounds),
            "grayDark"
          ) || null
        }
        fullWidth
      >
        <Poster
          alignBottom={boolean("alignBottom", false)}
          height={select("height", Poster.heights, "xs")}
          imageAnchor={select("imageAnchor", anchorPoints, "C")}
          imageInlineAtNarrow={boolean("imageInlineAtNarrow", false)}
          imageOverlay={
            select("imageOverlay", [null].concat(Image.overlays)) || null
          }
          imageSrc={text("imageSrc", "test-images/16-9/large_9.jpg")}
          imageSrcSet={text(
            "imageSrcSet",
            "test-images/16-9/large_9.jpg 2000w, test-images/16-9/medium_9.jpg 800w, test-images/16-9/small_9.jpg 400w"
          )}
          share={boolean("share", false) && socialDropdown}
        >
          <Block color="red" height="6rem" text="children" />
        </Poster>
      </Section>
      <PageNav
        showPrimaryActionOnlyOnSticky={boolean(
          "showPrimaryActionOnlyOnSticky",
          true
        )}
        sticky={boolean("sticky", true)}
        share={socialDropdown}
        shareLabel={text("shareLabel", "Share")}
      >
        <PageNav.Item title="PageNav Demo" href="#" />
        <PageNav.Item title="Item One" href="#item-one" />
        <PageNav.Item title="Item Two" href="#item-two" />
        <PageNav.Item title="Item Three" href="#item-three" />
        <PageNav.Item title="External" href="https://www.notvisited.com" />
      </PageNav>

      <Block height="200px" />
      <PageNav.Anchor id="item-one" />
      <Block height="1000px" color="green" />
      <PageNav.Anchor id="item-two" />
      <Block height="1000px" color="orange" />
      <PageNav.Anchor id="item-three" />
      <Block height="1000px" color="red" />
    </div>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer separateContainers={false}>
      <PageNav
        sticky={boolean("sticky", true)}
        actions={
          <Button block color="primary" kind="link">
            Register now
          </Button>
        }
      >
        <PageNav.Item
          title="PageNav Demo"
          href="#"
          datasets={`{"data-link-type":"stringOne","data-asset-title":"stringTwo","data-href-analytics":"stringThree","data-link-text":"stringFour","data-find-method":"stringFive"}`}
        />
        <PageNav.Item title="Item One" href="#item-one" />
        <PageNav.Item title="Item Two" href="#item-two" />
        <PageNav.Item title="Item Three" href="#item-three" />
        <PageNav.Item title="External" href="https://www.notvisited.com" />
      </PageNav>

      <Block height="200px" />
      <PageNav.Anchor id="item-one" />
      <Block height="1000px" color="green" />
      <PageNav.Anchor id="item-two" />
      <Block height="1000px" color="orange" />
      <PageNav.Anchor id="item-three" />
      <Block height="1000px" color="red" />
    </StaticRenderer>
  ))
  .add("Rehydrated with show primary action on sticky", () => (
    <StaticRenderer separateContainers={false}>
      <Section
        background={
          select(
            "Section background",
            [null].concat(Section.backgrounds),
            "grayDark"
          ) || null
        }
        fullWidth
      >
        <Poster
          alignBottom={boolean("alignBottom", false)}
          height={select("height", Poster.heights, "xs")}
          imageAnchor={select("imageAnchor", anchorPoints, "C")}
          imageInlineAtNarrow={boolean("imageInlineAtNarrow", false)}
          imageOverlay={
            select("imageOverlay", [null].concat(Image.overlays)) || null
          }
          imageSrc={text("imageSrc", "test-images/16-9/large_9.jpg")}
          imageSrcSet={text(
            "imageSrcSet",
            "test-images/16-9/large_9.jpg 2000w, test-images/16-9/medium_9.jpg 800w, test-images/16-9/small_9.jpg 400w"
          )}
          share={boolean("share", false) && socialDropdown}
        >
          <Block color="red" height="6rem" text="children" />
        </Poster>
      </Section>
      <PageNav
        sticky={boolean("sticky", true)}
        showPrimaryActionOnlyOnSticky={boolean(
          "showPrimaryActionOnlyOnSticky",
          true
        )}
        actions={
          <Button block color="primary" kind="link">
            Register now
          </Button>
        }
      >
        <PageNav.Item title="PageNav Demo" href="#" />
        <PageNav.Item title="Item One" href="#item-one" />
        <PageNav.Item title="Item Two" href="#item-two" />
        <PageNav.Item title="Item Three" href="#item-three" />
        <PageNav.Item title="External" href="https://www.notvisited.com" />
      </PageNav>

      <Block height="200px" />
      <PageNav.Anchor id="item-one" />
      <Block height="1000px" color="green" />
      <PageNav.Anchor id="item-two" />
      <Block height="1000px" color="orange" />
      <PageNav.Anchor id="item-three" />
      <Block height="1000px" color="red" />
    </StaticRenderer>
  ))
  .add("Rehydrated with social sharing", () => (
    <StaticRenderer separateContainers={false}>
      <Section
        background={
          select(
            "Section background",
            [null].concat(Section.backgrounds),
            "grayDark"
          ) || null
        }
        fullWidth
      >
        <Poster
          alignBottom={boolean("alignBottom", false)}
          height={select("height", Poster.heights, "xs")}
          imageAnchor={select("imageAnchor", anchorPoints, "C")}
          imageInlineAtNarrow={boolean("imageInlineAtNarrow", false)}
          imageOverlay={
            select("imageOverlay", [null].concat(Image.overlays)) || null
          }
          imageSrc={text("imageSrc", "test-images/16-9/large_9.jpg")}
          imageSrcSet={text(
            "imageSrcSet",
            "test-images/16-9/large_9.jpg 2000w, test-images/16-9/medium_9.jpg 800w, test-images/16-9/small_9.jpg 400w"
          )}
          share={boolean("share", false) && socialDropdown}
        >
          <Block color="red" height="6rem" text="children" />
        </Poster>
      </Section>
      <PageNav
        sticky={boolean("sticky", true)}
        showPrimaryActionOnlyOnSticky={boolean(
          "showPrimaryActionOnlyOnSticky",
          true
        )}
        share={socialDropdown}
        shareLabel={text("shareLabel", "Share")}
      >
        <PageNav.Item title="PageNav Demo" href="#" />
        <PageNav.Item title="Item One" href="#item-one" />
        <PageNav.Item title="Item Two" href="#item-two" />
        <PageNav.Item title="Item Three" href="#item-three" />
        <PageNav.Item title="External" href="https://www.notvisited.com" />
      </PageNav>

      <Block height="200px" />
      <PageNav.Anchor id="item-one" />
      <Block height="1000px" color="green" />
      <PageNav.Anchor id="item-two" />
      <Block height="1000px" color="orange" />
      <PageNav.Anchor id="item-three" />
      <Block height="1000px" color="red" />
    </StaticRenderer>
  ))
  .add("Rehydrated, no actions", () => (
    <StaticRenderer separateContainers={false}>
      <PageNav sticky={boolean("sticky", true)}>
        <PageNav.Item title="PageNav Demo" href="#" />
        <PageNav.Item title="Item One" href="#item-one" />
        <PageNav.Item title="Item Two" href="#item-two" />
        <PageNav.Item title="Item Three" href="#item-three" />
        <PageNav.Item title="External" href="https://www.notvisited.com" />
      </PageNav>

      <Block height="200px" />
      <PageNav.Anchor id="item-one" />
      <Block height="1000px" color="green" />
      <PageNav.Anchor id="item-two" />
      <Block height="1000px" color="orange" />
      <PageNav.Anchor id="item-three" />
      <Block height="1000px" color="red" />
    </StaticRenderer>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <PageNav
        sticky={boolean("sticky", true)}
        actions={
          <Button block color="primary" kind="link">
            سجل الان
          </Button>
        }
      >
        <PageNav.Item title="برهنة" href="#" />
        <PageNav.Item title="البند الاول" href="#item-one" />
        <PageNav.Item title="البند الثاني" href="#item-two" />
        <PageNav.Item title="البند الثالث" href="#item-three" />
        <PageNav.Item title="خارجي" href="https://www.notvisited.com" />
      </PageNav>

      <Block height="200px" />
      <PageNav.Anchor id="item-one" />
      <Block height="1000px" color="green" />
      <PageNav.Anchor id="item-two" />
      <Block height="1000px" color="orange" />
      <PageNav.Anchor id="item-three" />
      <Block height="1000px" color="red" />
    </Language>
  ))
  .add("Default with click", () => <Demo />);
