import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import TagCloud from "@emcm-ui/component-tag-cloud";
import { getRequestParameter } from "../../utilities/filterRequestParameters";

const TagFilters = ({ tagCloud, search, tagsLoading }) => {
  const getClassName = getClassNameFactory("SearchResultsMixture");
  const {
    tagFilters,
    selectedTags,
    showMore,
    showLess,
    tagHeading,
    clearAllText,
    onTagSelect,
    clearTagSelection
  } = tagCloud;
  const { hasSearchTerm } = search;
  const shouldShowTagCloud =
    tagFilters && tagFilters.length > 0 && !tagsLoading;

  if (!shouldShowTagCloud) {
    return null;
  }
  const showHeading = hasSearchTerm;

  const handleTagSelect = key => {
    const isTagSelected = selectedTags.includes(key);
    let updatedTags;

    if (isTagSelected) {
      const index = selectedTags.indexOf(key);

      updatedTags = [
        ...selectedTags.slice(0, index),
        ...selectedTags.slice(index + 1)
      ];
    } else {
      updatedTags = [...selectedTags, key];
    }

    if (onTagSelect) {
      const requestParameters = getRequestParameter(
        tagFilters,
        key,
        updatedTags
      );
      const response = {
        updatedTags,
        isTagSelected,
        ...requestParameters
      };

      onTagSelect(response);
    }
  };

  return (
    <div
      className={getClassName({
        descendantName: "tag-cloud"
      })}
    >
      <TagCloud
        showMore={showMore}
        showLess={showLess}
        clearAllText={clearAllText}
        tagList={tagFilters}
        selectedTags={selectedTags}
        heading={showHeading ? tagHeading : ""}
        onClick={handleTagSelect}
        clearAll={clearTagSelection}
      />
    </div>
  );
};

TagFilters.displayName = "SearchResults.TagFilters";

TagFilters.propTypes = {
  /**
   * tag cloud information
   */
  tagCloud: PropTypes.object,
  /**
   * search information
   */
  search: PropTypes.object,
  /**
   * boolean value to show tags loading
   */
  tagsLoading: PropTypes.bool
};

export default TagFilters;
