import PropTypes from "prop-types";
import React, { Component } from "react";
import unionBy from "lodash.unionby";

import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

import { Provider as StockTickerContextProvider } from "./StockTickerContext";
import Carousel from "./components/Carousel/Carousel";
import TickerContinuous from "./components/Ticker/TickerContinuous";

const DATE_FORMAT = "MMM dd, yyyy";
const TIME_FORMAT = "HH:mm";

class StockTicker extends Component {
  constructor(props) {
    super(props);
    this.itemsRef = React.createRef();
    this.INTERVAL_TIME = 60000;
    this.PERCENTAGE = 100;
    this.MAX_MOBILE_WIDTH = 768;
    this.fetchInterval = null;
    this.isUnMounted = false;
    this.state = {
      data: {},
      isMobile: window.innerWidth < this.MAX_MOBILE_WIDTH
    };
  }

  static displayName = "StockTicker";

  static propTypes = {
    /**
     *To get list of share prices data by api
     */
    apiUrl: PropTypes.string,
    /**
     *List of index whose share price to be fetched
     */
    indexCodes: PropTypes.array,
    /**
     * To set the background color
     */
    backgroundType: PropTypes.string,
    /**
     * To set the delay for total scroll
     */
    scrollDelay: PropTypes.number,
    /**
     * To set the aria label for if no price change. It requires placeholders like
     * {code} -> Will replace by share price name
     * {price} -> will replace by share price last value
     * {currency} -> will replace by share price currency
     * {date} -> will replace by last updated date and time
     */
    staleAriaLabel: PropTypes.string,
    /**
     * To set the aria label for if increase in share price change. It requires placeholder like
     * {code} -> Will replace by share price name
     * {netChange} -> will replace by share price change from last value
     * {percentualChange} -> will replace by percentage of share price change from last value
     * {price} -> will replace by share price last value
     * {currency} -> will replace by share price currency
     * {date} -> will replace by last updated date and time
     */
    increasedAriaLabel: PropTypes.string,
    /**
     * To set the aria label for if decrease in price change. It requires placeholder like
     * {code} -> Will replace by share price name
     * {netChange} -> will replace by share price change from last value
     * {percentualChange} -> will replace by percentage of share price change from last value
     * {price} -> will replace by share price last value
     * {currency} -> will replace by share price currency
     * {date} -> will replace by last updated date and time
     */
    decreasedAriaLabel: PropTypes.string,
    /**
     * To set the aria label for previous stock navigation button
     */
    previousAriaLabel: PropTypes.string,
    /**
     * To set the aria label for next stock navigation button
     */
    nextAriaLabel: PropTypes.string,
    /**
     * To set the aria label for play button
     */
    playAriaLabel: PropTypes.string,
    /**
     * To set the aria label for pause button
     */
    pauseAriaLabel: PropTypes.string,
    /**
     * Label to display for skip link accessibility button
     */
    skipLinkLabel: PropTypes.string,
    /**
     * Target element need to focus after skip focus
     */
    skipLinkTargetElement: PropTypes.string,
    /**
     * Hide the date in the stock
     */
    hideDate: PropTypes.bool,
    /**
     * Hide the time in the stock
     */
    hideTime: PropTypes.bool
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
    const { apiUrl, indexCodes } = this.props;

    if (apiUrl && indexCodes) {
      this.processStockPriceData();
      clearInterval(this.fetchInterval);
      this.fetchInterval = setInterval(
        this.processStockPriceData,
        this.INTERVAL_TIME
      );
    }
  }

  componentWillUnmount() {
    this.isUnMounted = true;
    clearInterval(this.fetchInterval);
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({
      isMobile: window.innerWidth < this.MAX_MOBILE_WIDTH
    });
  };

  parseToNumber = str => {
    return parseFloat(str.replaceAll(",", ""));
  };

  fetchStockPrice = async () => {
    const { indexCodes, apiUrl } = this.props;

    try {
      const body = { id: indexCodes.map(index => index.indexCode) };

      const response = await fetch(apiUrl, {
        method: "POST",
        body: JSON.stringify(body)
      });

      if (!response.ok) {
        throw new Error("Failed to fetch stock price data");
      }

      const responseData = await response.json();

      return responseData.Data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  processStockPriceData = async () => {
    try {
      const data = await this.fetchStockPrice();
      const shareDetails = this.createTickerInfo(data);

      const updatedShareDetails = unionBy(
        shareDetails,
        this.state.shareDetails,
        "indexDetails.indexCode"
      );

      if (!this.isUnMounted) {
        this.setState({
          shareDetails: updatedShareDetails,
          data
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
  createTickerInfo = data => {
    return data.map(
      ({
        LastValue,
        Change,
        FamilyPageCode,
        IndexName,
        UTCTimeStamp,
        IndexCode,
        Currency
      }) => {
        const price = this.parseToNumber(LastValue);
        const netChange = this.parseToNumber(Change);
        const calcNetChange = netChange / (price + netChange);
        const percentualChange = calcNetChange * this.PERCENTAGE || 0;
        const dateFormat = this.getDateFormat();

        return {
          price,
          netChange,
          percentualChange,
          familyPageCode: FamilyPageCode,
          code: IndexName,
          indexDetails: this.props.indexCodes.find(
            index => index.indexCode === IndexCode
          ),
          ...(dateFormat && { time: UTCTimeStamp }),
          ...(Currency && { currency: Currency })
        };
      }
    );
  };

  getClassName = getClassNameFactory(StockTicker.displayName);

  renderChild = () => {
    const { isMobile } = this.state;
    const langDirection = getComputedStyle(this.itemsRef.current).direction;

    if (isMobile) {
      return <Carousel langDirection={langDirection} />;
    }

    return <TickerContinuous />;
  };

  getDateFormat = () => {
    const { hideDate, hideTime } = this.props;

    if (hideDate && hideTime) {
      return "";
    }
    if (hideDate) {
      return TIME_FORMAT;
    }
    if (hideTime) {
      return DATE_FORMAT;
    }

    return `${DATE_FORMAT}, ${TIME_FORMAT}`;
  };

  render() {
    const {
      backgroundType,
      scrollDelay,
      indexCodes,
      apiUrl,
      staleAriaLabel,
      decreasedAriaLabel,
      increasedAriaLabel,
      playAriaLabel,
      pauseAriaLabel,
      previousAriaLabel,
      nextAriaLabel,
      skipLinkLabel,
      skipLinkTargetElement,
      hideDate,
      hideTime
    } = this.props;

    const { shareDetails } = this.state;

    const dateFormat = this.getDateFormat();

    const contextValue = {
      backgroundType,
      scrollDelay,
      dateFormat,
      shareDetails,
      staleAriaLabel,
      playAriaLabel,
      pauseAriaLabel,
      decreasedAriaLabel,
      increasedAriaLabel,
      previousAriaLabel,
      nextAriaLabel,
      skipLinkLabel,
      skipLinkTargetElement,
      hideTime
    };

    return (
      <div
        className={this.getClassName()}
        data-rehydratable={getRehydratableName(StockTicker.displayName)}
        data-api-url={apiUrl}
        data-index-codes={JSON.stringify(indexCodes)}
        data-scroll-delay={scrollDelay}
        data-background-type={backgroundType}
        data-stale-aria-label={staleAriaLabel}
        data-increased-aria-label={increasedAriaLabel}
        data-decreased-aria-label={decreasedAriaLabel}
        data-play-aria-label={playAriaLabel}
        data-pause-aria-label={pauseAriaLabel}
        data-previous-aria-label={previousAriaLabel}
        data-next-aria-label={nextAriaLabel}
        data-skip-link-label={skipLinkLabel}
        data-skip-link-target-element={skipLinkTargetElement}
        data-hide-date={hideDate}
        data-hide-time={hideTime}
        ref={this.itemsRef}
      >
        {shareDetails && (
          <StockTickerContextProvider value={contextValue}>
            {this.renderChild()}
          </StockTickerContextProvider>
        )}
      </div>
    );
  }
}

StockTicker.defaultProps = {
  backgroundType: "dark",
  staleAriaLabel:
    "{code} stock has no change to {price} {currency}. Updated on {date}.",
  increasedAriaLabel:
    "{code} stock has increased by {netChange} or {percentualChange} percent to {price} {currency}. Updated on {date}.",
  decreasedAriaLabel:
    "{code} stock has decreased by {netChange} or {percentualChange} percent to {price} {currency}. Updated on {date}.",
  skipLinkLabel: "Skip ticker",
  hideDate: false,
  hideTime: false
};

export default StockTicker;
