import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import Badge from "./index";
import "./Badge.css";
import BadgeREADME from "../README.md";

const stories = storiesOf("Components/Badge", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(BadgeREADME));

const type = Badge.type;

const color = Badge.color;

const background = Badge.background;

stories
  .add("Default", () => (
    <Badge
      text={text("Text", "BADGE")}
      type={select("Type", type, "badge")}
      background={select("Background", background, "dark")}
      color={select("Use Cases", color, "blue")}
    />
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Badge
        text={text("Text", "مرحبا بالعالم")}
        type={select("Type", type, "tag")}
        background={select("Background", background, "light")}
        color={select("Color", color, "green")}
      />
    </Language>
  ));
