import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text, object } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import FloatingBadge from "./index";
import "./FloatingBadge.css";
import FloatingBadgeREADME from "../README.md";

const stories = storiesOf("Components/FloatingBadge", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(FloatingBadgeREADME));

const localhostURL = `${window.location.protocol}//${
  window.location.hostname
}:${window.location.port}`;
const localEmbedURL = `${localhostURL}/floatingbadge.js`;
const defaultValue = {
  icon: "./icons/ui/white/questionmark.svg",
  tooltip: "tooltip text",
  landpageUrl:
    "https://forge-testing.vercel.app/components/floating-badge/demo-page.html",
  sourceSystem: "Ecomm",
  headerText: "Help and Console",
  productName: "Eikon",
  type: "Presale",
  clientID: "12345",
  embedUrl: localEmbedURL
};

const sourceMetadata = {
  productCode: "01ttty56Xs",
  parentBilling: "A-1234567"
};

stories
  .add("Default", () => (
    <FloatingBadge
      icon={text("Icon", defaultValue.icon)}
      tooltipText={text("Tooltip", defaultValue.tooltip)}
      landpageUrl={text("Land Page URL", defaultValue.landpageUrl)}
      sourceSystem={text("Source System", defaultValue.sourceSystem)}
      headerText={text("Header Text", defaultValue.headerText)}
      productName={text("Product Name", defaultValue.productName)}
      type={text("Type", defaultValue.type)}
      clientID={text("Client ID", defaultValue.clientID)}
      embedUrl={text("EmbedURL", defaultValue.embedUrl)}
      sourceMetadata={object("Source Meta Data", sourceMetadata)}
    />
  ))

  .add("Rehydrated", () => (
    <StaticRenderer>
      <FloatingBadge
        icon={text("Icon", defaultValue.icon)}
        tooltipText={text("Tooltip", defaultValue.tooltip)}
        landpageUrl={text("Land Page URL", defaultValue.landpageUrl)}
        sourceSystem={text("Source System", defaultValue.sourceSystem)}
        headerText={text("Header Text", defaultValue.headerText)}
        productName={text("Product Name", defaultValue.productName)}
        type={text("Type", defaultValue.type)}
        clientID={text("Client ID", defaultValue.clientID)}
        embedUrl={text("EmbedURL", defaultValue.embedUrl)}
        sourceMetadata={object("Source Meta Data", sourceMetadata)}
      />
    </StaticRenderer>
  ));
