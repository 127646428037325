import React from "react";
import Header from "../Header";
import Item from "./Item";
import getClassNameFactory from "@emcm-ui/utility-class-names";

export default async (domNode, rehydrateChildren) => {
  const getClassName = getClassNameFactory(Item.displayName);
  const className = getClassName({ descendantName: "content" });
  const childrenNode = domNode.querySelector(`.${className}`);
  const reactifiedChildren = await rehydrateChildren(childrenNode);

  /*
  * Reach insisde Header subcomponent in order to rehydrate the children of
  * it's internal Heading component.
  */
  const getHeaderClassName = getClassNameFactory(Header.displayName);
  const headingInnerClassName = getHeaderClassName({
    descendantName: "headingInner"
  });
  const headingInnerNode = domNode.querySelector(`.${headingInnerClassName}`);
  const reactifiedHeadingInner = await rehydrateChildren(headingInnerNode);

  const rehydrationProps = {
    isExpanded: domNode.getAttribute("data-is-expanded") === "true" || false,
    headingRank: domNode.getAttribute("data-heading-rank") || null,
    title: reactifiedHeadingInner,
    direction: domNode.getAttribute("data-direction"),
    expandedTitle: domNode.getAttribute("data-expanded-title"),
    type: domNode.getAttribute("data-type"),
    noTopPadding: JSON.parse(domNode.getAttribute("data-no-top-padding"))
  };

  return <Item {...rehydrationProps}>{reactifiedChildren}</Item>;
};
