import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Link from "@emcm-ui/component-link";
import Anchor from "@emcm-ui/component-anchor";

import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

const AccessibilityLink = ({
  href,
  label,
  position,
  targetElement,
  backgroundType
}) => {
  const getClassName = getClassNameFactory(AccessibilityLink.displayName);

  const handleTargetScroll = () => {
    const element = document.getElementsByClassName(targetElement);

    if (element && element.length > 0) {
      element[0].scrollIntoView();
      element[0].setAttribute("tabindex", "-1");
      element[0].focus();
    }
  };

  return (
    <div
      className={getClassName()}
      data-rehydratable={getRehydratableName(AccessibilityLink.displayName)}
      data-target-element={targetElement}
      data-href={href}
      data-label={label}
      data-position={position}
      data-background-type={backgroundType}
    >
      <div
        className={getClassName({
          descendantName: position
        })}
      >
        {targetElement ? (
          <Anchor
            className={getClassName({
              descendantName: "link",
              modifiers: backgroundType
            })}
            kind="button"
            onClick={handleTargetScroll}
          >
            {label}
          </Anchor>
        ) : (
          <Link
            customClassName={getClassName({
              descendantName: "link",
              modifiers: backgroundType
            })}
            href={href}
          >
            {label}
          </Link>
        )}
      </div>
    </div>
  );
};

AccessibilityLink.displayName = "AccessibilityLink";

AccessibilityLink.positions = ["left", "right"];

AccessibilityLink.backgroundType = ["light", "dark"];

AccessibilityLink.defaultProps = {
  position: "left",
  backgroundType: "dark"
};

AccessibilityLink.propTypes = {
  /**
   * Link target
   */
  href: PropTypes.string,
  /**
   * Link display label
   */
  label: PropTypes.string.isRequired,
  /**
   * Link display position
   */
  position: PropTypes.string,
  /**
   * Target element
   */
  targetElement: PropTypes.string,
  /**
   * Skip link button background theme
   */
  backgroundType: PropTypes.oneOf(AccessibilityLink.backgroundType)
};

export default AccessibilityLink;
