import {
  ANALYTICS_APPLY_FILTER,
  ANALYTICS_PAGE_LOAD,
  ANALYTICS_TAB_CHANGE,
  FILTER_TYPE,
  SEARCH_LAYOUT,
  SEARCH_TYPE,
  THEMES,
  TOPICS
} from "./constants";

const hasAnalyticsEnabled = () => {
  return typeof window !== "undefined" && window.digitalData && window.PubSub;
};

const triggerEvent = event => {
  if (hasAnalyticsEnabled()) {
    window.PubSub.publish(event, window.digitalData);
  }
};

export const trackFilters = (activeFilter, selectedTags) => {
  const allFilters = {
    [THEMES]: activeFilter,
    [TOPICS]: selectedTags.join(",")
  };
  const filters = [];

  Object.entries(allFilters).forEach(([key, value]) => {
    if (value.length > 0) {
      filters.push({
        filterType: FILTER_TYPE,
        filterName: key,
        filterValue: value
      });
    }
  });

  if (hasAnalyticsEnabled()) {
    window.digitalData.page = {
      ...window.digitalData.page,
      ...{ filters }
    };
    triggerEvent(ANALYTICS_APPLY_FILTER);
  }
};

export const trackSearch = (searchResults, searchTerm) => {
  if (hasAnalyticsEnabled()) {
    window.digitalData = {
      ...window.digitalData,
      ...{
        search: {
          searchResults,
          layout: SEARCH_LAYOUT,
          searchTerm,
          searchType: SEARCH_TYPE,
          pageNum: 1
        }
      }
    };
    triggerEvent(ANALYTICS_PAGE_LOAD);
  }
};

export const trackPageNumber = page => {
  if (hasAnalyticsEnabled() && window.digitalData.page) {
    const pageInfo = {
      ...window.digitalData.page.pageInfo,
      pageNumber: page
    };

    window.digitalData.page.pageInfo = pageInfo;
    triggerEvent(ANALYTICS_TAB_CHANGE);
  }
};
