import React, { Component } from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

import { formConsts } from "../../FormConstant";

import { toggleInvalidClass } from "../../utilities";

class RadioControl extends Component {
  static displayName = "FormNew.RadioControl";

  static propTypes = {
    /**
     * Initialize with selected <input>
     */
    defaultValue: PropTypes.string,
    /**
     * Disable all <input> elements
     */
    disabled: PropTypes.bool,
    /**
     * Name attribute for <input> elements
     */
    name: PropTypes.string.isRequired,
    /**
     * Array of objects with `label` and `value` properties
     */
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        onChange: PropTypes.func,
        value: PropTypes.string.isRequired,
        ref: PropTypes.object
      })
    ).isRequired,
    /**
     * Set <input> to be required
     */
    required: PropTypes.bool,
    /**
     * tabIndex attribute
     */
    tabIndex: PropTypes.string,
    /**
     * Explicitly set an input as selected. Will switch into controlled mode.
     */
    value: PropTypes.string
  };

  static defaultProps = {
    disabled: false,
    name: formConsts.radioControlName,
    options: [
      { label: "Radio label", value: "daily" },
      { label: "Radio label", value: "weekly" },
      { label: "Radio label", value: "monthly" }
    ],
    required: true,
    tabIndex: formConsts.tabindex
  };

  constructor(props) {
    super(props);
    this.getClassName = getClassNameFactory(RadioControl.displayName);

    this.state = {
      hasError: false
    };
  }

  updateState = (e, onChange) => {
    if (onChange) {
      return onChange(e);
    }

    if (e.target.checked) {
      this.setState({ hasError: false });
    }
  };

  updateClassName = options => {
    options.map(option => {
      option.ref.current.className = this.getClassName({
        descendantName: "input"
      });

      return options;
    });
  };

  render() {
    const {
      defaultValue,
      disabled,
      name,
      options,
      required,
      tabIndex,
      value
    } = this.props;

    return (
      <div
        className={toggleInvalidClass(
          this.getClassName(),
          this.props.required,
          this.state.hasError
        )}
      >
        {options.map(option => {
          const props = {};

          if (value) {
            // Controlled mode
            props.checked = option.value === value;
          }

          if (defaultValue) {
            // Uncontrolled mode
            props.defaultChecked = option.value === defaultValue;
          }

          return (
            <label
              htmlFor={option.value}
              key={option.value}
              aria-label={`${name}.`}
            >
              <input
                disabled={disabled}
                id={option.value}
                name={name}
                onChange={e => {
                  this.updateState(e, option.onChange);
                  this.updateClassName(options);
                }}
                required={required}
                tabIndex={tabIndex}
                type="radio"
                value={option.value}
                {...props}
                ref={(option.ref = React.createRef())}
              />
              <span
                className={this.getClassName({
                  descendantName: "labelText",
                  className: typestack("p1")
                })}
              >
                {option.label}
              </span>
            </label>
          );
        })}
      </div>
    );
  }
}

export default RadioControl;
