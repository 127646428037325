import React, { Component } from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import classNames from "classnames";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

class Tab extends Component {
  static propTypes = {
    /**
     * Optional modifier to switch layout variants
     */
    layoutVariant: PropTypes.oneOf(["verticalAtDesktop", "wrapped"]),

    /**
     * Event handler for clicking this tab
     */
    onClick: PropTypes.func,

    /**
     * Event handler for pressing a key while focused on this tab
     */
    onKeyDown: PropTypes.func,

    /**
     * The title to display on this tab
     */
    title: PropTypes.string.isRequired,

    /**
     * The HTML ID of the associated tab panel
     */
    relatedId: PropTypes.string.isRequired,

    /**
     * Whether or not this tab is the currently selected tab
     */
    selected: PropTypes.bool,

    /**
     * The content location of the related Panel, to AJAX in content
     */
    contentLocation: PropTypes.string,

    /**
     * The page location of the related Panel content for AJAX content fallback
     */
    pageLocation: PropTypes.string
  };

  static defaultProps = {
    onClick: () => {},
    onKeyDown: () => {}
  };

  static displayName = "Tabs.Tab";

  /**
   * Get Tab props from DOM node, used by Tabs component rehydration.
   */
  static getTabsProps = domNode => {
    return {
      contentLocation: domNode.getAttribute("data-content-location"),
      pageLocation: domNode.getAttribute("data-page-location"),
      relatedId: domNode.getAttribute("data-related-id"),
      title: domNode.getAttribute("data-title")
    };
  };

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleClick(e) {
    this.props.onClick(e, this.props.relatedId);
  }

  handleKeyDown(e) {
    this.props.onKeyDown(e);
  }

  componentDidUpdate(oldProps) {
    // When a tab is selected, make it gain the focus. This ensures that a
    // keyboard user doesn't get lost on the page. In particular, it covers the
    // case of focusing the tab when switching tabs with arrow keys.
    if (this.anchorRef && !oldProps.selected && this.props.selected) {
      this.anchorRef.focus();
    }
  }

  render() {
    const {
      contentLocation,
      layoutVariant,
      pageLocation,
      relatedId,
      selected,
      title
    } = this.props;
    const getClassName = getClassNameFactory("TabsTab");

    const anchorProps = {
      className: getClassName({
        descendantName: "link",
        className:
          layoutVariant === "verticalAtDesktop"
            ? typestack("p1")
            : typestack("p1Bold")
      }),
      href: pageLocation || `#${relatedId}`,
      onClick: this.handleClick,
      onKeyDown: this.handleKeyDown,
      id: `tab-${relatedId}`,
      role: "tab"
    };

    if (selected) {
      anchorProps["aria-selected"] = true;
    } else {
      anchorProps.tabIndex = "-1";
    }

    return (
      <li
        role="presentation"
        className={getClassName({
          modifiers: classNames(layoutVariant),
          states: classNames({
            selected
          })
        })}
        data-content-location={contentLocation}
        data-page-location={pageLocation}
        data-related-id={relatedId}
        data-title={title}
      >
        {/*
          * Note on target="_self": this prevents a bug in Storybook which
          * would navigate outside of the main frame. However, there is no
          * circumstance where we *wouldn't* want the target to be `_self`,
          * since tabs are always on the current page. So it's desireable
          * behaviour anyway.
          */}
        <a {...anchorProps} ref={ref => (this.anchorRef = ref)} target="_self">
          {title}
          {layoutVariant === "verticalAtDesktop" && (
            <span className={getClassName({ descendantName: "icon" })}>
              <SVGIcon name="caret" size="s" />
            </span>
          )}
        </a>
      </li>
    );
  }
}

export default Tab;
