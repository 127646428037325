import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";
import FormsNewStyle from "@emcm-ui/component-forms-new-style";

class CartSummary extends Component {
  static displayName = "CartSummary";

  static propTypes = {
    /**
     * Children node
     */
    children: PropTypes.node,
    /**
     * Price for the product
     */
    priceLabel: PropTypes.string.isRequired,
    /**
     * License text for the product
     */
    licenseLabel: PropTypes.string.isRequired,
    /**
     * License unit for the product
     */
    licenseUnit: PropTypes.string.isRequired,
    /**
     * Array of products object
     */
    products: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        licenseCount: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
        additionalText: PropTypes.string,
        isSelected: PropTypes.bool
      })
    ).isRequired,
    /**
     * Timeframe for the license of the product.
     */
    timeframe: PropTypes.string.isRequired,
    /**
     * RadioControl variant
     */
    isRadio: PropTypes.bool,
    /**
     * Function to capture cart item details
     */
    exportCartItem: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedId: this.getSelectedIdFromProps(this.props.products)
    };
    this.listItemRefs = {};
    this.getClassName = getClassNameFactory(CartSummary.displayName);
  }

  getSelectedIdFromProps = products => {
    const product = products.find(item => item.isSelected === true);

    return product && product.id;
  };

  componentDidMount() {
    const { isRadio } = this.props;
    const { selectedId } = this.state;

    if (isRadio) {
      this.exportCartItem(selectedId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isRadio } = this.props;
    const { selectedId } = this.state;

    if (isRadio && prevState.selectedId !== selectedId) {
      const selectedItemRef = this.listItemRefs[selectedId];

      if (selectedItemRef && selectedItemRef.current) {
        selectedItemRef.current.focus();
      }
    }
  }

  handleListItemClick = productId => {
    this.setSelectedProductId(productId);
  };

  handleKeyPress = (event, productId) => {
    const { products } = this.props;
    const length = products.length;
    const currentIndex = products.findIndex(
      product => product.id === productId
    );

    switch (event.key) {
      case "ArrowRight":
      case "ArrowDown": {
        const nextIndex = (currentIndex + 1) % length;

        this.setSelectedProductId(products[nextIndex].id);
        break;
      }
      case "ArrowLeft":
      case "ArrowUp": {
        const prevIndex = (currentIndex - 1 + length) % length;

        this.setSelectedProductId(products[prevIndex].id);
        break;
      }
    }
  };

  setSelectedProductId = productId => {
    const { selectedId } = this.state;

    if (productId !== selectedId) {
      this.setState(
        {
          selectedId: productId
        },
        () => {
          this.exportCartItem(productId);
        }
      );
    }
  };

  exportCartItem = productId => {
    const { products, exportCartItem } = this.props;
    const product = products.find(item => item.id === productId);

    if (exportCartItem) {
      exportCartItem(product);
    }
  };

  render() {
    const {
      priceLabel,
      licenseLabel,
      licenseUnit,
      products,
      timeframe,
      isRadio
    } = this.props;
    const { selectedId } = this.state;
    const role = isRadio ? "list" : "presentation";

    const productList = products.map(item => {
      const checked = item.id === selectedId;
      const totalPrice = item.licenseCount * item.price;
      const additionalTextAriaLabel = item.additionalText
        ? `${item.additionalText}.`
        : "";
      const timeframeAriaLabel = `${timeframe}. ${item.currency}${
        item.price
      } ${licenseUnit}. ${additionalTextAriaLabel}`;

      this.listItemRefs[item.id] = React.createRef();

      const radioControl = isRadio && (
        <div
          className={this.getClassName({
            descendantName: classNames("radioContainer")
          })}
        >
          <FormsNewStyle.RadioControl
            disabled={false}
            name={item.name}
            options={[
              {
                label: "",
                value: item.id,
                onChange: null
              }
            ]}
            value={selectedId}
            tabIndex="-1"
          />
        </div>
      );

      const additionalText = item.additionalText && (
        <p
          className={this.getClassName({
            descendantName: classNames("additional"),
            className: typestack("p2")
          })}
          aria-label={`${item.additionalText}.`}
        >
          {item.additionalText}
        </p>
      );

      const itemProps = {
        className: this.getClassName({
          descendantName: classNames("productlist"),
          states:
            isRadio &&
            classNames({
              checked
            })
        }),
        key: item.id,
        role: isRadio ? "listitem" : "presentation",
        ref: isRadio && this.listItemRefs[item.id],
        onClick: isRadio ? () => this.handleListItemClick(item.id) : null,
        onKeyDown: isRadio ? e => this.handleKeyPress(e, item.id) : null,
        ...(isRadio && {
          "aria-current": checked,
          tabIndex: checked ? "0" : "-1"
        })
      };

      const qtyAriaLabel = `${item.licenseCount} ${licenseLabel},`;

      return (
        <li {...itemProps}>
          {radioControl}
          <div
            className={this.getClassName({
              descendantName: classNames("productInfo")
            })}
          >
            <div
              className={this.getClassName({
                descendantName: classNames("product")
              })}
            >
              <div
                className={this.getClassName({
                  descendantName: classNames("name"),
                  className: typestack("h3")
                })}
                {...(isRadio
                  ? { "aria-hidden": true }
                  : { "aria-label": `${item.name},` })}
              >
                {item.name}
              </div>
              <div
                className={this.getClassName({
                  descendantName: classNames("count"),
                  className: typestack("p2Bold")
                })}
                aria-label={qtyAriaLabel}
              >
                {item.licenseCount}
              </div>
              <div
                className={this.getClassName({
                  descendantName: classNames("amount"),
                  className: typestack("p1Bold")
                })}
                aria-label={`${priceLabel} ${item.currency} ${totalPrice}.`}
              >
                {item.currency}
                {totalPrice}
              </div>
            </div>
            <div aria-label={timeframeAriaLabel}>
              <div>
                <span
                  className={this.getClassName({
                    descendantName: classNames("timeframe"),
                    className: typestack("h5")
                  })}
                >
                  {timeframe}
                </span>
                <span
                  className={this.getClassName({
                    descendantName: classNames("itemprice"),
                    className: typestack("p1")
                  })}
                >
                  {item.currency}
                  {item.price} {licenseUnit}
                </span>
              </div>
              {additionalText}
            </div>
          </div>
        </li>
      );
    });

    return (
      <div
        className={this.getClassName({
          modifiers: classNames({ isRadio })
        })}
        data-rehydratable={getRehydratableName(CartSummary.displayName)}
        data-price-label={priceLabel}
        data-license-label={licenseLabel}
        data-license-unit={licenseUnit}
        data-timeframe={timeframe}
        data-products={JSON.stringify(products)}
        data-radio-variant={isRadio}
      >
        <div
          className={this.getClassName({
            descendantName: classNames("header")
          })}
          aria-label={CartSummary.displayName}
        >
          <p
            className={this.getClassName({
              descendantName: classNames("license"),
              className: typestack("p1Bold")
            })}
          >
            {licenseLabel}
          </p>
          <p
            className={this.getClassName({
              descendantName: classNames("price"),
              className: typestack("p1Bold")
            })}
          >
            {priceLabel}
          </p>
        </div>
        <ul role={role}>{productList}</ul>
      </div>
    );
  }
}

CartSummary.defaultProps = {
  priceLabel: "Price",
  licenseLabel: "Licenses",
  licenseUnit: "per license",
  products: [],
  timeframe: "Monthly subscription:",
  isRadio: false
};

export default CartSummary;
