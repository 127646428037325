import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import DateTime from "@emcm-ui/component-date-time";
import Eyebrow from "@emcm-ui/component-eyebrow";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

import ArticleInfoFooter from "./components/ArticleInfoFooter";
import TagFooter from "./components/TagFooter";
import EventInfoFooter from "./components/EventInfoFooter";
import Icon from "@emcm-ui/component-icon";

const ResultContent = ({
  type,
  dateTime,
  description,
  eyebrow,
  footer,
  imgSrc,
  links,
  icon,
  subtitle,
  iconInMobile,
  showFeatured,
  includeFooterLink,
  footerLinkTitle,
  featuredText,
  children
}) => {
  const getClassName = getClassNameFactory("Result");
  const isListView = type === "list";
  const isGridView = type === "grid";

  const getIcon = () => {
    const iconClassName = isListView ? "iconMobile" : "iconGridMobile";

    if (icon) {
      return (
        <span
          className={getClassName({
            descendantName: iconInMobile ? iconClassName : "icon"
          })}
        >
          {icon}
        </span>
      );
    }

    return "";
  };

  return (
    <React.Fragment>
      {isListView && getIcon()}

      <div
        className={getClassName({
          descendantName: isListView ? "body" : "gridBody"
        })}
      >
        {isGridView && getIcon()}

        {dateTime &&
          !eyebrow && (
            <span
              className={getClassName({
                descendantName: "dateTime",
                utilities: "typographySmallCaps",
                className: typestack("subline1")
              })}
            >
              <DateTime dateTime={dateTime} />
            </span>
          )}

        {eyebrow && (
          <span
            className={getClassName({
              descendantName: "eyebrow"
            })}
          >
            <Eyebrow text={eyebrow} />
          </span>
        )}

        {children}

        {showFeatured &&
          ((isGridView && !imgSrc) || isListView) && (
            <div className={getClassName({ descendantName: "feature" })}>
              <span
                className={getClassName({
                  descendantName: "featureText",
                  className: typestack("p2Bold")
                })}
              >
                {featuredText}
              </span>
            </div>
          )}

        <div
          className={getClassName({
            descendantName: "description",
            className: typestack("p1")
          })}
        >
          {description}
        </div>

        {subtitle && (
          <div
            className={getClassName({
              descendantName: "subtitle",
              className: typestack("p2Bold")
            })}
          >
            {subtitle}
          </div>
        )}

        {footer && (
          <div className={getClassName({ descendantName: "footer" })}>
            {footer}
          </div>
        )}

        {links &&
          links.length > 0 && (
            <div className={getClassName({ descendantName: "links" })}>
              {links.map((link, i) => (
                <span
                  className={getClassName({
                    descendantName: "link",
                    className: typestack("p1Bold")
                  })}
                  key={i}
                >
                  {link}
                </span>
              ))}
            </div>
          )}

        {isGridView &&
          includeFooterLink && (
            <div className={getClassName({ descendantName: "footerLinks" })}>
              <span
                className={getClassName({
                  descendantName: "footerLink",
                  className: typestack("p1Bold")
                })}
              >
                {footerLinkTitle &&
                  footerLinkTitle.length > 0 && (
                    <span
                      className={getClassName({
                        descendantName: "footerLinkData"
                      })}
                    >
                      {footerLinkTitle}
                    </span>
                  )}
                <span>
                  <Icon path="./icons/ui/blue/arrow-right.svg" size="s" />
                </span>
              </span>
            </div>
          )}
      </div>
    </React.Fragment>
  );
};

ResultContent.displayName = "SearchResults.ResultContent";

ResultContent.propTypes = {
  /**
   * Result view type
   */
  type: PropTypes.string,
  /**
   * DEPRECATED: The date associated with this search result. Should be ISO 8601.
   */
  dateTime: PropTypes.string,
  /**
   * Description for the result. Can contain markup.
   */
  description: PropTypes.node.isRequired,
  /**
   * Eyebrow text
   */
  eyebrow: PropTypes.string,
  /**
   * Footer to show on this result.
   */
  footer: PropTypes.element,
  /**
   * Image `src`. Used if srcSet is not defined, or as a fallback.
   */
  imgSrc: PropTypes.string,
  /**
   * Additional links that appear at the bottom of the result.
   */
  links: PropTypes.arrayOf(PropTypes.node),
  /**
   * Title text.
   */
  title: PropTypes.string.isRequired,
  /**
   * Icon of Document Type
   */
  icon: PropTypes.node,
  /**
   * Show Icon in Mobile
   */
  iconInMobile: PropTypes.bool,
  /**
   * Show Sub Title
   */
  subtitle: PropTypes.string,
  /**
   * Show Footer Link Title
   */
  footerLinkTitle: PropTypes.string,
  /**
   * Show Footer Links
   */
  includeFooterLink: PropTypes.bool,
  /**
   * Show Featured Tag
   */
  showFeatured: PropTypes.bool,
  /**
   * Show Featured Text
   */
  featuredText: PropTypes.string,
  /**
   * Show Featured Text
   */
  children: PropTypes.node
};

ResultContent.ArticleInfoFooter = ArticleInfoFooter;
ResultContent.TagFooter = TagFooter;
ResultContent.EventInfoFooter = EventInfoFooter;

export default ResultContent;
