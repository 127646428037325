import React from "react";
import PropTypes from "prop-types";
import { storiesOf } from "@storybook/react";
import {
  withKnobs,
  text,
  number,
  select,
  object
} from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import TableWithFilters from "./index";
import "./TableWithFilters.css";
import TableWithFiltersREADME from "../README.md";
import Heading from "@emcm-ui/component-heading";

const stories = storiesOf("Components/TableWithFilters", module);

const ranks = [""].concat(Heading.ranks);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(TableWithFiltersREADME));

class DataHelper extends React.Component {
  // this component is needed to unmount and remount TWF because the data that gets passed
  // through gets stored in state. So any subsequent change to data doesn't cause a re-render.
  // To prevent unwanted side affects to TWF we have this helper in the storybook to show
  // how the table would render with different column widths.

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    col1Width: PropTypes.number,
    col2Width: PropTypes.number,
    col3Width: PropTypes.number,
    col4Width: PropTypes.number,
    updating: PropTypes.bool
  };

  state = {
    col1Width: 0,
    col2Width: 0,
    col3Width: 0,
    col4Width: 0,
    updating: false
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.col1Width !== prevState.col1Width ||
      nextProps.col2Width !== prevState.col2Width ||
      nextProps.col3Width !== prevState.col3Width ||
      nextProps.col4Width !== prevState.col4Width
    ) {
      return {
        ...nextProps,
        updating: true
      };
    }

    return null;
  }

  componentDidUpdate() {
    if (this.state.updating === true) {
      this.setState(() => ({ updating: false }));
    }
  }

  componentDidMount() {
    this.setState(() => ({ updating: false }));
  }

  render() {
    const { col1Width, col2Width, col3Width, col4Width, updating } = this.state;
    const data = {
      filter: {
        cells: [
          {
            display: true,
            displayOrder: 4
          },
          {
            display: true,
            displayOrder: 1
          },
          {
            display: true,
            displayOrder: 2
          },
          {
            display: true,
            displayOrder: 3
          }
        ]
      },
      table: {
        caption: {
          content: "Table of brokers"
        },
        head: {
          rows: [
            {
              cells: [
                {
                  content: "Broker",
                  width: col1Width
                },
                {
                  content: "Asset",
                  width: col2Width
                },
                {
                  content: "Region",
                  width: col3Width
                },
                {
                  content: "Algo",
                  width: col4Width
                }
              ]
            }
          ]
        },
        bodies: [
          {
            rows: [
              {
                cells: [
                  {
                    content: "ABN AMRO",
                    link: {
                      href: "https://www.abnamro.com/"
                    }
                  },
                  {
                    content: "Futures"
                  },
                  {
                    content: "EMEA"
                  },
                  {
                    content: "DMA"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "ABN AMRO"
                  },
                  {
                    content: "Futures"
                  },
                  {
                    content: "US"
                  },
                  {
                    content: "DMA"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "ADM Investor Services Inc."
                  },
                  {
                    content: "Futures"
                  },
                  {
                    content: "EMEA"
                  },
                  {
                    content: "DMA"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "ADM Investor Services Inc."
                  },
                  {
                    content: "Futures"
                  },
                  {
                    content: "US"
                  },
                  {
                    content: "DMA"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "ADM Investor Services Inc."
                  },
                  {
                    content: "Options on Futures"
                  },
                  {
                    content: "US"
                  },
                  {
                    content: "DMA"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Americas Executions, LLC"
                  },
                  {
                    content: "Equities"
                  },
                  {
                    content: "US"
                  },
                  {
                    content: "Desk"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Apex"
                  },
                  {
                    content: "Equities"
                  },
                  {
                    content: "Canada"
                  },
                  {
                    content: "DMA"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Apex"
                  },
                  {
                    content: "Equities"
                  },
                  {
                    content: "US"
                  },
                  {
                    content: "Algo"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Apex"
                  },
                  {
                    content: "Equities"
                  },
                  {
                    content: "US"
                  },
                  {
                    content: "Desk"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "B. Riley & Co. LLC"
                  },
                  {
                    content: "Equities"
                  },
                  {
                    content: "US"
                  },
                  {
                    content: "Desk"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Bank of America Merrill Lynch"
                  },
                  {
                    content: "Equities"
                  },
                  {
                    content: "Asia"
                  },
                  {
                    content: "Algo"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Bank of America Merrill Lynch"
                  },
                  {
                    content: "Equities"
                  },
                  {
                    content: "Asia"
                  },
                  {
                    content: "Desk"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Bank of America Merrill Lynch"
                  },
                  {
                    content: "Equities"
                  },
                  {
                    content: "Asia"
                  },
                  {
                    content: "DMA"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Bank of America Merrill Lynch"
                  },
                  {
                    content: "Equities"
                  },
                  {
                    content: "Canada"
                  },
                  {
                    content: "Algo"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Bank of America Merrill Lynch"
                  },
                  {
                    content: "Equities"
                  },
                  {
                    content: "EMEA"
                  },
                  {
                    content: "Algo"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Bank of America Merrill Lynch"
                  },
                  {
                    content: "Equities"
                  },
                  {
                    content: "EMEA"
                  },
                  {
                    content: "Desk"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Bank of America Merrill Lynch"
                  },
                  {
                    content: "Equities"
                  },
                  {
                    content: "EMEA"
                  },
                  {
                    content: "Algo"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Bank of America Merrill Lynch"
                  },
                  {
                    content: "Equities"
                  },
                  {
                    content: "LATAM"
                  },
                  {
                    content: "Algo"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Bank of America Merrill Lynch"
                  },
                  {
                    content: "Equities"
                  },
                  {
                    content: "US"
                  },
                  {
                    content: "Algo"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Broker"
                  },
                  {
                    content: "Asset 0"
                  },
                  {
                    content: "Region"
                  },
                  {
                    content: "Algo"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Broker"
                  },
                  {
                    content: "Asset 1"
                  },
                  {
                    content: "Region"
                  },
                  {
                    content: "Algo"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Broker"
                  },
                  {
                    content: "Asset 2"
                  },
                  {
                    content: "Region"
                  },
                  {
                    content: "Algo"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Broker"
                  },
                  {
                    content: "Asset 3"
                  },
                  {
                    content: "Region"
                  },
                  {
                    content: "Algo"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Broker"
                  },
                  {
                    content: "Asset 4"
                  },
                  {
                    content: "Region"
                  },
                  {
                    content: "Algo"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Broker"
                  },
                  {
                    content: "Asset 5"
                  },
                  {
                    content: "Region"
                  },
                  {
                    content: "Algo"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Broker"
                  },
                  {
                    content: "Asset 6"
                  },
                  {
                    content: "Region"
                  },
                  {
                    content: "Algo"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Broker"
                  },
                  {
                    content: "Asset 7"
                  },
                  {
                    content: "Region"
                  },
                  {
                    content: "Algo"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Broker"
                  },
                  {
                    content: "Asset 8"
                  },
                  {
                    content: "Region"
                  },
                  {
                    content: "Algo"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Broker"
                  },
                  {
                    content: "Asset 9"
                  },
                  {
                    content: "Region"
                  },
                  {
                    content: "Algo"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Broker"
                  },
                  {
                    content: "Asset 10"
                  },
                  {
                    content: "Region"
                  },
                  {
                    content: "Algo"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Broker"
                  },
                  {
                    content: "Asset 11"
                  },
                  {
                    content: "Region"
                  },
                  {
                    content: "Algo"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Broker"
                  },
                  {
                    content: "Asset 12"
                  },
                  {
                    content: "Region"
                  },
                  {
                    content: "Algo"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Broker"
                  },
                  {
                    content: "Asset 13"
                  },
                  {
                    content: "Region"
                  },
                  {
                    content: "Algo"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Broker"
                  },
                  {
                    content: "Asset 14"
                  },
                  {
                    content: "Region"
                  },
                  {
                    content: "Algo"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Broker"
                  },
                  {
                    content: "Asset 15"
                  },
                  {
                    content: "Region"
                  },
                  {
                    content: "Algo"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Broker"
                  },
                  {
                    content: "Asset 16"
                  },
                  {
                    content: "Region"
                  },
                  {
                    content: "Algo"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Broker"
                  },
                  {
                    content: "Asset 17"
                  },
                  {
                    content: "Region"
                  },
                  {
                    content: "Algo"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Broker"
                  },
                  {
                    content: "Asset 18"
                  },
                  {
                    content: "Region"
                  },
                  {
                    content: "Algo"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Broker"
                  },
                  {
                    content: "Asset 19"
                  },
                  {
                    content: "Region"
                  },
                  {
                    content: "Algo"
                  }
                ]
              },
              {
                cells: [
                  {
                    content: "Broker"
                  },
                  {
                    content: "Asset 20"
                  },
                  {
                    content: "Region"
                  },
                  {
                    content: "Algo"
                  }
                ]
              }
            ]
          }
        ]
      }
    };

    if (updating) {
      return null;
    }

    return this.props.children({ data });
  }
}

stories
  .add("Default", () => (
    <DataHelper
      col1Width={parseInt(text("Column1 width", "0")) || null}
      col2Width={parseInt(text("Column2 width", "0")) || null}
      col3Width={parseInt(text("Column3 width", "0")) || null}
      col4Width={parseInt(text("Column4 width", "0")) || null}
    >
      {({ data }) => (
        <TableWithFilters
          data={data}
          filtersNoOptionsMessage={text(
            "Filters: No tags found",
            "No results found that match your search term. Please try another or check your spelling."
          )}
          filtersPlaceholder={text(
            "Filters: Initial message",
            "Add filter(s)..."
          )}
          infoText={text(
            "Subtitle",
            "Find a broker by asset, region, route or name."
          )}
          backgroundColor={select(
            "Select Background Color",
            ["white", "gray"],
            "white"
          )}
          noResultsMessage={text(
            "No results message",
            "No results have been found as a result of the table being over constrained. To see results, please try removing filters or try and different combination of filters to find the information you’re looking for."
          )}
          // Intending to pass a value
          // eslint-disable-next-line no-magic-numbers
          pagesNavigableOnSide={number("Pages navigable on side", 2)}
          paginationResultSummaryText={text(
            "Pagination summary",
            "Viewing {1} of {2} results:"
          )}
          // Intending to pass a value
          // eslint-disable-next-line no-magic-numbers
          rowsPerPage={number("Rows per page", 2)}
          subtitleHeadingRank={select("Subtitle Heading Rank", ranks, "3")}
          title={text("Title", "Search Our Network")}
          titleHeadingRank={select("Title Heading Rank", ranks, "2")}
        />
      )}
    </DataHelper>
  ))

  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <TableWithFilters
        data={{
          filter: {
            cells: [
              {
                display: true,
                displayOrder: 4
              },
              {
                display: true,
                displayOrder: 1
              },
              {
                display: true,
                displayOrder: 2
              },
              {
                display: true,
                displayOrder: 3
              }
            ]
          },
          table: {
            caption: {
              content: "Table of brokers"
            },
            head: {
              rows: [
                {
                  cells: [
                    {
                      content: "Broker"
                    },
                    {
                      content: "Asset"
                    },
                    {
                      content: "Region"
                    },
                    {
                      content: "Algo"
                    }
                  ]
                }
              ]
            },
            bodies: [
              {
                rows: [
                  {
                    cells: [
                      {
                        content: "ABN AMRO",
                        link: {
                          href: "https://www.abnamro.com/"
                        }
                      },
                      {
                        content: "Futures"
                      },
                      {
                        content: "EMEA"
                      },
                      {
                        content: "DMA"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "ABN AMRO"
                      },
                      {
                        content: "Futures"
                      },
                      {
                        content: "US"
                      },
                      {
                        content: "DMA"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "ADM Investor Services Inc."
                      },
                      {
                        content: "Futures"
                      },
                      {
                        content: "EMEA"
                      },
                      {
                        content: "DMA"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "ADM Investor Services Inc."
                      },
                      {
                        content: "Futures"
                      },
                      {
                        content: "US"
                      },
                      {
                        content: "DMA"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "ADM Investor Services Inc."
                      },
                      {
                        content: "Options on Futures"
                      },
                      {
                        content: "US"
                      },
                      {
                        content: "DMA"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "Americas Executions, LLC"
                      },
                      {
                        content: "Equities"
                      },
                      {
                        content: "US"
                      },
                      {
                        content: "Desk"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "Apex"
                      },
                      {
                        content: "Equities"
                      },
                      {
                        content: "Canada"
                      },
                      {
                        content: "DMA"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "Apex"
                      },
                      {
                        content: "Equities"
                      },
                      {
                        content: "US"
                      },
                      {
                        content: "Algo"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "Apex"
                      },
                      {
                        content: "Equities"
                      },
                      {
                        content: "US"
                      },
                      {
                        content: "Desk"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "B. Riley & Co. LLC"
                      },
                      {
                        content: "Equities"
                      },
                      {
                        content: "US"
                      },
                      {
                        content: "Desk"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "Bank of America Merrill Lynch"
                      },
                      {
                        content: "Equities"
                      },
                      {
                        content: "Asia"
                      },
                      {
                        content: "Algo"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "Bank of America Merrill Lynch"
                      },
                      {
                        content: "Equities"
                      },
                      {
                        content: "Asia"
                      },
                      {
                        content: "Desk"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "Bank of America Merrill Lynch"
                      },
                      {
                        content: "Equities"
                      },
                      {
                        content: "Asia"
                      },
                      {
                        content: "DMA"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "Bank of America Merrill Lynch"
                      },
                      {
                        content: "Equities"
                      },
                      {
                        content: "Canada"
                      },
                      {
                        content: "Algo"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "Bank of America Merrill Lynch"
                      },
                      {
                        content: "Equities"
                      },
                      {
                        content: "EMEA"
                      },
                      {
                        content: "Algo"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "Bank of America Merrill Lynch"
                      },
                      {
                        content: "Equities"
                      },
                      {
                        content: "EMEA"
                      },
                      {
                        content: "Desk"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "Bank of America Merrill Lynch"
                      },
                      {
                        content: "Equities"
                      },
                      {
                        content: "EMEA"
                      },
                      {
                        content: "Algo"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "Bank of America Merrill Lynch"
                      },
                      {
                        content: "Equities"
                      },
                      {
                        content: "LATAM"
                      },
                      {
                        content: "Algo"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "Bank of America Merrill Lynch"
                      },
                      {
                        content: "Equities"
                      },
                      {
                        content: "US"
                      },
                      {
                        content: "Algo"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        }}
        filtersNoOptionsMessage="No results found that match your search term. Please try another or check your spelling."
        filtersPlaceholder="بطيئة الكلب"
        infoText="بطيئة الكلب الأسود الانحناء قبل الثعلب ملكي"
        backgroundColor="white"
        noResultsMessage="No results have been found as a result of the table being over constrained. To see results, please try removing filters or try and different combination of filters to find the information you’re looking for."
        pagesNavigableOnSide={2}
        paginationResultSummaryText="Viewing {1} of {2} results:"
        rowsPerPage={2}
        subtitleHeadingRank="3"
        title="بطيئة الكلب"
        titleHeadingRank="2"
      />
    </Language>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <TableWithFilters
        data={{
          filter: {
            cells: [
              {
                display: true,
                displayOrder: 4
              },
              {
                display: true,
                displayOrder: 1
              },
              {
                display: true,
                displayOrder: 2
              },
              {
                display: true,
                displayOrder: 3
              }
            ]
          },
          table: {
            caption: {
              content: "Table of brokers"
            },
            head: {
              rows: [
                {
                  cells: [
                    {
                      content: "Broker"
                    },
                    {
                      content: "Asset"
                    },
                    {
                      content: "Region"
                    },
                    {
                      content: "Algo"
                    }
                  ]
                }
              ]
            },
            bodies: [
              {
                rows: [
                  {
                    cells: [
                      {
                        content: "ABN AMRO",
                        link: {
                          href: "https://www.abnamro.com/"
                        }
                      },
                      {
                        content: "Futures"
                      },
                      {
                        content: "EMEA"
                      },
                      {
                        content: "DMA"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "ABN AMRO"
                      },
                      {
                        content: "Futures"
                      },
                      {
                        content: "US"
                      },
                      {
                        content: "DMA"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "ADM Investor Services Inc."
                      },
                      {
                        content: "Futures"
                      },
                      {
                        content: "EMEA"
                      },
                      {
                        content: "DMA"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "ADM Investor Services Inc."
                      },
                      {
                        content: "Futures"
                      },
                      {
                        content: "US"
                      },
                      {
                        content: "DMA"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "ADM Investor Services Inc."
                      },
                      {
                        content: "Options on Futures"
                      },
                      {
                        content: "US"
                      },
                      {
                        content: "DMA"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "Americas Executions, LLC"
                      },
                      {
                        content: "Equities"
                      },
                      {
                        content: "US"
                      },
                      {
                        content: "Desk"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "Apex"
                      },
                      {
                        content: "Equities"
                      },
                      {
                        content: "Canada"
                      },
                      {
                        content: "DMA"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "Apex"
                      },
                      {
                        content: "Equities"
                      },
                      {
                        content: "US"
                      },
                      {
                        content: "Algo"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "Apex"
                      },
                      {
                        content: "Equities"
                      },
                      {
                        content: "US"
                      },
                      {
                        content: "Desk"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "B. Riley & Co. LLC"
                      },
                      {
                        content: "Equities"
                      },
                      {
                        content: "US"
                      },
                      {
                        content: "Desk"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "Bank of America Merrill Lynch"
                      },
                      {
                        content: "Equities"
                      },
                      {
                        content: "Asia"
                      },
                      {
                        content: "Algo"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "Bank of America Merrill Lynch"
                      },
                      {
                        content: "Equities"
                      },
                      {
                        content: "Asia"
                      },
                      {
                        content: "Desk"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "Bank of America Merrill Lynch"
                      },
                      {
                        content: "Equities"
                      },
                      {
                        content: "Asia"
                      },
                      {
                        content: "DMA"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "Bank of America Merrill Lynch"
                      },
                      {
                        content: "Equities"
                      },
                      {
                        content: "Canada"
                      },
                      {
                        content: "Algo"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "Bank of America Merrill Lynch"
                      },
                      {
                        content: "Equities"
                      },
                      {
                        content: "EMEA"
                      },
                      {
                        content: "Algo"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "Bank of America Merrill Lynch"
                      },
                      {
                        content: "Equities"
                      },
                      {
                        content: "EMEA"
                      },
                      {
                        content: "Desk"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "Bank of America Merrill Lynch"
                      },
                      {
                        content: "Equities"
                      },
                      {
                        content: "EMEA"
                      },
                      {
                        content: "Algo"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "Bank of America Merrill Lynch"
                      },
                      {
                        content: "Equities"
                      },
                      {
                        content: "LATAM"
                      },
                      {
                        content: "Algo"
                      }
                    ]
                  },
                  {
                    cells: [
                      {
                        content: "Bank of America Merrill Lynch"
                      },
                      {
                        content: "Equities"
                      },
                      {
                        content: "US"
                      },
                      {
                        content: "Algo"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        }}
        filtersNoOptionsMessage="No results found that match your search term. Please try another or check your spelling."
        filtersPlaceholder="Add filter(s)..."
        infoText="Find a broker by asset, region, route or name."
        backgroundColor="white"
        noResultsMessage="No results have been found as a result of the table being over constrained. To see results, please try removing filters or try and different combination of filters to find the information you’re looking for."
        pagesNavigableOnSide={2}
        paginationResultSummaryText="Viewing {1} of {2} results:"
        rowsPerPage={2}
        subtitleHeadingRank="3"
        title="Search Our Network"
        titleHeadingRank="2"
      >
        <div className="emcmUi-TableWithFiltersTable">
          <table>
            <caption>Table of brokers</caption>
            <thead>
              <tr>
                <th>Broker</th>
                <th>Asset</th>
                <th>Region</th>
                <th>Route</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <a
                    className="emcmUi-Link emcmUi-Link--inline"
                    href="https://www.abnamro.com/"
                  >
                    ABN AMRO
                  </a>
                </td>
                <td>Futures</td>
                <td>EMEA</td>
                <td>DMA</td>
              </tr>
              <tr>
                <td>ABN AMRO</td>
                <td>Futures</td>
                <td>US</td>
                <td>DMA</td>
              </tr>
              <tr>
                <td>ADM Investor Services Inc.</td>
                <td>Futures</td>
                <td>EMEA</td>
                <td>DMA</td>
              </tr>
              <tr>
                <td>ADM Investor Services Inc.</td>
                <td>Futures</td>
                <td>US</td>
                <td>DMA</td>
              </tr>
              <tr>
                <td>ADM Investor Services Inc.</td>
                <td>Options on Futures</td>
                <td>US</td>
                <td>DMA</td>
              </tr>
              <tr>
                <td>Americas Executions, LLC</td>
                <td>Equities</td>
                <td>US</td>
                <td>Desk</td>
              </tr>
              <tr>
                <td>Apex</td>
                <td>Equities</td>
                <td>Canada</td>
                <td>DMA</td>
              </tr>
              <tr>
                <td>Apex</td>
                <td>Equities</td>
                <td>US</td>
                <td>Algo</td>
              </tr>
              <tr>
                <td>Apex</td>
                <td>Equities</td>
                <td>US</td>
                <td>Desk</td>
              </tr>
              <tr>
                <td>B. Riley &amp; Co. LLC</td>
                <td>Equities</td>
                <td>US</td>
                <td>Desk</td>
              </tr>
              <tr>
                <td>Bank of America Merrill Lynch</td>
                <td>Equities</td>
                <td>Asia</td>
                <td>Algo</td>
              </tr>
              <tr>
                <td>Bank of America Merrill Lynch</td>
                <td>Equities</td>
                <td>Asia</td>
                <td>Desk</td>
              </tr>
              <tr>
                <td>Bank of America Merrill Lynch</td>
                <td>Equities</td>
                <td>Asia</td>
                <td>DMA</td>
              </tr>
              <tr>
                <td>Bank of America Merrill Lynch</td>
                <td>Equities</td>
                <td>Canada</td>
                <td>Algo</td>
              </tr>
              <tr>
                <td>Bank of America Merrill Lynch</td>
                <td>Equities</td>
                <td>EMEA</td>
                <td>Algo</td>
              </tr>
              <tr>
                <td>Bank of America Merrill Lynch</td>
                <td>Equities</td>
                <td>EMEA</td>
                <td>Desk</td>
              </tr>
              <tr>
                <td>Bank of America Merrill Lynch</td>
                <td>Equities</td>
                <td>EMEA</td>
                <td>PT</td>
              </tr>
              <tr>
                <td>Bank of America Merrill Lynch</td>
                <td>Equities</td>
                <td>LATAM</td>
                <td>Algo</td>
              </tr>
              <tr>
                <td>Bank of America Merrill Lynch</td>
                <td>Equities</td>
                <td>US</td>
                <td>Route</td>
              </tr>
            </tbody>
          </table>
        </div>
      </TableWithFilters>
    </StaticRenderer>
  ))
  .add("Table with hidden column", () => (
    <TableWithFilters
      data={{
        filter: {
          cells: [
            {
              display: true,
              displayOrder: 1
            },
            {
              display: true,
              displayOrder: 2
            },
            {
              display: true,
              displayOrder: 3
            },
            {
              display: true,
              displayOrder: 4
            }
          ]
        },
        table: {
          caption: {
            content: "Table of download pill button"
          },
          head: {
            rows: [
              {
                cells: [
                  {
                    content: "Date & Time"
                  },
                  {
                    content: "Duration",
                    isHidden: true
                  },
                  {
                    content: "Language",
                    isHidden: false
                  },
                  {
                    content: "Location",
                    isHidden: false
                  },
                  {
                    content: "Download",
                    isCta: true,
                    disableCTASticky: false
                  }
                ]
              }
            ]
          },
          bodies: [
            {
              rows: [
                {
                  cells: [
                    {
                      content: "2021-11-19 09:00:00",
                      region: "Europe/London"
                    },
                    {
                      content: "1 hrs ",
                      isHidden: true
                    },
                    {
                      content: "English",
                      isHidden: false
                    },
                    {
                      content: "Webcast eLearning",
                      isHidden: false
                    },
                    {
                      content: "Download",
                      isCta: true,
                      icon: "arrow",
                      isCtaActive: true,
                      href:
                        "https://www.refinitiv.com/content/dam/marketing/bundle-emcm/static/fonts/all-characters/proximanova-regular-webfont.woff2"
                    }
                  ]
                },
                {
                  cells: [
                    {
                      content: "2021-11-19 12:00:00",
                      region: "Europe/London"
                    },
                    {
                      content: "1 hrs ",
                      isHidden: true
                    },
                    {
                      content: "English",
                      isHidden: false
                    },
                    {
                      content: "Webcast eLearning",
                      isHidden: false
                    },
                    {
                      content: "Download",
                      isCta: true,
                      icon: "externallink",
                      isCtaActive: true,
                      href:
                        "https://www.refinitiv.com/content/dam/marketing/bundle-emcm/static/fonts/all-characters/proximanova-regular-webfont.woff2"
                    }
                  ]
                },
                {
                  cells: [
                    {
                      content: "2021-11-19 10:00",
                      region: "Asia/Singapore"
                    },
                    {
                      content: "1 hrs ",
                      isHidden: true
                    },
                    {
                      content: "English",
                      isHidden: false
                    },
                    {
                      content: "Webcast eLearning",
                      isHidden: false
                    },
                    {
                      content: "Download",
                      isCta: true,
                      icon: "download",
                      isCtaActive: true,
                      href:
                        "https://www.refinitiv.com/content/dam/marketing/bundle-emcm/static/fonts/all-characters/proximanova-regular-webfont.woff2"
                    }
                  ]
                },
                {
                  cells: [
                    {
                      content: "2021-11-19 16:00",
                      region: "Asia/Dubai"
                    },
                    {
                      content: "1 hrs ",
                      isHidden: true
                    },
                    {
                      content: "English",
                      isHidden: false
                    },
                    {
                      content: "Webcast eLearning",
                      isHidden: false
                    },
                    {
                      content: "Download",
                      isCta: true,
                      icon: "arrow",
                      isCtaActive: true,
                      href:
                        "https://www.refinitiv.com/content/dam/marketing/bundle-emcm/static/fonts/all-characters/proximanova-regular-webfont.woff2"
                    }
                  ]
                }
              ]
            }
          ]
        }
      }}
      filtersNoOptionsMessage={text(
        "Filters: No tags found",
        "No results found that match your search term. Please try another or check your spelling."
      )}
      filtersPlaceholder={text("Filters: Initial message", "Add filter(s)...")}
      infoText={text("Subtitle", "If you want to request a class please")}
      backgroundColor={select(
        "Select Background Color",
        ["white", "gray"],
        "white"
      )}
      infoLink={object("Subtitle Info Link", {
        text: "contact us",
        link:
          "mailto:training@refinitiv.com?subject=Client Request for Training",
        newWindow: false
      })}
      noResultsMessage={text(
        "No results message",
        "No results have been found as a result of the table being over constrained. To see results, please try removing filters or try and different combination of filters to find the information you’re looking for."
      )}
      // Intending to pass a value
      // eslint-disable-next-line no-magic-numbers
      pagesNavigableOnSide={number("Pages navigable on side", 2)}
      paginationResultSummaryText={text(
        "Pagination summary",
        "Viewing {1} of {2} results:"
      )}
      // Intending to pass a value
      // eslint-disable-next-line no-magic-numbers
      rowsPerPage={number("Rows per page", 2)}
      subtitleHeadingRank={select("Subtitle Heading Rank", ranks, "3")}
      title={text("Title", "Scheduled Classes (12)")}
      titleHeadingRank={select("Title Heading Rank", ranks, "2")}
      isMulti={false}
      isOverflow={false}
      languageSelectedOption={object("Language Select Option", [
        {
          label: "English",
          value: "English"
        }
      ])}
      timezoneSelectedOption={object("Timezone Select Option", [
        {
          label: "Asia/Calcutta (IST +05:30)",
          value: "Asia/Calcutta"
        }
      ])}
      filterHeader={object("Filters Mapping with Table Headers", {
        "Date & Time": {
          label: "Change Timezone",
          placeholder: "Change TimeZone",
          selector: "timezones"
        },
        Language: {
          label: "Filter Languages",
          placeholder: "All Languages"
        }
      })}
      timezones={[
        {
          label: "Africa/Abidjan (GMT +00:00)",
          value: "Africa/Abidjan"
        },
        {
          label: "Africa/Accra (GMT +00:00)",
          value: "Africa/Accra"
        },
        { label: "Africa/Algiers (CET +01:00)", value: "Africa/Algiers" },
        {
          label: "Africa/Asmara (EAT +03:00)",
          value: "Africa/Asmara"
        },
        {
          label: "Africa/Bamako (GMT +00:00)",
          value: "Africa/Bamako"
        },
        {
          label: "Africa/Windhoek (CAT +02:00)",
          value: "Africa/Windhoek"
        },
        {
          label: "America/Adak (HDT -09:00)",
          value: "America/Adak"
        },
        {
          label: "America/Argentina/Jujuy (-03 -03:00)",
          value: "America/Argentina/Jujuy"
        },
        {
          label: "Asia/Kolkata (IST +05:30)",
          value: "Asia/Kolkata"
        },
        {
          label: "Asia/Omsk (+06 +06:00)",
          value: "Asia/Omsk"
        },
        {
          label: "Asia/Calcutta (IST +05:30)",
          value: "Asia/Calcutta"
        },
        {
          label: "Asia/Manila (CST +08:00)",
          value: "Asia/Manila"
        },
        {
          label: "Singapore (MYT +08:00)",
          value: "Singapore"
        },
        {
          label: "US/Central (CDT -05:00)",
          value: "US/Central"
        }
      ]}
    />
  ))
  .add("Timezone with Language", () => (
    <TableWithFilters
      data={{
        filter: {
          cells: [
            {
              display: true,
              displayOrder: 1
            },
            {
              display: false,
              displayOrder: 2
            },
            {
              display: true,
              displayOrder: 3
            },
            {
              display: false,
              displayOrder: 4
            }
          ]
        },
        table: {
          caption: {
            content: "Table of brokers"
          },
          head: {
            rows: [
              {
                cells: [
                  {
                    content: "Date & Time"
                  },
                  {
                    content: "Duration"
                  },
                  {
                    content: "Language"
                  },
                  {
                    content: "Location"
                  },
                  {
                    content: ""
                  }
                ]
              }
            ]
          },
          bodies: [
            {
              rows: [
                {
                  cells: [
                    {
                      content: "2021-11-19 09:00:00"
                    },
                    {
                      content: "1 hrs "
                    },
                    {
                      content: "English"
                    },
                    {
                      content: "Webcast eLearning"
                    },
                    {
                      content: "Full",
                      isCta: true,
                      isCtaActive: true,
                      href:
                        "https://www.refinitiv.com/content/dam/marketing/bundle-emcm/static/fonts/all-characters/proximanova-regular-webfont.woff2"
                    }
                  ]
                },
                {
                  cells: [
                    {
                      content: "2021-11-19 12:00:00"
                    },
                    {
                      content: "1 hrs "
                    },
                    {
                      content: "English"
                    },
                    {
                      content: "Webcast eLearning"
                    },
                    {
                      content: "Enroll",
                      isCta: true,
                      isCtaActive: true
                    }
                  ]
                },
                {
                  cells: [
                    {
                      content: "2021-11-19 10:00"
                    },
                    {
                      content: "1 hrs "
                    },
                    {
                      content: "German"
                    },
                    {
                      content: "Webcast eLearning"
                    },
                    {
                      content: "Enroll",
                      isCta: true,
                      isCtaActive: true
                    }
                  ]
                },
                {
                  cells: [
                    {
                      content: "2021-11-19 13:00"
                    },
                    {
                      content: "1 hrs "
                    },
                    {
                      content: "German"
                    },
                    {
                      content: "Webcast eLearning"
                    },
                    {
                      content: "Enroll",
                      isCta: true,
                      isCtaActive: true
                    }
                  ]
                },
                {
                  cells: [
                    {
                      content: "2021-11-19 14:00"
                    },
                    {
                      content: "1 hrs "
                    },
                    {
                      content: "Japaneese"
                    },
                    {
                      content: "Webcast eLearning"
                    },
                    {
                      content: "Enroll",
                      isCta: true,
                      isCtaActive: true
                    }
                  ]
                },
                {
                  cells: [
                    {
                      content: "2021-11-19 16:00"
                    },
                    {
                      content: "1 hrs "
                    },
                    {
                      content: "English"
                    },
                    {
                      content: "Webcast eLearning"
                    },
                    {
                      content: "Enroll",
                      isCta: true,
                      isCtaActive: true
                    }
                  ]
                },
                {
                  cells: [
                    {
                      content: "2021-11-19 17:00"
                    },
                    {
                      content: "1 hrs "
                    },
                    {
                      content: "English"
                    },
                    {
                      content: "Webcast eLearning"
                    },
                    {
                      content: "Enroll",
                      isCta: true,
                      isCtaActive: true
                    }
                  ]
                },
                {
                  cells: [
                    {
                      content: "2021-11-19 11:00"
                    },
                    {
                      content: "1 hrs "
                    },
                    {
                      content: "English"
                    },
                    {
                      content: "Webcast eLearning"
                    },
                    {
                      content: "Enroll",
                      isCta: true,
                      isCtaActive: true
                    }
                  ]
                },
                {
                  cells: [
                    {
                      content: "2021-11-19 09:00"
                    },
                    {
                      content: "1 hrs "
                    },
                    {
                      content: "English"
                    },
                    {
                      content: "Webcast eLearning"
                    },
                    {
                      content: "Enroll",
                      isCta: true,
                      isCtaActive: true
                    }
                  ]
                },
                {
                  cells: [
                    {
                      content: "2021-11-19 10:00"
                    },
                    {
                      content: "1 hrs "
                    },
                    {
                      content: "English"
                    },
                    {
                      content: "Webcast eLearning"
                    },
                    {
                      content: "Enroll",
                      isCta: true,
                      isCtaActive: true
                    }
                  ]
                },
                {
                  cells: [
                    {
                      content: "2021-11-19 18:00"
                    },
                    {
                      content: "1 hrs "
                    },
                    {
                      content: "English"
                    },
                    {
                      content: "Webcast eLearning"
                    },
                    {
                      content: "Enroll",
                      isCta: true,
                      isCtaActive: true
                    }
                  ]
                },
                {
                  cells: [
                    {
                      content: "2021-11-19 16:00"
                    },
                    {
                      content: "1 hrs "
                    },
                    {
                      content: "French"
                    },
                    {
                      content: "Webcast eLearning"
                    },
                    {
                      content: "Enroll",
                      isCta: true,
                      isCtaActive: true
                    }
                  ]
                },
                {
                  cells: [
                    {
                      content: "2023-10-25 15:00"
                    },
                    {
                      content: "15 Mins"
                    },
                    {
                      content: "English"
                    },
                    {
                      content: "Online"
                    },
                    {
                      content: "Enroll",
                      isCta: true,
                      isCtaActive: true
                    }
                  ]
                },
                {
                  cells: [
                    {
                      content: "2023-10-31 20:00"
                    },
                    {
                      content: "15 Mins"
                    },
                    {
                      content: "English"
                    },
                    {
                      content: "Online"
                    },
                    {
                      content: "Download",
                      icon: "externallink",
                      href: "https://www.google.com",
                      isCta: true,
                      isCtaActive: true
                    }
                  ]
                }
              ]
            }
          ]
        }
      }}
      filtersNoOptionsMessage={text(
        "Filters: No tags found",
        "No results found that match your search term. Please try another or check your spelling."
      )}
      filtersPlaceholder={text("Filters: Initial message", "Add filter(s)...")}
      infoText={text("Subtitle", "If you want to request a class please")}
      backgroundColor={select(
        "Select Background Color",
        ["white", "gray"],
        "white"
      )}
      infoLink={object("Subtitle Info Link", {
        text: "contact us",
        link:
          "mailto:training@refinitiv.com?subject=Client Request for Training",
        newWindow: false
      })}
      noResultsMessage={text(
        "No results message",
        "No results have been found as a result of the table being over constrained. To see results, please try removing filters or try and different combination of filters to find the information you’re looking for."
      )}
      // Intending to pass a value
      // eslint-disable-next-line no-magic-numbers
      pagesNavigableOnSide={number("Pages navigable on side", 2)}
      paginationResultSummaryText={text(
        "Pagination summary",
        "Viewing {1} of {2} results:"
      )}
      // Intending to pass a value
      // eslint-disable-next-line no-magic-numbers
      rowsPerPage={number("Rows per page", 2)}
      subtitleHeadingRank={select("Subtitle Heading Rank", ranks, "3")}
      title={text("Title", "Scheduled Classes (12)")}
      titleHeadingRank={select("Title Heading Rank", ranks, "2")}
      isMulti={false}
      isOverflow={true}
      languageSelectedOption={object("Language Select Option", [
        {
          label: "English",
          value: "English"
        }
      ])}
      timezoneSelectedOption={object("Timezone Select Option", [
        {
          label: "Asia/Calcutta",
          value: "Asia/Calcutta"
        }
      ])}
      filterHeader={object("Filters Mapping with Table Headers", {
        "Date & Time": {
          label: "Change Timezone",
          placeholder: "Change TimeZone",
          selector: "timezones"
        },
        Language: {
          label: "Filter Languages",
          placeholder: "All Languages"
        }
      })}
      timezones={[
        {
          label: "Africa/Abidjan",
          value: "Africa/Abidjan"
        },
        {
          label: "Africa/Accra (GMT +00:00)",
          value: "Africa/Accra"
        },
        { label: "Africa/Algiers (CET +01:00)", value: "Africa/Algiers" },
        {
          label: "Africa/Asmara (EAT +03:00)",
          value: "Africa/Asmara"
        },
        {
          label: "Africa/Bamako (GMT +00:00)",
          value: "Africa/Bamako"
        },
        {
          label: "Africa/Windhoek (CAT +02:00)",
          value: "Africa/Windhoek"
        },
        {
          label: "America/Adak (HDT -09:00)",
          value: "America/Adak"
        },
        {
          label: "America/Argentina/Jujuy (-03 -03:00)",
          value: "America/Argentina/Jujuy"
        },
        {
          label: "Asia/Kolkata",
          value: "Asia/Kolkata"
        },
        {
          label: "Asia/Omsk (+06 +06:00)",
          value: "Asia/Omsk"
        },
        {
          label: "Asia/Calcutta",
          value: "Asia/Calcutta"
        },
        {
          label: "Asia/Manila (CST +08:00)",
          value: "Asia/Manila"
        },
        {
          label: "Singapore (MYT +08:00)",
          value: "Singapore"
        },
        {
          label: "US/Central (CDT -05:00)",
          value: "US/Central"
        },
        {
          label: "Mexico City, Monterrey, Merida (CST -06:00)",
          value: "America/Mexico_City"
        }
      ]}
    />
  ))
  .add("Rehydrated Timezone with Language", () => (
    <StaticRenderer>
      <TableWithFilters
        data={{
          filter: {
            cells: [
              { display: true, displayOrder: 1 },
              { display: false, displayOrder: 4 },
              { display: true, displayOrder: 2 },
              { display: false, displayOrder: 3 }
            ]
          },
          table: {
            head: {
              rows: [
                {
                  cells: [
                    { content: "Date & Time" },
                    { content: "Duration" },
                    { content: "Language" },
                    { content: "Location" },
                    { content: "", isCta: true }
                  ]
                }
              ]
            },
            bodies: [
              {
                rows: [
                  {
                    cells: [
                      { content: "2022-06-06 16:00:00" },
                      { content: "45 mins" },
                      { content: "English" },
                      { content: "Webcast eLearning" },
                      { isCtaActive: true, isCta: true, content: "Enroll" }
                    ]
                  },
                  {
                    cells: [
                      { content: "2022-06-08 16:00:00" },
                      { content: "45 mins" },
                      { content: "Spanish" },
                      { content: "Webcast eLearning" },
                      { isCtaActive: true, isCta: true, content: "Enroll" }
                    ]
                  },
                  {
                    cells: [
                      { content: "2022-06-09 10:00:00" },
                      { content: "45 mins" },
                      { content: "English" },
                      { content: "Webcast eLearning" },
                      { isCtaActive: true, isCta: true, content: "Enroll" }
                    ]
                  },
                  {
                    cells: [
                      { content: "2022-06-10 11:00:00" },
                      { content: "45 mins" },
                      { content: "English" },
                      { content: "Webcast eLearning" },
                      { isCtaActive: true, isCta: true, content: "Enroll" }
                    ]
                  },
                  {
                    cells: [
                      { content: "2022-06-13 16:00:00" },
                      { content: "45 mins" },
                      { content: "English" },
                      { content: "Webcast eLearning" },
                      { isCtaActive: true, isCta: true, content: "Enroll" }
                    ]
                  },
                  {
                    cells: [
                      { content: "2022-06-14 16:00:00" },
                      { content: "45 mins" },
                      { content: "Spanish" },
                      { content: "Webcast eLearning" },
                      { isCtaActive: true, isCta: true, content: "Enroll" }
                    ]
                  },
                  {
                    cells: [
                      { content: "2022-06-16 10:00:00" },
                      { content: "45 mins" },
                      { content: "English" },
                      { content: "Webcast eLearning" },
                      { isCtaActive: true, isCta: true, content: "Enroll" }
                    ]
                  },
                  {
                    cells: [
                      { content: "2022-06-16 12:30:00" },
                      { content: "45 mins" },
                      { content: "Spanish" },
                      { content: "Webcast eLearning" },
                      { isCtaActive: true, isCta: true, content: "Enroll" }
                    ]
                  },
                  {
                    cells: [
                      { content: "2022-06-20 16:00:00" },
                      { content: "45 mins" },
                      { content: "English" },
                      { content: "Webcast eLearning" },
                      { isCtaActive: true, isCta: true, content: "Enroll" }
                    ]
                  },
                  {
                    cells: [
                      { content: "2022-06-23 10:00:00" },
                      { content: "45 mins" },
                      { content: "English" },
                      { content: "Webcast eLearning" },
                      { isCtaActive: true, isCta: true, content: "Enroll" }
                    ]
                  },
                  {
                    cells: [
                      { content: "2022-06-23 14:00:00" },
                      { content: "30 mins" },
                      { content: "English" },
                      { content: "Webcast eLearning" },
                      { isCtaActive: true, isCta: true, content: "Enroll" }
                    ]
                  },
                  {
                    cells: [
                      { content: "2022-06-27 16:00:00" },
                      { content: "45 mins" },
                      { content: "English" },
                      { content: "Webcast eLearning" },
                      { isCtaActive: true, isCta: true, content: "Enroll" }
                    ]
                  },
                  {
                    cells: [
                      { content: "2022-08-04 15:00:00" },
                      { content: "45 mins" },
                      { content: "English" },
                      { content: "Webcast eLearning" },
                      { isCtaActive: true, isCta: true, content: "Enroll" }
                    ]
                  },
                  {
                    cells: [
                      { content: "2023-10-25 15:00:00" },
                      { content: "15 mins" },
                      { content: "English" },
                      { content: "Online" },
                      { isCtaActive: true, isCta: true, content: "Enroll" }
                    ]
                  },
                  {
                    cells: [
                      { content: "2023-10-31 20:00:00" },
                      { content: "15 mins" },
                      { content: "English" },
                      { content: "Online" },
                      { isCtaActive: true, isCta: true, content: "Enroll" }
                    ]
                  }
                ]
              }
            ],
            caption: { content: "Table of brokers" }
          }
        }}
        filtersNoOptionsMessage={text(
          "Filters: No tags found",
          "No results found that match your search term. Please try another or check your spelling."
        )}
        filtersPlaceholder={text(
          "Filters: Initial message",
          "Add filter(s)..."
        )}
        infoText={text("Subtitle", "If you want to request a class please")}
        backgroundColor={select(
          "Select Background Color",
          ["white", "gray"],
          "white"
        )}
        infoLink={object("Subtitle Info Link", {
          text: "contact us",
          link:
            "mailto:training@refinitiv.com?subject=Client Request for Training",
          newWindow: false
        })}
        noResultsMessage={text(
          "No results message",
          "No results have been found as a result of the table being over constrained. To see results, please try removing filters or try and different combination of filters to find the information you’re looking for."
        )}
        // Intending to pass a value
        // eslint-disable-next-line no-magic-numbers
        pagesNavigableOnSide={number("Pages navigable on side", 2)}
        paginationResultSummaryText={text(
          "Pagination summary",
          "Viewing {1} of {2} results:"
        )}
        // Intending to pass a value
        // eslint-disable-next-line no-magic-numbers
        rowsPerPage={number("Rows per page", 2)}
        subtitleHeadingRank={select("Subtitle Heading Rank", ranks, "3")}
        title={text("Title", "Scheduled Classes (12)")}
        titleHeadingRank={select("Title Heading Rank", ranks, "2")}
        isMulti={false}
        isOverflow={false}
        languageSelectedOption={object("Language Select Option", [
          {
            label: "English",
            value: "English"
          }
        ])}
        timezoneSelectedOption={object("Timezone Select Option", [
          {
            label: "Asia/Calcutta (IST +05:30)",
            value: "Asia/Calcutta"
          }
        ])}
        filterHeader={object("Filters Mapping with Table Headers", {
          "Date & Time": {
            label: "Change Timezone",
            placeholder: "Change TimeZone",
            selector: "timezones"
          },
          Language: {
            label: "Filter Languages",
            placeholder: "All Languages"
          }
        })}
        timezones={[
          {
            label: "Africa/Abidjan (GMT +00:00)",
            value: "Africa/Abidjan"
          },
          {
            label: "Africa/Accra (GMT +00:00)",
            value: "Africa/Accra"
          },
          { label: "Africa/Algiers (CET +01:00)", value: "Africa/Algiers" },
          {
            label: "Africa/Asmara (EAT +03:00)",
            value: "Africa/Asmara"
          },
          {
            label: "Africa/Bamako (GMT +00:00)",
            value: "Africa/Bamako"
          },
          {
            label: "Africa/Windhoek (CAT +02:00)",
            value: "Africa/Windhoek"
          },
          {
            label: "America/Adak (HDT -09:00)",
            value: "America/Adak"
          },
          {
            label: "America/Argentina/Jujuy (-03 -03:00)",
            value: "America/Argentina/Jujuy"
          },
          {
            label: "Asia/Kolkata (IST +05:30)",
            value: "Asia/Kolkata"
          },
          {
            label: "Asia/Omsk (+06 +06:00)",
            value: "Asia/Omsk"
          },
          {
            label: "Asia/Calcutta (IST +05:30)",
            value: "Asia/Calcutta"
          },
          {
            label: "Asia/Manila (CST +08:00)",
            value: "Asia/Manila"
          },
          {
            label: "Singapore (MYT +08:00)",
            value: "Singapore"
          },
          {
            label: "US/Central (CDT -05:00)",
            value: "US/Central"
          },
          {
            label: "Mexico City, Monterrey, Merida (CST -06:00)",
            value: "America/Mexico_City"
          }
        ]}
      />
    </StaticRenderer>
  ));
