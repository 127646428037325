/* eslint-disable no-unused-expressions */
import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

import Typestack from "@emcm-ui/component-typestack";
import Grid from "@emcm-ui/component-grid";

import FilterHeader from "./FilterHeader";
import FilterFooter from "./FilterFooter";
import SavedFilters from "./SavedFilters";

import Environment from "../utils/environment";

import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

class Filter extends Component {
  static displayName = "Filter";

  static propTypes = {
    children: PropTypes.node.isRequired,

    header: PropTypes.string.isRequired,

    filterLabel: PropTypes.string.isRequired,

    filterOpenLabel: PropTypes.string,

    filterCloseLabel: PropTypes.string.isRequired,

    applyFilterButtonLabel: PropTypes.string.isRequired,

    filtersHeader: PropTypes.string.isRequired,

    onDropdownChange: PropTypes.func,

    noSavedFiltersLabel: PropTypes.string.isRequired,

    dropdownKeys: PropTypes.array.isRequired,

    gridProps: PropTypes.object.isRequired,

    savedFilters: PropTypes.object.isRequired,

    onApplyFilter: PropTypes.func,

    onShowFilterBody: PropTypes.func,

    deleteFilter: PropTypes.func,

    saveFilterChecked: PropTypes.bool.isRequired,

    showSaveFilter: PropTypes.bool.isRequired,

    path: PropTypes.string,

    resetFilters: PropTypes.bool,

    updateResetFilters: PropTypes.func,

    disableFilter: PropTypes.bool.isRequired,

    filterChildrenStatus: PropTypes.bool,

    updateFilterChildrenStatus: PropTypes.func
  };

  static defaultProps = {
    savedFilters: {
      data: []
    }
  };

  showFilterBody = () => {
    const isFilterOpened = !this.state.isFilterOpened;

    !isFilterOpened &&
      this.setState({
        saveFilterChecked: false
      });

    this.setState({
      isFilterOpened
    });
    this.props.onShowFilterBody && this.props.onShowFilterBody();
  };

  filterData = () => {
    const { onApplyFilter } = this.props;

    onApplyFilter &&
      onApplyFilter(this.state.filtersSelected, this.state.saveFilterChecked);

    this.setState({
      saveFilterChecked: false,
      isFilterOpened: false
    });
  };

  onSaveFilterCheckboxClick = () => {
    const { saveFilterChecked } = this.state;

    this.setState({
      saveFilterChecked: !saveFilterChecked
    });
  };

  populateFilterValues = item => {
    if (item.filters && item.filters.length > 0) {
      const { onDropdownChange, updateResetFilters } = this.props;

      updateResetFilters && updateResetFilters();
      item.filters.forEach(filterItem => {
        onDropdownChange(filterItem.column, filterItem.values);
        this.onchildValueChange(filterItem.column, filterItem.values);
      });
    }

    this.resetSavedFilters(item);

    const updatedChildren = this.renderChildren(this.props, item);

    this.setState({
      updatedChildren
    });
  };

  resetSavedFilters = item => {
    let { savedFilters } = this.state;

    savedFilters = savedFilters.map(element => {
      if (item && element.name === item.name) {
        <SVGIcon name="success" size="s" />;
      } else {
        <SVGIcon name="filter" size="s" />;
      }

      return element;
    });

    this.setState({
      savedFilters
    });
  };

  onchildValueChange = (key, value) => {
    const { filtersSelected } = this.state;

    filtersSelected[key] = value;
    this.setState({ filtersSelected });
  };

  getFilterValue = (savedFilter, item, key) => {
    const { dropdownKeys } = this.props;

    if (!savedFilter && item.props && item.props.value) {
      this.onchildValueChange(dropdownKeys[key], item.props.value);

      return item.props.value;
    }
    const index = savedFilter.filters.findIndex(filterObj => {
      return filterObj.column === dropdownKeys[key];
    });

    return savedFilter.filters[index] && savedFilter.filters[index].values;
  };

  renderChildren = (props, savedFilter) => {
    const { children, gridProps, dropdownKeys, onDropdownChange } = props;
    const childrenTemplate = children.map((item, key) => {
      const onValueChanged = value => {
        this.onchildValueChange(dropdownKeys[key], value);
        this.resetSavedFilters();
        onDropdownChange(dropdownKeys[key], value);
      };
      const filteredValue = this.getFilterValue(savedFilter, item, key);
      const element = React.cloneElement(item, {
        filteredValue,
        onValueChanged
      });

      return <Grid.Item key={key}>{element}</Grid.Item>;
    });

    return <Grid {...gridProps}>{childrenTemplate}</Grid>;
  };

  componentDidMount() {
    const updatedChildren = this.renderChildren(this.props);

    this.setState({
      updatedChildren
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { savedFilters: pSavedFilters } = prevState;
    const {
      savedFilters,
      resetFilters,
      filterChildrenStatus,
      updateFilterChildrenStatus
    } = this.props;

    if (JSON.stringify(savedFilters.data) !== JSON.stringify(pSavedFilters)) {
      this.setState({
        savedFilters: savedFilters.data
      });
    }
    if (filterChildrenStatus) {
      const updatedChildren = this.renderChildren(this.props);

      this.setState({
        updatedChildren
      });
      updateFilterChildrenStatus && updateFilterChildrenStatus();
    }
    if (prevProps.resetFilters !== resetFilters && resetFilters) {
      this.resetSavedFilters();
      this.setState({
        savedFilters: []
      });
    }
  }

  constructor(props) {
    super(props);

    // dont require path and env
    const path = props.path;

    this.env = new Environment(path);

    this.getClassName = getClassNameFactory(Filter.displayName);

    this.state = {
      isFilterOpened: false,
      filtersSelected: {},
      updatedChildren: null,
      savedFilters: props.savedFilters.data,
      saveFilterChecked: props.saveFilterChecked && props.showSaveFilter
    };
  }

  render() {
    const {
      header,
      filterOpenLabel,
      filterLabel,
      filtersHeader,
      filterCloseLabel,
      applyFilterButtonLabel,
      noSavedFiltersLabel,
      showSaveFilter,
      deleteFilter,
      disableFilter,
      gridProps,
      dropdownKeys
    } = this.props;
    const {
      isFilterOpened,
      savedFilters,
      updatedChildren,
      saveFilterChecked
    } = this.state;

    const isFilterSaved = savedFilters && savedFilters.length > 0;

    if (!isFilterOpened) {
      return (
        <div
          className={this.getClassName()}
          data-rehydratable={getRehydratableName(Filter.displayName)}
          data-button-label={JSON.stringify(applyFilterButtonLabel)}
          data-disable-filter={JSON.stringify(disableFilter)}
          data-dropdown-keys={JSON.stringify(dropdownKeys)}
          data-filter-close-label={JSON.stringify(filterCloseLabel)}
          data-filter-label={JSON.stringify(filterLabel)}
          data-filter-open-label={JSON.stringify(filterOpenLabel)}
          data-grid-props={JSON.stringify(gridProps)}
          data-header={JSON.stringify(header)}
          data-no-saved-filters-label={JSON.stringify(noSavedFiltersLabel)}
          data-saved-filters={JSON.stringify(savedFilters)}
          data-save-filter-checked={JSON.stringify(saveFilterChecked)}
          data-show-save-filter={JSON.stringify(showSaveFilter)}
          data-filters-header={JSON.stringify(filtersHeader)}
        >
          <FilterHeader
            filterOpenLabel={filterOpenLabel}
            header={header}
            isFilterOpened={isFilterOpened}
            showFilterBody={this.showFilterBody}
          />
        </div>
      );
    }

    return (
      <div
        className={this.getClassName()}
        data-rehydratable={getRehydratableName(Filter.displayName)}
        data-button-label={JSON.stringify(applyFilterButtonLabel)}
        data-disable-filter={JSON.stringify(disableFilter)}
        data-dropdown-keys={JSON.stringify(dropdownKeys)}
        data-filter-close-label={JSON.stringify(filterCloseLabel)}
        data-filter-label={JSON.stringify(filterLabel)}
        data-filter-open-label={JSON.stringify(filterOpenLabel)}
        data-grid-props={JSON.stringify(gridProps)}
        data-header={JSON.stringify(header)}
        data-no-saved-filters-label={JSON.stringify(noSavedFiltersLabel)}
        data-saved-filters={JSON.stringify(savedFilters)}
        data-save-filter-checked={JSON.stringify(saveFilterChecked)}
        data-show-save-filter={JSON.stringify(showSaveFilter)}
        data-filters-header={JSON.stringify(filtersHeader)}
      >
        <FilterHeader
          filterOpenLabel={filterOpenLabel}
          header={header}
          isFilterOpened={isFilterOpened}
          showFilterBody={this.showFilterBody}
        />
        <div
          className={this.getClassName({
            descendantName: "filterBodyWrapper"
          })}
        >
          <div className={this.getClassName({ descendantName: "filterInput" })}>
            <div
              className={this.getClassName({ descendantName: "filterBody" })}
            >
              {showSaveFilter && (
                <div
                  className={this.getClassName({
                    descendantName: "savedFilterBodyWrapper"
                  })}
                >
                  <div
                    className={this.getClassName({
                      descendantName: "savedFilterBody"
                    })}
                  >
                    <div
                      className={this.getClassName({
                        descendantName: "savedFilterBodyHeader"
                      })}
                    >
                      <Typestack align="left" rank="h5" type="subline1">
                        {filterLabel}
                      </Typestack>
                    </div>

                    <ul
                      className={this.getClassName({ descendantName: "items" })}
                    >
                      {isFilterSaved &&
                        savedFilters.map((item, i) => (
                          <SavedFilters
                            key={i}
                            name={item.name}
                            onFilterClick={() =>
                              this.populateFilterValues(item)
                            }
                            onDeleteFilterClick={() =>
                              deleteFilter && deleteFilter(item)
                            }
                            iconType={item.iconType}
                          />
                        ))}

                      {!isFilterSaved && (
                        <SavedFilters name={noSavedFiltersLabel} />
                      )}
                    </ul>
                  </div>
                </div>
              )}
              <div
                className={this.getClassName({
                  descendantName: "userFilterBodyWrapper"
                })}
              >
                <div
                  className={this.getClassName({
                    descendantName: "userFilterBodyHeader"
                  })}
                >
                  <Typestack align="left" rank="p" type="p2Bold">
                    {filtersHeader}
                  </Typestack>
                </div>
                <div
                  className={this.getClassName({
                    descendantName: "filterBody"
                  })}
                >
                  {updatedChildren}
                  <FilterFooter
                    filterCloseLabel={filterCloseLabel}
                    applyFilterButtonLabel={applyFilterButtonLabel}
                    showSaveFilter={showSaveFilter}
                    saveFilterChecked={saveFilterChecked}
                    showFilterBody={this.showFilterBody}
                    onSaveFilterCheckboxClick={this.onSaveFilterCheckboxClick}
                    filterData={this.filterData}
                    id={this.props.header}
                    disableFilter={disableFilter}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Filter;
