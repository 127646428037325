import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

class AppNav extends Component {
  static displayName = "AppNav";
  static propTypes = {
    /**
     * Items array
     */
    items: PropTypes.array
  };

  constructor(props) {
    super(props);

    this.getClassName = getClassNameFactory(AppNav.displayName);
    this.notEmpty = -1;
    this.state = {
      activeItemIndex: 0,
      focusItemindex: 0
    };
    this.itemRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      activeItemIndex: this.getActiveItemIndex()
    });
  }

  getActiveItemIndex = () => {
    const pageName = window.location.pathname.split("/").pop();

    return (
      this.props.items &&
      this.props.items.findIndex(item => {
        return item.link.indexOf(pageName) !== this.notEmpty;
      })
    );
  };

  showLabel = item => {
    return (
      <div className={this.getClassName({ descendantName: "label" })}>
        {item.label && item.label}
      </div>
    );
  };

  showNotification = item => {
    return (
      <div className={this.getClassName({ descendantName: "icon" })}>
        {item.icon &&
          item.notification && (
            <div
              className={this.getClassName({
                descendantName: "notification"
              })}
            />
          )}
        {item.icon && (
          <SVGIcon
            name={item.label}
            size="m"
            viewBox="0 0 32 32"
            icon={<path d={item.icon} />}
          />
        )}
      </div>
    );
  };

  setFocusItemByIndex = focusItemindex => {
    const focusElement = this.itemRef.current.children[
      focusItemindex
    ].querySelector("a");

    focusElement.setAttribute("tabindex", "0");
    focusElement.focus();
    this.setState({ focusItemindex });
  };

  handleKeyDown = e => {
    const { focusItemindex } = this.state;
    const { items } = this.props;
    const focusElement = this.itemRef.current.children[
      focusItemindex
    ].querySelector("a");

    if (e.key === "ArrowRight" && focusItemindex < items.length - 1) {
      focusElement.setAttribute("tabindex", this.notEmpty);
      this.setFocusItemByIndex(focusItemindex + 1);
    } else if (e.key === "ArrowLeft" && focusItemindex > 0) {
      focusElement.setAttribute("tabindex", this.notEmpty);
      this.setFocusItemByIndex(focusItemindex - 1);
    }
  };

  getNavItems = items => {
    const { activeItemIndex } = this.state;

    return (
      <ul
        className={this.getClassName({ descendantName: "items" })}
        role="tablist"
        aria-orientation="horizontal"
        ref={this.itemRef}
      >
        {items &&
          items.map((item, index) => (
            <li
              key={index}
              className={this.getClassName({
                states: index === activeItemIndex && "active",
                descendantName: "item",
                className:
                  index === activeItemIndex
                    ? typestack("p3Bold")
                    : typestack("p3")
              })}
              role="presentation"
            >
              <a
                id={`tab-${index}`}
                role="tab"
                aria-selected={index === activeItemIndex}
                href={item.link}
                className={this.getClassName({ descendantName: "link" })}
                onKeyDown={this.handleKeyDown}
                tabIndex={index === activeItemIndex ? "0" : this.notEmpty}
              >
                {this.showNotification(item)}
                {this.showLabel(item)}
              </a>
            </li>
          ))}
      </ul>
    );
  };

  render() {
    const { items } = this.props;

    return (
      <div
        data-items={JSON.stringify(items)}
        className={this.getClassName()}
        data-rehydratable={getRehydratableName(AppNav.displayName)}
      >
        <div
          className={this.getClassName({ descendantName: "children" })}
          data-rehydratable-children
        >
          {this.getNavItems(items)}
        </div>
      </div>
    );
  }
}

AppNav.defaultProps = {
  lineItems: []
};

export default AppNav;
