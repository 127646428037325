import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import classNames from "classnames";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const Chapter = ({ active, href, prefix, title }) => {
  const getClassName = getClassNameFactory("LongformChapterNavChapter");

  return (
    <li
      className={getClassName({ states: classNames({ active }) })}
      data-active={active ? JSON.stringify(active) : null}
      data-href={JSON.stringify(href)}
      data-prefix={prefix ? JSON.stringify(prefix) : null}
      data-title={JSON.stringify(title)}
      data-rehydratable={getRehydratableName(Chapter.displayName)}
    >
      <a
        className={getClassName({
          descendantName: "inner",
          className: typestack("p1")
        })}
        href={href}
      >
        {prefix ? (
          <span
            className={getClassName({
              descendantName: "prefix",
              utilities: "typographySmallCaps"
            })}
          >
            {prefix}
          </span>
        ) : null}

        <span className={getClassName({ descendantName: "title" })}>
          {title}
        </span>
      </a>
    </li>
  );
};

Chapter.displayName = "Longform.ChapterNav.Chapter";

Chapter.propTypes = {
  /**
   * Set this chapter to active. Use for setting the default chapter, or defining the current chapter if not using ChapterNav's `spy` functionality. Will be overwritten when using `spy`.
   */
  active: PropTypes.bool,
  /**
   * URL for the chapter. Should be an anchor if using ChapterNav on a single page (such as with `spy`) or a URL if not. If using on multiple pages, you will need to set the `active` Chapter manually.
   */
  href: PropTypes.string.isRequired,
  /**
   * Prefix for the chapter, such as "1.". Numbers work better than the longhand.
   */
  prefix: PropTypes.string,
  /**
   * Title of the chapter.
   */
  title: PropTypes.string.isRequired
};

export default Chapter;
