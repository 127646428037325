import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import propTypes from "prop-types";
import classNames from "classnames";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const highlightKeywords = (getClassName, phrase, keyword) => {
  const startIndex = phrase.indexOf(keyword);
  const endIndex = startIndex + keyword.length;

  if (startIndex >= 0) {
    return (
      <span>
        {phrase.slice(0, startIndex)}
        <span
          className={getClassName({
            descendantName: "highlight"
          })}
        >
          {keyword}
        </span>
        {phrase.slice(endIndex)}
      </span>
    );
  }

  return <span>{phrase}</span>;
};

const TypeaheadItem = ({
  colorTheme,
  highlight,
  id,
  onClick,
  selected,
  value
}) => {
  const getClassName = getClassNameFactory(TypeaheadItem.displayName);

  return (
    <li
      className={getClassName({
        states: selected ? "selected" : "",
        modifiers: classNames({
          dark: colorTheme === "dark"
        })
      })}
      role="option"
      aria-label="Search results"
      aria-selected={selected ? "true" : "false"}
      id={id}
    >
      <button
        className={getClassName({
          descendantName: "button",
          className: typestack("p1")
        })}
        onClick={onClick}
        type="submit"
        value={value}
      >
        {highlightKeywords(getClassName, value, highlight)}
      </button>
    </li>
  );
};

TypeaheadItem.displayName = "SearchInputTypeaheadItem";

TypeaheadItem.propTypes = {
  colorTheme: propTypes.oneOf(["dark", "light"]),
  highlight: propTypes.string,
  id: propTypes.string,
  onClick: propTypes.func,
  selected: propTypes.bool,
  value: propTypes.string
};

TypeaheadItem.defaultProps = {
  colorTheme: "light"
};

export default TypeaheadItem;
