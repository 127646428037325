import React from "react";
import PropTypes from "prop-types";
import SearchInput from "@emcm-ui/component-search-input";

const SiteFooterSearch = ({
  getClassName,
  searchFormAction,
  searchFormMethod,
  searchInputName,
  typeaheadUrl,
  elasticKey,
  searchMinLength,
  autosuggestion,
  size,
  placeholder,
  searchSubmitAriaLabel,
  searchClearAriaLabel
}) => {
  return (
    <form
      className={getClassName({ descendantName: "search" })}
      action={searchFormAction}
      method={searchFormMethod}
      role="search"
    >
      <div className={getClassName({ descendantName: "searchInner" })}>
        <SearchInput
          searchSubmitAriaLabel={searchSubmitAriaLabel}
          searchClearAriaLabel={searchClearAriaLabel}
          colorTheme="dark"
          name={searchInputName}
          typeaheadUrl={typeaheadUrl}
          elasticKey={elasticKey}
          searchMinLength={searchMinLength}
          autosuggestion={autosuggestion}
          size={size}
          placeholder={placeholder}
        />
      </div>
    </form>
  );
};

SiteFooterSearch.displayName = "SiteFooterSearch";

SiteFooterSearch.propTypes = {
  getClassName: PropTypes.func,
  searchFormAction: PropTypes.string,
  searchFormMethod: PropTypes.string,
  searchInputName: PropTypes.string,
  typeaheadUrl: PropTypes.string,
  elasticKey: PropTypes.string,
  searchMinLength: PropTypes.number,
  autosuggestion: PropTypes.string,
  size: PropTypes.number,
  /**
   * placeholder text for the search input
   */
  placeholder: PropTypes.string,
  /**
   * Search button aria-label
   */
  searchSubmitAriaLabel: PropTypes.string,
  /**
   * Search clear button aria-label
   */
  searchClearAriaLabel: PropTypes.string
};

export default SiteFooterSearch;
