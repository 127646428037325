import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import Typestack from "./index";
import "./Typestack.css";
import TypestackREADME from "../README.md";

const stories = storiesOf("Components/Typestack", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(TypestackREADME));

stories
  .add("Default", () => {
    const storyTypestackStyle = {
      fontSize: "22px",
      fontWeight: "500",
      lineHeight: "24px",
      marginBottom: "8px",
      marginTop: "20px"
    };
    const alignment = select("alignment", Typestack.alignments, "left");

    storyTypestackStyle.textAlign = alignment;

    return (
      <div>
        <p style={storyTypestackStyle}>Headings</p>
        <Typestack align={alignment} rank="h1" type="h1">
          Heading 1
        </Typestack>

        <Typestack align={alignment} rank="h2" type="h2">
          Heading 2
        </Typestack>

        <Typestack align={alignment} rank="h3" type="h3">
          Heading 3
        </Typestack>

        <Typestack align={alignment} rank="h4" type="h4">
          Heading 4
        </Typestack>

        <Typestack align={alignment} rank="h5" type="h5">
          Heading 5
        </Typestack>

        <p style={storyTypestackStyle}>Sublines</p>
        <Typestack align={alignment} rank="h5" type="subline1">
          Subline 1
        </Typestack>

        <Typestack align={alignment} rank="h5" type="subline2">
          Subline 2
        </Typestack>

        <p style={storyTypestackStyle}>Content</p>
        <Typestack align={alignment} rank="p" type="p1">
          P1 Default
        </Typestack>

        <Typestack align={alignment} rank="p" type="p1Bold">
          P1 Bold
        </Typestack>

        <Typestack align={alignment} rank="p" type="p1Semibold">
          P1 Semibold
        </Typestack>

        <Typestack align={alignment} rank="p" type="p1Link">
          P1 Link
        </Typestack>

        <Typestack align={alignment} rank="p" type="p2">
          P2 Default
        </Typestack>

        <Typestack align={alignment} rank="p" type="p2Bold">
          P2 Bold
        </Typestack>

        <Typestack align={alignment} rank="p" type="p2Link">
          P2 Link
        </Typestack>

        <Typestack align={alignment} rank="p" type="p3">
          P3 Default
        </Typestack>

        <Typestack align={alignment} rank="p" type="p3Bold">
          P3 Bold
        </Typestack>

        <Typestack align={alignment} rank="p" type="p3Link">
          P3 Link
        </Typestack>

        <p style={storyTypestackStyle}>Button</p>
        <Typestack align={alignment} rank="p" type="buttonBold">
          Button Bold
        </Typestack>

        <p style={storyTypestackStyle}>Quotes</p>
        <Typestack align={alignment} rank="p" type="quoteLarge">
          Quote Large
        </Typestack>

        <Typestack align={alignment} rank="p" type="quoteSmall">
          Quote Small
        </Typestack>

        <p style={storyTypestackStyle}>Motto navigation</p>
        <Typestack align={alignment} rank="p" type="navRegular">
          Motto Nav Regular
        </Typestack>

        <Typestack align={alignment} rank="p" type="navSemibold">
          Motto Nav Semibold
        </Typestack>

        <Typestack align={alignment} rank="p" type="navRegularSmall">
          Motto Nav Regular Small
        </Typestack>

        <Typestack align={alignment} rank="p" type="navSemiboldSmall">
          Motto Nav Semibold Small
        </Typestack>
      </div>
    );
  })
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Typestack align="left" rank="h1" type="h1">
        {text("Text", "مرحبا بالعالم")}
      </Typestack>

      <Typestack align="left" rank="h2" type="h2">
        {text("Text", "مرحبا بالعالم")}
      </Typestack>

      <Typestack align="left" rank="h3" type="h3">
        {text("Text", "مرحبا بالعالم")}
      </Typestack>

      <Typestack align="left" rank="h4" type="h4">
        {text("Text", "مرحبا بالعالم")}
      </Typestack>

      <Typestack align="left" rank="h5" type="h5">
        {text("Text", "مرحبا بالعالم")}
      </Typestack>
    </Language>
  ));
