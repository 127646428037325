import React from "react";
import CartSummary from "./CartSummary";

export default async (domNode, rehydrateChildren) => {
  const childrenNode = domNode.querySelector("[data-rehydratable-children]");
  const reactifiedChildren = await rehydrateChildren(childrenNode);

  const props = {
    priceLabel: domNode.getAttribute("data-price-label"),
    licenseLabel: domNode.getAttribute("data-license-label"),
    licenseUnit: domNode.getAttribute("data-license-unit"),
    timeframe: domNode.getAttribute("data-timeframe"),
    products: JSON.parse(domNode.getAttribute("data-products")),
    isRadio: JSON.parse(domNode.getAttribute("data-radio-variant"))
  };

  return <CartSummary {...props}>{reactifiedChildren}</CartSummary>;
};
