import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text, object, boolean } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import FeaturedCards from "./index";
import "./FeaturedCards.css";
import SearchResultsMixtureREADME from ".../../../README.md";
import * as data from "../../../__mocks__/featuredMock.json";
import { FEATURED_SECTION_CARDS_COUNT, ICON } from "../../utilities/constants";

const stories = storiesOf(
  "Components/SearchMixedCard/Components/FeaturedCards",
  module
);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(SearchResultsMixtureREADME));

stories
  .add("Default", () => {
    const includeDescription = boolean(
      "Include description",
      true,
      "Attributes"
    );
    const featured = {
      featuredText: text("Featured text", "Featured", "Attributes"),
      featuredCards: object(
        "cards",
        data.searchResults.slice(0, FEATURED_SECTION_CARDS_COUNT),
        "Data"
      )
    };

    return (
      <FeaturedCards
        featured={featured}
        includeDescription={includeDescription}
        icon={ICON}
        includeReadTime={true}
        minLabel="min"
      />
    );
  })
  .add("Language: Arabic (RTL)", () => {
    const includeDescription = boolean(
      "Include description",
      true,
      "Attributes"
    );
    const featured = {
      featuredText: text("Featured text", "Featured", "Attributes"),
      featuredCards: object(
        "cards",
        data.searchResults.slice(0, FEATURED_SECTION_CARDS_COUNT),
        "Data"
      )
    };

    return (
      <Language code="ar">
        <FeaturedCards
          featured={featured}
          includeDescription={includeDescription}
          icon={ICON}
          includeReadTime={true}
          minLabel="min"
        />
      </Language>
    );
  });
