import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import CardBase from "../../bases/CardBase";
import Eyebrow from "@emcm-ui/component-eyebrow";
import TwitterCardTimeline from "./TwitterCardTimeline";

class TwitterCard extends Component {
  static propTypes = {
    /**
     * Eyebrow to display above the timeline
     */
    eyebrow: PropTypes.string,
    /**
     * Data source for the timeline. Conforms to the [Twitter specification for createTimeline](https://dev.twitter.com/web/javascript/creating-widgets#timelines)
     */
    timelineDataSource: PropTypes.object.isRequired,
    /**
     * Display options for the timeline. Conforms to the [Twitter specification for createTimeline](https://dev.twitter.com/web/javascript/creating-widgets#timelines)
     */
    timelineOptions: PropTypes.object,
    /**
     * Card border variations
     */
    borderVariant: PropTypes.oneOf([null, "borderless"])
  };

  static defaultProps = {
    timelineOptions: {
      chrome: "noheader",
      linkColor: "#005da2",
      dnt: true
    },
    borderVariant: null
  };

  static displayName = "TwitterCard";

  render() {
    const {
      borderVariant,
      eyebrow,
      timelineDataSource,
      timelineOptions
    } = this.props;
    const getClassName = getClassNameFactory(TwitterCard.displayName);

    return (
      <div className={getClassName()}>
        <CardBase borderVariant={borderVariant} size="large">
          <div className={getClassName({ descendantName: "wrapper" })}>
            <div className={getClassName({ descendantName: "inner" })}>
              <div className={getClassName({ descendantName: "eyebrow" })}>
                <Eyebrow text={eyebrow} />
              </div>

              <div className={getClassName({ descendantName: "timeline" })}>
                <TwitterCardTimeline
                  timelineOptions={timelineOptions}
                  timelineDataSource={timelineDataSource}
                />
              </div>
            </div>
          </div>
        </CardBase>
      </div>
    );
  }
}

export default TwitterCard;
