import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import className from "classnames";

const Typestack = ({ align, children, rank, type, id }) => {
  const getClassName = getClassNameFactory(Typestack.displayName);

  let TypestackTag = "span";

  if (Typestack.ranks.indexOf(rank) >= 0) {
    TypestackTag = `${rank}`;
  }

  return (
    <TypestackTag
      className={getClassName({
        modifiers: className({
          [type]: true,
          [align]: align !== "left"
        })
      })}
      id={id}
    >
      {children}
    </TypestackTag>
  );
};

Typestack.alignments = ["center", "left", "right"];
Typestack.ranks = ["h1", "h2", "h3", "h4", "h5", "p"];
Typestack.types = [
  "buttonBold",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "subline1",
  "subline2",
  "p1",
  "p1Bold",
  "p1Semibold",
  "p1Link",
  "p2",
  "p2Bold",
  "p2Link",
  "p3",
  "p3Bold",
  "p3Link",
  "quoteLarge",
  "quoteSmall",
  "navRegular",
  "navSemibold",
  "navRegularSmall",
  "navSemiboldSmall"
];

Typestack.displayName = "Typestack";

Typestack.propTypes = {
  /**
   * Typestack children.
   */
  align: PropTypes.oneOf(Typestack.alignments),
  /**
   * Typestack children.
   */
  children: PropTypes.node,
  /**
   * Typestack rank. 1 will render `<h1 />`, and so on.
   * If null, will render a `<div />`.
   */
  rank: PropTypes.oneOf(Typestack.ranks),
  /**
   * Style of typestack.
   */
  type: PropTypes.oneOf(Typestack.types).isRequired,
  /**
   * Primarily used to group accessible elements Example.
   * aria-labelledby.
   */
  id: PropTypes.string
};

Typestack.defaultProps = {
  align: "left"
};

export default Typestack;
