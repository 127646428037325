import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Tooltip from "@emcm-ui/component-tooltip";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import Badge from "@emcm-ui/component-badge";
import Expandable from "@emcm-ui/component-expandable";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";
import Button from "@emcm-ui/component-button";
import Typestack from "@emcm-ui/component-typestack";
import RichText from "@emcm-ui/component-rich-text";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities/typestack";

class PlanCard extends Component {
  static displayName = "PlanCard";

  static propTypes = {
    /**
     * Description of the plancard
     */
    children: PropTypes.node,
    /**
     * Unique identifier for the plancard
     */
    id: PropTypes.string.isRequired,
    /**
     * Heading of the plancard
     */
    heading: PropTypes.string.isRequired,
    /**
     * Heading rank of the plancard
     */
    headingRank: PropTypes.string.isRequired,
    /**
     * Check for value points required or not for the plancard
     */
    isValuePointsRequired: PropTypes.bool,
    /**
     * Amout or text for the value points
     */
    valuePoints: PropTypes.string,
    /**
     * Information regarding the value points
     */
    valuePointsInfo: PropTypes.string,
    /**
     * Price for the plancard
     */
    price: PropTypes.string,
    /**
     * Price heading rank for the plancard
     */
    priceHeadingRank: PropTypes.string,
    /**
     * Information about the price for the plancard
     */
    priceInfo: PropTypes.string,
    /**
     * Additional information about the price for the plancard
     */
    priceNote: PropTypes.string,
    /**
     * Link for the first button
     */
    href: PropTypes.string,
    /**
     * Text for the first button
     */
    buttonText: PropTypes.string,
    /**
     * Color of the first button
     */
    buttonColor: PropTypes.string,
    /**
     * Link for the second button
     */
    secondButtonHref: PropTypes.string,
    /**
     * Text for the second button
     */
    secondButtonText: PropTypes.string,
    /**
     * Color of the second button
     */
    secondButtonColor: PropTypes.string,
    /**
     * Check for show more section required or not
     */
    isShowMoreRequired: PropTypes.bool,
    /**
     * Text for show more
     */
    showMoreText: PropTypes.string,
    /**
     * Text for show less
     */
    showLessText: PropTypes.string,
    /**
     * Check for badge required or not in the plancard
     */
    isBadgeRequired: PropTypes.bool,
    /**
     * Text for the badge of the card
     */
    badgeText: PropTypes.string,
    /**
     * Type of the badge of the card
     */
    badgeType: PropTypes.string,
    /**
     * Badge background
     */
    background: PropTypes.string,
    /**
     * Color for the badge of the card
     */
    badgeColor: PropTypes.string,
    /**
     * Tooltip for the bullet points required or not
     */
    isTooltipRequired: PropTypes.bool,
    /**
     * Bullet points for the card
     */
    bulletPoints: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        feature: PropTypes.string,
        helpText: PropTypes.string
      })
    )
  };

  getClassName = getClassNameFactory(PlanCard.displayName);

  constructor(props) {
    super(props);

    this.visibleListCount = 2;
    this.getClassName = getClassNameFactory(PlanCard.displayName);
  }

  tooltipContainer = helpText => {
    return (
      helpText && (
        <div
          className={this.getClassName({ descendantName: "tooltip-container" })}
        >
          <Tooltip tooltipText={helpText} position="top">
            {<SVGIcon name="question-07" size="xs" />}
          </Tooltip>
        </div>
      )
    );
  };

  createBulletPoints = (
    visibleBulletPoints,
    isShowMoreRequired,
    hiddenBulletPoints
  ) => {
    return (
      <ul
        className={this.getClassName({ descendantName: "visibleBulletPoints" })}
      >
        {visibleBulletPoints}
        {!isShowMoreRequired && hiddenBulletPoints}
      </ul>
    );
  };

  additionalList = (showLessText, showMoreText, hiddenBulletPoints) => {
    return (
      <div
        className={this.getClassName({ descendantName: "showmoreContainer" })}
      >
        <Expandable
          direction="up"
          expandedTitle={showLessText}
          type="showMore"
          title={showMoreText}
          headingType="p1Bold"
        >
          <ul
            className={this.getClassName({
              descendantName: "hiddenBulletPointslist"
            })}
          >
            {hiddenBulletPoints}
          </ul>
        </Expandable>
      </div>
    );
  };

  render() {
    const {
      children,
      id,
      heading,
      headingRank,
      isValuePointsRequired,
      valuePoints,
      valuePointsInfo,
      price,
      priceHeadingRank,
      priceInfo,
      priceNote,
      href,
      buttonText,
      buttonColor,
      secondButtonText,
      secondButtonHref,
      secondButtonColor,
      isShowMoreRequired,
      showMoreText,
      showLessText,
      isBadgeRequired,
      badgeText,
      badgeType,
      background,
      badgeColor,
      isTooltipRequired,
      bulletPoints
    } = this.props;

    const visibleBulletPoints = bulletPoints.slice(0, this.visibleListCount);

    const hiddenBulletPoints = bulletPoints.slice(
      this.visibleListCount,
      bulletPoints.length
    );

    const visibleBulletPointsList = visibleBulletPoints.map(item => (
      <li
        className={this.getClassName({ descendantName: "bulletitem" })}
        key={item.id}
        role="listitem"
      >
        <p
          className={this.getClassName({
            descendantName: "bulletitemtext",
            className: typestack("p1")
          })}
        >
          {item.feature}
        </p>
        {isTooltipRequired && this.tooltipContainer(item.helpText)}
      </li>
    ));

    const hiddenBulletPointsList = hiddenBulletPoints.map(item => (
      <li
        className={this.getClassName({ descendantName: "bulletitem" })}
        key={item.id}
        role="listitem"
      >
        <p className={this.getClassName({ descendantName: "bulletitemtext" })}>
          {item.feature}
        </p>
        {isTooltipRequired && this.tooltipContainer(item.helpText)}
      </li>
    ));

    const isShowMoreValid =
      isShowMoreRequired && bulletPoints.length > this.visibleListCount;

    const priceDescriptionId = id && `price-description-${id}`;

    const priceNoteId = id && `price-note-${id}`;

    const createHeader = () => {
      return (
        <header className={this.getClassName({ descendantName: "header" })}>
          <div
            className={this.getClassName({
              descendantName: "heading-container"
            })}
          >
            {heading && (
              <div
                className={this.getClassName({
                  descendantName: "heading"
                })}
              >
                <Typestack type="h2" rank={headingRank}>
                  {heading}
                </Typestack>
              </div>
            )}
            {isBadgeRequired &&
              badgeText && (
                <Badge
                  text={badgeText}
                  type={badgeType}
                  background={background}
                  color={badgeColor}
                />
              )}
          </div>
          <div
            className={this.getClassName({
              descendantName: "price-container"
            })}
          >
            {price && (
              <div
                className={this.getClassName({
                  descendantName: "price"
                })}
              >
                <Typestack type="h1" rank={priceHeadingRank}>
                  {price}
                </Typestack>
              </div>
            )}
            {priceInfo && (
              <p
                className={this.getClassName({
                  descendantName: "priceinfo",
                  className: typestack("p1")
                })}
                id={priceDescriptionId}
              >
                {priceInfo}
              </p>
            )}
            {priceNote && (
              <p
                className={this.getClassName({
                  descendantName: "pricenote",
                  className: typestack("p3")
                })}
                id={priceNoteId}
              >
                {priceNote}
              </p>
            )}
          </div>

          {isValuePointsRequired && (
            <div className={this.getClassName({ descendantName: "points" })}>
              {valuePoints && (
                <span
                  className={this.getClassName({
                    descendantName: "points-value",
                    className: typestack("h4")
                  })}
                >
                  {valuePoints}
                </span>
              )}
              {valuePointsInfo && (
                <span
                  className={this.getClassName({
                    descendantName: "points-info",
                    className: typestack("p3")
                  })}
                >
                  {valuePointsInfo}
                </span>
              )}
            </div>
          )}
        </header>
      );
    };

    const buttongroup = () => {
      return (
        <section
          className={this.getClassName({
            descendantName: "footer"
          })}
        >
          <VerticalSpacing size="s">
            {secondButtonText && (
              <Button
                color={secondButtonColor}
                disabled={false}
                href={secondButtonHref}
                kind="link"
                tabIndex="0"
                ariaLabel={secondButtonText}
                block={true}
              >
                {secondButtonText}
              </Button>
            )}
          </VerticalSpacing>
          {buttonText && (
            <Button
              color={buttonColor}
              disabled={false}
              href={href}
              kind="link"
              tabIndex="0"
              ariaLabel={buttonText}
              block={true}
            >
              {buttonText}
            </Button>
          )}
        </section>
      );
    };

    return (
      <section
        className={this.getClassName()}
        data-rehydratable={getRehydratableName(PlanCard.displayName)}
        data-id={id}
        data-price={price}
        data-price-heading-rank={priceHeadingRank}
        data-heading={heading}
        data-heading-rank={headingRank}
        data-value-points-required={isValuePointsRequired}
        data-value-points={valuePoints}
        data-value-points-info={valuePointsInfo}
        data-price-info={priceInfo}
        data-price-note={priceNote}
        data-href={href}
        data-button-text={buttonText}
        data-button-color={buttonColor}
        data-second-button-text={secondButtonText}
        data-second-button-href={secondButtonHref}
        data-second-button-color={secondButtonColor}
        data-bullet-points={JSON.stringify(bulletPoints)}
        data-tooltip-required={isTooltipRequired}
        data-show-more-required={isShowMoreRequired}
        data-show-more-text={showMoreText}
        data-show-less-text={showLessText}
        data-badge-required={isBadgeRequired}
        data-badge-text={badgeText}
        data-badge-type={badgeType}
        data-badge-color={badgeColor}
        data-badge-background={background}
      >
        {createHeader()}

        {children && (
          <section
            className={this.getClassName({
              descendantName: "description"
            })}
            data-rehydratable-children
          >
            {children}
          </section>
        )}

        <section
          className={this.getClassName({ descendantName: "points-wrapper" })}
        >
          {bulletPoints &&
            this.createBulletPoints(
              visibleBulletPointsList,
              isShowMoreRequired,
              hiddenBulletPointsList
            )}

          {isShowMoreValid &&
            this.additionalList(
              showLessText,
              showMoreText,
              hiddenBulletPointsList
            )}
        </section>

        {buttongroup()}
      </section>
    );
  }
}

PlanCard.RichText = RichText;

PlanCard.defaultProps = {
  id: "plancard1",
  heading: "Heading",
  headingRank: "h2",
  isValuePointsRequired: false,
  isShowMoreRequired: false,
  showMoreText: "Show More",
  showLessText: "Show Less",
  isBadgeRequired: true,
  badgeText: "NEW",
  badgeType: "pill",
  background: "dark",
  badgeColor: "blue",
  isTooltipRequired: false
};

export default PlanCard;
