export const tabEvents = {
  getSelectedTabCount: state => {
    const { tabs, currentOpenPanel } = state;

    return (
      tabs
        .map(e => {
          return e.relatedId;
        })
        .indexOf(currentOpenPanel) + 1
    );
  },
  getTabListItem: (currentOpenPanel, tabElement) => {
    return tabElement.querySelector(`#tab-${currentOpenPanel}`).parentElement
      .parentElement;
  },
  getTabListWidth: tabListItem => {
    return tabListItem.clientWidth;
  },
  getTabListElement: tabListItem => {
    return tabListItem.parentElement;
  },
  getMaxTabCount: tabWidth => {
    return Math.floor(window.innerWidth / tabWidth);
  },
  showTabFromLeft: (tabElement, visibleFromRight) => {
    tabElement.classList.add(visibleFromRight);
  },
  showTabFromRight: (tabElement, visibleFromLeft) => {
    tabElement.classList.add(visibleFromLeft);
  },
  showTabFromBohSides: (tabElement, visibleFromRight, visibleFromLeft) => {
    tabElement.classList.add(visibleFromLeft);
    tabElement.classList.add(visibleFromRight);
  },
  scrollToPosition: (tabElement, scrollX) => {
    tabElement.scrollTo(scrollX, 0);
  },
  getRequiredScroll: (selectedTabCount, maxTabCount, trackerConfigurations) => {
    const { tabWidth, adjustPosition } = trackerConfigurations;

    return (selectedTabCount - maxTabCount) * tabWidth + adjustPosition;
  },
  isRightAlignedOnTablet: (
    selectedTabCount,
    tabCount,
    trackerConfigurations
  ) => {
    const { tabWidth, progressDividerNumber } = trackerConfigurations;

    return (
      selectedTabCount > tabCount / progressDividerNumber &&
      tabWidth * tabCount > window.innerWidth
    );
  }
};

export const position = {
  adjustPositionOnMobileClick: tabDetails => {
    const {
      state,
      tabElement,
      visibleFromRight,
      visibleFromLeft,
      trackerConfigurations
    } = tabDetails;

    const { tabs, currentOpenPanel } = state;
    const tabListItem = tabEvents.getTabListItem(currentOpenPanel, tabElement);
    const { tabWidth } = trackerConfigurations;
    const tabListWidth = tabEvents.getTabListWidth(tabListItem);
    const tabListElement = tabEvents.getTabListElement(tabListItem);
    const maxTabCount = tabEvents.getMaxTabCount(tabWidth);
    const tabCount = tabs.length;
    const selectedTabCount = tabEvents.getSelectedTabCount(state);

    if (selectedTabCount <= maxTabCount) {
      tabEvents.showTabFromLeft(tabElement, visibleFromRight);
      tabEvents.scrollToPosition(tabListElement, 0);
    } else if (selectedTabCount > tabCount - maxTabCount) {
      tabEvents.showTabFromRight(tabElement, visibleFromLeft);
      tabEvents.scrollToPosition(tabListElement, tabListWidth);
    } else {
      tabEvents.showTabFromBohSides(
        tabElement,
        visibleFromRight,
        visibleFromLeft
      );
      const requiredScroll = tabEvents.getRequiredScroll(
        selectedTabCount,
        maxTabCount,
        trackerConfigurations
      );

      tabEvents.scrollToPosition(tabListElement, requiredScroll);
    }
  },
  adjustPositionOnTabletClick: tabDetails => {
    const {
      state,
      tabElement,
      visibleFromRight,
      visibleFromLeft,
      trackerConfigurations
    } = tabDetails;

    const { tabs } = state;
    const tabCount = tabs.length;
    const selectedTabCount = tabEvents.getSelectedTabCount(state);
    const isRightAlignRequired = tabEvents.isRightAlignedOnTablet(
      selectedTabCount,
      tabCount,
      trackerConfigurations
    );

    const directionClass = isRightAlignRequired
      ? visibleFromRight
      : visibleFromLeft;

    tabElement.classList.add(directionClass);
  },
  fixTabFromRight: tabDetails => {
    const {
      tabElement,
      visibleFromRight,
      visibleFromLeft,
      trackerConfigurations
    } = tabDetails;

    if (
      tabElement &&
      window.innerWidth <= trackerConfigurations.tabletMaxWidth
    ) {
      tabElement.classList.remove(visibleFromLeft);
      tabElement.classList.remove(visibleFromRight);

      const isTablet =
        window.innerWidth >= trackerConfigurations.tabletMinWidth;

      if (isTablet) {
        position.adjustPositionOnTabletClick(tabDetails);
      } else {
        position.adjustPositionOnMobileClick(tabDetails);
      }
    }
  }
};
