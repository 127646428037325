import getAnalytics from "./analytics";
import ConsoleAnalytics from "./ConsoleAnalytics";
import StorybookAnalytics from "./StorybookAnalytics";
import NullAnalytics from "./NullAnalytics";
import canFireEvents from "./checkAnalytics";
import analyticsClasses from "./analyticsClasses";

export default getAnalytics;
export {
  ConsoleAnalytics,
  StorybookAnalytics,
  NullAnalytics,
  canFireEvents,
  analyticsClasses
};
