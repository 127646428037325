import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, number, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import DateSelect from "./index";
import "./DateSelect.css";
import DateSelectREADME from "../README.md";
import StaticRenderer from "@emcm-ui/component-static-renderer";

const stories = storiesOf("Components/DateSelect", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(DateSelectREADME));

stories
  .add("Default", () => (
    <DateSelect
      dateFormatter="DD YY MMMM"
      variant="default"
      onDateChanged={() => {}}
      yearRange={{
        upper: 2028,
        lower: 2001
      }}
    />
  ))
  .add("Months and Years", () => (
    <DateSelect
      dateFormatter="DD MMMM YY"
      variant="monthsAndYears"
      onDateChanged={() => {}}
      yearRange={{
        upper: 2028,
        lower: 2001
      }}
    />
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <DateSelect
        dateFormatter="DD MMMM YYYY"
        variant="monthsAndYears"
        yearOrder={select("Year Order", ["ASC", "DESC"], "DESC")}
        yearRange={{
          upper: 2028,
          lower: 2001
        }}
        numberOfYears={number("Number of Years", 0)}
      />
    </StaticRenderer>
  ));
