import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const EventInfoFooter = ({ eventDate, eventTime, location, category }) => {
  const getClassName = getClassNameFactory("SearchResults");

  const separator = () => (
    <span
      aria-hidden
      className={getClassName({
        descendantName: "eventInfoFooterDot"
      })}
      role="presentation"
    >
      {" • "}
    </span>
  );

  return (
    <div
      className={getClassName({
        descendantName: "eventInfoFooter",
        className: typestack("p3")
      })}
    >
      <div
        className={getClassName({
          descendantName: "eventInfoFooterMetadata"
        })}
      >
        <span
          className={getClassName({
            descendantName: "eventInfoFooterDate"
          })}
        >
          {eventDate}
        </span>
        {eventTime && (
          <React.Fragment>
            {separator()}
            <span
              className={getClassName({
                descendantName: "eventInfoFooterTime"
              })}
            >
              {eventTime}
            </span>
          </React.Fragment>
        )}
        {location && (
          <React.Fragment>
            {separator()}
            <span
              className={getClassName({
                descendantName: "eventInfoFooterLocation"
              })}
            >
              {location}
            </span>
          </React.Fragment>
        )}
        {category && (
          <React.Fragment>
            {separator()}
            <span
              className={getClassName({
                descendantName: "eventInfoFooterCategory"
              })}
            >
              {category}
            </span>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

EventInfoFooter.propTypes = {
  /**
   * Event start and end date
   */
  eventDate: PropTypes.string,

  /**
   * Event time
   */
  eventTime: PropTypes.string,

  /**
   * Event location
   */
  location: PropTypes.string,

  /**
   * Event category
   */
  category: PropTypes.string
};

export default EventInfoFooter;
