import React from "react";

import Attribution from "@emcm-ui/component-attribution";
import Button from "@emcm-ui/component-button";
import {
  TextCard,
  ImageCard,
  PairedCard,
  PairedHorizontalCard,
  QuoteCard,
  ArticleCardFooter,
  LinkCardFooter,
  VideoCardFooter
} from "@emcm-ui/component-card";
import Eyebrow from "@emcm-ui/component-eyebrow";
import Grid from "@emcm-ui/component-grid";
import Heading from "@emcm-ui/component-heading";
import MarkupContainer from "@emcm-ui/component-markup-container";
import Poster from "@emcm-ui/component-poster";
import SharePriceTracker from "@emcm-ui/component-share-price-tracker";
import Section from "@emcm-ui/component-section";
import SemanticMain from "@emcm-ui/component-semantic-main";
import Tabs from "@emcm-ui/component-tabs";
import Ticker from "@emcm-ui/component-ticker";
import TinyTile from "@emcm-ui/component-tiny-tile";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";
import Icon from "@emcm-ui/component-icon";
import SampleSchemaOrganization from "../../fragments/schema-organization";
import SampleFooter from "../../fragments/footer";
import SampleHeader from "../../fragments/header";

import articleHeadshot from "../../sampleAssets/Article-headshot.jpg";
import articleLadyJustice from "../../sampleAssets/Article-lady-justice.jpg";
import articleLadyJusticeMobile from "../../sampleAssets/Article-lady-justice.mob.jpg";

import imgLarge1 from "../../sampleAssets/large_1.1x.jpg";
import imgLarge2 from "../../sampleAssets/large_2.1x.jpg";
import imgLarge3 from "../../sampleAssets/large_3.1x.jpg";
import imgLarge4 from "../../sampleAssets/large_4.1x.jpg";
import imgLarge5 from "../../sampleAssets/large_5.1x.jpg";
import imgLarge6 from "../../sampleAssets/large_6.1x.jpg";
import imgLarge7 from "../../sampleAssets/large_7.1x.jpg";

const Icons = {
  arrowRightBlue: "./icons/ui/blue/arrow-right.svg",
  arrowRightWhite: "./icons/ui/white/arrow-right.svg",
  time: "./icons/ui/grey/time-07.svg",
  timeWhite: "./icons/ui/white/time.svg",
  plusBlue: "./icons/ui/blue/plus.svg",
  playtriangle: "./icons/ui/grey/playoutline-07.svg",
  playtriangleWhite: "./icons/ui/white/playtriangle.svg",

  home: "./icons/nucleo/energy-environment/small/home.svg",
  atom: "./icons/nucleo/healthcare-medical/small/atom.svg",
  carFront: "./icons/nucleo/transportation/small/car-front.svg",
  flaskConical: "./icons/nucleo/healthcare-medical/small/flask-conical.svg"
};

const gridVariant1 = (
  <React.Fragment>
    <VerticalSpacing size="l">
      <Grid variant="2/3,1/3">
        <Grid.Item>
          <ImageCard
            href="https://www.example.com"
            size="large"
            eyebrow="Eyebrow"
            heading="Heading"
            footer={
              <ArticleCardFooter
                attributionName="Sophie P."
                dateTime="2016-03-02 13:57"
                readingTime="5 min"
                icon={<Icon path={Icons.time} size="xs" />}
              />
            }
            imgSrc={imgLarge2}
            imgAnchor="C"
            imgGradient
            largeHeading
          />
        </Grid.Item>
        <Grid.Item>
          <PairedCard
            borderVariant="borderless"
            href="https://www.example.com"
            eyebrow="Eyebrow"
            heading="Heading"
            footer={
              <ArticleCardFooter
                attributionName="Sophie P."
                dateTime="2016-03-02 13:57"
                readingTime="5 min"
                icon={<Icon path={Icons.time} size="xs" />}
              />
            }
            imgSrc={imgLarge3}
            imgAnchor="C"
          />
        </Grid.Item>
      </Grid>
    </VerticalSpacing>
    <VerticalSpacing size="l">
      <Grid variant="collage-1">
        <Grid.Item>
          <ImageCard
            href="https://www.example.com"
            size="small"
            eyebrow="Eyebrow"
            heading="Heading"
            footer={
              <ArticleCardFooter
                attributionName="Sophie P."
                dateTime="2016-03-02 13:57"
                readingTime="5 min"
                icon={<Icon path={Icons.time} size="xs" />}
              />
            }
            imgSrc={imgLarge4}
            imgAnchor="C"
            imgGradient
          />
        </Grid.Item>
        <Grid.Item>
          <ImageCard
            href="https://www.example.com"
            size="small"
            eyebrow="Eyebrow"
            heading="Heading"
            footer={
              <ArticleCardFooter
                attributionName="Sophie P."
                dateTime="2016-03-02 13:57"
                readingTime="5 min"
                icon={<Icon path={Icons.time} size="xs" />}
              />
            }
            imgSrc={imgLarge5}
            imgAnchor="C"
            imgGradient
          />
        </Grid.Item>
        <Grid.Item>
          <ImageCard
            href="https://www.example.com"
            size="large"
            eyebrow="Eyebrow"
            heading="Heading"
            footer={
              <ArticleCardFooter
                attributionName="Sophie P."
                dateTime="2016-03-02 13:57"
                readingTime="5 min"
                icon={<Icon path={Icons.time} size="xs" />}
              />
            }
            imgSrc={imgLarge6}
            imgAnchor="C"
            imgGradient
          />
        </Grid.Item>
        <Grid.Item>
          <PairedHorizontalCard
            borderVariant="borderless"
            href="https://www.example.com"
            eyebrow="Eyebrow"
            heading="Heading"
            footer={
              <ArticleCardFooter
                attributionName="Sophie P."
                dateTime="2016-03-02 13:57"
                readingTime="5 min"
                icon={<Icon path={Icons.time} size="xs" />}
              />
            }
            imgSrc={imgLarge7}
            imgAnchor="C"
          />
        </Grid.Item>
      </Grid>
    </VerticalSpacing>
  </React.Fragment>
);

const gridVariant2 = (
  <React.Fragment>
    <VerticalSpacing size="l">
      <Grid variant="2/3,1/3">
        <Grid.Item>
          <ImageCard
            href="https://www.example.com"
            size="large"
            eyebrow="Case Study"
            heading="Regulating Bitcoin: Crypto&shy;currency, commodity or gambling?"
            footer={
              <ArticleCardFooter
                dateTime="2016-02-28 13:57"
                readingTime="6 min"
                icon={<Icon path={Icons.timeWhite} size="xs" />}
              />
            }
            imgSrc={imgLarge1}
            imgAnchor="C"
            imgGradient
            largeHeading
          />
        </Grid.Item>
        <Grid.Item>
          <QuoteCard
            attribution={
              <Attribution
                imgSrc={articleHeadshot}
                name="Casey S."
                caption="Managing director of corporate law"
              />
            }
            eyebrow="Our Experts"
            text="You wouldn’t sit idly by while your competitors snatched your customers, cornered the market on innovation, or pilfered your talent."
            href="https://www.example.com"
            borderVariant="borderless"
          />
        </Grid.Item>
      </Grid>
    </VerticalSpacing>
    <VerticalSpacing size="l">
      <Grid variant="collage-2">
        <Grid.Item>
          <ImageCard
            href="https://www.example.com"
            size="small"
            eyebrow="Products"
            heading="Power your enterprise workflow with Elektron"
            footer={
              <LinkCardFooter
                title="Learn more about Elektron"
                type="icon"
                icon={<Icon path={Icons.arrowRightWhite} size="s" />}
              />
            }
            imgSrc={imgLarge4}
            imgAnchor="C"
            imgGradient
          />
        </Grid.Item>
        <Grid.Item>
          <ImageCard
            href="https://www.example.com"
            size="small"
            eyebrow="Our experts"
            heading="Dr. Tharindi Hapuarachchi"
            footer={
              <LinkCardFooter
                title="View profile"
                type="icon"
                icon={<Icon path={Icons.arrowRightWhite} size="s" />}
              />
            }
            imgSrc={imgLarge5}
            imgAnchor="C"
            imgGradient
          />
        </Grid.Item>
        <Grid.Item>
          <PairedCard
            borderVariant="borderless"
            href="https://www.example.com"
            eyebrow="Enterprise Risk Management"
            heading="One global organization’s risk exposure to slavery. "
            footer={
              <VideoCardFooter
                runningTime="1 min"
                icon={<Icon path={Icons.playtriangle} size="xs" />}
              />
            }
            imgSrc={imgLarge6}
            imgAnchor="C"
            imgGradient
          />
        </Grid.Item>
        <Grid.Item>
          <PairedCard
            borderVariant="borderless"
            href="https://www.example.com"
            eyebrow="Articles"
            heading="Mercury rising. The heavy social and environmental costs of illegal mining"
            footer={
              <ArticleCardFooter
                attributionName="Sophia P."
                dateTime="2016-02-05 13:57"
                readingTime="1 min"
                icon={<Icon path={Icons.time} size="xs" />}
              />
            }
            imgSrc={imgLarge7}
            imgAnchor="C"
          />
        </Grid.Item>
      </Grid>
    </VerticalSpacing>
  </React.Fragment>
);

/* eslint-disable no-magic-numbers */
const oneMinute = 60000;
const time1 = new Date().getTime() - 65 * oneMinute;
const time2 = new Date().getTime() - 10 * oneMinute;
const time3 = new Date().getTime() - 4 * oneMinute;
const time4 = new Date().getTime() - 180 * oneMinute;
const time5 = new Date().getTime() - 120 * oneMinute;
/* eslint-enable no-magic-numbers */

const Home = () => {
  return (
    <div>
      <SampleSchemaOrganization />
      <SampleHeader />

      <SemanticMain>
        <Section background="grayDark" fullWidth>
          <MarkupContainer>
            <SharePriceTracker
              apiUrl="https://run.mocky.io/v3/ab1b7013-0c26-4855-bc08-2405f0116f81"
              backgroundType="light"
              overlay={true}
              format={"dd MMM, hh:mm z"}
            />
          </MarkupContainer>
          <Poster
            height="l"
            imageOverlay="scrimBottomDark"
            imageSrc={articleLadyJustice}
            imageSrcSet={`${articleLadyJusticeMobile} 768w, ${articleLadyJustice} 2048w`}
            alignBottom
          >
            <Grid variant="7/12,5/12" gutterlessAtNarrow>
              <Grid.Item>
                <VerticalSpacing size="s">
                  <Eyebrow text="News Agency" />
                </VerticalSpacing>
                <VerticalSpacing>
                  <Heading type="xl" rank="1">
                    Mining trusted news from Twitter with&nbsp;
                    <strong>Reuters News Tracer</strong>
                  </Heading>
                </VerticalSpacing>
                <Button color="primary" href="https://example.com" kind="link">
                  View product details
                </Button>
              </Grid.Item>
            </Grid>
          </Poster>
        </Section>
        <Section>
          <VerticalSpacing size="xl">
            <Heading rank="2" type="l">
              Providing answers across <strong>every industry</strong>
            </Heading>
          </VerticalSpacing>
          <VerticalSpacing size="xl">
            <Grid variant="even-6" gutterBorder>
              <Grid.Item>
                <TinyTile
                  title="Financial"
                  href="https://example.com"
                  icon={<Icon path={Icons.home} size="s" />}
                />
              </Grid.Item>
              <Grid.Item>
                <TinyTile
                  title="Government"
                  href="https://example.com"
                  icon={<Icon path={Icons.atom} size="s" />}
                />
              </Grid.Item>
              <Grid.Item>
                <TinyTile
                  title="Legal"
                  href="https://example.com"
                  icon={<Icon path={Icons.carFront} size="s" />}
                />
              </Grid.Item>
              <Grid.Item>
                <TinyTile
                  title="Reuters news"
                  href="https://example.com"
                  icon={<Icon path={Icons.flaskConical} size="s" />}
                />
              </Grid.Item>
              <Grid.Item>
                <TinyTile
                  title="Risk management"
                  href="https://example.com"
                  icon={<Icon path={Icons.home} size="s" />}
                />
              </Grid.Item>
              <Grid.Item>
                <TinyTile
                  title="Tax & accounting"
                  href="https://example.com"
                  icon={<Icon path={Icons.atom} size="s" />}
                />
              </Grid.Item>
            </Grid>
          </VerticalSpacing>
          <Grid variant="even-3">
            <Grid.Item>
              <TextCard
                size="medium"
                eyebrow="Financial"
                heading="Eikon"
                description="Gain access to trusted news, data, analytics and investment decisions, all filtered to your exact needs in one dedicated space"
                href="https://www.example.com"
                footer={
                  <LinkCardFooter
                    title="Expand details"
                    type="icon"
                    icon={<Icon path={Icons.plusBlue} size="s" />}
                  />
                }
              />
            </Grid.Item>
            <Grid.Item>
              <TextCard
                size="medium"
                eyebrow="Financial"
                heading="Elektron"
                description="Get real time market data, analytics, news, and more with our low-latency feed"
                href="https://www.example.com"
                footer={
                  <LinkCardFooter
                    title="Expand details"
                    type="icon"
                    icon={<Icon path={Icons.plusBlue} size="s" />}
                  />
                }
              />
            </Grid.Item>
            <Grid.Item>
              <TextCard
                size="medium"
                eyebrow="Legal"
                heading="Westlaw"
                description="Most advanced legal research technology combined with market-leading content"
                href="https://www.example.com"
                footer={
                  <LinkCardFooter
                    title="Expand details"
                    type="icon"
                    icon={<Icon path={Icons.plusBlue} size="s" />}
                  />
                }
              />
            </Grid.Item>
            <Grid.Item>
              <TextCard
                size="medium"
                eyebrow="Tax & Accounting"
                heading="OneSource"
                description="Address the complexities of tax and compliance on every level"
                href="https://www.example.com"
                footer={
                  <LinkCardFooter
                    title="Expand details"
                    type="icon"
                    icon={<Icon path={Icons.plusBlue} size="s" />}
                  />
                }
              />
            </Grid.Item>
            <Grid.Item>
              <TextCard
                size="medium"
                eyebrow="Tax & Accounting"
                heading="Checkpoint"
                description="Blending cutting-edge technology with intelligent linking to related content"
                href="https://www.example.com"
                footer={
                  <LinkCardFooter
                    title="Expand details"
                    type="icon"
                    icon={<Icon path={Icons.plusBlue} size="s" />}
                  />
                }
              />
            </Grid.Item>
            <Grid.Item>
              <TextCard
                size="medium"
                eyebrow="Risk management"
                heading="Compliance learning"
                description="Engaging, comprehensive, training programs in over 30 different languages"
                href="https://www.example.com"
                footer={
                  <LinkCardFooter
                    title="Expand details"
                    type="icon"
                    icon={<Icon path={Icons.plusBlue} size="s" />}
                  />
                }
              />
            </Grid.Item>
          </Grid>
        </Section>
        <MarkupContainer>
          <Ticker>
            <Ticker.Item lead title="Latest News" />
            <Ticker.Item
              title="Lorem ipsum dolor sit amet"
              href="#"
              dateTime={new Date(time1).toISOString()}
            />
            <Ticker.Item
              title="Lorem ipsum dolor sit amet"
              href="#"
              dateTime={new Date().toISOString()}
            />
            <Ticker.Item
              title="Lorem ipsum dolor sit amet"
              href="#"
              dateTime={new Date(time2).toISOString()}
            />
            <Ticker.Item
              title="Lorem ipsum dolor sit amet"
              href="#"
              dateTime={new Date(time3).toISOString()}
            />
            <Ticker.Item
              title="Lorem ipsum dolor sit amet"
              href="#"
              dateTime={new Date(time4).toISOString()}
            />
            <Ticker.Item
              title="Lorem ipsum dolor sit amet"
              href="#"
              dateTime={new Date(time5).toISOString()}
            />
          </Ticker>
        </MarkupContainer>

        <Section background="grayLighter">
          <VerticalSpacing size="xl">
            <Heading rank="2" type="l">
              Latest <strong>answers</strong>
            </Heading>
          </VerticalSpacing>
          <MarkupContainer>
            <Tabs>
              <Tabs.Panel id="latest-answers-all" tabTitle="All">
                {gridVariant2}
              </Tabs.Panel>

              <Tabs.Panel id="latest-answers-finance" tabTitle="Finance">
                {gridVariant1}
              </Tabs.Panel>

              <Tabs.Panel id="latest-answers-government" tabTitle="Government">
                {gridVariant2}
              </Tabs.Panel>

              <Tabs.Panel id="latest-answers-news" tabTitle="News">
                {gridVariant1}
              </Tabs.Panel>

              <Tabs.Panel id="latest-answers-legal" tabTitle="Legal">
                {gridVariant2}
              </Tabs.Panel>

              <Tabs.Panel
                id="latest-answers-risk-management"
                tabTitle="Risk management"
              >
                {gridVariant1}
              </Tabs.Panel>

              <Tabs.Panel
                id="latest-answers-tax-and-accounting"
                tabTitle="Tax & accounting"
              >
                {gridVariant2}
              </Tabs.Panel>

              <Tabs.Panel id="latest-answers-technology" tabTitle="Technology">
                {gridVariant1}
              </Tabs.Panel>

              <Tabs.Panel id="latest-answers-automotive" tabTitle="Automotive">
                {gridVariant2}
              </Tabs.Panel>
            </Tabs>
          </MarkupContainer>
        </Section>

        <Section>
          <Grid variant="even-4">
            <Grid.Item>
              <VerticalSpacing>
                <Heading rank="3" type="m">
                  We’re here to help you
                </Heading>
              </VerticalSpacing>
            </Grid.Item>
            <Grid.Item>
              <TextCard
                size="medium"
                heading="Need help?"
                description="We want our products to provide you optimum efficiency. Find technical support, product updates, training sessions and more."
                href="https://www.example.com"
                footer={
                  <LinkCardFooter
                    title="Support & training"
                    type="icon"
                    icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  />
                }
              />
            </Grid.Item>
            <Grid.Item>
              <TextCard
                size="medium"
                heading="Get in touch"
                description="For any questions regarding our solutions ans services, our customer service representatives are here to help."
                href="https://www.example.com"
                footer={
                  <LinkCardFooter
                    title="Contact us"
                    type="icon"
                    icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  />
                }
              />
            </Grid.Item>
            <Grid.Item>
              <TextCard
                size="medium"
                heading="Your Thomson Reuters"
                description="Already a Thomson Reuters customer? Log in to access your account and recent work."
                href="https://www.example.com"
                footer={
                  <LinkCardFooter
                    title="Support & training"
                    type="icon"
                    icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  />
                }
              />
            </Grid.Item>
          </Grid>
        </Section>
      </SemanticMain>

      <SampleFooter />
    </div>
  );
};

export default Home;
