import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

import Eyebrow from "@emcm-ui/component-eyebrow";

import Tag from "./components/Tag";

const TagFooter = ({ children, title }) => {
  const getClassName = getClassNameFactory("SearchResults");

  return (
    <div
      className={getClassName({
        descendantName: "tagFooter",
        className: typestack("p3")
      })}
    >
      <span
        className={getClassName({
          descendantName: "tagFooterTitle"
        })}
      >
        <Eyebrow text={title} />
      </span>
      <ul className={getClassName({ descendantName: "tagFooterItems" })}>
        {children}
      </ul>
    </div>
  );
};

TagFooter.propTypes = {
  /**
   * Tags to display
   */
  children: PropTypes.node,

  /**
   * Title to show before the tags
   */
  title: PropTypes.string
};

TagFooter.defaultProps = {
  title: "For:"
};

TagFooter.Tag = Tag;

export default TagFooter;
