import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import Typestack from "@emcm-ui/component-typestack";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const PageHeadingWrapper = ({
  lsegBlue,
  compact,
  description,
  heading,
  large
}) => {
  const getClassName = getClassNameFactory(PageHeadingWrapper.displayName);

  return (
    <h1 className={getClassName({ modifiers: classNames({ compact, large }) })}>
      <span className={getClassName({ descendantName: "heading" })}>
        <Typestack align="left" rank="p" type="h1" lsegBlue={lsegBlue}>
          {heading}
        </Typestack>
      </span>
      <span
        className={getClassName({
          descendantName: "description",
          className: large ? typestack("subline2") : typestack("p1")
        })}
      >
        {description}
      </span>
    </h1>
  );
};

PageHeadingWrapper.displayName = "PageHeadingWrapper";

PageHeadingWrapper.propTypes = {
  /**
   * Set color to LSEG blue.
   */
  lsegBlue: PropTypes.bool,
  /**
   * Compact description variant modifier
   */
  compact: PropTypes.bool,
  /**
   * Text or inline markup only
   */
  description: PropTypes.node.isRequired,
  /**
   * Text or inline markup only
   */
  heading: PropTypes.node.isRequired,
  /**
   * Large description variant modifier
   */
  large: PropTypes.bool
};

export default PageHeadingWrapper;
