import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import Alert from "@emcm-ui/component-alert";
import Button from "@emcm-ui/component-button";
import Grid from "@emcm-ui/component-grid";
import Icon from "@emcm-ui/component-icon";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

import DropdownItem from "./DropdownItem";
import DropdownHeader from "./DropdownHeader";

import { Provider as AjaxPanelProvider } from "@emcm-ui/component-panel/lib/ajaxPanelProvider";

import classNames from "classnames";

const itemsArrayShape = {
  href: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string.isRequired
};

class Dropdown extends Component {
  static displayName = "Dropdown";

  /* eslint-disable max-len */
  static propTypes = {
    /**
     * Render the Dropdown with 100% width.
     */
    block: PropTypes.bool,
    /**
     * Text of the button when dropdown with button CTA is selected.
     */
    buttonText: PropTypes.string,
    /**
     * Color of the button when dropdown with button CTA is selected.
     */
    buttonColor: PropTypes.oneOf(Button.colorOptions),
    /**
     * Type of the button when dropdown with button CTA is selected.
     */
    buttonType: PropTypes.oneOf(Button.buttonTypes),
    /**
     * String to tag labels to the button for analytics.
     */
    buttonCanonicalCta: PropTypes.string,
    /**
     * Children
     */
    children: PropTypes.node,
    /**
     * String to display error message when button is clicked with dropdown selection.
     */
    errorMessage: PropTypes.string,
    /**
     * Array of dropdown items. Items can contain value or href, not both.
     */
    items: PropTypes.arrayOf(PropTypes.shape(itemsArrayShape)).isRequired,
    /**
     * Label.
     */
    labelText: PropTypes.string,
    /**
     * Add lang code attribute to Dropdown options.
     */
    lang: PropTypes.bool,
    /**
     *  No Record found text.
     */
    noRecordText: PropTypes.string,
    /**
     * Method override for clicking on the button. To check whether dropdown item is selected ot not.
     */
    onButtonClicked: PropTypes.func,
    /**
     * Method override for clicking the items. Receives an `item` arguement.
     */
    onItemClicked: PropTypes.func,
    /**
     * Callback, called when value changes (unless set by property). Receives a `value` arguement.
     */
    onValueChanged: PropTypes.func,
    /**
     * Placeholder
     */
    placeholder: PropTypes.string.isRequired,
    /**
     * Placeholder Icon
     */
    placeholderIcon: PropTypes.string,
    /**
     * Method to override the dropdown markup of the component. Useful for reusing the dropdown elsewhere.
     */
    renderDropdown: PropTypes.func,
    /**
     * Method to override the error message of the dropdown. Useful for reusing the dropdown elsewhere
     */
    renderErrorMessage: PropTypes.func,
    /**
     * Method to override the header of the dropdown. Useful for reusing the dropdown elsewhere. Receives `text`, `expanded` and `toggleExpand()` arguements.
     */
    renderHeader: PropTypes.func,
    /**
     * placeholder to be diaplayed before entering any term for searching
     */
    searchPlaceholder: PropTypes.string,
    /**
     * Size
     */
    size: PropTypes.oneOf(["large", "small"]),
    /**
     * typeAheadEnabled boolean to decide whether to have typeAhead as a feature or not.
     */
    typeAheadEnabled: PropTypes.bool,
    /**
     * Value of the selected item. Will always override the existing value when set.
     */
    value: PropTypes.any,
    /**
     * id for dropdown list
     */
    id: PropTypes.string
  };
  /* eslint-enable max-len */

  static defaultProps = {
    block: true,
    lang: false,
    size: "large"
  };

  constructor(props) {
    super(props);

    this.getClassName = getClassNameFactory(Dropdown.displayName);
    this.getButtonClassName = getClassNameFactory(Button.displayName);

    this.state = {
      expanded: false,
      hasError: false,
      value: this.props.value,
      userInput: "",
      items: props.items
    };

    this.collapse = this.collapse.bind(this);
    this.getItemForCurrentValue = this.getItemForCurrentValue.bind(this);
    this.maybeCollapse = this.maybeCollapse.bind(this);
    this.onButtonClicked = this.onButtonClicked.bind(this);
    this.onItemClicked = this.onItemClicked.bind(this);
    this.renderDropdown = this.renderDropdown.bind(this);
    this.renderErrorMessage = this.renderErrorMessage.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.toggleExpand = this.toggleExpand.bind(this);

    this.buttonRef = React.createRef();
  }

  async componentDidMount() {
    if (this.props.placeholderIcon) {
      const iconComponent = await this.getDisplayIcon(
        this.props.placeholderIcon
      );

      this.setState({ iconComponent, placeholderIconComponent: iconComponent });

      const itemIcons = [];

      this.state.items.forEach(item => {
        itemIcons.push(this.getDisplayIcon(item.icon));

        return item;
      });

      Promise.all(itemIcons).then(result => {
        this.setState({ iconComponent, itemIcons: result });
      });
    }
  }

  collapse() {
    this.setState({ expanded: false });
    this.onFilterItems("");
  }

  // Collapse if next focus target is not a menu item (i.e. itemInner)
  maybeCollapse(event) {
    // document.activeElement is IE11 fallback
    const relatedTarget = event.relatedTarget || document.activeElement;
    const relatedTargetItems = ["itemInner", "items", "input"];
    const isRelatedTargetItem = relatedTargetItems.find(relatedTargetItem => {
      return (
        relatedTarget.className ===
        this.getClassName({ descendantName: relatedTargetItem })
      );
    });

    if (
      typeof event === "undefined" ||
      typeof relatedTarget === "undefined" ||
      relatedTarget === null ||
      typeof isRelatedTargetItem === "undefined"
    ) {
      this.collapse();
    }
  }

  getDisplayIcon = iconPath => {
    return <Icon path={iconPath} size="s" />;
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps, prevState) {
    if (
      nextProps.value !== this.props.value ||
      prevState.value !== nextProps.value
    ) {
      this.setState({ value: nextProps.value });
    }
    this.setState({ items: nextProps.items });
  }

  getItemForCurrentValue() {
    const value = this.state.value || this.props.value;

    if (typeof value === "undefined" || value === null) {
      return;
    }

    return this.state.items.find(
      item => item.value === (this.state.value || this.props.value)
    );
  }

  onFilterItems = (userInput = "") => {
    let { items } = this.props;

    items = userInput
      ? items.filter(({ label = "" }) =>
          label.toLowerCase().includes(userInput.toLowerCase())
        )
      : items;
    this.setState({ userInput, items });
  };

  onButtonClicked() {
    const { value } = this.state;

    this.setState({ hasError: Boolean(!value) });
  }

  onItemClicked(item, icon) {
    this.setState({
      expanded: false,
      hasError: false,
      value: item.value,
      userInput: "",
      iconComponent: icon ? icon : this.state.placeholderIconComponent
    });

    setTimeout(() => {
      this.buttonRef.current.focus();
    }, 0);

    if (this.props.onValueChanged) {
      this.props.onValueChanged(item.value);
    }
  }

  toggleExpand() {
    this.setState({ expanded: !this.state.expanded });
  }

  renderHeader({
    text,
    expanded,
    toggleExpand,
    onFilterItems,
    hasError,
    searchPlaceholder,
    userInput,
    typeAheadEnabled,
    id
  }) {
    return (
      <DropdownHeader
        classNames={classNames}
        hasError={hasError}
        text={text}
        expanded={expanded}
        toggleExpand={toggleExpand}
        onFilterItems={onFilterItems}
        getClassName={this.getClassName}
        icon={this.state.iconComponent}
        searchPlaceholder={searchPlaceholder}
        userInput={userInput}
        typeAheadEnabled={typeAheadEnabled}
        id={id}
        buttonRef={this.buttonRef}
      />
    );
  }

  renderDropdown() {
    const {
      buttonText,
      noRecordText = "No record found",
      onItemClicked = this.onItemClicked,
      placeholder,
      placeholderIcon,
      renderHeader = this.renderHeader,
      searchPlaceholder,
      size,
      typeAheadEnabled,
      lang,
      id
    } = this.props;

    const { items, userInput } = this.state;

    const block = Boolean(buttonText) || this.props.block;

    const { expanded } = this.state;

    const currentItem = this.getItemForCurrentValue();

    let label;

    if (currentItem) {
      label = currentItem.label;
    } else {
      label = placeholder;
    }

    return (
      <div
        className={this.getClassName({
          modifiers: classNames({
            block,
            [size]: true
          }),
          states: classNames({
            expanded
          }),
          className: size === "large" ? typestack("p1") : typestack("p3")
        })}
        onBlur={this.maybeCollapse}
      >
        {renderHeader({
          onFilterItems: this.onFilterItems,
          text: lang ? label.split(" - ")[0] : label,
          ...this.state,
          toggleExpand: this.toggleExpand,
          searchPlaceholder,
          typeAheadEnabled,
          icon: placeholderIcon,
          id
        })}

        <ul
          role="listbox"
          tabIndex="-1"
          className={this.getClassName({ descendantName: "items" })}
          id={id}
        >
          {items.length > 0 ? (
            items.map((item, i) => (
              <DropdownItem
                getClassName={this.getClassName}
                item={item}
                icon={this.state.itemIcons && this.state.itemIcons[i]}
                key={i}
                onItemClicked={onItemClicked}
                onBlur={this.maybeCollapse}
                userInput={userInput}
                isSelected={Boolean(
                  item.value &&
                    this.state.value &&
                    item.value === this.state.value
                )}
                typeAheadEnabled={typeAheadEnabled}
                lang={lang}
              />
            ))
          ) : (
            <DropdownItem
              getClassName={this.getClassName}
              item={{ label: noRecordText }}
              onItemClicked={() => {}}
              onBlur={this.maybeCollapse}
            />
          )}
        </ul>
      </div>
    );
  }

  renderErrorMessage() {
    const { errorMessage = "Please make a selection" } = this.props;
    const { hasError } = this.state;

    return (
      hasError && (
        <div
          className={this.getClassName({
            descendantName: "errorMessage"
          })}
        >
          <Alert state="failure">{errorMessage}</Alert>
        </div>
      )
    );
  }

  render() {
    const {
      buttonColor = "primary",
      buttonText,
      buttonType = "standard",
      buttonCanonicalCta,
      labelText,
      lang,
      renderErrorMessage = this.renderErrorMessage,
      block,
      noRecordText,
      placeholder,
      placeholderIcon,
      size,
      errorMessage,
      typeAheadEnabled,
      searchPlaceholder
    } = this.props;
    const { value, items } = this.state;
    const ButtonElement = value ? "a" : "button";
    const buttonProps = {
      className: this.getButtonClassName({
        modifiers: classNames(
          buttonColor,
          buttonType ? buttonType : null,
          "block"
        )
      }),
      href: value,
      "data-canonicalcta": buttonCanonicalCta
    };

    const typeAheadProps = typeAheadEnabled
      ? {
          "data-type-ahead-enabled": JSON.stringify(typeAheadEnabled),
          "data-no-record-text": JSON.stringify(noRecordText),
          "data-search-placeholder": JSON.stringify(searchPlaceholder)
        }
      : {};

    if (!value) {
      buttonProps.onClick = this.onButtonClicked;
    }

    return (
      <div
        // Rehyratable props
        data-block={JSON.stringify(block)}
        data-items={JSON.stringify(items)}
        data-placeholder={JSON.stringify(placeholder)}
        data-placeholder-icon={JSON.stringify(placeholderIcon)}
        data-rehydratable={getRehydratableName(Dropdown.displayName)}
        data-size={JSON.stringify(size)}
        data-value={JSON.stringify(value)}
        data-label-text={JSON.stringify(labelText)}
        data-lang={JSON.stringify(lang)}
        data-button-text={JSON.stringify(buttonText)}
        data-button-color={JSON.stringify(buttonColor)}
        data-button-type={JSON.stringify(buttonType)}
        data-error-message={JSON.stringify(errorMessage)}
        data-button-canonical-cta={JSON.stringify(buttonCanonicalCta)}
        {...typeAheadProps}
      >
        {labelText && (
          <span
            className={this.getClassName({
              descendantName: "labelText",
              className: typestack("p1Bold")
            })}
          >
            {labelText}
          </span>
        )}
        {buttonText ? (
          <Grid variant="3/4,1/4" gutterVerticalSmall={true}>
            <Grid.Item>{this.renderDropdown()}</Grid.Item>
            <Grid.Item>
              <div
                className={this.getClassName({
                  descendantName: "button"
                })}
              >
                <ButtonElement {...buttonProps}>
                  <span
                    className={this.getButtonClassName({
                      descendantName: "body"
                    })}
                  >
                    {buttonText}
                  </span>
                </ButtonElement>
              </div>
            </Grid.Item>
          </Grid>
        ) : (
          this.renderDropdown()
        )}
        {renderErrorMessage()}

        {this.props.children && (
          <div className={this.getClassName({ descendantName: "panels" })}>
            <AjaxPanelProvider value={this.state.value}>
              {this.props.children}
            </AjaxPanelProvider>
          </div>
        )}
      </div>
    );
  }
}

export default Dropdown;
