/* eslint-disable no-magic-numbers */
import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text, number, array, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import Chart from "./index";
import Grid from "@emcm-ui/component-grid";
import "./Chart.css";
import ChartREADME from "../README.md";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

const stories = storiesOf("Components/Chart", module);
const defaultProps = {
  data: [65, 59, 80, 81, 56, 55, 40, 25, 80, 42, 50, 100],
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec"
  ]
};

const barChartData = {
  datasets: [
    {
      data: [15, 28, 26, 29, 38],
      label: "FTSE All-World Ex Fossil Fuels Index"
    },
    {
      data: [15, 27, 25, 29, 32],
      label: "FTSE All World Index"
    }
  ]
};

const labels = ["0.1 to 1", "1.1 to 2", "2.1 to 3", "3.1 to 4", "4.1 to 5"];
const colors = ["#001EFF", "#808080"];
const dataValue = barChartData.datasets.map(value => {
  return value.data;
});
const xAxesTitle = barChartData.datasets.map(value => {
  return value.label;
});

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(ChartREADME));

const chartTypes = [
  "bar",
  "line",
  "radar",
  "doughnut",
  "pie",
  "polarArea",
  "bubble",
  "scatter"
];
const datasets = [
  {
    label: "chartlabel1",
    data: [
      "6050",
      "12110",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ],
    fill: false,
    lineTension: 0.1,
    backgroundColor: " #472574",
    barThickness: 20,
    borderColor: " #472574",
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0,
    borderJoinStyle: "miter",
    pointBorderColor: " #472574",
    pointBackgroundColor: " #472574",
    pointBorderWidth: 3,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: " #472574",
    pointHoverBorderColor: " #472574",
    pointHoverBorderWidth: 2,
    pointRadius: 3,
    pointHitRadius: 5
  },
  {
    label: "chartlabel2",
    data: [
      null,
      null,
      "6180",
      "12390",
      "18630",
      "24900",
      "31200",
      "37530",
      "43890",
      "43890",
      "43890",
      "48890"
    ],
    fill: false,
    lineTension: 0.1,
    backgroundColor: "#AD8BDA",
    barThickness: 20,
    borderColor: "#AD8BDA",
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0,
    borderJoinStyle: "miter",
    pointBorderColor: "#AD8BDA",
    pointBackgroundColor: "#FFF",
    pointBorderWidth: 3,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: "#AD8BDA",
    pointHoverBorderColor: "#AD8BDA",
    pointHoverBorderWidth: 2,
    pointRadius: 3,
    pointHitRadius: 5
  }
];

const multiLineLabels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

stories
  .add("Default", () => <Chart {...defaultProps} />)
  .add("Bar with Single Data", () => (
    <Chart
      {...defaultProps}
      colors={text("Chart Color", "#001eff")}
      chartLabel={text("Chart Label", "Usage Report")}
      chartTitle={text("Chart Title", "Bar Chart")}
      type={select("Type", chartTypes, "bar")}
      height={350}
      width={500}
    />
  ))
  .add("Bar with Multiple Data", () => (
    <Chart
      data={dataValue}
      labels={labels}
      colors={array("Chart Color", colors, ",")}
      legends={array("Legends", xAxesTitle, ",")}
      chartLabel={text("Chart Label", "Usage Report")}
      chartTitle={text("Chart Title", "Bar Chart")}
      type={select("Type", chartTypes, "bar")}
      height={350}
      width={500}
      yLabel={text("yLabel", "% of index weight")}
      stepSize={number("stepSize", 10)}
      scaleType={"%"}
      isStacked={false}
    />
  ))
  .add("Line", () => (
    <Chart
      {...defaultProps}
      colors={text("Chart Color", "#001eff")}
      chartLabel={text("Chart Label", "Usage Report")}
      chartTitle={text("Chart Title", "Line Chart")}
      type={select("Type", chartTypes, "line")}
      height={350}
      width={500}
    />
  ))
  .add("Line with Multiple Data", () => (
    <Chart
      datasets={datasets}
      labels={multiLineLabels}
      legends={array("Legends", xAxesTitle, ",")}
      colors={array("Chart Color", colors, ",")}
      chartTitle={text("Chart Title", "Line Chart")}
      type={select("Type", chartTypes, "line")}
      height={350}
      width={500}
      isStacked={false}
    />
  ))
  .add("Doughnut", () => (
    <Chart
      {...defaultProps}
      colors={[
        "#1FFFF1",
        "#F1FF1F",
        "#2EFFE2",
        "#E2FF2E",
        "#3DFFD3",
        "#D3FF3D",
        "#4CFFC4",
        "#5BFFB5",
        "#6AFFA6",
        "#A6FF6A",
        "#79FF97",
        "#97FF79"
      ]}
      chartLabel={text("Chart Label", "Usage Report")}
      chartTitle={text("Chart Title", "Line Chart")}
      doughnutTitle={text("Doughnut Title", "87 %")}
      doughnutSubTitle={text("Doughnut Sub-Title", "Total usage data")}
      doughnutToolTipLabel={text("Doughnut's tooltip", "Sample")}
      doughnutHelperTooltipIcon={<SVGIcon name="question-07" size="s" />}
      doughnutHelperTooltipText={text(
        "Doughnut Helper Tooltip Text",
        "This is a sample text"
      )}
      type={select("Type", chartTypes, "doughnut")}
      height={350}
      width={500}
    />
  ))
  .add("Multiple charts with different inputs", () => (
    <div>
      <Grid variant="even-2" gutterVerticalSmall={true}>
        <Grid.Item>
          <Chart
            data={[65, 59, 80, 81, 56, 55, 40]}
            labels={["Jan", "Feb", "Mar", "Apr", "Jun"]}
            colors={text("Chart Color", "#001eff")}
            chartLabel={text("Chart Label", "Usage Report")}
            chartTitle={text("Chart Title", "Line User")}
            type={select("Type", chartTypes, "line")}
            height={350}
            width={500}
          />
        </Grid.Item>
        <Grid.Item>
          <Chart
            data={[45, 32, 60, 55, 80, 73]}
            labels={["Jan", "Feb", "Mar", "Apr", "Jun"]}
            colors={text("Chart Color", "#001eff")}
            chartLabel={text("Chart Label", "Usage Report")}
            chartTitle={text("Chart Title", "Bar Chart")}
            type={select("Type", chartTypes, "bar")}
            height={350}
            width={500}
          />
        </Grid.Item>
      </Grid>
      <Grid variant="even-2" gutterVerticalSmall={true}>
        <Grid.Item>
          <Chart
            {...defaultProps}
            colors={text("Chart Color", "#ffc900")}
            chartLabel={text("Chart Label", "Usage Report")}
            chartTitle={text("Chart Title", "Line Chart")}
            numberOfGrids={10}
            type={select("Type", chartTypes, "line")}
            height={550}
            width={500}
          />
        </Grid.Item>
        <Grid.Item>
          <Chart
            {...defaultProps}
            colors={text("Chart Color", "#ffc900")}
            chartLabel={text("Chart Label", "Usage Report")}
            chartTitle={text("Chart Title", "Bar Chart")}
            numberOfGrids={10}
            type={select("Type", chartTypes, "bar")}
            height={550}
            width={500}
          />
        </Grid.Item>
      </Grid>
    </div>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <Chart {...defaultProps} />
    </StaticRenderer>
  ));
