import React from "react";
import PropTypes from "prop-types";
import { storiesOf } from "@storybook/react";
import { withKnobs, text, object, boolean } from "@storybook/addon-knobs";
import { action } from "@storybook/addon-actions";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import TagFilters from "./index";
import "./TagFilters.css";
import SearchResultsMixtureREADME from ".../../../README.md";
import * as data from "../../../__mocks__/searchMock.json";

const stories = storiesOf(
  "Components/SearchMixedCard/Components/TagFilters",
  module
);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(SearchResultsMixtureREADME));

let list = data.filterSettings[0].resultFilters;

list = list.map(({ name, title, ...rest }) => {
  return {
    key: name,
    label: title,
    ...rest
  };
});

class Demo extends React.Component {
  static propTypes = {
    /**
     * tag cloud object
     */
    tagCloud: PropTypes.object,
    /**
     * search object
     */
    search: PropTypes.object,
    /**
     * onSelect function
     */
    onSelect: PropTypes.func,
    /**
     * onClear function
     */
    onClear: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedTags: []
    };
  }

  onTagSelect = response => {
    const { onSelect } = this.props;

    this.setState({
      selectedTags: response.updatedTags
    });

    onSelect(response);
  };
  clearTagSelection = () => {
    const { onClear } = this.props;

    this.setState({
      selectedTags: []
    });

    onClear();
  };

  render() {
    const { tagCloud, search } = this.props;
    const { selectedTags } = this.state;

    tagCloud.selectedTags = selectedTags;
    tagCloud.onTagSelect = this.onTagSelect;
    tagCloud.clearTagSelection = this.clearTagSelection;

    return <TagFilters tagCloud={tagCloud} search={search} />;
  }
}

stories
  .add("Default", () => {
    const showMore = text("Show more", "Show more", "Attributes");
    const showLess = text("Show less", "Show less", "Attributes");
    const tagHeading = text("Heading", "Related topics", "Attributes");
    const clearAllText = text("Clear all", "Clear all", "Attributes");

    const tagFilters = object("List", list, "List");
    const tagCloud = {
      tagFilters,
      showMore,
      showLess,
      tagHeading,
      clearAllText
    };
    const search = {
      hasSearchTerm: boolean("Search Term", true, "Attributes")
    };

    return (
      <Demo
        tagCloud={tagCloud}
        search={search}
        onSelect={action("Tag selection")}
        onClear={action("Clear selection")}
      />
    );
  })
  .add("Language: Arabic (RTL)", () => {
    const showMore = text("Show more", "أظهر المزيد", "Attributes");
    const showLess = text("Show less", "إظهار أقل", "Attributes");
    const tagHeading = text("Heading", "مواضيع ذات صلة", "Attributes");
    const clearAllText = text("Clear all", "امسح الكل", "Attributes");

    const tagFilters = object("List", list, "List");
    const tagCloud = {
      tagFilters,
      showMore,
      showLess,
      tagHeading,
      clearAllText
    };
    const search = {
      hasSearchTerm: boolean("Search Term", true, "Attributes")
    };

    return (
      <Language code="ar">
        <Demo
          tagCloud={tagCloud}
          search={search}
          onSelect={action("Tag selection")}
          onClear={action("Clear selection")}
        />{" "}
      </Language>
    );
  });
