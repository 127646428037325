/**
 * @define VerticalNav.Menu
 * VerticalNav.Menu Component
 */

// Vendor
import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// emcm-ui
import Expandable from "@emcm-ui/component-expandable";
import getClassNameFactory from "@emcm-ui/utility-class-names";

// local
import { Consumer as VerticalNavConsumer } from "../../verticalNavContext";

class Menu extends Component {
  static displayName = "VerticalNav.Menu";

  static defaultProps = {
    label: ""
  };

  static propTypes = {
    /**
     * Title for the menu item
     */
    label: PropTypes.string,
    /**
     * indent for the menu item. Don't use it
     */
    indent: PropTypes.number,
    /**
     * children for the menu item
     */
    children: PropTypes.node,
    /**
     * To know whether rehydration happens or not. Don't use it
     */
    isRehydrating: PropTypes.bool,
    /**
     * selectedItem name to expand if there is default;
     */
    selectedItem: PropTypes.string,
    /**
     * context object if we are in an ajaxContent context.
     */
    ajaxContext: PropTypes.oneOfType([
      PropTypes.shape({
        href: PropTypes.string,
        updateHref: PropTypes.func
      }),
      PropTypes.bool
    ])
  };

  state = {
    isSelected: false
  };

  shouldExpand = (children, selectedItem) => {
    if (!children) return false;

    if (Array.isArray(children)) {
      return children.some(child => {
        return (
          child.props.label === selectedItem ||
          (child.type.displayName === "MenuWithContext" &&
            this.shouldExpand(child.props.children, selectedItem))
        );
      });
    }

    return (
      children.props.label === selectedItem ||
      (children.type.displayName === "MenuWithContext" &&
        this.shouldExpand(children.props.children, selectedItem))
    );
  };

  render() {
    const { label, indent, children, selectedItem, ajaxContext } = this.props;
    const getClassName = getClassNameFactory("VerticalNavMenu");
    const maxLevel = 3;
    const isExpanded = this.shouldExpand(children, selectedItem);

    return (
      indent < maxLevel && (
        <div
          className={getClassName({
            modifiers: classNames(indent)
          })}
          data-label={label}
        >
          <div className={getClassName({ descendantName: "content" })}>
            <Expandable
              title={label}
              headingRank="p"
              headingType="p1Bold"
              isExpanded={isExpanded}
            >
              <div>
                {children &&
                  (Array.isArray(children)
                    ? children.map(element => {
                        const Element = element.type;

                        return (
                          <Element
                            indent={indent + 1}
                            {...element.props}
                            key={element.props.label}
                            ajaxContext={ajaxContext}
                          />
                        );
                      })
                    : children.props && (
                        <children.type
                          indent={indent + 1}
                          {...children.props}
                          key={children.props.label}
                          ajaxContext={ajaxContext}
                        />
                      ))}
              </div>
            </Expandable>
          </div>
        </div>
      )
    );
  }
}

const MenuWithContext = ({ ...props }) => (
  <VerticalNavConsumer>
    {({ selectedItem }) => <Menu {...props} selectedItem={selectedItem} />}
  </VerticalNavConsumer>
);

MenuWithContext.displayName = "MenuWithContext";

export default MenuWithContext;
