import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, boolean, select, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import Image, { anchorPoints } from "@emcm-ui/component-image";
import { Language } from "@emcm-ui/component-theme";
import Block from "@emcm-ui/component-block";
import Section from "@emcm-ui/component-section";
import SocialDropdown from "@emcm-ui/component-social-dropdown";
import SocialShare from "@emcm-ui/component-social-share";
import Icon from "@emcm-ui/component-icon";
import Poster from "./index";
import "./Poster.css";
import PosterREADME from "../README.md";

const stories = storiesOf("Components/Poster", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(PosterREADME));

const socialDropdown = (
  <SocialDropdown>
    <SocialDropdown.Item>
      <SocialShare pageUrl="https://www.lseg.com" type="facebook">
        <Icon.SVGIcon
          size="s"
          icon={
            <path
              fill="#404040"
              d="M18.55 30V17.4h3.825L23 11.8h-4.45V9.073c0-1.442.037-2.873 2.052-2.873h2.04V2.196c0-.06-1.753-.196-3.526-.196-3.704 0-6.023 2.32-6.023 6.58v3.22H9v5.6h4.093V30h5.457zM18.55 30V17.4h3.825L23 11.8h-4.45V9.073c0-1.442.037-2.873 2.052-2.873h2.04V2.196c0-.06-1.753-.196-3.526-.196-3.704 0-6.023 2.32-6.023 6.58v3.22H9v5.6h4.093V30h5.457z"
            />
          }
        />
      </SocialShare>
    </SocialDropdown.Item>
    <SocialDropdown.Item>
      <SocialShare
        pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
        pageUrl="https://www.lseg.com"
        type="linkedIn"
      >
        <Icon.SVGIcon
          size="s"
          icon={
            <path
              fill="#404040"
              d="M28.6,2 C29.3731986,2 30,2.62680135 30,3.4 L30,28.6 C30,29.3731986 29.3731986,30 28.6,30 L3.4,30 C2.62680135,30 2,29.3731986 2,28.6 L2,3.4 C2,2.62680135 2.62680135,2 3.4,2 L28.6,2 Z M21,12 C19.2524261,12 17.4828554,13.0189582 17.0030406,13.985359 L17.0030406,13.985359 L17.0030406,12.3054904 L13,12.3054904 L13,26.0030618 L17.0030406,26.0030618 L17.0030406,18.5 C17.0030406,16.8969967 17.6313778,15.2672737 19.5,15.2672737 C21.3414512,15.2672737 21.9979774,17.0743782 21.9979774,18.5 L21.9979774,18.5 L21.9979774,26.0030618 L26,26.0030618 L26,18 C26,14.6901924 24.0925371,12 21,12 Z M10,12 L6,12 L6,26.0030618 L10,26.0030618 L10,12 Z M8.00088981,6 C6.89515053,6 6,6.89620403 6,7.99940688 C6,9.10379597 6.89515053,10 8.00088981,10 C9.10366306,10 10,9.10379597 10,7.99940688 C10,6.89620403 9.10366306,6 8.00088981,6 Z"
            />
          }
        />
      </SocialShare>
    </SocialDropdown.Item>
    <SocialDropdown.Item>
      <SocialShare
        pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
        pageUrl="https://www.lseg.com"
        type="twitter"
      >
        <Icon.SVGIcon
          size="s"
          icon={
            <path
              fill="#404040"
              d="M27.151,11.1950094 C27.151,10.9540425 27.151,10.7144294 27.1342,10.47617 C28.2584,9.69099698 29.2286,8.71629948 30,7.60081235 C28.9528,8.0502562 27.8398,8.34537294 26.7016,8.4753326 C27.9,7.78086064 28.7974,6.68974094 29.2272,5.402328 C28.0988,6.04941884 26.8654,6.50563142 25.5788,6.75065954 C23.4032,4.51427028 19.7646,4.40597056 17.4504,6.50969266 C15.9594,7.86614667 15.3252,9.88864397 15.7886,11.8177328 C11.17,11.5930108 6.8664,9.48387376 3.9488,6.01422143 C2.4242,8.55249616 3.204,11.7987803 5.7282,13.4286911 C4.814,13.4029699 3.9194,13.1647106 3.12,12.7342192 L3.12,12.804614 C3.1214,15.4484809 5.0492,17.7254826 7.7288,18.2493825 C6.8832,18.4727507 5.9956,18.5052406 5.136,18.3441447 C5.8878,20.6076089 8.0452,22.1576487 10.5022,22.2023223 C8.468,23.7483009 5.955,24.5876237 3.3678,24.5849162 C2.9114,24.5835625 2.455,24.5578413 2,24.5050452 C4.6278,26.134956 7.684,27 10.806,26.9959388 C21.3718,27 27.151,18.535023 27.151,11.1950094 Z"
            />
          }
        />
      </SocialShare>
    </SocialDropdown.Item>
    <SocialDropdown.Item>
      <SocialShare
        pageTitle="LSEG is a leading global financial markets infrastructure and data provider."
        pageUrl="https://www.lseg.com"
        type="email"
      >
        <Icon.SVGIcon
          size="s"
          icon={
            <path
              fill="#404040"
              d="M30,7 L30,25 L2,25 L2,7 L30,7 Z M28,9 L4,9 L4,23 L28,23 L28,9 Z M29.9592921,7.87 L29.9610964,10.1782884 L17.7907079,17.204 L16.0055515,18.2420073 L2.03963298,10.1787805 L2.04070786,7.87 L15.9997079,15.929 L29.9592921,7.87 Z"
            />
          }
        />
      </SocialShare>
    </SocialDropdown.Item>
  </SocialDropdown>
);

stories
  .add("Default", () => (
    <Section
      background={
        select(
          "Section background",
          [null].concat(Section.backgrounds),
          "grayDark"
        ) || null
      }
      fullWidth
    >
      <Poster
        alignBottom={boolean("alignBottom", false)}
        height={select("height", Poster.heights, "xs")}
        imageAnchor={select("imageAnchor", anchorPoints, "C")}
        imageInlineAtNarrow={boolean("imageInlineAtNarrow", false)}
        imageOverlay={
          select("imageOverlay", [null].concat(Image.overlays)) || null
        }
        imageSrc={text("imageSrc", "test-images/16-9/large_9.jpg")}
        imageSrcSet={text(
          "imageSrcSet",
          "test-images/16-9/large_9.jpg 2000w, test-images/16-9/medium_9.jpg 800w, test-images/16-9/small_9.jpg 400w"
        )}
        mobileImageAnchor={select("mobileImageAnchor", anchorPoints, "C")}
        mobileImageSrc={text("mobileImageSrc", "test-images/16-9/large_8.jpg")}
        share={boolean("share", false) && socialDropdown}
        enableMobileBackground={boolean("Enable Mobile Background", true)}
      >
        <Block color="red" height="6rem" text="children" />
      </Poster>
    </Section>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Section
        background={
          select(
            "Section background",
            [null].concat(Section.backgrounds),
            "grayDark"
          ) || null
        }
        fullWidth
      >
        <Poster
          alignBottom={boolean("alignBottom", false)}
          height={select("height", Poster.heights, "xs")}
          imageAnchor={select("imageAnchor", anchorPoints, "C")}
          imageInlineAtNarrow={boolean("imageInlineAtNarrow", false)}
          imageOverlay={
            select("imageOverlay", [null].concat(Image.overlays)) || null
          }
          imageSrc={text("imageSrc", "test-images/16-9/large_9.jpg")}
          imageSrcSet={text(
            "imageSrcSet",
            "test-images/16-9/large_9.jpg 2000w, test-images/16-9/medium_9.jpg 800w, test-images/16-9/small_9.jpg 400w"
          )}
          share={boolean("share", false) && socialDropdown}
        >
          <Block color="red" height="6rem" text="الأطفال" />
        </Poster>
      </Section>
    </Language>
  ))
  .add("With video loop", () => (
    <Section
      background={
        select(
          "Section background",
          [null].concat(Section.backgrounds),
          "grayDark"
        ) || null
      }
      fullWidth
    >
      <Poster
        alignBottom={boolean("alignBottom", false)}
        height={select("height", Poster.heights, "xs")}
        imageAnchor={select("imageAnchor", anchorPoints, "C")}
        imageInlineAtNarrow={boolean("imageInlineAtNarrow", false)}
        imageOverlay={
          select("imageOverlay", [null].concat(Image.overlays)) || null
        }
        videoSrc={text("videoSrc", "video-loop/player.html")}
        share={boolean("share", false) && socialDropdown}
        videoWidth={1280}
        videoHeight={720}
      >
        <Block color="red" height="6rem" text="With a video loop" />
      </Poster>
    </Section>
  ));
