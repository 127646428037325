import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text, boolean, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import SharePriceTracker from "./index";
import "./SharePriceTracker.css";
import SharePriceTrackerREADME from "../README.md";

const stories = storiesOf("Components/SharePriceTracker", module);

const BackgroundType = ["light", "dark"];

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(SharePriceTrackerREADME));

stories
  .add("Default", () => (
    <SharePriceTracker
      apiUrl="https://run.mocky.io/v3/75cde8bd-a490-418c-9754-79e1acf1318d"
      backgroundType={select("Background Type", BackgroundType, "light")}
      overlay={boolean("Overlay", true)}
      format={text("Custom format", "dd MMM, hh:mm")}
      useLocalTimeZone={boolean("Use local time", false)}
      borderless={boolean("Remove border", false)}
    />
  ))
  .add("Zero price change", () => (
    <SharePriceTracker
      apiUrl="https://run.mocky.io/v3/200bceec-089e-431a-ad71-e2465948fee0"
      backgroundType={select("Background Type", BackgroundType, "dark")}
      overlay={boolean("Overlay", true)}
      format={text("Custom format", "dd MMM, hh:mm")}
      useLocalTimeZone={boolean("Use local time", false)}
      borderless={boolean("Remove border", false)}
    />
  ))
  .add("Tool Tracker Variant", () => (
    <SharePriceTracker
      apiUrl="https://run.mocky.io/v3/75cde8bd-a490-418c-9754-79e1acf1318d"
      overlay={false}
      format={text("Custom format", "EEEE, MMMM dd, Y • hh:mm")}
      title={text("Title", "Current Share Price Label")}
    >
      <div>Current Share Price Label</div>
    </SharePriceTracker>
  ))
  .add("Link Tracker Variant", () => (
    <SharePriceTracker
      backgroundType={select("Background Type", BackgroundType, "light")}
      format={text("Custom format", "dd MMM, hh:mm")}
      useLocalTimeZone={boolean("Use local time", true)}
      borderless={boolean("Remove border", false)}
      indexes={{
        price: 10889.41,
        netChange: -50.76,
        currency: "USD",
        time: "2023-06-05T20:09:00Z",
        percentualChange: -2.0,
        familyPageCode: "FTSE4Good",
        code: "FTSE4Good Developed"
      }}
      href={text("Index Page Link", "#")}
      staleAriaLabel={text(
        "No Price Change Aria Label",
        "{code} stock has no change to {price} {currency}. Updated on {date}."
      )}
      increasedAriaLabel={text(
        "Increased Price Change Aria Label",
        "{code} stock has increased by {netChange} or {percentualChange} percent to {price} {currency}. Updated on {date}."
      )}
      decreasedAriaLabel={text(
        "Decreased Price Change Aria Label",
        "{code} stock has decreased by {netChange} or {percentualChange} percent to {price} {currency}. Updated on {date}."
      )}
    />
  ))
  .add("Ticker  Variant", () => (
    <SharePriceTracker
      backgroundType={select("Background Type", BackgroundType, "light")}
      format={text("Custom format", "dd MMM, hh:mm")}
      useLocalTimeZone={boolean("Use local time", true)}
      ticker={true}
      indexes={{
        price: 10889.41,
        netChange: -50.76,
        currency: "USD",
        time: "2023-06-05T20:09:00Z",
        percentualChange: -2.0,
        familyPageCode: "FTSE4Good",
        code: "FTSE4Good Developed"
      }}
      href={text("Index Page Link", "#")}
      staleAriaLabel={text(
        "No Price Change Aria Label",
        "{code} stock has no change to {price} {currency}. Updated on {date}."
      )}
      increasedAriaLabel={text(
        "Increased Price Change Aria Label",
        "{code} stock has increased by {netChange} or {percentualChange} percent to {price} {currency}. Updated on {date}."
      )}
      decreasedAriaLabel={text(
        "Decreased Price Change Aria Label",
        "{code} stock has decreased by {netChange} or {percentualChange} percent to {price} {currency}. Updated on {date}."
      )}
    />
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <SharePriceTracker
        apiUrl="https://run.mocky.io/v3/75cde8bd-a490-418c-9754-79e1acf1318d"
        backgroundType={select("Background Type", BackgroundType, "light")}
        overlay={boolean("Overlay", true)}
        format={text("Custom format", "dd MMM, hh:mm")}
        useLocalTimeZone={boolean("Use local time", false)}
        borderless={boolean("Remove border", false)}
      />
    </Language>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <SharePriceTracker
        apiUrl="https://run.mocky.io/v3/75cde8bd-a490-418c-9754-79e1acf1318d"
        backgroundType={select("Background Type", BackgroundType, "light")}
        overlay={boolean("Overlay", true)}
        format={text("Custom format", "dd MMM, hh:mm")}
        useLocalTimeZone={boolean("Use local time", false)}
        borderless={boolean("Remove border", false)}
      />
    </StaticRenderer>
  ));
