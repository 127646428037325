import React from "react";
import { storiesOf } from "@storybook/react";
import { action } from "@storybook/addon-actions";
import { boolean, select, text, withKnobs } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import Dropdown from "./index";
import "./Dropdown.css";
import DropdownREADME from "../README.md";
import AjaxPanel from "@emcm-ui/component-panel/lib/Panel/Panel";
const stories = storiesOf("Components/Dropdown", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(DropdownREADME));

const icecreamFlavours = [
  {
    value: "chocolate",
    label: "Chocolate"
  },
  {
    value: "strawberry",
    label: "Strawberry"
  },
  {
    value: "vanilla",
    label: "Vanilla"
  }
];

const icecreamFlavoursWithIcons = [
  {
    value: "google",
    label: "Google Calendar",
    icon: "./icons/logos/google.svg"
  },
  {
    value: "apple",
    label: "Apple Calendar",
    icon: "./icons/logos/apple.svg"
  },
  {
    value: "outlook",
    label: "Outlook Calendar",
    icon: "./icons/logos/outlook.svg"
  },
  {
    value: "ical",
    label: "Download Ical",
    icon: "./icons/ui/grey/calendar-07.svg"
  }
];

const sizeOptions = ["large", "small"];
const colorOptions = ["primary", "secondary", "actionPositive"];
const buttonTypes = ["standard", "pill"];

const itemClickedAction = action("Item Clicked");
const valueChangedAction = action("Value Changed");

const onItemClicked = item => itemClickedAction(item);

const onValueChanged = value => valueChangedAction(value);

stories
  .add("Default", () => (
    <Dropdown
      block={boolean("Block", true)}
      items={icecreamFlavours}
      placeholder={text("Placeholder", "Your favourite ice cream")}
      onValueChanged={onValueChanged}
      size={select("Size", sizeOptions, "large")}
      id="dropdownList"
    />
  ))
  .add("With Icons", () => (
    <Dropdown
      block={boolean("Block", true)}
      items={icecreamFlavoursWithIcons}
      placeholder={text("Placeholder", "Add to Calendar")}
      onValueChanged={onValueChanged}
      size={select("Size", sizeOptions, "large")}
      placeholderIcon="./icons/ui/grey/calendar-07.svg"
    />
  ))
  .add("Stacked", () => (
    <div>
      <Dropdown
        block={boolean("Block", false)}
        items={icecreamFlavours}
        placeholder={text("Placeholder", "Your favourite ice cream")}
        onValueChanged={onValueChanged}
        size={select("Size", sizeOptions, "large")}
      />
      <p>This text should be behind the dropdown.</p>
    </div>
  ))
  .add("Stacked dropdowns", () => (
    <div>
      <div style={{ position: "relative", zIndex: "1" }}>
        <Dropdown
          block={boolean("Block", false)}
          items={icecreamFlavours}
          placeholder={text("Placeholder", "Your favourite ice cream")}
          onValueChanged={onValueChanged}
          size={select("Size", sizeOptions, "large")}
        />
      </div>
      <div>
        <Dropdown
          block={boolean("Block", false)}
          items={icecreamFlavours}
          placeholder={text("Placeholder", "Should be behind first dropdown")}
          onValueChanged={onValueChanged}
          size={select("Size", sizeOptions, "large")}
        />
        <p>
          <em>z-index is handled by the parent</em>
        </p>
      </div>
    </div>
  ))
  .add("With default value", () => (
    <Dropdown
      block={boolean("Block", true)}
      items={icecreamFlavours}
      placeholder={text("Placeholder", "Your favourite ice cream")}
      size={select("Size", sizeOptions, "large")}
      value={select(
        "Value",
        icecreamFlavours.map(item => item.value),
        icecreamFlavours[1].value
      )}
      onValueChanged={onValueChanged}
    />
  ))
  .add("With links instead of values", () => (
    <Dropdown
      block={boolean("Block", true)}
      items={[
        {
          href: "#",
          label: "Product login"
        },
        {
          href: "#",
          label: "Support"
        },
        {
          href: "#",
          label: "Contact"
        }
      ]}
      placeholder={text("Placeholder", "My Dropdown")}
      size={select("Size", sizeOptions, "large")}
    />
  ))
  .add("With a custom header", () => (
    <Dropdown
      block={boolean("Block", false)}
      items={icecreamFlavours}
      placeholder={text("Placeholder", "Open the dropdown")}
      renderHeader={({ text: textValue, expanded, toggleExpand }) => (
        <button onClick={toggleExpand} style={{ backgroundColor: "pink" }}>
          {expanded ? "Close the dropdown" : textValue}
        </button>
      )}
      size={select("Size", sizeOptions, "small")}
    />
  ))
  .add("With overridden click callback (no value control)", () => (
    <Dropdown
      block={boolean("Block", true)}
      items={icecreamFlavours}
      placeholder={text("Placeholder", "Your favourite ice cream")}
      onItemClicked={onItemClicked}
      size={select("Size", sizeOptions, "large")}
    />
  ))
  .add("Dropdown with Button CTA", () => (
    <Dropdown
      block={boolean("Block", false)}
      buttonText={text("Placeholder", "Go")}
      buttonType={select("buttonType", buttonTypes, "standard")}
      buttonColor={select("buttonColor", colorOptions, "primary")}
      items={[
        {
          value: "https://example.com/product",
          label: "Product login"
        },
        {
          value: "https://example.com/support",
          label: "Support"
        },
        {
          value: "https://example.com/contact",
          label: "Contact"
        }
      ]}
      labelText={text("Label", "Page Links")}
      placeholder={text("Placeholder", "Please select a link from the list")}
      size={select("Size", sizeOptions, "large")}
    />
  ))

  .add("Dropdown with typeAhead search", () => (
    <Dropdown
      block={boolean("Block", false)}
      items={[
        {
          value: "https://example.com/product",
          label: "Product login"
        },
        {
          value: "https://example.com/support",
          label: "Support"
        },
        {
          value: "https://example.com/contact",
          label: "Contact"
        }
      ]}
      labelText={text("Label", "Page Links")}
      placeholder={text("Placeholder", "Please select a link from the list")}
      size={select("Size", sizeOptions, "large")}
      noRecordText={text("No record text", "No record found")}
      searchPlaceHolder={text("Search placeholder", "search from the list")}
      typeAheadEnabled={boolean("Enable typeAhead", true)}
    />
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Dropdown
        block={boolean("Block", true)}
        items={[
          {
            value: "chocolate",
            label: "شوكولاتة"
          },
          {
            value: "strawberry",
            label: "الفراولة"
          },
          {
            value: "vanilla",
            label: "فانيلا"
          }
        ]}
        placeholder={text("Placeholder", "الآيس كريم المفضلة لديك")}
        size={select("Size", sizeOptions, "large")}
      />
    </Language>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <Dropdown
        block={boolean("Block", true)}
        items={icecreamFlavours}
        placeholder={text("Placeholder", "Your favourite ice cream")}
        size={select("Size", sizeOptions, "large")}
      />
    </StaticRenderer>
  ))
  .add("With Icons Rehydrated", () => (
    <StaticRenderer>
      <Dropdown
        block={boolean("Block", true)}
        items={icecreamFlavoursWithIcons}
        placeholder={text("Placeholder", "Add to Calendar")}
        onValueChanged={onValueChanged}
        size={select("Size", sizeOptions, "large")}
        placeholderIcon="./icons/ui/grey/calendar-07.svg"
      />
    </StaticRenderer>
  ))
  .add("CTA Rehydrated", () => (
    <StaticRenderer>
      <Dropdown
        block={boolean("Block", false)}
        buttonText={text("Button Text", "Go")}
        buttonType={select("buttonType", buttonTypes, "standard")}
        buttonColor={select("buttonColor", colorOptions, "primary")}
        buttonCanonicalCta="true"
        items={[
          {
            value: "https://example.com/product",
            label: "Product login"
          },
          {
            value: "https://example.com/support",
            label: "Support"
          },
          {
            value: "https://example.com/contact",
            label: "Contact"
          }
        ]}
        labelText={text("Label", "Page Links")}
        placeholder={text("Placeholder", "Please select a link from the list")}
        size={select("Size", sizeOptions, "large")}
      />
    </StaticRenderer>
  ))
  .add("Ajax Dropdown", () => {
    return (
      <Dropdown
        block={boolean("Block", true)}
        items={icecreamFlavours}
        placeholder={text("Placeholder", "Your favourite ice cream")}
        onValueChanged={onValueChanged}
        size={select("Size", sizeOptions, "large")}
      >
        <AjaxPanel
          id="chocolate"
          tabTitle="chocolate"
          contentLocation={`./chocolate.html`}
        />
        <AjaxPanel
          id="vanilla"
          tabTitle="vanilla"
          contentLocation={`./vanilla.html`}
        />
        <AjaxPanel
          id="strawberry"
          tabTitle="strawberry"
          contentLocation={`./strawberry.html`}
        />
      </Dropdown>
    );
  })
  .add("Ajax Dropdown Rehydrated", () => {
    return (
      <StaticRenderer>
        <Dropdown
          block={boolean("Block", true)}
          items={icecreamFlavours}
          placeholder={text("Placeholder", "Your favourite ice cream")}
          onValueChanged={onValueChanged}
          size={select("Size", sizeOptions, "large")}
        >
          <AjaxPanel
            id="chocolate"
            tabTitle="chocolate"
            contentLocation={`./chocolate.html`}
          />
          <AjaxPanel
            id="vanilla"
            tabTitle="vanilla"
            contentLocation={`./vanilla.html`}
          />
          <AjaxPanel
            id="strawberry"
            tabTitle="strawberry"
            contentLocation={`./strawberry.html`}
          />
        </Dropdown>
      </StaticRenderer>
    );
  });
