import React from "react";
import SalesCard from "./SalesCard";

// eslint-disable-next-line require-await
export default async (domNode, rehydrateChildren) => {
  const props = {
    theme: domNode.getAttribute("data-theme"),
    currency: domNode.getAttribute("data-currency"),
    price: parseInt(domNode.getAttribute("data-price")),
    isPriceTermRequired: JSON.parse(
      domNode.getAttribute("data-price-term-required")
    ),
    headingStyle: domNode.getAttribute("data-heading-style"),
    descriptiveCard: domNode.getAttribute("data-descriptive-card"),
    validity: domNode.getAttribute("data-validity"),
    descriptionType: domNode.getAttribute("data-description-type"),
    description: domNode.getAttribute("data-description"),
    isBtnRequired: JSON.parse(domNode.getAttribute("data-btn-required")),
    href: domNode.getAttribute("data-href"),
    buttonText: domNode.getAttribute("data-button-text"),

    isSecondaryBtnRequired: JSON.parse(
      domNode.getAttribute("data-secondary-btn-required")
    ),
    secondaryHref: domNode.getAttribute("data-secondary-href"),
    secondaryButtonText: domNode.getAttribute("data-secondary-button-text"),
    isLegalCopyRequired: JSON.parse(
      domNode.getAttribute("data-legal-copy-required")
    ),
    legalCopyText: domNode.getAttribute("data-legal-copy-text"),
    descriptionArray: JSON.parse(domNode.getAttribute("data-description-array"))
  };

  const childrenNode = domNode.querySelector("[data-rehydratable-children]");
  const children = await rehydrateChildren(childrenNode);

  return <SalesCard {...props}>{children}</SalesCard>;
};
