// search parameter
export const SORT = "sort";
export const PAGE = "page";
export const SELECTED = "selected";
export const DESELECTED = "deselected";
export const CROSS_FACET_FILTER = "crossFacetFilter";
// request parameter
export const SEARCH_QUERY_STRING_KEY = "q";
export const ELASTIC_SEARCH_QUERY_KEY_PREFIX = "x";
export const ELASTIC_SEARCH_QUERY_VALUE_PREFIX = "q";
export const ELASTIC_SEARCH_QUERY_KEY = "x1";
// cards
export const FEATURED_CARD_COUNT = 4;
export const FEATURED_SECTION_CARDS_COUNT = 3;
export const FEATURED_BILLBOARD_CARD_INDEX = 3;
export const FIRST_PAGE = 1;
export const CARD_PER_SECTION = 9;
export const COLUMN_COUNT_DESKTOP = 3;
export const COLUMN_COUNT_TABLET = 2;
// Filter key
export const FILTER_TOPIC_KEY = "topic:";
export const FILTER_TOPICS_ID = "w_topics_id";
export const FILTER_THEMES_ID = "w_themes_id";
// card page type
export const CARD_ARTICLES = "Articles";
export const CARD_RESEARCH = "research";
export const CARD_DATA_VISUALIZATION = "data visualization";
export const CARD_VIDEO = "video";
export const CARD_EVENT = "event";
// device width
export const MAX_MOBILE_WIDTH = 768;
// fetch response index
export const FETCH_RESPONSE_INDEX_SEARCH_RESULT = 0;
export const FETCH_RESPONSE_INDEX_FEATURED_CARD = 1;
// Analytics
export const ANALYTICS_APPLY_FILTER = "analytics.applyFilter";
export const ANALYTICS_PAGE_LOAD = "analytics.pageload";
export const ANALYTICS_TAB_CHANGE = "analytics.tabChange";
export const FILTER_TYPE = "data catalog";
export const SEARCH_LAYOUT = "Search result | Tiles";
export const SEARCH_TYPE = "content search";
export const THEMES = "themes";
export const TOPICS = "topics";
// icons
export const ICON = {
  clock: "./icons/ui/grey/time-07.svg",
  arrow: "./icons/ui/white/arrow-right.svg",
  video: "./icons/ui/grey/playoutline-07.svg"
};
