import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import SearchResults from "@emcm-ui/component-search-results";
import Section from "@emcm-ui/component-section";

const NoResult = ({ noMatchesText, search }) => {
  const getClassName = getClassNameFactory("SearchResultsMixture");
  const { searchKey } = search;

  const noResultTitle = noMatchesText.replace("{term}", searchKey);

  return (
    <Section background="white">
      <div
        className={getClassName({
          descendantName: "result-summary"
        })}
      >
        <strong>{noResultTitle}</strong>
      </div>
      <SearchResults.NoResults />
    </Section>
  );
};

NoResult.displayName = "SearchResults.NoResult";

NoResult.propTypes = {
  /**
   * Text for no result found text
   */
  noMatchesText: PropTypes.string,
  /**
   * search information
   */
  search: PropTypes.object
};

export default NoResult;
