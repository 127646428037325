import React from "react";
import { storiesOf } from "@storybook/react";
import { action } from "@storybook/addon-actions";
import { withKnobs, text, boolean, object } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import CartSummary from "./index";
import "./CartSummary.css";
import CartSummaryREADME from "../README.md";

const stories = storiesOf("Components/E-Commerce/CartSummary", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(CartSummaryREADME));

const cartSummaryItemSelect = action("Cart Summary Details");

const exportCartItem = value => cartSummaryItemSelect(value);

stories
  .add("Default", () => (
    <CartSummary
      priceLabel={text("Price Label", "Price")}
      licenseLabel={text("License Label", "Licenses")}
      licenseUnit={text("License Unit", "per license")}
      products={object("Products", [
        {
          id: "product-name-1",
          name: "Product Name 1",
          licenseCount: 2,
          price: 9,
          currency: "£",
          additionalText: "Additional information",
          isSelected: true
        },
        {
          id: "product-name-2",
          name: "Product Name 2",
          licenseCount: 3,
          price: 10,
          currency: "$",
          isSelected: false
        },
        {
          id: "product-name-3",
          name: "Product Name 3",
          licenseCount: 1,
          price: 5,
          currency: "£",
          additionalText: "Additional information",
          isSelected: false
        }
      ])}
      timeframe={text("Timeframe text", "Monthly subscription:")}
      isRadio={false}
      exportCartItem={exportCartItem}
    />
  ))
  .add("Radio Variant", () => (
    <CartSummary
      priceLabel={text("Price Label", "Price")}
      licenseLabel={text("License Label", "Licenses")}
      licenseUnit={text("License Unit", "per license")}
      products={object("Products", [
        {
          id: "product-name-1",
          name: "Product Name 1",
          licenseCount: 1,
          price: 9,
          currency: "£",
          additionalText: "Additional information",
          isSelected: true
        },
        {
          id: "product-name-2",
          name: "Product Name 2",
          licenseCount: 2,
          price: 10,
          currency: "$",
          isSelected: false
        },
        {
          id: "product-name-3",
          name: "Product Name 3",
          licenseCount: 1,
          price: 5,
          currency: "£",
          additionalText: "Additional information",
          isSelected: false
        }
      ])}
      timeframe={text("Timeframe text", "Monthly subscription:")}
      isRadio={boolean("Radio Variant", true)}
      exportCartItem={exportCartItem}
    />
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <CartSummary
        priceLabel={text("Price Label", "Price")}
        licenseLabel={text("License Label", "Licenses")}
        licenseUnit={text("License Unit", "per license")}
        products={object("Products", [
          {
            id: "product-name-1",
            name: "Product Name 1",
            licenseCount: 1,
            price: 9,
            currency: "£",
            additionalText: "Additional information",
            isSelected: true
          },
          {
            id: "product-name-2",
            name: "Product Name 2",
            licenseCount: 2,
            price: 10,
            currency: "$",
            isSelected: false
          }
        ])}
        timeframe={text("Timeframe text", "Monthly subscription:")}
        isRadio={false}
        exportCartItem={exportCartItem}
      />
    </Language>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <CartSummary
        priceLabel={text("Price Label", "Price")}
        licenseLabel={text("License Label", "Licenses")}
        licenseUnit={text("License Unit", "per license")}
        products={object("Products", [
          {
            id: "product-name-1",
            name: "Product Name 1",
            licenseCount: 1,
            price: 9,
            currency: "£",
            additionalText: "Additional information",
            isSelected: true
          },
          {
            id: "product-name-2",
            name: "Product Name 2",
            licenseCount: 2,
            price: 10,
            currency: "$",
            isSelected: false
          }
        ])}
        timeframe={text("Timeframe text", "Monthly subscription:")}
        isRadio={false}
        exportCartItem={exportCartItem}
      />
    </StaticRenderer>
  ))
  .add("Rehydrated Radio Variant", () => (
    <StaticRenderer>
      <CartSummary
        priceLabel={text("Price Label", "Price")}
        licenseLabel={text("License Label", "Licenses")}
        licenseUnit={text("License Unit", "per license")}
        products={object("Products", [
          {
            id: "product-name-1",
            name: "Product Name 1",
            licenseCount: 1,
            price: 9,
            currency: "£",
            additionalText: "Additional information",
            isSelected: true
          },
          {
            id: "product-name-2",
            name: "Product Name 2",
            licenseCount: 2,
            price: 10,
            currency: "$",
            isSelected: false
          }
        ])}
        timeframe={text("Timeframe text", "Monthly subscription:")}
        isRadio={boolean("Radio Variant", true)}
        exportCartItem={exportCartItem}
      />
    </StaticRenderer>
  ));
