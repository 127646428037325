import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, boolean, select, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import CampaignTrackingParameterService from "@emcm-ui/utility-campaign";
import queryString from "query-string";
import { action } from "@storybook/addon-actions";
import "./FormsNewStyle.css";
import FormREADME from "../README.md";
import Typestack from "@emcm-ui/component-typestack";
import VerticalSpacing from "../../component-vertical-spacing/src/VerticalSpacing";
import FormsNewStyle from "./index";

const stories = storiesOf("Components/FormsNewStyle", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(FormREADME));

const actions = {
  "https://jsonplaceholder.typicode.com/posts": "Succeed post",
  "https://demo1129453.mockable.io/form/succeed/inline-message":
    "Succeed inline",
  "https://demo1129453.mockable.io/form/succeed/redirect": "Succeed redirect",
  "https://demo1129453.mockable.io/form/fail/client": "Fail client",
  "https://demo1129453.mockable.io/form/fail/server": "Fail server",
  "https://demo1129453.mockable.io/form/fail/server-fault":
    "Fail server (invalid response)"
};

const countries = [
  { value: "AU", label: "Australia" },
  { value: "AT", label: "Austria" },
  { value: "CN", label: "Canada" },
  { value: "CZ", label: "Czech Republic" },
  { value: "DK", label: "Denmark" },
  { value: "KR", label: "Korea" },
  { value: "MX", label: "Mexico" },
  { value: "PH", label: "Philippines" },
  { value: "PL", label: "Poland" },
  { value: "PT", label: "Portugal" },
  { value: "GB", label: "United Kingdom" },
  { value: "US", label: "United States" },
  { value: "YE", label: "Yemen" },
  { value: "ZW", label: "Zimbabwe" },
  { value: "ZA", label: "South Africa" }
];

const compliantCountries = ["AT", "CZ", "DK", "PL", "PT", "GB", "ZA"];
const textControlTypes = ["email", "text", "url", "tel"];
const autocompleteTypes = ["on", "off"];
const confirmationTypes = ["inlineMessage", "redirect", "downloadFile"];

const toggleSwitchClick = action("Toggle state");

const captureToogleState = value => toggleSwitchClick(value);

stories
  .add("TextControl", () => (
    <FormsNewStyle
      action={select("Action", actions, Object.keys(actions)[0])}
      isDark={boolean("dark background", false)}
      confirmationType={select(
        "action type",
        confirmationTypes,
        "inlineMessage"
      )}
      redirectPage={text("redirect page")}
      defaultFailMessage={text(
        "Submit fail message",
        "An error occurred during submission."
      )}
      defaultSuccessMessage={text(
        "Submit success message",
        "Submission completed successfully."
      )}
      allowFormReSubmit={boolean("Allow form re-submit", true)}
    >
      <FormsNewStyle.TextControl
        autoComplete={select("autocomplete", autocompleteTypes, "off")}
        disabled={boolean("disabled", false)}
        id="input1"
        labelText={text("labelText", "Label")}
        optionalText={text("optionalText", "(optional)")}
        maxLength={text("maxlength", "40")}
        name="input1"
        required={boolean("required", true)}
        tabIndex={text("tabIndex", "1")}
        errorMessage={text(
          "errorMessage",
          "Error Message: This field is required"
        )}
        type={select("type", textControlTypes, "text")}
        pattern={text("pattern (regex)")}
        helpText={text("Help Text", "This is a hint about this field")}
      />
    </FormsNewStyle>
  ))
  .add("TextareaControl", () => (
    <FormsNewStyle
      action="https://jsonplaceholder.typicode.com/posts"
      isDark={boolean("dark background", false)}
      defaultFailMessage={text(
        "Submit fail message",
        "An error occurred during submission."
      )}
      defaultSuccessMessage={text(
        "Submit success message",
        "Submission completed successfully."
      )}
      allowFormReSubmit={boolean("Allow form re-submit", true)}
    >
      <FormsNewStyle.TextareaControl
        autoComplete={select("autocomplete", autocompleteTypes, "off")}
        disabled={boolean("disabled", false)}
        id={text("id", "textarea1")}
        labelText={text("labelText", "Label")}
        optionalText={text("optionalText", "(optional)")}
        maxLength={text("maxlength", "160")}
        name={text("name", "textarea1")}
        required={boolean("required", true)}
        tabIndex={text("tabIndex", "1")}
        errorMessage={text(
          "errorMessage",
          "Error Message: This field is required"
        )}
        helpText={text("Help Text", "This is a hint about this field")}
      />
    </FormsNewStyle>
  ))
  .add("SelectControl", () => {
    const options = [
      { value: "1", label: "Line item 1" },
      { value: "2", label: "Line item 2" },
      { value: "3", label: "Line item 3" },
      { value: "4", label: "Line item 4" },
      { value: "5", label: "Line item 5" },
      { value: "6", label: "Line item 6" },
      { value: "7", label: "Line item 7" }
    ];

    return (
      <FormsNewStyle
        action="https://jsonplaceholder.typicode.com/posts"
        isDark={boolean("dark background", false)}
        defaultFailMessage={text(
          "Submit fail message",
          "An error occurred during submission."
        )}
        defaultSuccessMessage={text(
          "Submit success message",
          "Submission completed successfully."
        )}
        allowFormReSubmit={boolean("Allow form re-submit", true)}
      >
        <FormsNewStyle.SelectControl
          disabled={boolean("disabled", false)}
          id={text("id", "select1")}
          labelText={text("labelText", "Label")}
          optionalText={text("optionalText", "(optional)")}
          name={text("name", "select1")}
          options={options}
          placeholder={boolean("placeholder", true)}
          placeholderText={text("placeholderText", "Select…")}
          required={boolean("required", true)}
          tabIndex={text("tabIndex", "1")}
          errorMessage={text(
            "errorMessage",
            "Error Message: This field is required"
          )}
          helpText={text("Help Text", "This is a hint about this field")}
        />
      </FormsNewStyle>
    );
  })
  .add("MultiSelectDropdown Control", () => {
    const icecreamFlavours = [
      {
        value: "chocolate",
        label: "Chocolate"
      },
      {
        value: "strawberry",
        label: "Strawberry"
      },
      {
        value: "vanilla",
        label: "Vanilla"
      }
    ];

    return (
      <FormsNewStyle
        action="https://jsonplaceholder.typicode.com/posts"
        isDark={boolean("dark background", false)}
        defaultFailMessage={text(
          "Submit fail message",
          "An error occurred during submission."
        )}
        defaultSuccessMessage={text(
          "Submit success message",
          "Submission completed successfully."
        )}
        allowFormReSubmit={boolean("Allow form re-submit", true)}
      >
        <FormsNewStyle.MultiSelectDropdown
          id={text("id", "multiselect1")}
          name={text("Name", "multiselect1")}
          noOptionsMessage={text("noOptionsMessage", "No Item Found")}
          options={icecreamFlavours}
          placeholder={text("placeholder", "Select from the list")}
          required={boolean("required", true)}
          errorMessage={text(
            "errorMessage",
            "Error Message: This field is required"
          )}
          labelText={text("labelText", "Label(optional)")}
          helpText={text("Help Text", "This is a hint about this field")}
        />
      </FormsNewStyle>
    );
  })
  .add("Rehydrated MultiSelectDropdown Control", () => (
    <StaticRenderer>
      <FormsNewStyle
        action="https://jsonplaceholder.typicode.com/posts"
        isDark={boolean("dark background", false)}
        defaultFailMessage={text(
          "Submit fail message",
          "An error occurred during submission."
        )}
        defaultSuccessMessage={text(
          "Submit success message",
          "Submission completed successfully."
        )}
      >
        <FormsNewStyle.MultiSelectDropdown
          id={text("id", "multiselect1")}
          name={text("Name", "multiselect1")}
          noOptionsMessage={text("noOptionsMessage", "No Item Found")}
          options={[
            {
              value: "chocolate",
              label: "Chocolate"
            },
            {
              value: "strawberry",
              label: "Strawberry"
            },
            {
              value: "vanilla",
              label: "Vanilla"
            }
          ]}
          placeholder={text("placeholder", "Select from the list")}
          required={boolean("required", true)}
          errorMessage={text(
            "errorMessage",
            "Error Message: This field is required"
          )}
          labelText={text("labelText", "Label(optional)")}
          helpText={text("Help Text", "This is a hint about this field")}
        />
      </FormsNewStyle>
    </StaticRenderer>
  ))
  .add("Fieldset", () => {
    return (
      <FormsNewStyle
        action="https://jsonplaceholder.typicode.com/posts"
        isDark={boolean("dark background", false)}
        defaultFailMessage={text(
          "Submit fail message",
          "An error occurred during submission."
        )}
        defaultSuccessMessage={text(
          "Submit success message",
          "Submission completed successfully."
        )}
        allowFormReSubmit={boolean("Allow form re-submit", true)}
      >
        <FormsNewStyle.Fieldset
          legend={text("Fieldset legend", "Fieldset legend")}
          optionalText={text("Fieldset optionalText", "(optional)")}
          required={boolean("required", true)}
        >
          <VerticalSpacing>
            <FormsNewStyle.TextControl
              id="input1"
              labelText="Text control"
              name="input1"
              required={boolean("required", true)}
              errorMessage={text(
                "errorMessage",
                "Error Message: This field is required"
              )}
            />
          </VerticalSpacing>
          <VerticalSpacing>
            <FormsNewStyle.TextControl
              id="input2"
              labelText="Text control"
              name="input2"
              required={boolean("required", true)}
              errorMessage={text(
                "errorMessage",
                "Error Message: This field is required"
              )}
            />
          </VerticalSpacing>
        </FormsNewStyle.Fieldset>
      </FormsNewStyle>
    );
  })

  .add("ToggleSwitchControl", () => (
    <FormsNewStyle
      action="https://jsonplaceholder.typicode.com/posts"
      isDark={boolean("dark background", false)}
      defaultFailMessage={text(
        "Submit fail message",
        "An error occurred during submission."
      )}
      defaultSuccessMessage={text(
        "Submit success message",
        "Submission completed successfully."
      )}
      allowFormReSubmit={boolean("Allow form re-submit", true)}
    >
      <FormsNewStyle.ToggleSwitchControl
        id="toggleswitch1"
        active={boolean("Active", true)}
        activeText={text("Active text", "On")}
        inActiveText={text("Inactive text", "Off")}
        name="toggleswitch1"
        disabled={boolean("Disabled", false)}
        onClick={captureToogleState}
      />
    </FormsNewStyle>
  ))

  .add("Rehydrated ToggleSwitchControl", () => (
    <StaticRenderer>
      <FormsNewStyle
        action="https://jsonplaceholder.typicode.com/posts"
        isDark={boolean("dark background", false)}
        defaultFailMessage={text(
          "Submit fail message",
          "An error occurred during submission."
        )}
        defaultSuccessMessage={text(
          "Submit success message",
          "Submission completed successfully."
        )}
        allowFormReSubmit={boolean("Allow form re-submit", true)}
      >
        <FormsNewStyle.ToggleSwitchControl
          id="toggleswitch2"
          active={boolean("Active", true)}
          activeText={text("Active text", "On")}
          inActiveText={text("Inactive text", "Off")}
          name={text("Name", "Toggleswitch2")}
          disabled={boolean("Disabled", false)}
          onClick={captureToogleState}
        />
      </FormsNewStyle>
    </StaticRenderer>
  ))
  .add("CheckboxControl", () => {
    const checkboxState = () => {
      document.querySelector("#checkbox3").indeterminate = true;
    };

    window.onload = checkboxState;

    return (
      <FormsNewStyle
        action="https://jsonplaceholder.typicode.com/posts"
        isDark={boolean("dark background", false)}
        defaultFailMessage={text(
          "Submit fail message",
          "An error occurred during submission."
        )}
        defaultSuccessMessage={text(
          "Submit success message",
          "Submission completed successfully."
        )}
        allowFormReSubmit={boolean("Allow form re-submit", true)}
      >
        <FormsNewStyle.Fieldset
          legend={text("Fieldset legend", "Label")}
          optionalText={text("Fieldset optionalText", "(optional)")}
          required={boolean("required", true)}
        >
          <FormsNewStyle.CheckboxControl
            disabled={boolean("disabled", false)}
            id={text("id 1", "checkbox1")}
            labelText={text("labelText 1", "Checkbox label")}
            optionalText={text("optionalText 1", "(optional)")}
            name={text("name 1", "checkbox1")}
            required={boolean("required", true)}
            hideOptional={boolean("hideOptional", true)}
            tabIndex={text("tabIndex 1", "1")}
            errorMessage={text(
              "errorMessage",
              "Error Message: This field is required"
            )}
            value={text("value 1", "1")}
          />
          <FormsNewStyle.CheckboxControl
            disabled={boolean("disabled", false)}
            id={text("id 2", "checkbox2")}
            labelText={text("labelText 2", "Checkbox label")}
            optionalText={text("optionalText 2", "(optional)")}
            name={text("name 2", "checkbox2")}
            required={boolean("required", true)}
            hideOptional={boolean("hideOptional", true)}
            tabIndex={text("tabIndex 2", "2")}
            errorMessage={text(
              "errorMessage",
              "Error Message: This field is required"
            )}
            value={text("value 2", "2")}
          />
          <VerticalSpacing>
            <FormsNewStyle.CheckboxControl
              disabled={boolean("disabled", false)}
              id={text("id 3", "checkbox3")}
              labelText={text("labelText 3", "Checkbox label")}
              optionalText={text("optionalText 3", "(optional)")}
              name={text("name 3", "checkbox3")}
              required={boolean("required", true)}
              hideOptional={boolean("hideOptional", true)}
              tabIndex={text("tabIndex 3", "3")}
              errorMessage={text(
                "errorMessage",
                "Error Message: This field is required"
              )}
              value={text("value 3", "3")}
            />
          </VerticalSpacing>
        </FormsNewStyle.Fieldset>
      </FormsNewStyle>
    );
  })
  .add("RadioControl", () => {
    const options = [
      { label: "Radio label", value: "daily" },
      { label: "Radio label", value: "weekly" },
      { label: "Radio label", value: "monthly" }
    ];

    return (
      <FormsNewStyle
        action="https://jsonplaceholder.typicode.com/posts"
        isDark={boolean("dark background", false)}
        defaultFailMessage={text(
          "Submit fail message",
          "An error occurred during submission."
        )}
        defaultSuccessMessage={text(
          "Submit success message",
          "Submission completed successfully."
        )}
        allowFormReSubmit={boolean("Allow form re-submit", true)}
      >
        <FormsNewStyle.Fieldset
          legend={text("Fieldset legend", "Label")}
          optionalText={text("Fieldset optionalText", "(optional)")}
          required={boolean("required", true)}
        >
          <FormsNewStyle.RadioControl
            disabled={boolean("disabled", false)}
            name={text("name", "radio")}
            options={options}
            required={boolean("required", true)}
          />
        </FormsNewStyle.Fieldset>
      </FormsNewStyle>
    );
  })
  .add("CampaignControl", () => {
    const parameterToTrack = text("parameterToTrack", "selectedStory");
    const service = new CampaignTrackingParameterService([parameterToTrack]);

    service.add(queryString.parse(window.location.search));

    return (
      <div>
        <p>
          The CampaignControl is a hidden element. However, upon inspection, its
          value should match the {parameterToTrack} parameter ({service.get(
            parameterToTrack
          )}) in the URL of this page.
        </p>
        <FormsNewStyle
          action={select("Action", actions, Object.keys(actions)[0])}
        >
          <FormsNewStyle.CampaignControl
            name="campaign1"
            parameterToTrack={parameterToTrack}
            service={service}
          />
        </FormsNewStyle>
      </div>
    );
  })
  .add("PrivacyControl", () => {
    return (
      <FormsNewStyle
        action="https://jsonplaceholder.typicode.com/posts"
        isDark={boolean("dark background", false)}
        defaultFailMessage={text(
          "Submit fail message",
          "An error occurred during submission."
        )}
        defaultSuccessMessage={text(
          "Submit success message",
          "Submission completed successfully."
        )}
      >
        <VerticalSpacing>
          <FormsNewStyle.SelectControl
            id="countries"
            labelText={"Select your country"}
            name="countries"
            options={countries}
            required
          />
        </VerticalSpacing>
        <VerticalSpacing>
          <FormsNewStyle.PrivacyControl
            consentName={text("consentName", "consent")}
            customCompliantsLabel="Accepted channel (Aleast any one)"
            optionalLabel={text("OptionalLabel", "")}
            customCompliants={[
              {
                country: "ZA",
                channels: [
                  {
                    name: "phone",
                    displayname: "Phone Number"
                  },
                  {
                    name: "email",
                    displayname: "Email Address"
                  }
                ],
                text:
                  "I Confirm my signature and consent to receive communication about refinitiv resources, events, product or service. Please note you can manage and update your preference at any time"
              }
            ]}
            consentText={
              <span>
                By submitting this form you are acknowledging that you have read
                and agree to our{" "}
                <a href="https://www.example.com">Marketing Policy</a> and would
                therefore be fine receiving our newsletter time to time.
              </span>
            }
            compliantCountries={compliantCountries}
            dependsOn="countries"
            explicitConsentText="Custom I'd like to receive your newsletter"
          />
        </VerticalSpacing>
      </FormsNewStyle>
    );
  })
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <FormsNewStyle
        action={select("Action", actions, Object.keys(actions)[0])}
        header={
          <Typestack align="left" rank="h4" type="h4">
            أرسل لي رسائل البريد الإلكتروني
          </Typestack>
        }
        isDark={boolean("dark background", false)}
        defaultFailMessage={text(
          "Submit fail message",
          "An error occurred during submission."
        )}
        defaultSuccessMessage={text(
          "Submit success message",
          "Submission completed successfully."
        )}
        submitButtonText={text("submitButtonText", "خضع")}
        allowFormReSubmit={boolean("Allow form re-submit", true)}
      >
        الأطفال
      </FormsNewStyle>
    </Language>
  ))
  .add("Controlled components", () => {
    class ControlledForm extends React.Component {
      constructor(props) {
        super(props);

        this.state = {
          checkboxControl: false,
          radioControl: "second",
          selectControl: "B", // Only updated onBlur
          selectControlActive: "B", // Always the current value of the select
          textareaControl: "textarea control",
          textControl: "text control"
        };
      }

      handleChangedRadio = e => {
        this.setStateIfEnabled({
          radioControl: e.target.value
        });
      };

      setStateIfEnabled = state => {
        // eslint-disable-next-line react/prop-types
        if (this.props.allowStateChanges) {
          this.setState(state);
        }
      };

      render() {
        return (
          <FormsNewStyle
            action="https://jsonplaceholder.typicode.com/posts"
            header={
              <Typestack align="left" rank="h4" type="h4">
                Controlled Components
              </Typestack>
            }
            footer={
              <span>
                By submitting this form you are acknowledging that you have read
                and agree to our{" "}
                <a href="https://not-visited.com">Privacy Policy</a>
              </span>
            }
            isDark={boolean("dark background", false)}
            defaultFailMessage={text(
              "Submit fail message",
              "An error occurred during submission."
            )}
            defaultSuccessMessage={text(
              "Submit success message",
              "Submission completed successfully."
            )}
            submitButtonText="Submit"
          >
            <VerticalSpacing>
              <FormsNewStyle.TextControl
                id="input1"
                labelText="TextControl"
                name="input1"
                onChange={e =>
                  this.setStateIfEnabled({ textControl: e.target.value })
                }
                required
                errorMessage="This field is required"
                defaultValue={this.state.textControl}
              />
            </VerticalSpacing>
            <VerticalSpacing>
              <FormsNewStyle.CheckboxControl
                checked={this.state.checkboxControl}
                id="checkbox"
                labelText="Checkbox control"
                name="checkbox"
                onChange={e =>
                  this.setStateIfEnabled({ checkboxControl: e.target.checked })
                }
                errorMessage="This field is required"
                value="checkbox"
              />
            </VerticalSpacing>
            <VerticalSpacing>
              <FormsNewStyle.RadioControl
                name="radio"
                options={[
                  {
                    label: "RadioControl",
                    onChange: this.handleChangedRadio,
                    value: "first"
                  },
                  {
                    label: "With A Default Second",
                    onChange: this.handleChangedRadio,
                    value: "second"
                  },
                  {
                    label: "Option",
                    onChange: this.handleChangedRadio,
                    value: "option"
                  }
                ]}
                value={this.state.radioControl}
              />
            </VerticalSpacing>
            <VerticalSpacing>
              <FormsNewStyle.SelectControl
                id="selectcontrol"
                labelText="Required SelectControl with default value"
                name="selectcontrol"
                onChange={e =>
                  this.setStateIfEnabled({
                    selectControlActive: e.target.value
                  })
                }
                onBlur={e =>
                  this.setStateIfEnabled({ selectControl: e.target.value })
                }
                options={[
                  { value: "A", label: "Line item A" },
                  { value: "B", label: "Line item B" },
                  { value: "C", label: "Line item C" }
                ]}
                value={this.state.selectControlActive}
                required
                errorMessage="This field is required"
              />
            </VerticalSpacing>
            <VerticalSpacing>
              <p>
                The value in the SelectControl is {this.state.selectControl}.
              </p>
            </VerticalSpacing>
            <VerticalSpacing>
              <FormsNewStyle.TextareaControl
                id="textarea"
                labelText="TextareaControl"
                name="textarea"
                onChange={e =>
                  this.setStateIfEnabled({ textareaControl: e.target.value })
                }
                defaultValue={this.state.textareaControl}
                errorMessage="This field is required"
              />
            </VerticalSpacing>

            <VerticalSpacing>
              <FormsNewStyle.ToggleSwitchControl
                id="toggleswitch"
                active={true}
                activeText="On"
                inActiveText="Off"
                name="toggleswitch"
              />
            </VerticalSpacing>
          </FormsNewStyle>
        );
      }
    }

    return (
      <ControlledForm
        allowStateChanges={boolean("Allow state changes?", true)}
      />
    );
  })
  .add("Rehydrated", () => (
    <StaticRenderer>
      <FormsNewStyle
        action="https://jsonplaceholder.typicode.com/posts"
        analyticsName="analytics-name"
        analyticsNodes={{ textControl: "input1", fruits: "selectcontrol" }}
        analyticsType="analytics-type"
        header={
          <Typestack align="left" rank="h4" type="h4">
            Form
          </Typestack>
        }
        footer={
          <span>
            By submitting this form you are acknowledging that you have read and
            agree to our <a href="https://not-visited.com">Privacy Policy</a>
          </span>
        }
        id="formId"
        isDark={boolean("dark background", false)}
        defaultFailMessage={text(
          "Submit fail message",
          "An error occurred during submission."
        )}
        defaultSuccessMessage={text(
          "Submit success message",
          "Submission completed successfully."
        )}
        submitButtonText="Submit"
        allowFormReSubmit={boolean("Allow form re-submit", true)}
      >
        <VerticalSpacing>
          <FormsNewStyle.TextControl
            id="input1"
            labelText="TextControl"
            name="input1"
            required
            errorMessage="This field is required"
            defaultValue="Text control"
            type="text"
          />
        </VerticalSpacing>
        <VerticalSpacing>
          <FormsNewStyle.CheckboxControl
            defaultChecked
            id="checkedcheckbox"
            labelText="Checked Checkbox control"
            name="checkedcheckbox"
            value="checkedcheckbox"
          />
          <FormsNewStyle.CheckboxControl
            id="uncheckedcheckbox"
            labelText="Unchecked Checkbox control"
            name="uncheckedcheckbox"
            value="uncheckedcheckbox"
          />
        </VerticalSpacing>
        <VerticalSpacing>
          <FormsNewStyle.RadioControl
            name="radio"
            options={[
              { label: "RadioControl", value: "first" },
              { label: "With A Default Second", value: "second" },
              { label: "Option", value: "option" }
            ]}
            defaultValue="second"
          />
        </VerticalSpacing>
        <VerticalSpacing>
          <FormsNewStyle.SelectControl
            id="selectcontrol"
            labelText="Required SelectControl with default value"
            name="selectcontrol"
            options={[
              { value: "A", label: "Line item A" },
              { value: "B", label: "Line item B" },
              { value: "C", label: "Line item C" }
            ]}
            value="B"
            required
          />
        </VerticalSpacing>
        <VerticalSpacing>
          <FormsNewStyle.TextareaControl
            id="textarea"
            labelText="TextareaControl"
            name="textarea"
            defaultValue="Textarea control"
          />
        </VerticalSpacing>
        <VerticalSpacing>
          <FormsNewStyle.TextareaControl
            disabled
            id="textarea"
            labelText="Disabled TextareaControl"
            name="textarea"
          />
        </VerticalSpacing>

        <VerticalSpacing>
          <FormsNewStyle.ToggleSwitchControl
            id="toggleswitch"
            active={true}
            activeText="On"
            inActiveText="Off"
            name="toggleswitch"
          />
        </VerticalSpacing>
      </FormsNewStyle>
    </StaticRenderer>
  ))
  .add("Rehydrated CampaignControl", () => {
    return (
      <StaticRenderer>
        <div>
          <p>
            The CampaignControl is a hidden element. However, upon inspection,
            its value should match the selectedStory parameter in the URL of
            this page.
          </p>
          <FormsNewStyle action={Object.keys(actions)[0]}>
            <FormsNewStyle.CampaignControl
              name="campaign1"
              defaultValue="defaultValue"
              parameterToTrack="selectedStory"
              service={null} // This will get replaced on rehydrate, using the value passed to registerService in the bundle.
            />
          </FormsNewStyle>
        </div>
      </StaticRenderer>
    );
  })
  .add("Rehydrated TextControl", () => (
    <StaticRenderer>
      <FormsNewStyle
        action="https://jsonplaceholder.typicode.com/posts"
        isDark={boolean("dark background", false)}
        defaultFailMessage={text(
          "Submit fail message",
          "An error occurred during submission."
        )}
        defaultSuccessMessage={text(
          "Submit success message",
          "Submission completed successfully."
        )}
      >
        <VerticalSpacing>
          <FormsNewStyle.TextControl
            disabled={boolean("disabled text", false)}
            id="input1"
            labelText="TextControl"
            name="input1"
            required
            errorMessage="This field is required"
            defaultValue="Text control"
            helpText={text("Help Text", "This is a hint about this field")}
          />
        </VerticalSpacing>
      </FormsNewStyle>
    </StaticRenderer>
  ))
  .add("Rehydrated TextAreaControl", () => (
    <StaticRenderer>
      <FormsNewStyle
        action="https://jsonplaceholder.typicode.com/posts"
        isDark={boolean("dark background", false)}
        defaultFailMessage={text(
          "Submit fail message",
          "An error occurred during submission."
        )}
        defaultSuccessMessage={text(
          "Submit success message",
          "Submission completed successfully."
        )}
      >
        <VerticalSpacing>
          <FormsNewStyle.TextareaControl
            disabled={boolean("disabled", false)}
            id="textarea"
            labelText="TextareaControl"
            name="textarea"
            required={boolean("required", true)}
            helpText={text("Help Text", "This is a hint about this field")}
          />
        </VerticalSpacing>
      </FormsNewStyle>
    </StaticRenderer>
  ))
  .add("Rehydrated SelectControl", () => (
    <StaticRenderer>
      <FormsNewStyle
        action="https://jsonplaceholder.typicode.com/posts"
        isDark={boolean("dark background", false)}
        defaultFailMessage={text(
          "Submit fail message",
          "An error occurred during submission."
        )}
        defaultSuccessMessage={text(
          "Submit success message",
          "Submission completed successfully."
        )}
      >
        <FormsNewStyle.SelectControl
          disabled={boolean("disabled", false)}
          id={text("id", "select1")}
          labelText={text("labelText", "Label")}
          name={text("name", "select1")}
          options={[
            { value: "1", label: "Line item 1" },
            { value: "2", label: "Line item 2" },
            { value: "3", label: "Line item 3" },
            { value: "4", label: "Line item 4" },
            { value: "5", label: "Line item 5" },
            { value: "6", label: "Line item 6" },
            { value: "7", label: "Line item 7" }
          ]}
          placeholder={boolean("placeholder", true)}
          placeholderText={text("placeholderText", "Select…")}
          required={boolean("required", true)}
          errorMessage={text(
            "errorMessage",
            "Error Message: This field is required"
          )}
          helpText={text("Help Text", "This is a hint about this field")}
        />
      </FormsNewStyle>
    </StaticRenderer>
  ))
  .add("Rehydrated PrivacyControl", () => {
    return (
      <StaticRenderer>
        <FormsNewStyle
          action="https://jsonplaceholder.typicode.com/posts"
          isDark={boolean("dark background", false)}
          defaultFailMessage={text(
            "Submit fail message",
            "An error occurred during submission."
          )}
          defaultSuccessMessage={text(
            "Submit success message",
            "Submission completed successfully."
          )}
        >
          <VerticalSpacing>
            <FormsNewStyle.SelectControl
              id="countries"
              labelText={"Select your country"}
              name="countries"
              options={countries}
              required
            />
          </VerticalSpacing>
          <VerticalSpacing>
            <FormsNewStyle.PrivacyControl
              consentName={text("consentName", "consent")}
              customCompliantsLabel="Accepted channel (Aleast any one)"
              optionalLabel={text("OptionalLabel", "")}
              customCompliants={[
                {
                  country: "ZA",
                  channels: [
                    {
                      name: "phone",
                      displayname: "Phone Number"
                    },
                    {
                      name: "email",
                      displayname: "Email Address"
                    },
                    {
                      name: "whatsapp",
                      displayname: "Whatsapp"
                    },
                    {
                      name: "telegram",
                      displayname: "Telegram"
                    }
                  ],
                  text:
                    "I Confirm my signature and consent to receive communication about refinitiv resources, events, product or service. Please note you can manage and update your preference at any time"
                }
              ]}
              consentText={
                <span>
                  By submitting this form you are acknowledging that you have
                  read and agree to our{" "}
                  <a href="https://www.example.com">Marketing Policy</a> and
                  would therefore be fine receiving our newsletter time to time.
                </span>
              }
              compliantCountries={compliantCountries}
              dependsOn="countries"
              explicitConsentText="Custom I'd like to receive your newsletter"
            />
          </VerticalSpacing>
        </FormsNewStyle>
      </StaticRenderer>
    );
  });
