import React from "react";
import Cart from "./Cart";
import getClassNameFactory from "@emcm-ui/utility-class-names";

export default async (domNode, rehydrateChildren) => {
  const childrenNode = domNode.querySelector("[data-rehydratable-children]");
  const reactifiedChildren = await rehydrateChildren(childrenNode);

  const getClassName = getClassNameFactory("CartItem");

  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data ? JSON.parse(data) : null;
  };

  const supportNode = domNode.querySelector(
    `.${getClassName({ descendantName: "maxvalidation" })}`
  );

  const reactifiedSupport = await rehydrateChildren(supportNode);

  const props = {
    title: domNode.getAttribute("data-cart-title"),
    description: domNode.getAttribute("data-cart-description"),
    maxLicense: parseInt(domNode.getAttribute("data-max-license")),
    maxOnlineLicense: parseInt(domNode.getAttribute("data-max-online-license")),
    subTotalText: domNode.getAttribute("data-total-text"),
    taxInfo: domNode.getAttribute("data-tax-info"),
    footerText: domNode.getAttribute("data-footer-text"),
    showButton: JSON.parse(domNode.getAttribute("data-show-button")),
    buttonLink: domNode.getAttribute("data-button-link"),
    buttonText: domNode.getAttribute("data-button-text"),
    buttonBlock: JSON.parse(domNode.getAttribute("data-button-block")),
    productDetails: readData("product-details"),
    addonsDetails: readData("addons-details"),
    exchangesDetails: readData("exchanges-details"),
    productValidity: domNode.getAttribute("data-product-validity-text"),
    licenseText: domNode.getAttribute("data-license-text"),
    support: reactifiedSupport,
    subTotalInfo: domNode.getAttribute("data-sub-total-info"),
    showRemoveLink: readData("show-remove-link"),
    tooltipInfo: domNode.getAttribute("data-tooltip-info")
  };

  return <Cart {...props}>{reactifiedChildren}</Cart>;
};
