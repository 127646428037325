import PropTypes from "prop-types";
import React from "react";
import Button from "@emcm-ui/component-button";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import Link from "@emcm-ui/component-link";

import getClassNameFactory from "@emcm-ui/utility-class-names";

const ModalFooter = props => {
  const getClassName = getClassNameFactory(ModalFooter.displayName);

  const {
    primaryButtonColor,
    primaryButtonUrl,
    secondaryButtonLabel,
    secondaryButtonUrl,
    secondaryLinkLabel,
    secondaryLinkUrl,
    closeThisComponent,
    modalStyle
  } = props;

  const primaryButtonLabel =
    modalStyle === "dialogStyle"
      ? props.primaryButtonLabel || "Close" // For dialog style modal Primary button is mandatory
      : props.primaryButtonLabel;

  const hasFooterLinks =
    primaryButtonLabel || secondaryButtonLabel || secondaryLinkLabel;

  if (!hasFooterLinks) {
    return null;
  }

  const primaryButtonKind = primaryButtonUrl ? "link" : "button";
  const secondaryButtonKind = secondaryButtonUrl ? "link" : "button";

  return (
    <div className={getClassName()}>
      <div
        className={getClassName({
          descendantName: "buttonGroup"
        })}
      >
        {primaryButtonLabel && (
          <Button
            kind={primaryButtonKind}
            color={primaryButtonColor}
            href={primaryButtonUrl}
            {...!primaryButtonUrl && {
              onClick: closeThisComponent
            }}
          >
            {primaryButtonLabel}
          </Button>
        )}
        {secondaryButtonLabel && (
          <Button
            color="secondary"
            kind={secondaryButtonKind}
            href={secondaryButtonUrl}
            {...!secondaryButtonUrl && {
              onClick: closeThisComponent
            }}
          >
            {secondaryButtonLabel}
          </Button>
        )}
        {secondaryLinkLabel &&
          secondaryLinkUrl && (
            <Link
              href={secondaryLinkUrl}
              icon={
                <span
                  className={getClassName({
                    descendantName: "linkArrow"
                  })}
                >
                  <SVGIcon name="arrow" size="s" />
                </span>
              }
              iconPosition={"right"}
              newWindow={false}
              type={"icon"}
            >
              {secondaryLinkLabel}
            </Link>
          )}
      </div>
    </div>
  );
};

ModalFooter.displayName = "ModalFooter";

ModalFooter.propTypes = {
  /**
   * Primary button text
   */
  primaryButtonLabel: PropTypes.string,

  /**
   * Primary button color
   */
  primaryButtonColor: PropTypes.string,

  /**
   * Primary button target
   */
  primaryButtonUrl: PropTypes.string,

  /**
   * Secondary button text
   */
  secondaryButtonLabel: PropTypes.string,

  /**
   * Secondary button target
   */
  secondaryButtonUrl: PropTypes.string,

  /**
   * Secondary link label
   */
  secondaryLinkLabel: PropTypes.string,

  /**
   * Secondary link target
   */
  secondaryLinkUrl: PropTypes.string,

  /**
   * Callback to close the modal
   */
  closeThisComponent: PropTypes.func,

  /**
   * Check for new fly in modal
   */
  modalStyle: PropTypes.oneOf(["flyInStyle", "dialogStyle"])
};

ModalFooter.defaultProps = {
  primaryButtonColor: "primary"
};

export default ModalFooter;
