import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import SearchInput from "@emcm-ui/component-search-input";
import Dropdown from "@emcm-ui/component-dropdown";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

import Column from "./components/Column";
import FeaturedItem from "./components/FeaturedItem";
import Flyouts from "./components/Flyouts";
import Menu from "./components/Menu";
import NavList from "./components/NavList";
import NavItem from "./components/NavItem";
import Section from "./components/Section";
import SectionStack from "./components/SectionStack";

import { Provider as SetOpenMenuProvider } from "./setOpenMenuContext";
import { Provider as CurrentOpenMenuProvider } from "./currentOpenMenuContext";

const getFocusableDescendants = node => {
  /*
   * this is based on the code provided by:
   * https://www.w3.org/TR/wai-aria-practices-1.1/examples/dialog-modal/dialog.html
   * at `aria.Utils.isFocusable`
   */
  const selector = [
    "a:not([disabled])",
    "button:not([disabled])",
    "[href]",
    "input:not([disabled])",
    "select:not([disabled])",
    "textarea:not([disabled])",
    "[tabindex]:not([tabindex='-1'])"
  ].join(", ");

  return node.querySelectorAll(selector);
};

const itemsArrayShape = {
  href: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string.isRequired
};

class SiteHeader extends Component {
  static displayName = "SiteHeader";

  static propTypes = {
    /**
     * The SiteHeader contents. These should be `SiteHeader.Menu`s
     */
    children: PropTypes.node,

    /**
     * URL for the logo link
     */
    logoHref: PropTypes.string.isRequired,

    /**
     * Alternative text for the logo
     */
    logoText: PropTypes.string,

    /**
     * path to logo file
     */
    logoPath: PropTypes.string,

    /**
     * logo size options
     */
    logoSize: PropTypes.string,

    /**
     * Enable or disable search.
     */
    search: PropTypes.bool,

    /**
     * Search button aria-label
     */
    searchSubmitAriaLabel: PropTypes.string,

    /**
     * Search clear button aria-label
     */
    searchClearAriaLabel: PropTypes.string,

    /**
     * Search form action. If unspecified, will submit to the current page address.
     */
    searchFormAction: PropTypes.string,

    /**
     * Search form type
     */
    searchFormMethod: PropTypes.string,

    /**
     * Search input name
     */
    searchInputName: PropTypes.string,

    /**
     * Site name for the header.
     */
    siteName: PropTypes.string,

    /**
     * Search Typeahead URL. If unspecified, typeahead will be disabled.
     */
    typeaheadUrl: PropTypes.string,

    /**
     * Elastic Search
     */
    elasticKey: PropTypes.string,

    /**
     * autosuggestion
     */
    autosuggestion: PropTypes.string,

    /**
     * size
     */
    size: PropTypes.number,

    /**
     * minimum search character limit
     */
    searchMinLength: PropTypes.number,

    /**
     * placeholder text for the search input
     */
    placeholder: PropTypes.string,

    /**
     * Enable or disable language dropdown.
     */
    languageEnabled: PropTypes.bool,

    /**
     * Array of dropdown items. Items can contain value or href, not both.
     */
    languageItems: PropTypes.arrayOf(PropTypes.shape(itemsArrayShape))
      .isRequired,

    /**
     * Callback, called when value changes (unless set by property). Receives a `value` arguement.
     */
    languageOnValueChanged: PropTypes.func,

    /**
     * Value of the selected item. Will always override the existing value when set.
     */
    languageValue: PropTypes.any
  };

  static defaultProps = {
    logoText: "LSEG",
    logoSize: "horizontal",
    search: true,
    searchFormAction: null,
    searchFormMethod: "get",
    searchInputName: "q",
    typeaheadUrl: null,
    searchSubmitAriaLabel: "Search"
  };

  constructor(props) {
    super(props);

    this.state = {
      currentMenu: null,
      expanded: false,
      searching: false
    };

    this.bannerRef = React.createRef();
    this.logoRef = React.createRef();
    this.navRef = React.createRef();
    this.menusEl = React.createRef();
    this.searchRef = React.createRef();
    this.buttonsRef = React.createRef();

    this.setOpenMenu = this.setOpenMenu.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleSearchBlur = this.handleSearchBlur.bind(this);
    this.handleToggleClick = this.handleToggleClick.bind(this);
  }

  handleSearchBlur(e) {
    // document.activeElement is IE11 fallback
    const relatedTarget = e.relatedTarget || document.activeElement;

    // This prevents blur from triggering an additional setState in the event
    // that the search button is tapped on mobile.
    if (
      relatedTarget &&
      ((this.searchRef.current &&
        this.searchRef.current.contains(relatedTarget)) ||
        (this.buttonsRef.current &&
          this.buttonsRef.current.contains(relatedTarget)))
    ) {
      return;
    }

    this.setState({
      searching: false
    });
  }

  handleSearchClick() {
    this.setState({
      searching: !this.state.searching
    });
  }

  handleToggleClick() {
    const expanded = !this.state.expanded;

    window.scrollTo(0, 0);

    if (expanded) {
      document.body.classList.add("u-bodyModalOpenNarrowOnly");
      document.addEventListener("focus", this.handleFocus, true);

      this.lastFocusEl = this.bannerRef;
      this.bannerRef.current.focus();
    } else {
      document.body.classList.remove("u-bodyModalOpenNarrowOnly");
      document.removeEventListener("focus", this.handleFocus, true);
    }

    this.setState({
      expanded
    });
  }

  setOpenMenu(menuId) {
    if (this.menusEl.current) {
      // Scroll back to 0, so that panels are in view and
      // cover the available space and can't be over-scrolled.
      this.menusEl.current.scrollTop = 0;
    }

    this.setState({
      currentMenu: menuId
    });
  }

  handleFocus = event => {
    const isTargetWithinModal = this.navRef.current.contains(event.target);

    if (isTargetWithinModal) {
      this.lastFocusEl = event.target;
      this.lastFocusEl.focus();

      return;
    }

    const focusableDialogDescendants = getFocusableDescendants(
      this.navRef.current
    );

    if (!focusableDialogDescendants.length) {
      this.navRef.current.focus();

      return;
    }

    if (this.lastFocusEl === focusableDialogDescendants[0]) {
      focusableDialogDescendants[focusableDialogDescendants.length - 1].focus();
    } else {
      focusableDialogDescendants[0].focus();
    }

    this.lastFocusEl = document.activeElement;
  };

  render() {
    const getClassName = getClassNameFactory("SiteHeader");
    const { searching, currentMenu, expanded } = this.state;
    const {
      search,
      siteName,
      logoHref,
      logoPath,
      logoSize,
      logoText,
      typeaheadUrl,
      elasticKey,
      searchInputName,
      searchMinLength,
      autosuggestion,
      size,
      placeholder,
      languageEnabled,
      languageItems,
      languageValue,
      children,
      searchFormAction,
      searchFormMethod,
      languageOnValueChanged,
      searchSubmitAriaLabel,
      searchClearAriaLabel
    } = this.props;

    return (
      <SetOpenMenuProvider value={this.setOpenMenu}>
        <CurrentOpenMenuProvider value={currentMenu}>
          <div
            role="banner"
            className={getClassName({
              states: classNames({
                expanded,
                expandedMenu: currentMenu !== null,
                searching
              }),
              modifiers: classNames({
                noSearch: !search,
                withSiteName: Boolean(siteName)
              })
            })}
            data-logo-href={logoHref}
            data-logo-path={logoPath}
            data-logo-text={logoText}
            data-logo-size={logoSize}
            data-search={search}
            data-search-submit-label={searchSubmitAriaLabel}
            data-clear-search-label={searchClearAriaLabel}
            data-search-form-action={searchFormAction}
            data-search-form-method={searchFormMethod}
            data-search-input-name={searchInputName}
            data-rehydratable={getRehydratableName(SiteHeader.displayName)}
            data-typeahead-url={typeaheadUrl}
            data-elastic-key={elasticKey}
            data-search-min-length={searchMinLength}
            data-autosuggestion={autosuggestion}
            data-size={size}
            data-placeholder={placeholder}
            data-language-enabled={languageEnabled}
            data-language-items={JSON.stringify(languageItems)}
            data-language-value={languageValue}
            ref={this.bannerRef}
          >
            <nav
              className={getClassName({ descendantName: "inner" })}
              ref={this.navRef}
            >
              <a
                className={getClassName({ descendantName: "logo" })}
                href={logoHref}
                ref={this.logoRef}
              >
                {logoPath ? (
                  <div
                    className={getClassName({
                      descendantName: "logoContainer"
                    })}
                  >
                    <img
                      className={getClassName({
                        descendantName: "logoImg",
                        className: `${getClassName()}-${logoSize}`
                      })}
                      loading="lazy"
                      src={logoPath}
                      alt={logoText}
                    />
                  </div>
                ) : (
                  <div
                    className={getClassName({
                      descendantName: "logoInner",
                      className: `${getClassName()}-${logoSize}`
                    })}
                  >
                    <span
                      className={getClassName({
                        descendantName: "logoInnerLabel",
                        utilities: "hiddenVisually"
                      })}
                    >
                      {logoText}
                    </span>
                  </div>
                )}
              </a>

              {siteName ? (
                <span
                  className={getClassName({
                    descendantName: "siteName",
                    utilities: "typographySmallCaps"
                  })}
                >
                  {siteName}
                </span>
              ) : null}

              {!searching && (
                <ul
                  className={getClassName({ descendantName: "menus" })}
                  ref={this.menusEl}
                  role="menubar"
                >
                  {children}
                </ul>
              )}

              <div
                className={getClassName({ descendantName: "buttons" })}
                ref={this.buttonsRef}
              >
                {search ? (
                  <button
                    aria-label={this.props.searchSubmitAriaLabel}
                    className={getClassName({ descendantName: "searchButton" })}
                    onClick={this.handleSearchClick}
                  >
                    {searching ? (
                      <SVGIcon name="close" size="s" />
                    ) : (
                      <SVGIcon name="search" size="s" />
                    )}
                  </button>
                ) : null}
                <button
                  aria-label={expanded ? "Close Menu" : "Menu"}
                  className={getClassName({ descendantName: "toggleButton" })}
                  onClick={this.handleToggleClick}
                >
                  {expanded ? (
                    <SVGIcon name="close" size="s" />
                  ) : (
                    <SVGIcon name="menu" size="s" />
                  )}
                </button>
                {languageEnabled ? (
                  <Dropdown
                    block={false}
                    items={languageItems}
                    lang={true}
                    placeholder="EN"
                    size="small"
                    value={languageValue}
                    onValueChanged={languageOnValueChanged}
                  />
                ) : null}
              </div>

              {searching && (
                <div
                  className={getClassName({ descendantName: "search" })}
                  ref={this.searchRef}
                >
                  <form action={searchFormAction} type="get" role="search">
                    <SearchInput
                      // When the user clicks the search button, and the search
                      // input appears, the expectation is that the input will be in
                      // focus.
                      autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                      name={searchInputName}
                      typeahead={Boolean(typeaheadUrl)}
                      typeaheadUrl={typeaheadUrl}
                      onBlur={this.handleSearchBlur}
                      elasticKey={this.props.elasticKey}
                      searchMinLength={this.props.searchMinLength}
                      autosuggestion={this.props.autosuggestion}
                      size={this.props.size}
                      placeholder={this.props.placeholder}
                      searchClearAriaLabel={this.props.searchClearAriaLabel}
                    />
                  </form>
                </div>
              )}
            </nav>
          </div>
        </CurrentOpenMenuProvider>
      </SetOpenMenuProvider>
    );
  }
}

SiteHeader.Column = Column;
SiteHeader.FeaturedItem = FeaturedItem;
SiteHeader.Flyouts = Flyouts;
SiteHeader.Menu = Menu;
SiteHeader.NavList = NavList;
SiteHeader.NavItem = NavItem;
SiteHeader.Section = Section;
SiteHeader.SectionStack = SectionStack;

export default SiteHeader;
