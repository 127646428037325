import React from "react";
import { storiesOf } from "@storybook/react";
import {
  withKnobs,
  text,
  number,
  select,
  boolean
} from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import CardFooter from "./index";
import SearchResultsMixtureREADME from ".../../../README.md";
import {
  CARD_ARTICLES,
  CARD_RESEARCH,
  CARD_DATA_VISUALIZATION,
  CARD_VIDEO,
  CARD_EVENT,
  ICON
} from "../../utilities/constants";
const stories = storiesOf(
  "Components/SearchMixedCard/Components/CardFooter",
  module
);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(SearchResultsMixtureREADME));

const pageTypes = [
  "Default",
  CARD_ARTICLES,
  CARD_RESEARCH,
  CARD_DATA_VISUALIZATION,
  CARD_VIDEO,
  CARD_EVENT
];
const publishedDate = "2023-08-14T00:00:00.000Z";

stories
  .add("Default", () => {
    const pageType = select("Page type", pageTypes, pageTypes[0]);

    return (
      <CardFooter
        pageType={pageType}
        publishedDate={text("Published date", publishedDate)}
        icon={ICON}
        {...(pageType === "Default" || pageType === CARD_ARTICLES) && {
          author: text("Author", "Author Name")
        }}
        {...pageType !== CARD_RESEARCH &&
          pageType !== CARD_DATA_VISUALIZATION && {
            includeReadTime: boolean("Include read time", true)
          }}
        {...(pageType === "Default" ||
          pageType === CARD_ARTICLES ||
          pageType === CARD_VIDEO) && {
          readTime: number("Read time", "5"),
          minLabel: text("Min label", "min")
        }}
        {...pageType === CARD_EVENT && {
          eventInfoTime: text("Event info time", "17:33 - 18:50 GMT")
        }}
      />
    );
  })
  .add("Language: Arabic (RTL)", () => {
    const pageType = select("Page type", pageTypes, pageTypes[0]);

    return (
      <Language code="ar">
        <CardFooter
          pageType={pageType}
          publishedDate={text("Published date", publishedDate)}
          icon={ICON}
          {...(pageType === "Default" || pageType === CARD_ARTICLES) && {
            author: text("Author", "اسم المؤلف")
          }}
          {...pageType !== CARD_RESEARCH &&
            pageType !== CARD_DATA_VISUALIZATION && {
              includeReadTime: boolean("Include read time", true)
            }}
          {...(pageType === "Default" ||
            pageType === CARD_ARTICLES ||
            pageType === CARD_VIDEO) && {
            readTime: number("Read time", "5"),
            minLabel: text("Min label", "min")
          }}
          {...pageType === CARD_EVENT && {
            eventInfoTime: text("Event info time", "17:33 - 18:50 GMT")
          }}
        />
      </Language>
    );
  });
