import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Pagination from "@emcm-ui/component-pagination";

const SearchPagination = ({ page }) => {
  const getClassName = getClassNameFactory("SearchResultsMixture");
  const { pagination, onPageChanged } = page;

  if (!pagination) {
    return false;
  }

  const getPagination = () => {
    return (
      <Pagination
        borderlessAtStandard={false}
        initialPage={pagination.thisPage.pageNumber}
        onPageChanged={onPageChanged}
        pageCount={pagination.last.pageNumber}
        pageRangeCount={5}
      />
    );
  };

  return (
    <div className={getClassName({ descendantName: "pagination" })}>
      {getPagination()}
    </div>
  );
};

SearchPagination.displayName = "SearchResults.Pagination";

SearchPagination.propTypes = {
  /**
   * page information
   */
  page: PropTypes.object
};

export default SearchPagination;
