import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const RichText = ({ children, dropcap }) => {
  const getClassName = getClassNameFactory("LongformRichText");

  return (
    <div
      className={getClassName({
        modifiers: classNames(dropcap ? "dropcap" : null),
        className: typestack("subline2")
      })}
    >
      {children}
    </div>
  );
};

RichText.propTypes = {
  /**
   * Child nodes passed to the component.
   */
  children: PropTypes.node,
  /**
   * Make the first letter of the first <p> larger, and drop it down.
   */
  dropcap: PropTypes.bool
};

RichText.defaultProps = { dropcap: false };

RichText.displayName = "Longform.RichText";

export default RichText;
