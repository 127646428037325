import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const GlobalFooterItem = ({ href, title }) => {
  const getClassName = getClassNameFactory(GlobalFooterItem.displayName);

  return (
    <li className={getClassName()}>
      <a
        className={getClassName({
          descendantName: "inner",
          className: typestack("p1")
        })}
        href={href}
      >
        {title}
      </a>
    </li>
  );
};

GlobalFooterItem.displayName = "GlobalFooterItem";

/* eslint-disable max-len */
GlobalFooterItem.propTypes = {
  /**
   * URL.
   */
  href: PropTypes.string.isRequired,
  /**
   * Title of the item.
   */
  title: PropTypes.string.isRequired
};
/* eslint-enable max-len */

export default GlobalFooterItem;
