import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import FormsNewStyle from "@emcm-ui/component-forms-new-style";
import Grid from "@emcm-ui/component-grid";
import moment from "moment";

import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

const ASC = "ASC";
const DESC = "DESC";
const maxYearLength = 12;

const generateRange = (start, end) => {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => (start + idx).toString());
};

const getDynamicRange = range => {
  const upper = Number(moment().year());

  const lower = Number(
    moment()
      .subtract(range, "years")
      .format("YYYY")
  );

  return { upper, lower };
};

class DateSelect extends Component {
  constructor(props) {
    super(props);

    let years = [];
    const yearOrder = props.yearOrder || ASC;
    const yearRange = props.numberOfYears
      ? getDynamicRange(props.numberOfYears - 1)
      : props.yearRange;

    if (yearRange) {
      years = generateRange(yearRange.lower, yearRange.upper);
    }

    this.state = {
      days: null,
      years: yearOrder === DESC ? years.reverse() : years,
      current: {
        day: "",
        month: "",
        year: ""
      },
      output: null
    };
  }

  static displayName = "DateSelect";
  static propTypes = {
    onDateChanged: PropTypes.func,
    yearRange: PropTypes.shape({
      upper: PropTypes.number,
      lower: PropTypes.number
    }),
    yearOrder: PropTypes.oneOf([ASC, DESC]),
    dateFormatter: PropTypes.string,
    numberOfYears: PropTypes.number,
    variant: PropTypes.oneOf(["default", "monthsAndYears"]).isRequired,
    /**
     * Error message for day dropdown
     */
    dayErrorMessage: PropTypes.string,
    /**
     * Error message for month dropdown
     */
    monthErrorMessage: PropTypes.string,
    /**
     * Error message for year dropdown
     */
    yearErrorMessage: PropTypes.string
  };
  static defaultProps = {
    dateFormatter: "DD MM YYYY",
    numberOfYears: null
  };

  handleDateChanged = ({ current }) => {
    let output = null;
    let userSelected = null;
    const { day, month, year } = current;

    const { variant } = this.props;

    const isVariant = (variant === "default" && day) || variant !== "default";

    if (isVariant && month && year) {
      output = `${day || "1"} ${month} ${year}`;
    }

    userSelected = output
      ? moment(output, "DD, M, YYYY").format(this.props.dateFormatter)
      : output;

    this.setState({ output: userSelected });
    if (this.props.onDateChanged) {
      this.props.onDateChanged(userSelected);
    }
  };

  getYears = () => {
    return this.state.years.map(year => {
      return {
        value: year,
        label: year
      };
    });
  };

  setYear = selectedYear => {
    const { current } = this.state;

    const updatedYear = {
      current: {
        ...current,
        day:
          moment(`${current.year}-${selectedYear}`, "YYYY-M").daysInMonth() <
          parseInt(current.day)
            ? null
            : current.day,
        year: selectedYear
      }
    };

    this.setState(updatedYear);
    this.handleDateChanged(updatedYear);
  };

  getMonths = () => {
    const arr = new Array(maxYearLength).fill("", 0, maxYearLength);

    return arr.map((val, i) => {
      return {
        value: `${i + 1}`,
        label: moment(`${i + 1}`, "M").format("MMMM")
      };
    });
  };

  setMonth = selectedMonth => {
    const { current } = this.state;

    const updatedMonth = {
      current: {
        ...current,
        day:
          moment(`${current.year}-${selectedMonth}`, "YYYY-M").daysInMonth() <
          parseInt(current.day)
            ? null
            : current.day,
        month: selectedMonth
      }
    };

    this.setState(updatedMonth);
    this.handleDateChanged(updatedMonth);
  };

  getDays = () => {
    const currMonth = this.state.current.month || "1";
    const currYear = this.state.current.year || "2022";
    const daysInMonth = moment(
      `${currYear}-${currMonth}`,
      "YYYY-M"
    ).daysInMonth();
    const arr = new Array(daysInMonth).fill("", 0, daysInMonth);

    return arr.map((day, i) => {
      return {
        value: `${i + 1}`,
        label: `${i + 1}`
      };
    });
  };

  setDay = selectedDay => {
    const updatedDay = {
      current: {
        ...this.state.current,
        day: selectedDay
      }
    };

    this.setState(updatedDay);
    this.handleDateChanged(updatedDay);
  };

  getClassName = getClassNameFactory(DateSelect.displayName);

  render() {
    const {
      variant,
      yearRange,
      yearOrder,
      dateFormatter,
      numberOfYears,
      dayErrorMessage,
      monthErrorMessage,
      yearErrorMessage
    } = this.props;
    const gridVariant = `even-${variant === "default" ? "3" : "2"}`;

    return (
      <div
        className={this.getClassName()}
        data-rehydratable={getRehydratableName(DateSelect.displayName)}
        data-variant={JSON.stringify(variant)}
        data-year-range={JSON.stringify(yearRange)}
        data-year-order={yearOrder}
        data-format={dateFormatter}
        data-number-of-years={numberOfYears}
        data-day-error-message={dayErrorMessage}
        data-month-error-message={monthErrorMessage}
        data-year-error-message={yearErrorMessage}
      >
        <Grid variant={gridVariant}>
          {variant !== "monthsAndYears" && (
            <Grid.Item>
              <FormsNewStyle.SelectControl
                id="day"
                onChange={e => this.setDay(e.target.value)}
                name="day"
                labelText={"Day"}
                required
                options={this.getDays()}
                value={this.state.current.day}
                errorMessage={dayErrorMessage}
              />
            </Grid.Item>
          )}
          <Grid.Item>
            <FormsNewStyle.SelectControl
              id="month"
              onChange={e => this.setMonth(e.target.value)}
              name="month"
              labelText={"Month"}
              required
              options={this.getMonths()}
              value={this.state.current.month}
              errorMessage={monthErrorMessage}
            />
          </Grid.Item>
          <Grid.Item>
            <FormsNewStyle.SelectControl
              id="year"
              onChange={e => this.setYear(e.target.value)}
              name="year"
              labelText={"Year"}
              required
              options={this.getYears()}
              value={this.state.current.year}
              errorMessage={yearErrorMessage}
            />
          </Grid.Item>
        </Grid>
        <input type="hidden" name="output" value={this.state.output || ""} />
      </div>
    );
  }
}

export default DateSelect;
