import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

const BlockQuote = ({ attribution, cite, quotemark, small, text }) => {
  const getClassName = getClassNameFactory("LongformBlockQuote");

  return (
    <div
      className={getClassName({ modifiers: classNames({ quotemark, small }) })}
    >
      <div className={getClassName({ descendantName: "inner" })}>
        <blockquote
          className={getClassName({
            descendantName: "quote",
            className: typestack("quoteSmall")
          })}
          cite={cite}
        >
          {text}
        </blockquote>
      </div>

      {attribution ? (
        <div className={getClassName({ descendantName: "attribution" })}>
          {attribution}
        </div>
      ) : null}
    </div>
  );
};

BlockQuote.propTypes = {
  /**
   * Attribution component for attributing the quotation
   */
  attribution: PropTypes.node,
  /**
   * URL that designates source document or message for the information quoted.
   */
  cite: PropTypes.string,
  /**
   * Show the decorative quotemark
   */
  quotemark: PropTypes.bool.isRequired,
  /**
   * Optional small variant
   */
  small: PropTypes.bool,
  /**
   * The quotation
   */
  text: PropTypes.string.isRequired
};

BlockQuote.defaultProps = {
  quotemark: true,
  small: false
};

BlockQuote.displayName = "Longform.BlockQuote";

export default BlockQuote;
