import React from "react";
import MarkupContainer from "@emcm-ui/component-markup-container";
import SiteFooter from "@emcm-ui/component-site-footer";

const SampleSiteFooter = () => (
  <MarkupContainer>
    <SiteFooter
      logoHref="#"
      searchFormAction="/"
      searchFormMethod="get"
      typeaheadUrl="//content.atomz.com/autocomplete/sp10/05/0d/51?_=1506335725065"
    >
      <SiteFooter.NavList title="About Thomson Reuters">
        <SiteFooter.NavItem title="About us" href="#" />
        <SiteFooter.NavItem title="Investor relations" href="#" />
        <SiteFooter.NavItem title="Newsroom" href="#" />
        <SiteFooter.NavItem title="Press release" href="#" />
        <SiteFooter.NavItem title="Innovation" href="#" />
        <SiteFooter.NavItem title="Global site directory" href="#" />
      </SiteFooter.NavList>
      <SiteFooter.NavList title="Products & Services">
        <SiteFooter.NavItem title="All products" href="#" />
        <SiteFooter.NavItem title="Financial" href="#" />
        <SiteFooter.NavItem title="Government" href="#" />
        <SiteFooter.NavItem title="Legal" href="#" />
        <SiteFooter.NavItem title="Reuters news agency" href="#" />
        <SiteFooter.NavItem title="Risk management" href="#" />
        <SiteFooter.NavItem title="Tax & accounting" href="#" />
      </SiteFooter.NavList>
      <SiteFooter.NavList title="Resources">
        <SiteFooter.NavItem title="Careers" href="#" />
        <SiteFooter.NavItem title="Answers On" href="#" />
        <SiteFooter.NavItem title="Product logins" href="#" />
        <SiteFooter.NavItem title="Mobile apps" href="#" />
        <SiteFooter.NavItem title="Know 360 publication" href="#" />
      </SiteFooter.NavList>
      <SiteFooter.NavList title="Contact Us">
        <SiteFooter.NavItem title="Support & Training" href="#" />
        <SiteFooter.NavItem title="Office locations" href="#" />
        <SiteFooter.NavItem title="Media relations" href="#" />
        <SiteFooter.NavItem title="sales" href="#" />
      </SiteFooter.NavList>
      <SiteFooter.NavList title="Connect">
        <SiteFooter.NavItem title="Support & Training" href="#" />
        <SiteFooter.NavItem title="Office locations" href="#" />
        <SiteFooter.NavItem title="Media relations" href="#" />
        <SiteFooter.NavItem title="sales" href="#" />
      </SiteFooter.NavList>
    </SiteFooter>
  </MarkupContainer>
);

export default SampleSiteFooter;
