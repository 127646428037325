import PropTypes from "prop-types";
import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text, boolean, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import SearchResultsMixture from "./index";
import "./SearchResultsMixture.css";
import SearchResultsMixtureREADME from "../README.md";
import Poster from "@emcm-ui/component-poster";
import SearchInput from "@emcm-ui/component-search-input";

const stories = storiesOf("Components/SearchMixedCard/MixedCard", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(SearchResultsMixtureREADME));

const searchResultMock =
  "https://run.mocky.io/v3/eb56e254-3f9a-45b6-b1ee-f35007cee66e";
const searchResultMockWithFilterSelected =
  "https://run.mocky.io/v3/6b67c398-ba77-4b7c-ab6a-f5e91dcafa74";
const featuredMock =
  "https://run.mocky.io/v3/5f14d4b9-37d0-4f43-82cd-5543c97943e6?x1=w_featured&q1=yes";
const noResultMock =
  "https://run.mocky.io/v3/421a3e1a-9559-4218-864b-79738a110879";
const searchResultMockHashChange =
  "https://run.mocky.io/v3/a9b4b714-46c7-4be8-8986-bcab24ad4252";
const featuredMockHashChange =
  "https://run.mocky.io/v3/09a25014-d103-430b-9483-de15547cd164?x1=w_featured&q1=yes";

const filters = [
  {
    link: "?sort=relevance&x1=w_themes_id&q1=theme:funds-and-etfs",
    name: "theme:funds-and-etfs",
    title: "Funds and ETFs"
  },
  {
    link: "?sort=relevance&x1=w_themes_id&q1=theme:innovation-and-trends",
    name: "theme:innovation-and-trends",
    title: "Innovation and Trends"
  }
];
const icon = {
  clock: "./icons/ui/grey/time-07.svg",
  arrow: "./icons/ui/white/arrow-right.svg",
  video: "./icons/ui/grey/playoutline-07.svg"
};
const filtersOption = ["All", "Funds and ETFs", "Innovation and Trends"];

class DemoWrapper extends React.Component {
  static propTypes = {
    /**
     * Label for expand more button
     */
    showMore: PropTypes.string,
    /**
     * Label for expended button
     */
    showLess: PropTypes.string,
    /**
     * Label for filter heading
     */
    tagHeading: PropTypes.string,
    /**
     * clear all text
     */
    clearAllText: PropTypes.string,
    /**
     * Label for featured text
     */
    featuredText: PropTypes.string,
    /**
     * check for featured content
     */
    featuredContent: PropTypes.bool,
    /**
     * value for search result sort by
     */
    sortBy: PropTypes.string,
    /**
     * label for all text
     */
    allText: PropTypes.string,
    /**
     * Boolean value to display description of card
     */
    includeDescription: PropTypes.bool,
    /**
     * Boolean value to display read time in card footer section
     */
    includeReadTime: PropTypes.bool,
    /**
     * Text for read article
     */
    readArticle: PropTypes.string,
    /**
     * Text for view result text
     */
    viewText: PropTypes.string,
    /**
     * Text for no result found text
     */
    noMatchesText: PropTypes.string,
    /**
     * value for 'min' text
     */
    minLabel: PropTypes.string,
    /**
     * selected filter
     */
    filterSelect: PropTypes.string
  };
  constructor(props) {
    super(props);

    this.state = {
      endpoint: searchResultMock,
      featuredContentUrl: featuredMock
    };
  }

  static getDerivedStateFromProps(props) {
    const { filterSelect } = props;

    if (filterSelect !== filtersOption[0]) {
      const theme = filters.filter(item => item.title === filterSelect)[0].name;

      window.location.hash = `x1=w_themes_id&q1=${theme}`;

      return {
        endpoint: searchResultMockHashChange,
        featuredContentUrl: featuredMockHashChange
      };
    }

    window.location.hash = "";

    return {
      endpoint: searchResultMock,
      featuredContentUrl: featuredMock
    };
  }

  render() {
    const {
      clearAllText,
      featuredContent,
      featuredText,
      tagHeading,
      noMatchesText,
      showLess,
      showMore,
      viewText,
      allText,
      minLabel,
      sortBy,
      includeDescription,
      includeReadTime,
      readArticle
    } = this.props;
    const { endpoint, featuredContentUrl } = this.state;

    return (
      <SearchResultsMixture
        clearAllText={clearAllText}
        endpoint={endpoint}
        featuredContent={featuredContent}
        featuredContentUrl={featuredContentUrl}
        featuredText={featuredText}
        tagHeading={tagHeading}
        noMatchesText={noMatchesText}
        showLess={showLess}
        showMore={showMore}
        viewText={viewText}
        allText={allText}
        minLabel={minLabel}
        sortBy={sortBy}
        includeDescription={includeDescription}
        includeReadTime={includeReadTime}
        topFilters={filters}
        icon={icon}
        readArticle={readArticle}
      />
    );
  }
}

class DemoSearchTerm extends React.Component {
  static propTypes = {
    /**
     * children node
     */
    children: PropTypes.node
  };
  searchInputSubmit = e => {
    const urlParam = new URLSearchParams(window.location.search);

    urlParam.set("q", e);
    window.location.search = urlParam.toString();
  };

  componentWillUnmount() {
    const urlParam = new URLSearchParams(window.location.search);

    urlParam.delete("q");
    window.location.search = urlParam.toString();
  }

  render() {
    const { children } = this.props;

    return (
      <div>
        <Poster imageSrc={"test-images/16-9/large_9.jpg"}>
          <SearchInput onSubmit={this.searchInputSubmit} />
        </Poster>
        {children}
      </div>
    );
  }
}

stories
  .add("Default", () => (
    <DemoWrapper
      clearAllText={text("Clear all text", "Clear all")}
      endpoint={text("search end point", searchResultMock)}
      featuredContent={true}
      featuredContentUrl={text("featured content end point", featuredMock)}
      featuredText={text("Featured text", "Featured")}
      tagHeading={text("Heading Text", "Related topics")}
      noMatchesText={text(
        "No match text",
        "We did not find any matches for {term}"
      )}
      showLess={text("Show less text", "Show less")}
      showMore={text("Show more text", "Show more")}
      viewText={text(
        "View text",
        "Viewing {start}-{end} of {total} for {term}"
      )}
      allText={text("all text", "All")}
      minLabel={text("Text for min", "min")}
      sortBy={text("Sort by", "relevance")}
      includeDescription={boolean("Include Description", true)}
      includeReadTime={boolean("Include readtime", true)}
      topFilters={filters}
      icon={icon}
      readArticle={text("read article text", "Read article")}
      filterSelect={select(
        "Deeplinking function",
        filtersOption,
        filtersOption[0]
      )}
    />
  ))
  .add("With filter selected", () => (
    <SearchResultsMixture
      clearAllText={text("Clear all text", "Clear all")}
      endpoint={text("search end point", searchResultMockWithFilterSelected)}
      featuredContent={true}
      featuredContentUrl={text("featured content end point", featuredMock)}
      featuredText={text("Featured text", "Featured")}
      tagHeading={text("Heading Text", "Related topics")}
      noMatchesText={text(
        "No match text",
        "We did not find any matches for {term}"
      )}
      showLess={text("Show less text", "Show less")}
      showMore={text("Show more text", "Show more")}
      viewText={text(
        "View text",
        "Viewing {start}-{end} of {total} for {term}"
      )}
      allText={text("all text", "All")}
      minLabel={text("Text for min", "min")}
      sortBy={text("Sort by", "relevance")}
      includeDescription={boolean("Include Description", true)}
      includeReadTime={boolean("Include readtime", true)}
      topFilters={filters}
      icon={icon}
      readArticle={text("read article text", "Read article")}
    />
  ))
  .add("no results", () => (
    <SearchResultsMixture
      clearAllText={text("Clear all text", "Clear all")}
      endpoint={text("search end point", noResultMock)}
      featuredContent={false}
      featuredContentUrl={text("featured content end point", noResultMock)}
      featuredText={text("Featured text", "Featured")}
      tagHeading={text("Heading Text", "Related topics")}
      noMatchesText={text(
        "No match text",
        "We did not find any matches for {term}"
      )}
      showLess={text("Show less text", "Show less")}
      showMore={text("Show more text", "Show more")}
      viewText={text(
        "View text",
        "Viewing {start}-{end} of {total} for {term}"
      )}
      allText={text("all text", "All")}
      minLabel={text("Text for min", "min")}
      sortBy={text("Sort by", "relevance")}
      includeDescription={boolean("Include Description", true)}
      includeReadTime={boolean("Include readtime", true)}
      topFilters={filters}
      icon={icon}
      readArticle={text("read article text", "Read article")}
    />
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <DemoWrapper
        clearAllText={text("Clear all text", "امسح الكل")}
        endpoint={text("search end point", searchResultMock)}
        featuredContent={true}
        featuredContentUrl={text("featured content end point", featuredMock)}
        featuredText={text("Featured text", "متميز")}
        tagHeading={text("Heading Text", "مواضيع ذات صلة")}
        noMatchesText={text(
          "No match text",
          "لم نعثر على أية مطابقات لـ {term}"
        )}
        showLess={text("Show less text", "عرض أقل")}
        showMore={text("Show more text", "أظهر المزيد")}
        viewText={text(
          "View text",
          "يتم الآن عرض {start} - {end} من {total} لـ {term}"
        )}
        allText={text("all text", "الجميع")}
        minLabel={text("Text for min", "دقيقة")}
        sortBy={text("Sort by", "relevance")}
        includeDescription={boolean("Include Description", true)}
        includeReadTime={boolean("Include readtime", true)}
        topFilters={filters}
        icon={icon}
        readArticle={text("read article text", "اقرأ المقال")}
        filterSelect={select("Filter select", filtersOption, filtersOption[0])}
      />
    </Language>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <DemoWrapper
        clearAllText={text("Clear all text", "Clear all")}
        endpoint={text("search end point", searchResultMock)}
        featuredContent={true}
        featuredContentUrl={text("featured content end point", featuredMock)}
        featuredText={text("Featured text", "Featured")}
        tagHeading={text("Heading Text", "Related topics")}
        noMatchesText={text(
          "No match text",
          "We did not find any matches for {term}"
        )}
        showLess={text("Show less text", "Show less")}
        showMore={text("Show more text", "Show more")}
        viewText={text(
          "View text",
          "Viewing {start}-{end} of {total} for {term}"
        )}
        allText={text("all text", "All")}
        minLabel={text("Text for min", "min")}
        includeDescription={boolean("Include Description", true)}
        includeReadTime={boolean("Include readtime", true)}
        topFilters={filters}
        icon={icon}
        readArticle={text("read article text", "Read article")}
        filterSelect={select("Filter select", filtersOption, filtersOption[0])}
      />
    </StaticRenderer>
  ))
  .add("with search input", () => (
    <DemoSearchTerm>
      <SearchResultsMixture
        clearAllText={text("Clear all text", "Clear all")}
        endpoint={text("search end point", searchResultMock)}
        featuredContent={true}
        featuredContentUrl={text("featured content end point", featuredMock)}
        featuredText={text("Featured text", "Featured")}
        tagHeading={text("Heading Text", "Related topics")}
        noMatchesText={text(
          "No match text",
          "We did not find any matches for {term}"
        )}
        showLess={text("Show less text", "Show less")}
        showMore={text("Show more text", "Show more")}
        viewText={text(
          "View text",
          "Viewing {start}-{end} of {total} for {term}"
        )}
        allText={text("all text", "All")}
        minLabel={text("Text for min", "min")}
        sortBy={text("Sort by", "relevance")}
        includeDescription={boolean("Include Description", true)}
        includeReadTime={boolean("Include readtime", true)}
        topFilters={filters}
        icon={icon}
        readArticle={text("read article text", "Read article")}
      />
    </DemoSearchTerm>
  ))
  .add("Rehydrated: with search input", () => (
    <DemoSearchTerm>
      <StaticRenderer>
        <SearchResultsMixture
          clearAllText={text("Clear all text", "Clear all")}
          endpoint={text("search end point", searchResultMock)}
          featuredContent={true}
          featuredContentUrl={text("featured content end point", featuredMock)}
          featuredText={text("Featured text", "Featured")}
          tagHeading={text("Heading Text", "Related topics")}
          noMatchesText={text(
            "No match text",
            "We did not find any matches for {term}"
          )}
          showLess={text("Show less text", "Show less")}
          showMore={text("Show more text", "Show more")}
          viewText={text(
            "View text",
            "Viewing {start}-{end} of {total} for {term}"
          )}
          allText={text("all text", "All")}
          minLabel={text("Text for min", "min")}
          includeDescription={boolean("Include Description", true)}
          includeReadTime={boolean("Include readtime", true)}
          topFilters={filters}
          icon={icon}
          readArticle={text("read article text", "Read article")}
        />
      </StaticRenderer>
    </DemoSearchTerm>
  ));
