import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, number } from "@storybook/addon-knobs";
import { action } from "@storybook/addon-actions";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import Pagination from "./index";
import "./Pagination.css";
import SearchResultsMixtureREADME from ".../../../README.md";

const stories = storiesOf(
  "Components/SearchMixedCard/Components/Pagination",
  module
);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(SearchResultsMixtureREADME));

const lastPage = 10;

stories
  .add("Default", () => (
    <Pagination
      page={{
        pagination: {
          thisPage: {
            pageNumber: number("Current Page", 1)
          },
          last: {
            pageNumber: number("Last Page", lastPage)
          }
        },
        onPageChanged: action("Pagination clicked")
      }}
    />
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Pagination
        page={{
          pagination: {
            thisPage: {
              pageNumber: number("Current Page", 1)
            },
            last: {
              pageNumber: number("Last Page", lastPage)
            }
          },
          onPageChanged: action("Pagination clicked")
        }}
      />
    </Language>
  ));
