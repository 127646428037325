import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, boolean } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import PageHeadingWrapper from "./index";
import "./PageHeadingWrapper.css";
import PageHeadingWrapperREADME from "../README.md";

const stories = storiesOf("Components/PageHeadingWrapper", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(PageHeadingWrapperREADME));

stories
  .add("Default", () => (
    <PageHeadingWrapper
      lsegBlue={boolean("LSEG Blue", false)}
      compact={boolean("compact", false)}
      description="The heading and description are wrapped in a single h1, heading one, h-one, page heading, page header, page title, page rank, google label"
      heading={
        <span>
          SEO <strong>power</strong>
        </span>
      }
      large={boolean("large", false)}
    />
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <PageHeadingWrapper
        lsegBlue={boolean("LSEG Blue", false)}
        compact={boolean("compact", false)}
        description="يتم تغليف العنوان والوصف في h1 واحد ، العنوان واحد ، h-one ، عنوان الصفحة ، رأس الصفحة ، عنوان الصفحة ، تصنيف الصفحة ، google label"
        heading={
          <span>
            SEO <strong>مثال</strong>
          </span>
        }
        large={boolean("large", false)}
      />
    </Language>
  ));
