import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import Attribution from "@emcm-ui/component-attribution";
import { Language } from "@emcm-ui/component-theme";
import QuoteCard from "./index";
import "./QuoteCard.css";

import CardREADME from "../../../../README.md";

const stories = storiesOf("Components/Card/Cards/QuoteCard", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(CardREADME));

stories
  .add("Default", () => (
    <div style={{ maxWidth: 360 }}>
      <QuoteCard
        attribution={
          <Attribution
            imgSrc="headshot.jpg"
            name={text("name", "Casey S.")}
            caption={text("caption", "Senior writer")}
          />
        }
        borderVariant={select("borderVariant", QuoteCard.borderVariant, null)}
        href={text("href", "https://example.com")}
        eyebrow={text("eyebrow", "Eyebrow")}
        text={text(
          "text",
          "Quote, Lorem ipsum dolor sit amet, consectetur elit proin tincidunt iaculis metus in porta."
        )}
      />
    </div>
  ))
  .add("Customer", () => (
    <div style={{ maxWidth: 360 }}>
      <QuoteCard
        attribution={
          <Attribution
            imgSrc="headshot.jpg"
            name={text("name", "Casey S.")}
            caption={text("caption", "Senior writer")}
          />
        }
        borderVariant={select("borderVariant", QuoteCard.borderVariant, null)}
        href={text("href", "https://example.com")}
        text={text(
          "text",
          "Quote, Lorem ipsum dolor sit amet, consectetur elit proin tincidunt iaculis metus in porta."
        )}
        customer
      />
    </div>
  ))
  .add("Language: Arabic (RTL)", () => (
    <div style={{ maxWidth: 360 }}>
      <Language code="ar">
        <QuoteCard
          attribution={
            <Attribution
              imgSrc="headshot.jpg"
              name="Casey S."
              caption="كبير الكاتب"
            />
          }
          borderVariant={select("borderVariant", QuoteCard.borderVariant, null)}
          href={text("href", "https://example.com")}
          eyebrow={text("eyebrow", "حاجب العين")}
          text={text(
            "text",
            "إيو الشرقية التبرعات تم. من الإطلاق ولكسمبورغ وتم. بعد مع شدّت لعدم لفشل, و كان انتصارهم المتّبعة. تم لمّ معقل وحتى بريطانيا, جعل أن أحكم ومحاولة باستحداث. و ضمنها الأول العالمي عرض, لفشل بخطوط واشتدّت نفس قد. قتيل، الأراضي أخر من, أخر هو مقاومة الرئيسية."
          )}
        />
      </Language>
    </div>
  ));
