import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";

import getClassNameFactory from "@emcm-ui/utility-class-names";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

class PlayPauseControl extends Component {
  static displayName = "PlayPauseControl";

  static propTypes = {
    /**
     * Defines the theme of the component
     */
    backgroundType: PropTypes.oneOf(["dark", "light"]),
    /**
     * aria-label for the play button
     */
    playAriaLabel: PropTypes.string,
    /**
     * aria-label for the pause button
     */
    pauseAriaLabel: PropTypes.string,
    /**
     * Defines the component is play or pause
     */
    defaultState: PropTypes.bool,
    /**
     * Callback function to get the updated state value on click
     */
    handleClick: PropTypes.func,
    /**
     * id for the play-pause-button
     */
    id: PropTypes.string
  };

  getClassName = getClassNameFactory(PlayPauseControl.displayName);

  constructor(props) {
    super(props);
    this.state = {
      currentState: props.defaultState
    };
  }

  handleClick = () => {
    const { handleClick } = this.props;
    const { currentState } = this.state;

    this.setState(
      {
        currentState: !currentState
      },
      () => {
        if (handleClick) {
          handleClick(this.state.currentState);
        }
      }
    );
  };

  render() {
    const { playAriaLabel, pauseAriaLabel, backgroundType, id } = this.props;
    const { currentState } = this.state;
    const name = currentState ? "play" : "pause";
    const ariaLabel = currentState ? playAriaLabel : pauseAriaLabel;

    return (
      <button
        id={id}
        className={this.getClassName({
          modifiers: classNames(backgroundType)
        })}
        onClick={this.handleClick}
        aria-label={ariaLabel}
        aria-pressed={currentState}
      >
        <SVGIcon
          name={name}
          viewBox="0 0 28 28"
          style={{ width: "28px", height: "28px" }}
        />
      </button>
    );
  }
}

PlayPauseControl.defaultProps = {
  defaultState: false,
  backgroundType: "light",
  playAriaLabel: "play button",
  pauseAriaLabel: "pause button",
  id: "play-pause-button"
};

export default PlayPauseControl;
