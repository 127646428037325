import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import classNames from "classnames";
import Button from "@emcm-ui/component-button";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

const cartIcon = <SVGIcon name="cart" size="s" />;

const SalesCard = ({
  theme,
  currency,
  price,
  isPriceTermRequired,
  headingStyle,
  descriptiveCard,
  validity,
  descriptionType,
  description,
  isBtnRequired,
  href,
  buttonText,
  isLegalCopyRequired,
  legalCopyText,
  descriptionArray,
  isSecondaryBtnRequired,
  secondaryHref,
  secondaryButtonText
}) => {
  const getClassName = getClassNameFactory(SalesCard.displayName);

  const listDescription = descriptionArray.map(item => (
    <li
      className={getClassName({
        descendantName: "listitem",
        className: typestack("p1")
      })}
      key={item.name}
    >
      {item.name}
    </li>
  ));

  const createDescription = () => {
    switch (descriptionType) {
      case "Default":
        return (
          <article
            className={getClassName({
              descendantName: "description",
              className: typestack("p1")
            })}
            aria-label={description}
          >
            {description}
          </article>
        );
      case "Ordered List":
        return (
          <ol
            className={getClassName({
              descendantName: "ordereddescription",
              className: typestack("p1")
            })}
          >
            {listDescription}
          </ol>
        );
      case "Tick List":
        return (
          <ul
            className={getClassName({
              descendantName: "tickdescription",
              className: typestack("p1")
            })}
          >
            {listDescription}
          </ul>
        );
      default:
        return null;
    }
  };

  const getHeading = () => {
    if (headingStyle === "Descriptive") {
      return (
        <h2
          className={getClassName({
            descendantName: "descriptivecard",
            className: typestack("h4")
          })}
        >
          {descriptiveCard}
        </h2>
      );
    }

    return (
      <h2
        className={getClassName({
          descendantName: "price",
          className: typestack("h2")
        })}
      >
        {currency} {price}
      </h2>
    );
  };

  const getPrimaryButton = () => {
    if (isBtnRequired) {
      return (
        <Button
          block={false}
          color={"primary"}
          disabled={false}
          isBtnRequired={isBtnRequired}
          href={href}
          kind="link"
          tabIndex="0"
          ariaLabel={buttonText}
          icon={cartIcon}
        >
          {buttonText}
        </Button>
      );
    }
  };
  const getSecondaryButton = () => {
    const btnStyle = theme === "light" ? "secondary" : "secondaryDark";

    if (isSecondaryBtnRequired) {
      return (
        <Button
          block={false}
          color={btnStyle}
          disabled={false}
          isBtnRequired={isSecondaryBtnRequired}
          href={secondaryHref}
          kind="link"
          tabIndex="0"
          ariaLabel={secondaryButtonText}
        >
          {secondaryButtonText}
        </Button>
      );
    }
  };

  const getCardFooter = () => {
    if (isBtnRequired || isSecondaryBtnRequired) {
      return (
        <article className={getClassName({ descendantName: "footer" })}>
          {getPrimaryButton()}
          {getSecondaryButton()}
        </article>
      );
    }
  };

  return (
    <section
      className={getClassName({
        modifiers: classNames(theme)
      })}
      data-rehydratable={getRehydratableName(SalesCard.displayName)}
      data-theme={theme}
      data-currency={currency}
      data-price={price}
      data-price-term-required={isPriceTermRequired}
      data-heading-style={headingStyle}
      data-descriptive-card={descriptiveCard}
      data-validity={validity}
      data-description-type={descriptionType}
      data-description={description}
      data-btn-required={isBtnRequired}
      data-href={href}
      data-button-text={buttonText}
      data-secondary-btn-required={isSecondaryBtnRequired}
      data-secondary-href={secondaryHref}
      data-secondary-button-text={secondaryButtonText}
      data-legal-copy-required={isLegalCopyRequired}
      data-legal-copy-text={legalCopyText}
      data-description-array={JSON.stringify(descriptionArray)}
    >
      <article className={getClassName({ descendantName: "header" })}>
        <article aria-describedby="price-description">
          {getHeading()}

          {isPriceTermRequired &&
            headingStyle === "Default" && (
              <p
                className={getClassName({
                  descendantName: "validity",
                  className: typestack("p1")
                })}
                id="price-description"
              >
                {validity}
              </p>
            )}
        </article>
      </article>

      {createDescription()}

      {getCardFooter()}

      {isLegalCopyRequired && (
        <p
          className={getClassName({
            descendantName: "legal",
            className: typestack("p2")
          })}
        >
          {legalCopyText}
        </p>
      )}
    </section>
  );
};

SalesCard.displayName = "SalesCard";

SalesCard.headingStyle = ["Default", "Descriptive"];

SalesCard.themeTypes = ["dark", "light"];

SalesCard.descriptionType = ["Default", "Ordered List", "Tick List"];

SalesCard.currency = ["£", "$", "€", "₹", "₣"];

SalesCard.propTypes = {
  /**
   * Dark or light theme
   */
  theme: PropTypes.string.isRequired,
  /**
   * Currency of the product price
   */
  currency: PropTypes.string.isRequired,
  /**
   * Product price amount
   */
  price: PropTypes.number.isRequired,
  /**
   * Check for price term description reuired or ot
   */
  isPriceTermRequired: PropTypes.bool.isRequired,
  /**
   * Check for price required or descriptive required
   */
  headingStyle: PropTypes.string.isRequired,
  /**
   * Text for descriptive card
   */
  descriptiveCard: PropTypes.string.isRequired,
  /**
   * Text for the license period
   */
  validity: PropTypes.string.isRequired,
  /**
   * Description type of the card
   */
  descriptionType: PropTypes.string,
  /**
   * Description of the product price
   */
  description: PropTypes.string.isRequired,
  /**
   * Primary button required or not check
   */
  isBtnRequired: PropTypes.bool.isRequired,
  /**
   * Hyperlink for card
   */
  href: PropTypes.string.isRequired,
  /**
   * Text of the primary button
   */
  buttonText: PropTypes.string.isRequired,
  /**
   * Secondary button required or not check
   */
  isSecondaryBtnRequired: PropTypes.bool,
  /**
   * Hyperlink for secondary button
   */
  secondaryHref: PropTypes.string,
  /**
   * Text of the secondary button
   */
  secondaryButtonText: PropTypes.string,
  /**
   * Check if legal copy is required or not
   */
  isLegalCopyRequired: PropTypes.bool.isRequired,
  /**
   * Text for legal copy
   */
  legalCopyText: PropTypes.string.isRequired,
  /**
   * Array of list items
   */
  descriptionArray: PropTypes.array
};

SalesCard.defaultProps = {
  isPriceTermRequired: true,
  headingStyle: "Default",
  descriptivecard: "Benefits",
  isLegalCopyRequired: false,
  legalCopyText: "*Legal copy",
  isSecondaryBtnRequired: false
};

export default SalesCard;
