import React, { Component } from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import { typestack } from "@emcm-ui/component-typestack/lib/utilities";

class FilterSectionOverflow extends Component {
  static displayName = "SearchFilters.FilterSectionOverflow";

  static propTypes = {
    /**
     * The children of this section. Should be a `SearchFilters.FilterItem` or `SearchFilters.FilterGroup`.
     */
    children: PropTypes.node,

    /**
     * The text to show when the section can expand
     */
    collapseText: PropTypes.string,

    /**
     * The text to show when the section can expand
     */
    expandText: PropTypes.string
  };

  static defaultProps = {
    collapseText: "See less",
    expandText: "See more"
  };

  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false
    };

    this.handleToggle = this.handleToggle.bind(this);
    this.getClassName = getClassNameFactory(
      "SearchFiltersFilterSectionOverflow"
    );
  }

  handleToggle() {
    this.setState({
      isExpanded: !this.state.isExpanded
    });
  }

  render() {
    const titleText = this.state.isExpanded
      ? this.props.collapseText
      : this.props.expandText;

    return (
      <li
        className={this.getClassName({
          states: this.state.isExpanded ? "expanded" : ""
        })}
        data-collapse-text={this.props.collapseText}
        data-expand-text={this.props.expandText}
        data-rehydratable={getRehydratableName(
          FilterSectionOverflow.displayName
        )}
      >
        <ul className={this.getClassName({ descendantName: "items" })}>
          {this.props.children}
        </ul>

        <button
          className={this.getClassName({
            descendantName: "toggleButton",
            className: typestack("p1Bold")
          })}
          onClick={this.handleToggle}
        >
          <div
            className={this.getClassName({
              descendantName: "toggleButtonIcon"
            })}
          >
            {this.state.isExpanded ? (
              <span
                className={this.getClassName({
                  descendantName: "caretUp"
                })}
              >
                <SVGIcon name="caret" size="s" />
              </span>
            ) : (
              <SVGIcon name="caret" size="s" />
            )}
          </div>
          <span
            className={this.getClassName({
              descendantName: "toggleButtonText"
            })}
          >
            {titleText}
          </span>
        </button>
      </li>
    );
  }
}

export default FilterSectionOverflow;
